import * as React from 'react';
import {useSelector} from 'react-redux';
import styled from '@emotion/styled';
import {Header} from '>shared/components/landmarks/header/header';
import {respondTo} from '>styles/breakpoints';
import {getHeaderItems} from './headerItems';

import {
  HeaderContainer,
  HeaderContentContainer,
} from '>shared/components/layouts/responsive/responsiveAppComponents';
import {SideBarToggle} from '>shared/components/sideBar/sideBarToggle/sideBarToggle';
import {ValidAuthStateWithUser} from '>store/reducers/authReducer';
import {assertValidAuthState} from '>lib/assert';
import {history} from '../../history';

import {ImpersonationBanner} from '>shared/components/banners/impersonationBanner';
import {HeaderBrandingLink} from './headerBrandingLink';
import {DEVELOPMENT_PRODUCT_NAME, WEBUI_DVP_URL} from '>root/env';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';

interface HeaderProps {
  onSideBarToggle: () => void;
  isSideBarExpanded?: boolean;
  onItemOpened?: () => void;
}

const HideOnMobile = styled.div`
  display: flex;
  ${respondTo.mediumOrHigher} {
    display: none;
  }
`;

export const AuthenticatedHeader: React.FC<HeaderProps> = ({
  onSideBarToggle,
  isSideBarExpanded,
  onItemOpened,
}) => {
  const authInfoStore = useSelector((state) => state.auth);
  assertValidAuthState(authInfoStore);
  const isImpersonated = authInfoStore.isImpersonated;

  const userName = authInfoStore.token.user.name;

  const auth = useSelector((state) => state.auth) as ValidAuthStateWithUser;

  const headerItems = getHeaderItems(auth.token.user, onItemOpened);

  const unImpersonate = React.useCallback(() => {
    history.push('/logout');
  }, []);

  const {isHeaderHidden} = useResponsiveLayout();

  return (
    <HeaderContainer hidden={isHeaderHidden}>
      <HeaderContentContainer>
        <HideOnMobile>
          <SideBarToggle onToggle={onSideBarToggle} isSideBarExpanded={isSideBarExpanded} />
        </HideOnMobile>
        <Header
          brandingLogo={<HeaderBrandingLink />}
          items={headerItems}
          productName={DEVELOPMENT_PRODUCT_NAME}
          homePath={WEBUI_DVP_URL}
        />
      </HeaderContentContainer>
      {isImpersonated && <ImpersonationBanner name={userName} unImpersonate={unImpersonate} />}
    </HeaderContainer>
  );
};
