"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildHelpMenuItems = exports.HelpMenuContext = void 0;
var _menuItem = require("../components/menu/menuItem");
var HelpMenuContext;
(function (HelpMenuContext) {
  HelpMenuContext["Header"] = "header";
  HelpMenuContext["Sidebar"] = "sidebar";
})(HelpMenuContext || (exports.HelpMenuContext = HelpMenuContext = {}));
const knowledgeBaseHelpUrl = 'https://help.wonderlic.com/en';
const howToGuideHelpUrl = 'https://help.wonderlic.com/en/articles/3753325-wonderlic-select-tour-guide';
const whatsNewHelpUrl = 'https://help.wonderlic.com/en/collections/2053150-product-changes-and-updates';
const feedbackUrl = 'https://www.research.net/r/WonScoreFeedback';
const privacyPolicyUrl = 'https://privacy.wonderlic.com/';
const buildHelpMenuItems = context => {
  if (context === HelpMenuContext.Header) {
    return [{
      id: 'knowledge-base',
      href: knowledgeBaseHelpUrl,
      target: _menuItem.LinkTarget.NewTab,
      text: 'Knowledge Base',
      hoverStyle: true,
      qaDataAttribute: {
        type: 'data-qa-link',
        name: 'knowledge-base'
      }
    }, {
      id: 'how-to-guide',
      href: howToGuideHelpUrl,
      target: _menuItem.LinkTarget.NewTab,
      text: 'How-to Guide',
      hoverStyle: true,
      qaDataAttribute: {
        type: 'data-qa-link',
        name: 'how-to-guide'
      }
    }, {
      id: 'whats-new',
      href: whatsNewHelpUrl,
      target: _menuItem.LinkTarget.NewTab,
      text: "What's New",
      hoverStyle: true,
      border: true,
      qaDataAttribute: {
        type: 'data-qa-link',
        name: 'whats-new'
      }
    }, {
      id: 'send-feedback',
      href: feedbackUrl,
      target: _menuItem.LinkTarget.NewTab,
      text: 'Send Feedback',
      hoverStyle: true,
      qaDataAttribute: {
        type: 'data-qa-link',
        name: 'send-feedback'
      }
    }];
  } else {
    return [{
      id: 'privacy-policy',
      href: privacyPolicyUrl,
      target: _menuItem.LinkTarget.NewTab,
      text: 'Privacy Policy',
      hoverStyle: true,
      qaDataAttribute: {
        type: 'data-qa-link',
        name: 'privacy-policy'
      }
    }];
  }
};
exports.buildHelpMenuItems = buildHelpMenuItems;