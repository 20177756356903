/* NEW COLOR PALETTE */
export var white = '#FFFFFF';
export var black = '#000000';
// Grays
export var gray25 = '#FCFCFD';
export var gray50 = '#F9FAFB';
export var gray100 = '#F5F7FA';
export var gray200 = '#eaecf0';
export var gray300 = '#d0d5dd';
export var gray400 = '#98A2B3';
export var gray500 = '#667085';
export var gray600 = '#475467';
export var gray700 = '#344054';
export var gray800 = '#1D2939';
export var gray900 = '#101828';
// Blues
export var blue25 = '#F5FBFF';
export var blue50 = '#F0F9FF';
export var blue100 = '#E0F2FE';
export var blue200 = '#b9e6fe';
export var blue300 = '#7cd4fd';
export var blue400 = '#36BFFA';
export var blue500 = '#0697E0';
export var blue600 = '#017AB7';
export var blue700 = '#026aa2';
export var blue800 = '#065986';
export var blue900 = '#0B4A6F';
// Primary Color (Blue) - Blues are exported as both "blue" and primary since the names are used interchangeably in design.
export var primary25 = blue25;
export var primary50 = blue50;
export var primary100 = blue100;
export var primary200 = blue200;
export var primary300 = blue300;
export var primary400 = blue400;
export var primary500 = blue500;
export var primary600 = blue600;
export var primary700 = blue700;
export var primary800 = blue800;
export var primary900 = blue900;
// Success (Green)
export var success25 = '#F6FEF9';
export var success50 = '#ECFDF3';
export var success100 = '#D1FADF';
export var success200 = '#A6F4C5';
export var success300 = '#6CE9A6';
export var success400 = '#32D583';
export var success500 = '#12B76A';
export var success600 = '#039855';
export var success700 = '#027A48';
export var success800 = '#05603A';
export var success900 = '#054F31';
// Error (Red)
export var error25 = '#FFFBFA';
export var error50 = '#FEF3F2';
export var error100 = '#FEE4E2';
export var error200 = '#FECDCA';
export var error300 = '#FDA29B';
export var error400 = '#F97066';
export var error500 = '#F04438';
export var error600 = '#D92D20';
export var error700 = '#B42318';
export var error800 = '#961B13';
export var error900 = '#7A271A';
// Warning
export var warning25 = '#FFFCF5';
export var warning50 = '#FFFAEB';
export var warning100 = '#FEF0C7';
export var warning200 = '#FEDF89';
export var warning300 = '#FEC84B';
export var warning400 = '#FDB022';
export var warning500 = '#F79009';
export var warning600 = '#DC6803';
export var warning700 = '#B54708';
export var warning800 = '#93370D';
export var warning900 = '#7A2E0E';
// Orange
export var orange25 = '#FEFAF5';
export var orange50 = '#FEF6EE';
export var orange100 = '#FDEAD7';
export var orange200 = '#F9DBAF';
export var orange300 = '#F7B27A';
export var orange400 = '#F38744';
export var orange500 = '#EF6820';
export var orange600 = '#E04F16';
export var orange700 = '#B93815';
export var orange800 = '#932F19';
export var orange900 = '#772917';
// Yellow
export var yellow25 = '#FFFCF4';
export var yellow50 = '#FFF8E6';
export var yellow100 = '#FFF3D8';
export var yellow200 = '#FFE8B2';
export var yellow300 = '#FFDC8C';
export var yellow400 = '#FFD166';
export var yellow500 = '#FFC02E';
export var yellow600 = '#E5A000';
export var yellow700 = '#A15C07';
export var yellow800 = '#854A0E';
export var yellow900 = '#713B12';
// Indigo
export var indigo25 = '#F5F8FF';
export var indigo50 = '#EEF4FF';
export var indigo100 = '#E0EAFF';
export var indigo200 = '#C7D7FE';
export var indigo300 = '#A4BCFD';
export var indigo400 = '#8098F9';
export var indigo500 = '#6172F3';
export var indigo700 = '#3538CD';
export var indigo900 = '#2D3282';
// Violet
export var violet25 = '#FBFAFF';
export var violet50 = '#F5F3FF';
export var violet100 = '#ECE9FE';
export var violet200 = '#DDD6FE';
export var violet400 = '#A48AFB';
export var violet500 = '#875BF7';
export var violet600 = '#7839EE';
export var violet700 = '#6927DA';
// Rose
export var rose25 = '#FFF5F6';
export var rose50 = '#FFF1F3';
export var rose200 = '#FECDD6';
export var rose700 = '#C01048';
// Fuschia
export var fuschia25 = '#FEFAFF';
export var fuschia50 = '#FDF4FF';
export var fuschia100 = '#FBE8FF';
export var fuschia200 = '#F6D0FE';
export var fuschia300 = '#EEAAFD';
export var fuschia400 = '#E478FA';
export var fuschia500 = '#D444F1';
export var fuschia700 = '#9F1AB1';
export var fuschia900 = '#6F1877';
// Cyan
export var cyan25 = '#F5FEFF';
export var cyan50 = '#ECFDFF';
export var cyan100 = '#CFF9FE';
export var cyan200 = '#A5F0FC';
export var cyan300 = '#67E3F9';
export var cyan400 = '#22CCEE';
export var cyan500 = '#06AED4';
export var cyan700 = '#0E7090';
export var cyan900 = '#164C63';
/*
 * OLD COLORS - DON'T USE COLORS BELOW THIS LINE :)
 * -----------------------------------------------------
 */
// Purples
/**
 * @deprecated color - please use new color palette
 */
export var purpleLight = '#EAEAF4';
/**
 * @deprecated color - please use new color palette
 */
export var purple = '#6F03E0';
/**
 * @deprecated color - please use new color palette
 */
export var purpleAccent = '#E9DFF2';
/**
 * @deprecated color - please use new color palette
 */
export var violet = '#2B2BBD';
/**
 * @deprecated color - please use new color palette
 */
export var violetAccent = '#E3E3FF';
/**
 * @deprecated color - please use new color palette
 */
export var electricViolet = '#D01EDE';
// Blues
/**
 * @deprecated color - please use new color palette
 */
export var aquaBlue = '#0081B1';
/**
 * @deprecated color - please use new color palette
 */
export var aquaBlueAccent = '#0078a0';
/**
 * @deprecated color - please use new color palette
 */
export var astral = '#327B9D';
/**
 * @deprecated color - please use new color palette
 */
export var blue = '#0080FF';
/**
 * @deprecated color - please use new color palette
 */
export var blueAccent = '#DEEDFE';
/**
 * @deprecated color - please use new color palette
 */
export var cello = '#1E475A';
/**
 * @deprecated color - please use new color palette
 */
export var chathamsBlue = '#12336D';
/**
 * @deprecated color - please use new color palette
 */
export var darkBlue = '#373894';
/**
 * @deprecated color - please use new color palette
 */
export var darkGreyAzure = '#4D5966';
/**
 * @deprecated color - please use new color palette
 */
export var hanBlue = '#2B2BBD';
/**
 * @deprecated color - please use new color palette
 */
export var hawkesBlue = '#CEE4FB';
/**
 * @deprecated color - please use new color palette
 */
export var iceberg = '#E5F2F7';
/**
 * @deprecated color - please use new color palette
 */
export var lightBlue = '#CCCDE4';
/**
 * @deprecated color - please use new color palette
 */
export var lochmara = '#0079C5';
/**
 * @deprecated color - please use new color palette
 */
export var mariner = '#1F61D9';
/**
 * @deprecated color - please use new color palette
 */
export var outerSpace = '#2F3942';
/**
 * @deprecated color - please use new color palette
 */
export var pattensBlue = '#DEEDFE';
/**
 * @deprecated color - please use new color palette
 */
export var portage = '#89AAE7';
/**
 * @deprecated color - please use new color palette
 */
export var riverBed = '#43515F';
/**
 * @deprecated color - please use new color palette
 */
export var rockBlue = '#98BCCE';
/**
 * @deprecated color - please use new color palette
 */
export var scienceBlue = '#0571DC';
/**
 * @deprecated color - please use new color palette
 */
export var scienceDarkBlue = '#1E475A';
/**
 * @deprecated color - please use new color palette
 */
export var wonderlicDarkBlue = '#001F63';
/**
 * @deprecated color - please use new color palette
 */
export var wonscoreDarkBlue = '#171D4F';
/**
 * @deprecated color - please use new color palette
 */
export var wonscoreLightBlue = '#327b9d';
/**
 * @deprecated color - please use new color palette
 */
export var flickrBlue = '#1f61d9';
/**
 * @deprecated color - please use new color palette
 */
export var aliceBlue = '#dae9fa';
/**
 * @deprecated color - please use new color palette
 */
export var frenchSkyBlue = '#7fade5';
// Blue-Greens
/**
 * @deprecated color - please use new color palette
 */
export var aqua = '#00E2C1';
/**
 * @deprecated color - please use new color palette
 */
export var aquaAccent = '#CAF9EB';
/**
 * @deprecated color - please use new color palette
 */
export var aquaSqueeze = '#EBF6F5';
/**
 * @deprecated color - please use new color palette
 */
export var keppel = '#39A69B';
/**
 * @deprecated color - please use new color palette
 */
export var keppelAccent = '#36938A';
/**
 * @deprecated color - please use new color palette
 */
export var lochinvar = '#2B8077';
/**
 * @deprecated color - please use new color palette
 */
export var paradiso = '#256E69';
/**
 * @deprecated color - please use new color palette
 */
export var sea = '#02C4CE';
/**
 * @deprecated color - please use new color palette
 */
export var seaAccent = '#D7F9F8';
/**
 * @deprecated color - please use new color palette
 */
export var swansDown = '#D7EDEB';
/**
 * @deprecated color - please use new color palette
 */
export var darkSkyBlue = '#93becc';
// Greens
/**
 * @deprecated color - please use new color palette
 */
export var green = '#24B471';
/**
 * @deprecated color - please use new color palette
 */
export var greenAccent = '#DDF3EB';
/**
 * @deprecated color - please use new color palette
 */
export var mountainMeadow = '#24B47E';
/**
 * @deprecated color - please use new color palette
 */
export var magicMint = '#A7E1CB';
/**
 * @deprecated color - please use new color palette
 */
export var deepSea = '#19805A';
/**
 * @deprecated color - please use new color palette
 */
export var elfGreen = '#1F996C';
/**
 * @deprecated color - please use new color palette
 */
export var darkGreen = '#1C524C';
/**
 * @deprecated color - please use new color palette
 */
export var jaggedIce = '#CCEEDE';
// Oranges
/**
 * @deprecated color - please use new color palette
 */
export var orange = '#ED741B';
/**
 * @deprecated color - please use new color palette
 */
export var carrotOrange = '#F29829';
/**
 * @deprecated color - please use new color palette
 */
export var vividTangerine = '#FF8C7D';
/**
 * @deprecated color - please use new color palette
 */
export var chestnutOrange = '#D66363';
/**
 * @deprecated color - please use new color palette
 */
export var serenade = '#FFF5EA';
/**
 * @deprecated color - please use new color palette
 */
export var cherokee = '#FCD09B';
// Reds
/**
 * @deprecated color - please use new color palette
 */
export var lightRed = '#F1624E';
/**
 * @deprecated color - please use new color palette
 */
export var vermilion = '#FD3E17';
/**
 * @deprecated color - please use new color palette
 */
export var validationErrorRed = '#F06F6F';
/**
 * @deprecated color - please use new color palette
 */
export var errorStateRed = '#E00000';
// Old gray palette (these will eventually be replaced by the grays below)
/**
 * @deprecated color - please use new color palette
 */
export var grey98 = '#FAFAFA';
/**
 * @deprecated color - please use new color palette
 */
export var grey96 = '#F5F5F5';
/**
 * @deprecated color - please use new color palette
 */
export var grey93 = '#EDEDED';
/**
 * @deprecated color - please use new color palette
 */
export var grey90 = '#E5E5E5';
/**
 * @deprecated color - please use new color palette
 */
export var grey62 = '#9E9E9E';
/**
 * @deprecated color - please use new color palette
 */
export var grey50 = '#7F7F7F';
/**
 * @deprecated color - please use new color palette
 */
export var grey44 = '#707070';
/**
 * @deprecated color - please use new color palette
 */
export var grey30 = '#4C4C4C';
/**
 * @deprecated color - please use new color palette
 */
export var grey15 = '#262626';
/**
 * @deprecated color - please use new color palette
 */
export var grey11 = '#bbb';
/**
 * @deprecated color - please use new color palette
 */
export var athensGrey = '#EBEEF2';
/**
 * @deprecated color - please use new color palette
 */
export var greyChateau = '#A9AFB4';
/**
 * @deprecated color - please use new color palette
 */
export var silverSand = '#BABFC5';
/**
 * @deprecated color - please use new color palette
 */
export var trout = '#4F5B66';
/**
 * @deprecated color - please use new color palette
 */
export var platinum = '#EAEAEA';
// Blue
/**
 * @deprecated color - please use new color palette
 */
export var victoria = '#534491';
// Pinks
/**
 * @deprecated color - please use new color palette
 */
export var darkPink = '#EB6181';
// TODO: This should be in the theme
/**
 * @deprecated color - please use new color palette
 */
export var dropShadowColor = 'rgba(0, 0, 0, 0.16)';
/**
 * @deprecated color - please use new color palette
 */
export var transparent = 'rgba(255, 255, 255, 0)';
/**
 * @deprecated color - please use new color palette
 */
export var auroMetalSaurus = '#656D81';
/**
 * @deprecated color - please use new color palette
 */
export var char00 = '#F5F7FA';
/**
 * @deprecated color - please use new color palette
 */
export var char100 = '#e2e4e9';
/**
 * @deprecated color - please use new color palette
 */
export var char200 = '#c7cad1';
/**
 * @deprecated color - please use new color palette
 */
export var char400 = '#656D81';
/**
 * @deprecated color - please use new color palette
 */
export var char600 = '#444A5A';
/**
 * @deprecated color - please use new color palette
 */
export var char800 = '#2a2f3c';
// Greens - OLD
/**
 * @deprecated color - please use new color palette
 */
export var greenText = '#083a22';
/**
 * @deprecated color - please use new color palette
 */
export var green7 = '#f1faf6';
/**
 * @deprecated color - please use new color palette
 */
export var green25 = '#cceede';
/**
 * @deprecated color - please use new color palette
 */
export var green100 = '#34bc7d';
// Blues - OLD
/**
 * @deprecated color - please use new color palette
 */
export var blueText = '#004b66';
/**
 * @deprecated color - please use new color palette
 */
export var blue7 = '#edf6f9';
/**
 * @deprecated color - please use new color palette
 */
export var bluePrimary = '#0a8ebd';
// Reds - OLD
/**
 * @deprecated color - please use new color palette
 */
export var redText = '#9A110E';
/**
 * @deprecated color - please use new color palette
 */
export var red7 = '#FEF3F3';
/**
 * @deprecated color - please use new color palette
 */
export var red25 = '#FBD4D3';
/**
 * @deprecated color - please use new color palette
 */
export var red100 = '#EF5350';
/**
 * @deprecated color - please use new color palette
 */
export var red200 = '#D14A47';
