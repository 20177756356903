import * as React from 'react';
import {Location} from 'history';
import {ErrorPage} from '>shared/components/pages/errorPage';
import {
  AccountNotFoundError,
  CannotAccessContentManagerError,
  EmployeeNotFoundError,
  NotAdminUserError,
  EmployeeProfileNotResolvedError,
  WndError,
  AccountCancelledError,
  AccountOnHoldPastDueError,
  AccountOnHoldFailedBillingError,
  EmployeeArchivedError,
} from '>root/errors';
import {pageChangeEffect} from '>lib/sideEffects';
import {useSelector} from 'react-redux';
import {assertValidAuthState} from '>lib/assert';
import {arrowBackIcon} from '>shared/components/icon/icons';
import {WEBUI_BILLING_URL, INTERCOM_BASE_HELP_URL} from '>root/env';
import {history} from '>root/history';
import {
  StyledMessage,
  HelpContainer,
  ArrowIcon,
  AnchorText,
  HelpSection,
  StyledAnchor,
  ButtonContainer,
  AccountManagementButton,
  AccessButton,
  ImpersonationBypassButton,
} from './styles';
import {textMdRegular} from '>shared/components/typography/designSystemTypography';
import {ButtonType} from '>shared/components/button/button';

const PAGE_TITLE = `${process.env.DEVELOPMENT_PRODUCT_NAME} - Error`;
interface ErrorProps {
  location?: Location;
  error?: WndError;
}

function errorCodeToContent(
  errorCode: number | undefined,
  canManageBilling: Boolean,
  isImpersonated: Boolean
): {heading: string; content: React.ReactNode} {
  switch (errorCode) {
    case AccountNotFoundError.code:
      return {
        heading: 'Account Not Found',
        content: (
          <p css={textMdRegular}>
            This user does not have an account attached. Please contact your{' '}
            {process.env.DEVELOPMENT_PRODUCT_NAME}
            Account Owner or Admin to update this user's permissions.
          </p>
        ),
      };
    case EmployeeNotFoundError.code:
      return {
        heading: 'Employee Not Found',
        content: (
          <p css={textMdRegular}>
            This user is not included as an Employee of this company. Please contact your{' '}
            {process.env.DEVELOPMENT_PRODUCT_NAME} Account Owner or Admin to add this user as an
            Employee.
          </p>
        ),
      };
    case EmployeeArchivedError.code:
      return {
        heading: 'Employee Archived',
        content: (
          <p css={textMdRegular}>This Employee has been archived and may not view their results.</p>
        ),
      };
    case NotAdminUserError.code:
      return {
        heading: 'Admin User Required',
        content: (
          <p css={textMdRegular}>
            The user must be an admin to view this page. Please contact your{' '}
            {process.env.DEVELOPMENT_PRODUCT_NAME}
            Account Owner or Admin to add this user as an Admin.
          </p>
        ),
      };
    case CannotAccessContentManagerError.code:
      return {
        heading: 'No Content Manager Access',
        content: (
          <p css={textMdRegular}>This account user does not have access to manage content.</p>
        ),
      };
    case EmployeeProfileNotResolvedError.code:
      return {
        heading: 'Job Profile not Resolved',
        content: (
          <p css={textMdRegular}>Please contact your manager to correct your job profile.</p>
        ),
      };
    case AccountCancelledError.code:
      return {
        heading: 'Cancelled Account',
        content: getBillingContent(errorCode, canManageBilling, isImpersonated),
      };
    case AccountOnHoldPastDueError.code:
      return {
        heading: 'Account On Hold',
        content: getBillingContent(errorCode, canManageBilling, isImpersonated),
      };

    case AccountOnHoldFailedBillingError.code:
      return {
        heading: 'Account On Hold',
        content: getBillingContent(errorCode, canManageBilling, isImpersonated),
      };

    default:
      return {
        heading: 'Something Went Wrong',
        content: (
          <p css={textMdRegular}>
            We ran into an unexpected problem when handling your request and logged the issue on our
            side. Please try again and let our support team know if you're unable to continue.
          </p>
        ),
      };
  }
}

function getBillingContent(errorCode: number, canManageBilling: Boolean, isImpersonated: Boolean) {
  let billingMessage;
  switch (errorCode) {
    case AccountCancelledError.code:
      billingMessage = canManageBilling
        ? 'Your account has been cancelled. Please consider purchasing a plan to reactivate it.'
        : 'This account has been cancelled. Please contact the owner of your account for assistance.';
      break;
    case AccountOnHoldPastDueError.code:
      billingMessage = canManageBilling
        ? 'Wonderlic has not received payment for an invoice for your account. Please visit Account Management and provide payment to re-instate your account or call (847) 549-5487 to discuss payment status.'
        : 'Wonderlic has not received payment for an invoice for your account. Please contact your account owner to provide payment to re-instate your account.';
      break;
    case AccountOnHoldFailedBillingError.code:
      billingMessage = canManageBilling
        ? 'There was an error during a recent attempt to bill your credit card. Please verify your credit card information on the Account Management page in order to restore access to your account or call (847) 549-5487 to discuss payment status.'
        : 'There was an error during a recent attempt to bill your credit card. As a result your account has been placed on hold. Please contact the owner of your account.';
      break;
  }

  const helpMenuItems = [
    {
      href: INTERCOM_BASE_HELP_URL,
      linkText: 'Knowledge Base',
      message: 'Access articles and get answers to common questions.',
      icon: arrowBackIcon,
    },
    {
      linkText: 'Chat to support',
      message: 'Our friendly team is here to help.',
      icon: arrowBackIcon,
      onClick: () => {
        window.Intercom('show');
      },
    },
  ];

  return (
    <>
      <StyledMessage>{billingMessage}</StyledMessage>
      {getActionButtons(canManageBilling, isImpersonated)}
      <HelpContainer>
        {helpMenuItems.map((menuItem, index) => {
          return (
            <HelpSection key={index}>
              <StyledAnchor href={menuItem.href} target="_blank" onClick={menuItem.onClick}>
                <AnchorText>{menuItem.linkText}</AnchorText>
                <ArrowIcon src={arrowBackIcon} />
              </StyledAnchor>
              <p css={textMdRegular}>{menuItem.message}</p>
            </HelpSection>
          );
        })}
      </HelpContainer>
    </>
  );
}

function getActionButtons(canManageBilling: Boolean, isImpersonated: Boolean) {
  const accountManagementUrl = `${WEBUI_BILLING_URL}/account-management`;
  const planSelectionUrl = accountManagementUrl + '/plan-selection';

  const handleImpersonationBypass = () => {
    history.push('/');
  };

  return (
    <ButtonContainer>
      {canManageBilling && (
        <>
          <AccountManagementButton
            data-qa-button="account-management-button"
            buttonType={ButtonType.Neutral}
            onClick={() => (window.location.href = accountManagementUrl)}
          >
            Account Management
          </AccountManagementButton>
          <AccessButton
            data-qa-button="grant-access-button"
            onClick={() => (window.location.href = planSelectionUrl)}
          >
            Give me access
          </AccessButton>
        </>
      )}
      {isImpersonated && (
        <ImpersonationBypassButton
          buttonType={ButtonType.SuperDestructive}
          data-qa-button="bypass-impersonation"
          onClick={handleImpersonationBypass}
        >
          Bypass
        </ImpersonationBypassButton>
      )}
    </ButtonContainer>
  );
}

export const DVPErrorPage: React.FC<ErrorProps> = ({location, error}) => {
  let errorCode;

  if (error) {
    // can be provided when explicitly rendering this page
    errorCode = error.code;
  } else if (location) {
    // navigated or redirected to this page
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get('code');
    errorCode = codeParam ? Number(codeParam) : undefined;
  }

  const authState = useSelector((state) => state.auth);
  assertValidAuthState(authState);

  let canManageBilling = false;
  const productUser = useSelector((state) => state.user.productUser);

  if (productUser) {
    const selectedAccountId = productUser.selectedUnifiedAccountId;

    canManageBilling = Boolean(
      productUser.unifiedAccounts[selectedAccountId].permissions.canManageBilling
    );
  }

  const isImpersonated = authState.isImpersonated;

  const {heading, content} = errorCodeToContent(errorCode, canManageBilling, isImpersonated);

  React.useEffect(pageChangeEffect(PAGE_TITLE));

  return <ErrorPage heading={heading} content={content} />;
};
