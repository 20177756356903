import * as React from 'react';
import {useSelector} from 'react-redux';
import styled from '@emotion/styled';
import {StylelessLink} from '>shared/components/actions/link';
import {
  textSmMedium,
  textXsSemiboldUppercase,
} from '>shared/components/typography/designSystemTypography';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import * as colors from 'wnd-themes/lib/colorPalette';
import {vr2, vr4, vr8} from '>shared/styles/mixins/verticalRhythm';

import {LinkButton, LinkVariant} from '>shared/components/actions/linkButton';

export type HeaderLink = {
  text: string;
  targetId: string;
};

const StyledLinkText = styled.span`
  text-align: left;
  ${textSmMedium}
  color: ${colors.gray500};
`;
const Container = styled.nav`
  ${vr8}
`;

const TableOfContentsHeading = styled(Paragraph)`
  ${textXsSemiboldUppercase}
  margin-bottom: ${vr4};
  color: ${colors.blue700};
`;

const LinkContainer = styled.div`
  ${vr2};
  padding-left: 0.8rem;
`;

const SCROLL_OFFSET_ADMIN_Y = 200;
const SCROLL_OFFSET_EMPLOYEE_Y = 170;

interface TabTableOfContentsProps {
  links?: HeaderLink[];
}

export const TabTableOfContents: React.FC<TabTableOfContentsProps> = ({links}) => {
  if (!links) {
    return <React.Fragment />;
  }

  const isAdminImpersonatingEmployee = useSelector(
    (state) => state.employee.employee?.isImpersonated
  );

  const scrollOffset = isAdminImpersonatingEmployee
    ? SCROLL_OFFSET_ADMIN_Y
    : SCROLL_OFFSET_EMPLOYEE_Y;

  const scrollToHeader = React.useCallback((targetId: string) => {
    const linkedHeader = document.getElementById(targetId);
    if (linkedHeader) {
      const offset = linkedHeader.getBoundingClientRect().top + window.scrollY - scrollOffset;
      window.scrollTo({top: offset, behavior: 'smooth'});
    }
  }, []);

  return (
    <Container aria-labelledby="table-of-contents">
      <TableOfContentsHeading id="table-of-contents">On this page</TableOfContentsHeading>
      {links.map((link, idx) => (
        <LinkContainer key={idx}>
          <StylelessLink
            onClick={() => {
              scrollToHeader(link.targetId);
            }}
          >
            <LinkButton variant={LinkVariant.Neutral} data-qa-attribute="tab-table-of-contents">
              <StyledLinkText>{link.text}</StyledLinkText>
            </LinkButton>
          </StylelessLink>
        </LinkContainer>
      ))}
    </Container>
  );
};
