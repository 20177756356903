import React from 'react';
import styled from '@emotion/styled';

export const HeadingActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

export const HeadingActions: React.FC = ({children}) => {
  return <HeadingActionsContainer>{children}</HeadingActionsContainer>;
};
