import styled from '@emotion/styled';
import * as colors from 'wnd-themes/lib/colorPalette';
import {HashLink as Link} from 'react-router-hash-link';
import {baseDropShadow} from '>styles/mixins/shadows';
import {textXsMedium} from '>shared/components/typography/designSystemTypography';
import {BaseMeasureCard} from '>components/measures/measureCard/measureCard.styles';

export const SpotlightCard = styled(BaseMeasureCard)`
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
`;

export const SpotlightHeader = styled.div`
  height: 4.8rem;
  background-color: lightblue;
`;

export const Title = styled.div`
  ${textXsMedium};
  height: 3.6rem;
  margin: 6rem 0 0 0;
  width: 100%;
  color: ${colors.gray600};

  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ChaptersLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  padding: 0.4rem 1.6rem;
  margin-top: 0.4rem;
  background: ${colors.char00};
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.04%), 0 0 0.2rem rgba(0, 0, 0, 0.06%),
    0 0 0.1rem rgba(0, 0, 0, 0.04%);
  border: 0.05rem solid ${colors.gray200};
  border-radius: 0.4rem;
  text-decoration: none;
  white-space: nowrap;
  max-width: 12.2rem;
  overflow-x: clip;
  cursor: pointer;

  & > * {
    cursor: pointer;
  }

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > p:first-of-type {
    margin-top: 0;
  }

  :hover {
    border: 0.05rem solid ${colors.char200};
    ${baseDropShadow}
  }

  &:focus {
    border: 0.1rem solid ${colors.blue300};
    box-shadow: 0px 0.1rem 0.2rem rgba(16, 24, 40, 0.05), 0px 0px 0px 0.4rem ${colors.primary100};
    outline: none;
  }
`;

export const ChaptersContainer = styled.div`
  margin-top: 0.8rem;
`;

export const SpotlightFlagContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const DonutMetricContainer = styled.div`
  position: absolute;
`;
