import * as React from 'react';
import {RouteChildrenProps} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {assertAccountExists, assertAccountUserExists} from '>lib/assert';
import {getLoggedInUserId, isAdmin, isCoachWithTeamAccess, useAccountUser} from '>lib/user';
import {dvpApi} from '>root/apis';
import {history} from '>root/history';
import {pageChangeEffect} from '>lib/sideEffects';
import {LoadingScreen} from '>shared/components/loadingScreen';
import {Table, TableColumnDataType, TableSize} from '>shared/components/tables/table';
import {CellProps, CellValue} from 'react-table';
import * as icons from '>shared/components/icon/icons';
import {Team, TeamRoles} from '>generated/dvp.types';
import {getEmployeeFullName} from '>lib/employee';
import styled from '@emotion/styled';
import {Heading} from '>shared/components/heading/heading';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {NoItemsView} from '>components/noItemsView';
import {EmployeeActionIcon} from '>components/employeeTable/employeeRow';
import {MIN_NUM_MEMBERS} from '>lib/team';
import {IconButton, IconButtonType} from '>shared/components/iconButton/iconButton';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {Dropdown, DropdownPosition} from '>shared/components/menu/dropdown';
import {ModalProvider, useModal} from '>shared/components/modal/useModal';
import {OverflowMenu} from '>shared/components/menu/overflowMenu';
import {RoutePath} from '>root/routes/routes';
import {ActionsContainer} from '>components/dataTable/dataTableRow';
import {ReleaseTeamResultsModal} from '>components/modal/releaseTeamResults';
import {
  TableActionButtonsContainer,
  TableActionsContainer,
} from '../adminEmployees/adminEmployeesPage.styles';
import {Button, ButtonType} from '>shared/components/button/button';
import {AddEditTeamDrawerForm, AddEditTeamMode} from '>components/teams/addEditTeamForm';
import {Link} from '>shared/components/actions/link';
import {ShareTeamReportDrawerForm} from '>components/teams/shareTeamReportForm';

const PAGE_TITLE = 'Team Management';

export enum TeamReportReleaseStatus {
  NOT_RELEASED = 'Pending',
  RELEASED = 'Released',
}

interface TableTeam {
  key: string; // for react
  id: string;
  name: string;
  manager: string;
  size: number;
  status: TeamReportReleaseStatus;
  sourceTeam: Team;
}

export const PageHeader = styled.div`
  padding: 2.4rem 1.6rem 2.5rem 3.2rem;
`;

export const TableContainer = styled.div`
  padding: 0 1.6rem 2rem 1.6rem;
`;

export const OverflowContainer = styled.div`
  min-width: 4.4rem;
  max-width: 4.4rem;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  overflow: visible;
`;

function apiTeamToTableTeam(apiTeam: Team): TableTeam {
  const firstManager = apiTeam.members.find((m) => m.role === TeamRoles.Manager);

  return {
    key: apiTeam.id,
    id: apiTeam.id,
    name: apiTeam.name,
    manager: firstManager ? getEmployeeFullName(firstManager.employee) : '',
    size: apiTeam.members.length,
    status: apiTeam.isReportReleased
      ? TeamReportReleaseStatus.RELEASED
      : TeamReportReleaseStatus.NOT_RELEASED,
    sourceTeam: apiTeam,
  };
}

export const TeamManagementPage: React.FC<RouteChildrenProps> = React.memo(() => {
  React.useEffect(pageChangeEffect(PAGE_TITLE), []);
  const {showModal} = useModal();
  const accountState = useSelector((state) => state.account);
  const account = accountState.account;
  assertAccountExists(account);
  const currentUser = useAccountUser();
  assertAccountUserExists(currentUser);
  const loggedInUserId = getLoggedInUserId();
  const isAccountAdmin = isAdmin(currentUser);

  if (!isAccountAdmin && !isCoachWithTeamAccess(currentUser)) {
    history.replace('/');
    return <React.Fragment />;
  }

  const [tableTeams, setTableTeams] = React.useState<TableTeam[]>();
  const [isAddTeamDrawerOpen, setAddTeamDrawerOpen] = React.useState<boolean>(false);
  const [isTeamShareDrawerOpen, setIsTeamShareDrawerOpen] = React.useState<boolean>(false);
  const [teamToShare, setTeamToShare] = React.useState<Team>();

  const refreshTableTeam = React.useCallback(
    (team: Team) => {
      if (!tableTeams) {
        return;
      }

      const updatedTeams = [...tableTeams];
      const replaceIndex = tableTeams.findIndex((t) => t.id === team.id);
      updatedTeams[replaceIndex] = apiTeamToTableTeam(team);
      setTableTeams(updatedTeams);
    },
    [tableTeams]
  );

  const releaseResults = React.useCallback(
    (team: Team) => {
      showModal(
        <ReleaseTeamResultsModal
          teamId={team.id}
          teamName={team.name}
          isReportReleased={team.isReportReleased}
          onRelease={refreshTableTeam}
        />
      );
    },
    [refreshTableTeam]
  );

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Team Name',
        accessor: 'name' as const,
        Cell: ({row}: CellProps<CellValue>) => {
          const team: Team = row.original.sourceTeam;
          return (
            <Link to={RoutePath.AdminTeamDetails.replace(':teamId', team.id)}>{team.name}</Link>
          );
        },
        width: 360,
      },
      {
        Header: 'Manager',
        accessor: 'manager' as const,
        width: 360,
      },
      {
        Header: 'Size',
        accessor: 'size' as const,
        width: 137,
      },
      {
        Header: 'Status',
        accessor: 'status' as const,
        width: 170,
      },
      {
        Header: 'Action',
        accessor: 'sourceTeam' as const,
        disableSortBy: true,
        Cell: ({value}: CellProps<CellValue>) => {
          const team: Team = value;
          const releaseId = `release-results-${team.id}`;
          const text = 'Release Results';

          return (
            isAccountAdmin && (
              <ActionsContainer>
                {!team.isReportReleased && (
                  <EmployeeActionIcon
                    id={releaseId}
                    tooltipText={text}
                    icon={icons.sendRoundedIcon}
                    disabled={team.members.length < MIN_NUM_MEMBERS}
                    onClick={() => {
                      releaseResults(team);
                    }}
                  />
                )}
              </ActionsContainer>
            )
          );
        },
        width: 117,
      },
      {
        Header: '',
        accessor: 'overflow' as const,
        disableSortBy: true,
        dataType: TableColumnDataType.OverflowMenu,
        Cell: ({row}: CellProps<CellValue>) => {
          const team: Team = row.original.sourceTeam;

          const viewRoster = React.useCallback(() => {
            history.push(RoutePath.AdminTeamDetails.replace(':teamId', team.id));
          }, []);

          const viewTeamReport = React.useCallback(() => {
            history.push(`${RoutePath.TeamInsights}?teamId=${team.id}`);
          }, []);
          const openTeamShareDrawer = React.useCallback(async () => {
            setIsTeamShareDrawerOpen(true);
            setTeamToShare(team);
          }, []);

          const HOVER_STYLE = true;
          const hasMinMembers = team.members.length >= MIN_NUM_MEMBERS;

          const menuItems: MenuLinkItem[] = [
            {
              id: 'view-roster',
              icon: icons.team,
              text: 'View Roster',
              hoverStyle: HOVER_STYLE,
              action: viewRoster,
            },
          ];

          if (hasMinMembers) {
            menuItems.push({
              id: 'view-team-report',
              icon: icons.bookIcon,
              text: 'View Team Report',
              hoverStyle: HOVER_STYLE,
              action: viewTeamReport,
            });

            if (isAccountAdmin) {
              menuItems.push({
                id: 'share-team-report',
                icon: icons.shareIcon,
                text: 'Share Team Report',
                hoverStyle: HOVER_STYLE,
                action: openTeamShareDrawer,
              });

              menuItems.push({
                id: 'release-team-report',
                icon: icons.sendIcon,
                text: 'Release Team Report',
                hoverStyle: HOVER_STYLE,
                action: () => {
                  releaseResults(team);
                },
              });
            }
          }

          return menuItems.length > 0 ? (
            <ModalProvider>
              <OverflowContainer>
                <Dropdown
                  position={DropdownPosition.AlignBottomRight}
                  renderButton={(onClick) => (
                    <IconButton
                      icon={icons.kebabMenu}
                      buttonLabel="Overflow Menu"
                      data-qa-button="team-overflow-menu"
                      onClick={onClick}
                      variant={IconButtonType.NAV}
                    />
                  )}
                >
                  <OverflowMenu hideOnMobile={false} menuItems={menuItems} />
                </Dropdown>
              </OverflowContainer>
            </ModalProvider>
          ) : (
            <></>
          );
        },
        width: 60,
      },
    ];
  }, [tableTeams]);

  React.useEffect(() => {
    const fetchTeams = isCoachWithTeamAccess(currentUser)
      ? dvpApi.getCoachTeams({accountId: account.id, authUserId: loggedInUserId})
      : dvpApi.getAccountTeams({accountId: account.id});

    fetchTeams.then((teamsResponse) => {
      const teams = teamsResponse.data.map(apiTeamToTableTeam);
      setTableTeams(teams);
    });
  }, [account.id]);

  if (tableTeams === undefined) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <PageHeader>
        <Heading css={[displayXsMedium]}>Team Management</Heading>
      </PageHeader>
      <TableContainer>
        <TableActionsContainer>
          <TableActionButtonsContainer>
            {isAccountAdmin && (
              <Button
                data-qa-button="Add Team"
                buttonType={ButtonType.Tertiary}
                icon={icons.teamAdmin}
                onClick={() => {
                  setAddTeamDrawerOpen(true);
                }}
              >
                Add Team
              </Button>
            )}
          </TableActionButtonsContainer>
        </TableActionsContainer>
        <Table columns={columns} data={tableTeams} size={TableSize.Medium} />
        {tableTeams && tableTeams.length === 0 && (
          <NoItemsView icon={icons.team}>
            Add teams by clicking the “Add team” button in the top right corner.
          </NoItemsView>
        )}
      </TableContainer>
      <AddEditTeamDrawerForm
        mode={AddEditTeamMode.Add}
        isOpen={isAddTeamDrawerOpen}
        onClose={() => {
          setAddTeamDrawerOpen(false);
        }}
        onSuccess={(newTeam) => {
          setTableTeams([...tableTeams, apiTeamToTableTeam(newTeam)]);
        }}
      />
      {teamToShare && (
        <ShareTeamReportDrawerForm
          isOpen={isTeamShareDrawerOpen}
          onClose={() => {
            setIsTeamShareDrawerOpen(false);
          }}
          teamToShare={teamToShare}
        />
      )}
    </div>
  );
});
