import ExtendableError from 'ts-error';
// This can possibly be moved to wnd-util, but need to think about its relation to HttpError
export interface WndError {
  name: string;
  message: string;
  isUserFacing?: boolean;
  stack?: string;
  code?: number; // A wonderlic defined code
  statusCode?: number; // HTTP status code, if applicable
}

export class MissingTokenError extends ExtendableError {
  static code = 1000;
  code = MissingTokenError.code;
}

export class AccountNotFoundError extends ExtendableError {
  static code = 1404;
  code = AccountNotFoundError.code;
}

export class CannotAccessContentManagerError extends ExtendableError implements WndError {
  static code = 2000;
  code = CannotAccessContentManagerError.code;
}

export class EmployeeNotFoundError extends ExtendableError {
  static code = 2404;
  code = EmployeeNotFoundError.code;
}

export class EmployeeProfileNotResolvedError extends ExtendableError {
  static code = 2405;
  code = EmployeeProfileNotResolvedError.code;
}

export class EmployeeHasNoActiveResultsError extends ExtendableError {
  static code = 2406;
  code = EmployeeHasNoActiveResultsError.code;
}

export class EmployeeArchivedError extends ExtendableError {
  static code = 2407;
  code = EmployeeArchivedError.code;
}

export class AccountCancelledError extends ExtendableError {
  static code = 3000;
  code = AccountCancelledError.code;
}

export class AccountOnHoldPastDueError extends ExtendableError {
  static code = 3001;
  code = AccountOnHoldPastDueError.code;
}

export class AccountHasNoPlanError extends ExtendableError {
  static code = 3002;
  code = AccountHasNoPlanError.code;
}

export class AccountOnHoldFailedBillingError extends ExtendableError {
  static code = 3003;
  code = AccountOnHoldFailedBillingError.code;
}

export class UserHasNoDVPAccessError extends ExtendableError {
  static code = 3004;
  code = UserHasNoDVPAccessError.code;
}

export class NotAdminUserError extends ExtendableError {
  static code = 3403;
  code = NotAdminUserError.code;
}

export class ActionItemsRetrievalError extends ExtendableError {
  static code = 4000;
  code = ActionItemsRetrievalError.code;
}

export class MissingAttributeError extends ExtendableError {
  static code = 5000;
  code = MissingAttributeError.code;
}

export class TeamNotFoundError extends ExtendableError {
  static code = 6404;
  code = TeamNotFoundError.code;
}
