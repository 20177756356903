import React from 'react';
import styled from '@emotion/styled';
import {Callout} from '>shared/components/book/content/callout';
import {Flagpole} from '>shared/components/book/content/flagpole';
import {SubsectionHeading} from '>shared/components/book/content/subsectionHeading';
import {ContentHeading} from '>shared/components/book/content/contentHeading';
import {
  Anchor,
  BulletList,
  Caption,
  Emphasis,
  ListItem,
  NumberedList,
  Paragraph,
  Strong,
} from '>shared/components/typography/baseTypography2';
import {SharedComponents, ViewType} from 'wnd-dvp-reports';
import {Image} from '>shared/components/media/image';
import {Video} from '>shared/components/media/video';
import {LoadingScreen} from '>shared/components/loadingScreen';

export const PrimaryContent = styled(Paragraph)`
  grid-column-start: 1;
`;

export const SidebarContent = styled(Caption)`
  grid-column-start: 2;
`;

export const sharedComponents: SharedComponents = {
  viewType: ViewType.Web,
  Paragraph,
  Subsection: React.Fragment,
  SubsectionHeading,
  ContentHeading,
  PrimaryContent,
  SidebarContent,
  Callout,
  Flagpole,
  OrderedList: NumberedList,
  UnorderedList: BulletList,
  ListItem,
  Strong,
  Emphasis,
  Anchor,
  Image,
  Video,
  LoadingScreen,
};
