"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generatePrefixed = generatePrefixed;
const prefixMap = new Map();
const initialValue = 0;
function generatePrefixed(prefix) {
  let currentCount = prefixMap.get(prefix);
  if (currentCount === undefined) {
    prefixMap.set(prefix, initialValue);
    currentCount = initialValue;
  }
  const nextCount = currentCount + 1;
  prefixMap.set(prefix, nextCount);
  return `${prefix}-${nextCount}`;
}