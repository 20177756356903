"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderBranding = exports.Header = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _header = require("./header.styles");
var _icons = require("../../icon/icons");
var _link = require("../../actions/link");
var _headerSearch = require("../../headerSearch/headerSearch");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const Header = ({
  brandingLogo,
  homePath,
  items,
  productName,
  showSearch,
  searchDestinationUrl
}) => {
  const isSelect = productName === process.env.SELECTION_PRODUCT_NAME;
  const Branding = renderBranding(brandingLogo, homePath, productName, isSelect);
  const onSearch = React.useCallback(inputValue => {
    if (inputValue) {
      window.location.assign(searchDestinationUrl ?? `${process.env.WEBUI_WONSCORE_URL}/search-results?query=${inputValue}` // TODO replace with webui-select URL when search results page is created (ticket TBD)
      );
    }
  }, []);
  return (0, _jsxRuntime.jsx)(_header.HeaderContainer, {
    children: (0, _jsxRuntime.jsx)(_header.TopHeaderContainer, {
      children: (0, _jsxRuntime.jsx)(_header.Gutter, {
        children: (0, _jsxRuntime.jsxs)(_header.TopHeader, {
          children: [Branding, (0, _jsxRuntime.jsxs)(_header.ItemsContainer, {
            children: [showSearch && (0, _jsxRuntime.jsx)(_headerSearch.HeaderSearch, {
              searchAction: onSearch
            }), items && items.map(actionItem => (0, _jsxRuntime.jsx)(_header.ActionItem, {
              children: actionItem.component
            }, actionItem.id))]
          })]
        })
      })
    })
  });
};
exports.Header = Header;
const renderBranding = (brandingLogo, homePath, productName, isSelect) => {
  return (0, _jsxRuntime.jsxs)(_header.FlexContainer, {
    hideOnMobile: !isSelect,
    children: [(0, _jsxRuntime.jsx)(_header.LogoContainer, {
      children: brandingLogo ? brandingLogo : (0, _jsxRuntime.jsx)(_link.ExternalLink, {
        href: homePath ?? '/',
        "aria-label": "Home",
        "data-qa-link": "headerLogo",
        children: (0, _jsxRuntime.jsx)(_header.HeaderLogo, {
          src: _icons.wonscoreHeaderLogoMobile
        })
      })
    }), productName && (0, _jsxRuntime.jsx)(_header.ProductName, {
      hideOnMobile: isSelect,
      children: productName
    })]
  });
};
exports.renderBranding = renderBranding;
Header.displayName = 'Header';