"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Image = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
const Image = ({
  src,
  alt,
  style
}) => {
  return (0, _jsxRuntime.jsx)("img", {
    src: src,
    alt: alt,
    style: style
  });
};
exports.Image = Image;