import styled from '@emotion/styled';
import {css} from '@emotion/react';
import * as colors from 'wnd-themes/lib/colorPalette';

export const tagStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.6rem;
  margin: 0 0.8rem 0.4rem 0;
  background: ${colors.char00};
  color: ${colors.char400};
  border-radius: 0.4rem;
`;

export const Tag = styled.div`
  ${tagStyle}
`;
