import {store} from '>root/store/main';
import {refreshIntercomPage} from '>root/store/actions/vendors';

export function pageChangeEffect(pageTitle: string) {
  return function() {
    document.title = pageTitle;
    if (process.env.INTERCOM_ENABLED) {
      store.dispatch(refreshIntercomPage());
    }
  };
}
