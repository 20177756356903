import {
  BulkEmployeeSelection,
  Employee,
  NewEmployee,
  Team,
  UpdateEmployee,
} from '>generated/dvp.types';
import {assertAccountExists, assertEmployeeExists} from '>lib/assert';
import {dvpApi} from '>root/apis';
import * as dvpTypes from '>root/generated/dvp.types';
import {createAction} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';
import {createAppThunk} from '../thunk';
import {getEmployeeMetrics} from './account';

export const quickAddEmployee = createAppThunk(
  '@wnd/employees/quickAdd',
  async (newEmployee: NewEmployee, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);
    // Should this be a required on the NewEmployee type if the accountId is a required param?
    newEmployee.accountId = accountState.account.id;

    const response = await dvpApi.quickImportEmployee(newEmployee, {
      accountId: accountState.account.id,
    });

    const getEmployeeMetricsAction = await thunkApi.dispatch(getEmployeeMetrics());
    if (getEmployeeMetrics.rejected.match(getEmployeeMetricsAction)) {
      throw getEmployeeMetricsAction.payload;
    }

    return response.data;
  }
);

export const updateEmployee = createAppThunk(
  '@wnd/employees/update',
  async ({employee, updatedEmployee}: {employee: Employee; updatedEmployee: UpdateEmployee}, _) => {
    const response = await dvpApi.updateEmployee(updatedEmployee, {
      accountId: employee.accountId,
      employeeId: employee.id,
      ephemeralEmployeeId: employee.ephemeralId,
    });

    return response.data;
  }
);

export const archiveEmployee = createAppThunk(
  '@wnd/employees/archive',
  async (employee: Employee, _) => {
    const response = await dvpApi.archiveEmployee({
      accountId: employee.accountId,
      employeeId: employee.id,
    });

    return response.data;
  }
);

export const unArchiveEmployee = createAppThunk(
  '@wnd/employees/unarchive',
  async (employee: Employee, _) => {
    const response = await dvpApi.unArchiveEmployee({
      accountId: employee.accountId,
      employeeId: employee.id,
    });

    return response.data;
  }
);

export const bulkArchiveEmployees = createAppThunk(
  '@wnd/employees/bulkArchive',
  async (bulkEmployeeSelection: BulkEmployeeSelection, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);

    const response = await dvpApi.bulkArchiveEmployees(bulkEmployeeSelection, {
      accountId: accountState.account.id,
    });

    return response.data;
  }
);

export const bulkUnArchiveEmployees = createAppThunk(
  '@wnd/employees/bulkUnArchive',
  async (bulkEmployeeSelection: BulkEmployeeSelection, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);

    const response = await dvpApi.bulkUnArchiveEmployees(bulkEmployeeSelection, {
      accountId: accountState.account.id,
    });

    return response.data;
  }
);

export const sendAssessmentInvitations = createAppThunk(
  '@wnd/employees/sendAssessmentInvitations',
  async (bulkEmployeeSelection: BulkEmployeeSelection, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);

    const response = await dvpApi.sendAssessmentInvitations(bulkEmployeeSelection, {
      accountId: accountState.account.id,
    });

    return response.data;
  }
);

export const stageBulkEmployeeImport = createAppThunk(
  '@wnd/employees/stage',
  async (file: File, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);

    const formData = new FormData();
    formData.append('file', file);

    let response;
    try {
      response = await dvpApi.stageBulkEmployeeImport(formData, {
        accountId: accountState.account.id,
      });
    } catch (err) {
      if ((err as any).status === 400) {
        return (err as any).payload;
      }

      throw err;
    }

    return response.data;
  }
);

export const commitBulkEmployeeImport = createAppThunk(
  '@wnd/employees/commit',
  async (commitId: string, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);

    let response;
    try {
      response = await dvpApi.commitBulkEmployeeImport(
        {commitId},
        {accountId: accountState.account.id}
      );
    } catch (err) {
      if ((err as any).status === 400) {
        return (err as any).payload;
      }

      throw err;
    }

    return response.data;
  }
);

export const releaseResults = createAppThunk(
  '@wnd/employee/releaseResults',
  async (bulkEmployeeSelection: BulkEmployeeSelection, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);

    const response = await dvpApi.releaseResults(bulkEmployeeSelection, {
      accountId: accountState.account.id,
    });

    return response.data;
  }
);

export const impersonateEmployee = createAppThunk(
  '@wnd/employees/impersonate',
  async (employeeId: string, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);

    const accountId = accountState.account.id;

    const [employeeResponse, teamsResponse] = await Promise.all([
      dvpApi.getEmployeeById({accountId, employeeId}),
      dvpApi.getEmployeeTeams({accountId, employeeId}),
    ]);

    const employee: Employee = employeeResponse.data;
    const teams: Team[] = teamsResponse.data;

    return {
      employee: {
        isImpersonated: true,
        ...employee,
      },
      teams,
    };
  }
);

export const getIndexMeasures = createAppThunk(
  '@wnd/employees/getIndexMeasures',
  async (employeeId: string, thunkApi) => {
    const {account: accountState} = thunkApi.getState();
    assertAccountExists(accountState.account);

    const response = await dvpApi.getEmployeeIndexMeasures({
      accountId: accountState.account.id,
      employeeId,
    });

    return response.data;
  }
);

export const getHomePageContent = createAppThunk(
  '@wnd/employees/getHomePageContent',
  async (_, thunkApi) => {
    const {account: accountState, employee: employeeState} = thunkApi.getState();
    assertAccountExists(accountState.account);
    assertEmployeeExists(employeeState.employee);

    const [{data: spotlightMeasures}, {data: homePageItems}] = await Promise.all([
      dvpApi.getEmployeeSpotlightMeasures({
        accountId: accountState.account.id,
        employeeId: employeeState.employee.id,
      }),
      dvpApi.listRenderedHomePageItems({
        accountId: accountState.account.id,
        employeeId: employeeState.employee.id,
      }),
    ]);

    return {
      spotlightMeasures,
      homePageItems,
    };
  }
);

export const updatePendingOnboardingProgress = createAction<dvpTypes.UserData>(
  '@wnd/employees/updatePendingOnboardingProgress'
);

export const updateOnboardingProgress = createAppThunk(
  '@wnd/employees/updateOnboardingProgress',
  async (
    {isCompleted, sectionCompleted}: {isCompleted?: boolean; sectionCompleted?: string},
    thunkApi
  ) => {
    const {employee: employeeState, account: accountState} = thunkApi.getState();

    assertEmployeeExists(employeeState.employee);
    assertAccountExists(accountState.account);

    const currentUserData: dvpTypes.UserData = employeeState.employee.userData ?? {};
    const nextUserData = cloneDeep(currentUserData);

    nextUserData.onboardingProgress = {
      ...(currentUserData.onboardingProgress ?? {isCompleted: isCompleted ?? false}),
      ...(isCompleted ? {isCompleted} : {}),
      finishedSections: [
        ...(currentUserData.onboardingProgress?.finishedSections ?? []),
        ...(sectionCompleted ? [sectionCompleted] : []),
      ],
    };

    thunkApi.dispatch(updatePendingOnboardingProgress(nextUserData));

    if (employeeState.employee.isImpersonated) {
      // Don't update progress if impersonated
      return;
    }

    const {data} = await dvpApi.updateUserData(currentUserData, nextUserData, {
      employeeId: employeeState.employee.id,
      accountId: accountState.account.id,
    });

    return data;
  }
);
