"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SideBarToggle = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var _icons = require("../../icon/icons");
var _sideBarToggle = require("./sideBarToggle.styles");
const SideBarToggle = ({
  onToggle,
  isSideBarExpanded
}) => {
  return (0, _jsxRuntime.jsx)(_sideBarToggle.ToggleContainer, {
    children: (0, _jsxRuntime.jsxs)(_sideBarToggle.ToggleLabel, {
      children: [(0, _jsxRuntime.jsx)(_sideBarToggle.HiddenInput, {
        type: "checkbox",
        onChange: onToggle,
        "aria-controls": "sidebar",
        checked: isSideBarExpanded
      }), (0, _jsxRuntime.jsxs)(_sideBarToggle.IconContainer, {
        children: [(0, _jsxRuntime.jsx)(_sideBarToggle.SideBarToggleIcon, {
          src: _icons.hamburgerIcon,
          className: "hamburger-icon"
        }), (0, _jsxRuntime.jsx)(_sideBarToggle.SideBarToggleIcon, {
          src: _icons.arrowBackIcon,
          className: "arrow-back-icon"
        })]
      })]
    })
  });
};
exports.SideBarToggle = SideBarToggle;