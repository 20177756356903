import {AccountUser, User} from '>generated/dvp.types';
import {ProductUserWithCompanyData} from '>generated/unifiedAccount.types';
import {createReducer} from '@reduxjs/toolkit';
import {getMyDvpUser, getMyProductUser} from '../actions/user';

export interface UserState {
  productUser?: ProductUserWithCompanyData;
  accountUser?: AccountUser;
}

const DEFAULT_STATE: Readonly<UserState> = {};

function updateProductUser(
  state: UserState,
  {payload}: {payload: ProductUserWithCompanyData}
): UserState {
  return {
    ...state,
    productUser: payload,
  };
}

function clearProductUser(state: UserState): UserState {
  const newState = {...state};
  delete newState.productUser;
  return newState;
}

function updateAccountUser(state: UserState, {payload}: {payload: User}): UserState {
  return {
    ...state,
    accountUser: payload.accountUser,
  };
}

function clearAccountUser(state: UserState): UserState {
  const newState = {...state};
  delete newState.accountUser;
  return newState;
}

export const userReducer = createReducer<UserState>(DEFAULT_STATE, (builder) => {
  builder
    .addCase(getMyProductUser.fulfilled, updateProductUser)
    .addCase(getMyProductUser.rejected, clearProductUser)
    .addCase(getMyDvpUser.fulfilled, updateAccountUser)
    .addCase(getMyDvpUser.rejected, clearAccountUser);
});
