import {createReducer} from '@reduxjs/toolkit';
import {BucketMapping, ConstructDomain} from 'wnd-dvp-reports';
import {getInterpretationBuckets} from '../actions/interpretationBuckets';

export interface InterpretationBucketsState {
  bucketMapping: BucketMapping;
}

const DEFAULT_STATE: Readonly<InterpretationBucketsState> = {
  bucketMapping: {
    [ConstructDomain.Cognitive]: [0],
    [ConstructDomain.Competency]: [0],
    [ConstructDomain.Interest]: [0],
    [ConstructDomain.Personality]: [0],
  },
};

export const interpretationBucketReducer = createReducer<InterpretationBucketsState>(
  DEFAULT_STATE,
  (builder) => {
    builder.addCase(getInterpretationBuckets.fulfilled, (state, {payload}) => {
      return {
        ...state,
        bucketMapping: payload.buckets,
      };
    });
  }
);
