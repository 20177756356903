import * as React from 'react';
import {useForm} from 'react-hook-form';
import {ActionType} from '>shared/components/modal/modal';
import {AlertModal, AlertModalVariants} from '>shared/components/modal/alertModal';
import {useModal} from '>shared/components/modal/useModal';
import {AccountUserWithPII} from '>generated/dvp.types';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {useAppDispatch} from '>root/store/main';
import {useAsyncSubmit} from '>shared/components/form/formBuilder';
import {deleteUser} from '>root/store/actions/account';
import {ButtonType} from '>shared/components/button/button';

interface RemoveUserModalProps {
  accountId: string;
  authUserId: string;
  userName: string;
  email: string;
  isPending: boolean;
}

export const RemoveUserModal: React.FC<RemoveUserModalProps> = ({
  accountId,
  authUserId,
  userName,
  email,
  isPending,
}) => {
  const {hideModal} = useModal();
  const [isSaving, setIsSaving] = React.useState(false);
  const dispatch = useAppDispatch();
  const {showSuccessAlert, showErrorAlert} = useSnackbar();

  const formMethods = useForm<AccountUserWithPII>();

  const handleDelete = React.useCallback(
    () => async () => {
      setIsSaving(true);
      const response = await dispatch(deleteUser({accountId, authUserId}));
      if (deleteUser.rejected.match(response)) {
        showErrorAlert('Something went wrong. Please try again.');
      } else {
        const snackbarMessage = `Success! User ${email} removed.`;
        showSuccessAlert(snackbarMessage);
      }
      setIsSaving(false);

      hideModal();
    },
    [authUserId]
  );

  return (
    <AlertModal
      title={'Remove User?'}
      subtitle={`This will permanently remove ${
        isPending ? email : userName
      } as a user from your account.`}
      variant={AlertModalVariants.Delete}
      actions={[
        {
          type: ActionType.Primary,
          label: 'Remove',
          onClick: useAsyncSubmit(formMethods, handleDelete(), [authUserId]),
          inputType: 'submit',
          qaDataAttribute: 'remove-user-button',
          disabled: isSaving,
          buttonType: ButtonType.SuperDestructive,
        },
        {
          type: ActionType.Secondary,
          label: 'Cancel',
          onClick: hideModal,
          qaDataAttribute: 'cancel-remove-user-button',
          disabled: isSaving,
        },
      ]}
    />
  );
};
