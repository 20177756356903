import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useUIDSeed} from 'react-uid';
import styled from '@emotion/styled';

import {
  DrawerFormActions,
  DrawerFormContainer,
  DrawerFormHeader,
  DrawerFormSecondaryAction,
  Subtitle,
  XButton,
} from '>shared/components/drawer/drawerForm.styles';
import {
  FormBuilder,
  FormBuilderFields,
  FormBuilderFieldType,
  useAsyncSubmit,
} from '>shared/components/form/formBuilder';
import {BannerType, InfoBanner} from '>shared/components/banners/infoBanner';
import {Button, ButtonType} from '>shared/components/button/button';
import {CheckBox} from '>shared/components/form/checkBox';
import {ControlledSelect} from '>shared/components/form/select';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';
import {vr2, vr4} from '>shared/styles/mixins/verticalRhythm';
import {roundedCloseIcon} from '>shared/components/icon/icons';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {Input} from '>shared/components/form/input';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';

import {assertValidAuthState} from '>lib/assert';
import {rollbarLogger} from '>lib/logger';
import {TableUser} from '>root/pages/adminUserManagement/userManagementPage';
import {unifiedAccountApi} from '>root/apis';
import {updateUser, UserUpdateActionParams} from '>root/store/actions/account';
import {useAsyncDispatch} from '>root/store/main';
import {UserRole} from '>generated/dvp.types';
import {WndError} from '>root/errors';

interface EditUserFormProps {
  onClose: () => void;
  initialRoleEditedUser: UserRole;
  roleLoggedInUser: UserRole;
  roleOptions: Option[];
  user: TableUser;
}
interface UpdateUser {
  role: UserRole;
  canManageBilling: boolean;
}

function getRoleDescription(role: UserRole): string {
  switch (role) {
    case UserRole.Owner:
      return '';
    case UserRole.AccountAdmin:
      return ACCOUNT_ADMIN_ROLE_DESCRIPTION;
    case UserRole.TeamAdmin:
      return TEAM_ADMIN_ROLE_DESCRIPTION;
    case UserRole.Coach:
      return COACH_ROLE_DESCRIPTION;
    default:
      return '';
  }
}

const ACCOUNT_ADMIN_ROLE_DESCRIPTION = 'This role has access to all employee data';
const TEAM_ADMIN_ROLE_DESCRIPTION = 'This role has access to all employee data';
const COACH_ROLE_DESCRIPTION = 'This role has access to shared results only';

export const EditUserForm: React.FC<EditUserFormProps> = ({
  onClose,
  roleLoggedInUser,
  roleOptions,
  user,
}) => {
  const seed = useUIDSeed();
  const authState = useSelector((state) => state.auth);
  assertValidAuthState(authState);

  const authUserIdLoggedInUser = authState.token.user.id;
  const {accountId, authUserId, firstName, lastName, email, role: initialRoleEditedUser} = user;

  const {showSuccessAlert} = useSnackbar();
  const asyncDispatch = useAsyncDispatch();

  const formMethods = useForm<UpdateUser>({
    mode: 'all',
    defaultValues: {
      role: user.role,
    },
  });
  const {formState} = formMethods;
  const [hintText, setHintText] = React.useState<string>(getRoleDescription(initialRoleEditedUser));

  const [canManageBilling, setCanManageBilling] = React.useState(false);
  const [isManageBillingEnabled, setIsManageBillingEnabled] = React.useState(
    canChangeCanManageBilling(initialRoleEditedUser)
  );
  function canChangeCanManageBilling(currentlySetRole: UserRole): boolean {
    if (roleLoggedInUser !== UserRole.Owner) {
      return false;
    }

    return currentlySetRole === UserRole.AccountAdmin;
  }

  React.useEffect(() => {
    setIsManageBillingEnabled(canChangeCanManageBilling(initialRoleEditedUser));
    setHintText(getRoleDescription(initialRoleEditedUser));
  }, [initialRoleEditedUser]);

  React.useEffect(() => {
    unifiedAccountApi.getProductUserByAuthUserId({authUserId}).then(({data: productUser}) => {
      Object.keys(productUser.unifiedAccounts).find((unifiedAccountId) => {
        setCanManageBilling(
          Boolean(productUser.unifiedAccounts[unifiedAccountId].permissions.canManageBilling)
        );
      });
    });
  }, [authUserId]);

  // Manage role changes
  const roleCannotBeChangedText = React.useMemo((): string => {
    if (roleLoggedInUser !== UserRole.Owner) {
      return 'Only account owners can modify roles';
    } else if (initialRoleEditedUser !== UserRole.AccountAdmin) {
      return `This user's role cannot be modified`;
    } else {
      return '';
    }
  }, [initialRoleEditedUser, roleLoggedInUser]);

  const onRoleChange = React.useCallback(
    (newRole: UserRole) => {
      setHintText(getRoleDescription(newRole));

      if (newRole === UserRole.Owner) {
        setCanManageBilling(true);
        setIsManageBillingEnabled(false);
      } else {
        setIsManageBillingEnabled(canChangeCanManageBilling(newRole));
      }
    },
    [formMethods]
  );

  function isTransferringOwnership() {
    return (
      roleLoggedInUser === UserRole.Owner &&
      authUserId !== authUserIdLoggedInUser &&
      formMethods.getValues().role === UserRole.Owner
    );
  }

  const closeAndReset = React.useCallback(() => {
    formMethods.reset();
    setHintText('');
    onClose();
    setIsManageBillingEnabled(canChangeCanManageBilling(initialRoleEditedUser));
  }, [onClose, initialRoleEditedUser]);

  const submitForm = useAsyncSubmit(
    formMethods,
    async (data, _event, _setFormError) => {
      const {role} = data;

      const userUpdateParams: UserUpdateActionParams = {
        accountId,
        authUserId,
        permissions: {canManageBilling},
      };

      const willRoleChange = role !== initialRoleEditedUser;

      // Only pass to the API if it changed
      if (willRoleChange) {
        userUpdateParams.role = role;
      }

      try {
        await asyncDispatch(updateUser(userUpdateParams));

        if (willRoleChange && role === UserRole.Owner) {
          showSuccessAlert(`${firstName} ${lastName} has been assigned owner successfully`);
        } else {
          showSuccessAlert(`${firstName} ${lastName} has been updated successfully`);
        }
        closeAndReset();
      } catch (err) {
        const error = err as WndError;
        rollbarLogger.error(error);

        if (error.statusCode === 409) {
          _setFormError(`${email} already owns a Develop account and cannot own another.`);
        } else {
          throw new Error(error.message);
        }
      }
    },
    [canManageBilling, initialRoleEditedUser]
  );

  const userFormFields: FormBuilderFields = [
    {
      type: FormBuilderFieldType.Input,
      label: 'First Name',
      name: 'firstName',
      component: <Input value={firstName} disabled />,
    },
    {
      type: FormBuilderFieldType.Input,
      label: 'Last Name',
      name: 'lastName',
      component: <Input value={lastName} disabled />,
    },
    {
      type: FormBuilderFieldType.Input,
      label: 'Email',
      name: 'email',
      component: <Input value={email} disabled />,
    },
    {
      type: FormBuilderFieldType.Input,
      label: 'Role',
      name: 'role',
      component: (
        <StyledSelect
          disabled={Boolean(roleCannotBeChangedText.length)}
          tooltip={roleCannotBeChangedText ?? undefined}
          helperText={hintText}
          options={roleOptions}
          onSelectChange={onRoleChange}
          required
          rules={{required: 'Role is required'}}
        />
      ),
    },
  ];

  const formFields = React.useMemo(() => {
    const formBuilderFields: FormBuilderFields = [
      {
        type: FormBuilderFieldType.Group,
        fields: userFormFields,
      },
    ];

    if (isManageBillingEnabled) {
      const id = seed('checkbox-billing');

      formBuilderFields.push({
        type: FormBuilderFieldType.Group,
        fields: [
          {
            type: FormBuilderFieldType.Custom,
            name: 'billingAccess',
            component: (
              <CheckBox
                label="Allow billing access"
                id={id}
                checked={canManageBilling}
                onChange={() => {
                  if (formMethods.getValues().role === UserRole.Owner) {
                    setCanManageBilling(true);
                  } else {
                    setCanManageBilling(!canManageBilling);
                  }
                }}
              />
            ),
          },
        ],
        label: 'Permission Settings',
      });
    }

    return formBuilderFields;
  }, [isManageBillingEnabled, userFormFields]);

  return (
    <FormProvider {...formMethods}>
      <DrawerFormContainer>
        <DrawerFormHeader css={[vr2]}>
          <Heading css={[displayXsMedium]}>{'Edit User'}</Heading>
          <XButton
            buttonLabel="Dismiss Drawer"
            data-qa-button="dismissDrawer"
            onClick={closeAndReset}
            icon={roundedCloseIcon}
          />
        </DrawerFormHeader>
        <Subtitle css={[vr4]}>{`Manage a user's role and permission settings`}</Subtitle>
        <FormBuilder fields={formFields} showErrorSummary={false} />
        {isTransferringOwnership() && (
          <InfoBanner
            bannerType={BannerType.Warning}
            message={'Transferring account ownership will remove these permissions from you'}
          />
        )}
        <DrawerFormActions>
          <DrawerFormSecondaryAction>
            <Button
              buttonType={ButtonType.Neutral}
              disabled={formState.isSubmitting}
              onClick={closeAndReset}
              data-qa-button="cancel-edit-user-button"
            >
              {'Cancel'}
            </Button>
          </DrawerFormSecondaryAction>
          <Button
            data-qa-button="save-edit-user-button"
            disabled={formState.isSubmitting || Boolean(roleCannotBeChangedText.length)}
            onClick={submitForm}
            type="submit"
          >
            {'Save'}
          </Button>
        </DrawerFormActions>
      </DrawerFormContainer>
    </FormProvider>
  );
};

const StyledSelect = styled(ControlledSelect)`
  text-align: left;
`;
