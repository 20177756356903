import * as React from 'react';
import styled from '@emotion/styled';
import {useTheme} from '@emotion/react';
import {useDispatch, useSelector} from 'react-redux';

import {RoutePath} from '>root/routes/routes';
import {history} from '>root/history';
import {getMyDvpUser} from '>root/store/actions/user';
import {assertAccountExists} from '>lib/assert';

import * as colors from 'wnd-themes/lib/colorPalette';
import {Banner} from '>shared/components/banners/banner';
import {IconButton, IconButtonType} from '>shared/components/iconButton/iconButton';
import {Icon} from '>shared/components/icon/icon';
import {roundedCloseIcon} from '>shared/components/icon/icons';
import {textMdSemibold} from '>shared/components/typography/designSystemTypography';
import {useAccountUser} from '>lib/user';

interface EmployeeBannerProps {
  name: string;
  jobTitle: string;
}

const BannerContainer = styled.div`
  position: relative;
  margin: 4.8rem 0 -0.4rem;
`;

const BannerContent = styled.div<{maxWidth: string}>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: auto;
  max-width: ${(props) => props.maxWidth};

  ${textMdSemibold};
  color: ${colors.white};
`;

const CloseButton = styled(IconButton)`
  background: transparent;
  outline: none;
  margin-right: 0.8rem;

  &:hover {
    background-color: transparent;
    border: 0.1rem solid ${colors.white};

    svg {
      fill: ${colors.white};
    }
  }

  &:focus,
  &:focus-visible {
    background-color: transparent;
    border: 0.1rem solid ${colors.white};
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: transparent;
  }
`;

export const EmployeeImpersonationBanner: React.FC<EmployeeBannerProps> = ({jobTitle, name}) => {
  const dispatch = useDispatch();
  const {layout} = useTheme();

  const accountState = useSelector((state) => state.account);
  const accountUser = useAccountUser();
  const {account} = accountState;
  assertAccountExists(account);

  const exitImpersonation = React.useCallback(() => {
    if (accountUser) {
      dispatch(getMyDvpUser(account.id));
      history.push(RoutePath.AdminEmployees);
    } else {
      window.location.reload();
    }
  }, []);

  return (
    <BannerContainer>
      <Banner backgroundColor={colors.primary600} isFixed>
        <BannerContent maxWidth={layout.maxPageWidth}>
          {name} - {jobTitle}
          <CloseButton
            buttonLabel={'Exit Employee Results'}
            onClick={exitImpersonation}
            variant={IconButtonType.NAV}
          >
            <Icon src={roundedCloseIcon} primaryColor={colors.white} />
          </CloseButton>
        </BannerContent>
      </Banner>
    </BannerContainer>
  );
};
