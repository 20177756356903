import {createAppThunk} from '../thunk';
import {dvpApi, unifiedAccountApi} from '../../apis';
import {assertExists} from 'wnd-util/lib/assert';
import {assertValidAuthState} from '>lib/assert';
import {getAccountById} from './account';
import {UserHasNoDVPAccessError} from '../../errors';

export const getMyProductUser = createAppThunk('@wnd/users/getMyProductUser', async () => {
  const {data} = await unifiedAccountApi.getMyProductUser();
  return data;
});

export const getMyDvpUser = createAppThunk(
  '@wnd/users/getMyDvpUser',
  async (accountId: string, _) => {
    const response = await dvpApi.getMyUser({accountId});

    return response.data;
  }
);

export const refreshUserContext = createAppThunk(
  '@wnd/users/refreshUserContext',
  async (_, thunkApi) => {
    const {auth: authState} = thunkApi.getState();
    assertValidAuthState(authState);

    const getMyProductUserResponse = await thunkApi.dispatch(getMyProductUser());
    if (getMyProductUser.rejected.match(getMyProductUserResponse)) {
      throw getMyProductUserResponse.payload;
    }

    const productUser = thunkApi.getState().user.productUser;
    assertExists(productUser, 'Product User must exist');

    const unifiedAccounts = productUser.unifiedAccounts;
    const selectedUnifiedAccount = unifiedAccounts[productUser.selectedUnifiedAccountId];
    assertExists(selectedUnifiedAccount, 'The selected Unified Account was not found');

    const developmentProduct = selectedUnifiedAccount.productAccounts.development;
    if (!developmentProduct) {
      throw new UserHasNoDVPAccessError(
        `AuthUserId ${productUser.authUserId} does not have access to ${process.env.DEVELOPMENT_PRODUCT_NAME} with company ${selectedUnifiedAccount.companyName}`
      );
    }

    await Promise.all([
      unifiedAccountApi.accessUserProductAccount({
        authUserId: productUser.authUserId,
        unifiedAccountId: productUser.selectedUnifiedAccountId,
        productAccountId: developmentProduct.productAccountId,
      }),
      thunkApi.dispatch(getAccountById(developmentProduct.productAccountId)),
      thunkApi.dispatch(getMyDvpUser(developmentProduct.productAccountId)),
    ]);
  }
);
