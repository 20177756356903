import * as colors from 'wnd-themes/lib/colorPalette';
import {assertExists} from 'wnd-util/lib/assert';
import {TeamReportDomain, Typology, TypologyQuadrant, getQuadrantColors} from 'wnd-dvp-reports';

import {ChartAxesLabels, QuadrantLabels, QuadrantTheme} from '>shared/components/charts/quadrant';

export function getAdjectivesForChartAxes(domain: TeamReportDomain): ChartAxesLabels {
  switch (domain) {
    case TeamReportDomain.Communication:
      return {
        xAxis: 'Courtesy',
        yAxis: 'Assertiveness',
      };
    case TeamReportDomain.Work:
      return {
        xAxis: 'Stability',
        yAxis: 'Diligence',
      };
    case TeamReportDomain.Challenge:
      return {
        xAxis: 'Ambition',
        yAxis: 'Curiosity',
      };
    default:
      return {
        xAxis: 'Ambition',
        yAxis: 'Curiosity',
      };
  }
}

export function getQuadrantLabels(typologies: Typology[]): QuadrantLabels {
  const typeI = typologies.find((t) => t.quadrant === TypologyQuadrant.TypeI);
  const typeII = typologies.find((t) => t.quadrant === TypologyQuadrant.TypeII);
  const typeIII = typologies.find((t) => t.quadrant === TypologyQuadrant.TypeIII);
  const typeIV = typologies.find((t) => t.quadrant === TypologyQuadrant.TypeIV);

  assertExists(typeI, 'Missing Type I typology');
  assertExists(typeII, 'Missing Type II typology');
  assertExists(typeIII, 'Missing Type III typology');
  assertExists(typeIV, 'Missing Type IV typology');

  return {
    TypeI: typeI.name,
    TypeII: typeII.name,
    TypeIII: typeIII.name,
    TypeIV: typeIV.name,
  };
}

export function getQuadrantTheme(typology: Typology, domain: TeamReportDomain): QuadrantTheme {
  let colors;

  if (domain === TeamReportDomain.Communication) {
    colors = communicationTheme;
  } else if (domain === TeamReportDomain.Work) {
    colors = workTheme;
  } else if (domain === TeamReportDomain.Challenge) {
    colors = challengeTheme;
  }
  assertExists(colors, 'domain colors must correspond to team report domain');

  const {quadrant1, quadrant2, quadrant3, quadrant4} = getQuadrantColors(typology, colors);

  return {
    axesLabelColor: colors.axesLabelColor,
    axesArrowColor: colors.axesArrowColor,
    quadrant1Fill: quadrant1,
    quadrant2Fill: quadrant2,
    quadrant3Fill: quadrant3,
    quadrant4Fill: quadrant4,
    svgFillColor: colors.svgFillColor,
  };
}

const workTheme = {
  quadrantDark: colors.fuschia200,
  quadrantMid: colors.fuschia100,
  quadrantLight: colors.fuschia50,
  axesLabelColor: colors.fuschia500,
  axesArrowColor: colors.fuschia300,
  svgFillColor: colors.fuschia900,
};

const communicationTheme = {
  quadrantDark: colors.indigo200,
  quadrantMid: colors.indigo100,
  quadrantLight: colors.indigo50,
  axesLabelColor: colors.indigo500,
  axesArrowColor: colors.indigo300,
  svgFillColor: colors.indigo900,
};

const challengeTheme = {
  quadrantDark: colors.cyan200,
  quadrantMid: colors.cyan100,
  quadrantLight: colors.cyan50,
  axesLabelColor: colors.cyan500,
  axesArrowColor: colors.cyan300,
  svgFillColor: colors.cyan900,
};
