"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Toolkits = exports.Products = exports.HeaderAppMenu = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var _dropdown = require("./dropdown");
var _userMenu = require("./userMenu");
var _icon = require("../icon/icon");
var _iconTypes = require("../../styles/icon-types");
var _iconButton = require("../iconButton/iconButton");
var _menu = require("./menu.styles");
var Products;
(function (Products) {
  Products["WonderlicSelect"] = "selection";
  Products["WonderlicDevelop"] = "development";
})(Products || (exports.Products = Products = {}));
var Toolkits;
(function (Toolkits) {
  Toolkits["wonscore"] = "wonscore";
  Toolkits["acquiretm"] = "acquiretm";
  Toolkits["applicantpro"] = "applicantpro";
  Toolkits["bizmerlin"] = "bizmerlin";
  Toolkits["hiringthing"] = "hiringthing";
  Toolkits["icims"] = "icims";
  Toolkits["jobma"] = "jobma";
  Toolkits["newton"] = "newton";
  Toolkits["research"] = "research";
  Toolkits["consulting"] = "consulting";
})(Toolkits || (exports.Toolkits = Toolkits = {}));
const HeaderAppMenu = ({
  menuItems,
  onOpen
}) => {
  return (0, _jsxRuntime.jsx)(_dropdown.Dropdown, {
    onOpen: onOpen,
    position: _dropdown.DropdownPosition.AlignBottomRight,
    renderButton: onClick => (0, _jsxRuntime.jsx)(_iconButton.IconButton, {
      buttonLabel: "Account Management",
      "data-qa-button": "appSwitcher",
      onClick: onClick,
      variant: _iconButton.IconButtonType.NAV,
      children: (0, _jsxRuntime.jsx)(_icon.FontIcon, {
        icon: _iconTypes.Icons.BentoMenu
      })
    }),
    children: (0, _jsxRuntime.jsx)(_userMenu.UserMenu, {
      hideOnMobile: false,
      menuHeading: (0, _jsxRuntime.jsx)(_menu.MenuItemHeading, {
        iconDivHidden: true,
        children: 'Products & Management'
      }),
      menuItems: menuItems
    })
  });
};
exports.HeaderAppMenu = HeaderAppMenu;