import * as React from 'react';
import {textEllipsis} from '>styles/mixins/textConstraints';
import {Tag} from './synonymTag.styles';
import {SynonymLabel} from '../measureCard/measureCard.styles';

interface Props {
  synonym: string;
}

export const SynonymTag: React.FC<Props> = ({synonym}) => {
  return (
    <Tag>
      <SynonymLabel css={textEllipsis}>{synonym}</SynonymLabel>
    </Tag>
  );
};
