"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AlertModalVariants = exports.AlertModal = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var _colorPalette = require("wnd-themes/lib/colorPalette");
var _icons = require("../icon/icons");
var _iconHalo = require("../icon/iconHalo");
var _icon = require("../icon/icon");
var _modal = require("./modal");
var _displayType = require("./displayType");
var AlertModalVariants;
(function (AlertModalVariants) {
  AlertModalVariants["Success"] = "Success";
  AlertModalVariants["Warning"] = "Warning";
  AlertModalVariants["Delete"] = "Delete";
  AlertModalVariants["Neutral"] = "Neutral";
  AlertModalVariants["Primary"] = "Primary";
})(AlertModalVariants || (exports.AlertModalVariants = AlertModalVariants = {}));
function getIconColors(variant) {
  switch (variant) {
    case AlertModalVariants.Success:
      return {
        borderColor: _colorPalette.success50,
        backgroundColor: _colorPalette.success100,
        iconColor: _colorPalette.success600
      };
    case AlertModalVariants.Warning:
      return {
        borderColor: _colorPalette.warning50,
        backgroundColor: _colorPalette.warning100,
        iconColor: _colorPalette.warning600
      };
    case AlertModalVariants.Delete:
      return {
        borderColor: _colorPalette.error50,
        backgroundColor: _colorPalette.error100,
        iconColor: _colorPalette.error600
      };
    case AlertModalVariants.Neutral:
      return {
        borderColor: _colorPalette.gray50,
        backgroundColor: _colorPalette.gray100,
        iconColor: _colorPalette.gray600
      };
    case AlertModalVariants.Primary:
      return {
        borderColor: _colorPalette.primary50,
        backgroundColor: _colorPalette.primary100,
        iconColor: _colorPalette.primary600
      };
  }
}
const AlertModal = ({
  actions,
  title,
  subtitle,
  variant = AlertModalVariants.Primary,
  children
}) => {
  const {
    borderColor,
    backgroundColor,
    iconColor
  } = getIconColors(variant);
  return (0, _jsxRuntime.jsx)(_modal.Modal, {
    headerOptions: {
      subtitle,
      title,
      icon: (0, _jsxRuntime.jsx)(_iconHalo.IconHalo, {
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        children: (0, _jsxRuntime.jsx)(_icon.Icon, {
          src: _icons.errorOutlineIcon,
          primaryColor: iconColor,
          hoverColor: iconColor
        })
      })
    },
    actions: actions,
    displayType: _displayType.ModalDisplayType.Alert,
    children: children
  });
};
exports.AlertModal = AlertModal;