import {
  COGNITIVE_VALUES,
  COMPETENCY_VALUES,
  ConstructDomain,
  INTEREST_VALUES,
  INTERNAL_SOURCE_CONSTRUCTS,
  PERSONALITY_VALUES,
  SourceConstruct,
} from 'wnd-dvp-reports';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {ChapterCategory} from '>generated/dvp.types';

const SourceConstructLabel = {
  [ConstructDomain.Competency]: 'Competencies',
  [ConstructDomain.Cognitive]: 'Cognitive',
  [ConstructDomain.Personality]: 'Personality',
  [ConstructDomain.Interest]: 'Interests',
};

function buildSourceConstructOptionsByDomain(domain: ConstructDomain): Option[] {
  let optionValues: string[] = [];

  switch (domain) {
    case ConstructDomain.Competency:
      optionValues = COMPETENCY_VALUES;
      break;
    case ConstructDomain.Cognitive:
      optionValues = COGNITIVE_VALUES;
      break;
    case ConstructDomain.Personality:
      optionValues = PERSONALITY_VALUES;
      break;
    case ConstructDomain.Interest:
      optionValues = INTEREST_VALUES;
      break;
  }

  const options = optionValues
    .filter((value) => !INTERNAL_SOURCE_CONSTRUCTS.includes(value as SourceConstruct))
    .map((value) => {
      return {
        label: value,
        value,
      };
    });

  return options;
}

export function getSourceConstructOptions(): Option[] {
  const competenciesOptions = buildSourceConstructOptionsByDomain(ConstructDomain.Competency);
  const cognitiveOptions = buildSourceConstructOptionsByDomain(ConstructDomain.Cognitive);
  const personalityOptions = buildSourceConstructOptionsByDomain(ConstructDomain.Personality);
  const interestsOptions = buildSourceConstructOptionsByDomain(ConstructDomain.Interest);

  return [
    {
      label: SourceConstructLabel[ConstructDomain.Competency],
      value: 'groupCompetencies',
      options: competenciesOptions,
    },
    {
      label: SourceConstructLabel[ConstructDomain.Cognitive],
      value: 'groupCognitive',
      options: cognitiveOptions,
    },
    {
      label: SourceConstructLabel[ConstructDomain.Personality],
      value: 'groupPersonality',
      options: personalityOptions,
    },
    {
      label: SourceConstructLabel[ConstructDomain.Interest],
      value: 'groupInterests',
      options: interestsOptions,
    },
  ];
}

export function getCategoryOptions(): Option[] {
  const categoryValues = Object.values(ChapterCategory);

  return categoryValues.map((category) => {
    return {
      label: category,
      value: category,
    };
  });
}
