import * as React from 'react';
import {groupBy} from 'lodash';

import {
  DrawerFormActions,
  DrawerFormContainer,
  DrawerFormHeader,
  DrawerFormSecondaryAction,
  Subtitle,
  XButton,
} from '>shared/components/drawer/drawerForm.styles';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {Drawer} from '>shared/components/drawer/drawer';
import {Button, ButtonType} from '>shared/components/button/button';
import {FileImportError} from '>generated/dvp.types';
import {FileInput} from '>shared/components/form/fileInput';
import {generatePrefixed} from '>shared/lib/uniqueString';
import {Heading} from '>shared/components/heading/heading';
import {Message, MessageType} from '>shared/components/form/message';
import {roundedCloseIcon} from '>shared/components/icon/icons';
import {rollbarLogger} from '>lib/logger';
import {vr2, vr4} from '>shared/styles/mixins/verticalRhythm';

import {dvpApi} from '>root/apis';
import {Messages, FileInputContainer} from './fileImport.styles';

enum FileImportState {
  NEVER_SELECTED_FILE,
  FILE_CHOSEN_HAS_ERRORS,
  FILE_CHOSEN_SUCCESSFULLY,
  COMMIT_HAS_ERRORS,
}

function getUploadButtonText(state: FileImportState): string {
  switch (state) {
    case FileImportState.FILE_CHOSEN_HAS_ERRORS:
    case FileImportState.COMMIT_HAS_ERRORS:
      return 'Re-upload';
    default:
      return 'Upload';
  }
}
function isFileValid(filePath?: string): boolean {
  return (
    Boolean(filePath) && Boolean(filePath?.endsWith('.xlsx') || Boolean(filePath?.endsWith('.xls')))
  );
}

function groupErrorMessagesByColumn(errors: FileImportError[]): string[] {
  const MAX_ERRORS_PER_COLUMN = 5;
  const NO_COLUMN_NAME = 'undefined';
  const errorMessages: string[] = [];
  const groupedErrors = groupBy(errors, 'columnName');

  Object.keys(groupedErrors).forEach((columnName) => {
    const numErrors = groupedErrors[columnName].length;
    if (numErrors > MAX_ERRORS_PER_COLUMN && columnName !== NO_COLUMN_NAME) {
      errorMessages.push(`${numErrors} rows have an invalid ${columnName}`);
    } else {
      const messagesForColumn = groupedErrors[columnName].map((group) => group.userFacingMessage);
      errorMessages.push(...messagesForColumn);
    }
  });

  return errorMessages;
}

interface ActionItemsImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImport?: () => void;
}

export const ActionItemsImportModal: React.FC<ActionItemsImportModalProps> = ({
  isOpen,
  onClose,
  onImport,
}) => {
  const [state, setState] = React.useState(FileImportState.NEVER_SELECTED_FILE);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const [successMessages, setSuccessMessages] = React.useState<string[]>([]);
  const [fileToUpload, setFileToUpload] = React.useState(null);
  const [fileInputKey, setFileInputKey] = React.useState(generatePrefixed('fileInputKey'));

  const clearMessages = React.useCallback(() => {
    setErrorMessages([]);
    setSuccessMessages([]);
  }, []);

  const closeAndResetModal = React.useCallback(() => {
    setState(FileImportState.NEVER_SELECTED_FILE);
    clearMessages();
    setFileInputKey(generatePrefixed('fileInputKey'));
    onClose();
  }, []);

  const handleFileSelection = React.useCallback(({filePath, file}) => {
    if (isFileValid(filePath)) {
      clearMessages();
      setFileToUpload(file);
      setState(FileImportState.FILE_CHOSEN_SUCCESSFULLY);
    } else {
      // invalid file
      setState(FileImportState.FILE_CHOSEN_HAS_ERRORS);
      const errorMessages = [];

      if (!isFileValid(filePath)) {
        errorMessages.push(
          'The file type is invalid. It must be an excel file with extension .xlsx or .xls'
        );
      }

      setErrorMessages(errorMessages);
    }
  }, []);

  const handleCommit = React.useCallback(async () => {
    clearMessages();
    if (fileToUpload) {
      let response;
      try {
        const formData = new FormData();
        formData.append('file', fileToUpload);
        response = await dvpApi.actionItemTemplateImport(formData);
      } catch (err) {
        if ((err as any).status === 400) {
          response = (err as any).payload;
        } else {
          setState(FileImportState.COMMIT_HAS_ERRORS);
          setErrorMessages(['There was an error uploading this file.']);
          return rollbarLogger.error(err as Error);
        }
      }

      if (response.errors) {
        setErrorMessages(groupErrorMessagesByColumn(response.errors));
        setState(FileImportState.COMMIT_HAS_ERRORS);
      } else {
        onImport?.();
        setSuccessMessages([
          `Added ${response.data.actionItemTemplatesAdded} action item record(s)`,
          `Updated ${response.data.actionItemTemplatesUpdated} action item record(s)`,
        ]);
      }
    }
  }, [fileToUpload, onImport]);

  return (
    <Drawer isOpen={isOpen}>
      <DrawerFormContainer>
        <DrawerFormHeader css={[vr2]}>
          <Heading css={[displayXsMedium]}>{'Upload Action Items'}</Heading>
          <XButton
            buttonLabel="Dismiss Drawer"
            data-qa-button="dismissDrawer"
            onClick={closeAndResetModal}
            icon={roundedCloseIcon}
          />
        </DrawerFormHeader>
        <Subtitle css={[vr4]}>{'Choose a file (.xls or .xlsx only)'}</Subtitle>
        <FileInputContainer key={fileInputKey}>
          <FileInput
            required
            buttonText={getUploadButtonText(state)}
            onFileChange={handleFileSelection}
            qaAttribute="upload-action-items-bulk"
          />
        </FileInputContainer>
        <Messages>
          {errorMessages.map((errorMessage) => {
            return (
              <Message
                key={generatePrefixed('error')}
                text={errorMessage}
                type={MessageType.Error}
              />
            );
          })}
          {successMessages.map((successMessage) => {
            return (
              <Message
                key={generatePrefixed('success')}
                text={successMessage}
                type={MessageType.Success}
              />
            );
          })}
        </Messages>

        <DrawerFormActions>
          <DrawerFormSecondaryAction>
            <Button
              buttonType={ButtonType.Neutral}
              data-qa-button="cancel-action-item-import"
              onClick={closeAndResetModal}
            >
              {'Cancel'}
            </Button>
          </DrawerFormSecondaryAction>
          <Button
            data-qa-button="confirm-action-item-import"
            disabled={state !== FileImportState.FILE_CHOSEN_SUCCESSFULLY}
            onClick={handleCommit}
            type="submit"
          >
            {'Upload'}
          </Button>
        </DrawerFormActions>
      </DrawerFormContainer>
    </Drawer>
  );
};
