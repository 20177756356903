import * as React from 'react';
import {Redirect, RouteChildrenProps, useParams} from 'react-router-dom';
import {assertExists} from 'wnd-util/lib/assert';
import {useDispatch} from 'react-redux';

import {impersonateEmployee} from '>root/store/actions/employee';
import {Spinner} from '>shared/components/spinner';

interface ImpersonateEmployeeParams {
  employeeId: string;
}

export const ImpersonateEmployee: React.FC<RouteChildrenProps> = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const {employeeId} = useParams<ImpersonateEmployeeParams>();
  assertExists(employeeId, 'employeeId must be part of the route params');
  const dispatch = useDispatch();

  const impersonate = React.useCallback(async () => {
    await dispatch(impersonateEmployee(employeeId));

    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    impersonate();
  }, []);

  return isLoading ? <Spinner /> : <Redirect to="/" />;
};
