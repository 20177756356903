import * as colors from 'wnd-themes/lib/colorPalette';

export const tableStyles = {
  borderColor: colors.gray200,
  defaultFontColor: colors.gray500,
  headerBackgroundColor: colors.gray100,
  hoverFontColor: colors.gray700,
  hoverBackgroundColor: colors.gray50,
  tooltipBackgroundColor: colors.white,
};
