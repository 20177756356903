import React from 'react';
import styled from '@emotion/styled';
import {Card} from '>shared/components/cards/card';
import {vr2, vr8} from '>styles/mixins/verticalRhythm';
import * as colors from 'wnd-themes/lib/colorPalette';
import {textXlMedium} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';

const SubSectionContainer = styled.div`
  margin: 2.4rem;
`;

const SectionDivider = styled.div`
  border: 0.1rem solid ${colors.char100};
`;

export const InstructionsWidget: React.FC = ({children}) => {
  return (
    <Card css={{border: `0.1rem solid ${colors.char100}`}}>
      <SubSectionContainer>
        <Heading css={[vr2, textXlMedium]}>Instructions</Heading>
        <SectionDivider css={vr8} />
        {children}
      </SubSectionContainer>
    </Card>
  );
};
