import * as React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {respondTo} from '>styles/breakpoints';
import * as colors from 'wnd-themes/lib/colorPalette';
import {
  PageLayoutComponents,
  ResponsiveLayout,
} from '>shared/components/layouts/responsive/responsiveLayout';
import {MobileSectionHeader} from '>shared/components/landmarks/header/mobileSectionHeader';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {Section} from '>shared/components/heading/heading';
import {useWindowSize} from '>shared/components/hooks/windowSize';
import {pixelsToRem, pixelsWithUnitToPixels} from '>shared/lib/remConversion';

const IndexSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 36.4rem;
  flex-shrink: 0;

  ${respondTo.smallOrLower} {
    width: 100%;
  }
`;

const ContentSection = styled.div<{fixedHeading?: boolean}>`
  padding: 0 4rem 4rem 4rem;
  overflow-y: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
  flex-grow: 1;
  padding-top: ${(props) => (props.fixedHeading ? '0' : '2.4rem')};
  ::-webkit-scrollbar {
    display: none;
  }

  ${respondTo.smallOrLower} {
    padding: 2.4rem;
  }
`;

type OptionsSectionProps = {
  showNotifications?: boolean;
};

const OptionsSection = styled.div<OptionsSectionProps>`
  border-left: 0.1rem solid ${colors.char100};
  max-width: 36.4rem;
  width: 100%;

  ${(props: OptionsSectionProps) =>
    props.showNotifications &&
    css`
      border-left: none;
      max-width: 31rem;
      position: sticky;
      top: 0;
    `}

  ${respondTo.mediumOrLower} {
    display: none;
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;

  ${respondTo.smallOrLower} {
    flex-flow: row wrap;
  }
`;

interface MainContentProps {
  indexSection: React.ReactNode;
  contentSection: React.ReactNode;
  optionsSection?: React.ReactNode;
  mobileMenuButton?: React.ReactNode;
  showNotifications?: boolean;
  fixedHeading?: boolean;
  onContentWidthChange?: (width: number) => void;
}

export const IndexContentLayout: React.FC<MainContentProps> = ({
  indexSection,
  contentSection,
  mobileMenuButton,
  optionsSection,
  showNotifications,
  fixedHeading,
  onContentWidthChange,
}) => {
  const {activeSectionTitle, setHiddenComponent} = useResponsiveLayout();
  const ref = React.useRef<HTMLDivElement>(null);
  const {width} = useWindowSize();

  const hideBody = React.useCallback(() => {
    setHiddenComponent(PageLayoutComponents.Body);
  }, []);

  // Notify subscriber of content width changes
  React.useEffect(() => {
    const element = ref.current;

    if (!element || !onContentWidthChange) {
      return;
    }

    const {paddingLeft, paddingRight} = getComputedStyle(element);

    const contentWidth = pixelsToRem(
      element.clientWidth -
        pixelsWithUnitToPixels(paddingLeft) -
        pixelsWithUnitToPixels(paddingRight)
    );
    if (contentWidth > 0) {
      onContentWidthChange(contentWidth);
    }
  }, [width]);

  return (
    <Section>
      <ResponsiveLayout
        nav={<IndexSection>{indexSection}</IndexSection>}
        main={
          <Main>
            <ContentSection ref={ref} fixedHeading={fixedHeading}>
              <MobileSectionHeader
                primaryHeader={true}
                onBackButtonClick={hideBody}
                sectionHeading={activeSectionTitle ?? 'Your Results in the Spotlight'}
                menuButton={mobileMenuButton}
              />
              {contentSection}
            </ContentSection>
            {optionsSection && (
              <OptionsSection showNotifications={showNotifications}>
                {optionsSection}
              </OptionsSection>
            )}
          </Main>
        }
      />
    </Section>
  );
};
