import * as React from 'react';
import {useForm} from 'react-hook-form';
import {useAppDispatch} from '>root/store/main';
import {CannotAccessContentManagerError} from '>root/errors';
import {IndexContentLayout} from '>components/indexContentLayout';
import {redirectToErrorPage} from '>lib/redirect';
import {
  convertFormFieldsToMeasure,
  MeasureFormFields,
} from '>components/contentManagement/form/measure/formFields';
import {
  chapterDropdownOptions,
  NavigationIndex,
} from '>components/contentManagement/navigation/navigationIndex';
import {ChapterItemSettings} from '>components/contentManagement/settings/chapterItemSettings';
import {
  createChapterIntroduction,
  createChapterMeasure,
  deleteChapterMeasure,
  updateChapterItem,
} from '>root/store/actions/chapter';
import {ChapterItem, ChapterItemType} from '>generated/dvp.types';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {useSelector} from 'react-redux';
import {
  defaultEmployeeReportTemplateContext,
  defaultEmployeeTemplateControlFormValues,
} from '>components/contentManagement/form/templateControls/form';
import {defaultMeasureFormValues} from '>components/contentManagement/form/measure/form';
import {IntroductionFormFields} from '>components/contentManagement/form/introduction/formFields';
import {defaultIntroductionFormValues} from '>components/contentManagement/form/introduction/form';
import {ChapterItemContent, ViewState} from './content';
import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import {
  convertChapterItemToFormFields,
  convertFormFieldsToChapterItemContent,
  validateFormForPublish,
} from './formView';
import {setFormFields, validateContentForPublish} from '../formUtils';
import {assertExists} from 'wnd-util/lib/assert';
import {checkUserContentManagementAccess} from '>lib/user';

export const ContentManagerForResults: React.FC = () => {
  const defaultChapter = chapterDropdownOptions[0];

  const chapterState = useSelector((state) => state.chapter);
  const authState = useSelector((state) => state.auth);

  const [viewState, setViewState] = React.useState<ViewState>(ViewState.View);
  const [previousViewState, setPreviousViewState] = React.useState<ViewState>();
  const [selectedChapterNumber, setSelectedChapterNumber] = React.useState(defaultChapter.value);
  const [selectedChapterItem, setSelectedChapterItem] = React.useState<ChapterItem>(
    chapterState.chapter.introduction
  );
  const [isPublishChecked, setIsPublishChecked] = React.useState<boolean>(false);
  const [formFieldsInEdit, setFormFieldsInEdit] = React.useState<
    IntroductionFormFields | MeasureFormFields
  >();
  const [templateContext, setTemplateContext] = React.useState(
    JSON.parse(defaultEmployeeReportTemplateContext)
  );
  const [globalVariables, setGlobalVariables] = React.useState('');

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const canUserAccessContentManager = checkUserContentManagementAccess();
    if (!canUserAccessContentManager) {
      redirectToErrorPage(new CannotAccessContentManagerError());
    }
  }, [authState]);

  React.useEffect(() => {
    setIsPublishChecked(selectedChapterItem.isPublished);
  }, [selectedChapterItem]);

  React.useEffect(() => {
    setSelectedChapterNumber('1');
  }, []);

  React.useEffect(() => {
    setSelectedChapterItem(chapterState.chapter.introduction);
  }, [chapterState.chapter.introduction]);

  const introductionFormMethods = useForm<IntroductionFormFields>({
    mode: 'all',
    defaultValues: defaultIntroductionFormValues,
  });

  const measureFormMethods = useForm<MeasureFormFields>({
    mode: 'all',
    defaultValues: defaultMeasureFormValues,
  });

  const templateControlFormMethods = useForm<TemplateControlFormFields>({
    mode: 'all',
    defaultValues: defaultEmployeeTemplateControlFormValues,
  });

  React.useEffect(() => {
    if (selectedChapterItem.content.variables) {
      templateControlFormMethods.reset({
        templateContext: defaultEmployeeReportTemplateContext,
        globalVariables: selectedChapterItem.content.variables,
      });
      setGlobalVariables(selectedChapterItem.content.variables);
    }
  }, [selectedChapterItem]);

  const formMethods = React.useMemo(() => {
    return {
      [ChapterItemType.Introduction]: introductionFormMethods,
      [ChapterItemType.Measure]: measureFormMethods,
    };
  }, [introductionFormMethods, measureFormMethods]);

  const validateChapterItem = React.useCallback(async () => {
    const areTemplateFieldsValid = await templateControlFormMethods.trigger();
    if (!areTemplateFieldsValid) {
      return false;
    }

    const areNonTemplateFieldsValid = await formMethods[selectedChapterItem.type].trigger();

    if (isPublishChecked) {
      const isPublishValid = validateFormForPublish(
        formMethods[selectedChapterItem.type],
        viewState
      );
      return isPublishValid && areNonTemplateFieldsValid;
    }

    return areNonTemplateFieldsValid;
  }, [selectedChapterItem, viewState, formMethods, isPublishChecked]);

  const submitChapterItem = React.useCallback(async () => {
    const isChapterItemValid = await validateChapterItem();
    let submittedChapterItem;

    if (isChapterItemValid) {
      if (viewState === ViewState.Create) {
        const response = await dispatch(
          createChapterMeasure({
            chapterNumber: selectedChapterNumber,
            isPublished: isPublishChecked,
            measure: {
              ...convertFormFieldsToMeasure(measureFormMethods.getValues()),
              variables: globalVariables,
            },
          })
        );

        if (createChapterMeasure.rejected.match(response)) {
          throw response.payload;
        }

        submittedChapterItem = response.payload;
      } else if (viewState === ViewState.Edit) {
        if (
          selectedChapterItem.type === ChapterItemType.Introduction &&
          selectedChapterItem.id === 'pending'
        ) {
          const response = await dispatch(
            createChapterIntroduction({
              chapterNumber: selectedChapterNumber,
              isPublished: isPublishChecked,
              introduction: {
                ...formMethods[ChapterItemType.Introduction].getValues(),
                variables: globalVariables,
              },
            })
          );

          if (createChapterIntroduction.rejected.match(response)) {
            throw response.payload;
          }

          submittedChapterItem = response.payload;
        } else {
          const response = await dispatch(
            updateChapterItem({
              chapterItemId: selectedChapterItem.id,
              update: {
                type: selectedChapterItem.type,
                chapterNumber: selectedChapterNumber,
                isPublished: isPublishChecked,
                content: {
                  ...convertFormFieldsToChapterItemContent(
                    formMethods[selectedChapterItem.type].getValues(),
                    selectedChapterItem.type
                  ),
                  variables: globalVariables,
                },
              },
            })
          );

          if (updateChapterItem.rejected.match(response)) {
            throw response.payload;
          }

          submittedChapterItem = response.payload;
        }
      } else if (viewState === ViewState.Preview) {
        if (previousViewState === ViewState.Create) {
          const response = await dispatch(
            createChapterMeasure({
              chapterNumber: selectedChapterNumber,
              isPublished: isPublishChecked,
              measure: {
                ...convertFormFieldsToMeasure(measureFormMethods.getValues()),
                variables: globalVariables,
              },
            })
          );

          if (createChapterMeasure.rejected.match(response)) {
            throw response.payload;
          }

          submittedChapterItem = response.payload;
        } else {
          assertExists(formFieldsInEdit, 'Form fields should exist when submitting form');
          const response = await dispatch(
            updateChapterItem({
              chapterItemId: selectedChapterItem.id,
              update: {
                type: selectedChapterItem.type,
                chapterNumber: selectedChapterNumber,
                isPublished: isPublishChecked,
                content: {
                  ...convertFormFieldsToChapterItemContent(
                    formFieldsInEdit,
                    selectedChapterItem.type
                  ),
                  variables: globalVariables,
                },
              },
            })
          );

          if (updateChapterItem.rejected.match(response)) {
            throw response.payload;
          }

          submittedChapterItem = response.payload;
        }
      }

      if (submittedChapterItem) {
        setSelectedChapterItem(submittedChapterItem);
        setViewState(ViewState.View);
      }
    }
  }, [
    validateChapterItem,
    selectedChapterItem,
    selectedChapterNumber,
    viewState,
    isPublishChecked,
    formFieldsInEdit,
    formMethods,
    measureFormMethods,
  ]);

  /*
   *   Handler Methods
   */

  const handleTemplateContextChange = React.useCallback((newValue: string) => {
    setTemplateContext(JSON.parse(newValue));
    templateControlFormMethods.setValue('templateContext', newValue);
  }, []);

  const handleGlobalVariablesChange = React.useCallback((newValue: string) => {
    setGlobalVariables(newValue);
    templateControlFormMethods.setValue('globalVariables', newValue);
  }, []);

  const handleCreateMeasure = React.useCallback(() => {
    setViewState(ViewState.Create);
    setSelectedChapterItem({
      id: 'pending',
      type: ChapterItemType.Measure,
      chapterNumber: selectedChapterNumber,
      content: defaultMeasureFormValues,
      isPublished: false,
      anchorId: '0',
    });
    measureFormMethods.reset(defaultMeasureFormValues);
    measureFormMethods.clearErrors();
    templateControlFormMethods.reset({
      globalVariables: '',
      templateContext: defaultEmployeeReportTemplateContext,
    });
  }, []);

  const handleChapterChanged = React.useCallback(
    async (chapter: Option) => {
      setViewState(ViewState.View);
      setSelectedChapterNumber(chapter.value);
      setSelectedChapterItem(chapterState.chapter.introduction);
    },
    [chapterState.chapter.introduction]
  );

  const handleChapterItemSelected = React.useCallback(
    (chapterItemId: string) => {
      setSelectedChapterItem(
        chapterState.chapter.measures.find((item) => item.id === chapterItemId) ??
          chapterState.chapter.introduction
      );

      setViewState(ViewState.View);
    },
    [chapterState]
  );

  const handleDeleteMeasure = React.useCallback(async () => {
    await dispatch(deleteChapterMeasure(selectedChapterItem.id));
    // This reload is a temporary fix until we can fix bug WD-1169
    window.location.reload();
  }, [selectedChapterItem, chapterState.chapter.introduction]);

  const handleEditChapterItem = React.useCallback(() => {
    setFormFields(
      formMethods[selectedChapterItem.type],
      convertChapterItemToFormFields(selectedChapterItem)
    );

    setViewState(ViewState.Edit);

    setGlobalVariables(selectedChapterItem.content.variables || '');
    templateControlFormMethods.reset({
      globalVariables: selectedChapterItem.content.variables,
      templateContext: defaultEmployeeReportTemplateContext,
    });
  }, [selectedChapterItem]);

  const handleCancel = React.useCallback(() => {
    if (viewState === ViewState.Create) {
      setSelectedChapterItem(chapterState.chapter.introduction);
    }
    setIsPublishChecked(selectedChapterItem.isPublished);

    setViewState(ViewState.View);
  }, [viewState, selectedChapterItem, chapterState]);

  const handlePublishChapterItem = React.useCallback(
    async (isPublished: boolean): Promise<boolean> => {
      setIsPublishChecked(isPublished);

      let isValidForPublish = true;

      if (isPublished) {
        if (viewState === ViewState.View) {
          isValidForPublish = validateContentForPublish(selectedChapterItem.content);

          if (isValidForPublish) {
            await dispatch(
              updateChapterItem({
                chapterItemId: selectedChapterItem.id,
                update: {...selectedChapterItem, isPublished: true},
              })
            );
          }
        } else if (viewState === ViewState.Preview) {
          if (formFieldsInEdit) {
            isValidForPublish = validateContentForPublish(formFieldsInEdit);
          }
        } else {
          isValidForPublish = validateFormForPublish(
            formMethods[selectedChapterItem.type],
            viewState
          );
        }
      } else if (viewState === ViewState.View) {
        if (isValidForPublish) {
          await dispatch(
            updateChapterItem({
              chapterItemId: selectedChapterItem.id,
              update: {...selectedChapterItem, isPublished: false},
            })
          );
        }
      }

      return isValidForPublish;
    },
    [viewState, selectedChapterItem, formFieldsInEdit, formMethods]
  );

  const togglePreview = React.useCallback(async () => {
    if (!(await validateChapterItem())) {
      return;
    }

    setPreviousViewState(viewState);

    switch (viewState) {
      case ViewState.Create:
        setFormFieldsInEdit(measureFormMethods.getValues());
        setGlobalVariables(templateControlFormMethods.getValues('globalVariables'));
        setTemplateContext(JSON.parse(templateControlFormMethods.getValues('templateContext')));
        setViewState(ViewState.Preview);
        break;
      case ViewState.Preview:
        assertExists(
          formFieldsInEdit,
          'When going back from preview state the form fields being edited should exist'
        );
        setFormFields(formMethods[selectedChapterItem.type], formFieldsInEdit);
        templateControlFormMethods.reset({
          globalVariables,
          templateContext: JSON.stringify(templateContext, null, 2),
        });

        assertExists(
          previousViewState,
          'When going back from preview state the previous state must exist'
        );
        setViewState(previousViewState);
        break;
      case ViewState.Edit:
        setFormFieldsInEdit(formMethods[selectedChapterItem.type].getValues());
        setGlobalVariables(templateControlFormMethods.getValues('globalVariables'));
        setTemplateContext(JSON.parse(templateControlFormMethods.getValues('templateContext')));
        setViewState(ViewState.Preview);
        break;
    }
  }, [viewState]);

  /*
   *   End of Handler Methods
   */

  return (
    <IndexContentLayout
      indexSection={
        <NavigationIndex
          chapterItem={selectedChapterItem}
          chapterNumber={selectedChapterNumber}
          onChapterChanged={handleChapterChanged}
          onCreateMeasure={handleCreateMeasure}
          onChapterItemSelected={handleChapterItemSelected}
        />
      }
      contentSection={
        <ChapterItemContent
          chapterItem={selectedChapterItem}
          chapterItemFormMethods={formMethods[selectedChapterItem.type]}
          formFieldsInEdit={formFieldsInEdit}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
          handleGlobalVariablesChange={handleGlobalVariablesChange}
          viewState={viewState}
          templateContext={templateContext}
          globalVariables={globalVariables}
        />
      }
      optionsSection={
        <ChapterItemSettings
          chapterItem={selectedChapterItem}
          viewState={viewState}
          isPublishedChecked={isPublishChecked}
          setIsPublishChecked={setIsPublishChecked}
          onSubmit={submitChapterItem}
          onDelete={handleDeleteMeasure}
          onEdit={handleEditChapterItem}
          onPublish={(isPublished: boolean) => {
            return handlePublishChapterItem(isPublished);
          }}
          onCancel={handleCancel}
          onTogglePreview={togglePreview}
        />
      }
    />
  );
};
