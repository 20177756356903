import * as React from 'react';
import styled from '@emotion/styled';
import {FormProvider, useForm, ValidationRules} from 'react-hook-form';

import {DrawerForm} from '>shared/components/drawer/drawerForm';
import {
  FormBuilder,
  FormBuilderFields,
  FormBuilderFieldType,
} from '>shared/components/form/formBuilder';
import {Input} from '>shared/components/form/input';
import {mobileSidebarDrawOrder} from '>shared/styles/mixins/drawOrder';

import {dvpApi} from '>root/apis';
import {EmployeeData} from '>components/employeeTable/employeeRow';
import {Employee, JobProfileStatus} from '>generated/dvp.types';
import {rollbarLogger} from '>lib/logger';
import {WndError} from '>root/errors';
import {assertExists} from 'wnd-util/lib/assert';

enum ModalState {
  ConfirmJobTitle = 'confirm',
  AlternateJobTitle = 'alternate',
}
const ALTERNATE_JOB_TITLE_MATCH_FAIL_MESSAGE =
  'We don’t have enough information about this alternate Job Title yet. Please try a different alternate Job Title.';

type EmployeeAlternateJobTitle = Employee & {alternateJobTitle: string};

const JOB_TITLE_LABEL = 'Job title';
const ALTERNATE_JOB_TITLE_LABEL = 'Alternate job title';

function getJobTitleInputValidationRules(jobTitleLabel: string) {
  return {
    minJobTitleLength: (value: string) => {
      if (value?.trim().length < 2) {
        return `${jobTitleLabel} value must contain more than two characters`;
      }
    },
    maxJobTitleLength: (value: string) => {
      if (value?.trim().length > 60) {
        return `${jobTitleLabel} value must not contain more than 60 characters`;
      }
    },
    required: (value: string) => {
      if (value?.trim().length === 0) {
        return `${jobTitleLabel} is required`;
      }
    },
  };
}

function getFormFieldsByModalState(
  state: ModalState,
  register: (rules?: ValidationRules) => React.Ref<HTMLInputElement>
): FormBuilderFields {
  const isAlternateJobTitle = state === ModalState.AlternateJobTitle;
  const jobTitleLabel = isAlternateJobTitle ? ALTERNATE_JOB_TITLE_LABEL : JOB_TITLE_LABEL;

  return [
    {
      type: FormBuilderFieldType.Input,
      label: isAlternateJobTitle ? ALTERNATE_JOB_TITLE_LABEL : JOB_TITLE_LABEL,
      name: isAlternateJobTitle ? 'alternateJobTitle' : 'jobTitle',
      component: (
        <Input
          ref={register({
            required: `${jobTitleLabel} is required`,
            validate: getJobTitleInputValidationRules(jobTitleLabel),
          })}
          placeholder={isAlternateJobTitle ? '' : JOB_TITLE_LABEL}
          required
        />
      ),
    },
  ];
}

interface Props {
  employee?: Employee;
  isOpen: boolean;
  onClose: () => void;
  updateRow: (data: EmployeeData) => void;
}

export const ConfirmJobTitleModal: React.FC<Props> = ({employee, isOpen, onClose, updateRow}) => {
  const [modalState, setModalState] = React.useState(ModalState.ConfirmJobTitle);
  const [jobProfileError, setJobProfileError] = React.useState<string | undefined>(undefined);

  const formMethods = useForm<EmployeeAlternateJobTitle>({
    mode: 'all',
    defaultValues: employee,
  });
  const {register, formState} = formMethods;

  async function handleConfirmJobTitleSubmit() {
    assertExists(employee, 'Employee must be defined for job title update');
    const {jobTitle} = formMethods.getValues();
    const response = await dvpApi.updateEmployee(
      {
        jobTitle,
      },
      {
        accountId: employee.accountId,
        employeeId: employee.id,
        ephemeralEmployeeId: employee.ephemeralId,
      }
    );
    const updatedEmployee = response.data;

    const didJobProfileMappingFail = updatedEmployee.jobProfileStatus === JobProfileStatus.Failure;

    if (didJobProfileMappingFail) {
      setModalState(ModalState.AlternateJobTitle);
    } else {
      updateRow({employee: updatedEmployee});
      onClose();
    }
  }

  async function handleAlternateJobTitleSubmit() {
    assertExists(employee, 'Employee must be defined for alternate job title update');
    const {alternateJobTitle} = formMethods.getValues();
    // reset the errors in between submissions
    setJobProfileError(undefined);

    const response = await dvpApi.mapAlternateJobTitleToJobProfile({
      accountId: employee.accountId,
      employeeId: employee.id,
      alternateJobTitle,
    });
    const updatedEmployee = response.data;
    const didJobProfileUpdateFail = updatedEmployee.jobProfileStatus === JobProfileStatus.Failure;

    if (didJobProfileUpdateFail) {
      setJobProfileError(ALTERNATE_JOB_TITLE_MATCH_FAIL_MESSAGE);
    } else {
      setJobProfileError(undefined);
      updateRow({employee: updatedEmployee});
      onClose();
    }
  }

  const onSubmitError = React.useCallback(
    (error, setFormError) => {
      const err = error as WndError;
      rollbarLogger.error(err);

      if (err.isUserFacing) {
        setFormError(error.message);
      } else {
        throw new Error(error.message);
      }
    },
    [rollbarLogger]
  );

  const isAlternateJobTitleModalState = modalState === ModalState.AlternateJobTitle;
  const handleSecondaryOnClick = isAlternateJobTitleModalState
    ? () => {
        setModalState(ModalState.ConfirmJobTitle);
        setJobProfileError(undefined);
      }
    : onClose;

  React.useEffect(() => {
    formMethods.reset(employee);
  }, [employee]);

  return (
    <FormProvider {...formMethods}>
      <FixedPositionDrawerContainer>
        <DrawerForm
          isOpen={isOpen}
          onDismiss={onClose}
          onSubmitError={onSubmitError}
          manualError={jobProfileError}
          formDescription={`We need a more informative Job Title in order to provide role-specific feedback, insights, and action items. Please enter a specific or more descriptive Job Title or update as needed, then save to continue.`}
          formTitle="Need Specific Job Title"
          stages={[
            {
              methods: formMethods,
              form: <FormBuilder fields={getFormFieldsByModalState(modalState, register)} />,
              actions: {
                next: {
                  disabled: formState.isSubmitting,
                  onClick: isAlternateJobTitleModalState
                    ? handleAlternateJobTitleSubmit
                    : handleConfirmJobTitleSubmit,
                },
                back: {
                  label: isAlternateJobTitleModalState ? 'Back' : 'Cancel',
                  disabled: formState.isSubmitting,
                  onClick: handleSecondaryOnClick,
                },
              },
            },
          ]}
        />
      </FixedPositionDrawerContainer>
    </FormProvider>
  );
};

const FixedPositionDrawerContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  ${mobileSidebarDrawOrder}
`;
