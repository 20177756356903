import styled from '@emotion/styled';
import * as colors from 'wnd-themes/lib/colorPalette';

export const FileInputContainer = styled.div`
  margin-left: 0.4rem;
`;

export const Messages = styled.div`
  margin: 1.6rem 0;
`;

export const FileInputSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Divider = styled.hr`
  margin: 2.4rem 0;
  border: 0;
  border-top: 1px solid ${colors.gray300};
  width: 100%;
`;
