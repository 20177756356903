import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {ActionPlannerWelcomeItemView} from './itemView';
import {ActionPlannerWelcomeItemFormView} from './formView';
import {ActionPlannerWelcomeItem} from '>generated/dvp.types';
import {ActionPlannerWelcomeItemFormFields} from '>components/contentManagement/form/actionPlannerWelcome/formFields';
import {ViewState} from '../homePage/content';

interface Props {
  actionPlannerWelcomeItem: ActionPlannerWelcomeItem;
  actionPlannerWelcomeItemFormMethods: UseFormMethods<ActionPlannerWelcomeItemFormFields>;
  formFieldsInEdit?: ActionPlannerWelcomeItemFormFields;
  templateControlFormMethods: UseFormMethods<TemplateControlFormFields>;
  handleTemplateContextChange: (newValue: string) => void;
  handleGlobalVariablesChange: (newValue: string) => void;
  viewState: ViewState;
  templateContext: Record<string, any>;
  globalVariables: string;
}

export const ActionPlannerWelcomeItemViewer: React.FC<Props> = ({
  actionPlannerWelcomeItem,
  actionPlannerWelcomeItemFormMethods,
  formFieldsInEdit,
  templateControlFormMethods,
  handleTemplateContextChange,
  handleGlobalVariablesChange,
  viewState,
  templateContext,
  globalVariables,
}) => {
  const Content = React.useMemo(() => {
    return {
      [ViewState.View]: (
        <ActionPlannerWelcomeItemView
          actionPlannerWelcomeItem={actionPlannerWelcomeItem}
          templateContext={templateContext}
        />
      ),
      [ViewState.Preview]: formFieldsInEdit ? (
        <ActionPlannerWelcomeItemView
          actionPlannerWelcomeItem={{
            ...actionPlannerWelcomeItem,
            content: {...formFieldsInEdit},
            variables: globalVariables,
          }}
          templateContext={templateContext}
        />
      ) : (
        <></>
      ),
      [ViewState.Edit]: (
        <ActionPlannerWelcomeItemFormView
          actionPlannerWelcomeItemFormMethods={actionPlannerWelcomeItemFormMethods}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
          handleGlobalVariablesChange={handleGlobalVariablesChange}
        />
      ),
      [ViewState.Create]: (
        <ActionPlannerWelcomeItemFormView
          actionPlannerWelcomeItemFormMethods={actionPlannerWelcomeItemFormMethods}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
          handleGlobalVariablesChange={handleGlobalVariablesChange}
        />
      ),
    };
  }, [
    viewState,
    actionPlannerWelcomeItem,
    actionPlannerWelcomeItemFormMethods,
    templateControlFormMethods,
    handleTemplateContextChange,
    templateContext,
  ]);

  return Content[viewState];
};
