import {createAppThunk} from '../thunk';
import {dvpApi} from '>root/apis';

export const getInterpretationBuckets = createAppThunk(
  '@wnd/interpretationBuckets/get',
  async () => {
    const response = await dvpApi.getInterpretationBuckets();

    return response.data;
  }
);