var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FontStack } from './fonts';
import { defaultTheme } from './defaultTheme';
import * as colors from './colorPalette';
var primaryColor = colors.darkBlue;
var primaryAccent = colors.wonscoreDarkBlue;
var PrimaryButton = {
    borderRadius: '2.7rem',
    padding: '0.4rem 2.0rem',
    outlineColor: primaryColor,
    enabled: {
        backgroundColor: primaryColor,
        color: colors.white,
        borderColor: colors.iceberg,
    },
    active: {
        backgroundColor: primaryColor,
        color: colors.white,
        borderColor: primaryColor,
    },
    disabled: {
        backgroundColor: colors.lightBlue,
        color: colors.white,
        borderColor: colors.lightBlue,
    },
    hover: {
        backgroundColor: primaryAccent,
        color: colors.white,
        borderColor: colors.iceberg,
    },
};
var SecondaryButton = __assign(__assign({}, PrimaryButton), { enabled: {
        backgroundColor: colors.transparent,
        color: primaryColor,
        borderColor: primaryColor,
    }, hover: {
        backgroundColor: colors.transparent,
        color: primaryAccent,
        borderColor: primaryAccent,
    }, active: {
        backgroundColor: colors.grey50,
        color: colors.white,
        borderColor: colors.white,
    } });
var TransparentButton = __assign(__assign({}, PrimaryButton), { enabled: {
        backgroundColor: colors.transparent,
        color: primaryColor,
        borderColor: primaryColor,
    }, hover: {
        backgroundColor: primaryColor,
        color: colors.white,
        borderColor: primaryColor,
    }, active: {
        backgroundColor: primaryColor,
        color: colors.white,
        borderColor: primaryColor,
    } });
var InvertedButton = __assign(__assign({}, PrimaryButton), { enabled: {
        backgroundColor: colors.transparent,
        color: primaryColor,
        borderColor: colors.white,
    }, hover: {
        backgroundColor: colors.grey96,
        color: primaryColor,
        borderColor: colors.grey96,
    }, active: {
        backgroundColor: colors.white,
        color: primaryColor,
        borderColor: colors.white,
    }, disabled: {
        backgroundColor: colors.transparent,
        color: colors.grey90,
        borderColor: colors.grey90,
    } });
var Tile = {
    backgroundColor: colors.white,
    borderColor: colors.purpleLight,
    borderRadius: '0.4rem',
    borderSize: '0.1rem',
    paddingMediumOrHigher: '2.8rem',
    paddingSmallOrLower: '1.6rem',
};
var AppHeader = {
    subHeaderColor: primaryColor,
};
var Icons = {
    primaryColor: primaryColor,
};
var SideBar = {
    background: primaryColor,
    mobileBackground: primaryColor,
    hoverOutlineColor: primaryAccent,
    activeBackgroundColor: primaryAccent,
    dividerColor: primaryAccent,
    color: colors.white,
    itemFocusColor: colors.darkPink,
    activeOutlineColor: primaryAccent,
    focusOutlineColor: primaryAccent,
    hoverBackgroundColor: primaryAccent,
};
var Checkbox = {
    borderColor: primaryColor,
    backgroundColor: primaryColor,
};
var InputBubble = {
    focusBackgroundColor: colors.purpleLight,
    activeBackgroundColor: colors.purpleAccent,
};
var UserMenu = {
    backgroundColor: colors.trout,
    hoverBackgroundColor: colors.greyChateau,
    dividerColor: colors.riverBed,
    activeMenuIconColor: primaryColor,
};
var Card = {
    backgroundColor: colors.white,
    border: 'none',
    boxShadow: "0 0.6rem 1rem -0.4rem ".concat(colors.grey50),
    sectionStatement: {
        fontFamily: FontStack.Kievit,
    },
    title: {
        color: colors.grey44,
        fontFamily: FontStack.Kievit,
    },
};
export var clientTheme = __assign(__assign({}, defaultTheme), { name: 'Client Experience Theme', button: __assign(__assign({}, defaultTheme.button), { primary: PrimaryButton, secondary: SecondaryButton, transparent: TransparentButton, inverted: InvertedButton }), radioButton: __assign(__assign({}, defaultTheme.radioButton), { checked: {
            color: defaultTheme.radioButton.checked.color,
            backgroundColor: primaryAccent,
            borderColor: primaryAccent,
            buttonColor: primaryAccent,
            buttonCircleColor: primaryAccent,
        }, tabSelected: __assign(__assign({}, defaultTheme.radioButton.tabSelected), { buttonColor: primaryColor }) }), primaryFontFamily: FontStack.Kievit, tile: Tile, appHeader: AppHeader, icons: Icons, sideBar: SideBar, checkbox: Checkbox, inputBubble: InputBubble, userMenu: UserMenu, toolTip: {
        textColor: primaryColor,
        backgroundColor: colors.white,
        borderColor: primaryColor,
    }, card: Card });
