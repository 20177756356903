import * as React from 'react';
import {assertExists} from 'wnd-util/lib/assert';
import {useSelector} from 'react-redux';

import {Action, ActionType, Modal} from '>shared/components/modal/modal';
import {ModalDisplayType} from '>shared/components/modal/displayType';
import {peopleFilledIcon, personFilledIcon} from '>shared/components/icon/icons';
import {useModal} from '>shared/components/modal/useModal';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';

import {
  AssessmentStatus,
  BulkEmployeeSelection,
  EmployeeSelectionMode,
  FilterQuery,
} from '>generated/dvp.types';
import {dvpApi} from '>root/apis';
import {EmployeeSelection} from '>root/pages/adminEmployees/employeesTable';
import {IconAvatar} from '>shared/components/avatar/avatar';
import {releaseResults} from '>root/store/actions/employee';
import {useAppDispatch} from '>root/store/main';

import {ModalSubHeading, ModalText} from './styles';

interface EmployeeDetails {
  firstName: string;
  lastName: string;
  lastAssessmentStatus: string;
}

interface Props {
  employeeSelection: EmployeeSelection;
  employeeDetails?: EmployeeDetails;
  filterQuery?: FilterQuery;
  onRelease?: () => void;
}

export const ReleaseEmployeeResultsModal: React.FC<Props> = ({
  employeeSelection,
  employeeDetails,
  filterQuery,
  onRelease,
}) => {
  const [isBusy, setIsBusy] = React.useState(false);
  const {hideModal} = useModal();
  const {showSuccessAlert, showErrorAlert} = useSnackbar();
  const dispatch = useAppDispatch();

  const account = useSelector((state) => state.account.account);
  assertExists(account, 'account must exist');
  const accountId = account.id;

  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = React.useState<
    EmployeeDetails | undefined
  >(employeeDetails);

  React.useEffect(() => {
    if (!selectedEmployeeDetails) {
      const {activeEmployees} = employeeSelection;
      const firstSelectedEmployeeId = activeEmployees.keys().next().value;

      fetchEmployeeDetails(firstSelectedEmployeeId);
    }

    async function fetchEmployeeDetails(employeeId: string) {
      const {data} = await dvpApi.getEmployeeById({accountId, employeeId});

      setSelectedEmployeeDetails({
        firstName: data.firstName,
        lastName: data.lastName,
        lastAssessmentStatus: data.lastAssessmentStatus,
      });
    }
  }, [employeeDetails]);

  const numOfReleases = employeeSelection.selectionCount;
  const isSingleRelease = numOfReleases === 1;

  let resultsRecipients: string = '';

  if (selectedEmployeeDetails) {
    const name = `${selectedEmployeeDetails.firstName} ${selectedEmployeeDetails.lastName}`;
    if (isSingleRelease) {
      resultsRecipients = name;
    } else {
      const numRemaining = numOfReleases - 1;

      resultsRecipients = `${name} and ${
        numRemaining > 1 ? `${numRemaining} others` : '1 other person'
      }`;
    }
  }
  const isReleased = employeeDetails?.lastAssessmentStatus === AssessmentStatus.ResultsReleased;

  const handleSubmit = React.useCallback(async () => {
    setIsBusy(true);

    const {selectionMode, activeEmployees, selectionCount} = employeeSelection;

    const canSubmit =
      (selectionMode === EmployeeSelectionMode.Inclusive && activeEmployees.size > 0) || // at least one selection
      (selectionMode === EmployeeSelectionMode.Exclusive && selectionCount > 0);

    if (canSubmit) {
      let bulkSelection: BulkEmployeeSelection | undefined;

      bulkSelection = {
        employeeSelectionMode: selectionMode,
        employeeIds: Array.from(activeEmployees.keys()),
        filterQuery,
      };

      const response = await dispatch(releaseResults(bulkSelection));

      if (releaseResults.rejected.match(response)) {
        showErrorAlert('Something went wrong. Please try again.');
      } else {
        const snackbarMessage = `Success! ${numOfReleases} employee ${
          isSingleRelease ? 'result was' : 'results'
        } released.`;
        showSuccessAlert(snackbarMessage);
      }

      onRelease?.();
    }

    setIsBusy(false);
    hideModal();
  }, [isBusy, onRelease]);

  const modalActions: Action[] = [
    {
      type: ActionType.Primary,
      label: ` ${isReleased ? 'Resend' : 'Release'}`,
      inputType: 'button',
      disabled: isBusy,
      onClick: handleSubmit,
      qaDataAttribute: 'release-results-button',
    },
    {
      type: ActionType.Secondary,
      label: 'Cancel',
      inputType: 'button',
      disabled: isBusy,
      onClick: hideModal,
      qaDataAttribute: 'cancel-release-results',
    },
  ];

  const title = isReleased ? 'Resend Results' : 'Release Results';
  const subtitle = `These results will be ${
    isReleased ? 'resent to' : 'released for'
  } the selected ${isSingleRelease ? 'employee:' : 'employees:'}`;

  return (
    <Modal
      headerOptions={{title, subtitle}}
      actions={modalActions}
      displayType={ModalDisplayType.Alert}
      isLocked={isBusy}
    >
      <ModalSubHeading>
        <IconAvatar
          iconSrc={isSingleRelease ? personFilledIcon : peopleFilledIcon}
          text={'Person Icon'}
        />
        <ModalText>{resultsRecipients}</ModalText>
      </ModalSubHeading>
    </Modal>
  );
};
