"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SideBarNavItem = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _icon = require("../../icon/icon");
var _navItem = require("./navItem.styles");
var _universalNavItem = require("./universalNavItem");
var _react2 = require("@emotion/react");
var _reactRouterDom = require("react-router-dom");
const _excluded = ["icon", "label", "role", "onClick", "qaDataAttribute", "isBold", "truncateText", "showLabel", "smallIcon"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
const SideBarNavItem = _ref => {
  let {
      icon,
      label,
      role,
      onClick,
      qaDataAttribute,
      isBold,
      truncateText,
      showLabel = true,
      smallIcon = false
    } = _ref,
    pathParams = _objectWithoutProperties(_ref, _excluded);
  const [isHover, setIsHover] = React.useState(false);
  const theme = (0, _react2.useTheme)();
  const location = (0, _reactRouterDom.useLocation)();
  const currentPath = React.useMemo(() => location.pathname, [location]);
  const isSelected = pathParams.path === currentPath;
  const mouseOverHandler = () => {
    setIsHover(true);
  };
  const mouseOutHandler = () => {
    setIsHover(false);
  };
  return (0, _jsxRuntime.jsx)(_navItem.NavItemContainer, {
    onMouseOver: mouseOverHandler,
    onMouseOut: mouseOutHandler,
    onFocus: mouseOverHandler,
    onBlur: mouseOutHandler,
    children: (0, _jsxRuntime.jsxs)(_universalNavItem.UniversalNavItem, _objectSpread(_objectSpread({}, pathParams), {}, {
      role: role,
      onClick: onClick,
      qaDataAttribute: qaDataAttribute,
      label: label,
      children: [(0, _jsxRuntime.jsx)(_icon.Icon, {
        src: icon,
        primaryColor: isSelected ? theme.sideBar.itemFocusColor : theme.sideBar.color,
        small: smallIcon,
        css: {
          overflow: 'visible'
        }
      }), showLabel && (0, _jsxRuntime.jsx)(_navItem.NavItemLabel, {
        truncateText: truncateText,
        children: label
      })]
    }))
  });
};
exports.SideBarNavItem = SideBarNavItem;