import React from 'react';
import {ActionItem, ActionItemStatus, IndexMeasure, UpdateActionItem} from '>generated/dvp.types';
import {Button, ButtonType, ButtonSizes} from '>shared/components/button/button';

import * as colors from 'wnd-themes/lib/colorPalette';
import {actionItemStatusOptions} from './form/formOptions';

import {IconDropdownMenu} from '>shared/components/menu/overflowMenu';
import moment from 'moment';
import {dvpApi} from '>root/apis';
import {ContextTabs, TabType} from '>shared/components/containers/contextTabs';

import * as Icons from '>shared/components/icon/icons';
import {Icons as IconTypes} from '>shared/styles/icon-types';
import {SVG} from '>shared/components/icon/svg';
import {
  StatusChanger,
  StatusOption,
  DateStatusChanger,
  ReaderPageContainer,
  PageContent,
  DatePicker,
  PageHeader,
  AttributeContainer,
  ReaderPageTitle,
  AttributeBadge,
  StatusChangeOption,
  ActionContainer,
  StatusChangeOptionContainer,
} from './actionItemReader.styles';

import {ActionDescription, AttributeDetails} from './actionItemReader/tabContents';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {calculateDropdownPosition} from '>lib/overflowMenu';
import {mediumOrHigher} from '>styles/mixins/breakpoints';
import {useAsyncDispatch} from '>root/store/main';
import {useSelector} from 'react-redux';
import {MissingAttributeError} from '>root/errors';
import {getIndexMeasures} from '>root/store/actions/employee';
import {rollbarLogger} from '>lib/logger';
import {redirectToErrorPage} from '>lib/redirect';

interface ActionItemDisplayProps {
  actionItem: ActionItem;
  menuItems: MenuLinkItem[];
  onEdit: () => void;
  onQuickChange: () => void;
}

const STATUS_ICON_SIZE = 1.6;

export const StatusIcon: React.FC<{src: string; color: string}> = ({src, color}) => (
  <SVG src={src} width={`${STATUS_ICON_SIZE}rem`} height={`${STATUS_ICON_SIZE}rem`} fill={color} />
);

export const ActionItemReader: React.FC<ActionItemDisplayProps> = ({
  actionItem,
  menuItems,
  onEdit,
  onQuickChange,
}) => {
  const [statusChangeButtonText, setStatusChangeButtonText] = React.useState<JSX.Element>();
  const [measure, setMeasure] = React.useState<IndexMeasure | undefined>();
  const pageHeaderDom = React.useRef<HTMLDivElement>(null);

  const asyncDispatch = useAsyncDispatch();
  const indexMeasures = useSelector((state) => state.employee.indexMeasures);

  React.useEffect(() => {
    const selectedOption = actionItemStatusOptions.find(
      (status) => status.value === actionItem.status
    );
    setStatusChangeButtonText(
      selectedOption ? (
        <>
          <StatusIcon src={selectedOption.icon} color={colors.gray500} />
          <span css={{marginLeft: '0.8rem', color: colors.gray500}}> {selectedOption.label}</span>
        </>
      ) : (
        <>Select ...</>
      )
    );
  }, [actionItem, actionItemStatusOptions]);

  // Lazy-load index measures
  React.useEffect(() => {
    if (indexMeasures) {
      const indexMeasure = indexMeasures.find((m) => m.title === actionItem.attribute);
      if (indexMeasure) {
        setMeasure(indexMeasure);
      } else {
        const err = new MissingAttributeError(
          `Action item ${actionItem.id} is using an attribute called ${actionItem.attribute}, but there's no Measure with that display title.`
        );
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      }
    } else {
      asyncDispatch(getIndexMeasures(actionItem.employeeId)).catch((err) => {
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      });
    }
  }, [actionItem.employeeId, indexMeasures]);

  const dateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const update = {
      id: actionItem.id,
      accountId: actionItem.accountId,
      employeeId: actionItem.employeeId,
      dueDate: moment(event.target.value).format('yyyy-MM-DDTHH:mm:ss.000Z'),
    };
    dvpApi.updateActionItem(update);
    onQuickChange();
  };

  const statusChange = async (selection: string) => {
    pageHeaderDom.current?.click(); // close drop down
    const update: UpdateActionItem = {
      id: actionItem.id,
      accountId: actionItem.accountId,
      employeeId: actionItem.employeeId,
      status: selection as ActionItemStatus,
    };
    await dvpApi.updateActionItem(update);
    onQuickChange();
  };

  const statusMenuOptions = actionItemStatusOptions.map((option) => {
    return {
      id: option.value,
      text: (
        <StatusChangeOption>
          <StatusChangeOptionContainer>
            <StatusIcon color={colors.gray500} src={option.icon} />{' '}
            <span css={{marginLeft: '1.4rem', color: colors.gray500}}> {option.label}</span>
          </StatusChangeOptionContainer>
        </StatusChangeOption>
      ),
      hoverStyle: true,
      hideDivider: true,
      action: () => {
        statusChange(option.value);
      },
    };
  });

  return (
    <ReaderPageContainer>
      <PageHeader>
        <span css={{width: '100%'}} ref={pageHeaderDom}>
          <ReaderPageTitle>{actionItem.title}</ReaderPageTitle>
          <AttributeContainer>
            <AttributeBadge>{[actionItem.attribute]}</AttributeBadge>
            {measure && <AttributeBadge>{measure.category}</AttributeBadge>}
          </AttributeContainer>
          <DateStatusChanger>
            <div>
              <DatePicker
                type="date"
                value={actionItem.dueDate ? moment(actionItem.dueDate).format('YYYY-MM-DD') : ''}
                onChange={dateChange}
              />
            </div>
            <StatusChanger>
              <StatusOption>
                <IconDropdownMenu
                  label={statusChangeButtonText}
                  menuWidth="20rem"
                  altText="Change Action Item Status"
                  icon={Icons.arrowDropdown}
                  menuItems={statusMenuOptions}
                  buttonConfig={{size: ButtonSizes.XS, type: ButtonType.Ghost}}
                />
              </StatusOption>
            </StatusChanger>
          </DateStatusChanger>
        </span>
        <ActionContainer css={mediumOrHigher}>
          <Button
            buttonType={ButtonType.Neutral}
            onClick={onEdit}
            data-qa-button="action-item-edit-button"
          >
            Edit
          </Button>
          <IconDropdownMenu
            altText="Actions"
            icon={IconTypes.KebabMenu}
            menuItems={menuItems}
            position={calculateDropdownPosition()}
          />
        </ActionContainer>
      </PageHeader>
      <PageContent>
        <ContextTabs
          tabType={TabType.Underlined}
          tabs={{
            description: {
              title: 'Action details',
              page: () => <ActionDescription actionItem={actionItem} />,
            },
            attribute: {
              title: 'Attribute summary',
              page: () => <AttributeDetails actionItem={actionItem} />,
            },
            // reflection: {
            //   title: 'Reflections & notes',
            //   page: () => <Reflections actionItem={actionItem} />,
            // },
          }}
        />
      </PageContent>
    </ReaderPageContainer>
  );
};
