import * as QueryString from 'qs';
import * as React from 'react';
import {useUIDSeed} from 'react-uid';
import {Redirect, RouteChildrenProps} from 'react-router-dom';

import {InfiniteScrollPageContents} from '../page.styles';
import {
  SearchAndBulkSelectContainer,
  searchStyles,
  TableActionsContainer,
  TableActionButtonsContainer,
  stickyContent,
} from './adminEmployeesPage.styles';
import {TableSelectionCount} from '>shared/components/tables/table.styles';

import {pageChangeEffect} from '>lib/sideEffects';
import {Icons} from '>styles/icon-types';
import {useModal} from '>shared/components/modal/useModal';
import {EmployeeSelection, EmployeesTable, SelectedEmployee} from './employeesTable';
import {AddEmployeeButton} from './addEmployeeButton';
import {
  EmployeeSelectionMode,
  EmployeeSortField,
  SortDirection,
  UserRole,
} from '>generated/dvp.types';
import {Filters, SelectFilter, useSelectFilter} from './filters/manageAppliedFilters';
import {usePureState} from '>lib/util';
import {history} from '../../history';
import {
  AppliedFilterTags,
  Tags,
  FiltersModal,
} from '>root/pages/adminEmployees/filters/employeesFilterModal';
import {EmployeeFetcher, useEmployeeFetch} from '>root/pages/adminEmployees/employeeFetch';
import {useSelector} from 'react-redux';
import {assertAccountExists} from '>lib/assert';
import {getEmployeeMetrics} from '>root/store/actions/account';
import {useAppDispatch} from '>root/store/main';
import {useAccountUser} from '>lib/user';
import {redirectToErrorPage} from '>lib/redirect';
import {NotAdminUserError} from '>root/errors';
import {RoutePath} from '>root/routes/routes';
import {getMyDvpUser} from '>root/store/actions/user';
import {
  convertToUserFriendlyMetrics,
  EmployeesMetricsWidget,
} from '>components/widgets/employeesMetricsWidget/employeesMetricsWidget';
import {EmployeeTableActionButtonGroup} from '>components/employeeTable/employeeTableActionButtonGroup';
import {visuallyHiddenMixin} from '>shared/styles/mixins/accessibility';
import {RawInput} from '>shared/components/form/rawControls/rawInput';
import {fillVerticalSpace} from '>shared/styles/mixins/alignment';
import {ClearFiltersButton, FilterButton} from './filters/filterButtons';
import {EmployeeManagementDrawerModalProvider} from '>root/hooks/useEmployeeManagementDrawerModal';

const PAGE_TITLE = 'Employees';

export const AdminEmployeesPage: React.FC<RouteChildrenProps> = () => {
  React.useEffect(pageChangeEffect(PAGE_TITLE), []);

  const accountUser = useSelector((state) => state.user.accountUser);

  if (accountUser?.role === UserRole.Coach) {
    return <Redirect to={RoutePath.Coaching} />;
  }

  return <EmployeesPageContents />;
};

const fetchQueryParams = () => {
  const {name, assessmentStatus} = QueryString.parse(location.search.replace(/^\?/, ''));

  let searchText: string | undefined;
  let assessmentStatusValue: string | undefined;

  if (typeof name === 'string') {
    searchText = name;
  }

  if (typeof assessmentStatus === 'string') {
    assessmentStatusValue = assessmentStatus;
  }

  return {searchText, assessmentStatus: assessmentStatusValue};
};

export const EmployeesPageContents: React.FC<{}> = React.memo(() => {
  const {showModal} = useModal();

  const [appliedFilters, setAppliedFilters] = usePureState<Filters>(() => {
    const {searchText, assessmentStatus} = fetchQueryParams();

    const filters: Filters = {
      searchText: searchText ?? '',
      departments: [],
      locations: [],
      assessmentStatuses: assessmentStatus ? [assessmentStatus] : [],
      jobTitles: [],
      sort: EmployeeSortField.LastName,
      sortDirection: SortDirection.Ascending,
    };

    return filters;
  });

  const [employeeSelection, setEmployeeSelection] = React.useState<EmployeeSelection>({
    selectionMode: EmployeeSelectionMode.Inclusive,
    activeEmployees: new Map<string, SelectedEmployee>(),
    selectionCount: 0,
    version: 0,
  });

  const clearSelection = React.useCallback(() => {
    const newSelection = {
      selectionMode: EmployeeSelectionMode.Inclusive,
      activeEmployees: new Map<string, SelectedEmployee>(),
      selectionCount: 0,
      version: 0,
    };

    setEmployeeSelection(newSelection);
  }, []);

  const account = useSelector((state) => state.account.account);
  const accountUser = useAccountUser();

  if (!accountUser) {
    redirectToErrorPage(new NotAdminUserError('Must be an admin user to view this page'));
    return null;
  }

  assertAccountExists(account);

  React.useEffect(() => {
    dispatch(getMyDvpUser(account.id));
  }, []);

  const employeeFetcher = useEmployeeFetch({
    dvpAccountId: account.id,
    appliedFilters,
  });

  const {availableTags} = employeeFetcher;

  const selectFilter = useSelectFilter(availableTags, appliedFilters, setAppliedFilters);
  const {appliedTags, setAppliedTags, removeAllTags} = selectFilter;

  const updateFiltersAndTags = React.useCallback(
    (filters: Filters, appliedTags: AppliedFilterTags) => {
      setAppliedFilters(() => filters);
      setAppliedTags(appliedTags);
    },
    [setAppliedFilters, setAppliedTags]
  );

  const parseURL = React.useCallback(() => {
    const {searchText, assessmentStatus} = fetchQueryParams();

    if (searchText) {
      setAppliedFilters((filters) => ({
        ...filters,
        searchText,
      }));

      history.replace(window.location.pathname);
    }

    if (assessmentStatus) {
      setAppliedFilters((filters) => ({
        ...filters,
        assessmentStatuses: [assessmentStatus],
      }));

      const appliedTags: AppliedFilterTags = [
        {
          fieldName: 'assessmentStatuses',
          label: assessmentStatus,
          value: assessmentStatus,
        },
      ];

      setAppliedTags(appliedTags);

      history.replace(window.location.pathname);
    }
  }, []);

  React.useEffect(() => {
    const unregister = history.listen(parseURL);
    parseURL();

    return () => {
      unregister();
    };
  }, []);

  const openFiltersModal = React.useCallback(() => {
    showModal(
      <FiltersModal
        tags={availableTags}
        filters={appliedFilters}
        appliedTags={appliedTags}
        applyFiltersAndTags={updateFiltersAndTags}
      />
    );
  }, [availableTags, appliedFilters, updateFiltersAndTags, appliedTags]);

  const metrics = useSelector((state) => state.account.employeeMetrics);
  const versionId = useSelector((state) => state.account.employeeTableVersionId);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getEmployeeMetrics());
  }, [versionId]);

  const userFriendlyMetrics = convertToUserFriendlyMetrics(metrics);

  const seed = useUIDSeed();
  const onSearch: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (evt) => {
      const {value} = evt.target;
      setAppliedFilters((prevAppliedFilters) => {
        return {...prevAppliedFilters, searchText: value};
      });
    },
    [setAppliedFilters]
  );

  return (
    <EmployeeManagementDrawerModalProvider>
      <InfiniteScrollPageContents css={fillVerticalSpace}>
        <div css={stickyContent()}>
          <h1 css={visuallyHiddenMixin}>Employees</h1>
          <EmployeesMetricsWidget
            filters={appliedFilters}
            appliedTags={appliedTags}
            applyFiltersAndTags={updateFiltersAndTags}
            metrics={userFriendlyMetrics}
          />
          <TableActionsContainer>
            <SearchAndBulkSelectContainer>
              <TableSelectionCount>All Employees ({metrics.employeeCount})</TableSelectionCount>
              <RawInput
                compact
                css={searchStyles}
                id={seed('search')}
                name="Search Employees"
                placeholder="Search"
                insetIcon={Icons.Search}
                onChange={onSearch}
              />
            </SearchAndBulkSelectContainer>
            <ActionButtons
              employeeSelection={employeeSelection}
              selectFilter={selectFilter}
              clearSelection={clearSelection}
              removeAllTags={removeAllTags}
              appliedFilters={appliedFilters}
              updateFiltersAndTags={updateFiltersAndTags}
              onMobileFiltersClick={openFiltersModal}
            />
          </TableActionsContainer>
        </div>
        <EmployeesTable
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          employeeFetcher={employeeFetcher}
          selectFilter={selectFilter}
          employeeSelection={employeeSelection}
          setEmployeeSelection={setEmployeeSelection}
          numEmployeeMatches={employeeFetcher.employeeCount}
          clearSelection={clearSelection}
        />
      </InfiniteScrollPageContents>
    </EmployeeManagementDrawerModalProvider>
  );
});

AdminEmployeesPage.displayName = 'EmployeesPage';

interface ActionButtonProps {
  employeeSelection: EmployeeSelection;
  selectFilter: SelectFilter<keyof Tags>;
  clearSelection(): void;
  removeAllTags(): void;
  appliedFilters: Filters;
  updateFiltersAndTags: (filters: Filters, appliedTags: AppliedFilterTags) => void;
  onMobileFiltersClick(): void;
}

const ActionButtons: React.FC<ActionButtonProps> = ({
  employeeSelection,
  selectFilter,
  clearSelection,
  removeAllTags,
  appliedFilters,
  updateFiltersAndTags,
  onMobileFiltersClick,
}) => {
  return (
    <TableActionButtonsContainer>
      {selectFilter.appliedTags.length > 1 && <ClearFiltersButton onClick={removeAllTags} />}
      <FilterButton
        appliedFilters={appliedFilters}
        selectFilter={selectFilter}
        applyFiltersAndTags={updateFiltersAndTags}
        onMobileClick={onMobileFiltersClick}
      />
      <EmployeeTableActionButtonGroup
        selection={employeeSelection}
        filters={appliedFilters}
        clearSelection={clearSelection}
        selectFilter={selectFilter}
      />
      <AddEmployeeButton />
    </TableActionButtonsContainer>
  );
};
