import {store} from '../main';
import {UnifiedAccountExternalPayload} from '../../generated/unifiedAccount.types';
import {dvpApi, unifiedAccountApi} from '../../apis';
import {INTERCOM_APP_ID, PENDO_ENABLED, INTERCOM_ENABLED} from '>root/env';
import {IntercomState, PendoState} from '../reducers/vendors';
import {updateIntercomState, updatePendoState} from '../actions/vendors';
import {ProductUser} from '>generated/unifiedAccount.types';
import {tokenUser} from '>generated/auth.types';
import {AuthState, isValidAuthState} from '../reducers/authReducer';
import {assertValidAuthState} from '>lib/assert';
import {assertExists} from 'wnd-util/lib/assert';
import {AccountStatus, AccountUser, Employee} from '>generated/dvp.types';
import {PendoInitOptions} from 'pendo';
import {DvpCombinedState} from '../reducers/main';

interface DvpExternalPayload extends UnifiedAccountExternalPayload {
  developPlanStatus: AccountStatus;
  numEmployees: number;
}

function isImpersonated(authState: AuthState): boolean {
  return authState.isValidUserToken && authState.isImpersonated;
}

function hasAccessToProducts(productUser?: ProductUser): boolean {
  return Boolean(productUser);
}

async function initializePendo(
  tokenUser: tokenUser,
  accountPayload: DvpExternalPayload,
  accountUser?: AccountUser,
  employee?: Employee
) {
  const visitorPayload = buildPendoVisitorPayload(tokenUser, accountUser, employee);

  const externalPayload: PendoInitOptions = {
    disablePersistence: true, // Do not use cookies to remember any visitor/account information.
    visitor: visitorPayload,
    account: {
      ...accountPayload,
      developNumberOfEmployees: accountPayload.numEmployees,
    },
  };

  window.pendo.initialize(externalPayload);
}

function bootIntercom(
  tokenUser: tokenUser,
  accountPayload: DvpExternalPayload,
  accountUser?: AccountUser,
  employee?: Employee
): void {
  const intercomPayload = buildIntercomPayload(tokenUser, accountPayload, accountUser, employee);

  window.Intercom('boot', intercomPayload);
}

function buildPendoVisitorPayload(
  tokenUser: tokenUser,
  accountUser?: AccountUser,
  employee?: Employee
) {
  return {
    id: tokenUser.id,
    domain: window.location.hostname,
    email: tokenUser.email,
    name: `${tokenUser.firstName} ${tokenUser.lastName}`,
    developRole: accountUser?.role,
    developJobLevel: employee?.jobLevel?.level,
    developManagerLevel: employee?.jobLevel?.directReports,
  };
}

function buildIntercomVisitorPayload(
  tokenUser: tokenUser,
  accountPayload: DvpExternalPayload,
  accountUser?: AccountUser,
  employee?: Employee
): Intercom.Payload {
  const visitorPayload: Intercom.Payload = {
    user_id: tokenUser.id,
    name: `${tokenUser.firstName} ${tokenUser.lastName}`,
    email: tokenUser.email,
    domain: window.location.hostname,
    app_id: INTERCOM_APP_ID,
    developRole: accountUser?.role,
    developNumberOfEmployees: accountPayload.numEmployees,
    developJobLevel: employee?.jobLevel?.level,
    developManagerLevel: employee?.jobLevel?.directReports,
    isInternal: accountPayload.isInternal,
    country: accountPayload.country,
    dateOfInitialPurchase: accountPayload.dateOfInitialPurchase,
    developPlanStatus: accountPayload.developPlanStatus,
    developPlanType: accountPayload.developPlanType,
    developProduct: accountPayload.developProduct,
    developPrice: accountPayload.developPrice,
  };

  return visitorPayload;
}

function buildIntercomPayload(
  tokenUser: tokenUser,
  accountPayload: DvpExternalPayload,
  accountUser?: AccountUser,
  employee?: Employee
): Intercom.Payload {
  const visitorPayload = buildIntercomVisitorPayload(
    tokenUser,
    accountPayload,
    accountUser,
    employee
  );

  const intercomPayload: Intercom.Payload = {
    ...visitorPayload,
    company: {
      company_id: accountPayload.id,
      monthly_spend: accountPayload.monthlySpend,
      name: accountPayload.name,
    },
  };

  return intercomPayload;
}

async function getUnifiedAccountPayload(
  unifiedAccountId: string
): Promise<UnifiedAccountExternalPayload> {
  const {data: accountPayload} = await unifiedAccountApi.getUnifiedAccountExternalPayload({
    unifiedAccountId,
  });

  return accountPayload;
}

enum PayloadDeliveryState {
  WAITING_TO_REQUEST_DATA,
  REQUESTING_DATA,
  READY_TO_SEND,
  SEND_COMPLETE,
}

function areAllVendorsReadyToBoot(state: DvpCombinedState): boolean {
  const {account} = state.account;
  const {intercomState, pendoState} = state.vendors;

  const isDoneWaitingForPendoScriptTag =
    !PENDO_ENABLED || pendoState === PendoState.READY_TO_INITIALIZE;

  const isDoneWaitingForIntercomScriptTag =
    !INTERCOM_ENABLED || intercomState === IntercomState.READY_TO_BOOT;

  return isDoneWaitingForPendoScriptTag && isDoneWaitingForIntercomScriptTag && Boolean(account);
}

export function registerExternalPayloadSideEffects(rootStore: typeof store) {
  if (!PENDO_ENABLED && !INTERCOM_ENABLED) {
    return;
  }

  // Dispatching in rootStore.subscribe()'s callback either does not update the redux state in real-time
  // or is running the callback multiple times in parallel. Maintain state through payloadDeliveryState
  // instead of trusting the Pendo and Intercom states. They should be reliable in the rest of the app.
  let payloadDeliveryState = PayloadDeliveryState.WAITING_TO_REQUEST_DATA;
  // Caching the API responses so we're not requesting the same information multiple times.
  let cachedDvpExternalPayload: DvpExternalPayload | undefined;

  rootStore.subscribe(async () => {
    if (payloadDeliveryState === PayloadDeliveryState.SEND_COMPLETE) {
      return;
    }

    const state: DvpCombinedState = rootStore.getState();
    const authState = state.auth;

    if (!isValidAuthState(authState) || !areAllVendorsReadyToBoot(state)) {
      return;
    }

    const {productUser, accountUser} = state.user;
    assertValidAuthState(authState);
    const user = authState.token.user;

    if (!hasAccessToProducts(productUser) || isImpersonated(authState)) {
      return;
    }

    assertExists(productUser, 'product user must exist at this point');

    if (payloadDeliveryState === PayloadDeliveryState.WAITING_TO_REQUEST_DATA) {
      const {account} = state.account;
      assertExists(account, 'account must exist to enter before requesting data');
      payloadDeliveryState = PayloadDeliveryState.REQUESTING_DATA;

      const [unifiedAccountPayload, employeeCountResponse] = await Promise.all([
        getUnifiedAccountPayload(productUser.selectedUnifiedAccountId),
        dvpApi.getEmployeeCount({accountId: account.id}),
      ]);

      cachedDvpExternalPayload = {
        ...unifiedAccountPayload,
        developPlanStatus: account.status,
        numEmployees: employeeCountResponse.data,
      };

      payloadDeliveryState = PayloadDeliveryState.READY_TO_SEND;
    }

    if (payloadDeliveryState === PayloadDeliveryState.READY_TO_SEND) {
      assertExists(cachedDvpExternalPayload, 'payload must exist before sending');
      const {employee} = state.employee;

      if (PENDO_ENABLED) {
        initializePendo(user, cachedDvpExternalPayload, accountUser, employee);
        rootStore.dispatch(updatePendoState(PendoState.INITIALIZED));
      }

      if (INTERCOM_ENABLED) {
        bootIntercom(user, cachedDvpExternalPayload, accountUser, employee);
        rootStore.dispatch(updateIntercomState(IntercomState.BOOTED));
      }

      payloadDeliveryState = PayloadDeliveryState.SEND_COMPLETE;
    }
  });
}
