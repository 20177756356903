import * as React from 'react';
import {useSelector} from 'react-redux';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {
  ProductUserWithCompanyData,
  UserUnifiedAccountWithCompanyData,
} from '>generated/unifiedAccount.types';
import {selectUnifiedAccount} from '>root/store/actions/account';
import {assertExists} from 'wnd-util/lib/assert';
import {useAsyncDispatch} from '>root/store/main';
import {
  areAnyProductsAccessible,
  buildLogoutMenuItem,
  buildUnifiedAccountMenuItem,
  isDevelopAccessible,
  isSelectAccessible,
  shouldAttemptToShowAccountSwitcher,
} from '>shared/lib/accountMenuBuilder';
import {RoutePath} from '>root/routes/routes';
import {history} from '>root/history';

function handleLogout() {
  // this can't be handled through as an href on the menu item because we need to check
  // the redirect location at the time logout is clicked.
  history.push(`${RoutePath.Logout}?redirect_uri=${encodeURIComponent(window.location.href)}`);
}

function buildUnifiedAccountItems(
  productUser: ProductUserWithCompanyData,
  asyncDispatch: any
): MenuLinkItem[] {
  const unifiedAccountMenuItems = [];

  for (const [unifiedAccountId, unifiedAccount] of Object.entries<
    UserUnifiedAccountWithCompanyData
  >(productUser.unifiedAccounts)) {
    if (!areAnyProductsAccessible(unifiedAccount)) {
      continue;
    }

    const isAccountCurrentlyInUse = unifiedAccountId === productUser.selectedUnifiedAccountId;

    const unifiedAccountMenuItem = buildUnifiedAccountMenuItem(
      unifiedAccountId,
      isAccountCurrentlyInUse,
      unifiedAccount.companyName
    );

    // Only switch accounts if this is not the account we're currently viewing
    if (unifiedAccountMenuItem.id !== productUser.selectedUnifiedAccountId) {
      unifiedAccountMenuItem.action = async (_event: React.MouseEvent) => {
        await asyncDispatch(
          selectUnifiedAccount({authUserId: productUser.authUserId, unifiedAccountId})
        );
      };

      if (!isDevelopAccessible(unifiedAccount) && isSelectAccessible(unifiedAccount)) {
        unifiedAccountMenuItem.href = `${process.env.WEBUI_WONSCORE_URL}/#`;
      }
    }

    unifiedAccountMenuItems.push(unifiedAccountMenuItem);
  }

  return unifiedAccountMenuItems;
}

export const getUserMenuItems = () => {
  const asyncDispatch = useAsyncDispatch();
  const productUser = useSelector((state) => state.user.productUser);

  const userMenuItems: MenuLinkItem[] = [];

  if (shouldAttemptToShowAccountSwitcher(productUser)) {
    assertExists(productUser, 'productUser must exist');
    const unifiedAccountMenuItems = buildUnifiedAccountItems(productUser, asyncDispatch);

    unifiedAccountMenuItems.forEach((item) => userMenuItems.push(item));
  }

  const logoutMenuItem = buildLogoutMenuItem(handleLogout, userMenuItems.length);

  userMenuItems.push(logoutMenuItem);

  return userMenuItems;
};
