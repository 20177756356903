import {createReducer} from '@reduxjs/toolkit';
import {TokenType, getUserToken, getAccessToken, clearTokens, UserData} from '../actions/auth';
import {token as UserToken, toolkitRoles as ToolkitRoles} from '>generated/auth.types';

export interface ValidAuthState {
  token: UserToken;
  isValidUserToken: boolean;
  isValidToken: true;
  toolkitRoles: ToolkitRoles;
  isAuthenticated: boolean;
  isAnonymous: boolean;
  isImpersonated: boolean;
  hasAcceptedCookies: boolean;
}

interface UserTokenWithUser extends UserToken {
  user: UserData;
}

export interface ValidAuthStateWithUser extends ValidAuthState {
  token: UserTokenWithUser;
}

export interface InvalidAuthState {
  isValidToken: false;
  isValidUserToken: false;
  isAuthenticated: false;
}

export type AuthState = ValidAuthState | InvalidAuthState;

function unauthenticatedState(): AuthState {
  return {
    isValidToken: false,
    isValidUserToken: false,
    isAuthenticated: false,
  };
}

export function isValidAuthState(authState: AuthState) {
  return authState.isValidToken && authState.isAuthenticated && Boolean(authState.token?.user);
}

// For anyone looking at this reducer as a pattern to copy, please note that the
// builder arrow function must have curly braces around the function.
// If you don't add curly braces around the function, then typescript thinks
// that the actions are part of the reducer type and it creates a circular
// type-reference
// TL;DR: Use (builder) => {builder.addCase(...)}, NOT (builder) => builder.addCase(...)
export const authReducer = createReducer<AuthState>(unauthenticatedState(), (builder) => {
  builder
    .addCase(getUserToken.fulfilled, (_, {payload: userToken}) => {
      return {
        token: userToken,
        isValidToken: true,
        isValidUserToken: userToken.token_type === TokenType.User,
        toolkitRoles: userToken.user?.toolkitRoles ?? {},
        isAuthenticated: userToken.token_type === TokenType.User,
        isAnonymous: userToken.token_type === TokenType.Anonymous,
        isImpersonated: Boolean(userToken.impersonatedByUserId),
        hasAcceptedCookies: Boolean(userToken.user?.dateOfCookiesConsent),
      };
    })
    .addCase(getUserToken.pending, () => unauthenticatedState())
    .addCase(getAccessToken.pending, () => unauthenticatedState())
    .addCase(clearTokens.fulfilled, () => unauthenticatedState());
});
