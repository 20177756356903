import * as React from 'react';

import {assertUnreachable} from 'wnd-util/lib/assert';
import {BadgeColors} from 'wnd-graphics/lib/badge/badge';
import {Badge, BadgeStyle} from '>shared/components/emblems/badge';
import {AssessmentStatus} from '>generated/dvp.types';
import {mapAssessmentStatusToColor} from '>root/utils/assessmentStatusHelpers';

const StatusBadge: React.FC<{color: BadgeColors; text: string}> = ({color, text}) => (
  <Badge color={color} variant={BadgeStyle.Default}>
    {text}
  </Badge>
);

export const InProgressBadge = (
  <StatusBadge
    color={mapAssessmentStatusToColor(AssessmentStatus.InProgress)}
    text={assessmentStatusToUserFriendly(AssessmentStatus.InProgress)}
  />
);

export const ExpiredBadge = (
  <StatusBadge
    color={mapAssessmentStatusToColor(AssessmentStatus.Expired)}
    text={assessmentStatusToUserFriendly(AssessmentStatus.Expired)}
  />
);

export const ReadyBadge = (
  <StatusBadge
    color={mapAssessmentStatusToColor(AssessmentStatus.New)}
    text={assessmentStatusToUserFriendly(AssessmentStatus.New)}
  />
);

export const AwaitingResultsBadge = (
  <StatusBadge
    color={mapAssessmentStatusToColor(AssessmentStatus.AwaitingResults)}
    text={assessmentStatusToUserFriendly(AssessmentStatus.AwaitingResults)}
  />
);

export const ResultsReleasedBadge = (
  <StatusBadge
    color={mapAssessmentStatusToColor(AssessmentStatus.ResultsReleased)}
    text={assessmentStatusToUserFriendly(AssessmentStatus.ResultsReleased)}
  />
);

export const ArchivedBadge = <StatusBadge color={BadgeColors.GRAY} text="Archived" />;

export function assessmentStatusToUserFriendly(status: AssessmentStatus | 'isArchived'): string {
  switch (status) {
    case AssessmentStatus.New:
      return 'Ready';
    case AssessmentStatus.NotStarted:
      return 'In Progress';
    case AssessmentStatus.InProgress:
      return 'In Progress';
    case AssessmentStatus.Invited:
      return 'In Progress';
    case AssessmentStatus.Expiring:
      return 'In Progress';
    case AssessmentStatus.Expired:
      return 'Expired';
    case AssessmentStatus.AwaitingResults:
      return 'Pending';
    case AssessmentStatus.ResultsReleased:
      return 'Released';
    case 'isArchived':
      return 'Archived';
    default:
      assertUnreachable(status);
  }
}

export const statusToBadgeMapping = {
  [AssessmentStatus.New]: ReadyBadge,
  [AssessmentStatus.NotStarted]: InProgressBadge,
  [AssessmentStatus.Invited]: InProgressBadge,
  [AssessmentStatus.Expiring]: InProgressBadge,
  [AssessmentStatus.InProgress]: InProgressBadge,
  [AssessmentStatus.Expired]: ExpiredBadge,
  [AssessmentStatus.AwaitingResults]: AwaitingResultsBadge,
  [AssessmentStatus.ResultsReleased]: ResultsReleasedBadge,
  // Archived is treated as an assessment status only on the front-end for UX purposes.
  Archived: ArchivedBadge,
};
