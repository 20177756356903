import * as React from 'react';
import {useSelector} from 'react-redux';
import {
  ActionItemWidgetTemplate,
  ActionItemWidgetProps,
  NestedMarkdown,
  ActionItemWidgetVariant,
} from 'wnd-dvp-reports';
import * as colors from 'wnd-themes/lib/colorPalette';
import * as Icons from '>shared/components/icon/icons';
import {sharedComponents} from '>lib/markdownSectionComponents';
import {
  ActionItemWidgetBody,
  ActionItemWidgetContainer,
  ActionItemWidgetHeader,
  RecommendedActions,
  RecommendedActionsHeader,
  ActionItemWidgetHeading,
  LabelContainer,
  StyledParagraph,
  StyledCheckboxContainer,
} from './actionItemWidget.styles';
import {Icon} from '>shared/components/icon/icon';
import {DrawerForm} from '>shared/components/drawer/drawerForm';
import {useForm} from 'react-hook-form';
import {assertExists} from 'wnd-util/lib/assert';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {useModal} from '>shared/components/modal/useModal';
import {TemplatedActionItemFields} from './interfaces';
import {TemplatedActionItemForm} from './templatedActionItemForm';
import {useAsyncDispatch} from '>root/store/main';
import {
  createActionItem,
  deleteActionItem,
  updateActionItem,
  getActionItems,
} from '>root/store/actions/actionPlanner';
import {IconToCheckbox} from '>shared/components/form/iconToCheckbox';
import {ActionItem} from '>generated/dvp.types';
import {vr2} from '>shared/styles/mixins/verticalRhythm';
import {Spinner} from '>shared/components/spinner';
import {rollbarLogger} from '>lib/logger';
import {redirectToErrorPage} from '>lib/redirect';
import {Button, ButtonSizes, ButtonType} from '>shared/components/button/button';
import {useAsyncSubmit} from '>shared/components/form/formBuilder';
import {
  ActionItemDestructiveModal,
  ActionItemModalType,
} from '>components/actionPlanner/actionItemModals';
import {history} from '>root/history';
import {LinkButton, LinkSizes} from '>shared/components/actions/linkButton';

enum ActionItemType {
  FromTemplate,
  New,
}

interface WorkingActionItem {
  type?: ActionItemType;
  template?: ActionItemWidgetTemplate;
  attribute?: string;
}

export const ActionItemWidget: React.FC<ActionItemWidgetProps> = ({
  measureDisplayTitle,
  content,
  templates,
  variant,
  onGoToActionItem,
}) => {
  if (!templates || templates.length === 0) {
    return <></>;
  }

  const asyncDispatch = useAsyncDispatch();
  const {showModal, hideModal} = useModal();
  const [workingActionItem, setWorkingActionItem] = React.useState<WorkingActionItem>();
  const [isSaving, setIsSaving] = React.useState(false);
  const actionItems = useSelector((state) => state.actionPlanner.actionItems);
  const [recentlyUsedTemplates, setRecentlyUsedTemplates] = React.useState<string[]>([]);
  const {showSuccessAlert} = useSnackbar();

  React.useEffect(() => {
    if (!actionItems) {
      asyncDispatch(getActionItems()).catch((err) => {
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      });
    }
  }, [actionItems]);

  const formMethods = useForm<TemplatedActionItemFields>({
    mode: 'all',
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const onBeginCreateOrUpdateActionItem = React.useCallback(
    (workingActionItem: WorkingActionItem) => {
      const {template, attribute} = workingActionItem;

      formMethods.clearErrors();

      if (template) {
        formMethods.setValue('title', template.title);
        formMethods.setValue('description', template.description);
        setWorkingActionItem({type: ActionItemType.FromTemplate, template});
      } else {
        formMethods.setValue('title', '');
        formMethods.setValue('description', '');
        setWorkingActionItem({type: ActionItemType.New, attribute});
      }
    },
    []
  );

  //Wrapper for the form
  const saveWrapper = React.useCallback(() => {
    return save();
  }, [workingActionItem, actionItems]);

  const save = React.useCallback(
    async (templateToSaveWithoutEdit?: WorkingActionItem) => {
      if (!actionItems) {
        return;
      }

      const actionItemToSave = templateToSaveWithoutEdit || workingActionItem;
      assertExists(actionItemToSave, 'an action item must exist to save');

      let title: string | undefined;
      let description: string | undefined;
      const isUsed = actionItemToSave.template?.isUsed;

      setIsSaving(true);
      if (!templateToSaveWithoutEdit) {
        hideModal();

        const {title: formTitle, description: formDescription} = formMethods.getValues();
        title = formTitle;
        description = formDescription;
      } else {
        const {template} = templateToSaveWithoutEdit;

        assertExists(template, 'Template must exist before saving');
        assertExists(template.title, 'Template title must exist before saving');
        assertExists(template.description, 'Template description must exist before saving');
        title = template.title;
        description = template.description;
      }

      let newActionItems: ActionItem[] | undefined;

      if (actionItemToSave.type === ActionItemType.FromTemplate) {
        assertExists(actionItemToSave.template, 'template must exist to when type is template');
        const templateId: string = actionItemToSave.template.id;

        if (isUsed) {
          const existingActionItem = actionItems.find(
            (i) => i.templateId === actionItemToSave.template?.id
          );
          assertExists(existingActionItem, 'Action item must exist in order to update');
          newActionItems = (
            await asyncDispatch(
              updateActionItem({actionItemId: existingActionItem.id, title, description})
            )
          ).payload;
        } else {
          newActionItems = (await asyncDispatch(createActionItem({title, description, templateId})))
            .payload;
        }
        const usedTemplate = templates.find((t) => t.id === templateId);
        assertExists(usedTemplate, `Could not find selected template ${templateId}`);
        usedTemplate.isUsed = true;
        setRecentlyUsedTemplates([...recentlyUsedTemplates, usedTemplate.id]);
      } else {
        newActionItems = (
          await asyncDispatch(
            createActionItem({
              title,
              description,
              attribute: actionItemToSave.attribute,
            })
          )
        ).payload;
      }

      const newActionItem = newActionItems.find((i) => i.title === title);
      assertExists(newActionItem, `Expected to find action item with title ${title}`);

      setIsSaving(false);
      setWorkingActionItem(undefined);

      const message = isUsed
        ? 'Your action item was updated.'
        : 'A new action item was added to your Action Planner.';
      showSuccessAlert(`Success! ${message}`);
    },
    [workingActionItem, actionItems, recentlyUsedTemplates]
  );

  const cancel = React.useCallback(() => {
    const {title, description} = formMethods.getValues();

    const isUntouchedNewItem =
      workingActionItem?.type === ActionItemType.New && !title && !description;

    const isUntouchedTemplateItem =
      workingActionItem?.type === ActionItemType.FromTemplate &&
      title === workingActionItem.template?.title &&
      description === workingActionItem.template.description;

    const fieldsAreUntouched = isUntouchedNewItem || isUntouchedTemplateItem;

    if (fieldsAreUntouched) {
      setWorkingActionItem(undefined);
    } else {
      showModal(
        <ActionItemDestructiveModal
          type={ActionItemModalType.Discard}
          onYes={() => {
            setWorkingActionItem(undefined);
          }}
        />
      );
    }
  }, [workingActionItem]);

  const confirmDelete = React.useCallback((actionItem: ActionItem) => {
    showModal(
      <ActionItemDestructiveModal
        type={ActionItemModalType.DeleteActionItem}
        onYes={async () => {
          const returningTemplate = templates.find((t) => t.id === actionItem.templateId);
          assertExists(
            returningTemplate,
            `Could not find returning template with ID ${actionItem.templateId}`
          );
          returningTemplate.isUsed = false;
          await asyncDispatch(deleteActionItem(actionItem.id));
        }}
      />
    );
  }, []);

  const buildRecommendedActions = React.useCallback(() => {
    if (!actionItems) {
      return <></>;
    }

    return templates.map((template: ActionItemWidgetTemplate, idx: number) => {
      const templateId = template.id;
      const isUsed = template.isUsed;
      const wasRecentlyUsed = recentlyUsedTemplates.find((id) => id === template.id);
      let title = template.title;
      let description = template.description;
      let existingActionItem: ActionItem | undefined;
      let buttonTitle = 'Read more';

      // Use the real action item title if one was already created from this template.
      if (template.isUsed) {
        const actionItem = actionItems.find((i) => i.templateId === template.id);
        assertExists(
          actionItem,
          `Expected to find action item with templateId ${
            template.id
          }, but only found action items with these templateIds [${actionItems.map(
            (a) => a.templateId
          )}]`
        );
        title = actionItem.title;
        description = actionItem.description;
        existingActionItem = actionItem;
        buttonTitle = wasRecentlyUsed ? 'Edit' : 'View in Action Planner';
      }

      const untouchedExistingItem = Boolean(existingActionItem) && !wasRecentlyUsed;

      return (
        <StyledCheckboxContainer tabIndex={0} key={idx}>
          <IconToCheckbox
            icon={Icons.addBoxIcon}
            disabled={untouchedExistingItem}
            label={
              <LabelContainer>
                <StyledParagraph untouchedExistingItem={untouchedExistingItem}>
                  {title}
                </StyledParagraph>
                <LinkButton
                  data-qa-attribute="action-item-template-read-more"
                  size={LinkSizes.LG}
                  onClick={() => {
                    if (untouchedExistingItem) {
                      assertExists(existingActionItem, 'Action item must in order to view');

                      if (onGoToActionItem) {
                        onGoToActionItem(existingActionItem.id);
                      }
                    } else {
                      onBeginCreateOrUpdateActionItem({
                        type: ActionItemType.FromTemplate,
                        template: {
                          id: templateId,
                          title,
                          description,
                          isUsed,
                        },
                      });
                    }
                  }}
                >
                  {buttonTitle}
                </LinkButton>
              </LabelContainer>
            }
            checked={template.isUsed}
            onChange={(isChecked) => {
              if (isChecked) {
                save({
                  type: ActionItemType.FromTemplate,
                  template: template,
                });
              } else {
                assertExists(
                  existingActionItem,
                  'Should have an existing action item if unchecking'
                );
                confirmDelete(existingActionItem);
              }
            }}
          />
        </StyledCheckboxContainer>
      );
    });
  }, [actionItems, templates, workingActionItem, recentlyUsedTemplates]);

  if (!actionItems) {
    return <Spinner />;
  }

  const isDrawerOpen = Boolean(workingActionItem);

  return (
    <ActionItemWidgetContainer variant={variant}>
      <ActionItemWidgetHeader variant={variant}>
        {variant === ActionItemWidgetVariant.Results && (
          <Icon src={Icons.factCheckIcon} primaryColor={colors.gray500} small={true} />
        )}
        <ActionItemWidgetHeading variant={variant}>
          {variant === ActionItemWidgetVariant.Results
            ? `Taking Action on ${measureDisplayTitle}`
            : `${measureDisplayTitle} Action Items:`}
        </ActionItemWidgetHeading>
      </ActionItemWidgetHeader>
      <ActionItemWidgetBody>
        <NestedMarkdown components={sharedComponents}>{content}</NestedMarkdown>
        <RecommendedActionsHeader css={[vr2]} variant={variant}>
          Recommended actions
        </RecommendedActionsHeader>
        <RecommendedActions>{buildRecommendedActions()}</RecommendedActions>
        {variant === ActionItemWidgetVariant.Results && (
          <Button
            buttonType={ButtonType.Primary}
            size={ButtonSizes.MD}
            icon={Icons.addTackIcon}
            disabled={isDrawerOpen}
            data-qa-button="createActionItem"
            onClick={() => {
              onBeginCreateOrUpdateActionItem({
                type: ActionItemType.New,
                attribute: measureDisplayTitle,
              });
            }}
          >
            Create an action item
          </Button>
        )}
      </ActionItemWidgetBody>
      <DrawerForm
        isOpen={isDrawerOpen}
        formTitle="Edit Action item"
        formDescription="Details entered here will appear as a new action item in your Action Planner."
        onDismiss={cancel}
        stages={[
          {
            methods: formMethods,
            form: <TemplatedActionItemForm />,
            hint: 'Go to the Action Planner to fill out additional details on this action item.',
            actions: {
              next: {
                label: 'Save',
                onClick: saveWrapper,
              },
              cancel: {
                onClick: cancel,
              },
            },
          },
        ]}
      />
    </ActionItemWidgetContainer>
  );
};
