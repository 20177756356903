import * as React from 'react';
import {Action, ActionType} from '>shared/components/modal/modal';
import {useModal} from '>shared/components/modal/useModal';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {dvpApi} from '>root/apis';
import {rollbarLogger} from '>lib/logger';
import {Team} from '>generated/dvp.types';
import {AlertModal} from '>shared/components/modal/alertModal';

export type OnReleaseFn = (releasedTeam: Team) => void;

interface Props {
  teamId: string;
  teamName: string;
  isReportReleased?: boolean;
  onRelease: OnReleaseFn;
}

export const ReleaseTeamResultsModal: React.FC<Props> = ({
  teamId,
  teamName,
  isReportReleased,
  onRelease,
}) => {
  const [isBusy, setIsBusy] = React.useState(false);
  const {hideModal} = useModal();
  const {showSuccessAlert, showErrorAlert} = useSnackbar();

  const handleSubmit = React.useCallback(async () => {
    setIsBusy(true);

    try {
      const {data: releasedTeam} = await dvpApi.updateIsTeamReportReleased(
        {value: true},
        {teamId: teamId}
      );
      const snackbarMessage = `Success! Results for the ${teamName} team were released.`;
      showSuccessAlert(snackbarMessage);
      onRelease(releasedTeam);
    } catch (err) {
      showErrorAlert('Something went wrong. Please try again.');
      rollbarLogger.error(err as Error);
    }

    setIsBusy(false);
    hideModal();
  }, [isBusy, onRelease]);

  const modalActions: Action[] = [
    {
      type: ActionType.Primary,
      label: `${isReportReleased ? 'Resend' : 'Release'}`,
      inputType: 'button',
      disabled: isBusy,
      onClick: handleSubmit,
      qaDataAttribute: 'release-results-button',
    },
    {
      type: ActionType.Secondary,
      label: 'Cancel',
      inputType: 'button',
      disabled: isBusy,
      onClick: hideModal,
      qaDataAttribute: 'cancel-release-results',
    },
  ];

  return (
    <AlertModal
      title={'Release Report'}
      subtitle={`Report will be ${
        isReportReleased ? 'resent' : 'released'
      } for the ${teamName} team`}
      actions={modalActions}
    />
  );
};
