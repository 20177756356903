/*
    DO NOT MODIFY THIS FILE. THIS FILE WAS AUTOMATICALLY GENERATED BY WND-SWAGGER-TS-CLIENT-GENERATOR
*/

/* eslint-disable */
import * as generatedTypes from './dvp.types';

import {httpClient, RequestOptions, Response, toBearer, buildJsonPatch} from 'wnd-util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function pick(params: Record<string, unknown>, fields: string[]): Record<string, string> {
  return fields.reduce((acc, key) => {
    if (params[key] !== undefined) {
      acc[key] = params[key];
    }
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as Record<string, any>);
}

interface Options {
  // If provided, gets access token during each request
  getAuthToken?(): Promise<string>;
}

export class Api {
  private client = httpClient.create();

  constructor(private rootUrl: string, private options: Options = {}) {
    this.options = options;
  }

  setAccessToken(accessToken: string): void {
    if (this.options.getAuthToken) {
      throw new Error('Should not call setAccessToken method when getAuthToken method is provided');
    }

    this.client.setAuthToken(accessToken);
  }

  clearAccessToken(): void {
    if (this.options.getAuthToken) {
      throw new Error(
        'Should not call clearAccessToken method when getAuthToken method is provided'
      );
    }

    this.client.clearAuthToken();
  }

  private async request<RequestPayload, ResponsePayload, QueryParams>(
    requestOptions: RequestOptions<RequestPayload, QueryParams>
  ): Promise<Response<ResponsePayload>> {
    const updatedRequestOptions = {
      ...requestOptions,
    };

    updatedRequestOptions.headers = requestOptions.headers ?? {};

    if (this.options.getAuthToken) {
      const authToken = await this.options.getAuthToken();
      updatedRequestOptions.headers.Authorization = toBearer(authToken);
    }

    return this.client.request(updatedRequestOptions);
  }

  static healthCheck_queryParams: string[] = [];
  async healthCheck(): Promise<httpClient.Response<void>> {
    const queryObj = {};

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/healthCheck`,
      queryParams: queryObj,
    });
  }

  static addNewAccount_queryParams: string[] = [];
  async addNewAccount(
    body: generatedTypes.NewAccount
  ): Promise<httpClient.Response<generatedTypes.Account & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts`,
      queryParams: queryObj,
      body,
    });
  }

  static searchAccountsForToolkit_queryParams: string[] = ['limit'];
  async searchAccountsForToolkit(
    params: {searchString: string} & {limit?: number}
  ): Promise<httpClient.Response<Array<string>>> {
    const queryObj = pick(params, Api.searchAccountsForToolkit_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/toolkitSearch/${params.searchString}`,
      queryParams: queryObj,
    });
  }

  static searchEmployeesForToolkit_queryParams: string[] = ['limit'];
  async searchEmployeesForToolkit(
    params: {searchString: string} & {limit?: number}
  ): Promise<httpClient.Response<generatedTypes.EmployeesToolkitSearch>> {
    const queryObj = pick(params, Api.searchEmployeesForToolkit_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/employees/toolkitSearch/${params.searchString}`,
      queryParams: queryObj,
    });
  }

  static getAccountById_queryParams: string[] = [];
  async getAccountById(
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Account & void>> {
    const queryObj = pick(params, Api.getAccountById_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/authUserId/${params.accountId}`,
      queryParams: queryObj,
    });
  }

  static getMyUser_queryParams: string[] = [];
  async getMyUser(
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.User & void>> {
    const queryObj = pick(params, Api.getMyUser_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/users/me`,
      queryParams: queryObj,
    });
  }

  static getEmployeeCount_queryParams: string[] = [];
  async getEmployeeCount(
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<number & void>> {
    const queryObj = pick(params, Api.getEmployeeCount_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/count`,
      queryParams: queryObj,
    });
  }

  static searchEmployeesByName_queryParams: string[] = ['searchQuery'];
  async searchEmployeesByName(
    params: {accountId: string} & {searchQuery: string}
  ): Promise<httpClient.Response<Array<generatedTypes.EmployeeNames>>> {
    const queryObj = pick(params, Api.searchEmployeesByName_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/names`,
      queryParams: queryObj,
    });
  }

  static updateUserData_queryParams: string[] = [];
  async updateUserData(
    __prev_body: generatedTypes.UserData,
    __next_body: generatedTypes.UserData,
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.UserData>> {
    const queryObj = pick(params, Api.updateUserData_queryParams);

    const body = buildJsonPatch(__prev_body, __next_body);

    return this.request({
      method: 'patch',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/userData`,
      queryParams: queryObj,
      body,
    });
  }

  static getEmployeeById_queryParams: string[] = [];
  async getEmployeeById(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<generatedTypes.Employee & {employeeTableVersionId?: string} & void>
  > {
    const queryObj = pick(params, Api.getEmployeeById_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}`,
      queryParams: queryObj,
    });
  }

  static updateEmployee_queryParams: string[] = ['ephemeralEmployeeId'];
  async updateEmployee(
    body: generatedTypes.UpdateEmployee,
    params: {accountId: string; employeeId: string} & {ephemeralEmployeeId: string}
  ): Promise<
    httpClient.Response<generatedTypes.Employee & {employeeTableVersionId?: string} & void & void>
  > {
    const queryObj = pick(params, Api.updateEmployee_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}`,
      queryParams: queryObj,
      body,
    });
  }

  static deleteEmployee_queryParams: string[] = [];
  async deleteEmployee(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<generatedTypes.Employee & {employeeTableVersionId?: string} & void & void>
  > {
    const queryObj = pick(params, Api.deleteEmployee_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}`,
      queryParams: queryObj,
    });
  }

  static checkEmployeeCookieConsent_queryParams: string[] = [];
  async checkEmployeeCookieConsent(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<boolean & void>> {
    const queryObj = pick(params, Api.checkEmployeeCookieConsent_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/checkCookieConsent`,
      queryParams: queryObj,
    });
  }

  static acceptEmployeeCookieConsent_queryParams: string[] = [];
  async acceptEmployeeCookieConsent(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.acceptEmployeeCookieConsent_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/acceptCookieConsent`,
      queryParams: queryObj,
    });
  }

  static archiveEmployee_queryParams: string[] = [];
  async archiveEmployee(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<generatedTypes.Employee & {employeeTableVersionId?: string} & void & void>
  > {
    const queryObj = pick(params, Api.archiveEmployee_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/archive`,
      queryParams: queryObj,
    });
  }

  static unArchiveEmployee_queryParams: string[] = [];
  async unArchiveEmployee(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<generatedTypes.Employee & {employeeTableVersionId?: string} & void & void>
  > {
    const queryObj = pick(params, Api.unArchiveEmployee_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/unarchive`,
      queryParams: queryObj,
    });
  }

  static getEmployeeByEmail_queryParams: string[] = [];
  async getEmployeeByEmail(
    params: {accountId: string; employeeEmail: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<generatedTypes.Employee & {employeeTableVersionId?: string} & void>
  > {
    const queryObj = pick(params, Api.getEmployeeByEmail_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/email/${params.employeeEmail}`,
      queryParams: queryObj,
    });
  }

  static getEmployeeTeams_queryParams: string[] = [];
  async getEmployeeTeams(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Teams & void>> {
    const queryObj = pick(params, Api.getEmployeeTeams_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/teams`,
      queryParams: queryObj,
    });
  }

  static getEmployeesByAccountId_queryParams: string[] = [
    'pageSize',
    'offset',
    'sort',
    'sortDirection',
    'searchText',
    'departments',
    'jobTitles',
    'locations',
    'assessmentStatuses',
    'includeTags',
  ];
  async getEmployeesByAccountId(
    params: {accountId: string} & {
      pageSize?: number;
      offset?: number;
      sort?: generatedTypes.EmployeeSortField;
      sortDirection?: generatedTypes.SortDirection;
      searchText?: string;
      departments?: Array<string>;
      jobTitles?: Array<string>;
      locations?: Array<string>;
      assessmentStatuses?: Array<string>;
      includeTags?: boolean;
    }
  ): Promise<
    httpClient.Response<generatedTypes.Employees & {employeeTableVersionId?: string} & void>
  > {
    const queryObj = pick(params, Api.getEmployeesByAccountId_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees`,
      queryParams: queryObj,
    });
  }

  static quickImportEmployee_queryParams: string[] = [];
  async quickImportEmployee(
    body: generatedTypes.NewEmployee,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<generatedTypes.Employee & {employeeTableVersionId?: string} & number>
  > {
    const queryObj = pick(params, Api.quickImportEmployee_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees`,
      queryParams: queryObj,
      body,
    });
  }

  static stageBulkEmployeeImport_queryParams: string[] = [];
  async stageBulkEmployeeImport(
    body: FormData,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<generatedTypes.BulkImportStageResult & generatedTypes.BulkImportStageResult>
  > {
    const queryObj = pick(params, Api.stageBulkEmployeeImport_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/stageImport`,
      queryParams: queryObj,
      body,
      headers: {'content-type': 'multipart/form-data'},
    });
  }

  static commitBulkEmployeeImport_queryParams: string[] = [];
  async commitBulkEmployeeImport(
    body: {commitId: string},
    params: {accountId: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<
      generatedTypes.BulkImportCommitResult & generatedTypes.BulkImportCommitResult
    >
  > {
    const queryObj = pick(params, Api.commitBulkEmployeeImport_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/commitImport`,
      queryParams: queryObj,
      body,
    });
  }

  static mapAlternateJobTitleToJobProfile_queryParams: string[] = [];
  async mapAlternateJobTitleToJobProfile(
    params: {accountId: string; employeeId: string; alternateJobTitle: string} & Record<
      string,
      unknown
    >
  ): Promise<
    httpClient.Response<generatedTypes.Employee & {employeeTableVersionId?: string} & void & void>
  > {
    const queryObj = pick(params, Api.mapAlternateJobTitleToJobProfile_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/alternateJobTitle/${params.alternateJobTitle}`,
      queryParams: queryObj,
    });
  }

  static acceptEmployeeUserInvite_queryParams: string[] = [];
  async acceptEmployeeUserInvite(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.acceptEmployeeUserInvite_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/acceptInvite`,
      queryParams: queryObj,
    });
  }

  static getEmployeesMetrics_queryParams: string[] = [];
  async getEmployeesMetrics(
    params: {accountId: string; userId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.EmployeesMetrics & void>> {
    const queryObj = pick(params, Api.getEmployeesMetrics_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/users/${params.userId}/metrics`,
      queryParams: queryObj,
    });
  }

  static shareResultsWithUser_queryParams: string[] = [];
  async shareResultsWithUser(
    body: generatedTypes.ResultsShareDetails,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.shareResultsWithUser_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/share`,
      queryParams: queryObj,
      body,
    });
  }

  static getEmployeeActionItemWidgetParams_queryParams: string[] = [];
  async getEmployeeActionItemWidgetParams(
    params: {accountId: string; employeeId: string; attribute: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ActionItemWidgetParams & void>> {
    const queryObj = pick(params, Api.getEmployeeActionItemWidgetParams_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/actionItemTemplates/${params.attribute}`,
      queryParams: queryObj,
    });
  }

  static getUsers_queryParams: string[] = [];
  async getUsers(
    params: {accountId: string} & Record<string, unknown>
  ): Promise<
    httpClient.Response<Array<generatedTypes.AccountUserWithPII & {authUserId: string}> & void>
  > {
    const queryObj = pick(params, Api.getUsers_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/users`,
      queryParams: queryObj,
    });
  }

  static addUser_queryParams: string[] = [];
  async addUser(
    body: generatedTypes.NewUser,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Account>> {
    const queryObj = pick(params, Api.addUser_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/users`,
      queryParams: queryObj,
      body,
    });
  }

  static acceptUserInvite_queryParams: string[] = [];
  async acceptUserInvite(
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.acceptUserInvite_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/acceptInvite`,
      queryParams: queryObj,
    });
  }

  static resendUserInvitation_queryParams: string[] = [];
  async resendUserInvitation(
    params: {accountId: string; authUserId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.resendUserInvitation_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/users/${params.authUserId}/invite`,
      queryParams: queryObj,
    });
  }

  static updateUser_queryParams: string[] = [];
  async updateUser(
    body: generatedTypes.UserUpdate,
    params: {accountId: string; authUserId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Account & void>> {
    const queryObj = pick(params, Api.updateUser_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/accounts/${params.accountId}/users/${params.authUserId}`,
      queryParams: queryObj,
      body,
    });
  }

  static deleteUser_queryParams: string[] = [];
  async deleteUser(
    params: {accountId: string; authUserId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Account & void>> {
    const queryObj = pick(params, Api.deleteUser_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/accounts/${params.accountId}/users/${params.authUserId}`,
      queryParams: queryObj,
    });
  }

  static getCoachTeams_queryParams: string[] = [];
  async getCoachTeams(
    params: {accountId: string; authUserId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Teams & void>> {
    const queryObj = pick(params, Api.getCoachTeams_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/users/${params.authUserId}/teams`,
      queryParams: queryObj,
    });
  }

  static shareTeamReportWithUser_queryParams: string[] = [];
  async shareTeamReportWithUser(
    body: generatedTypes.TeamReportShareDetails,
    params: {accountId: string; teamId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.shareTeamReportWithUser_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/teams/${params.teamId}/share`,
      queryParams: queryObj,
      body,
    });
  }

  static sendAssessmentInvitations_queryParams: string[] = [];
  async sendAssessmentInvitations(
    body: generatedTypes.BulkEmployeeSelection,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<{employeeTableVersionId: string} & void>> {
    const queryObj = pick(params, Api.sendAssessmentInvitations_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/workflows/invitations`,
      queryParams: queryObj,
      body,
    });
  }

  static checkWorkflowStatus_queryParams: string[] = [];
  async checkWorkflowStatus(
    params: {accountId: string; workflowId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.WorkflowStatusCheck & void>> {
    const queryObj = pick(params, Api.checkWorkflowStatus_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/workflows/${params.workflowId}/status`,
      queryParams: queryObj,
    });
  }

  static checkWorkflowActivityStatusWorkflowEngine_queryParams: string[] = [];
  async checkWorkflowActivityStatusWorkflowEngine(
    params: {accountId: string; workflowId: string; productId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ActivityStatusCheck & void>> {
    const queryObj = pick(params, Api.checkWorkflowActivityStatusWorkflowEngine_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/workflows/${params.workflowId}/activity/${params.productId}/status`,
      queryParams: queryObj,
    });
  }

  static checkWorkflowActivityStatusContentPlayer_queryParams: string[] = [];
  async checkWorkflowActivityStatusContentPlayer(
    params: {accountId: string; workflowId: string; productId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ActivityStatusCheck & void>> {
    const queryObj = pick(params, Api.checkWorkflowActivityStatusContentPlayer_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/workflows/${params.workflowId}/activity/${params.productId}/status`,
      queryParams: queryObj,
    });
  }

  static selectLanguage_queryParams: string[] = [];
  async selectLanguage(
    body: generatedTypes.LanguageSelection,
    params: {accountId: string; workflowId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.selectLanguage_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/workflows/${params.workflowId}/language`,
      queryParams: queryObj,
      body,
    });
  }

  static getBookResults_queryParams: string[] = [];
  async getBookResults(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Book>> {
    const queryObj = pick(params, Api.getBookResults_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/book`,
      queryParams: queryObj,
    });
  }

  static releaseResults_queryParams: string[] = [];
  async releaseResults(
    body: generatedTypes.BulkEmployeeSelection,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<{employeeTableVersionId: string} & void>> {
    const queryObj = pick(params, Api.releaseResults_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/releaseResults`,
      queryParams: queryObj,
      body,
    });
  }

  static bulkDeleteEmployees_queryParams: string[] = [];
  async bulkDeleteEmployees(
    body: generatedTypes.BulkEmployeeSelection,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.BulkEmployeeDeleteResult & void>> {
    const queryObj = pick(params, Api.bulkDeleteEmployees_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/bulkDelete`,
      queryParams: queryObj,
      body,
    });
  }

  static bulkArchiveEmployees_queryParams: string[] = [];
  async bulkArchiveEmployees(
    body: generatedTypes.BulkEmployeeSelection,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.BulkEmployeeArchiveResult>> {
    const queryObj = pick(params, Api.bulkArchiveEmployees_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/bulkArchive`,
      queryParams: queryObj,
      body,
    });
  }

  static bulkUnArchiveEmployees_queryParams: string[] = [];
  async bulkUnArchiveEmployees(
    body: generatedTypes.BulkEmployeeSelection,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.BulkEmployeeUnArchiveResult>> {
    const queryObj = pick(params, Api.bulkUnArchiveEmployees_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/bulkUnArchive`,
      queryParams: queryObj,
      body,
    });
  }

  static getBookChapterResultsPdf_queryParams: string[] = [];
  async getBookChapterResultsPdf(
    params: {accountId: string; employeeId: string; chapterNumber: string} & Record<string, unknown>
  ): Promise<httpClient.Response<string & void>> {
    const queryObj = pick(params, Api.getBookChapterResultsPdf_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/book/chapter/${params.chapterNumber}/pdf`,
      queryParams: queryObj,
    });
  }

  static getEmployeeIndexMeasures_queryParams: string[] = [];
  async getEmployeeIndexMeasures(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.IndexMeasures & void>> {
    const queryObj = pick(params, Api.getEmployeeIndexMeasures_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/indexMeasures`,
      queryParams: queryObj,
    });
  }

  static getEmployeeIndexMeasuresPdf_queryParams: string[] = [];
  async getEmployeeIndexMeasuresPdf(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<string & void>> {
    const queryObj = pick(params, Api.getEmployeeIndexMeasuresPdf_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/indexMeasures/pdf`,
      queryParams: queryObj,
    });
  }

  static getEmployeeHomePagePdf_queryParams: string[] = [];
  async getEmployeeHomePagePdf(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<string & void>> {
    const queryObj = pick(params, Api.getEmployeeHomePagePdf_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/homepage/pdf`,
      queryParams: queryObj,
    });
  }

  static getEmployeeMeasureForAllSections_queryParams: string[] = [];
  async getEmployeeMeasureForAllSections(
    params: {accountId: string; employeeId: string; measureName: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.EmployeeRenderedMeasures & void>> {
    const queryObj = pick(params, Api.getEmployeeMeasureForAllSections_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/measures/${params.measureName}`,
      queryParams: queryObj,
    });
  }

  static getEmployeeSpotlightMeasures_queryParams: string[] = [];
  async getEmployeeSpotlightMeasures(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.SpotlightMeasures>> {
    const queryObj = pick(params, Api.getEmployeeSpotlightMeasures_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/spotlightMeasures`,
      queryParams: queryObj,
    });
  }

  static getEmployeeSpotlightIwas_queryParams: string[] = [];
  async getEmployeeSpotlightIwas(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.SpotlightIwas>> {
    const queryObj = pick(params, Api.getEmployeeSpotlightIwas_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/spotlightIwas`,
      queryParams: queryObj,
    });
  }

  static listActionItems_queryParams: string[] = [];
  async listActionItems(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ActionItems>> {
    const queryObj = pick(params, Api.listActionItems_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/actionItems`,
      queryParams: queryObj,
    });
  }

  static deleteActionItem_queryParams: string[] = [];
  async deleteActionItem(
    params: {accountId: string; employeeId: string; actionItemId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void>> {
    const queryObj = pick(params, Api.deleteActionItem_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/actionItems/${params.actionItemId}`,
      queryParams: queryObj,
    });
  }

  static getActionItemPdf_queryParams: string[] = [];
  async getActionItemPdf(
    params: {accountId: string; employeeId: string; actionItemId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<string & void>> {
    const queryObj = pick(params, Api.getActionItemPdf_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/actionItems/${params.actionItemId}/pdf`,
      queryParams: queryObj,
    });
  }

  static getInterpretationBuckets_queryParams: string[] = [];
  async getInterpretationBuckets(): Promise<
    httpClient.Response<generatedTypes.VersionedInterpretationBuckets>
  > {
    const queryObj = {};

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/scoring/interpretation`,
      queryParams: queryObj,
    });
  }

  static updateInterpretationBuckets_queryParams: string[] = [];
  async updateInterpretationBuckets(
    body: generatedTypes.UpdateInterpretationBuckets
  ): Promise<httpClient.Response<void>> {
    const queryObj = {};

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/scoring/interpretation`,
      queryParams: queryObj,
      body,
    });
  }

  static createChapterItem_queryParams: string[] = [];
  async createChapterItem(
    body: generatedTypes.NewChapterItem
  ): Promise<httpClient.Response<generatedTypes.ChapterItem & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/chapter`,
      queryParams: queryObj,
      body,
    });
  }

  static updateChapterItem_queryParams: string[] = [];
  async updateChapterItem(
    body: generatedTypes.NewChapterItem,
    params: {chapterItemId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ChapterItem & void>> {
    const queryObj = pick(params, Api.updateChapterItem_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/chapter/${params.chapterItemId}`,
      queryParams: queryObj,
      body,
    });
  }

  static deleteChapterItem_queryParams: string[] = [];
  async deleteChapterItem(
    params: {chapterItemId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ChapterItem & void>> {
    const queryObj = pick(params, Api.deleteChapterItem_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/chapter/${params.chapterItemId}`,
      queryParams: queryObj,
    });
  }

  static getChapterContent_queryParams: string[] = [];
  async getChapterContent(
    params: {chapterNumber: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ChapterContent>> {
    const queryObj = pick(params, Api.getChapterContent_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/chapter/${params.chapterNumber}/content`,
      queryParams: queryObj,
    });
  }

  static listRenderedHomePageItems_queryParams: string[] = [];
  async listRenderedHomePageItems(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.HomePageItems>> {
    const queryObj = pick(params, Api.listRenderedHomePageItems_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/renderedHomePageItems`,
      queryParams: queryObj,
    });
  }

  static listHomePageItems_queryParams: string[] = [];
  async listHomePageItems(): Promise<httpClient.Response<generatedTypes.HomePageItems>> {
    const queryObj = {};

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/homePageItems`,
      queryParams: queryObj,
    });
  }

  static createHomePageItem_queryParams: string[] = [];
  async createHomePageItem(
    body: generatedTypes.NewHomePageItem
  ): Promise<httpClient.Response<generatedTypes.HomePageItem>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/homePageItems`,
      queryParams: queryObj,
      body,
    });
  }

  static updateHomePageItem_queryParams: string[] = [];
  async updateHomePageItem(
    body: generatedTypes.HomePageItemUpdate,
    params: {homePageItemId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.HomePageItem>> {
    const queryObj = pick(params, Api.updateHomePageItem_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/homePageItems/${params.homePageItemId}`,
      queryParams: queryObj,
      body,
    });
  }

  static deleteHomePageItem_queryParams: string[] = [];
  async deleteHomePageItem(
    params: {homePageItemId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void>> {
    const queryObj = pick(params, Api.deleteHomePageItem_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/homePageItems/${params.homePageItemId}`,
      queryParams: queryObj,
    });
  }

  static listActionPlannerWelcomeItems_queryParams: string[] = [];
  async listActionPlannerWelcomeItems(): Promise<
    httpClient.Response<generatedTypes.ActionPlannerWelcomeItems>
  > {
    const queryObj = {};

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/actionPlannerWelcomeItems`,
      queryParams: queryObj,
    });
  }

  static createActionPlannerWelcomeItem_queryParams: string[] = [];
  async createActionPlannerWelcomeItem(
    body: generatedTypes.NewActionPlannerWelcomeItem
  ): Promise<httpClient.Response<generatedTypes.ActionPlannerWelcomeItem>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/actionPlannerWelcomeItems`,
      queryParams: queryObj,
      body,
    });
  }

  static listRenderedActionPlannerWelcomeItems_queryParams: string[] = [];
  async listRenderedActionPlannerWelcomeItems(
    params: {accountId: string; employeeId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ActionPlannerWelcomeItems>> {
    const queryObj = pick(params, Api.listRenderedActionPlannerWelcomeItems_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/employees/${params.employeeId}/renderedActionPlannerWelcomeItems`,
      queryParams: queryObj,
    });
  }

  static updateActionPlannerWelcomeItem_queryParams: string[] = [];
  async updateActionPlannerWelcomeItem(
    body: generatedTypes.ActionPlannerWelcomeItemUpdate,
    params: {actionPlannerWelcomeItemId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ActionPlannerWelcomeItem>> {
    const queryObj = pick(params, Api.updateActionPlannerWelcomeItem_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/actionPlannerWelcomeItems/${params.actionPlannerWelcomeItemId}`,
      queryParams: queryObj,
      body,
    });
  }

  static deleteActionPlannerWelcomeItem_queryParams: string[] = [];
  async deleteActionPlannerWelcomeItem(
    params: {actionPlannerWelcomeItemId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.deleteActionPlannerWelcomeItem_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/actionPlannerWelcomeItems/${params.actionPlannerWelcomeItemId}`,
      queryParams: queryObj,
    });
  }

  static createActionItem_queryParams: string[] = [];
  async createActionItem(
    body: generatedTypes.NewActionItem
  ): Promise<httpClient.Response<generatedTypes.ActionItem>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/actionItems`,
      queryParams: queryObj,
      body,
    });
  }

  static updateActionItem_queryParams: string[] = [];
  async updateActionItem(
    body: generatedTypes.UpdateActionItem
  ): Promise<httpClient.Response<generatedTypes.ActionItem>> {
    const queryObj = {};

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/actionItems`,
      queryParams: queryObj,
      body,
    });
  }

  static getActionItemAttributes_queryParams: string[] = [];
  async getActionItemAttributes(): Promise<
    httpClient.Response<generatedTypes.ActionItemAttributes>
  > {
    const queryObj = {};

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/actionItems/attributes`,
      queryParams: queryObj,
    });
  }

  static createActionItemFromTemplate_queryParams: string[] = [];
  async createActionItemFromTemplate(
    body: generatedTypes.NewTemplatedActionItem
  ): Promise<httpClient.Response<generatedTypes.ActionItem>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/actionItems/createFromTemplate`,
      queryParams: queryObj,
      body,
    });
  }

  static emailChangedWebhook_queryParams: string[] = [];
  async emailChangedWebhook(body: generatedTypes.Notification): Promise<httpClient.Response<void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/webhooks/email/changed`,
      queryParams: queryObj,
      body,
    });
  }

  static userChangedWebhook_queryParams: string[] = [];
  async userChangedWebhook(body: generatedTypes.Notification): Promise<httpClient.Response<void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/webhooks/user/changed`,
      queryParams: queryObj,
      body,
    });
  }

  static billingAccountChangedWebhook_queryParams: string[] = [];
  async billingAccountChangedWebhook(
    body: generatedTypes.Notification
  ): Promise<httpClient.Response<void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/webhooks/billingAccount/changed`,
      queryParams: queryObj,
      body,
    });
  }

  static listWorkflows_queryParams: string[] = ['initiatingUserIds'];
  async listWorkflows(
    params: Record<string, unknown> & {initiatingUserIds?: string}
  ): Promise<httpClient.Response<generatedTypes.Workflows>> {
    const queryObj = pick(params, Api.listWorkflows_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/workflows`,
      queryParams: queryObj,
    });
  }

  static updateBillingOverride_queryParams: string[] = [];
  async updateBillingOverride(
    body: generatedTypes.UpdateBillingOverride,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void>> {
    const queryObj = pick(params, Api.updateBillingOverride_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/accounts/${params.accountId}/billingOverride`,
      queryParams: queryObj,
      body,
    });
  }

  static getAccountTeams_queryParams: string[] = [];
  async getAccountTeams(
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Teams>> {
    const queryObj = pick(params, Api.getAccountTeams_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/teams`,
      queryParams: queryObj,
    });
  }

  static updateEnableTeams_queryParams: string[] = [];
  async updateEnableTeams(
    body: generatedTypes.UpdateEnableTeams,
    params: {accountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void>> {
    const queryObj = pick(params, Api.updateEnableTeams_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/accounts/${params.accountId}/teams`,
      queryParams: queryObj,
      body,
    });
  }

  static doesTeamExist_queryParams: string[] = ['teamName'];
  async doesTeamExist(
    params: {accountId: string} & {teamName: string}
  ): Promise<httpClient.Response<boolean>> {
    const queryObj = pick(params, Api.doesTeamExist_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/teams/exists`,
      queryParams: queryObj,
    });
  }

  static getTeamById_queryParams: string[] = [];
  async getTeamById(
    params: {accountId: string; teamId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Team>> {
    const queryObj = pick(params, Api.getTeamById_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/accounts/${params.accountId}/teams/${params.teamId}`,
      queryParams: queryObj,
    });
  }

  static listActionItemTemplates_queryParams: string[] = ['attributes'];
  async listActionItemTemplates(
    params: Record<string, unknown> & {attributes?: Array<string>}
  ): Promise<httpClient.Response<generatedTypes.ActionItemTemplates>> {
    const queryObj = pick(params, Api.listActionItemTemplates_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/actionItemTemplates`,
      queryParams: queryObj,
    });
  }

  static actionItemTemplateImport_queryParams: string[] = [];
  async actionItemTemplateImport(
    body: FormData
  ): Promise<
    httpClient.Response<
      generatedTypes.ActionItemTemplateImportResult & generatedTypes.ActionItemTemplateImportResult
    >
  > {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/actionItemTemplates`,
      queryParams: queryObj,
      body,
      headers: {'content-type': 'multipart/form-data'},
    });
  }

  static bulkDeleteActionItemTemplates_queryParams: string[] = [];
  async bulkDeleteActionItemTemplates(
    body: generatedTypes.BulkActionItemTemplateSelection
  ): Promise<httpClient.Response<generatedTypes.BulkActionItemTemplateDeleteResult>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/actionItemTemplates/bulkDelete`,
      queryParams: queryObj,
      body,
    });
  }

  static getActionItemTemplateById_queryParams: string[] = [];
  async getActionItemTemplateById(
    params: {actionItemTemplateId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ActionItemTemplate>> {
    const queryObj = pick(params, Api.getActionItemTemplateById_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/actionItemTemplates/${params.actionItemTemplateId}`,
      queryParams: queryObj,
    });
  }

  static createTeam_queryParams: string[] = [];
  async createTeam(
    body: generatedTypes.NewTeam
  ): Promise<httpClient.Response<generatedTypes.Team & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/teams`,
      queryParams: queryObj,
      body,
    });
  }

  static addTeamMembers_queryParams: string[] = [];
  async addTeamMembers(
    body: Array<generatedTypes.TeamMember>,
    params: {teamId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Team & void>> {
    const queryObj = pick(params, Api.addTeamMembers_queryParams);

    return this.request({
      method: 'patch',
      url: `${this.rootUrl}/teams/${params.teamId}/members`,
      queryParams: queryObj,
      body,
    });
  }

  static updateTeam_queryParams: string[] = [];
  async updateTeam(
    body: generatedTypes.UpdateTeamParams,
    params: {teamId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Team & void>> {
    const queryObj = pick(params, Api.updateTeam_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/teams/${params.teamId}/members`,
      queryParams: queryObj,
      body,
    });
  }

  static removeTeamMembers_queryParams: string[] = [];
  async removeTeamMembers(
    body: Array<string>,
    params: {teamId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Team & void>> {
    const queryObj = pick(params, Api.removeTeamMembers_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/teams/${params.teamId}/members`,
      queryParams: queryObj,
      body,
    });
  }

  static getTeamReport_queryParams: string[] = [];
  async getTeamReport(
    params: {teamId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.TeamReport & void & void>> {
    const queryObj = pick(params, Api.getTeamReport_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/teams/${params.teamId}/report`,
      queryParams: queryObj,
    });
  }

  static getTeamReportPdf_queryParams: string[] = [];
  async getTeamReportPdf(
    params: {teamId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.TeamReport & void & void>> {
    const queryObj = pick(params, Api.getTeamReportPdf_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/teams/${params.teamId}/report/pdf`,
      queryParams: queryObj,
    });
  }

  static updateIsTeamReportReleased_queryParams: string[] = [];
  async updateIsTeamReportReleased(
    body: generatedTypes.BooleanWrapper,
    params: {teamId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.Team & void>> {
    const queryObj = pick(params, Api.updateIsTeamReportReleased_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/teams/${params.teamId}/isReportReleased`,
      queryParams: queryObj,
      body,
    });
  }

  static employeeAccountTransfer_queryParams: string[] = [];
  async employeeAccountTransfer(
    params: {employeeId: string; unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.employeeAccountTransfer_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/employees/${params.employeeId}/unifiedAccount/${params.unifiedAccountId}`,
      queryParams: queryObj,
    });
  }

  static getDomainTeamReportItems_queryParams: string[] = [];
  async getDomainTeamReportItems(
    params: {teamReportDomain: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.TeamReportItems>> {
    const queryObj = pick(params, Api.getDomainTeamReportItems_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/teamReportItems/${params.teamReportDomain}`,
      queryParams: queryObj,
    });
  }

  static updateTeamReportItem_queryParams: string[] = [];
  async updateTeamReportItem(
    body: generatedTypes.TeamReportItem
  ): Promise<httpClient.Response<generatedTypes.TeamReportItem>> {
    const queryObj = {};

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/teamReportItems`,
      queryParams: queryObj,
      body,
    });
  }
}
