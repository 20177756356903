import * as React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {history} from './history';
import {rollbarLogger} from '>lib/logger';
import {RouterErrorBoundary} from '>components/routerErrorBoundary';

import {getUserSideBarMenuItems} from '>root/menus/sideBarMenuItems';
import {SideBar, SideBarItemProps} from '>shared/components/sideBar/sideBar';
import {ModalProvider} from '>shared/components/modal/useModal';
import {ResponsiveLayoutProvider} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {ScrollToTop} from '>shared/components/util/scrollToTop';
import {
  AppContainer,
  AppBody,
  SidebarOverflow,
  Main,
} from '>shared/components/layouts/responsive/responsiveAppComponents';
import {AuthenticatedHeader} from '>components/header/authenticatedHeader';
import {EmployeeImpersonationBanner} from './components/employeeImpersonationBanner';

import {AppRoute} from '>shared/lib/navigation';
import {DVPErrorPage} from './pages/errorPage/page';

import {useUserPref} from 'wnd-react-utils/lib/hooks/localStorage';
import {UserPref} from './configuration';
import {mediaQueries} from '>shared/styles/breakpoints';

import {SnackbarProvider} from '>shared/components/snackbars/useSnackbar';
import {Snackbar} from '>shared/components/snackbars/snackbar';
import {isCoachWithTeamAccess, useAccountUser} from '>lib/user';
import {store} from './store/main';
import {IntercomState, PendoState} from './store/reducers/vendors';
import {updatePendoState, updateIntercomState} from './store/actions/vendors';

import {Pendo} from '>shared/components/scripts/pendo';
import {HeaderBrandingLink} from '>components/header/headerBrandingLink';
import {CookieBanner} from '>components/cookieBanner';

function App({routes}: {routes: AppRoute[]}) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  React.useEffect(() => {
    if (process.env.INTERCOM_ENABLED) {
      store.dispatch(updateIntercomState(IntercomState.READY_TO_BOOT));
    }
  }, []);
  const employee = useSelector((state) => state.employee.employee);
  const isImpersonatingEmployee = employee?.isImpersonated;
  const accountUser = useAccountUser();
  const teams = useSelector((state) => state.employee.teams);
  const account = useSelector((state) => state.account.account);
  const isTeamReportEnabled = account?.enableTeams;
  

  const sideBarMenuItems: SideBarItemProps[] = getUserSideBarMenuItems({
    canManageUsers: accountUser?.permissions.canManageUsers,
    role: accountUser?.role,
    isNonArchivedEmployee: Boolean(employee) && !employee?.isArchived,
    isImpersonatingEmployee,
    isTeamMember: teams && teams.length > 0,
    isTeamReportEnabled,
    hasAccessibleTeams: accountUser && isCoachWithTeamAccess(accountUser),
  });

  const [isSideBarExpanded, setIsSideBarExpanded] = useUserPref(
    UserPref.IsSidebarExpanded,
    false,
    1
  );

  const collapseSideBarOnDesktop = React.useCallback(() => {
    const media = window.matchMedia(mediaQueries.mediumOrHigher.media);
    if (media.matches) {
      setIsSideBarExpanded(false);
    }
  }, []);

  React.useEffect(collapseSideBarOnDesktop, []);

  window.addEventListener('resize', collapseSideBarOnDesktop);

  return (
    <AppContainer className="App">
      <SnackbarProvider>
        <ModalProvider>
          <ResponsiveLayoutProvider>
            <React.Suspense fallback="">
              <Router history={history}>
                <ScrollToTop />
                {isAuthenticated && (
                  <AuthenticatedHeader
                    onSideBarToggle={() => setIsSideBarExpanded(!isSideBarExpanded)}
                    isSideBarExpanded={isSideBarExpanded}
                    onItemOpened={() => {
                      setIsSideBarExpanded(false);
                    }}
                  />
                )}
                {isImpersonatingEmployee && (
                  <EmployeeImpersonationBanner
                    name={`${employee.preferredName ?? employee.firstName} ${employee.lastName}`}
                    jobTitle={employee.jobTitle}
                  />
                )}
                <RouterErrorBoundary rollbarLogger={rollbarLogger}>
                  <Switch>
                    <Route path="/error" render={(props) => <DVPErrorPage {...props} />} />
                    <Route
                      path="/"
                      render={(_props) => (
                        <AppBody>
                          <SidebarOverflow />
                          <SideBar
                            branding={<HeaderBrandingLink />}
                            isOpen={isSideBarExpanded}
                            items={sideBarMenuItems}
                            onAnyItemSelected={() => {
                              if (mediaQueries.smallOrLower.matches) {
                                setIsSideBarExpanded(false);
                              }
                            }}
                            showToolTip={true}
                            productName={process.env.DEVELOPMENT_PRODUCT_NAME}
                            setIsOpen={setIsSideBarExpanded}
                          />
                          <Main>
                            <Switch>
                              {routes.map((route) => (
                                <Route
                                  key={route.path}
                                  exact
                                  path={`${route.path}`}
                                  render={(props) => <route.Component {...props} />}
                                ></Route>
                              ))}
                            </Switch>
                          </Main>
                          {isAuthenticated && <CookieBanner />}
                        </AppBody>
                      )}
                    />
                  </Switch>
                  <Snackbar />
                </RouterErrorBoundary>
              </Router>
            </React.Suspense>
          </ResponsiveLayoutProvider>
        </ModalProvider>
      </SnackbarProvider>
      {process.env.PENDO_ENABLED && process.env.PENDO_API_KEY && (
        <Pendo
          apiKey={process.env.PENDO_API_KEY}
          onReady={() => {
            store.dispatch(updatePendoState(PendoState.READY_TO_INITIALIZE));
          }}
        />
      )}
    </AppContainer>
  );
}

export default App;
