"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabOrientation = exports.TabActivationMethod = void 0;
exports.useTabs = useTabs;
var React = _interopRequireWildcard(require("react"));
var keys = _interopRequireWildcard(require("../util/keys"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var TabActivationMethod;
(function (TabActivationMethod) {
  TabActivationMethod[TabActivationMethod["Automatic"] = 0] = "Automatic";
  TabActivationMethod[TabActivationMethod["Manual"] = 1] = "Manual";
})(TabActivationMethod || (exports.TabActivationMethod = TabActivationMethod = {}));
var TabOrientation;
(function (TabOrientation) {
  TabOrientation[TabOrientation["Horizontal"] = 0] = "Horizontal";
  TabOrientation[TabOrientation["Vertical"] = 1] = "Vertical";
})(TabOrientation || (exports.TabOrientation = TabOrientation = {}));
function matchKeyCode(inputCode, activationCodes) {
  if (Array.isArray(activationCodes)) {
    return activationCodes.indexOf(inputCode) !== -1;
  } else {
    return inputCode === activationCodes;
  }
}
// Accessible tabs
function useTabs({
  orientation = TabOrientation.Horizontal,
  activationMethod = TabActivationMethod.Automatic
} = {}) {
  const [activeTab, setActiveTab] = React.useState();
  const prevTab = orientation === TabOrientation.Horizontal ? keys.left : keys.up;
  const nextTab = orientation === TabOrientation.Horizontal ? keys.right : keys.down;
  const firstTab = keys.home;
  const lastTab = keys.end;
  const activate = [keys.enter, keys.space];
  const tabs = React.useRef([]);
  const findIndex = React.useCallback(element => {
    return tabs.current.findIndex(([, currentElement]) => currentElement === element);
  }, []);
  const focusTab = React.useCallback(index => {
    const [, element] = tabs.current[index];
    element.focus();
  }, [activationMethod]);
  // This is purely here to prevent scrolling on certain key presses
  const onKeyDown = React.useCallback(evt => {
    if (matchKeyCode(evt.code, firstTab) || matchKeyCode(evt.code, lastTab)) {
      evt.preventDefault();
    } else if (orientation === TabOrientation.Vertical && matchKeyCode(evt.code, [keys.up, keys.down])) {
      evt.preventDefault();
      // Don't scroll in the vertical orientation
    }
  }, [orientation]);
  // We want to trigger tab navigation on key up.
  const onKeyUp = React.useCallback(evt => {
    if (matchKeyCode(evt.code, firstTab)) {
      evt.preventDefault();
      focusTab(0);
    } else if (matchKeyCode(evt.code, lastTab)) {
      evt.preventDefault();
      focusTab(tabs.current.length - 1);
    } else if (matchKeyCode(evt.code, prevTab)) {
      evt.preventDefault();
      const newIndex = Math.max(0, findIndex(evt.target) - 1);
      focusTab(newIndex);
    } else if (matchKeyCode(evt.code, nextTab)) {
      evt.preventDefault();
      const newIndex = Math.min(tabs.current.length - 1, findIndex(evt.target) + 1);
      focusTab(newIndex);
    } else if (matchKeyCode(evt.code, activate)) {
      evt.preventDefault();
      const [key] = tabs.current[findIndex(evt.target)];
      setActiveTab(key);
    }
  }, [prevTab, nextTab]);
  const onClick = React.useCallback(evt => {
    const [key] = tabs.current[findIndex(evt.target)];
    setActiveTab(key);
  }, []);
  const onFocus = React.useCallback(evt => {
    if (activationMethod === TabActivationMethod.Automatic) {
      const [key] = tabs.current[findIndex(evt.target)];
      setActiveTab(key);
    }
  }, [activationMethod]);
  const createTabRef = React.useCallback(key => element => {
    if (element) {
      setActiveTab(prevTab => prevTab === undefined ? key : prevTab);
      tabs.current.push([key, element]);
      element.addEventListener('keydown', onKeyDown);
      element.addEventListener('keyup', onKeyUp);
      element.addEventListener('click', onClick);
      element.addEventListener('focus', onFocus);
    } else {
      tabs.current.splice(tabs.current.findIndex(([currentKey]) => key === currentKey), 1);
    }
  }, []);
  const registerTab = React.useCallback(key => {
    return {
      ref: createTabRef(key),
      active: key === activeTab,
      tabIndex: key === activeTab ? undefined : -1
    };
  }, [createTabRef, activeTab]);
  return {
    registerTab,
    activeTab,
    setActiveTab
  };
}