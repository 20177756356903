import * as React from 'react';
import {HeaderItem} from '>shared/components/landmarks/header/header';
import {HeaderUserMenu} from '>shared/components/menu/headerUserMenu';
import {getUserMenuItems} from '>root/menus/userMenu';
import {getAppMenuItems} from '>root/menus/appMenu';
import {HeaderAppMenu} from '>shared/components/menu/headerAppMenu';
import {UserData} from '>root/store/actions/auth';
import {getInitials} from '>lib/user';

export const getHeaderItems = (
  user: UserData | undefined,
  onItemOpened?: () => void
): HeaderItem[] => {
  const appMenuItems = getAppMenuItems();
  const userMenuItems = getUserMenuItems();

  const initials = getInitials(user?.firstName, user?.lastName);
  const userInitial = user ? initials || 'NA' : '';

  const headerItems = [];

  if (appMenuItems.length > 1) {
    headerItems.push({
      id: 'app-menu',
      component: <HeaderAppMenu menuItems={appMenuItems} onOpen={onItemOpened} />,
    });
  }

  headerItems.push({
    id: 'user-menu',
    component: (
      <HeaderUserMenu
        menuItems={userMenuItems}
        user={{initials: userInitial, name: user?.name || '', email: user?.email || ''}}
        onOpen={onItemOpened}
      />
    ),
  });

  return headerItems;
};
