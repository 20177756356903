var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as colors from 'wnd-themes/lib/colorPalette';
import { getScoreZone } from './scoreZone';
import { linearInterpolationWithRange } from '../utils';
function generateScoringLines(average, xScale, yOffset, scoreZones) {
    var averageScoreZone = getScoreZone(average, scoreZones);
    var unHighlightedZoneColor = colors.gray200;
    var TICK_MARK_WIDTH = 4;
    var X2_OFFSET = 0.5;
    return scoreZones.map(function (zone, idx) {
        var isFirst = idx === 0;
        var isLast = idx === scoreZones.length - 1;
        var x1;
        var x2;
        if (isFirst) {
            x1 = TICK_MARK_WIDTH;
            x2 = xScale(scoreZones[idx].cutoff - X2_OFFSET);
        }
        else if (isLast) {
            x1 = xScale(scoreZones[idx - 1].cutoff + X2_OFFSET);
            x2 = xScale(scoreZones[idx].cutoff + X2_OFFSET + TICK_MARK_WIDTH);
        }
        else {
            x1 = xScale(scoreZones[idx - 1].cutoff);
            x2 = xScale(scoreZones[idx].cutoff);
        }
        return {
            x1: x1,
            x2: x2,
            y1: yOffset,
            y2: yOffset,
            stroke: zone.title === averageScoreZone.title ? zone.highlightColor : unHighlightedZoneColor,
        };
    });
}
function drawScoringLine(_a) {
    var x1 = _a.x1, x2 = _a.x2, y1 = _a.y1, y2 = _a.y2, stroke = _a.stroke;
    return (_jsx("line", { x1: x1.toString(), x2: x2.toString(), y1: y1.toString(), y2: y2.toString(), stroke: stroke, "stroke-width": "6" }));
}
function drawDotButton(fullName, isHighlighted, score, x, y, scoreZones, onClick) {
    var ariaLabel = "score for ".concat(fullName, " is ").concat(score);
    var scoreZone = getScoreZone(score, scoreZones);
    var fill = isHighlighted ? scoreZone.dotFill : colors.gray300;
    return (_jsx("circle", { cx: x.toString(), cy: y.toString(), fill: fill, "fill-opacity": "1", opacity: "1", r: "8", stroke: colors.white, strokeWidth: "2", role: "button", style: { cursor: 'pointer' }, onClick: onClick }));
}
export function drawArrowIndicator(x, barY, score, shouldRotate, scoreZones) {
    var scoreZone = getScoreZone(score, scoreZones);
    var width = 10;
    var height = 5;
    var radius = 0;
    var halfWidth = width / 2;
    var ARROW_FROM_BAR_OFFSET = 12;
    var y = shouldRotate ? barY - ARROW_FROM_BAR_OFFSET : barY + ARROW_FROM_BAR_OFFSET;
    var pathData = '';
    if (shouldRotate) {
        var topY = y - height;
        pathData = "\n    M ".concat(x, ",").concat(y, "\n    L ").concat(x - halfWidth + radius, ",").concat(topY, "\n    Q ").concat(x - halfWidth, ",").concat(topY, " ").concat(x - halfWidth + radius, ",").concat(topY, "\n    L ").concat(x + halfWidth - radius, ",").concat(topY, "\n    Q ").concat(x + halfWidth, ",").concat(topY, " ").concat(x + halfWidth - radius, ",").concat(topY, "\n    Z\n    ");
    }
    else {
        var bottomY = y + height;
        pathData = "\n    M ".concat(x, ",").concat(y, " \n    L ").concat(x - halfWidth + radius, ",").concat(bottomY, "\n    Q ").concat(x - halfWidth, ",").concat(bottomY, " ").concat(x - halfWidth + radius, ",").concat(bottomY, "\n    L ").concat(x + halfWidth - radius, ",").concat(bottomY, "\n    Q ").concat(x + halfWidth, ",").concat(bottomY, " ").concat(x + halfWidth - radius, ",").concat(bottomY, "\n    Z\n  ");
    }
    return _jsx("path", { d: pathData, fill: scoreZone.dotFill });
}
export function drawTickMark(x, y, height) {
    if (x === void 0) { x = 0; }
    if (y === void 0) { y = 0; }
    if (height === void 0) { height = 16; }
    return (_jsx("rect", { x: x.toString(), y: y.toString(), width: "4", height: height.toString(), rx: "2", fill: colors.gray500, stroke: colors.white, "stroke-width": "2" }));
}
var PLOT_HEIGHT = 48;
var TICK_MARK_PADDING = 12;
export function drawDotPlotGraphic(width, averageScore, highlightedScore, scoreZones, plotData, onSelectDot) {
    function xScale(score) {
        return linearInterpolationWithRange(score, 0, 100, TICK_MARK_PADDING, width - TICK_MARK_PADDING);
    }
    var yOffset = PLOT_HEIGHT / 2;
    var TICK_OFFSET_Y = 16;
    var scoringLines = generateScoringLines(averageScore, xScale, yOffset, scoreZones);
    var sortedPlotData = plotData.sort(function (a, b) { return a.score - b.score; });
    var dataPoints = sortedPlotData.map(function (d) {
        var fullName = "".concat(d.firstName, " ").concat(d.lastName);
        return drawDotButton(fullName, highlightedScore !== undefined && highlightedScore === d.score, d.score, xScale(d.score), yOffset, scoreZones, function () { return onSelectDot === null || onSelectDot === void 0 ? void 0 : onSelectDot(d.score); });
    });
    return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: width.toString(), height: PLOT_HEIGHT.toString(), viewBox: "0 0 ".concat(width, " ").concat(PLOT_HEIGHT) }, { children: [drawArrowIndicator(xScale(averageScore), yOffset, averageScore, false, scoreZones), drawArrowIndicator(xScale(averageScore), yOffset, averageScore, true, scoreZones), _jsxs("g", __assign({ transform: "translate(0, ".concat(yOffset, "px)") }, { children: [scoringLines.map(drawScoringLine), drawTickMark(0, TICK_OFFSET_Y), drawTickMark(width - 4, TICK_OFFSET_Y), dataPoints] }))] })));
}
