"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildAppMenuItems = void 0;
var _lodash = require("lodash");
var _icons = require("../components/icon/icons");
var _headerAppMenu = require("../components/menu/headerAppMenu");
var _menuItem = require("../components/menu/menuItem");
function mapUserProductsToItems(userProductAccounts, renderForMobile, currentProduct) {
  const items = [];
  const userProductAccountKeys = Object.keys(userProductAccounts);
  items.push({
    id: _headerAppMenu.Products.WonderlicSelect,
    href: userProductAccountKeys.includes(_headerAppMenu.Products.WonderlicSelect) ? process.env.WEBUI_WONSCORE_URL : '',
    text: process.env.SELECTION_PRODUCT_NAME,
    icon: renderForMobile ? _icons.chevronRight : undefined,
    hoverStyle: renderForMobile ? false : true,
    iconDivHidden: renderForMobile ? false : true,
    hasAccess: userProductAccountKeys.includes(_headerAppMenu.Products.WonderlicSelect),
    tooltipText: `${process.env.SELECTION_PRODUCT_NAME} is our world-class talent selection tool. Contact your account representative for more information.`,
    currentlyUsing: currentProduct === _headerAppMenu.Products.WonderlicSelect,
    qaDataAttribute: {
      type: 'data-qa-link',
      name: 'appSwitchSelect'
    }
  });
  items.push({
    id: _headerAppMenu.Products.WonderlicDevelop,
    href: userProductAccountKeys.includes(_headerAppMenu.Products.WonderlicDevelop) ? process.env.WEBUI_DVP_URL : '',
    text: process.env.DEVELOPMENT_PRODUCT_NAME,
    icon: renderForMobile ? _icons.chevronRight : undefined,
    hoverStyle: renderForMobile ? false : true,
    iconDivHidden: renderForMobile ? false : true,
    hasAccess: userProductAccountKeys.includes(_headerAppMenu.Products.WonderlicDevelop),
    tooltipText: `${process.env.DEVELOPMENT_PRODUCT_NAME} is our world-class employee development tool. Contact your account representative for more information.`,
    currentlyUsing: currentProduct === _headerAppMenu.Products.WonderlicDevelop,
    qaDataAttribute: {
      type: 'data-qa-link',
      name: 'appSwitchDevelop'
    }
  });
  return items;
}
function validateToolkitRoles(userToolkitRoles) {
  userToolkitRoles.forEach(role => {
    switch (role) {
      case _headerAppMenu.Toolkits.wonscore:
      case _headerAppMenu.Toolkits.research:
      case _headerAppMenu.Toolkits.acquiretm:
      case _headerAppMenu.Toolkits.applicantpro:
      case _headerAppMenu.Toolkits.bizmerlin:
      case _headerAppMenu.Toolkits.hiringthing:
      case _headerAppMenu.Toolkits.icims:
      case _headerAppMenu.Toolkits.jobma:
      case _headerAppMenu.Toolkits.newton:
      case _headerAppMenu.Toolkits.consulting:
        break;
      default:
        throw new Error(`The app menu does not support the toolkit role of ${role}`);
    }
  });
}
function mapInternalToolkitRolesToItems(userToolkitRoles, renderForMobile) {
  const items = [];
  userToolkitRoles.forEach(role => {
    const containsWonscoreToolkit = items.find(item => item.id === _headerAppMenu.Toolkits.wonscore);
    switch (role) {
      case _headerAppMenu.Toolkits.wonscore:
      case _headerAppMenu.Toolkits.consulting:
        // Since Consulting Toolkit is part of WonScore Toolkit, both toolkit roles need to map to a single WonScore Toolkit app
        if (!containsWonscoreToolkit) {
          items.push({
            id: _headerAppMenu.Toolkits.wonscore,
            href: process.env.WEBUI_TOOLKIT_WONSCORE_URL,
            text: 'Toolkit',
            icon: renderForMobile ? _icons.chevronRight : undefined,
            hoverStyle: renderForMobile ? false : true,
            iconDivHidden: renderForMobile ? false : true
          });
        }
        break;
      case _headerAppMenu.Toolkits.research:
        items.push({
          id: _headerAppMenu.Toolkits.research,
          href: process.env.WEBUI_TOOLKIT_RESEARCH_URL,
          text: 'R&D Toolkit',
          icon: renderForMobile ? _icons.chevronRight : undefined,
          hoverStyle: renderForMobile ? false : true,
          iconDivHidden: renderForMobile ? false : true
        });
        break;
      default:
        validateToolkitRoles(userToolkitRoles);
    }
  });
  return (0, _lodash.sortBy)(items, item => {
    return item.id !== _headerAppMenu.Toolkits.wonscore;
  });
}
function mapPartnerToolkitRolesToItems(userToolkitRoles, renderForMobile) {
  const items = [];
  userToolkitRoles.forEach(role => {
    switch (role) {
      case _headerAppMenu.Toolkits.acquiretm:
        items.push({
          id: _headerAppMenu.Toolkits.acquiretm,
          href: process.env.WEBUI_TOOLKIT_ACQUIRETM_URL,
          text: 'AcquireTM Toolkit',
          icon: renderForMobile ? _icons.chevronRight : undefined,
          hoverStyle: renderForMobile ? false : true,
          iconDivHidden: renderForMobile ? false : true
        });
        break;
      case _headerAppMenu.Toolkits.applicantpro:
        items.push({
          id: _headerAppMenu.Toolkits.applicantpro,
          href: process.env.WEBUI_TOOLKIT_APPLICANTPRO_URL,
          text: 'ApplicantPro Toolkit',
          icon: renderForMobile ? _icons.chevronRight : undefined,
          hoverStyle: renderForMobile ? false : true,
          iconDivHidden: renderForMobile ? false : true
        });
        break;
      case _headerAppMenu.Toolkits.bizmerlin:
        items.push({
          id: _headerAppMenu.Toolkits.bizmerlin,
          href: process.env.WEBUI_TOOLKIT_BIZMERLIN_URL,
          text: 'BizMerlin Toolkit',
          icon: renderForMobile ? _icons.chevronRight : undefined,
          hoverStyle: renderForMobile ? false : true,
          iconDivHidden: renderForMobile ? false : true
        });
        break;
      case _headerAppMenu.Toolkits.hiringthing:
        items.push({
          id: _headerAppMenu.Toolkits.hiringthing,
          href: process.env.WEBUI_TOOLKIT_HIRINGTHING_URL,
          text: 'HiringThing Toolkit',
          icon: renderForMobile ? _icons.chevronRight : undefined,
          hoverStyle: renderForMobile ? false : true,
          iconDivHidden: renderForMobile ? false : true
        });
        break;
      case _headerAppMenu.Toolkits.icims:
        items.push({
          id: _headerAppMenu.Toolkits.icims,
          href: process.env.WEBUI_TOOLKIT_ICIMS_URL,
          text: 'iCIMS Toolkit',
          icon: renderForMobile ? _icons.chevronRight : undefined,
          hoverStyle: renderForMobile ? false : true,
          iconDivHidden: renderForMobile ? false : true
        });
        break;
      case _headerAppMenu.Toolkits.jobma:
        items.push({
          id: _headerAppMenu.Toolkits.jobma,
          href: process.env.WEBUI_TOOLKIT_JOBMA_URL,
          text: 'Jobma Toolkit',
          icon: renderForMobile ? _icons.chevronRight : undefined,
          hoverStyle: renderForMobile ? false : true,
          iconDivHidden: renderForMobile ? false : true
        });
        break;
      case _headerAppMenu.Toolkits.newton:
        items.push({
          id: _headerAppMenu.Toolkits.newton,
          href: process.env.WEBUI_TOOLKIT_NEWTON_URL,
          text: 'Newton Toolkit',
          icon: renderForMobile ? _icons.chevronRight : undefined,
          hoverStyle: renderForMobile ? false : true,
          iconDivHidden: renderForMobile ? false : true
        });
        break;
      default:
        validateToolkitRoles(userToolkitRoles);
    }
  });
  return items;
}
const buildAppMenuItems = (productAccounts, toolkitRoles, renderForMobile, currentProduct, canManageBilling) => {
  let items = [];
  if (productAccounts) {
    items = mapUserProductsToItems(productAccounts, renderForMobile, currentProduct);
  }
  if (toolkitRoles && toolkitRoles.length) {
    const sortedToolkitRoles = (0, _lodash.sortBy)(toolkitRoles);
    const internalToolkitMenuItems = mapInternalToolkitRolesToItems(sortedToolkitRoles, renderForMobile);
    const partnerToolkitMenuItems = mapPartnerToolkitRolesToItems(sortedToolkitRoles, renderForMobile);
    items = items.concat(internalToolkitMenuItems);
    if (renderForMobile) {
      items = items.concat(partnerToolkitMenuItems);
    } else {
      items.push({
        id: 'partner-toolkits',
        text: 'Partner Toolkits',
        hoverStyle: true,
        iconDivHidden: true,
        menuItems: partnerToolkitMenuItems
      });
    }
  }
  if (canManageBilling) {
    const accountManagementMenuItem = {
      id: 'account-management',
      href: `${process.env.WEBUI_BILLING_URL}/account-management`,
      icon: _icons.settingsIcon,
      iconDivHidden: false,
      text: 'Account Management',
      hoverStyle: true,
      target: _menuItem.LinkTarget.NewTab,
      border: true,
      qaDataAttribute: {
        type: 'data-qa-link',
        name: 'accountManagement'
      }
    };
    items.push(accountManagementMenuItem);
  }
  return items;
};
exports.buildAppMenuItems = buildAppMenuItems;