import {AssessmentStatus} from '>generated/dvp.types';
import {BadgeColors} from 'wnd-graphics/lib/badge/badge';

export function mapAssessmentStatusToColor(status: AssessmentStatus): BadgeColors {
  switch (status) {
    case AssessmentStatus.AwaitingResults:
      return BadgeColors.PURPLE;

    case AssessmentStatus.Expired:
      return BadgeColors.ERROR;

    case AssessmentStatus.InProgress:
      return BadgeColors.WARNING;

    case AssessmentStatus.New:
      return BadgeColors.SUCCESS;

    case AssessmentStatus.ResultsReleased:
      return BadgeColors.PRIMARY;

    default:
      return BadgeColors.GRAY;
  }
}
