import * as React from 'react';

import {nextSortDirection, SortButtonDirection} from '>shared/components/data/sortButton';
import { EmployeeSortField, SortDirection } from '>generated/dvp.types';

export interface FieldAndSort<Field> {
  fieldName: Field;
  sortDirection: SortButtonDirection;
}

export function useSortSelect<Field>(
  defaultField?: Field,
  defaultSortDirection: SortButtonDirection = SortButtonDirection.None
) {
  const [sortedField, setSortedField] = React.useState<FieldAndSort<Field> | undefined>(
    defaultField && {
      fieldName: defaultField,
      sortDirection: defaultSortDirection,
    }
  );

  const getSortDirection = React.useCallback(
    (field: Field) => {
      if (field === sortedField?.fieldName) {
        return sortedField.sortDirection;
      } else {
        return SortButtonDirection.None;
      }
    },
    [sortedField]
  );

  const updateSortedField = React.useCallback(
    (fieldName: Field) => {
      return React.useCallback(() => {
        let updatedField: FieldAndSort<Field>;

        if (fieldName === sortedField?.fieldName) {
          updatedField = {
            fieldName: sortedField.fieldName,
            sortDirection: nextSortDirection(sortedField.sortDirection),
          };
        } else {
          updatedField = {
            fieldName: fieldName,
            sortDirection: SortButtonDirection.Ascending,
          };
        }

        setSortedField(updatedField);
      }, [fieldName, sortedField]);
    },
    [sortedField]
  );

  return {
    getSortDirection,
    updateSortedField,
    sortedField,
  };
}


interface SortParams {
  sort: EmployeeSortField;
  sortDirection: SortDirection;
}

export function buildSortedParams(field?: FieldAndSort<EmployeeSortField>): SortParams | undefined {
  if (!field || field.sortDirection === SortButtonDirection.None) {
    return;
  }

  return {
    sort: field.fieldName,
    sortDirection:
      field.sortDirection === SortButtonDirection.Ascending
        ? SortDirection.Ascending
        : SortDirection.Descending,
  };
}