import {MenuItem} from '>shared/components/menu/menuItem';
import {Dropdown, DropdownProps} from '>shared/components/menu/dropdown';
import {Menu} from '>shared/components/menu/menu.styles';
import {RawInput, RawInputProps} from '>shared/components/form/rawControls/rawInput';
import {Button} from '>shared/components/button/button';

import styled from '@emotion/styled';
import {CheckBox} from '>shared/components/form/checkBox';
import {FontWeight} from 'wnd-themes/lib/fonts';

export const FilterMenuContainer = styled(Menu)`
  width: 20rem;
`;

export const FilterOptionDropdown = styled(Dropdown)`
  cursor: pointer;
  display: block;
`;

export const FilterSearchInput = styled(RawInput)`
  border-radius: 0.8rem 0.8rem 0 0;
  border-top: none;
  border-left: none;
  border-right: none;
`;

export const FilterOptionMenuItem = styled(MenuItem)`
  padding-left: 0;
`;

export const NoMatchesMenuItem = styled(MenuItem)`
  margin-top: 0.8rem;
`;

export const FilterOptionCheckbox = styled(CheckBox)`
  font-weight: ${FontWeight.Normal};
`;

export const FilterButtonContainer = styled(Button)`
  height: auto;
  width: auto;
  padding: 0.8rem 1.2rem 0.8rem 0.6rem;
  display: flex;
  gap: 0.4rem;
`;
