"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Video = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var _enums = require("./enums");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function buildEmbeddedVideo(source, videoId, style) {
  let hostUrl;
  switch (source) {
    case _enums.EmbeddedVideoSource.Youtube:
      hostUrl = `https://www.youtube.com/embed/${videoId}`;
      break;
    case _enums.EmbeddedVideoSource.Vimeo:
      hostUrl = `https://player.vimeo.com/video/${videoId}`;
      break;
    default:
      throw new Error(`Video host ${source} is not yet supported`);
  }
  return (0, _jsxRuntime.jsx)("iframe", {
    height: '100%',
    src: hostUrl,
    title: "Embedded Video",
    allow: "clipboard-write; picture-in-picture; web-share",
    allowFullScreen: true,
    style: _objectSpread(_objectSpread({}, style), {}, {
      border: 0,
      aspectRatio: '16 / 9'
    })
  });
}
const Video = ({
  src,
  style,
  embedFrom
}) => {
  if (embedFrom) {
    return buildEmbeddedVideo(embedFrom, src, style);
  }
  return (0, _jsxRuntime.jsxs)("video", {
    controls: true,
    style: style,
    children: [(0, _jsxRuntime.jsx)("source", {
      src: src
    }), (0, _jsxRuntime.jsx)("track", {
      kind: "captions"
    })]
  });
};
exports.Video = Video;