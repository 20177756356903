import * as React from 'react';
import {useSelector} from 'react-redux';
import {DonutMetric} from '>components/measures/donutMetric/donutMetric';
import {DonutVariant, SCORING_BUCKET_PARAMETERS} from 'wnd-dvp-reports';
import {
  ConstructDomain,
  mapInterpretationBucketToColor,
  mapScoreToInterpretationBucket,
  SpotlightFlag,
} from 'wnd-dvp-reports';
import {
  SpotlightCard,
  Title,
  ChaptersLink,
  ChaptersContainer,
  SpotlightFlagContainer,
  DonutMetricContainer,
} from './styles';
import {textXsRegular} from '>shared/components/typography/designSystemTypography';
import {SpotlightSectionInfo} from '>generated/dvp.types';
import {SpotlightIcon} from '>components/measures/measureCard/spotlightIcon';

interface Props {
  score: number;
  title: string;
  sectionInfo: SpotlightSectionInfo[];
  constructDomain: ConstructDomain;
  flag: SpotlightFlag;
}

/**
 *
 * @deprecated This is being replaced by AbstractSpotlightWidget.
 * The work is partially complete, but the remainder is part of WD-1908.
 */
export const SpotlightWidget: React.FC<Props> = ({
  score,
  title,
  sectionInfo,
  constructDomain,
  flag,
}) => {
  let bucketMapping = useSelector((state) => state.interpretationBuckets.bucketMapping);

  const interpretationBucket = mapScoreToInterpretationBucket(
    score,
    constructDomain,
    bucketMapping
  );

  const color = mapInterpretationBucketToColor(interpretationBucket, constructDomain);
  const {backgroundColor, badgeColor} = SCORING_BUCKET_PARAMETERS[color];

  return (
    <>
      <SpotlightCard headerColor={backgroundColor}>
        <SpotlightFlagContainer>
          <SpotlightIcon
            key={flag}
            flag={flag}
            buttonStyle={false}
            id={`measureCard-${flag}`}
            color={badgeColor}
          />
        </SpotlightFlagContainer>
        <DonutMetricContainer>
          <DonutMetric score={score} variant={DonutVariant.Medium} color={color} />
        </DonutMetricContainer>
        <Title>{title}</Title>
      </SpotlightCard>
      <ChaptersContainer>
        {sectionInfo.map((sectionInfo: SpotlightSectionInfo, idx: number) => (
          <ChaptersLink key={idx} to={sectionInfo.redirectPath}>
            <p css={textXsRegular}>{sectionInfo.title}</p>
          </ChaptersLink>
        ))}
      </ChaptersContainer>
    </>
  );
};
