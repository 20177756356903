import * as React from 'react';
import styled from '@emotion/styled';
import {mediaQueries} from '>styles/breakpoints';
import {FullWidthContainer} from '>shared/components/layouts/responsive/responsiveLayout.styles';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {PageLayoutComponents} from '>shared/components/layouts/responsive/responsiveLayout';
import {respondTo} from '>styles/breakpoints';
import {MobileSectionHeader} from '>shared/components/landmarks/header/mobileSectionHeader';
import {textXlBold} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import * as Icons from '>shared/components/icon/icons';
import {downloadPdf} from '>lib/pdf';
import {API_URL} from '>root/env';
import {useSelector} from 'react-redux';
import {assertEmployeeExists} from '>lib/assert';
import {OverflowMenu} from '>shared/components/menu/overflowMenu';
import {Dropdown, DropdownPosition} from '>shared/components/menu/dropdown';
import {IconButton, IconButtonType} from '>shared/components/iconButton/iconButton';

export interface DevelopIndexContainerProps {
  className?: string;
  card: React.ReactNode;
  table: React.ReactNode;
}

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'table'
    'card';

  ${respondTo.mediumOrHigher} {
    grid-gap: 2.4rem;
    grid-template-areas: 'table card';
    grid-template-columns: 1fr 42rem;
    max-width: 144rem;
    padding: 3.2rem 1.6rem;
  }
`;

const TableAndHeadingContainer = styled.div`
  grid-area: table;
  padding: 1.6rem;
  overflow: hidden;

  ${respondTo.mediumOrHigher} {
    padding: 0;
  }
`;

const MeasureSection = styled.div`
  grid-area: card;
  padding: 6.4rem 1.6rem;
  overflow-x: hidden;

  ${respondTo.mediumOrHigher} {
    padding: 4.8rem 0;
  }
`;

const HeadingAndActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.4rem 0.4rem 2.4rem 0;
`;

const isMobile = () => window.matchMedia(mediaQueries.smallOrLower.media).matches;

export const DevelopIndexContainer: React.FC<DevelopIndexContainerProps> = ({
  className,
  table,
  card,
}) => {
  const firstRender = React.useRef(true);

  const {setActiveSectionTitle, hiddenComponent, setHiddenComponent} = useResponsiveLayout();
  const employee = useSelector((state) => state.employee.employee);
  assertEmployeeExists(employee);

  const menuItems: MenuLinkItem[] = React.useMemo(
    () => [
      {
        id: 'download',
        icon: Icons.downloadIcon,
        text: 'Download PDF',
        hoverStyle: true,
        action: async () => {
          const fileName = `Develop Index`;
          const pdfEndpoint = `${API_URL}/accounts/${employee.accountId}/employees/${employee.id}/indexMeasures/pdf`;
          downloadPdf(fileName, pdfEndpoint);
        },
      },
    ],
    [employee.accountId, employee.id]
  );

  const hideMeasureCard = React.useCallback(() => {
    setHiddenComponent(PageLayoutComponents.Aside);
  }, []);

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      const mobileView = isMobile();
      if (mobileView) {
        setHiddenComponent(PageLayoutComponents.Aside);
      }
      return;
    }
  }, []);

  const showCorrectViewAfterResize = React.useCallback(() => {
    const desktop = window.matchMedia(mediaQueries.mediumOrHigher.media);

    if (desktop.matches) {
      setHiddenComponent(null);
    } else {
      setHiddenComponent(PageLayoutComponents.Aside);
      setActiveSectionTitle('');
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', showCorrectViewAfterResize);

    return () => window.removeEventListener('resize', showCorrectViewAfterResize);
  }, []);

  return (
    <FullWidthContainer>
      <Container className={className}>
        <TableAndHeadingContainer
          hidden={hiddenComponent === PageLayoutComponents.Table && !firstRender.current}
        >
          <HeadingAndActionsContainer>
            <Heading css={[textXlBold]}>Develop Index</Heading>
            <Dropdown
              position={DropdownPosition.AlignBottomRight}
              renderButton={(onClick) => (
                <IconButton
                  icon={Icons.kebabMenu}
                  buttonLabel="Download Develop Index"
                  data-qa-button="actions"
                  onClick={onClick}
                  variant={IconButtonType.NAV}
                />
              )}
            >
              <OverflowMenu hideOnMobile={false} menuItems={menuItems} />
            </Dropdown>
          </HeadingAndActionsContainer>
          {table}
        </TableAndHeadingContainer>
        <MeasureSection hidden={hiddenComponent === PageLayoutComponents.Aside}>
          <MobileSectionHeader
            primaryHeader={true}
            sectionHeading={'Measure Summary'}
            onBackButtonClick={hideMeasureCard}
          />
          {card}
        </MeasureSection>
      </Container>
    </FullWidthContainer>
  );
};
