export function scoreIsInZone(score, zoneCutoff, prevZoneCutoff) {
    return score >= (prevZoneCutoff !== null && prevZoneCutoff !== void 0 ? prevZoneCutoff : 0) && score < zoneCutoff;
}
export function getScoreZone(score, scoreZones) {
    var _a;
    for (var _i = 0, scoreZones_1 = scoreZones; _i < scoreZones_1.length; _i++) {
        var zone = scoreZones_1[_i];
        if (scoreIsInZone(score, zone.cutoff, (_a = scoreZones[scoreZones.indexOf(zone) - 1]) === null || _a === void 0 ? void 0 : _a.cutoff)) {
            return zone;
        }
    }
    throw new Error("Score zone not found for score ".concat(score));
}
