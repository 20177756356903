import * as React from 'react';
import {useForm} from 'react-hook-form';
import {CannotAccessContentManagerError} from '>root/errors';
import {IndexContentLayout} from '>components/indexContentLayout';
import {redirectToErrorPage} from '>lib/redirect';
import {
  CmsNavigation,
  CmsItem,
  SimpleCmsNavHeader,
} from '>components/contentManagement/navigation/cmsNavigation';
import {useSelector} from 'react-redux';
import {
  defaultEmployeeReportTemplateContext,
  defaultEmployeeTemplateControlFormValues,
} from '>components/contentManagement/form/templateControls/form';
import {ViewState} from './content';
import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import {validateFormForPublish} from './formView';
import {setFormFields} from '../formUtils';
import {assertExists} from 'wnd-util/lib/assert';
import {checkUserContentManagementAccess} from '>lib/user';
import {
  SecondaryHomePageItemFormFields,
  SpotlightHomePageItemFormFields,
} from '>components/contentManagement/form/homePage/formFields';
import {
  defaultSecondaryHomePageItemFormValues,
  defaultSpotlightHomePageItemFormValues,
} from '>components/contentManagement/form/homePage/form';
import {HomePageItemContentView} from './content';
import {CmsItemSettings} from '>components/contentManagement/settings/cmsItemSettings';
import {dvpApi} from '>root/apis';
import {HomePageItem} from '>generated/dvp.types';
import {HomePageItemType} from '>generated/dvp.types';
import {validateContentForPublish} from '../formUtils';
import {Spinner} from '>shared/components/spinner';

export const PENDING_ITEM_ID = 'pending';

export const ContentManagerForHome: React.FC = () => {
  const authState = useSelector((state) => state.auth);

  const [viewState, setViewState] = React.useState<ViewState>(ViewState.View);
  const [previousViewState, setPreviousViewState] = React.useState<ViewState>();
  const [allHomePageItems, setAllHomePageItems] = React.useState<HomePageItem[]>([]);
  const [selectedHomePageItem, setSelectedHomePageItem] = React.useState<HomePageItem>();
  const [isPublishChecked, setIsPublishChecked] = React.useState<boolean>(false);
  const [formFieldsInEdit, setFormFieldsInEdit] = React.useState<
    SpotlightHomePageItemFormFields | SecondaryHomePageItemFormFields
  >();
  const [templateContext, setTemplateContext] = React.useState(
    JSON.parse(defaultEmployeeReportTemplateContext)
  );
  const [globalVariables, setGlobalVariables] = React.useState('');

  const getAllHomePageItems = React.useCallback(async () => {
    const {data} = await dvpApi.listHomePageItems();
    const defaultHomePageItem = data.find((tab) => tab.type === HomePageItemType.Spotlight);

    setAllHomePageItems(data);
    setSelectedHomePageItem(defaultHomePageItem);
  }, []);

  React.useEffect(() => {
    getAllHomePageItems();
  }, []);

  React.useEffect(() => {
    const canUserAccessContentManager = checkUserContentManagementAccess();
    if (!canUserAccessContentManager) {
      redirectToErrorPage(new CannotAccessContentManagerError());
    }
  }, [authState]);

  const spotlightHomePageItemFormMethods = useForm<SpotlightHomePageItemFormFields>({
    mode: 'all',
    defaultValues: defaultSpotlightHomePageItemFormValues,
  });

  const secondaryHomePageItemFormMethods = useForm<SecondaryHomePageItemFormFields>({
    mode: 'all',
    defaultValues: defaultSecondaryHomePageItemFormValues,
  });

  const templateControlFormMethods = useForm<TemplateControlFormFields>({
    mode: 'all',
    defaultValues: defaultEmployeeTemplateControlFormValues,
  });

  React.useEffect(() => {
    if (selectedHomePageItem) {
      setIsPublishChecked(selectedHomePageItem.isPublished);

      if (selectedHomePageItem.variables) {
        templateControlFormMethods.reset({
          templateContext: defaultEmployeeReportTemplateContext,
          globalVariables: selectedHomePageItem.variables,
        });
        setGlobalVariables(selectedHomePageItem.variables);
      }
    }
  }, [selectedHomePageItem]);

  const formMethods = React.useMemo(() => {
    return {
      [HomePageItemType.Spotlight]: spotlightHomePageItemFormMethods,
      [HomePageItemType.Secondary]: secondaryHomePageItemFormMethods,
    };
  }, [spotlightHomePageItemFormMethods, secondaryHomePageItemFormMethods]);

  const validateHomePageItem = React.useCallback(async () => {
    assertExists(selectedHomePageItem, 'Selected home page item must exist for validation');
    const areTemplateFieldsValid = await templateControlFormMethods.trigger();

    if (!areTemplateFieldsValid) {
      return false;
    }

    const areNonTemplateFieldsValid = await formMethods[selectedHomePageItem.type].trigger();

    if (isPublishChecked) {
      const isPublishValid = validateFormForPublish(
        formMethods[selectedHomePageItem.type],
        viewState
      );
      return isPublishValid && areNonTemplateFieldsValid;
    }

    return areNonTemplateFieldsValid;
  }, [selectedHomePageItem, viewState, formMethods, isPublishChecked]);

  const submitHomePageItem = React.useCallback(async () => {
    const isHomePageItemValid = await validateHomePageItem();

    assertExists(selectedHomePageItem, 'Home tab must exist in order to submit changes');

    if (isHomePageItemValid) {
      let content;
      let homePageItem;

      if (viewState === ViewState.Preview) {
        assertExists(formFieldsInEdit, 'Form fields should exist when submitting form');
        content = formFieldsInEdit;
      } else {
        content = formMethods[selectedHomePageItem.type].getValues();
      }

      if (viewState === ViewState.Create || previousViewState === ViewState.Create) {
        const {data} = await dvpApi.createHomePageItem({
          isPublished: isPublishChecked,
          type: selectedHomePageItem.type,
          content,
          variables: globalVariables,
        });

        homePageItem = data;
      } else {
        const {data} = await dvpApi.updateHomePageItem(
          {
            isPublished: isPublishChecked,
            type: selectedHomePageItem.type,
            content,
            variables: globalVariables,
          },
          {
            homePageItemId: selectedHomePageItem.id,
          }
        );

        homePageItem = data;
      }
      updateAllItemsAfterUpdate(homePageItem);
      setSelectedHomePageItem(homePageItem);
      setViewState(ViewState.View);
    }
  }, [
    allHomePageItems,
    validateHomePageItem,
    selectedHomePageItem,
    viewState,
    isPublishChecked,
    formFieldsInEdit,
    formMethods,
    spotlightHomePageItemFormMethods,
  ]);

  const handleDeleteHomePageItem = async () => {
    assertExists(selectedHomePageItem, 'A selected home page item must exist for deletion');
    await dvpApi.deleteHomePageItem({homePageItemId: selectedHomePageItem.id});
    // This reload is a temporary fix until we can fix bug WD-1169
    window.location.reload();
  };

  /*
   *   Handler Methods
   */

  const handleTemplateContextChange = React.useCallback((newValue: string) => {
    setTemplateContext(JSON.parse(newValue));
    templateControlFormMethods.setValue('templateContext', newValue);
  }, []);

  const handleGlobalVariablesChange = React.useCallback((newValue: string) => {
    setGlobalVariables(newValue);
    templateControlFormMethods.setValue('globalVariables', newValue);
  }, []);

  const handleCreateHomePageItem = React.useCallback(() => {
    setViewState(ViewState.Create);
    setSelectedHomePageItem({
      id: PENDING_ITEM_ID,
      dateCreated: new Date().toString(),
      type: HomePageItemType.Secondary,
      content: defaultSecondaryHomePageItemFormValues,
      isPublished: false,
    });
    secondaryHomePageItemFormMethods.reset(defaultSecondaryHomePageItemFormValues);
    secondaryHomePageItemFormMethods.clearErrors();
    templateControlFormMethods.reset({
      globalVariables: '',
      templateContext: defaultEmployeeReportTemplateContext,
    });
  }, []);

  const handleHomePageItemSelected = React.useCallback(
    (homePageItemId: string) => {
      const selectedHomePageItem = allHomePageItems.find(
        (homePageItem) => homePageItem.id === homePageItemId
      );
      setSelectedHomePageItem(selectedHomePageItem);
      setViewState(ViewState.View);
    },
    [selectedHomePageItem]
  );

  const handleEditHomePageItem = React.useCallback(() => {
    assertExists(selectedHomePageItem, 'At least one home tab should always exist');
    setFormFields(formMethods[selectedHomePageItem.type], selectedHomePageItem.content);

    setViewState(ViewState.Edit);

    setGlobalVariables(selectedHomePageItem.variables || '');
    templateControlFormMethods.reset({
      globalVariables: selectedHomePageItem.variables,
      templateContext: defaultEmployeeReportTemplateContext,
    });
  }, [selectedHomePageItem]);

  const handlePublishHomePageItem = React.useCallback(
    async (isPublished: boolean): Promise<boolean> => {
      setIsPublishChecked(isPublished);
      assertExists(selectedHomePageItem, 'Home page item should exist');

      let isValidForPublish = true;

      if (isPublished) {
        if (viewState === ViewState.View) {
          isValidForPublish = validateContentForPublish(selectedHomePageItem.content);

          if (isValidForPublish) {
            const {data} = await dvpApi.updateHomePageItem(
              {...selectedHomePageItem, isPublished: true},
              {homePageItemId: selectedHomePageItem.id}
            );

            setSelectedHomePageItem(data);
            updateAllItemsAfterUpdate(data);
          }
        } else if (viewState === ViewState.Preview) {
          if (formFieldsInEdit) {
            isValidForPublish = validateContentForPublish(formFieldsInEdit);
          }
        } else {
          isValidForPublish = validateFormForPublish(
            formMethods[selectedHomePageItem.type],
            viewState
          );
        }
      } else if (viewState === ViewState.View) {
        if (isValidForPublish) {
          const {data} = await dvpApi.updateHomePageItem(
            {...selectedHomePageItem, isPublished: false},
            {homePageItemId: selectedHomePageItem.id}
          );

          setSelectedHomePageItem(data);
          updateAllItemsAfterUpdate(data);
        }
      }

      return isValidForPublish;
    },
    [viewState, selectedHomePageItem, formFieldsInEdit, formMethods]
  );

  const handleCancel = React.useCallback(() => {
    assertExists(selectedHomePageItem, 'Home tab should exist');
    const spotlightItem = allHomePageItems.find(
      (homePageItem) => homePageItem.type === HomePageItemType.Spotlight
    );

    setSelectedHomePageItem(spotlightItem);
    setViewState(ViewState.View);
    setIsPublishChecked(selectedHomePageItem.isPublished);
    setViewState(ViewState.View);
  }, [viewState, selectedHomePageItem]);

  const togglePreview = React.useCallback(async () => {
    assertExists(selectedHomePageItem, 'Home tab should exist');
    if (!(await validateHomePageItem())) {
      return;
    }

    setPreviousViewState(viewState);

    switch (viewState) {
      case ViewState.Create:
        setFormFieldsInEdit(formMethods[selectedHomePageItem.type].getValues());
        setGlobalVariables(templateControlFormMethods.getValues('globalVariables'));
        setTemplateContext(JSON.parse(templateControlFormMethods.getValues('templateContext')));
        setViewState(ViewState.Preview);
        break;
      case ViewState.Preview:
        assertExists(
          formFieldsInEdit,
          'When going back from preview state the form fields being edited should exist'
        );
        setFormFields(formMethods[selectedHomePageItem.type], formFieldsInEdit);
        templateControlFormMethods.reset({
          globalVariables,
          templateContext: JSON.stringify(templateContext, null, 2),
        });

        assertExists(
          previousViewState,
          'When going back from preview state the previous state must exist'
        );

        setViewState(previousViewState);
        break;
      case ViewState.Edit:
        setFormFieldsInEdit(formMethods[selectedHomePageItem.type].getValues());
        setGlobalVariables(templateControlFormMethods.getValues('globalVariables'));
        setTemplateContext(JSON.parse(templateControlFormMethods.getValues('templateContext')));
        setViewState(ViewState.Preview);
        break;
    }
  }, [viewState]);

  function updateAllItemsAfterUpdate(updatedItem: HomePageItem) {
    let updatedAllHomePageItems;

    const isExistingItem = allHomePageItems.find((item) => item.id === updatedItem.id);

    if (isExistingItem) {
      updatedAllHomePageItems = allHomePageItems.map((item) => {
        return item.id === updatedItem.id ? updatedItem : item;
      });
    } else {
      updatedAllHomePageItems = [...allHomePageItems, updatedItem];
    }

    setAllHomePageItems(updatedAllHomePageItems);
  }

  /*
   *   End of Handler Methods
   */

  const navItemList: CmsItem[] = React.useMemo(() => {
    return allHomePageItems.map(homePageItemToCmsItem);
  }, [allHomePageItems]);

  return selectedHomePageItem ? (
    <IndexContentLayout
      indexSection={
        <>
          <SimpleCmsNavHeader title={'Develop Home'} onAddItem={handleCreateHomePageItem} />
          <CmsNavigation
            selectedNavItemId={selectedHomePageItem.id}
            navItemList={navItemList}
            onNavItemSelected={handleHomePageItemSelected}
          />
        </>
      }
      contentSection={
        <HomePageItemContentView
          homePageItem={selectedHomePageItem!}
          homePageItemFormMethods={formMethods[selectedHomePageItem.type]}
          formFieldsInEdit={formFieldsInEdit}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
          handleGlobalVariablesChange={handleGlobalVariablesChange}
          viewState={viewState}
          templateContext={templateContext}
          globalVariables={globalVariables}
        />
      }
      optionsSection={
        <CmsItemSettings
          cmsItem={homePageItemToCmsItem(selectedHomePageItem)}
          viewState={viewState}
          isPublishedChecked={isPublishChecked}
          setIsPublishChecked={setIsPublishChecked}
          onSubmit={submitHomePageItem}
          onEdit={handleEditHomePageItem}
          onPublish={(isPublished: boolean) => {
            return handlePublishHomePageItem(isPublished);
          }}
          onCancel={handleCancel}
          onTogglePreview={togglePreview}
          onDelete={handleDeleteHomePageItem}
        />
      }
    />
  ) : (
    <Spinner />
  );
};

function homePageItemToCmsItem(homePageItem: HomePageItem): CmsItem {
  return {
    id: homePageItem.id,
    isPublished: homePageItem.isPublished,
    title: homePageItem.content.title,
    label: homePageItem.type === HomePageItemType.Spotlight ? 'Required*' : '',
  };
}
