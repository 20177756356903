import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {FormBuilder} from '>shared/components/form/formBuilder';
import {buildActionPlannerWelcomeItemFormFields} from './formBuilder';

export interface ActionPlannerWelcomeItemFormFields {
  title: string;
  description: string;
  body: string;
}

export const ActionPlannerWelcomeItemFormBuilder: React.FC = () => {
  const {register} = useFormContext<ActionPlannerWelcomeItemFormFields>();
  const actionPlannerWelcomeItemFormFields = buildActionPlannerWelcomeItemFormFields(register);

  return <FormBuilder fields={actionPlannerWelcomeItemFormFields} />;
};
