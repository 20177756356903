import {getAPIAccessToken} from '>root/apis';

export function downloadPdf(fileName: string, endpoint: string) {
  const token = getAPIAccessToken();

  var request = new XMLHttpRequest();
  request.open('GET', endpoint, true);
  request.responseType = 'blob';
  request.setRequestHeader('Authorization', 'Bearer ' + token);
  request.onload = function(e) {
    if (this.status === 200) {
      const data = window.URL.createObjectURL(this.response);
      var link = document.createElement('a');
      link.href = data;
      link.download = fileName;
      link.click();
      setTimeout(function() {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    }
  };
  request.send();
}
