import * as React from 'react';
import {makeSpinalCase} from 'wnd-dvp-reports';
import {ContextTabs, TabDetails, TabType} from '>shared/components/containers/contextTabs';
import {
  Employee,
  HomePageItem,
  HomePageItemContent,
  HomePageItems,
  SpotlightMeasures,
} from '>generated/dvp.types';
import {Heading, Section} from '>shared/components/heading/heading';
import {displayXsBold} from '>shared/components/typography/designSystemTypography';
import {DownloadShareMenu} from '>components/downloadShareMenu';
import {FixedHeaderContextTabsContainer, TitleContainer} from './employeeHome.styles';
import {HomePageItemRenderedContent} from './renderedContent';
import {history} from '>root/history';
import {RoutePath} from '>root/routes/routes';
import {mediaQueries} from '>shared/styles/breakpoints';
import {useSelector} from 'react-redux';
import {LoadingScreen} from '>shared/components/loadingScreen';

type RenderedContent = Record<string, TabDetails>;

export interface CurrentTab {
  jobTitle: string;
  content: HomePageItemContent;
}

interface Props {
  accountId: string;
  employee: Employee;
  homePageItems?: HomePageItems;
  spotlightMeasures?: SpotlightMeasures;
  titleWidth?: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<CurrentTab | undefined>>;
}

export const EmployeeHomePageContentTabbed: React.FC<Props> = ({
  spotlightMeasures,
  homePageItems,
  employee,
  titleWidth,
  setCurrentTab,
}) => {
  const isAdminImpersonatingEmployee = useSelector(
    (state) => state.employee.employee?.isImpersonated
  );
  const [homePageItemContent, setHomePageItemContent] = React.useState<RenderedContent>();

  React.useEffect(() => {
    if (homePageItems && spotlightMeasures) {
      setHomePageItemContent(renderHomePageItemContent(homePageItems, spotlightMeasures));
    }
  }, [homePageItems, spotlightMeasures]);

  function renderHomePageItemContent(
    homePageItems: HomePageItems,
    spotlightMeasures: SpotlightMeasures
  ) {
    const tabContent: RenderedContent = {};

    const itemCount = homePageItems.length;

    homePageItems.forEach((homePageItem: HomePageItem, index: number) => {
      const isLastTab = index + 1 === itemCount;

      tabContent[index.toString()] = {
        title: homePageItem.content.title,
        hashLink: makeSpinalCase(homePageItem.content.title),
        page: (setActiveButton) => {
          if (setActiveButton) {
            const navToNextTab = () => {
              if (isLastTab) {
                history.push(RoutePath.EmployeeDevelopIndex);
              } else {
                setActiveButton((index + 1).toString());
              }
            };
            return (
              <HomePageItemRenderedContent
                homePageItem={homePageItem}
                spotlightMeasures={spotlightMeasures}
                isLastTab={isLastTab}
                navButtonClick={navToNextTab}
                jobTitle={employee.jobTitle}
              />
            );
          }
        },
        onActivate: (activeTab) => {
          if (activeTab) {
            setCurrentTab({
              jobTitle: employee.jobTitle,
              content: homePageItem.content,
            });
          }
        },
      };
    });

    return tabContent;
  }

  const isDesktop = window.matchMedia(mediaQueries.mediumOrHigher.media).matches;

  return homePageItemContent ? (
    <Section rootLevel={1}>
      {isDesktop && (
        <TitleContainer
          width={titleWidth || undefined}
          impersonatingEmployee={isAdminImpersonatingEmployee}
          fixedHeader
        >
          <Heading css={displayXsBold}>Your Development Journey</Heading>
          <DownloadShareMenu employeesToShare={[employee]} />
        </TitleContainer>
      )}
      <FixedHeaderContextTabsContainer impersonating={isAdminImpersonatingEmployee}>
        <ContextTabs tabs={homePageItemContent} tabType={TabType.Underlined} />
      </FixedHeaderContextTabsContainer>
    </Section>
  ) : (
    <LoadingScreen />
  );
};
