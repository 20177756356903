import styled from '@emotion/styled';
import {css} from '@emotion/react';

import {FilterLabel} from '>shared/components/data/sortButton';
import {FilterTag} from '>shared/components/form/filterTag';
import * as colors from 'wnd-themes/lib/colorPalette';

export const stickyTableHeader = css`
  position: sticky;
  top: 4.6rem;
`;

export const filterContainer = css`
  width: 100%;
  height: 4.4rem;

  & {
    td,
    th {
      background: ${colors.gray100};
      border-top: 0.1rem solid ${colors.gray200};
      border-bottom: 0.1rem solid ${colors.gray200};
    }

    td:first-of-type,
    th:first-of-type {
      border-left: 0.1rem solid ${colors.gray200};
      border-radius: 0.8rem 0 0 0.8rem;
    }

    td:last-child,
    th:last-child {
      border-right: 0.1rem solid ${colors.gray200};
      border-radius: 0 0.8rem 0.8rem 0;
    }
  }

  color: ${colors.gray600};
`;

export const EmployeeFilterLabel = styled(FilterLabel)``;

export const FilterGroup = styled.div`
  border-top: 0.1rem solid ${colors.gray200};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  padding: 0.8rem 0.4rem;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 0.8rem;
`;

export const EmployeeFilterTag = styled(FilterTag)`
  margin: 0.4rem 0.8rem;
`;
