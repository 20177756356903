"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfiniteScrollContainer = InfiniteScrollContainer;
exports.RowState = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _viewportDebounce = require("../hooks/viewportDebounce");
var _infiniteScroll = require("../util/infiniteScroll");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var RowState;
(function (RowState) {
  RowState[RowState["Pending"] = 0] = "Pending";
  RowState[RowState["Complete"] = 1] = "Complete";
  RowState[RowState["Hidden"] = 2] = "Hidden";
})(RowState || (exports.RowState = RowState = {}));
function rangeIntersection(rangeA, rangeB) {
  const intersectionRange = [Math.max(rangeA[0], rangeB[0]), Math.min(rangeA[1], rangeB[1])];
  if (intersectionRange[0] <= intersectionRange[1]) {
    return [intersectionRange[0], intersectionRange[1]];
  }
  return undefined;
}
function findScrollParent(self) {
  let el = self;
  const overflowKey = 'overflowY';
  while (el = el.parentElement) {
    switch (window.getComputedStyle(el)[overflowKey]) {
      case 'auto':
      case 'scroll':
      case 'overlay':
        return el;
    }
  }
  return window;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const NOOP = () => {};
function InfiniteScrollContainer({
  renderRow,
  rowData = [],
  debounceTimer = 300,
  fetchPages,
  prefetchRowCount = 1,
  renderThreshold = 100,
  pageSize = 1,
  maxRowCount = 0,
  maxScrollVelocity = 5,
  onCancellationRequested = NOOP
}) {
  const startVisibleRow = React.useRef(0);
  const endVisibleRow = React.useRef(0);
  const updateVisibleRows = React.useCallback((startIndex, endIndex) => {
    startVisibleRow.current = startIndex;
    endVisibleRow.current = endIndex;
  }, []);
  const getVisiblePages = React.useCallback(() => {
    const [minRow, maxRow] = [startVisibleRow.current, endVisibleRow.current];
    // Technically this includes the buffer, not just visible rows. This
    //  does not affect what is actually rendered, just what is fetched.
    //  Can't think of a better name at the moment though.
    const minFetch = Math.max(minRow - prefetchRowCount, 0);
    const maxFetch = maxRow + prefetchRowCount;
    return [Math.floor(minFetch / pageSize), Math.ceil(maxFetch / pageSize)];
  }, [prefetchRowCount, pageSize]);
  const fetchVisiblePages = React.useCallback(() => {
    const currentlyVisiblePages = getVisiblePages();
    const currentlyVisibleRows = [startVisibleRow.current, endVisibleRow.current];
    const pages = [];
    for (let i = currentlyVisiblePages[0]; i <= currentlyVisiblePages[1]; i += 1) {
      const pageRowRange = [i * pageSize, (i + 1) * pageSize - 1];
      const pageIntersection = rangeIntersection(pageRowRange, currentlyVisibleRows);
      let numVisible = 0;
      if (pageIntersection) {
        numVisible = pageIntersection[1] - pageIntersection[0] + 1;
      }
      pages.push({
        pageNumber: i,
        numVisible
      });
    }
    const midRow = Math.floor((currentlyVisibleRows[0] + currentlyVisibleRows[1]) / 2);
    const midPage = Math.floor(midRow / pageSize);
    // Prioritize pages by those that are most-visible first,
    //  then distance to center of the screen
    pages.sort((a, b) => {
      const val = b.numVisible - a.numVisible;
      if (val !== 0) {
        return val;
      }
      const aDist = Math.abs(a.pageNumber - midPage);
      const bDist = Math.abs(b.pageNumber - midPage);
      return aDist - bDist;
    });
    fetchPages(pages.map(page => page.pageNumber));
  }, [getVisiblePages, fetchPages]);
  (0, _viewportDebounce.useViewportDebounce)({
    onScrollFinished: fetchVisiblePages,
    onCancellationRequested,
    debounceTimeMs: debounceTimer,
    maxVelocity: maxScrollVelocity
  });
  const itemRenderer = React.useCallback((index, ref) => {
    const data = rowData[index];
    const showData = data && !data.isHidden;
    const isHidden = data && data.isHidden;
    return renderRow(index, _objectSpread({
      rowNumber: index + 1,
      type: showData ? RowState.Complete : isHidden ? RowState.Hidden : RowState.Pending
    }, showData && {
      data
    }), ref);
  }, [rowData, renderRow]);
  const itemsRenderer = React.useCallback((ref, visibleItems, spaceAbove, spaceBelow) => {
    return (0, _jsxRuntime.jsxs)("tbody", {
      ref: ref,
      children: [(0, _jsxRuntime.jsx)("tr", {
        style: {
          height: `${spaceAbove / 10}rem`
        }
      }), visibleItems, (0, _jsxRuntime.jsx)("tr", {
        style: {
          height: `${spaceBelow / 10}rem`
        }
      })]
    });
  }, []);
  return (0, _jsxRuntime.jsx)(_infiniteScroll.UniformInfiniteScroll, {
    initialIndex: 0,
    threshold: renderThreshold,
    itemRenderer: itemRenderer,
    itemsRenderer: itemsRenderer,
    listItemCount: maxRowCount,
    visibleRowsUpdate: updateVisibleRows
  });
}