import * as React from 'react';
import {Button, ButtonType} from '>shared/components/button/button';
import {
  ActionButtonContainer,
  BottomActionsContainer,
  OptionsContainer,
  OptionsHeaderContainer,
} from './styles';
import {Icons} from '>styles/icon-types';
import {vr6} from '>styles/mixins/verticalRhythm';
import {Toggle} from '>shared/components/toggle/toggle';
import {ViewState} from '>root/pages/contentManager/book/content';
import {roundedCloseIcon} from '>shared/components/icon/icons';
import {Icon} from '>shared/components/icon/icon';
import {textMdSemibold} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';
import {ModalProvider} from '>shared/components/modal/useModal';
import {OpenModalAction} from '>shared/components/modal/modalActions';
import {DeleteItemModal} from '>components/modal/deleteItemModal';
import {CmsItem} from '../navigation/cmsNavigation';
import {PENDING_ITEM_ID} from '>root/pages/contentManager/homePage/page';

interface Props {
  cmsItem: CmsItem;
  viewState: ViewState;
  usePublishingToggle?: boolean;
  isPublishedChecked?: boolean;
  setIsPublishChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (isPublished: boolean) => void;
  onEdit: () => void;
  onPublish?: (isPublished: boolean) => Promise<boolean>;
  onCancel: () => void;
  onTogglePreview: () => void;
  onDelete?: () => Promise<void>;
}

export const CmsItemSettings: React.FC<Props> = ({
  cmsItem,
  viewState,
  usePublishingToggle = true,
  isPublishedChecked,
  setIsPublishChecked,
  onSubmit,
  onEdit,
  onPublish,
  onCancel,
  onTogglePreview,
  onDelete,
}) => {
  const showTogglePreviewButton = viewState !== ViewState.View;

  const canUsePublishingToggle =
    usePublishingToggle && setIsPublishChecked !== undefined && onPublish;

  const ActionButtons = React.useMemo(() => {
    switch (viewState) {
      case ViewState.Create:
      case ViewState.Edit:
      case ViewState.Preview:
        return (
          <>
            <ActionButtonContainer>
              <Button
                buttonType={ButtonType.Secondary}
                icon={Icons.Save}
                fullWidth
                onClick={() => onSubmit(cmsItem.isPublished ?? false)}
                data-qa-button="save-item-button"
              >
                Save
              </Button>
            </ActionButtonContainer>
            <ActionButtonContainer>
              <Button
                buttonType={ButtonType.Tertiary}
                fullWidth
                onClick={onCancel}
                data-qa-button="cancel-item-button"
              >
                <Icon src={roundedCloseIcon} />
                {'Cancel'}
              </Button>
            </ActionButtonContainer>
          </>
        );
      case ViewState.View:
        return (
          <Button
            buttonType={ButtonType.Tertiary}
            icon={Icons.Edit}
            fullWidth
            onClick={onEdit}
            data-qa-button="edit-item-button"
          >
            Edit
          </Button>
        );
    }
  }, [viewState, cmsItem, onEdit, onCancel, onSubmit]);

  const PreviewButtonText = React.useMemo(() => {
    switch (viewState) {
      case ViewState.Preview:
        return 'Close Preview';
      case ViewState.Create:
      case ViewState.Edit:
        return 'View Preview';
    }
  }, [viewState]);

  const [errorMessage, setErrorMessage] = React.useState<string>();

  React.useEffect(() => {
    setErrorMessage(undefined);
  }, [cmsItem, viewState]);

  return (
    <OptionsContainer>
      <OptionsHeaderContainer css={vr6}>
        <Heading css={textMdSemibold}>Settings</Heading>
        {showTogglePreviewButton && (
          <Button
            buttonType={ButtonType.Tertiary}
            onClick={onTogglePreview}
            data-qa-button="preview-item-button"
          >
            {PreviewButtonText}
          </Button>
        )}
      </OptionsHeaderContainer>

      {canUsePublishingToggle && (
        <Toggle
          label="Draft"
          checkedLabel="Published"
          icon={Icons.NoVisibility}
          checkedIcon={Icons.Visibility}
          checked={isPublishedChecked}
          error={{message: errorMessage}}
          onChange={async (e) => {
            // The callback will return a boolean after checking the form values in parent component for validity
            const isFormValidForPublish = await onPublish(e.target.checked);
            // If form is not valid the toggle to publish a measure is not allowed to change state
            if (!isFormValidForPublish) {
              setIsPublishChecked(false);
              setErrorMessage('All fields are required before publishing');
            } else {
              setErrorMessage(undefined);
            }
          }}
        />
      )}

      {ActionButtons}
      {onDelete && cmsItem.id !== PENDING_ITEM_ID && (
        <BottomActionsContainer>
          <ModalProvider>
            <OpenModalAction
              modal={<DeleteItemModal deleteAction={onDelete} itemTitle={cmsItem.title} />}
              actionComponent={
                <Button
                  buttonType={ButtonType.Destructive}
                  icon={Icons.Trash}
                  fullWidth
                  data-qa-button="delete-home-page-item"
                >
                  Delete
                </Button>
              }
            />
          </ModalProvider>
        </BottomActionsContainer>
      )}
    </OptionsContainer>
  );
};
