import * as React from 'react';
import {RollbarLogger} from 'wnd-logger/lib/browser';
import * as ReactRouterDom from 'react-router-dom';
import {DVPErrorPage} from '>root/pages/errorPage/page';

interface Props extends ReactRouterDom.RouteComponentProps {
  rollbarLogger: RollbarLogger;
}

interface State {
  error?: Error;
}

// error boundaries must be class components as of React 16
class DvpErrorBoundary extends React.Component<Props, State> {
  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState({
      error: event.reason,
    });
  };

  state: State = {};

  componentDidCatch(error: Error): void {
    const {rollbarLogger} = this.props;
    rollbarLogger.error(error);
    this.setState({error});
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  render(): React.ReactNode {
    const {state, props} = this;

    if (state.error) {
      return <DVPErrorPage error={state.error} />;
    }
    return props.children;
  }
}

export const RouterErrorBoundary = ReactRouterDom.withRouter(DvpErrorBoundary);
