import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import styled from '@emotion/styled';

import {CheckBox} from '>shared/components/form/checkBox';
import {ControlledSelect} from '>shared/components/form/select';
import {DrawerForm} from '>shared/components/drawer/drawerForm';
import {
  FormBuilder,
  FormBuilderFields,
  FormBuilderFieldType,
} from '>shared/components/form/formBuilder';
import {isValidEmailAddress} from '>shared/lib/emailValidation';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {Input} from '>shared/components/form/input';

import {addUser} from '>root/store/actions/account';
import {getCustomerMessage} from '>lib/util';
import {Permissions, UserRole} from '>generated/dvp.types';
import {rollbarLogger} from '>lib/logger';
import {useAsyncDispatch} from '>root/store/main';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {WndError} from '>root/errors';

interface AddUserModalProps {
  accountId: string;
  roleOptions: Option[];
  isOpen: boolean;
  onClose: () => void;
}
interface NewUserInput {
  email: string;
  role: UserRole;
  importAccess: boolean;
}

function canRoleHavePermissionsChanged(role: string): boolean {
  return role !== UserRole.Coach;
}

export const AddUserDrawerModal: React.FC<AddUserModalProps> = ({
  accountId,
  isOpen,
  onClose,
  roleOptions,
}) => {
  const [canManageBilling, setCanManageBilling] = React.useState(false);
  const [hintText, setHintText] = React.useState<string>('');
  const [role, setRole] = React.useState<string>(UserRole.AccountAdmin);

  const asyncDispatch = useAsyncDispatch();
  const {showSuccessAlert} = useSnackbar();

  const formMethods = useForm<NewUserInput>({
    mode: 'all',
    defaultValues: {
      email: '',
      role: undefined,
      importAccess: false,
    },
  });
  const {register, formState} = formMethods;

  const closeAndReset = React.useCallback(() => {
    formMethods.reset();
    setHintText('');
    onClose();
  }, [onClose]);

  const onRoleChange = React.useCallback((newRole: string) => {
    setRole(newRole);
    if (newRole === UserRole.Coach) {
      setCanManageBilling(false);
      setHintText('This role has access to shared results only');
    } else if (newRole === UserRole.AccountAdmin) {
      setHintText('This role has access to all employee data');
    }
  }, []);

  const inviteUser = React.useCallback(async () => {
    const newUserInput = formMethods.getValues();

    const permissions: Permissions = {
      canBulkUploadEmployees: newUserInput.role === UserRole.AccountAdmin,
      canManageBilling: canManageBilling,
    };

    await asyncDispatch(
      addUser({accountId, email: newUserInput.email, role: newUserInput.role, permissions})
    );

    const snackbarMessage = `Success! User ${newUserInput.email} added.`;
    showSuccessAlert(snackbarMessage);
    onClose();
  }, [canManageBilling]);

  const onSubmitError = React.useCallback(
    (error, setFormError) => {
      const err = error as WndError;
      rollbarLogger.error(err);

      if (err.isUserFacing) {
        setFormError(error.message);
      } else if (err.message) {
        setFormError(getCustomerMessage(error.message));
      } else {
        throw new Error(error.message);
      }
    },
    [rollbarLogger]
  );

  const userFormFields: FormBuilderFields = [
    {
      type: FormBuilderFieldType.Input,
      label: 'Email',
      name: 'email',
      component: (
        <Input
          autoComplete="email"
          placeholder="Email Address"
          ref={register({
            required: 'Email is required',
            validate: (value: string) =>
              isValidEmailAddress(value) ? undefined : 'Invalid email address',
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      label: 'Role',
      name: 'role',
      component: (
        <StyledSelect
          rules={{required: 'Role is required'}}
          options={roleOptions}
          onSelectChange={onRoleChange}
          isSearchable={false}
          required
          placeholder="Select Role"
          helperText={hintText}
        />
      ),
    },
  ];

  const formFields = React.useMemo(() => {
    const formBuilderFields: FormBuilderFields = [
      {
        type: FormBuilderFieldType.Group,
        fields: userFormFields,
      },
    ];

    if (canRoleHavePermissionsChanged(role)) {
      formBuilderFields.push({
        type: FormBuilderFieldType.Group,
        fields: [
          {
            type: FormBuilderFieldType.Custom,
            name: 'billingAccess',
            component: (
              <CheckBox
                label="Allow billing access"
                checked={canManageBilling}
                onChange={() => setCanManageBilling(!canManageBilling)}
              />
            ),
          },
        ],
        label: 'Permission Settings',
      });
    }

    return formBuilderFields;
  }, [canRoleHavePermissionsChanged, role, userFormFields]);

  return (
    <FormProvider {...formMethods}>
      <DrawerForm
        formTitle="Add User"
        formDescription="Invite a user via email and select their role"
        isOpen={isOpen}
        onDismiss={closeAndReset}
        onSubmitError={onSubmitError}
        stages={[
          {
            methods: formMethods,
            form: <FormBuilder fields={formFields} showErrorSummary={false} />,
            actions: {
              next: {
                disabled: formState.isSubmitting,
                label: 'Add',
                onClick: inviteUser,
              },
              back: {
                label: 'Cancel',
                disabled: formState.isSubmitting,
                onClick: closeAndReset,
              },
            },
          },
        ]}
      />
    </FormProvider>
  );
};

const StyledSelect = styled(ControlledSelect)`
  text-align: left;
`;
