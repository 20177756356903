import * as React from 'react';
import {useSelector} from 'react-redux';
import styled from '@emotion/styled';

import * as icons from '>shared/components/icon/icons';
import {ModalProvider, useModal} from '>shared/components/modal/useModal';
import {ButtonGroup, ChildButton} from '>shared/components/buttonGroup/buttonGroup';

import {ShareResultsModal} from './modal/shareResults/shareResultsModal';
import {Employee} from '>generated/dvp.types';
import {downloadPdf} from '>lib/pdf';
import {API_URL} from '>root/env';
import {assertEmployeeExists} from '>lib/assert';
import {ButtonType} from '>shared/components/button/button';

interface DownloadShareMenuProps {
  employeesToShare: Employee[];
}

const StyledButtonGroup = styled(ButtonGroup)`
  background-color: white;
`;

export const DownloadShareMenu: React.FC<DownloadShareMenuProps> = React.memo(
  ({employeesToShare}) => {
    const employee = useSelector((state) => state.employee.employee);
    assertEmployeeExists(employee);
    const {showModal} = useModal();

    const menuItems: ChildButton[] = [];

    menuItems.push({
      icon: icons.shareIcon,
      text: 'Share Results',
      hideText: true,
      onClick: () => showModal(<ShareResultsModal employees={employeesToShare} />),
    });

    menuItems.push({
      icon: icons.downloadIcon,
      text: 'Download PDF',
      hideText: true,
      onClick: async () => {
        const fileName = `Develop Home Page `;
        const pdfEndpoint = `${API_URL}/accounts/${employee.accountId}/employees/${employee.id}/homepage/pdf`;
        downloadPdf(fileName, pdfEndpoint);
      },
    });

    return (
      <ModalProvider>
        <StyledButtonGroup options={menuItems} buttonType={ButtonType.Neutral} />
      </ModalProvider>
    );
  }
);
