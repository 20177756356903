"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UniversalNavItem = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _reactRouterDom = require("react-router-dom");
var _navItem = require("./navItem.styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function createNavItem(navItemProps, currentPath) {
  let isSelected = navItemProps.path === currentPath;
  if (!isSelected && navItemProps.pathMatcher) {
    isSelected = !!currentPath.match(navItemProps.pathMatcher);
  }
  const navItem = navItemProps.path ? (0, _jsxRuntime.jsx)(_navItem.InternalNavItem, {
    to: navItemProps.path,
    isSelected: isSelected,
    role: navItemProps.role,
    tabIndex: 0,
    "data-qa": navItemProps.qaDataAttribute,
    onClick: navItemProps.onClick,
    "aria-label": navItemProps.label,
    children: navItemProps.children
  }) : (0, _jsxRuntime.jsx)(_navItem.ExternalNavItem, {
    href: navItemProps.href,
    role: navItemProps.role,
    tabIndex: 0,
    "data-qa": navItemProps.qaDataAttribute,
    onClick: navItemProps.onClick,
    title: navItemProps.label,
    children: navItemProps.children
  });
  return navItem;
}
const UniversalNavItem = props => {
  const location = (0, _reactRouterDom.useLocation)();
  const currentPath = React.useMemo(() => location.pathname, [location]);
  return createNavItem(props, currentPath);
};
exports.UniversalNavItem = UniversalNavItem;