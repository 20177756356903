import * as React from 'react';
import {css, useTheme} from '@emotion/react';
import styled from '@emotion/styled';

import {Button} from '>shared/components/button/button';
import {respondTo, SMALL_UPPER_BOUND} from '>shared/styles/breakpoints';
import * as colors from 'wnd-themes/lib/colorPalette';
import {SIDEBAR_WIDTH_REM} from '>shared/components/sideBar/sideBar';
import {contentDrawOrder} from '>shared/styles/mixins/drawOrder';

export function getScrollbarWidth() {
  return window.innerWidth - document.body.clientWidth;
}

export function stickyContent() {
  const scrollbarWidth = getScrollbarWidth();

  return css`
    box-sizing: border-box;
    padding: 0 1.6rem;
    width: min(100vw - ${scrollbarWidth}px, 100%);

    left: 0rem;

    ${respondTo.mediumOrHigher} {
      width: min(calc(100vw - ${SIDEBAR_WIDTH_REM}rem - ${scrollbarWidth}px), 100%);
      left: ${SIDEBAR_WIDTH_REM}rem;
    }

    position: sticky;
    ${contentDrawOrder}
  `;
}

export const TableActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  flex-wrap: wrap;
  gap: 0.8rem;
  min-height: 4rem;
`;

export const SearchAndBulkSelectContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: row;
  gap: 1.6rem;
`;

export const searchStyles = css`
  flex-basis: 30rem;

  ${respondTo.smallOrLower} {
    flex-grow: 1;
  }
`;

export const TableActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  max-height: 3.6rem;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const FilterButton = styled(Button)`
  height: auto;
  width: auto;
  padding: 0.8rem 1.2rem 0.8rem 0.6rem;
  display: flex;
  gap: 0.4rem;
`;

interface Props {
  children?: React.ReactNode;
}

const TABLE_HZ_MARGIN_REM = 1.6;
const TABLE_HZ_MARGIN_PX = TABLE_HZ_MARGIN_REM * 10;

function isSidebarVisible(): boolean {
  return document.body.clientWidth >= SMALL_UPPER_BOUND;
}

const MAX_PAGE_WIDTH = 1440;

function calculateMinTableWidth(): number {
  return (
    Math.min(document.body.clientWidth, MAX_PAGE_WIDTH) -
    (isSidebarVisible() ? SIDEBAR_WIDTH_REM * 10 : 0) -
    TABLE_HZ_MARGIN_PX * 2
  );
}

export const EmployeesTableContainer: React.FC<Props> = ({children}) => {
  const [windowWidth, setWindowWidth] = React.useState(calculateMinTableWidth);

  React.useEffect(() => {
    const resizeEvent = () => {
      setWindowWidth(calculateMinTableWidth());
    };

    window.addEventListener('resize', resizeEvent);

    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  // Because the oddities of the tables, we have to manually calculate the
  // min-width
  return (
    <table
      css={css`
        border-spacing: 0;
        padding-bottom: 24rem;
        margin: 0 ${TABLE_HZ_MARGIN_REM}rem;
        min-width: ${windowWidth}px;
      `}
    >
      {children}
    </table>
  );
};

export const Container = styled.tbody`
  background-color: ${colors.white};
`;
