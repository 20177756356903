import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {
  SecondaryHomePageItemFormFields,
  SpotlightHomePageItemFormFields,
} from '>components/contentManagement/form/homePage/formFields';
import {HomePageItemView} from './itemView';
import {HomePageItemFormView} from './formView';
import {HomePageItem} from '>generated/dvp.types';

export enum ViewState {
  Preview = 'preview-state',
  Edit = 'edit-state',
  View = 'view-state',
  Create = 'create-state',
}

interface Props {
  homePageItem: HomePageItem;
  homePageItemFormMethods:
    | UseFormMethods<SpotlightHomePageItemFormFields>
    | UseFormMethods<SecondaryHomePageItemFormFields>;
  formFieldsInEdit?: SpotlightHomePageItemFormFields | SecondaryHomePageItemFormFields;
  templateControlFormMethods: UseFormMethods<TemplateControlFormFields>;
  handleTemplateContextChange: (newValue: string) => void;
  handleGlobalVariablesChange: (newValue: string) => void;
  viewState: ViewState;
  templateContext: Record<string, any>;
  globalVariables: string;
}

export const HomePageItemContentView: React.FC<Props> = ({
  homePageItem,
  homePageItemFormMethods,
  formFieldsInEdit,
  templateControlFormMethods,
  handleTemplateContextChange,
  handleGlobalVariablesChange,
  viewState,
  templateContext,
  globalVariables,
}) => {
  const Content = React.useMemo(() => {
    return {
      [ViewState.View]: (
        <HomePageItemView homePageItem={homePageItem} templateContext={templateContext} />
      ),
      [ViewState.Preview]: formFieldsInEdit ? (
        <HomePageItemView
          homePageItem={{
            ...homePageItem,
            content: {...formFieldsInEdit},
            variables: globalVariables,
          }}
          templateContext={templateContext}
        />
      ) : (
        <></>
      ),
      [ViewState.Create]: (
        <HomePageItemFormView
          homePageItemType={homePageItem.type}
          homePageItemFormMethods={homePageItemFormMethods}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
          handleGlobalVariablesChange={handleGlobalVariablesChange}
          viewState={viewState}
        />
      ),
      [ViewState.Edit]: (
        <HomePageItemFormView
          homePageItemType={homePageItem.type}
          homePageItemFormMethods={homePageItemFormMethods}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
          handleGlobalVariablesChange={handleGlobalVariablesChange}
          viewState={viewState}
        />
      ),
    };
  }, [
    viewState,
    homePageItem,
    homePageItemFormMethods,
    templateControlFormMethods,
    handleTemplateContextChange,
    templateContext,
  ]);

  return Content[viewState];
};
