import * as React from 'react';
import {vr6} from '>styles/mixins/verticalRhythm';
import {
  HomePageItemFormFields,
  SecondaryHomePageItemFormFields,
  SpotlightHomePageItemFormFields,
} from './formFields';
import {FormProvider, UseFormMethods} from 'react-hook-form';
import {Heading} from '>shared/components/heading/heading';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import { HomePageItemType } from '>generated/dvp.types';

export const defaultSpotlightHomePageItemFormValues: SpotlightHomePageItemFormFields = {
  title: '',
  description: '',
  summary: '',
  roleCriticalAttributes: '',
  leastEffortAttributes: '',
  mostEffortAttributes: '',
  closing: '',
  continueButtonText: '',
};

export const defaultSecondaryHomePageItemFormValues: SecondaryHomePageItemFormFields = {
  title: '',
  description: '',
  body: '',
  continueButtonText: '',
};

interface Props {
  title: string;
  homePageItemType: HomePageItemType;
  formMethods: UseFormMethods<SpotlightHomePageItemFormFields | SecondaryHomePageItemFormFields>;
}

export const HomePageItemForm: React.FC<Props> = ({title, homePageItemType, formMethods}) => {
  return (
    <>
      <Heading css={[vr6, displayXsMedium]}>{title}</Heading>
      <FormProvider {...formMethods}>
        <HomePageItemFormFields homePageItemType={homePageItemType}/>
      </FormProvider>
    </>
  );
};
