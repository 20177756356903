import styled from '@emotion/styled';
import {Icon} from '>shared/components/icon/icon';
import {Anchor, Paragraph} from '>shared/components/typography/baseTypography2';
import * as colors from 'wnd-themes/lib/colorPalette';
import {respondTo} from '>shared/styles/breakpoints';
import {Button} from '>shared/components/button/button';

export const StyledMessage = styled(Paragraph)`
  margin-bottom: 4.8rem;
`;

export const HelpContainer = styled.div`
  margin-top: 4.8rem;
`;

export const ArrowIcon = styled(Icon)`
  margin-left: 0.8rem;
  transform: rotate(180deg);
  fill: ${colors.blue700};
`;

export const AnchorText = styled(Paragraph)`
  color: ${colors.blue700};
`;

export const HelpSection = styled.div`
  margin-bottom: 2.4rem;
`;

export const StyledAnchor = styled(Anchor)`
  display: flex;
  margin-bottom: 0.4rem;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${respondTo.smallOrLower} {
    flex-direction: column;
  }
`;

export const AccountManagementButton = styled(Button)`
  margin-right: 1.2rem;

  ${respondTo.smallOrLower} {
    width: 100%;
    margin-bottom: 0.8rem;
    margin-right: 0;
  }
`;

export const AccessButton = styled(Button)`
  ${respondTo.smallOrLower} {
    width: 100%;
    margin-bottom: 0.8rem;
  }
`;

export const ImpersonationBypassButton = styled(Button)`
  margin-left: 1.2rem;

  ${respondTo.smallOrLower} {
    width: 100%;
    margin-left: 0;
  }
`;
