import {convertToUserFriendlyFieldName} from '>components/contentManagement/form/measure/formFields';
import {TemplateControlForm} from '>components/contentManagement/form/templateControls/form';
import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';

import styled from '@emotion/styled';
import React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {vr12} from '>styles/mixins/verticalRhythm';
import {ViewState} from './content';
import {TeamReportItemForm} from '>components/contentManagement/form/teamReport/form';
import {TeamReportItem} from '>generated/dvp.types';
import {AllTeamReportItemFormMethods} from '>components/contentManagement/form/teamReport/formFields';

interface Props {
  teamReportItem: TeamReportItem;
  teamReportItemFormMethods: AllTeamReportItemFormMethods;
  templateControlFormMethods: UseFormMethods<TemplateControlFormFields>;
  handleTemplateContextChange: (newValue: string) => void;
}

const Form = styled.div`
  flex-grow: 1;
`;

export const TeamReportItemFormView: React.FC<Props> = ({
  teamReportItem,
  teamReportItemFormMethods,
  templateControlFormMethods,
  handleTemplateContextChange,
}) => {
  return (
    <>
      <Form css={vr12}>
        <TeamReportItemForm
          title={`${teamReportItem.type}`}
          teamReportItem={teamReportItem}
          formMethods={teamReportItemFormMethods}
        />
      </Form>

      <TemplateControlForm
        formMethods={templateControlFormMethods}
        onTemplateContextChange={handleTemplateContextChange}
      />
    </>
  );
};

export function validateFormForPublish(
  formMethods: UseFormMethods<AllTeamReportItemFormMethods>,
  viewState: ViewState
): boolean {
  const formFieldsRecord = formMethods.getValues() as Record<string, any>;
  const formFieldKeys = Object.keys(formFieldsRecord);
  let isFormValid = true;

  const optionalFields = ['closing'];

  formFieldKeys.forEach((formFieldKey) => {
    if (optionalFields.includes(formFieldKey)) {
      return;
    }
    const value = formFieldsRecord[formFieldKey];

    if (value === '' || value === undefined) {
      isFormValid = false;

      if (viewState === ViewState.Edit) {
        const userFriendlyFieldName = convertToUserFriendlyFieldName(formFieldKey);
        formMethods.setError(formFieldKey as never, {
          type: `${formFieldKey}-error`,
          message: `${userFriendlyFieldName} is required`,
        });
      }
    }
  });

  return isFormValid;
}
