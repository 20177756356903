"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeamDot = exports.AvatarDot = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var _colorPalette = require("wnd-themes/lib/colorPalette");
var _reactUid = require("react-uid");
var _icon = require("../icon/icon");
var _icons = require("../icon/icons");
const AvatarDot = ({
  x,
  y,
  text,
  showTooltip,
  hideTooltip
}) => {
  const seed = (0, _reactUid.useUIDSeed)();
  const filterId = seed('filter');
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)("g", {
      onMouseEnter: showTooltip,
      onMouseLeave: hideTooltip,
      filter: `url(#${filterId})`,
      children: [(0, _jsxRuntime.jsx)("circle", {
        cx: x,
        cy: y,
        fill: _colorPalette.gray100,
        fillOpacity: 1,
        opacity: 1,
        r: 16,
        stroke: _colorPalette.white,
        strokeWidth: 2
      }), (0, _jsxRuntime.jsx)("text", {
        className: "avatar",
        alignmentBaseline: "central",
        dominantBaseline: "central",
        fill: _colorPalette.gray700,
        fontSize: "1.2rem",
        textAnchor: "middle",
        x: x,
        y: y,
        style: {
          cursor: 'default'
        },
        children: text
      })]
    }), (0, _jsxRuntime.jsx)("defs", {
      children: (0, _jsxRuntime.jsx)("filter", {
        id: filterId,
        children: (0, _jsxRuntime.jsx)("feDropShadow", {
          dx: "0",
          dy: "0",
          stdDeviation: "1.5",
          floodColor: _colorPalette.gray300
        })
      })
    })]
  });
};
exports.AvatarDot = AvatarDot;
const TeamDot = ({
  x,
  y,
  borderColor,
  fillColor,
  showTooltip,
  hideTooltip
}) => {
  return (0, _jsxRuntime.jsxs)("g", {
    onMouseEnter: showTooltip,
    onMouseLeave: hideTooltip,
    children: [(0, _jsxRuntime.jsx)("rect", {
      x: x,
      y: y,
      width: "32",
      height: "32",
      rx: "16",
      fill: _colorPalette.gray100,
      stroke: borderColor,
      strokeWidth: "2"
    }), (0, _jsxRuntime.jsx)(_icon.Icon, {
      src: _icons.groupIcon,
      primaryColor: fillColor,
      hoverColor: fillColor,
      width: "24",
      height: "24",
      x: x + 4,
      y: y + 3
    })]
  });
};
exports.TeamDot = TeamDot;