import * as React from 'react';
import {assertExists} from 'wnd-util/lib/assert';
import {groupBy} from 'lodash';

import {
  DrawerFormActions,
  DrawerFormContainer,
  DrawerFormHeader,
  DrawerFormSecondaryAction,
  Subtitle,
  XButton,
} from '>shared/components/drawer/drawerForm.styles';
import {Drawer} from '>shared/components/drawer/drawer';
import {FileInput} from '>shared/components/form/fileInput';
import {generatePrefixed} from '>shared/lib/uniqueString';
import {Message, MessageType} from '>shared/components/form/message';
import {Table, TableColumnDataType} from '>shared/components/tables/table';
import {useModal} from '>shared/components/modal/useModal';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';

import {useAsyncDispatch} from '>store/main';
import {FileImportError} from '>generated/dvp.types';
import {rollbarLogger} from '>lib/logger';

import {downloadFileIcon, roundedCloseIcon} from '>shared/components/icon/icons';
import {Button, ButtonSizes, ButtonType} from '>shared/components/button/button';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';
import {Modal} from '>shared/components/modal/modal';
import {ModalDisplayType} from '>shared/components/modal/displayType';
import {Spinner} from '>shared/components/spinner';
import {vr2, vr4} from '>shared/styles/mixins/verticalRhythm';

import {commitBulkEmployeeImport, stageBulkEmployeeImport} from '>root/store/actions/employee';
import {Messages, FileInputContainer, Divider} from './fileImport.styles';

enum BulkImportState {
  NEVER_SELECTED_FILE,
  FILE_CHOSEN_HAS_ERRORS,
  STAGING,
  STAGE_HAS_ERRORS,
  STAGED_SUCCESSFULLY,
  COMMITTING,
  COMMIT_HAS_ERRORS,
  COMMITTED_SUCCESSFULLY,
}

interface EmployeeTableRow {
  externalId: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  department: string;
  managerName?: string;
  location?: string;
}

export const headerColumns = [
  {
    Header: 'Employee ID*',
    accessor: 'externalId' as const,
    width: 1,
    dataType: TableColumnDataType.Textual,
  },
  {
    Header: 'First Name*',
    accessor: 'firstName' as const,
    width: 1,
    dataType: TableColumnDataType.Textual,
  },
  {
    Header: 'Last Name*',
    accessor: 'lastName' as const,
    width: 1,
    dataType: TableColumnDataType.Textual,
  },
  {
    Header: 'Email*',
    accessor: 'email' as const,
    width: 1,
    dataType: TableColumnDataType.Textual,
  },
  {
    Header: 'Job Title*',
    accessor: 'jobTitle' as const,
    width: 1,
    dataType: TableColumnDataType.Textual,
  },
  {
    Header: 'Department*',
    accessor: 'department' as const,
    width: 1,
    dataType: TableColumnDataType.Textual,
  },
  {
    Header: 'Manager Name',
    accessor: 'managerName' as const,
    width: 1,
    dataType: TableColumnDataType.Textual,
  },
  {
    Header: 'Location',
    accessor: 'location' as const,
    width: 1,
    dataType: TableColumnDataType.Textual,
  },
];

function getUploadButtonText(state: BulkImportState): string {
  switch (state) {
    case BulkImportState.FILE_CHOSEN_HAS_ERRORS:
    case BulkImportState.STAGED_SUCCESSFULLY:
    case BulkImportState.STAGE_HAS_ERRORS:
    case BulkImportState.COMMIT_HAS_ERRORS:
      return 'Re-import Employee List';
    default:
      return 'Import Employee List';
  }
}

function getSpinnerVisibility(state: BulkImportState): boolean | undefined {
  return state === BulkImportState.STAGING || state === BulkImportState.COMMITTING;
}

function isUploadDisabled(state: BulkImportState): boolean {
  return state === BulkImportState.STAGING;
}

function isFileValid(filePath?: string): boolean {
  return Boolean(filePath) && Boolean(filePath?.endsWith('.xlsx'));
}

function getTemplateUrl(): string {
  return `${process.env.PUBLIC_URL}/files/bulk-import-template.xlsx`;
}

function groupErrorMessagesByColumn(errors: FileImportError[]): string[] {
  const MAX_ERRORS_PER_COLUMN = 5;
  // Errors without columnNames are grouped by lodash under the string 'undefined'.
  const NO_COLUMN_NAME = 'undefined';
  const errorMessages: string[] = [];
  const groupedErrors = groupBy(errors, 'columnName');

  Object.keys(groupedErrors).forEach((columnName) => {
    const numErrors = groupedErrors[columnName].length;
    if (numErrors > MAX_ERRORS_PER_COLUMN && columnName !== NO_COLUMN_NAME) {
      errorMessages.push(`${numErrors} rows have an invalid ${columnName}`);
    } else {
      const messagesForColumn = groupedErrors[columnName].map((group) => group.userFacingMessage);
      errorMessages.push(...messagesForColumn);
    }
  });

  return errorMessages;
}

interface BulkImportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const BulkImportModal: React.FC<BulkImportModalProps> = ({isOpen, onClose}) => {
  const [state, setState] = React.useState(BulkImportState.NEVER_SELECTED_FILE);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const [stageSuccessMessages, setSuccessMessages] = React.useState<string[]>([]);
  const [commitId, setCommitId] = React.useState<string | undefined>();
  const [tableEmployees, setTableEmployees] = React.useState<EmployeeTableRow[]>([]);
  const [fileInputKey, setFileInputKey] = React.useState(generatePrefixed('fileInputKey'));
  const asyncDispatch = useAsyncDispatch();
  const {showSuccessAlert} = useSnackbar();

  const clearMessages = React.useCallback(() => {
    setErrorMessages([]);
    setSuccessMessages([]);
  }, []);

  const closeAndResetModal = React.useCallback(() => {
    setState(BulkImportState.NEVER_SELECTED_FILE);
    clearMessages();
    setCommitId(undefined);
    setTableEmployees([]);
    setFileInputKey(generatePrefixed('fileInputKey'));
    onClose();
  }, []);

  const handleFileSelection = React.useCallback(({filePath, file}) => {
    if (isFileValid(filePath)) {
      clearMessages();
      setState(BulkImportState.STAGING);
      asyncDispatch(stageBulkEmployeeImport(file))
        .then((actionResult) => {
          const stageResult = actionResult.payload;
          if (stageResult.errors) {
            setErrorMessages(groupErrorMessagesByColumn(stageResult.errors));
            setState(BulkImportState.STAGE_HAS_ERRORS);
          } else {
            // Successfully staged
            clearMessages();
            setCommitId(stageResult.commitId);
            assertExists(
              stageResult.previewEmployees,
              'previewEmployees should exist if there was no error on staging'
            );
            setTableEmployees(stageResult.previewEmployees);
            setSuccessMessages([
              `${stageResult.employeesToBeAdded} new employee record(s) found`,
              `${stageResult.employeesToBeUpdated} updated employee record(s) found`,
            ]);
            setState(BulkImportState.STAGED_SUCCESSFULLY);
          }
        })
        .catch((err) => {
          setErrorMessages(['Oops! Something went wrong - please try re-uploading the file.']);
          setState(BulkImportState.STAGE_HAS_ERRORS);
          rollbarLogger.error(err);
        });
    } else {
      // invalid file
      setState(BulkImportState.FILE_CHOSEN_HAS_ERRORS);
      const errorMessages = [];

      if (!isFileValid(filePath)) {
        errorMessages.push(
          'The file type is invalid. It must be an excel file with extension .xlsx'
        );
      }

      setErrorMessages(errorMessages);
    }
  }, []);

  const {showModal} = useModal();

  const handlePreview = React.useCallback(() => {
    showModal(<EmployeeUploadPreview tableEmployees={tableEmployees} />);
  }, [tableEmployees]);

  const handleCommit = React.useCallback(() => {
    assertExists(commitId, 'commitId should have been set before this button is enabled');
    clearMessages();
    setState(BulkImportState.COMMITTING);
    asyncDispatch(commitBulkEmployeeImport(commitId))
      .then((actionResult) => {
        const commitResult = actionResult.payload;
        if (commitResult.errors) {
          setErrorMessages(groupErrorMessagesByColumn(commitResult.errors));
          setState(BulkImportState.COMMIT_HAS_ERRORS);
        } else {
          setState(BulkImportState.COMMITTED_SUCCESSFULLY);

          const snackbarMessage = `Success! ${commitResult.employeesAdded} new employees added.`;
          showSuccessAlert(snackbarMessage);

          closeAndResetModal();
        }
      })
      .catch((err) => {
        setErrorMessages([
          'Oops! Something went wrong - please check your employee list before trying again.',
        ]);
        setState(BulkImportState.COMMIT_HAS_ERRORS);
        rollbarLogger.error(err);
      });
  }, [commitId]);

  return (
    <Drawer isOpen={isOpen}>
      <DrawerFormContainer>
        <DrawerFormHeader css={[vr2]}>
          <Heading css={[displayXsMedium]}>{'Import Employee List'}</Heading>
          <XButton
            buttonLabel="Dismiss Drawer"
            data-qa-button="dismissDrawer"
            onClick={closeAndResetModal}
            icon={roundedCloseIcon}
          ></XButton>
        </DrawerFormHeader>
        <Subtitle
          css={vr4}
        >{`Export data from your HRIS to prepare for employee data upload. You can also download our template and it fill out.`}</Subtitle>
        <Button
          buttonType={ButtonType.Neutral}
          data-qa-button="download-template"
          icon={downloadFileIcon}
          onClick={() => (window.location.href = getTemplateUrl())}
          size={ButtonSizes.LG}
        >
          Download Template
        </Button>
        <Divider />
        <Subtitle>{`Import file must be saved as .xlxs format (.csv or .xls will result in an error).`}</Subtitle>
        {/* This key is needed to force a rerender when the modal closes so that the state does not persist to the next drawer. */}
        <FileInputContainer key={fileInputKey}>
          <FileInput
            accept=".xlsx"
            required
            disabled={isUploadDisabled(state)}
            buttonText={getUploadButtonText(state)}
            onFileChange={handleFileSelection}
            qaAttribute="bulk-upload-employees"
          />
        </FileInputContainer>
        {getSpinnerVisibility(state) && <Spinner />}
        <Messages>
          {errorMessages.map((errorMessage) => {
            return (
              <Message
                key={generatePrefixed('error')}
                text={errorMessage}
                type={MessageType.Error}
              />
            );
          })}
          {stageSuccessMessages.map((stageSuccessMessage) => {
            return (
              <Message
                key={generatePrefixed('success')}
                text={stageSuccessMessage}
                type={MessageType.Success}
              />
            );
          })}
        </Messages>

        <DrawerFormActions>
          <DrawerFormSecondaryAction>
            <Button
              data-qa-button="cancel-button"
              buttonType={ButtonType.Neutral}
              disabled={state === BulkImportState.STAGING}
              type="button"
              onClick={closeAndResetModal}
            >
              {'Cancel'}
            </Button>
          </DrawerFormSecondaryAction>
          <DrawerFormSecondaryAction>
            <Button
              disabled={state !== BulkImportState.STAGED_SUCCESSFULLY}
              data-qa-button={'preview-employee-upload'}
              buttonType={ButtonType.Secondary}
              onClick={handlePreview}
            >
              {'Preview'}
            </Button>
          </DrawerFormSecondaryAction>
          <Button
            data-qa-button="commit-button"
            disabled={state !== BulkImportState.STAGED_SUCCESSFULLY}
            type="submit"
            onClick={(event) => {
              event?.preventDefault();
              handleCommit();
            }}
          >
            {'Save'}
          </Button>
        </DrawerFormActions>
      </DrawerFormContainer>
    </Drawer>
  );
};

const EmployeeUploadPreview: React.FC<{
  tableEmployees: EmployeeTableRow[];
}> = ({tableEmployees}) => {
  return (
    <Modal displayType={ModalDisplayType.Wide} headerOptions={{padding: '0'}}>
      <Table columns={headerColumns} data={tableEmployees} useSimpleMobileStyle={false} />
    </Modal>
  );
};
