import * as React from 'react';
import {vr6} from '>styles/mixins/verticalRhythm';
import {TemplateControlFormFields} from './formFields';
import {FormProvider, UseFormMethods} from 'react-hook-form';
import {
  mockEmployeeReportTemplateContext,
  mockTeamReportTemplateContext,
} from '>root/data/mockTemplateContext';
import {Heading} from '>shared/components/heading/heading';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';

const FORM_TITLE = 'Controls';
const JSON_REPLACER = null;
const JSON_SPACING = 2;
const VARIABLES_DEFAULT = '';

export const defaultEmployeeReportTemplateContext = JSON.stringify(
  mockEmployeeReportTemplateContext,
  JSON_REPLACER,
  JSON_SPACING
);

export const defaultTeamReportTemplateContext = JSON.stringify(
  mockTeamReportTemplateContext,
  JSON_REPLACER,
  JSON_SPACING
);

export const defaultEmployeeTemplateControlFormValues: TemplateControlFormFields = {
  globalVariables: VARIABLES_DEFAULT,
  templateContext: defaultEmployeeReportTemplateContext,
};

export const defaultTeamTemplateControlFormValues: TemplateControlFormFields = {
  globalVariables: VARIABLES_DEFAULT,
  templateContext: defaultTeamReportTemplateContext,
};

interface Props {
  formMethods: UseFormMethods<TemplateControlFormFields>;
  onTemplateContextChange: (newValue: string) => void;
  onGlobalVariablesChange?: (newValue: string) => void;
}
export const TemplateControlForm: React.FC<Props> = ({
  formMethods,
  onTemplateContextChange,
  onGlobalVariablesChange,
}) => {
  return (
    <>
      <Heading css={[vr6, displayXsMedium]}>{FORM_TITLE}</Heading>
      <FormProvider {...formMethods}>
        <TemplateControlFormFields
          onTemplateContextChange={onTemplateContextChange}
          onGlobalVariablesChange={onGlobalVariablesChange}
        />
      </FormProvider>
    </>
  );
};
