import * as React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {TextArea, TextAreaResizeEnum} from '>shared/components/form/textArea';
import {validateJson, validateTemplateSyntax} from '>root/lib/validation';

export function buildTemplateControlFormFields(
  register: UseFormMethods['register'],
  onTemplateContextChange: (newValue: string) => void,
  onGlobalVariablesChange?: (newValue: string) => void
): FormBuilderFields {
  const handleJsonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!validateJson(event.target.value)) {
      onTemplateContextChange(event.target.value);
    }
  };

  const handleGlobalVariableChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onGlobalVariablesChange) {
      onGlobalVariablesChange(event.target.value);
    }
  };

  const fields: FormBuilderFields = [
    {
      type: FormBuilderFieldType.Input,
      name: 'templateContext',
      component: (
        <TextArea
          placeholder="JSON Mock Content"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({validate: validateJson})}
          onChange={handleJsonChange}
        />
      ),
    },
  ];

  if (onGlobalVariablesChange) {
    fields.unshift({
      type: FormBuilderFieldType.Input,
      name: 'globalVariables',
      label: 'Global Variables',
      component: (
        <TextArea
          placeholder="Global Variables"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            validate: validateTemplateSyntax,
          })}
          onChange={handleGlobalVariableChange}
        />
      ),
    });
  }

  return fields;
}
