import {createAppThunk} from '../thunk';
import {dvpApi} from '>root/apis';
import {ChapterItemType, Introduction, Measure, NewChapterItem} from '>generated/dvp.types';

export const createChapterMeasure = createAppThunk(
  '@wnd/chapter/measures/create',
  async ({
    chapterNumber,
    isPublished,
    measure,
  }: {
    chapterNumber: string;
    isPublished: boolean;
    measure: Measure;
  }) => {
    const response = await dvpApi.createChapterItem({
      type: ChapterItemType.Measure,
      chapterNumber,
      isPublished,
      content: measure,
    });

    return response.data;
  }
);

export const deleteChapterMeasure = createAppThunk(
  '@wnd/chapter/measures/delete',
  async (chapterItemId: string) => {
    const response = await dvpApi.deleteChapterItem({chapterItemId});

    return response.data;
  }
);

export const createChapterIntroduction = createAppThunk(
  '@wnd/chapter/introduction/create',
  async ({
    chapterNumber,
    isPublished,
    introduction,
  }: {
    chapterNumber: string;
    isPublished: boolean;
    introduction: Introduction;
  }) => {
    const response = await dvpApi.createChapterItem({
      type: ChapterItemType.Introduction,
      chapterNumber,
      isPublished,
      content: introduction,
    });

    return response.data;
  }
);

export const updateChapterItem = createAppThunk(
  '@wnd/chapter/chapterItem/update',
  async ({chapterItemId, update}: {chapterItemId: string; update: NewChapterItem}) => {
    const response = await dvpApi.updateChapterItem(update, {chapterItemId});

    return response.data;
  }
);

export const fetchChapterContent = createAppThunk(
  '@wnd/chapter/content',
  async (chapterNumber: string) => {
    const response = await dvpApi.getChapterContent({chapterNumber});

    return response.data;
  }
);
