import {css} from '@emotion/react';
import styled from '@emotion/styled';
import * as colors from 'wnd-themes/lib/colorPalette';
import {baseDropShadow} from '>styles/mixins/shadows';
import {textXsRegular} from '>shared/components/typography/designSystemTypography';
import {Card} from '>shared/components/cards/card';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderMainSection = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

export const SynonymsContainer = styled.div`
  position: relative;
  bottom: 2.8rem;
  left: 8.8rem;
  margin-right: 8.8rem;
`;

export const Synonyms = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SynonymLabel = styled.label`
  ${textXsRegular};
  color: ${colors.gray500};
`;

export const measureCardStyles = css`
  width: 100%;
  border-radius: 1.2rem;
  padding: 2.4rem;
`;

export const HeaderIconSection = styled.div`
  display: flex;
  align-self: flex-start;
  flex-basis: 4rem;
  position: relative;
  bottom: 12px;
  left: 12px;
`;

export const FlagContainer = styled.div<{buttonstyle: boolean}>`
  margin: 0.4rem;
  height: 3.6rem;
  width: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  background-color: ${(props) => (props.buttonstyle ? colors.white : colors.transparent)};
  ${(props) => props.buttonstyle && baseDropShadow}
`;

export const BaseMeasureCard = styled(Card)<{headerColor: string}>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    `background: linear-gradient(to bottom, ${props.headerColor} 0rem, ${props.headerColor} 4.8rem, ${colors.gray200} 4.8rem, ${colors.gray200} 4.9rem, ${colors.white} 4.9rem, ${colors.white} 100%);`}
  border: 0.05rem solid ${colors.gray200};
  border-radius: 1.6rem;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  position: relative;
`;

export const MeasureCardContainer = styled(BaseMeasureCard)`
  padding: 2.4rem;
`;
