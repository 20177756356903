"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DelayRender = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const DelayRender = ({
  children,
  delayInSeconds
}) => {
  const [isShown, setIsShown] = React.useState(false);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShown(true);
    }, delayInSeconds * 1000);
    return () => clearTimeout(timeout);
  }, [delayInSeconds]);
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: isShown && children
  });
};
exports.DelayRender = DelayRender;