import * as React from 'react';
import {useFormContext, UseFormMethods} from 'react-hook-form';
import {buildMeasureFormFields} from '>components/contentManagement/form/measure/formBuilder';
import {FormBuilder} from '>shared/components/form/formBuilder';
import {ChapterCategory, Measure} from '>generated/dvp.types';
import {SourceConstruct} from 'wnd-dvp-reports';

export interface MeasureFormFields {
  sourceConstruct?: SourceConstruct | string;
  category: ChapterCategory;
  baseName: string;
  displayTitles?: string;
  synonym1?: string;
  synonym2?: string;
  synonym3?: string;
  definition?: string;
  bodyContent?: string;
  actionItems?: string;
  scoringFloor: number;
}

export const MeasureFormFields: React.FC = () => {
  const {register} = useFormContext<MeasureFormFields>();
  const measureFormFields = buildMeasureFormFields(register);

  return <FormBuilder fields={measureFormFields} />;
};

export function convertToUserFriendlyFieldName(fieldName: string): string {
  const sentenceForm = fieldName.replace(/([A-Z])/g, ' $1');
  const words = sentenceForm.split(' ');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(' ');
}

export function convertFormFieldsToMeasure(formFields: MeasureFormFields): Measure {
  return {
    sourceConstruct: formFields.sourceConstruct,
    category: formFields.category,
    baseName: formFields.baseName,
    displayTitles: formFields.displayTitles,
    definition: formFields.definition,
    bodyContent: formFields.bodyContent,
    actionItems: formFields.actionItems,
    synonyms: [formFields.synonym1 ?? '', formFields.synonym2 ?? '', formFields.synonym3 ?? ''],
    scoringFloor: Number(formFields.scoringFloor),
  };
}
