"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useViewportDebounce = useViewportDebounce;
var React = _interopRequireWildcard(require("react"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// eslint-disable-next-line @typescript-eslint/no-empty-function
const NOOP = () => {};
function getScrollPosition(scrollElement) {
  if (scrollElement instanceof Window) {
    return scrollElement.scrollY;
  }
  return scrollElement.scrollTop;
}
/**
 *
 * `useViewportDebounce` is a hook that triggers a debounce
 *    whenever the viewport changes (i.e. scrolling, resizing, etc.)
 *
 * `onCancellationRequested` will trigger whenever a new debounce is triggered
 *  for a substantive change (major scroll), allowing the caller to cancel any
 *  queued requests if need be.
 *
 *
 * velocity is measured in pixels/ms
 */
function useViewportDebounce({
  debounceTimeMs = 300,
  scrollElement = window,
  maxVelocity = 5,
  onScrollFinished,
  onCancellationRequested = NOOP
}) {
  // These are using `useRef` instead of `useState` so that
  //  they don't trigger re-renders. Using these like this
  //  is similar to instance variables in class components
  // https://reactjs.org/docs/hooks-reference.html#useref
  const prevPosition = React.useRef(0);
  const prevTime = React.useRef(0);
  const timeout = React.useRef();
  const isDebouncing = React.useRef(false);
  const pendingRequests = React.useRef(false);
  const scrollListener = React.useCallback(() => {
    const currentPosition = getScrollPosition(scrollElement);
    const currentTime = Date.now();
    const deltaPosition = Math.abs(currentPosition - prevPosition.current);
    const deltaTime = Math.abs(currentTime - prevTime.current);
    prevPosition.current = currentPosition;
    prevTime.current = currentTime;
    const velocity = deltaPosition / (deltaTime || 1);
    const triggerDebouncedEvent = () => {
      isDebouncing.current = false;
      pendingRequests.current = true;
      onScrollFinished();
    };
    const startDebounce = () => {
      isDebouncing.current = true;
      window.clearTimeout(timeout.current);
      timeout.current = window.setTimeout(triggerDebouncedEvent, debounceTimeMs);
    };
    if (velocity > maxVelocity) {
      if (pendingRequests.current) {
        // If there are requests in progress and we're scrolling fast again,
        //  cancel and requeue the message...
        onCancellationRequested();
        pendingRequests.current = false;
      }
      startDebounce();
    } else if (!isDebouncing.current) {
      // We haven't scrolled far enough to warrant resetting the debounce
      //  if there's one in progress, but we still want a debounce if there
      //  isn't one in progress.
      startDebounce();
    }
  }, [onScrollFinished, onCancellationRequested, debounceTimeMs, scrollElement]);
  React.useEffect(() => {
    scrollElement.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);
    prevPosition.current = getScrollPosition(scrollElement);
    prevTime.current = Date.now();
    onCancellationRequested();
    scrollListener();
    return () => {
      scrollElement.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
      window.clearTimeout(timeout.current);
      isDebouncing.current = false;
    };
  }, [scrollElement, scrollListener]);
}