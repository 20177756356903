"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWindowSize = useWindowSize;
var _react = require("react");
function useWindowSize() {
  const [width, setWidth] = (0, _react.useState)(window.innerWidth);
  const [height, setHeight] = (0, _react.useState)(window.innerHeight);
  (0, _react.useLayoutEffect)(() => {
    function updateSize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return {
    width,
    height
  };
}