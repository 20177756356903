import * as React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {TextArea, TextAreaResizeEnum} from '>shared/components/form/textArea';
import {Input} from '>shared/components/form/input';
import {validateTemplateSyntax} from '>root/lib/validation';

export function buildActionPlannerWelcomeItemFormFields(
  register: UseFormMethods['register']
): FormBuilderFields {
  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'title',
      label: 'Title',
      component: (
        <Input
          placeholder="Title"
          ref={register({
            required: 'Title is required',
            pattern: {
              message: 'Title should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          maxLength={100}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'description',
      label: 'Description',
      component: (
        <Input
          placeholder="Description"
          ref={register({
            pattern: {
              message: 'Description should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'body',
      label: 'Body',
      component: (
        <TextArea
          placeholder="Body"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Body contain should at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
  ];
}
