"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconPosition = exports.ButtonType = exports.ButtonSizes = exports.Button = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var _icon = require("../icon/icon");
var _buttonThemes = require("./buttonThemes");
var _button = require("./button.styles");
const _excluded = ["buttonType", "icon", "iconPosition", "size", "fullWidth", "onClick", "children", "className", "data-qa-button"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var ButtonType;
(function (ButtonType) {
  ButtonType["Primary"] = "primary";
  ButtonType["Secondary"] = "secondary";
  ButtonType["Tertiary"] = "tertiary";
  ButtonType["Neutral"] = "neutral";
  ButtonType["Ghost"] = "ghost";
  ButtonType["Destructive"] = "destructive";
  ButtonType["SuperDestructive"] = "superDestructive";
})(ButtonType || (exports.ButtonType = ButtonType = {}));
var ButtonSizes;
(function (ButtonSizes) {
  ButtonSizes["XS"] = "extraSmall";
  ButtonSizes["SM"] = "small";
  ButtonSizes["MD"] = "medium";
  ButtonSizes["LG"] = "large";
  ButtonSizes["XL"] = "extraLarge";
})(ButtonSizes || (exports.ButtonSizes = ButtonSizes = {}));
var IconPosition;
(function (IconPosition) {
  IconPosition["Left"] = "left";
  IconPosition["Right"] = "right";
})(IconPosition || (exports.IconPosition = IconPosition = {}));
function getButtonColors(buttonType) {
  switch (buttonType) {
    case ButtonType.Primary:
      return _buttonThemes.primaryTheme;
    case ButtonType.Neutral:
      return _buttonThemes.neutralTheme;
    case ButtonType.Tertiary:
      return _buttonThemes.tertiaryTheme;
    case ButtonType.Ghost:
      return _buttonThemes.ghostTheme;
    case ButtonType.Secondary:
      return _buttonThemes.secondaryTheme;
    case ButtonType.Destructive:
      return _buttonThemes.destructiveTheme;
    case ButtonType.SuperDestructive:
      return _buttonThemes.superDestructiveTheme;
  }
}
const Button = _ref => {
  let {
      buttonType = ButtonType.Primary,
      icon,
      iconPosition = IconPosition.Left,
      size = ButtonSizes.SM,
      fullWidth,
      onClick,
      children,
      className,
      'data-qa-button': qaAttribute
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  const colors = getButtonColors(buttonType);
  return (0, _jsxRuntime.jsxs)(_button.BaseButton, _objectSpread(_objectSpread({
    className: className,
    colors: colors,
    "data-qa-button": qaAttribute,
    fullWidth: fullWidth,
    icon: Boolean(icon),
    iconPosition: iconPosition,
    onClick: onClick,
    size: size,
    type: "button"
  }, rest), {}, {
    children: [icon && (0, _jsxRuntime.jsx)(_icon.Icon, {
      src: icon,
      primaryColor: colors.text.default,
      hoverColor: colors.text.pressed ?? colors.text.default,
      small: size !== ButtonSizes.LG && size !== ButtonSizes.XL
    }), children]
  }));
};
exports.Button = Button;