import * as React from 'react';
import {css} from '@emotion/react';
import {useUIDSeed} from 'react-uid';

import * as colors from 'wnd-themes/lib/colorPalette';
import * as icons from '>shared/components/icon/icons';
import {TextContentContainer} from './cellStyles';
import {Icon} from '>shared/components/icon/icon';
import {ToolTip, TooltipPosition} from '>shared/components/tooltip';

export interface TextContentProps {
  children?: React.ReactNode;
  hasWarning?: boolean;
  warningText?: string;
  hasError?: boolean;
  onClick?: () => void;
}

export const TextContent: React.FC<TextContentProps> = ({
  children,
  hasWarning,
  warningText,
  hasError,
  onClick,
}) => {
  const seed = useUIDSeed();

  const id = seed('tooltip');

  const iconColor = hasWarning ? colors.warning500 : hasError ? colors.error500 : colors.gray500;

  return (
    <TextContentContainer
      hasWarning={hasWarning}
      hasError={hasError}
      as={onClick ? 'button' : undefined}
      onClick={onClick}
      isButton={Boolean(onClick)}
    >
      {(hasWarning || hasError) && (
        <>
          <span
            css={css`
              vertical-align: middle;
            `}
            data-for={`${id}-toolTip`}
            data-tip={warningText}
          >
            <Icon
              css={css`
                width: 1.4rem;
                height: 1.4rem;
              `}
              small
              src={icons.errorIcon}
              hoverColor={iconColor}
              primaryColor={iconColor}
            />
          </span>
          <ToolTip id={`${id}-toolTip`} place={TooltipPosition.Left} />
        </>
      )}{' '}
      {children}
    </TextContentContainer>
  );
};
