import React from 'react';
import styled from '@emotion/styled';

import * as colors from 'wnd-themes/lib/colorPalette';
import {Modal} from '>shared/components/modal/modal';
import {ModalDisplayType} from '>shared/components/modal/displayType';
import {useModal} from '>shared/components/modal/useModal';
import {Button, ButtonType} from '>shared/components/button/button';
import {Caption} from '>shared/components/typography/baseTypography2';
import {Icon} from '>shared/components/icon/icon';
import {errorOutlineIcon} from '>shared/components/icon/icons';
import {IconHalo} from '>shared/components/icon/iconHalo';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';

import {dvpApi} from '>root/apis';
import {AccountUserWithPII, Employee} from '>generated/dvp.types';
import {rollbarLogger} from '>lib/logger';
import {WndError} from '>root/errors';

interface ConfirmShareResultsModalProps {
  accountId: string;
  employeeIdsToShare: string[];
  verifiedUser?: AccountUserWithPII | Employee;
}

export const ConfirmShareResultsModal: React.FC<ConfirmShareResultsModalProps> = ({
  accountId,
  employeeIdsToShare,
  verifiedUser,
}) => {
  const {hideModal} = useModal();
  const {showSuccessAlert, showErrorAlert} = useSnackbar();
  const isPlural = employeeIdsToShare.length > 1;

  async function handleShareResults(
    accountId: string,
    recipientEmail: string,
    employeeIds: string[],
    hideModal: () => void
  ) {
    try {
      await dvpApi.shareResultsWithUser(
        {
          recipientEmail,
          employeeIds,
        },
        {accountId}
      );

      showSuccessAlert('Employee information successfully shared');
    } catch (error) {
      rollbarLogger.error(error as WndError);
      showErrorAlert('Something went wrong. Please try again.');
    }
    hideModal();
  }

  return verifiedUser ? (
    <Modal
      headerOptions={{
        title: 'Share results',
        icon: (
          <IconHalo borderColor={colors.warning50} backgroundColor={colors.warning100}>
            <Icon
              src={errorOutlineIcon}
              primaryColor={colors.warning600}
              hoverColor={colors.warning600}
            />
          </IconHalo>
        ),
      }}
      displayType={ModalDisplayType.Popup}
    >
      <ContentContainer>
        <Caption>{`Are you sure you want to share the results of ${employeeIdsToShare.length} ${
          isPlural ? 'employees' : 'employee'
        } to ${verifiedUser.firstName} ${verifiedUser.lastName}?`}</Caption>
      </ContentContainer>
      <ButtonContainer>
        <SmallButton
          buttonType={ButtonType.Neutral}
          onClick={hideModal}
          data-qa-button="cancel-share-results"
        >
          Cancel
        </SmallButton>
        <SmallButton
          data-qa-button="confirm-share-results"
          onClick={() =>
            handleShareResults(accountId, verifiedUser.email, employeeIdsToShare, hideModal)
          }
        >
          Confirm
        </SmallButton>
      </ButtonContainer>
    </Modal>
  ) : null;
};

const ContentContainer = styled.div`
  padding: 0 1.6rem 4rem;
`;

const SmallButton = styled(Button)`
  height: auto;
  width: fit-content;
  justify-self: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.2rem;
`;
