import * as React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {ControlledSelect} from '>shared/components/form/select';
import {TextArea, TextAreaResizeEnum} from '>shared/components/form/textArea';
import {Input} from '>shared/components/form/input';
import {getSourceConstructOptions, getCategoryOptions} from './formOptions';
import {validateTemplateSyntax} from '>root/lib/validation';
import {SelectVariant} from '>shared/components/form/rawControls/rawSelect';

const AT_LEAST_ONE_CHARACTER_REGEX = new RegExp(/[^\s]+/);

export function buildMeasureFormFields(register: UseFormMethods['register']): FormBuilderFields {
  return [
    {
      type: FormBuilderFieldType.Row,
      fields: [
        {
          type: FormBuilderFieldType.Input,
          name: 'sourceConstruct',
          label: 'Source Construct',
          component: (
            <ControlledSelect
              isSearchable={false}
              options={getSourceConstructOptions()}
              placeholder="Source Construct"
              variant={SelectVariant.inverted}
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'category',
          label: 'Category',
          component: (
            <ControlledSelect
              isSearchable={false}
              options={getCategoryOptions()}
              placeholder="Category"
              rules={{required: 'Category is required'}}
              required
              variant={SelectVariant.inverted}
            />
          ),
        },
      ],
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'baseName',
      label: 'Base Measure Name',
      component: (
        <Input
          placeholder="Base Measure Name"
          ref={register({
            required: 'Base Measure Name is required',
            pattern: {
              message: 'Base Measure should contain at least one character',
              value: AT_LEAST_ONE_CHARACTER_REGEX,
            },
            validate: validateTemplateSyntax,
          })}
          maxLength={100}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'displayTitles',
      label: 'Display Measure Titles',
      component: (
        <TextArea
          placeholder="Display Measure Titles"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Display Measure Titles should contain at least one character',
              value: AT_LEAST_ONE_CHARACTER_REGEX,
            },
            validate: validateTemplateSyntax,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'synonym1',
      label: 'Synonym 1',
      component: (
        <TextArea
          placeholder="Synonym 1"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Synonym 1 should contain at least one character',
              value: AT_LEAST_ONE_CHARACTER_REGEX,
            },
            validate: validateTemplateSyntax,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'synonym2',
      label: 'Synonym 2',
      component: (
        <TextArea
          placeholder="Synonym 2"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Synonym 2 should contain at least one character',
              value: AT_LEAST_ONE_CHARACTER_REGEX,
            },
            validate: validateTemplateSyntax,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'synonym3',
      label: 'Synonym 3',
      component: (
        <TextArea
          placeholder="Synonym 3"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Synonym 3 should contain at least one character',
              value: AT_LEAST_ONE_CHARACTER_REGEX,
            },
            validate: validateTemplateSyntax,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'definition',
      label: 'Definition',
      component: (
        <TextArea
          placeholder="Definition"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Definition should contain at least one character',
              value: AT_LEAST_ONE_CHARACTER_REGEX,
            },
            validate: validateTemplateSyntax,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'bodyContent',
      label: 'Body Content',
      component: (
        <TextArea
          placeholder="Body Content"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Body Content should contain at least one character',
              value: AT_LEAST_ONE_CHARACTER_REGEX,
            },
            validate: validateTemplateSyntax,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'actionItems',
      label: 'Action Items',
      component: (
        <TextArea
          placeholder="Action Items"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Action Items should contain at least one character',
              value: AT_LEAST_ONE_CHARACTER_REGEX,
            },
            validate: validateTemplateSyntax,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      label: 'Scoring Floor',
      name: 'scoringFloor',
      component: (
        <Input
          ref={register({
            required: 'A scoring floor value between 0-100 is required',
            pattern: {
              message: 'Scoring floor must be a whole number between 0-100',
              value: new RegExp(/^[0-9][0-9]?$|^100$/),
            },
          })}
          required
          type="number"
        />
      ),
    },
  ];
}
