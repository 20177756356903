import * as React from 'react';
import {ToolTip, TooltipPosition} from '>shared/components/tooltip';

import * as colors from 'wnd-themes/lib/colorPalette';
import {SpotlightFlag} from 'wnd-dvp-reports';
import {FlagContainer} from './measureCard.styles';
import {landscapeIcon, roleSuccessIcon, starIcon} from '>shared/components/icon/icons';
import {Icon} from '>shared/components/icon/icon';

interface Props {
  flag: SpotlightFlag;
  buttonStyle?: boolean;
  id?: string;
  color?: string;
}

export const SpotlightIcon: React.FC<Props> = ({flag, buttonStyle = true, id = flag, color}) => {
  const spotlightFlagContent = {
    [SpotlightFlag.Critical]: {
      icon: roleSuccessIcon,
      tooltip: 'This is one of your critical attributes',
      title: 'Critical Attribute',
    },
    [SpotlightFlag.FocusOpportunity]: {
      icon: landscapeIcon,
      tooltip: 'This is one of your most effort attributes',
      title: 'Most Effort Attribute',
    },
    [SpotlightFlag.Strength]: {
      icon: starIcon,
      tooltip: 'This is one of your least effort attributes',
      title: 'Least Effort Attribute',
    },
  };

  const flagContent = spotlightFlagContent[flag];
  const iconColor = buttonStyle ? colors.primary600 : color ? color : colors.char400;

  return (
    <FlagContainer data-tip data-for={id} key={flag} buttonstyle={buttonStyle}>
      <Icon src={flagContent.icon} primaryColor={iconColor} hoverColor={iconColor} />
      <ToolTip id={id} place={TooltipPosition.Left}>
        {buttonStyle ? flagContent.tooltip : flagContent.title}
      </ToolTip>
    </FlagContainer>
  );
};
