import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {TeamReportItemFormView} from './formView';
import {TeamReportItem} from '>generated/dvp.types';
import {
  AllTeamReportItemFormFields,
  AllTeamReportItemFormMethods,
} from '>components/contentManagement/form/teamReport/formFields';
import {TeamReportItemView} from './itemView';

export enum ViewState {
  Preview = 'preview-state',
  Edit = 'edit-state',
  View = 'view-state',
}

interface Props {
  allTeamReportItems: TeamReportItem[];
  selectedTeamReportItem: TeamReportItem;
  teamReportItemFormMethods: AllTeamReportItemFormMethods;
  templateControlFormMethods: UseFormMethods<TemplateControlFormFields>;
  handleTemplateContextChange: (newValue: string) => void;
  viewState: ViewState;
  templateContext: Record<string, any>;
  formFieldsInEdit?: AllTeamReportItemFormFields;
}

export const TeamReportItemContentView: React.FC<Props> = ({
  allTeamReportItems,
  selectedTeamReportItem,
  teamReportItemFormMethods,
  templateControlFormMethods,
  handleTemplateContextChange,
  viewState,
  templateContext,
  formFieldsInEdit,
}) => {
  const Content = React.useMemo(() => {
    return {
      [ViewState.Preview]: formFieldsInEdit ? (
        <TeamReportItemView
          allTeamReportItems={allTeamReportItems}
          selectedTeamReportItem={{
            ...selectedTeamReportItem,
            content: {...selectedTeamReportItem.content, ...formFieldsInEdit},
          }}
          templateContext={templateContext}
        />
      ) : (
        <></>
      ),
      [ViewState.View]: (
        <TeamReportItemView
          allTeamReportItems={allTeamReportItems}
          selectedTeamReportItem={selectedTeamReportItem}
          templateContext={templateContext}
        />
      ),
      [ViewState.Edit]: (
        <TeamReportItemFormView
          teamReportItem={selectedTeamReportItem}
          teamReportItemFormMethods={teamReportItemFormMethods}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
        />
      ),
    };
  }, [
    formFieldsInEdit,
    viewState,
    allTeamReportItems,
    selectedTeamReportItem,
    teamReportItemFormMethods,
    templateControlFormMethods,
    handleTemplateContextChange,
    templateContext,
  ]);

  return Content[viewState];
};
