import * as React from 'react';
import * as colors from 'wnd-themes/lib/colorPalette';
import styled from '@emotion/styled';
import {TeamReportDomain, getColorForTeamReportSection} from 'wnd-dvp-reports';

import {
  displayXsMedium,
  displayXsSemibold,
  textLgSemibold,
  textSmSemibold,
  textXlRegular,
} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';
import {respondTo} from '>shared/styles/breakpoints';
import {vr10, vr2, vr4, vr6} from '>shared/styles/mixins/verticalRhythm';

export const HeaderSection = styled.div`
  margin: 1.6rem 1.6rem 0 1.6rem;
`;

export const TabContentNavContainer = styled.div`
  margin: 2.4rem 0;
`;

export const TabContentMainContainer = styled.div`
  ${respondTo.smallOrLower} {
    margin-top: 2.4rem;
  }

  ${respondTo.mediumOrHigher} {
    display: flex;
    margin: 2.4rem 0 4rem 4.8rem;
  }
`;

export const PageHeading = styled.div`
  color: ${colors.gray900};
  ${displayXsMedium};
`;

export const TabsContainer = styled.div`
  margin: 0 1.6rem 0 1.6rem;

  ${respondTo.smallOrLower} {
    max-width: 64rem;
    margin: 0 auto;
    padding: 1.6rem;
  }
`;

export const ReportMainContent = styled.div`
  max-width: 64rem;
  width: 100%;
`;

export const ReportRightPane = styled.div`
  ${respondTo.mediumOrLower} {
    display: none;
  }

  ${respondTo.largeOrHigher} {
    margin: 1.6rem 0 0 4.8rem;
    width: 28.1rem;
  }
`;

export const ReportRightPaneFixed = styled.div`
  position: fixed;
`;

export const TeamReportIntroBorder = styled.div<{sectionDomain: TeamReportDomain}>`
  border-top: 4px solid ${({sectionDomain}) => getColorForTeamReportSection(sectionDomain)};
  ${vr4}
`;

export const TeamReportIntroPreHeader = styled.div`
  ${textSmSemibold}
  color: ${colors.gray600};
  text-transform: uppercase;
  line-height: 1.8rem;
  letter-spacing: 0.14rem;
  ${vr2}
`;

export const TeamReportIntroMainHeader = styled(Heading)`
  ${displayXsSemibold}
  ${vr6}
`;

export const TeamReportIntroTeamDescription = styled.div`
  ${textXlRegular}
  ${vr10}
  margin-top: 4rem;
`;

export const Divider = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-top: 8rem;
  ${vr4};
`;

export const GrayDivider = styled.div`
  height: 0.2rem;
  border-radius: 0.2rem;
  background-color: ${colors.gray200};
  width: 100%;
`;

export const ColoredDivider = styled(GrayDivider)<{sectionDomain: TeamReportDomain}>`
  width: 4.8rem;
  background-color: ${({sectionDomain}) => getColorForTeamReportSection(sectionDomain)};
`;

export const TeamReportSubsectionHeader = styled(Heading)`
  ${textLgSemibold};
`;
