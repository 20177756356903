import {convertToUserFriendlyFieldName} from '>components/contentManagement/form/measure/formFields';
import {TemplateControlForm} from '>components/contentManagement/form/templateControls/form';
import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';

import styled from '@emotion/styled';
import React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {vr12} from '>styles/mixins/verticalRhythm';
import {ActionPlannerWelcomeItemFormFields} from '>components/contentManagement/form/actionPlannerWelcome/formFields';
import {ActionPlannerWelcomeItemForm} from '>components/contentManagement/form/actionPlannerWelcome/form';
import {ViewState} from '../homePage/content';

interface Props {
  actionPlannerWelcomeItemFormMethods: UseFormMethods<ActionPlannerWelcomeItemFormFields>;
  templateControlFormMethods: UseFormMethods<TemplateControlFormFields>;
  handleTemplateContextChange: (newValue: string) => void;
  handleGlobalVariablesChange: (newValue: string) => void;
}

const Form = styled.div`
  flex-grow: 1;
`;

export const ActionPlannerWelcomeItemFormView: React.FC<Props> = ({
  actionPlannerWelcomeItemFormMethods,
  templateControlFormMethods,
  handleTemplateContextChange,
  handleGlobalVariablesChange,
}) => {
  return (
    <>
      <Form css={vr12}>
        <ActionPlannerWelcomeItemForm
          title="Edit"
          formMethods={
            actionPlannerWelcomeItemFormMethods as UseFormMethods<
              ActionPlannerWelcomeItemFormFields
            >
          }
        />
      </Form>

      <TemplateControlForm
        formMethods={templateControlFormMethods}
        onTemplateContextChange={handleTemplateContextChange}
        onGlobalVariablesChange={handleGlobalVariablesChange}
      />
    </>
  );
};

export function validateFormForPublish(
  formMethods: UseFormMethods<ActionPlannerWelcomeItemFormFields>,
  viewState: ViewState
): boolean {
  const formFieldsRecord = formMethods.getValues() as Record<string, any>;
  const formFieldKeys = Object.keys(formFieldsRecord);
  let isFormValid = true;

  const optionalFields = ['closing'];

  formFieldKeys.forEach((formFieldKey) => {
    if (optionalFields.includes(formFieldKey)) {
      return;
    }
    const value = formFieldsRecord[formFieldKey];

    if (value === '' || value === undefined) {
      isFormValid = false;

      if (viewState === ViewState.Edit) {
        const userFriendlyFieldName = convertToUserFriendlyFieldName(formFieldKey);
        formMethods.setError(formFieldKey as never, {
          type: `${formFieldKey}-error`,
          message: `${userFriendlyFieldName} is required`,
        });
      }
    }
  });

  return isFormValid;
}
