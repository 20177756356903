import * as React from 'react';
import styled from '@emotion/styled';
import {HashLink as Link} from 'react-router-hash-link';

import {tagStyle} from '>components/measures/synonymTag/synonymTag.styles';
import {SynonymLabel, Synonyms} from './measureCard.styles';
import {MeasureChapter} from './measureCard';
import {textEllipsis} from '>styles/mixins/textConstraints';
import {VisuallyHidden} from '>styles/mixins/accessibility';
import * as colors from 'wnd-themes/lib/colorPalette';
import {textMdSemibold} from '>shared/components/typography/designSystemTypography';

const CardTextContainer = styled.div`
  margin-top: 1.6rem;

  p {
    ${textMdSemibold};
    margin-bottom: 0.8rem;
  }
`;

const ChapterLink = styled(Link)`
  ${tagStyle};
  background: ${colors.char100};
  text-decoration: none;

  label:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    border: 0.1rem solid ${colors.blue300};
    box-shadow: 0px 0.1rem 0.2rem rgba(16, 24, 40, 0.05), 0px 0px 0px 0.4rem ${colors.primary100};
  }

  &:active {
    background-color: ${colors.white};
    border: 0.1rem solid ${colors.blue300};
    box-shadow: 0px 0.1rem 0.2rem rgba(16, 24, 40, 0.05), 0px 0px 0px 0.4rem ${colors.primary100};
  }
`;

const ChapterLabel = styled(SynonymLabel)`
  color: ${colors.char600};
`;

interface Props {
  chapters: MeasureChapter[];
  title: string;
}

export const ReadMoreSection: React.FC<Props> = ({chapters, title}) => (
  <CardTextContainer>
    <p>{'Read More'}</p>
    <VisuallyHidden>{`Read More about ${title} in the following sections`}</VisuallyHidden>
    <Synonyms>
      {chapters.map((chapter, index) => (
        <ChapterLink key={`${chapter.number}-${index}`} to={chapter.href}>
          <ChapterLabel css={textEllipsis}>{`Section ${chapter.number}`}</ChapterLabel>
        </ChapterLink>
      ))}
    </Synonyms>
  </CardTextContainer>
);
