import * as React from 'react';
import {useForm} from 'react-hook-form';
import {ActionType} from '>shared/components/modal/modal';
import {useModal} from '>shared/components/modal/useModal';
import {
  AssessmentStatus,
  BulkEmployeeSelection,
  Employee,
  EmployeeSelectionMode,
  FilterQuery,
} from '>generated/dvp.types';
import {
  bulkArchiveEmployees,
  archiveEmployee,
  bulkUnArchiveEmployees,
  unArchiveEmployee,
} from '>root/store/actions/employee';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {useAsyncDispatch} from '>root/store/main';
import {useAsyncSubmit} from '>shared/components/form/formBuilder';
import {EmployeeSelection} from '>root/pages/adminEmployees/employeesTable';
import {assertExists} from 'wnd-util/lib/assert';
import {AlertModal, AlertModalVariants} from '>shared/components/modal/alertModal';
import {dvpApi} from '>root/apis';
import {useSelector} from 'react-redux';
import {ModalSubHeading, ModalText} from './styles';
import {IconAvatar} from '>shared/components/avatar/avatar';
import {peopleFilledIcon, personFilledIcon} from '>shared/components/icon/icons';

interface ArchiveEmployeeModalProps {
  employee?: Employee;
  employeeSelection: EmployeeSelection;
  filterQuery?: FilterQuery;
  mode: ArchiveEmployeeModalMode;
  onArchiveChange: () => void;
}

export enum ArchiveEmployeeModalMode {
  Archive,
  UnArchive,
}

const SINGLE_EMPLOYEE_ASSERT = 'employee param must be passed when operating on a single employee';
const BULK_EMPLOYEE_ASSERT = 'employeeSelection param must be passed when operating in bulk';

export const ArchiveEmployeeModal: React.FC<ArchiveEmployeeModalProps> = ({
  employee,
  employeeSelection,
  filterQuery,
  onArchiveChange,
  mode,
}) => {
  const {hideModal} = useModal();
  const [isSaving, setIsSaving] = React.useState(false);
  const {showSuccessAlert, showErrorAlert} = useSnackbar();
  const asyncDispatch = useAsyncDispatch();

  const account = useSelector((state) => state.account.account);
  assertExists(account, 'Account must exist');
  const accountId = account.id;

  const [selectedEmployee, setSelectedEmployee] = React.useState<Employee | undefined>(employee);

  React.useEffect(() => {
    if (!selectedEmployee) {
      const {activeEmployees} = employeeSelection;
      const firstSelectedEmployeeId = activeEmployees.keys().next().value;

      fetchEmployeeDetails(firstSelectedEmployeeId);
    }

    async function fetchEmployeeDetails(employeeId: string) {
      const {data} = await dvpApi.getEmployeeById({accountId, employeeId});

      setSelectedEmployee(data);
    }
  }, [employee]);

  const numOfEmployees = employeeSelection.selectionCount;
  const isBulk = numOfEmployees > 1;

  const modalTitle = `${
    mode === ArchiveEmployeeModalMode.Archive ? 'Archive' : 'Unarchive'
  } Employee${isBulk ? 's' : ''}`;

  let selectedEmployeesText: string = '';

  if (selectedEmployee) {
    const name = `${selectedEmployee.preferredName || selectedEmployee.firstName} ${
      selectedEmployee.lastName
    }`;
    if (isBulk) {
      const numRemaining = numOfEmployees - 1;

      selectedEmployeesText = `${name} and ${
        numRemaining > 1 ? `${numRemaining} others` : '1 other person'
      }`;
    } else {
      selectedEmployeesText = name;
    }
  }

  const formMethods = useForm<Employee>({
    mode: 'all',
    defaultValues: employee,
  });

  const handleArchiveChange = React.useCallback(
    () => async () => {
      setIsSaving(true);
      if (isBulk) {
        assertExists(employeeSelection, BULK_EMPLOYEE_ASSERT);

        const {selectionMode, activeEmployees, selectionCount} = employeeSelection;

        const canSubmit =
          (selectionMode === EmployeeSelectionMode.Inclusive && activeEmployees.size > 0) || // at least one selection
          (selectionMode === EmployeeSelectionMode.Exclusive &&
            activeEmployees.size !== selectionCount); // hasn't unchecked every selection

        if (canSubmit) {
          let bulkSelection: BulkEmployeeSelection | undefined;

          bulkSelection = {
            employeeSelectionMode: selectionMode,
            employeeIds: Array.from(activeEmployees.keys()),
            filterQuery,
          };

          const isFilteredOnInProgress = filterQuery?.assessmentStatuses?.includes(
            AssessmentStatus.InProgress
          );

          if (isFilteredOnInProgress) {
            // We show invited users under the in progress filter
            filterQuery?.assessmentStatuses?.push(AssessmentStatus.Invited);
          }

          let numEmployeesChanged = 0;

          if (mode === ArchiveEmployeeModalMode.Archive) {
            const response = await asyncDispatch(bulkArchiveEmployees(bulkSelection));
            numEmployeesChanged = response.payload.employeesArchived ?? 0;
          } else {
            const response = await asyncDispatch(bulkUnArchiveEmployees(bulkSelection));
            numEmployeesChanged = response.payload.employeesUnArchived ?? 0;
          }

          const employeeStringPlural = numEmployeesChanged === 1 ? 'Employee' : 'Employees';
          const snackbarMessage = `Success! ${numEmployeesChanged} ${employeeStringPlural} ${
            mode === ArchiveEmployeeModalMode.Archive ? 'archived' : 'unarchived'
          }.`;
          showSuccessAlert(snackbarMessage);
        }
      } else {
        // single employee
        assertExists(employee, SINGLE_EMPLOYEE_ASSERT);

        if (mode === ArchiveEmployeeModalMode.Archive) {
          await asyncDispatch(archiveEmployee(employee));
        } else {
          await asyncDispatch(unArchiveEmployee(employee));
        }

        const employeeName = `${employee.preferredName || employee.firstName} ${employee.lastName}`;
        const snackbarMessage = `Success! Employee ${employeeName} ${
          mode === ArchiveEmployeeModalMode.Archive ? 'archived' : 'unarchived'
        }.`;
        showSuccessAlert(snackbarMessage);
      }

      setIsSaving(false);
      hideModal();
      onArchiveChange();
    },
    [employee]
  );

  return (
    <AlertModal
      variant={AlertModalVariants.Warning}
      title={modalTitle}
      subtitle={`Are you sure you want to ${
        mode === ArchiveEmployeeModalMode.Archive ? 'archive' : 'unarchive'
      } the selected ${isBulk ? 'employees' : 'employee'}?`}
      actions={[
        {
          type: ActionType.Primary,
          label: mode === ArchiveEmployeeModalMode.Archive ? 'Archive' : 'Unarchive',
          onClick: useAsyncSubmit(formMethods, handleArchiveChange(), [handleArchiveChange]),
          inputType: 'submit',
          qaDataAttribute:
            mode === ArchiveEmployeeModalMode.Archive
              ? 'archive-employee-button'
              : 'unarchive-employee-button',
          disabled: isSaving,
        },
        {
          type: ActionType.Secondary,
          label: 'Cancel',
          onClick: hideModal,
          qaDataAttribute:
            mode === ArchiveEmployeeModalMode.Archive
              ? 'cancel-archive-employee-button'
              : 'cancel-unarchive-employee-button',
          disabled: isSaving,
        },
      ]}
    >
      <ModalSubHeading css={{marginLeft: '6.4rem'}}>
        <IconAvatar iconSrc={isBulk ? peopleFilledIcon : personFilledIcon} text={'Person Icon'} />
        <ModalText>{selectedEmployeesText}</ModalText>
      </ModalSubHeading>
    </AlertModal>
  );
};
