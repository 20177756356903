var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { clientTheme } from './clientTheme';
import * as colors from './colorPalette';
import { FontStack, FontWeight } from './fonts';
import { textSizeSm } from './fontSizes';
var primaryAccent = colors.grey50;
var primaryColor = colors.auroMetalSaurus;
var PrimaryButton = __assign(__assign({}, clientTheme.button.primary), { borderRadius: '0.8rem', padding: '2.4rem', active: __assign(__assign({}, clientTheme.button.primary.active), { backgroundColor: colors.bluePrimary }), enabled: __assign(__assign({}, clientTheme.button.primary.enabled), { backgroundColor: colors.bluePrimary }), disabled: __assign(__assign({}, clientTheme.button.primary.disabled), { backgroundColor: primaryAccent }), hover: __assign(__assign({}, clientTheme.button.primary.hover), { backgroundColor: primaryAccent }) });
var SecondaryButton = __assign(__assign({}, PrimaryButton), { enabled: {
        backgroundColor: colors.transparent,
        color: primaryColor,
        borderColor: primaryColor,
    }, hover: {
        backgroundColor: colors.transparent,
        color: primaryAccent,
        borderColor: primaryAccent,
    }, active: {
        backgroundColor: colors.grey50,
        color: colors.white,
        borderColor: colors.white,
    } });
var AccentButton = __assign(__assign({}, clientTheme.button.primary), { borderRadius: '0.8rem', padding: '2.4rem', active: __assign(__assign({}, clientTheme.button.primary.active), { backgroundColor: colors.blue600, borderColor: colors.blue800 }), enabled: __assign(__assign({}, clientTheme.button.primary.enabled), { backgroundColor: colors.blue600 }), disabled: __assign(__assign({}, clientTheme.button.primary.disabled), { backgroundColor: colors.blue200 }), hover: __assign(__assign({}, clientTheme.button.primary.hover), { backgroundColor: colors.blue700 }) });
export var dvpEmployeeTheme = __assign(__assign({}, clientTheme), { name: 'DVP Employee Theme', primaryFontFamily: FontStack.Inter, appHeader: {
        subHeaderColor: primaryColor,
    }, background: colors.white, banner: {
        border: "0.1rem solid ".concat(colors.blue300),
        padding: '0.5rem',
    }, button: __assign(__assign({}, clientTheme.button), { primary: PrimaryButton, secondary: SecondaryButton, accent: AccentButton }), card: {
        backgroundColor: colors.char00,
        border: 'none',
        boxShadow: 'none',
        sectionStatement: {
            fontFamily: FontStack.Inter,
        },
        title: {
            color: colors.gray700,
            fontFamily: FontStack.Inter,
        },
    }, checkbox: {
        borderColor: colors.primary600,
        backgroundColor: colors.primary600,
        borderRadius: '0.2rem',
        focusRing: true,
    }, defaultFontFamily: FontStack.Inter, icons: {
        primaryColor: colors.gray500,
        hoverColor: colors.gray500,
    }, inputBubble: {
        focusBackgroundColor: colors.transparent,
        activeBackgroundColor: colors.aquaAccent,
        disabledBackgroundColor: colors.gray200,
    }, menuToggle: {
        hoverBackgroundColor: 'none',
    }, page: {
        pageWidth: '100%',
        mediumGutter: '0rem',
        smallGutter: '0rem',
    }, paragraph: {
        fontFamily: FontStack.Inter,
        color: colors.gray700,
    }, radioButton: {
        bubble: {
            borderThickness: '0.2rem',
            dotSize: '50%',
            dotPosition: '25%',
            size: '2rem',
            margin: '0.8rem',
        },
        boxShadow: "0px 0px 0px 0.4rem ".concat(colors.primary100),
        contentMargin: '4.2rem',
        default: {
            color: colors.gray700,
            backgroundColor: colors.transparent,
            borderColor: colors.white,
            buttonColor: colors.white,
            buttonCircleColor: colors.gray600,
        },
        fontSize: '1.6rem',
        smallFontSize: '1.4rem',
        fontWeight: FontWeight.Normal,
        checked: {
            color: colors.gray700,
            backgroundColor: colors.transparent,
            borderColor: colors.white,
            buttonColor: colors.blue600,
            buttonCircleColor: colors.blue600,
        },
        hover: {
            color: colors.grey30,
            backgroundColor: colors.transparent,
            borderColor: colors.white,
            buttonColor: colors.white,
            buttonCircleColor: colors.blue600,
        },
        tabSelected: {
            color: clientTheme.radioButton.default.color,
            backgroundColor: colors.transparent,
            borderColor: colors.white,
            buttonColor: colors.white,
            buttonCircleColor: clientTheme.radioButton.default.buttonCircleColor,
        },
        disabled: {
            color: colors.gray500,
            backgroundColor: colors.gray200,
            borderColor: colors.gray300,
            buttonColor: colors.gray300,
            buttonCircleColor: colors.gray300,
        },
    }, sectionHeading: {
        color: colors.gray700,
        fontFamily: FontStack.Inter,
        fontWeight: FontWeight.Bold,
    }, sideBar: {
        background: colors.char00,
        mobileBackground: colors.white,
        hoverOutlineColor: colors.gray300,
        activeOutlineColor: colors.gray300,
        focusOutlineColor: colors.blue300,
        focusBoxShadow: "0px 0.1rem 0.2rem rgba(16, 24, 40, 0.05), 0px 0px 0px 0.4rem ".concat(colors.primary100),
        activeBackgroundColor: colors.white,
        hoverBackgroundColor: colors.gray100,
        dividerColor: colors.gray200,
        color: primaryColor,
        menuBorderRadiusMobile: '.4rem',
        menuBorderRadiusDesktop: '.8rem',
        itemFocusColor: colors.gray600,
        navItemLabelLeftPaddingMobile: '0.8rem',
        mobileMenuBorderColor: colors.gray200,
    }, toolTip: {
        textColor: colors.gray700,
        backgroundColor: colors.white,
        borderColor: colors.gray300,
    }, userMenu: {
        backgroundColor: colors.white,
        hoverBackgroundColor: 'none',
        dividerColor: colors.gray200,
        activeMenuIconColor: colors.gray500,
    }, widgets: {
        headerColor: primaryColor,
    }, formLayoutTheme: {
        gap: '2.4rem',
    }, overflowMenu: {
        hoverBackgroundColor: colors.white,
    }, tabs: {
        default: "font-size: ".concat(textSizeSm, ";  font-weight: ").concat(FontWeight.Medium, "; color: ").concat(colors.gray500, "; border-bottom: 2px solid ").concat(colors.transparent, "; border-bottom-width: 0.2rem; "),
        active: " font-size: ".concat(textSizeSm, ";  font-weight: ").concat(FontWeight.Medium, "; color: ").concat(colors.blue700, "; border-bottom: 2px solid ").concat(colors.blue700, "; border-bottom-width: 0.2rem;"),
    }, tabContainer: "border-bottom: 0.1rem solid ".concat(colors.grey90, ";  padding-left: 0"), textInput: {
        background: {
            default: colors.white,
            disabled: colors.gray200,
        },
        border: {
            border: '0.1rem solid',
            borderRadius: '0.8rem',
            borderColor: "".concat(colors.gray300),
            errorBorderColor: "".concat(colors.error300),
            focusBorderColor: "".concat(colors.blue300),
        },
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        color: {
            default: colors.gray700,
            disabled: colors.gray400,
            error: colors.error600,
            iconError: colors.error500,
            placeholder: colors.gray500,
        },
        defaultHeight: '4.4rem',
        focusBoxShadow: true,
        font: {
            fontSize: '1.6rem',
            fontWeight: FontWeight.Normal,
            lineHeight: '2.4rem',
        },
        helperText: {
            fontWeight: FontWeight.Normal,
        },
        iconSize: '2rem',
        inputPadding: '1.2rem',
        label: {
            fontSize: '1.4rem',
            fontWeight: FontWeight.Medium,
            lineHeight: '2rem',
            color: colors.gray700,
            asterisk: true,
            showOptional: false,
        },
    }, textArea: {
        showIcon: false,
        defaultHeight: '12rem',
        padding: '1.2rem',
    }, select: {
        placeholder: {
            color: colors.gray500, // this is the minimum required for WCAG AA, do not change
        },
        input: {
            focus: "box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ".concat(colors.primary100),
        },
        menu: {
            border: "0.1rem solid ".concat(colors.gray200),
            borderRadius: '0.8rem',
            boxShadow: '0.8rem',
        },
        menuList: {
            backgroundColor: colors.gray100,
            color: colors.gray700,
            fontSize: '1.4rem',
            padding: '0',
        },
        option: {
            styleOptions: true,
            activeBoxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderRadius: '0',
            padding: '0.8rem 1.4rem',
            margin: '0 0 0.4rem 0',
        },
        showIcon: false,
        value: {
            color: colors.gray700,
        },
        valueContainer: {
            margin: '0 12px',
        },
        enableVariants: true,
        variants: {
            default: {
                controlBackgroundColor: colors.white,
                activeOptionBackgroundColor: colors.char00,
                activeOptionFontColor: colors.gray700,
                menuBackgroundColor: colors.white,
            },
            inverted: {
                controlBackgroundColor: colors.char00,
                activeOptionBackgroundColor: colors.white,
                activeOptionFontColor: colors.gray700,
                menuBackgroundColor: colors.char00,
            },
            alternate: {
                controlBackgroundColor: colors.white,
                activeOptionBackgroundColor: colors.white,
                activeOptionFontColor: colors.gray700,
                menuBackgroundColor: colors.char00,
            },
        },
    } });
