import {Api as DvpAPI} from './generated/dvp';
import {API_URL, API_AUTH_URL, API_UNIFIED_ACCOUNT_URL, API_NOTIFICATION_URL} from './env';
import {setUpAPIs} from '>auth/apis';
import {Api as UnifiedAccountApi} from './generated/unifiedAccount';
import {Api as AuthApi} from './generated/auth';
import {Api as NotificationApi} from './generated/notification';

export const dvpApi = new DvpAPI(API_URL);
export const unifiedAccountApi = new UnifiedAccountApi(API_UNIFIED_ACCOUNT_URL);
export const authApi = new AuthApi(API_AUTH_URL);
export const notificationApi = new NotificationApi(API_NOTIFICATION_URL);

const APIS = [dvpApi, unifiedAccountApi, authApi, notificationApi];

export const {setAPIAccessToken, getAPIAccessToken, clearAPIAccessTokens} = setUpAPIs(APIS);
