import * as React from 'react';
import styled from '@emotion/styled';
import {assertExists} from 'wnd-util/lib/assert';
import {useLocation} from 'react-router-dom';
import {
  TeamReportDomain,
  Typology,
  BalancerCardProps,
  BalancerTypologyContent,
  getColorForTeamReportSection,
  SectionMarkdown,
} from 'wnd-dvp-reports';

import {
  textMdRegular,
  textXsExtraboldUppercase,
} from '>shared/components/typography/designSystemTypography';
import {ContextTabs, TabDetails, TabType} from '>shared/components/containers/contextTabs';
import {gridViewIcon} from '>shared/components/icon/icons';
import {Icon} from '>shared/components/icon/icon';
import {largeDropShadow} from '>shared/styles/mixins/shadows';
import {vr8} from '>shared/styles/mixins/verticalRhythm';
import {sharedComponents} from '>lib/markdownSectionComponents';

function generateTabs(selectedTypology: Typology, balancerContent: BalancerTypologyContent[]) {
  let tabContent: Record<string, TabDetails> = {};
  let index = 0;

  balancerContent.map((content: BalancerTypologyContent) => {
    if (content.typology.quadrant !== selectedTypology.quadrant) {
      tabContent[index++] = {
        title: content.typology.name,
        page() {
          return (
            <SectionMarkdown css={textMdRegular} components={sharedComponents}>
              {content.content}
            </SectionMarkdown>
          );
        },
      };
    }
  });

  return tabContent;
}

export const BalancerCard: React.FC<BalancerCardProps> = ({
  balancerContent,
  sectionDomain,
  title,
  typology,
}) => {
  const tabContent = generateTabs(typology, balancerContent);
  assertExists(tabContent, 'Balancer card tab content must exist');

  const {hash} = useLocation();

  return (
    <CardContainer sectionDomain={sectionDomain}>
      <HeaderContainer>
        <Icon src={gridViewIcon} small disableShrink />
        <div css={textXsExtraboldUppercase}>{title}</div>
      </HeaderContainer>
      <ContextTabs
        tabs={tabContent}
        tabType={TabType.Button}
        enableScroll={false}
        manualTabOverride={hash}
        useDropdownOnMobile={false}
      />
    </CardContainer>
  );
};

const CardContainer = styled.div<{sectionDomain: TeamReportDomain}>`
  border-top: 0.4rem solid ${({sectionDomain}) => getColorForTeamReportSection(sectionDomain)};
  border-radius: 0 0 0.8rem 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 2.4rem;
  ${largeDropShadow};
  ${vr8};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
