import * as React from 'react';
import {RouteChildrenProps, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {assertAccountExists, assertAccountUserExists} from '>lib/assert';
import {canCoachAccessCurrentTeam, isAdmin, useAccountUser} from '>lib/user';
import {dvpApi} from '>root/apis';
import {history} from '>root/history';
import {pageChangeEffect} from '>lib/sideEffects';
import {LoadingScreen} from '>shared/components/loadingScreen';
import {Table, TableColumnDataType, TableSize} from '>shared/components/tables/table';
import {CellProps, CellValue} from 'react-table';
import {AssessmentStatus, Employee, Team, TeamMemberWithEmployee} from '>generated/dvp.types';
import {Heading} from '>shared/components/heading/heading';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {ActionsContainer} from '>components/dataTable/dataTableRow';
import {LocalizedDate} from '>shared/components/util/localizedDate';
import * as icons from '>shared/components/icon/icons';
import {
  AwaitingResultsBadge,
  ResultsReleasedBadge,
  statusToBadgeMapping,
} from '>components/statusBadges';
import {
  OverflowContainer,
  PageHeader,
  TableContainer,
  TeamReportReleaseStatus,
} from './teamManagementPage';
import {last} from 'lodash';
import {Button, ButtonType} from '>shared/components/button/button';
import {
  TableActionsContainer,
  TableActionButtonsContainer,
} from '../adminEmployees/adminEmployeesPage.styles';
import {AddEditTeamDrawerForm, AddEditTeamMode} from '>components/teams/addEditTeamForm';
import {assertExists} from 'wnd-util/lib/assert';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {ModalProvider, useModal} from '>shared/components/modal/useModal';
import {Dropdown, DropdownPosition} from '>shared/components/menu/dropdown';
import {IconButton, IconButtonType} from '>shared/components/iconButton/iconButton';
import {OverflowMenu} from '>shared/components/menu/overflowMenu';
import {RemoveTeamMemberModal} from '>components/modal/removeTeamMemberModal';
import {MIN_NUM_MEMBERS} from '>lib/team';

const PAGE_TITLE = 'Team Details';

interface TeamDetails {
  name: string;
  status: TeamReportReleaseStatus;
  isReportReleased?: boolean;
}

interface TableEmployee {
  id: string;
  key: string;
  lastName: string;
  firstName: string;
  email: string;
  jobTitle: string;
  department: string;
  dateOfLastAssessmentSent?: string;
  lastAssessmentStatus: AssessmentStatus;
  sourceEmployee: Employee;
}

function apiTeamToTableEmployees(team: Team): TableEmployee[] {
  return team.members.map((m: TeamMemberWithEmployee) => {
    const employee = m.employee;
    return {
      id: employee.id,
      key: employee.id,
      firstName: employee.preferredName ?? employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
      jobTitle: employee.jobTitle,
      department: employee.department,
      dateOfLastAssessmentSent: employee.dateOfLastAssessmentSentDate,
      lastAssessmentStatus: employee.lastAssessmentStatus,
      sourceEmployee: employee,
    };
  });
}

export const TeamDetailsPage: React.FC<RouteChildrenProps> = React.memo(() => {
  React.useEffect(pageChangeEffect(PAGE_TITLE), []);
  const accountState = useSelector((state) => state.account);
  const account = accountState.account;
  assertAccountExists(account);
  const currentUser = useAccountUser();
  assertAccountUserExists(currentUser);
  const isAccountAdmin = isAdmin(currentUser);
  const {showModal} = useModal();

  const teamId = last(useLocation().pathname.split('/'));
  const [teamDetails, setTeamDetails] = React.useState<TeamDetails>();
  const [tableEmployees, setTableEmployees] = React.useState<TableEmployee[]>();
  const [apiTeam, setApiTeam] = React.useState<Team>(); // saved to populate defaults on edit
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isEditTeamDrawerOpen, setEditTeamDrawerOpen] = React.useState<boolean>(false);

  const badge = React.useMemo(() => {
    return teamDetails?.status === TeamReportReleaseStatus.RELEASED
      ? ResultsReleasedBadge
      : AwaitingResultsBadge;
  }, [teamDetails]);

  const refreshTeam = React.useCallback((team: Team) => {
    setTeamDetails({
      name: team.name,
      status: team.isReportReleased
        ? TeamReportReleaseStatus.RELEASED
        : TeamReportReleaseStatus.NOT_RELEASED,
    });
    setTableEmployees([...apiTeamToTableEmployees(team)]);
  }, []);

  const removeEmployeeFromTeam = React.useCallback(
    (teamId: string, employeeId: string, employeeFullName: string) => {
      showModal(
        <RemoveTeamMemberModal
          teamId={teamId}
          teamName={teamDetails?.name ?? ''}
          employeeId={employeeId}
          employeeFullName={employeeFullName}
          onRemove={refreshTeam}
          willHaveLessThanMinMembers={(tableEmployees ?? []).length <= MIN_NUM_MEMBERS}
        />
      );
    },
    [refreshTeam, teamDetails?.name, teamDetails?.status, tableEmployees?.length]
  );

  if (!teamId || (!isAdmin(currentUser) && !canCoachAccessCurrentTeam(currentUser, teamId))) {
    history.replace('/');
    return <React.Fragment />;
  }

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Last Name',
        accessor: 'lastName' as const,
      },
      {
        Header: 'First Name',
        accessor: 'firstName' as const,
      },
      {
        Header: 'Email',
        accessor: 'email' as const,
        width: 280,
      },

      {
        Header: 'Job Title',
        accessor: 'jobTitle' as const,
      },
      {
        Header: 'Department',
        accessor: 'department' as const,
      },
      {
        Header: 'Sent',
        accessor: 'dateOfLastAssessmentSent' as const,
        Cell: ({value}: CellProps<CellValue>) => {
          if (!value || value === AssessmentStatus.New) {
            return <React.Fragment />;
          }

          return <LocalizedDate date={new Date(value)} />;
        },
      },
      {
        Header: 'Status',
        accessor: 'lastAssessmentStatus' as const,
        Cell: ({value}: CellProps<CellValue>) => {
          return statusToBadgeMapping[value as AssessmentStatus];
        },
      },
      {
        Header: 'Action',
        accessor: 'action' as const,
        disableSortBy: true,
        Cell: ({row}: CellProps<CellValue>) => {
          return <ActionsContainer></ActionsContainer>;
        },
        width: 117,
      },
      {
        Header: '',
        accessor: 'overflow' as const,
        disableSortBy: true,
        dataType: TableColumnDataType.OverflowMenu,
        Cell: ({row}: CellProps<CellValue>) => {
          const employeeId: string = row.original.id;
          const menuItems: MenuLinkItem[] = [];

          if (isAccountAdmin) {
            menuItems.push({
              id: 'remove-employee',
              icon: icons.shareIcon,
              text: 'Remove Employee',
              hoverStyle: true,
              action: () => {
                removeEmployeeFromTeam(
                  teamId,
                  employeeId,
                  `${row.original.firstName} ${row.original.lastName}`
                );
              },
            });
          }

          return menuItems.length > 0 ? (
            <ModalProvider>
              <OverflowContainer>
                <Dropdown
                  position={DropdownPosition.AlignBottomRight}
                  renderButton={(onClick) => (
                    <IconButton
                      icon={icons.kebabMenu}
                      buttonLabel="Overflow Menu"
                      data-qa-button="employee-overflow-menu"
                      onClick={onClick}
                      variant={IconButtonType.NAV}
                    />
                  )}
                >
                  <OverflowMenu hideOnMobile={false} menuItems={menuItems} />
                </Dropdown>
              </OverflowContainer>
            </ModalProvider>
          ) : (
            <></>
          );
        },
        width: 60,
      },
    ];
  }, [tableEmployees]);

  React.useEffect(() => {
    if (!teamId) {
      return;
    }

    dvpApi.getTeamById({accountId: account.id, teamId}).then(({data: team}) => {
      setApiTeam(team);
      refreshTeam(team);
      setIsLoading(false);
    });
  }, [account.id, teamId]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <PageHeader>
        <Heading css={[displayXsMedium]}>{teamDetails?.name}</Heading>
        {badge}
      </PageHeader>
      <TableContainer>
        <TableActionsContainer>
          <TableActionButtonsContainer>
            {isAccountAdmin && (
              <Button
                data-qa-button="Edit Team"
                buttonType={ButtonType.Tertiary}
                icon={icons.editIcon}
                onClick={() => {
                  setEditTeamDrawerOpen(true);
                }}
              >
                Edit Team
              </Button>
            )}
          </TableActionButtonsContainer>
        </TableActionsContainer>
        <Table columns={columns} data={tableEmployees ?? []} size={TableSize.Medium} />
      </TableContainer>
      <AddEditTeamDrawerForm
        mode={AddEditTeamMode.Edit}
        teamInEdit={apiTeam}
        isOpen={isEditTeamDrawerOpen}
        onClose={() => {
          setEditTeamDrawerOpen(false);
        }}
        onSuccess={(updatedTeam) => {
          assertExists(teamDetails?.status, `team ${updatedTeam.id} never set its status`);
          refreshTeam(updatedTeam);
        }}
      />
    </div>
  );
});
