import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {detect} from 'detect-browser';
import {ThemeProvider, Global, css} from '@emotion/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {assertExists} from 'wnd-util/lib/assert';
import {GlobalStyles} from '>styles/globalStyles';
import {dvpEmployeeTheme} from 'wnd-themes/lib/dvpEmployeeTheme';
import {store} from './store/main';
import {routes} from './routes/routes';
import * as NavUtil from '>shared/lib/navigation';

import {initializeAuthToken} from './store/actions/auth';
import {
  AccountCancelledError,
  AccountHasNoPlanError,
  AccountOnHoldPastDueError,
  AccountOnHoldFailedBillingError,
  MissingTokenError,
  UserHasNoDVPAccessError,
  WndError,
} from './errors';
import {redirectToErrorPage, redirectToProductPicker} from '>lib/redirect';
import {refreshUserContext} from './store/actions/user';
import {getInterpretationBuckets} from './store/actions/interpretationBuckets';
import {assertAccountExists, assertValidAuthState} from '>lib/assert';
import {AccountStatus, AccountSubStatus, UserRole} from '>generated/dvp.types';
import {WEBUI_ECOMMERCE_URL, TERMS_AND_CONDITIONS_VERSION} from '>root/env';

function applyBrowserClass(element: HTMLElement) {
  const browserInfo = detect();
  element.className = browserInfo?.name ?? 'unknown-browser';
}

const appRoutes = NavUtil.generateAppRoutes(routes);

function shouldInitializeUserContext(): boolean {
  const paths = ['/accept-invite', '/managecontent', '/error'];

  return !paths.some((path) => window.location.pathname.toLowerCase().includes(path));
}

async function initializeState() {
  const initializeAuthTokenResponse = await store.dispatch(initializeAuthToken());
  if (initializeAuthToken.rejected.match(initializeAuthTokenResponse)) {
    const errorPayload = initializeAuthTokenResponse.payload as WndError;

    if (errorPayload.code === MissingTokenError.code) {
      throw initializeAuthTokenResponse.payload;
    }
    return redirectToErrorPage(errorPayload);
  }

  await store.dispatch(getInterpretationBuckets());

  if (shouldInitializeUserContext()) {
    const refreshUserResponse = await store.dispatch(refreshUserContext());
    if (refreshUserContext.rejected.match(refreshUserResponse)) {
      const errorPayload = refreshUserResponse.payload as WndError;

      if (errorPayload.code === UserHasNoDVPAccessError.code) {
        return redirectToProductPicker();
      }
    }

    const state = store.getState();

    const hasAcceptedTerms = hasAcceptedUnifiedAccountTermsAndConditions();
    const auth = state.auth;
    const accountUser = state.user.accountUser;
    assertValidAuthState(auth);

    const isOwner = accountUser?.role === UserRole.Owner;

    if (!hasAcceptedTerms && !auth.isImpersonated && isOwner) {
      window.location.href = `${WEBUI_ECOMMERCE_URL}/terms-and-conditions`;
      return;
    }

    if (auth.isImpersonated) {
      return;
    }

    const account = state.account.account;
    assertAccountExists(account);
    if (account.status === AccountStatus.NoPlan) {
      return redirectToErrorPage(new AccountHasNoPlanError());
    }

    if (account.status === AccountStatus.OnHold) {
      if (account.subStatus === AccountSubStatus.PastDue) {
        return redirectToErrorPage(new AccountOnHoldPastDueError());
      }
      if (account.subStatus === AccountSubStatus.FailedBilling) {
        return redirectToErrorPage(new AccountOnHoldFailedBillingError());
      }
    }

    if (account.status === AccountStatus.Cancelled) {
      return redirectToErrorPage(new AccountCancelledError());
    }
  }
}

function hasAcceptedUnifiedAccountTermsAndConditions(): boolean {
  const productUser = store.getState().user.productUser;
  assertExists(productUser, 'Product User must exist');
  const unifiedAccounts = productUser.unifiedAccounts;
  const selectedUnifiedAccount = unifiedAccounts[productUser.selectedUnifiedAccountId];
  assertExists(selectedUnifiedAccount, 'The selected Unified Account was not found');

  const unifiedAccountTerms = selectedUnifiedAccount.termsAndConditions;
  const isUnifiedAccountTermsUpToDate =
    unifiedAccountTerms && unifiedAccountTerms.version === TERMS_AND_CONDITIONS_VERSION;

  return Boolean(isUnifiedAccountTermsUpToDate);
}

async function mountApp() {
  try {
    await initializeState();
  } catch (err) {
    if ((err as any).name === 'MissingTokenError') {
      // Do not render the application and wait for redirection
      // initiated by the thrower.
      return;
    }
    throw err;
  }

  const rootElement = document.getElementById('root');
  assertExists(rootElement, 'must have element with id "root"');
  applyBrowserClass(rootElement);

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={dvpEmployeeTheme}>
        <GlobalStyles />
        <Global
          styles={css`
            html,
            body,
            #root {
              min-height: 100%;
              height: 100%;
              max-height: 100%;
            }
          `}
        />
        <App routes={appRoutes} />
      </ThemeProvider>
    </Provider>,
    rootElement
  );
}

// @ts-ignore
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // @ts-ignore
  module.hot.accept('./App', mountApp);
}

mountApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
