import * as React from 'react';

import {Option} from '>shared/components/form/rawControls/rawSelect';
import {assessmentStatusToUserFriendly} from '>components/statusBadges';
import {AssessmentStatus, EmployeeSortField, SortDirection, Tags} from '>generated/dvp.types';
import {AppliedFilterTags} from './employeesFilterModal';
import {EmployeeFilterTag, FilterGroup} from './employeesFilter.styles';
import {mapAssessmentStatusToColor} from '>root/utils/assessmentStatusHelpers';
import { BadgeStyle, getBadgeColorScheme } from '>shared/components/emblems/badge';

export interface Filters {
  sort?: EmployeeSortField;
  sortDirection?: SortDirection;
  searchText?: string;
  departments?: string[];
  manager?: string[];
  jobTitles?: string[];
  locations?: string[];
  assessmentStatuses?: string[];
}

export interface AppliedFilter<FieldName extends string = string> {
  fieldName: FieldName;
  label: string | JSX.Element;
  value: string;
}

export interface SelectFilter<Key extends string> {
  removeFromField(tag: AppliedFilter<Key>): void;
  getAllTags(fieldName: Key): string[];
  getAvailableTags(fieldName: Key): string[];
  addToField(fieldName: Key): (option: Option) => void;
  appliedTags: Array<AppliedFilter<Key>>;
  setAppliedTags(appliedTags: Array<AppliedFilter<Key>>): void;
  removeAllTags(): void;
}

export function useSelectFilter<
  Key extends keyof Tags & string,
  Tags extends {[key in Key]: string[]},
  Filters extends {[key in Key]?: string[]}
>(
  tags: Tags,
  filters: Filters,
  setFilters: (setter: (filters: Filters) => Filters) => void
): SelectFilter<Key> {
  type AppliedTags = Array<AppliedFilter<Key>>;
  const activeTags = React.useRef<{[key in Key]?: Set<string>}>({});

  const availableFieldNames = React.useMemo(() => {
    const fieldNames: Key[] = [];
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        fieldNames.push(key as Key);
      }
    });

    return fieldNames;
  }, []);

  const [appliedTags, setAppliedTags] = React.useState<AppliedTags>(() => {
    const appliedTags: AppliedTags = [];

    for (const key of Object.keys(filters) as Array<Key>) {
      const tags = filters[key];

      if (Array.isArray(tags)) {
        for (const v of tags) {
          appliedTags.push({
            fieldName: key,
            label: v,
            value: v,
          });
        }
      }
    }

    return appliedTags;
  });

  const addToField = React.useCallback(
    (fieldName: Key) => ({value, label}: Option) => {
      if (!activeTags.current[fieldName]) {
        activeTags.current[fieldName] = new Set();
      }

      const activeFieldTags = activeTags.current[fieldName];

      if (!activeFieldTags) {
        throw new Error('This must exist at this point');
      }

      activeFieldTags.add(value);

      setFilters((filters) => {
        const newFilters = {
          ...filters,
          [fieldName]: Array.from(activeFieldTags.values()),
        };

        return newFilters;
      });

      setAppliedTags((appliedTags) => {
        appliedTags.push({
          fieldName,
          label,
          value,
        });

        return appliedTags;
      });
    },
    []
  );

  const getAvailableTags = (fieldName: Key) => {
    return React.useMemo(() => {
      return tags[fieldName].filter((title) => !activeTags.current[fieldName]?.has(title));
    }, [fieldName, tags[fieldName], filters[fieldName]]);
  };

  const getAllTags = (fieldName: Key) => {
    return React.useMemo(() => {
      return tags[fieldName];
    }, [fieldName, tags[fieldName]]);
  };

  const removeAllTags = () => {
    setAppliedTags((_appliedTags) => []);

    for (const fieldName of availableFieldNames) {
      activeTags.current[fieldName]?.clear();
    }

    setFilters(() => {
      const newFilters: Filters = {...filters};

      for (const fieldName of availableFieldNames) {
        if (newFilters[fieldName] && Array.isArray(newFilters[fieldName])) {
          // Since we proved above this field is an array, we can cast it as
          // a type-less empty array safely without know its exact type.
          newFilters[fieldName] = [] as any;
        }
      }

      return newFilters;
    });
  };

  const removeFromField = React.useCallback((tag: AppliedFilter<Key>) => {
    setAppliedTags((appliedTags) => {
      const tagIndex = appliedTags.findIndex(
        (t) => t.fieldName === tag.fieldName && t.value === tag.value
      );
      appliedTags.splice(tagIndex, 1);
      return appliedTags;
    });

    const fieldName = tag.fieldName;
    activeTags.current[fieldName]?.delete(tag.value);

    setFilters((filters) => {
      return {
        ...filters,
        [fieldName]: Array.from(activeTags.current[fieldName]?.values() ?? []),
      };
    });
  }, []);

  const updateAppliedTags = React.useCallback((appliedTags: AppliedTags) => {
    activeTags.current = {};

    appliedTags.forEach((tag) => {
      let tagSet = activeTags.current[tag.fieldName];

      if (!tagSet) {
        tagSet = new Set();
      }

      tagSet.add(tag.value);

      activeTags.current[tag.fieldName] = tagSet;
    });

    setAppliedTags(appliedTags);
  }, []);

  return {
    removeFromField,
    getAllTags,
    getAvailableTags,
    removeAllTags,
    addToField,
    appliedTags,
    setAppliedTags: updateAppliedTags,
  };
}

export function useVerbatimTags<T extends string>(
  fetchTags: (fieldName: T) => string[],
  fieldName: T
) {
  const tags = fetchTags(fieldName).sort();

  return React.useMemo(
    (): Option[] =>
      tags.map((tag) => {
        return {
          label: tag,
          value: tag,
        };
      }),
    [tags]
  );
}

export function useAssessmentStatusTags<T extends string>(
  fetchTags: (fieldName: T) => string[],
  fieldName: T
) {
  const tags = fetchTags(fieldName).sort();

  return React.useMemo(
    (): Option[] =>
      tags.map((tag) => {
        return {
          label: assessmentStatusToUserFriendly(tag as AssessmentStatus),
          value: tag,
        };
      }),
    [tags]
  );
}

export const AppliedEmployeeFilters: React.FC<{
  appliedTags: AppliedFilterTags;
  onRemoveTag: (tag: AppliedFilter<keyof Tags>) => void;
}> = ({appliedTags, onRemoveTag}) => {
  const assessmentStatusFilterTags = appliedTags.filter(
    (tag) => tag.fieldName === 'assessmentStatuses'
  );
  const remainingAppliedTags = appliedTags.filter((tag) => tag.fieldName !== 'assessmentStatuses');

  return (
    <FilterGroup>
      {remainingAppliedTags.map((tag) => (
        <EmployeeFilterTag key={tag.value} text={tag.label} onRemove={() => onRemoveTag(tag)} />
      ))}
      {assessmentStatusFilterTags.map((tag) => (
        <EmployeeFilterTag
          {...getBadgeColorScheme(mapAssessmentStatusToColor(tag.value as AssessmentStatus), BadgeStyle.Outline)}
          key={tag.value}
          text={tag.label}
          onRemove={() => onRemoveTag(tag)}
        />
      ))}
    </FilterGroup>
  );
};
