import * as React from 'react';
import * as colors from 'wnd-themes/lib/colorPalette';
import styled from '@emotion/styled';
import {assert, assertExists, assertUnreachable} from 'wnd-util/lib/assert';
import {
  InterestsSourceConstruct,
  SectionMarkdown,
  TeamReportDomain,
  Typology,
  TypologyCardProps,
  SVGSize,
} from 'wnd-dvp-reports';

import {
  textMdRegular,
  textXlSemibold,
  textXsSemiboldUppercase,
} from '>shared/components/typography/designSystemTypography';
import {chevronRightRoundedIcon} from '>shared/components/icon/icons';
import {Icon} from '>shared/components/icon/icon';
import {vr10, vr2, vr4, vr8} from '>shared/styles/mixins/verticalRhythm';

import {history} from '>root/history';
import {TypologyDiamond} from './typologyDiamond';
import {sharedComponents} from '>lib/markdownSectionComponents';
import {DriverIcon} from './driverIcon';
import {Paragraph} from '>shared/components/typography/baseTypography2';

const TypologyCardContainer = styled.div`
  ${vr10}
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-radius: 1.6rem;
`;

const LinkHeading = styled.div<{fontColor: string; backgroundColor: string}>`
  ${textXsSemiboldUppercase}
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.backgroundColor};
  border: 0.1rem solid ${colors.gray200};
  border-radius: 1.6rem 1.6rem 0 0;
  padding: 1.6rem;
  color: ${(props) => props.fontColor};
  cursor: pointer;
`;

const Body = styled.div`
  padding: 1.6rem 2.4rem 2.4rem 2.4rem;
  border: 0.1rem solid ${colors.gray200};
  border-radius: 0 0 1.6rem 1.6rem;
  border-top: none;
  ${textMdRegular};
`;

const BodyHeading = styled.div`
  display: flex;
  justify-content: space-between;
  ${vr4}
`;

const TypologyWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const Convergence = styled.div``; // placeholder

const StrengthsAndRisks = styled.div`
  display: flex;
  flex: 1;
  gap: 2.4rem;
`;

const StrengthsRisksContainer = styled.div`
  flex: 1;
`;

const StrengthsRisksHeading = styled.div`
  ${textXsSemiboldUppercase}
  ${vr2}
  color: ${colors.gray600};
`;

function renderTypology(
  domain: TeamReportDomain,
  typologyOrMotivator: Typology | InterestsSourceConstruct
): React.ReactNode {
  let text;
  let iconNode;

  if (domain === TeamReportDomain.Drivers) {
    assert(
      typeof typologyOrMotivator === 'string',
      `Expected typologyOrMotivator to be a string, got ${typeof typologyOrMotivator}`
    );
    text = typologyOrMotivator as InterestsSourceConstruct;
    iconNode = <DriverIcon size={SVGSize.Small} highestMotivator={typologyOrMotivator} />;
  } else {
    text = (typologyOrMotivator as Typology).name;
    iconNode = (
      <TypologyDiamond
        domain={domain}
        size={SVGSize.Small}
        typology={typologyOrMotivator as Typology}
      />
    );
  }

  return (
    <>
      {iconNode}
      <div css={textXlSemibold}>{text}</div>
    </>
  );
}

function getHeadingColors(domain: TeamReportDomain): {fontColor: string; backgroundColor: string} {
  let fontColor;
  let backgroundColor;

  switch (domain) {
    case TeamReportDomain.Communication:
      fontColor = colors.indigo900;
      backgroundColor = colors.indigo25;
      break;
    case TeamReportDomain.Work:
      fontColor = colors.fuschia900;
      backgroundColor = colors.fuschia25;
      break;
    case TeamReportDomain.Challenge:
      fontColor = colors.cyan900;
      backgroundColor = colors.cyan25;
      break;
    case TeamReportDomain.Drivers:
      fontColor = colors.yellow900;
      backgroundColor = colors.yellow25;
      break;
    default:
      assertUnreachable(domain);
  }

  return {
    fontColor,
    backgroundColor,
  };
}

export const TypologyCard: React.FC<TypologyCardProps> = ({
  heading,
  domain,
  typologyOrMotivator,
  bodyContent,
  strengthHeading,
  riskHeading,
  strengths,
  risks,
  goToReport,
}) => {
  const goToReportSection = React.useCallback(() => {
    assertExists(goToReport, 'The web version of the typology card must define this.');
    history.push(`#${domain}Section`);
    goToReport();
  }, [domain, goToReport]);

  const {fontColor, backgroundColor} = getHeadingColors(domain);

  sharedComponents.Paragraph = styled(Paragraph)`
    color: ${colors.gray600};
  `;

  return (
    <TypologyCardContainer>
      <LinkHeading
        aria-label={heading}
        onClick={goToReportSection}
        fontColor={fontColor}
        backgroundColor={backgroundColor}
        role="button"
      >
        <div>{heading}</div>
        <Icon src={chevronRightRoundedIcon} small aria-hidden />
      </LinkHeading>
      <Body>
        <BodyHeading>
          <TypologyWithIcon>{renderTypology(domain, typologyOrMotivator)}</TypologyWithIcon>
          <Convergence></Convergence>
        </BodyHeading>
        <SectionMarkdown css={vr8} components={sharedComponents}>
          {bodyContent}
        </SectionMarkdown>
        <StrengthsAndRisks>
          <StrengthsRisksContainer>
            <StrengthsRisksHeading>{strengthHeading}</StrengthsRisksHeading>
            <SectionMarkdown css={vr8} components={sharedComponents}>
              {strengths}
            </SectionMarkdown>
          </StrengthsRisksContainer>
          <StrengthsRisksContainer>
            <StrengthsRisksHeading>{riskHeading}</StrengthsRisksHeading>
            <SectionMarkdown css={vr8} components={sharedComponents}>
              {risks}
            </SectionMarkdown>
          </StrengthsRisksContainer>
        </StrengthsAndRisks>
      </Body>
    </TypologyCardContainer>
  );
};
