"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoBanner = exports.BannerType = exports.BannerSize = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var colors = _interopRequireWildcard(require("wnd-themes/lib/colorPalette"));
var _banner = require("./banner.styles");
var _icons = require("../icon/icons");
var _icon = require("../icon/icon");
const _excluded = ["message", "isDismissible", "onDismiss", "bannerType", "sticky", "size", "innerRef"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var BannerType;
(function (BannerType) {
  BannerType["Info"] = "info";
  BannerType["Error"] = "error";
  BannerType["Success"] = "success";
  BannerType["Warning"] = "warning";
})(BannerType || (exports.BannerType = BannerType = {}));
var BannerSize;
(function (BannerSize) {
  BannerSize["Small"] = "small";
  BannerSize["Large"] = "large";
})(BannerSize || (exports.BannerSize = BannerSize = {}));
const InfoBanner = _ref => {
  let {
      message,
      isDismissible,
      onDismiss,
      bannerType = BannerType.Info,
      sticky = false,
      size = BannerSize.Small,
      innerRef
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  const themeColors = getBannerTheme(bannerType);
  const bannerIcon = getBannerIcon(bannerType);
  return (0, _jsxRuntime.jsxs)(_banner.InfoBannerContainer, _objectSpread(_objectSpread({
    backgroundColor: themeColors.backgroundColor,
    role: bannerType === BannerType.Error ? 'alert' : 'status',
    size: size,
    sticky: sticky,
    ref: innerRef
  }, rest), {}, {
    children: [(0, _jsxRuntime.jsx)(_banner.InfoBannerHalo, {
      "aria-hidden": true,
      borderColor: themeColors.haloBorderColor,
      backgroundColor: themeColors.backgroundColor,
      size: size,
      children: (0, _jsxRuntime.jsx)(_banner.BannerIcon, {
        small: true,
        src: bannerIcon,
        primaryColor: themeColors.iconColor,
        hoverColor: themeColors.iconColor
      })
    }), (0, _jsxRuntime.jsx)(_banner.TextContainer, {
      children: (0, _jsxRuntime.jsx)(_banner.Message, {
        color: themeColors.messageColor,
        size: size,
        children: message
      })
    }), isDismissible && (0, _jsxRuntime.jsx)(_banner.DismissButton, {
      "aria-label": "Dismiss alert",
      onClick: onDismiss,
      children: (0, _jsxRuntime.jsx)(_icon.Icon, {
        src: _icons.exitIcon,
        primaryColor: themeColors.messageColor,
        hoverColor: themeColors.messageColor,
        css: {
          width: '1.2rem',
          height: '1.2rem'
        }
      })
    })]
  }));
};
exports.InfoBanner = InfoBanner;
function getBannerIcon(type) {
  switch (type) {
    case BannerType.Info:
      return _icons.infoRoundedIcon;
    case BannerType.Error:
      return _icons.errorFilledIcon;
    case BannerType.Success:
      return _icons.checkmarkFilledIcon;
    case BannerType.Warning:
      return _icons.errorFilledIcon;
  }
}
const infoBanner = {
  backgroundColor: colors.primary50,
  haloBorderColor: colors.primary200,
  haloBackgroundColor: colors.primary200,
  iconColor: colors.primary500,
  messageColor: colors.primary700
};
const errorBanner = {
  backgroundColor: colors.error50,
  haloBorderColor: colors.error200,
  haloBackgroundColor: colors.error200,
  iconColor: colors.error500,
  messageColor: colors.error700
};
const successBanner = {
  backgroundColor: colors.success50,
  haloBorderColor: colors.success200,
  haloBackgroundColor: colors.success200,
  iconColor: colors.success500,
  messageColor: colors.success700
};
const warningBanner = {
  backgroundColor: colors.warning50,
  haloBorderColor: colors.warning200,
  haloBackgroundColor: colors.warning200,
  iconColor: colors.warning500,
  messageColor: colors.warning700
};
function getBannerTheme(type) {
  switch (type) {
    case BannerType.Info:
      return infoBanner;
    case BannerType.Error:
      return errorBanner;
    case BannerType.Success:
      return successBanner;
    case BannerType.Warning:
      return warningBanner;
  }
}