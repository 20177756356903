import * as React from 'react';
import {mL4} from '>styles/mixins/margins';
import {vr6} from '>styles/mixins/verticalRhythm';
import {textEllipsis, textNoWrap} from '>styles/mixins/textConstraints';
import {SynonymTag} from '>components/measures/synonymTag/synonymTag';
import {
  Header,
  HeaderMainSection,
  Synonyms,
  HeaderIconSection,
  MeasureCardContainer,
  SynonymsContainer,
} from './measureCard.styles';
import {DonutMetric} from '../donutMetric/donutMetric';
import {
  DonutVariant,
  SCORING_BUCKET_PARAMETERS,
  SectionMarkdown,
  SharedComponents,
} from 'wnd-dvp-reports';
import {
  BucketMapping,
  ConstructDomain,
  mapInterpretationBucketToColor,
  mapScoreToInterpretationBucket,
} from 'wnd-dvp-reports';
import {SpotlightFlag} from 'wnd-dvp-reports';

import {MeasureSlider} from '../measureSlider/measureSlider';
import {ReadMoreSection} from './readMoreSection';
import {SpotlightIcon} from './spotlightIcon';
import {Heading} from '>shared/components/heading/heading';
import {displayXsBold, textMdRegular} from '>shared/components/typography/designSystemTypography';

export interface MeasureChapter {
  number: string;
  href: string;
}

export enum CardSize {
  small = 'small',
  default = 'default',
}

interface Props {
  title: string;
  score: number;
  synonyms: string[];
  definition: string;
  constructDomain: ConstructDomain;
  spotlightFlags?: SpotlightFlag[];
  bucketMapping: BucketMapping;
  cardSize?: CardSize;
  sharedComponents: SharedComponents;
  chapters?: MeasureChapter[];
}

export const MeasureCard: React.FC<Props> = ({
  title,
  score,
  synonyms,
  constructDomain,
  definition,
  spotlightFlags,
  bucketMapping,
  cardSize = CardSize.default,
  sharedComponents,
  chapters,
}) => {
  const interpretationBucket = mapScoreToInterpretationBucket(
    score,
    constructDomain,
    bucketMapping
  );
  const color = mapInterpretationBucketToColor(interpretationBucket, constructDomain);
  const {backgroundColor, badgeColor} = SCORING_BUCKET_PARAMETERS[color];

  return (
    <MeasureCardContainer headerColor={backgroundColor}>
      <Header>
        <HeaderMainSection>
          <DonutMetric score={score} variant={DonutVariant.Large} color={color} />
          <Heading css={[vr6, mL4, displayXsBold, textEllipsis, textNoWrap]}>{title}</Heading>
        </HeaderMainSection>
        {spotlightFlags && spotlightFlags.length >= 1 && (
          <HeaderIconSection>
            {spotlightFlags.map((flag) => (
              <SpotlightIcon
                key={flag}
                flag={flag}
                buttonStyle={false}
                color={badgeColor}
                id={`measureCard-${flag}`}
              />
            ))}
          </HeaderIconSection>
        )}
      </Header>
      <SynonymsContainer>
        <Synonyms>
          {synonyms.map(
            (synonym, index) =>
              synonym !== '' && <SynonymTag key={`${synonym}-${index}`} synonym={synonym} />
          )}
        </Synonyms>
      </SynonymsContainer>
      <p css={textMdRegular}>
        <SectionMarkdown components={sharedComponents}>{definition}</SectionMarkdown>
      </p>
      <MeasureSlider
        measureName={title}
        score={score}
        color={color}
        width={cardSize === CardSize.default ? 592 : 360}
        leftAnchorText={'More Effort'}
        rightAnchorText={'Less Effort'}
      />
      {chapters && <ReadMoreSection chapters={chapters} title={title} />}
    </MeasureCardContainer>
  );
};
