import * as React from 'react';
import styled from '@emotion/styled';
import {defaultFocusStyle} from '>styles/mixins/focus';

import {HeaderLogo} from '>shared/components/landmarks/header/header.styles';

import {wonscoreHeaderLogoMobile} from '>shared/components/icon/icons';
import {Link} from 'react-router-dom';
import {RoutePath} from '>root/routes/routes';

const LogoLink = styled(Link)`
  ${defaultFocusStyle};
`;

const LogoButton = styled.button`
  border: none;
  background: none;

  ${defaultFocusStyle}
`;

export const HeaderBrandingLink: React.FC = () => {
  const reload = React.useCallback(() => window.location.reload(), []);

  const isNavigatingFromAdmin = React.useMemo(() => location.pathname.includes('admin'), [
    location,
  ]);

  const HeaderLinkOrButton = React.memo(({children}) => {
    const linkLocation = isNavigatingFromAdmin ? RoutePath.AdminEmployees : RoutePath.EmployeeHome;
    const linkTitle =
      linkLocation === RoutePath.AdminEmployees
        ? `${process.env.DEVELOPMENT_PRODUCT_NAME} Admin Home`
        : `${process.env.DEVELOPMENT_PRODUCT_NAME} Results`;

    return linkLocation === location.pathname ? (
      <LogoButton aria-label={linkTitle} onClick={reload} type="button" data-qa-link="headerLogo">
        {children}
      </LogoButton>
    ) : (
      <LogoLink to={linkLocation} title={linkTitle} data-qa-link="headerLogo">
        {children}
      </LogoLink>
    );
  });

  return (
    <HeaderLinkOrButton>
      <HeaderLogo src={wonscoreHeaderLogoMobile} uniquifyIDs />
    </HeaderLinkOrButton>
  );
};
