"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SideBarItemType = exports.SideBar = exports.SIDEBAR_WIDTH_REM = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _navItem = require("./sideBarItem/navItem");
var _sideBar = require("./sideBar.styles");
var _icon = require("../icon/icon");
var _sidebarHelpMenu = require("../menu/sidebarHelpMenu");
var _header = require("../landmarks/header/header");
var _icons = require("../icon/icons");
var _tooltip = require("../tooltip");
var _breakpoints = require("../../styles/breakpoints");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var SideBarItemType;
(function (SideBarItemType) {
  SideBarItemType[SideBarItemType["navItem"] = 0] = "navItem";
  SideBarItemType[SideBarItemType["divider"] = 1] = "divider";
})(SideBarItemType || (exports.SideBarItemType = SideBarItemType = {}));
const SIDEBAR_WIDTH_REM = exports.SIDEBAR_WIDTH_REM = 7.2;
const SideBar = ({
  items,
  isOpen,
  minSlide,
  maxSlide,
  onAnyItemSelected,
  showToolTip,
  className,
  branding,
  productName,
  setIsOpen
}) => {
  const sidebarRef = React.useRef(null);
  const media = window.matchMedia(_breakpoints.mediaQueries.smallOrLower.media);
  const hideDropdown = React.useCallback(e => {
    let parent = e.target.parentElement;
    while (parent && parent !== sidebarRef.current) {
      parent = parent.parentElement;
    }
    // This is to support nested dropdowns.  If parent is a dropdown component, then we know we are
    // in a context of a nested dropdown and therefore should display the nested dropdown menu
    const isParentDropdownComponent = parent === sidebarRef.current;
    if (media.matches && isParentDropdownComponent) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      window.removeEventListener('click', hideDropdown, true);
    }
  }, []);
  React.useEffect(() => {
    window.addEventListener('click', hideDropdown, true);
    return () => window.removeEventListener('click', hideDropdown, true);
  }, [isOpen]);
  return (0, _jsxRuntime.jsx)(_sideBar.SideBarSlider, {
    className: className,
    id: "sidebar",
    minSlide: minSlide ?? `${SIDEBAR_WIDTH_REM}rem`,
    maxSlide: maxSlide ?? '31rem',
    isOpen: isOpen,
    role: "menubar",
    "aria-label": "Navigation Bar",
    ref: sidebarRef,
    children: (0, _jsxRuntime.jsxs)(_sideBar.StickyContainer, {
      children: [(0, _jsxRuntime.jsxs)(_sideBar.BrandingContainer, {
        children: [(0, _header.renderBranding)(branding, undefined, productName), (0, _jsxRuntime.jsx)(_sideBar.BackButton, {
          buttonLabel: "Close Menu",
          onClick: () => setIsOpen(false),
          children: (0, _jsxRuntime.jsx)(_icon.Icon, {
            src: _icons.roundedCloseIcon
          })
        })]
      }), items.map((item, index) => {
        const uniqueKey = item.id + index;
        return (0, _jsxRuntime.jsxs)(_sideBar.SideBarItemContainer, {
          "data-tip": true,
          "data-for": item.id,
          displayForMobileOnly: item.displayForMobileOnly,
          children: [item.type === SideBarItemType.navItem && item.item ? (0, _jsxRuntime.jsx)(_navItem.SideBarNavItem, _objectSpread(_objectSpread({}, item.item), {}, {
            role: "menuitem",
            qaDataAttribute: item.qaDataAttribute,
            isBold: item.isBold,
            truncateText: item.truncateText,
            showLabel: isOpen,
            onClick: async () => {
              if (item.onItemSelected) {
                await item.onItemSelected();
              }
              if (onAnyItemSelected) {
                onAnyItemSelected();
              }
            }
          })) : (0, _jsxRuntime.jsx)(_sideBar.SideBarDivider, {}), !isOpen && showToolTip && item.type !== SideBarItemType.divider && (0, _jsxRuntime.jsx)(_tooltip.ToolTip, {
            id: item.id,
            offset: {
              left: 15
            },
            place: _tooltip.TooltipPosition.Right,
            children: item.item?.label
          })]
        }, uniqueKey);
      }), !isOpen && (0, _jsxRuntime.jsx)(_sidebarHelpMenu.SidebarHelpMenu, {})]
    })
  });
};
exports.SideBar = SideBar;