import styled from '@emotion/styled';
import {css} from '@emotion/react';

import {respondTo} from '>shared/styles/breakpoints';
import {tableStyles} from './styles';
import * as colors from 'wnd-themes/lib/colorPalette';

const Cell = styled.td`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  height: 4.4rem;
`;

export const TextCell = styled(Cell)<{isNumeric?: boolean}>`
  padding: 0 1.6rem;
  text-align: start;
  overflow: visible;
`;

export const TextContentContainer = styled.div<{
  hasWarning?: boolean;
  hasError?: boolean;
  isButton?: boolean;
}>`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  width: min-content;

  &:hover {
    padding: 0.8rem 1.2rem;
    left: -0.5rem;
    overflow: visible;

    background-color: ${tableStyles.tooltipBackgroundColor};
    border-radius: 0.8rem;
    border: 0.1rem solid ${tableStyles.borderColor};

    position: relative;
    z-index: 1;
    min-width: min-content;
  }

  ${(props) =>
    props.isButton &&
    `
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  `}

  ${(props) =>
    props.isButton &&
    !props.hasWarning &&
    !props.hasError &&
    ` 
    color: ${colors.gray500}
  `}

  ${(props) =>
    props.hasWarning
      ? `
    
    &, &:hover {
      background-color: ${colors.warning50};
      color: ${colors.warning700};
    }
  `
      : props.hasError
      ? `&, &:hover {
      background-color: ${colors.error50};
      color: ${colors.error700};
    }`
      : ``}
`;

export const NameCell = styled(TextCell)`
  min-width: 11.4rem;
  max-width: 11.4rem;

  ${respondTo.xLargeOrHigher} {
    min-width: 12.4rem;
    max-width: 12.4rem;
  }
`;

export const EmailCell = styled(TextCell)`
  min-width: 13rem;
  max-width: 13rem;

  ${respondTo.largeOrHigher} {
    min-width: 16rem;
    max-width: 16rem;
  }

  ${respondTo.xLargeOrHigher} {
    min-width: 21rem;
    max-width: 21rem;
  }
`;

export const ActionsCell = styled(Cell)`
  overflow: visible;
  min-width: 8rem;
  max-width: 8rem;

  ${respondTo.xLargeOrHigher} {
    min-width: 12rem;
    max-width: 12rem;
  }
`;

export const CheckboxCell = styled(TextCell)`
  padding: 0;
  text-align: center;
  min-width: 4.4rem;
  max-width: 4.4rem;
  height: 4.4rem;
  vertical-align: middle;
`;

export const OverflowCell = styled(Cell)`
  min-width: 4.4rem;
  max-width: 4.4rem;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  overflow: visible;
`;

export const HeaderCheckboxCell = styled(CheckboxCell)``;
