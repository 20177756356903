import {
  ActionItemDestructiveModal,
  ActionItemModalType,
} from '>components/actionPlanner/actionItemModals';
import {dvpApi} from '>root/apis';
import {ButtonGroup} from '>shared/components/buttonGroup/buttonGroup';
import {deleteIcon} from '>shared/components/icon/icons';
import {useModal} from '>shared/components/modal/useModal';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';

interface Props {
  selectedTemplateIds: string[];
  onDelete(): void;
}

export const ActionItemTemplateTableButtonGroup: React.FC<Props> = ({
  selectedTemplateIds,
  onDelete,
}) => {
  const {showModal} = useModal();
  const {showSuccessAlert} = useSnackbar();

  const numSelections = selectedTemplateIds.length;
  const canDelete = numSelections > 0;

  return (
    <ButtonGroup
      options={[
        {
          icon: deleteIcon,
          hideText: true,
          text: 'Remove',
          onClick: () => {
            showModal(
              <ActionItemDestructiveModal
                type={ActionItemModalType.DeleteMultiActionItemTemplate}
                quantity={numSelections}
                onYes={async () => {
                  await dvpApi.bulkDeleteActionItemTemplates({templateIds: selectedTemplateIds});
                  showSuccessAlert(`Success! ${numSelections} Action Item Template(s) deleted.`);
                  onDelete();
                }}
              />
            );
          },
          hide: !canDelete,
        },
      ]}
    />
  );
};
