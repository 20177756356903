import * as React from 'react';
import * as colors from 'wnd-themes/lib/colorPalette';
import styled from '@emotion/styled';
import {UseFormMethods} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {assertExists} from 'wnd-util/lib/assert';
import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {TextArea, TextAreaResizeEnum} from '>shared/components/form/textArea';
import {ControlledSelect} from '>shared/components/form/select';
import {Input} from '>shared/components/form/input';
import {actionItemStatusOptions, colorList} from './formOptions';

// Date Picker will be customized in WD-797
const DatePicker = styled(Input)`
  input {
  }
`;

export function buildActionItemFormFields(
  register: UseFormMethods['register'],
  onSelectedAttribute: (attribute: string) => void,
  isFromTemplate: boolean,
  selectableAttributes: string[],
  selectedAttribute?: string
): FormBuilderFields {
  const employee = useSelector((state) => state.employee.employee);

  assertExists(employee, 'Employee should exist when rendering the Action Item form fields');

  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'status',
      label: 'Status',
      component: (
        <ControlledSelect
          name="Status"
          css={{maxWidth: '45rem'}}
          isSearchable={false}
          options={actionItemStatusOptions}
          rules={{required: 'Status is required'}}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'title',
      label: 'Title of action item',
      component: (
        <Input
          css={{maxWidth: '45rem'}}
          placeholder="What do you want to do?"
          ref={register({
            required: 'Title is required',
            pattern: {
              message: 'Title should contain at least 3 characters',
              value: new RegExp(/[^\s]{3,}/),
            },
          })}
          maxLength={500}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'attribute',
      label: 'Attribute',
      component: (
        <ControlledSelect
          name="Attribute"
          css={{maxWidth: '45rem'}}
          isSearchable={false}
          options={selectableAttributes.map((attribute) => ({label: attribute, value: attribute}))}
          onSelectChange={onSelectedAttribute}
          rules={{required: 'Attribute is required'}}
          disabled={isFromTemplate}
          tooltip={
            isFromTemplate
              ? 'Suggested action items are related to specific attributes and cannot be changed.'
              : undefined
          }
        />
      ),
    },

    {
      type: FormBuilderFieldType.Row,
      label: 'dueDateAndColor',
      fields: [
        {
          type: FormBuilderFieldType.Input,
          name: 'dueDate',
          label: 'Due date',
          component: <DatePicker type="date" ref={register({})} />,
        },

        {
          type: FormBuilderFieldType.Input,
          name: 'color',
          label: 'Color',
          component: <ControlledSelect name="Color" isSearchable={false} options={colorList} />,
        },
      ],
    },
    {
      type: FormBuilderFieldType.Custom,
      name: 'divider',
      component: (
        <div>
          <hr css={{backgroundColor: colors.gray200, border: 0, height: '0.1rem'}} />
        </div>
      ),
    },
    //////////// the fields below is not in WD-106 but exists in figma mockup, leaving them here for future stories
    // {
    //   type: FormBuilderFieldType.Row,
    //   label: 'taskType',
    //   fields: [
    //     {
    //       type: FormBuilderFieldType.Input,
    //       name: 'taskType',
    //       label: 'Task Type',
    //       component: (
    //         <ControlledSelect
    //           isSearchable={false}
    //           options={taskTypeList}
    //           placeholder="Number #"
    //         />
    //       ),
    //     },

    //     {
    //       type: FormBuilderFieldType.Input,
    //       name: 'start',
    //       label: 'Start',
    //       component: <Input ref={register({})} type="number" />,
    //     },
    //     {
    //       type: FormBuilderFieldType.Input,
    //       name: 'finish',
    //       label: 'Finish',
    //       component: <Input ref={register({})} type="number" />,
    //     },
    //     {
    //       type: FormBuilderFieldType.Input,
    //       name: 'current',
    //       label: 'Current',
    //       component: <Input ref={register({})} type="number" />,
    //     },
    //   ],
    // },
    {
      type: FormBuilderFieldType.Input,
      name: 'description',
      label: 'Describe your action item.',
      component: (
        <TextArea
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Description should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
          })}
          placeholder="Enter a description..."
          maxLength={5000}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'improve',
      label: `How will this action help you improve${
        selectedAttribute ? ` in ${selectedAttribute}` : ''
      }?`,
      component: (
        <TextArea
          placeholder="Enter a description..."
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'This should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
          })}
          maxLength={5000}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'firstStep',
      label: `What is the first step you can take to make progress toward this action item?`,
      component: (
        <TextArea
          placeholder="Enter a description..."
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'This should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
          })}
          maxLength={5000}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'howToMeasure',
      label: "How will you know when you're done?",
      component: (
        <TextArea
          placeholder="Enter a description..."
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'This should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
          })}
          maxLength={5000}
          helperText="Setting a finish line for each action item allows you to measure the progress you're making."
        />
      ),
    },
  ];
}
