"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.primaryTheme = exports.neutralTheme = exports.destructiveTheme = void 0;
var colors = _interopRequireWildcard(require("wnd-themes/lib/colorPalette"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const primaryTheme = exports.primaryTheme = {
  text: {
    default: colors.primary700
  },
  background: {
    hover: colors.primary50
  }
};
const neutralTheme = exports.neutralTheme = {
  text: {
    default: colors.gray600,
    hover: colors.gray700
  },
  background: {
    hover: colors.gray100
  }
};
const destructiveTheme = exports.destructiveTheme = {
  text: {
    default: colors.error700
  },
  background: {
    hover: colors.error50
  }
};