import * as React from 'react';
import {vr6} from '>styles/mixins/verticalRhythm';
import {IntroductionFormFields} from './formFields';
import {FormProvider, UseFormMethods} from 'react-hook-form';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';

export const defaultIntroductionFormValues: IntroductionFormFields = {
  introduction: '',
  instructions: '',
};

interface Props {
  title: string;
  formMethods: UseFormMethods<IntroductionFormFields>;
}

export const IntroductionForm: React.FC<Props> = ({title, formMethods}) => {
  return (
    <>
      <Heading css={[vr6, displayXsMedium]}>{title}</Heading>
      <FormProvider {...formMethods}>
        <IntroductionFormFields />
      </FormProvider>
    </>
  );
};
