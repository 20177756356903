import * as React from 'react';
import {useDispatch} from 'react-redux';

import {statusToBadgeMapping} from '>components/statusBadges';
import {OverflowMenu} from '>shared/components/menu/overflowMenu';
import * as icons from '>shared/components/icon/icons';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {ArchiveEmployeeModal, ArchiveEmployeeModalMode} from '>components/modal/archiveEmployee';
import {AssessmentInvitationModal} from '>components/modal/assessmentInvitation/assessmentInvitation';
import {ModalProvider, useModal} from '>shared/components/modal/useModal';
import {Icons} from '>shared/styles/icon-types';
import {LocalizedDate} from '>shared/components/util/localizedDate';
import {
  AssessmentStatus,
  Employee,
  EmployeeSelectionMode,
  JobProfileStatus,
} from '>generated/dvp.types';
import {CheckBox, CheckboxSizes} from '>shared/components/form/checkBox';
import {ReleaseEmployeeResultsModal} from '>components/modal/releaseEmployeeResults';
import {impersonateEmployee} from '>root/store/actions/employee';
import {RoutePath} from '>root/routes/routes';
import {history} from '>root/history';
import {ShareResultsModal} from '>components/modal/shareResults/shareResultsModal';
import {Dropdown, DropdownPosition} from '>shared/components/menu/dropdown';
import {IconButton, IconButtonType} from '>shared/components/iconButton/iconButton';
import {SelectedEmployee} from '>root/pages/adminEmployees/employeesTable';

import styled from '@emotion/styled';
import {ToolTip, TooltipPosition} from '>shared/components/tooltip';
import {ActionsContainer, DataTableRowContainer} from '>components/dataTable/dataTableRow';
import {
  ActionsCell,
  CheckboxCell,
  EmailCell,
  NameCell,
  OverflowCell,
} from '>components/dataTable/cellStyles';
import {TextContent} from '>components/dataTable/textContent';
import {
  DateCell,
  DepartmentCell,
  LocationCell,
  StatusCell,
  UserSummaryCell,
} from './employeeRowCells';
import {SelectFilter} from '>root/pages/adminEmployees/filters/manageAppliedFilters';
import {Tags} from '>root/pages/adminEmployees/filters/employeesFilterModal';
import {hasEverHadResults} from '>lib/employee';
import {
  DrawerModalType,
  useEmployeeManagementDrawerModal,
} from '>root/hooks/useEmployeeManagementDrawerModal';

export interface EmployeeData {
  employee: Employee;
  isHidden?: boolean;
}

const NOOP = () => {};

const ActionIconContainer = styled.div`
  padding-left: 0.4rem;
  cursor: pointer;
`;

interface EmployeeActionIconProps {
  id: string;
  tooltipText: string;
  icon: Icons;
  onClick: () => void;
  disabled?: boolean;
}

export const EmployeeActionIcon: React.FC<EmployeeActionIconProps> = ({
  id,
  tooltipText,
  icon,
  onClick,
  disabled,
}) => {
  return (
    <ActionIconContainer>
      <span data-for={`${id}-toolTip`} data-tip={tooltipText}>
        <IconButton
          icon={icon}
          buttonLabel={tooltipText}
          onClick={onClick}
          variant={IconButtonType.NAV}
          disabled={disabled}
        />
      </span>
      <ToolTip id={`${id}-toolTip`} place={TooltipPosition.Left} />
    </ActionIconContainer>
  );
};

interface EmployeeRowProps {
  checked?: boolean;
  index: number;
  row: EmployeeData;
  onSelect?(selected: boolean): void;
  updateRow(row: EmployeeData): void;
  onContextMenuAction?(employeeId: string): void;
  innerRef: React.Ref<HTMLElement>;
  employeeId: string;
  selectFilter: SelectFilter<keyof Tags>;
  // eslint-disable-next-line react/display-name
}

export function rowToSelection(row: EmployeeData): Map<string, SelectedEmployee> {
  return new Map([
    [
      row.employee.id,
      {status: row.employee.lastAssessmentStatus, jobStatus: row.employee.jobProfileStatus},
    ],
  ]);
}

export const EmployeeRow: React.FC<EmployeeRowProps> = React.memo(
  ({
    index,
    row,
    updateRow,
    checked,
    onSelect,
    onContextMenuAction,
    innerRef,
    employeeId,
    selectFilter,
  }) => {
    const employee = row.employee;

    const {showModal} = useModal();
    const {
      setIsDrawerModalOpen,
      setSelectedEmployee,
      setModalType,
    } = useEmployeeManagementDrawerModal();

    const dispatch = useDispatch();

    const releaseResults = React.useCallback(() => {
      showModal(
        <ReleaseEmployeeResultsModal
          employeeSelection={{
            selectionMode: EmployeeSelectionMode.Inclusive,
            activeEmployees: rowToSelection(row),
            selectionCount: 1,
            version: 0,
          }}
          employeeDetails={employee}
          onRelease={() => {
            onContextMenuAction?.(employeeId);
          }}
        />
      );
    }, [employee, onContextMenuAction]);

    const sendInvite = React.useCallback(() => {
      showModal(
        <AssessmentInvitationModal
          employeeSelection={{
            selectionMode: EmployeeSelectionMode.Inclusive,
            activeEmployees: rowToSelection(row),
            selectionCount: 1,
            version: 0,
          }}
          employeeDetails={employee}
          onInvite={() => {
            onContextMenuAction?.(employee.id);
          }}
        />
      );
    }, [onContextMenuAction]);

    const openConfirmJobTitleModal = React.useCallback(() => {
      setSelectedEmployee(employee);
      setModalType(DrawerModalType.ConfirmJobTitle);
      setIsDrawerModalOpen(true);
    }, [employee]);

    const viewEmployeeResults = React.useCallback(async () => {
      await dispatch(impersonateEmployee(employee.id));

      history.push(RoutePath.EmployeeHome);
    }, []);

    const shareResults = React.useCallback(() => {
      showModal(<ShareResultsModal employees={[employee]} />);
    }, []);

    const filterByArchived = React.useCallback(() => {
      const addFn = selectFilter.addToField('assessmentStatuses');
      addFn({label: 'Archived', value: 'isArchived'});
    }, [selectFilter]);

    const removeArchivedFilter = React.useCallback(() => {
      selectFilter.removeFromField({
        fieldName: 'assessmentStatuses',
        label: 'Archived',
        value: 'isArchived',
      });
    }, [selectFilter]);

    const allowReleaseResults =
      employee.jobProfileStatus === JobProfileStatus.Success &&
      (employee.lastAssessmentStatus === AssessmentStatus.AwaitingResults ||
        employee.lastAssessmentStatus === AssessmentStatus.ResultsReleased);

    const allowViewAndShareResults =
      employee.jobProfileStatus === JobProfileStatus.Success && hasEverHadResults(employee);

    const renderEditEmployeeModal = React.useCallback(() => {
      setSelectedEmployee(employee);
      setModalType(DrawerModalType.EditEmployee);
      setIsDrawerModalOpen(true);
    }, [employee]);

    const menuItems: MenuLinkItem[] = React.useMemo(() => {
      if (employee.isArchived) {
        return [
          {
            id: 'unarchive-employee',
            icon: icons.unarchive,
            text: 'Unarchive',
            hoverStyle: true,
            modal: (
              <ArchiveEmployeeModal
                employee={employee}
                employeeSelection={{
                  selectionMode: EmployeeSelectionMode.Inclusive,
                  activeEmployees: rowToSelection(row),
                  selectionCount: 1,
                  version: 0,
                }}
                mode={ArchiveEmployeeModalMode.UnArchive}
                onArchiveChange={() => {
                  removeArchivedFilter();
                }}
              />
            ),
          },
        ];
      }

      const items: MenuLinkItem[] = [
        {
          id: 'edit-details',
          icon: icons.editIcon,
          text: 'Edit Details',
          hoverStyle: true,
          action: renderEditEmployeeModal,
        },
        {
          id: 'send-invitation',
          icon: icons.inviteIcon,
          text: 'Send Invitation',
          hoverStyle: true,
          action: sendInvite,
        },
      ];

      if (allowViewAndShareResults) {
        items.push({
          id: 'view-results',
          icon: icons.bookIcon,
          text: 'View Results',
          hoverStyle: true,
          action: viewEmployeeResults,
        });
      }

      if (allowReleaseResults) {
        items.push({
          id: 'release-results',
          icon: icons.sendIcon,
          text: `${
            employee.lastAssessmentStatus === AssessmentStatus.ResultsReleased
              ? 'Resend'
              : 'Release'
          } Results`,
          hoverStyle: true,
          action: releaseResults,
        });
      }

      if (allowViewAndShareResults) {
        items.push({
          id: 'share-results',
          icon: icons.shareIcon,
          text: 'Share Results',
          hoverStyle: true,
          action: shareResults,
        });
      }

      items.push({
        id: 'archive-employee',
        icon: icons.archive,
        text: 'Archive',
        hoverStyle: true,
        modal: (
          <ArchiveEmployeeModal
            employee={employee}
            employeeSelection={{
              selectionMode: EmployeeSelectionMode.Inclusive,
              activeEmployees: rowToSelection(row),
              selectionCount: 1,
              version: 0,
            }}
            mode={ArchiveEmployeeModalMode.Archive}
            onArchiveChange={() => {
              filterByArchived();
            }}
          />
        ),
      });

      return items;
    }, [row]);

    const isInvalidJobProfile = employee.jobProfileStatus === JobProfileStatus.Failure;

    const actions = React.useMemo(() => {
      if (employee.isArchived) {
        return []; // No actions allowed on archived employees
      }

      const applicableActions = [];
      if (employee.lastAssessmentStatus === AssessmentStatus.New) {
        applicableActions.push({
          id: `invitationAction-${employee.id}`,
          tooltipText: 'Send Invitation',
          icon: icons.inviteIcon,
          onClick: sendInvite,
        });
      }
      if (employee.lastAssessmentStatus === AssessmentStatus.AwaitingResults) {
        applicableActions.push({
          id: `releaseAction-${employee.id}`,
          tooltipText: 'Release Results',
          icon: icons.sendRoundedIcon,
          onClick: releaseResults,
          disabled: !allowReleaseResults,
        });
      }
      if (employee.lastAssessmentStatus === AssessmentStatus.Expired) {
        applicableActions.push({
          id: `invitationAction-${employee.id}`,
          tooltipText: 'Send Invitation',
          icon: icons.inviteIcon,
          onClick: sendInvite,
        });
      }
      return applicableActions;
    }, [employee]);

    return (
      <DataTableRowContainer ref={innerRef as React.Ref<HTMLTableRowElement>} aria-rowindex={index}>
        <CheckboxCell>
          <CheckBox
            hideLabel
            id={`employeeSelection-${employeeId}`}
            label={`Select ${employee.preferredName || employee.firstName} ${employee.lastName}`}
            onChange={onSelect ?? NOOP}
            checked={checked}
            size={CheckboxSizes.Small}
          />
        </CheckboxCell>
        <NameCell>
          <TextContent>{employee.lastName}</TextContent>
        </NameCell>
        <NameCell>
          <TextContent>{employee.preferredName || employee.firstName}</TextContent>
        </NameCell>
        <EmailCell>
          <TextContent>{employee.email}</TextContent>
        </EmailCell>
        <UserSummaryCell>
          <ModalProvider>
            <TextContent
              hasError={isInvalidJobProfile}
              warningText={isInvalidJobProfile ? 'Invalid Job Profile' : 'Job Profile'}
              onClick={() => {
                if (isInvalidJobProfile) {
                  openConfirmJobTitleModal();
                }
              }}
            >
              {employee.jobTitle}
            </TextContent>
          </ModalProvider>
        </UserSummaryCell>
        <DepartmentCell>
          <TextContent>{employee.department}</TextContent>
        </DepartmentCell>
        <LocationCell>
          <TextContent>{employee.location ?? ''}</TextContent>
        </LocationCell>
        <DateCell>
          <TextContent>
            {employee.lastAssessmentStatus !== AssessmentStatus.New &&
              employee.dateOfLastAssessmentSentDate && (
                <LocalizedDate date={new Date(employee.dateOfLastAssessmentSentDate)} />
              )}
          </TextContent>
        </DateCell>
        <StatusCell>
          {employee.isArchived
            ? statusToBadgeMapping.Archived
            : statusToBadgeMapping[employee.lastAssessmentStatus]}
        </StatusCell>
        <ActionsCell>
          <ActionsContainer>
            {actions.map((action) => (
              <EmployeeActionIcon
                key={action.id}
                id={action.id}
                tooltipText={action.tooltipText}
                icon={action.icon}
                onClick={action.onClick}
                disabled={action.disabled}
              />
            ))}
          </ActionsContainer>
        </ActionsCell>
        <OverflowCell>
          {menuItems.length > 0 && (
            <ModalProvider>
              <Dropdown
                closeOnSelect
                position={DropdownPosition.AlignBottomRight}
                renderButton={(onClick) => (
                  <IconButton
                    icon={icons.kebabMenu}
                    buttonLabel="View available actions for this employee"
                    data-qa-button="employee-actions-button"
                    onClick={onClick}
                    variant={IconButtonType.NAV}
                  />
                )}
              >
                <OverflowMenu hideOnMobile={false} menuItems={menuItems} />
              </Dropdown>
            </ModalProvider>
          )}
        </OverflowCell>
      </DataTableRowContainer>
    );
  }
);

EmployeeRow.displayName = 'EmployeeRow';
