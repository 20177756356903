import React from 'react';
import {ActionItem, IndexMeasures, NewActionItem} from '>generated/dvp.types';

import {Heading} from '>shared/components/heading/heading';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {Button, ButtonType} from '>shared/components/button/button';

import styled from '@emotion/styled';
import {ActionItemForm} from './form/form';

import {useForm} from 'react-hook-form';
import {
  ActionItemFormFields,
  convertFormFieldsToActionItem,
  convertActionItemToFormFields,
} from './form/formFields';
import {defaultActionFormValues, validateFormForPublish} from './form/form';

import {useModal, ModalProvider} from '>shared/components/modal/useModal';
import {ActionItemDestructiveModal, ActionItemModalType} from './actionItemModals';

import {useSelector} from 'react-redux';
import {assertExists} from 'wnd-util/lib/assert';
import {useAsyncDispatch} from '>root/store/main';
import {getIndexMeasures} from '>root/store/actions/employee';
import {LoadingScreen} from '>shared/components/loadingScreen';
import {sortBy} from 'lodash';
import {redirectToErrorPage} from '>lib/redirect';
import {rollbarLogger} from '>lib/logger';
interface ActionItemEditorProps {
  actionItem: ActionItem | undefined;
  onComplete: (actionItem?: ActionItem | NewActionItem) => void;
}

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
const BodySection = styled.div`
  display: block;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  direction: rtl;
`;

const EditPageContainer = styled.div`
  max-width: 64rem;
`;

function getAttributesFromIndexMeasures(measures: IndexMeasures): string[] {
  const sortedAttributes = sortBy(measures, 'title');
  return sortedAttributes.map((a) => a.title);
}

export const ActionItemEditor: React.FC<ActionItemEditorProps> = ({actionItem, onComplete}) => {
  const {showModal} = useModal();
  const asyncDispatch = useAsyncDispatch();
  const [selectableAttributes, setSelectedAttributes] = React.useState<string[] | undefined>();

  const employee = useSelector((state) => state.employee.employee);
  assertExists(employee, 'Employee should exist when rendering the Action Item form fields');

  const account = useSelector((state) => state.account.account);
  assertExists(account, 'Account should exist when rendering the Action Item form fields');

  const actionFormMethods = useForm<ActionItemFormFields>({
    mode: 'all',
    defaultValues: actionItem ? convertActionItemToFormFields(actionItem) : defaultActionFormValues,
  });

  const indexMeasures = useSelector((state) => state.employee.indexMeasures);

  const {
    reset,
    formState: {isDirty},
  } = actionFormMethods;

  // Lazy-load IndexMeasures to determine selectable attributes
  React.useEffect(() => {
    if (indexMeasures) {
      setSelectedAttributes(getAttributesFromIndexMeasures(indexMeasures));
    } else {
      asyncDispatch(getIndexMeasures(employee.id)).catch((err) => {
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      });
    }
  }, [employee.id, indexMeasures]);

  React.useEffect(() => {
    if (!actionItem) {
      reset(defaultActionFormValues);
    }
  }, [actionItem, defaultActionFormValues]);

  const submitActionItem = React.useCallback(async () => {
    if (!validateFormForPublish(actionFormMethods)) {
      return;
    }
    const formValues = actionFormMethods.getValues();
    const actionItemFromForm = convertFormFieldsToActionItem(formValues, employee.id, account.id);
    onComplete(actionItemFromForm);
  }, [actionFormMethods, onComplete, employee, account]);

  const cancelActionItem = React.useCallback(() => {
    if (isDirty) {
      return showModal(
        <ActionItemDestructiveModal
          type={ActionItemModalType.Discard}
          onYes={handleCancelActionItemEdit}
        />
      );
    } else {
      handleCancelActionItemEdit();
    }
  }, [isDirty]);

  const handleCancelActionItemEdit = React.useCallback(() => {
    onComplete();
  }, [onComplete]);

  return (
    <ModalProvider>
      <EditPageContainer css={{flexGrow: 1}}>
        <HeaderSection>
          <Heading css={displayXsSemibold}>
            {actionItem ? 'Edit' : 'Create a new'} action item
          </Heading>
          <ButtonsContainer>
            <Button
              buttonType={ButtonType.Primary}
              data-qa-button="save-action-item-button"
              onClick={submitActionItem}
              disabled={!isDirty}
            >
              Save
            </Button>
            <Button
              buttonType={ButtonType.Neutral}
              data-qa-button="cancel-action-item-button"
              onClick={cancelActionItem}
            >
              Cancel
            </Button>
          </ButtonsContainer>
        </HeaderSection>
        <BodySection>
          {selectableAttributes ? (
            <>
              <ActionItemForm
                title=""
                formMethods={actionFormMethods}
                selectableAttributes={selectableAttributes}
                isFromTemplate={Boolean(actionItem?.templateId)}
              />
              <ButtonsContainer css={{paddingTop: '3.2rem'}}>
                <Button
                  buttonType={ButtonType.Primary}
                  data-qa-button="save-action-item-button"
                  onClick={submitActionItem}
                  disabled={!isDirty}
                >
                  Save
                </Button>
                <Button
                  buttonType={ButtonType.Neutral}
                  data-qa-button="cancel-action-item-button"
                  onClick={cancelActionItem}
                >
                  Cancel
                </Button>
              </ButtonsContainer>
            </>
          ) : (
            <LoadingScreen />
          )}
        </BodySection>
      </EditPageContainer>
    </ModalProvider>
  );
};
