import React from 'react';
import {useModal} from '>shared/components/modal/useModal';
import {Action, ActionType} from '>shared/components/modal/modal';
import {AlertModal, AlertModalVariants} from '>shared/components/modal/alertModal';
import {ButtonType} from '>shared/components/button/button';

export enum ActionItemModalType {
  Discard,
  DeleteActionItem,
  DeleteSingleActionItemTemplate,
  DeleteMultiActionItemTemplate,
}

interface ActionItemDestructiveModalProps {
  type: ActionItemModalType;
  onYes: () => void;
  quantity?: number;
}

export const ActionItemDestructiveModal: React.FC<ActionItemDestructiveModalProps> = ({
  type,
  onYes,
  quantity,
}) => {
  const {hideModal} = useModal();
  const handleYes = () => {
    hideModal();
    onYes();
  };

  const content = {
    [ActionItemModalType.Discard]: {
      title: 'Discard unsaved changes',
      message: 'Are you sure you want to discard unsaved changes?',
      primaryButtonText: 'Discard',
    },
    [ActionItemModalType.DeleteActionItem]: {
      title: 'Delete Action Item',
      message: 'Are you sure you want to delete this action item? This action can’t be undone.',
      primaryButtonText: 'Delete',
    },
    [ActionItemModalType.DeleteSingleActionItemTemplate]: {
      title: 'Delete Action Item Template',
      message:
        'Are you sure you want to delete this action item template? This action can’t be undone.',
      primaryButtonText: 'Delete',
    },
    [ActionItemModalType.DeleteMultiActionItemTemplate]: {
      title: 'Delete Action Item Templates',
      message: `Are you sure you want to delete ${quantity} action item template(s)? This action can’t be undone.`,
      primaryButtonText: 'Delete',
    },
  };

  const {title, message, primaryButtonText} = content[type];

  const modalActions: Action[] = [
    {
      type: ActionType.Primary,
      label: primaryButtonText,
      inputType: 'button',
      disabled: false,
      onClick: handleYes,
      qaDataAttribute: 'confirm-destructive-action-item-modal',
      buttonType: ButtonType.SuperDestructive,
    },
    {
      type: ActionType.Secondary,
      label: 'Cancel',
      inputType: 'button',
      disabled: false,
      onClick: hideModal,
      qaDataAttribute: 'cancel-destructive-action-item-modal',
    },
  ];

  return (
    <AlertModal
      title={title}
      actions={modalActions}
      subtitle={message}
      variant={AlertModalVariants.Delete}
    />
  );
};
