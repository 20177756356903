import * as React from 'react';
import {Redirect, RouteChildrenProps} from 'react-router-dom';
import {vr4} from '>shared/styles/mixins/verticalRhythm';
import {redirectToErrorPage} from '>lib/redirect';
import {findLast} from 'lodash';

import {IndexContentLayout} from '>components/indexContentLayout';
import {
  DashboardIndexContainer,
  EmployeeJobTitle,
  EmployeeName,
  OnboardingHeading,
  OptionsSectionContainer,
} from './employeeHome.styles';
import {useSelector} from 'react-redux';
import {RoutePath} from '>root/routes/routes';
import {assertExists} from 'wnd-util/lib/assert';
import {UserRole} from '>generated/dvp.types';
import {EmployeeHasNoActiveResultsError} from '>root/errors';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {mediaQueries} from '>shared/styles/breakpoints';
import {Heading} from '>shared/components/heading/heading';
import {textMdBold, textSmRegular} from '>shared/components/typography/designSystemTypography';
import {CurrentTab, EmployeeHomePageContentTabbed} from './employeeHomeTabbedContent';
import {getJobNameWithIndefiniteArticle, makeSpinalCase} from 'wnd-dvp-reports';
import {DownloadShareMenu} from '>components/downloadShareMenu';
import {
  Orientation,
  Progress,
  ProgressSteps,
  Size,
  Variant,
} from '>shared/components/progressSteps';
import {useAppDispatch} from '>root/store/main';
import {getHomePageContent, updateOnboardingProgress} from '>root/store/actions/employee';
import {HeaderLink, TabTableOfContents} from '>components/tabTableOfContents';
import {IconButton} from '>shared/components/iconButton/iconButton';
import {Icon} from '>shared/components/icon/icon';
import {roundedCloseIcon} from '>shared/components/icon/icons';
import {assertEmployeeExists} from '>lib/assert';
import {hasEverHadResults} from '>lib/employee';
import {PageLayoutComponents} from '>shared/components/layouts/responsive/responsiveLayout';

export enum AnchorId {
  successAnchorId = 'success-in-your-role',
  strengthsAnchorId = 'greatest-strengths',
  focusAnchorId = 'focus-opportunities',
}

enum AllowedContentSections {
  Summary = 'summary',
  Body = 'body',
  RoleCriticalAttributes = 'roleCriticalAttributes',
  LeastEffortAttributes = 'leastEffortAttributes',
  MostEffortAttributes = 'mostEffortAttributes',
  closing = 'closing',
}

function computeProgress(
  onboardingIndex: number,
  currentOnboardingStep: number,
  currentTabIndex: number
): Progress {
  if (currentOnboardingStep > onboardingIndex) {
    return Progress.Complete;
  } else if (currentOnboardingStep < onboardingIndex) {
    return Progress.Incomplete;
  } else {
    if (currentTabIndex === currentOnboardingStep) {
      return Progress.Current;
    } else {
      return Progress.Incomplete;
    }
  }
}

function matchHeadersFromContent(content: string) {
  const regex = /^#{1,2}\s+(.*?)$/gm;

  const headers: string[] = [];

  let match;
  while ((match = regex.exec(content)) !== null) {
    headers.push(match[1]);
  }

  return headers;
}

function generateTableOfContents(currentTab?: CurrentTab): HeaderLink[] | undefined {
  if (!currentTab) {
    return;
  }

  const linkList: HeaderLink[] = [];

  Object.entries(currentTab.content).forEach(([key, value]) => {
    if (Object.values(AllowedContentSections).includes(key as AllowedContentSections)) {
      switch (key) {
        case AllowedContentSections.RoleCriticalAttributes:
          linkList.push({
            text: `Critical Attributes for ${currentTab.jobTitle}`,
            targetId: `${AnchorId.successAnchorId}`,
          });
          break;
        case AllowedContentSections.LeastEffortAttributes:
          linkList.push({
            text: 'Least Effort Attributes',
            targetId: `${AnchorId.strengthsAnchorId}`,
          });
          break;
        case AllowedContentSections.MostEffortAttributes:
          linkList.push({
            text: 'Most Effort Attributes',
            targetId: `${AnchorId.focusAnchorId}`,
          });
          break;

        default:
          break;
      }

      const headers = matchHeadersFromContent(value);
      headers.forEach((header) => {
        linkList.push({
          text: header,
          targetId: `${makeSpinalCase(header)}`,
        });
      });
    }
  });

  return linkList;
}

export const EmployeeHome: React.FC<RouteChildrenProps> = ({history}) => {
  const employee = useSelector((state) => state.employee.employee);
  assertEmployeeExists(employee);
  const accountUser = useSelector((state) => state.user.accountUser);
  const [currentTab, setCurrentTab] = React.useState<CurrentTab>();
  const isMobile = window.matchMedia(mediaQueries.smallOrLower.media).matches;
  const [currentOnboardingStep, setCurrentOnboardingStep] = React.useState(-1);
  const [dirtyOnboardingStep, setDirtyOnboardingStep] = React.useState(-1);
  const [contentWidth, setContentWidth] = React.useState(0);
  const {setActiveSectionTitle, setHiddenComponent} = useResponsiveLayout();

  const dispatch = useAppDispatch();
  const homePageItems = useSelector((state) => state.employee.homePageItems);
  const spotlightMeasures = useSelector((state) => state.employee.spotlightMeasures);

  React.useEffect(() => {
    dispatch(getHomePageContent());
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      setActiveSectionTitle('Your Development Journey');
    }
  });

  if (accountUser?.role === UserRole.Coach && !employee?.isImpersonated) {
    return <Redirect to={RoutePath.Coaching} />;
  }

  const {isCompleted: isOnboardingCompleted, finishedSections: onboardingProgress} =
    employee.userData?.onboardingProgress ?? {};

  React.useEffect(() => {
    if (!homePageItems) {
      return setCurrentOnboardingStep(-1);
    } else if (currentOnboardingStep > -1) {
      return;
    } else if (!onboardingProgress) {
      return setCurrentOnboardingStep(0);
    }

    if (onboardingProgress.find((sectionId) => sectionId === 'developIndex')) {
      return setCurrentOnboardingStep(homePageItems.length + 1);
    }

    const lastMatchingId = findLast(onboardingProgress, (sectionId) =>
      homePageItems.find((item) => item.id === sectionId)
    );

    const furthestCompletedTabIndex =
      homePageItems.findIndex((item) => item.id === lastMatchingId) ?? -1;

    return setCurrentOnboardingStep(furthestCompletedTabIndex + 1);
  }, [onboardingProgress, homePageItems]);

  const currentTabIndex = React.useMemo(() => {
    return (
      homePageItems?.findIndex((item) => item.content.title === currentTab?.content.title) ?? -1
    );
  }, [homePageItems, currentTab]);

  React.useEffect(() => {
    if (!homePageItems) {
      return;
    }

    if (currentTabIndex > -1 && currentTabIndex === currentOnboardingStep) {
      // We've finished the current onboarding step, so we can mark
      // this as done

      dispatch(
        updateOnboardingProgress({
          sectionCompleted: homePageItems[currentOnboardingStep].id,
        })
      );

      setDirtyOnboardingStep(currentOnboardingStep + 1);
    }
  }, [currentTabIndex, currentOnboardingStep, currentTab, homePageItems]);

  const dismissOnboardingGuide = React.useCallback(() => {
    dispatch(updateOnboardingProgress({isCompleted: true}));
  }, []);

  React.useEffect(() => {
    if (dirtyOnboardingStep > currentOnboardingStep) {
      setCurrentOnboardingStep(dirtyOnboardingStep);
    }
  }, [currentTab]);

  if (!hasEverHadResults(employee)) {
    redirectToErrorPage(
      new EmployeeHasNoActiveResultsError('Employee has no active results to render')
    );
    return null;
  }

  const account = useSelector((state) => state.account.account);
  assertExists(account, 'Account needs to be initialized to render employee home page');

  const employeeName = `${employee.preferredName || employee.firstName} ${employee.lastName}`;
  const jobTitleWithIndefiniteArticle = getJobNameWithIndefiniteArticle(employee.jobTitle);

  const progressSteps = React.useMemo(
    () =>
      homePageItems
        ? [
            ...homePageItems.map((item, index) => {
              return {
                title: item.content.title,
                details: item.content.description,
                progress: computeProgress(index, currentOnboardingStep, currentTabIndex),
                onClick() {
                  window.location.hash = `#${makeSpinalCase(item.content.title)}`;
                  if (isMobile) {
                    setHiddenComponent(PageLayoutComponents.Nav);
                  }
                },
              };
            }),
            {
              title: 'Explore your scores',
              details: 'Determining your next steps',
              progress: computeProgress(
                homePageItems.length,
                currentOnboardingStep,
                currentTabIndex
              ),
              onClick() {
                history.push(RoutePath.EmployeeDevelopIndex);
                if (isMobile) {
                  setHiddenComponent(PageLayoutComponents.Nav);
                }
              },
            },
          ]
        : [],
    [homePageItems, currentOnboardingStep, currentTabIndex]
  );

  return (
    <IndexContentLayout
      indexSection={
        <DashboardIndexContainer>
          <EmployeeName>{employeeName}</EmployeeName>
          <EmployeeJobTitle>{employee.jobTitle}</EmployeeJobTitle>
          <p css={[vr4, textSmRegular]}>
            Wonderlic Develop helps enhance your strategic self-awareness of key attributes that
            drive your behaviors as {jobTitleWithIndefiniteArticle}. Self-awareness leads to better
            job performance and satisfaction when you prioritize taking action on your results.
          </p>
          {!isOnboardingCompleted && (
            <>
              <OnboardingHeading>
                <Heading css={textMdBold}>Getting started</Heading>
                {Boolean(onboardingProgress?.find((id) => id === 'developIndex')) ? (
                  <IconButton
                    buttonLabel={'Dismiss "Getting started"'}
                    onClick={dismissOnboardingGuide}
                  >
                    <Icon src={roundedCloseIcon} />
                  </IconButton>
                ) : (
                  ''
                )}
              </OnboardingHeading>
              {homePageItems && (
                <ProgressSteps
                  size={Size.Small}
                  orientation={Orientation.Vertical}
                  variant={Variant.Dark}
                  steps={progressSteps}
                />
              )}
            </>
          )}
        </DashboardIndexContainer>
      }
      contentSection={
        <EmployeeHomePageContentTabbed
          homePageItems={homePageItems}
          spotlightMeasures={spotlightMeasures}
          accountId={account.id}
          employee={employee}
          setCurrentTab={setCurrentTab}
          titleWidth={contentWidth}
        />
      }
      optionsSection={
        <OptionsSectionContainer>
          <TabTableOfContents links={generateTableOfContents(currentTab)} />
        </OptionsSectionContainer>
      }
      mobileMenuButton={<DownloadShareMenu employeesToShare={[employee]} />}
      onContentWidthChange={(width) => {
        setContentWidth(width);
      }}
      showNotifications
      fixedHeading
    ></IndexContentLayout>
  );
};
