"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.areAnyProductsAccessible = areAnyProductsAccessible;
exports.buildBetaMenuItem = buildBetaMenuItem;
exports.buildCandidateEmailSettingsMenuItem = buildCandidateEmailSettingsMenuItem;
exports.buildLogoutMenuItem = buildLogoutMenuItem;
exports.buildNotificationManagementMenuItem = buildNotificationManagementMenuItem;
exports.buildUnifiedAccountMenuItem = buildUnifiedAccountMenuItem;
exports.buildUserManagementMenuItem = buildUserManagementMenuItem;
exports.getMostRecentlyAccessedAccount = getMostRecentlyAccessedAccount;
exports.isAccountOwnerOrAdmin = isAccountOwnerOrAdmin;
exports.isDevelopAccessible = isDevelopAccessible;
exports.isProductAccessible = isProductAccessible;
exports.isSelectAccessible = isSelectAccessible;
exports.shouldAttemptToShowAccountSwitcher = shouldAttemptToShowAccountSwitcher;
var _icons = require("../components/icon/icons");
var colors = _interopRequireWildcard(require("wnd-themes/lib/colorPalette"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var ProductType;
(function (ProductType) {
  ProductType["Selection"] = "selection";
  ProductType["Development"] = "development";
})(ProductType || (ProductType = {}));
function shouldAttemptToShowAccountSwitcher(productUser) {
  if (!productUser) {
    return false;
  }
  const hasMultipleUnifiedAccounts = Object.keys(productUser.unifiedAccounts).length >= 2;
  return hasMultipleUnifiedAccounts;
}
function isAccountOwnerOrAdmin(accountUser) {
  if (!accountUser) {
    return false;
  } else {
    return ['owner', 'admin'].includes(accountUser.role.toLowerCase());
  }
}
function isProductAccessible(productAccount) {
  return Boolean(productAccount) && !productAccount?.isPendingAcceptance;
}
function isSelectAccessible(userUnifiedAccount) {
  return isProductAccessible(userUnifiedAccount.productAccounts.selection);
}
function isDevelopAccessible(userUnifiedAccount) {
  return isProductAccessible(userUnifiedAccount.productAccounts.development);
}
function areAnyProductsAccessible(userUnifiedAccount) {
  return isSelectAccessible(userUnifiedAccount) || isDevelopAccessible(userUnifiedAccount);
}
function buildUnifiedAccountMenuItem(unifiedAccountId, isAccountCurrentlyInUse, companyName) {
  return {
    id: unifiedAccountId,
    icon: isAccountCurrentlyInUse ? _icons.checkmarkIcon : undefined,
    iconColor: isAccountCurrentlyInUse ? colors.primary600 : colors.gray500,
    iconDivHidden: true,
    text: companyName,
    href: '/',
    truncateText: true,
    hoverStyle: true,
    currentlyUsing: isAccountCurrentlyInUse
  };
}
function buildBetaMenuItem(menuLength) {
  return {
    id: 'manage-beta',
    href: `${process.env.WEBUI_PROFILE_V2_URL}/beta-access`,
    text: 'Manage beta access',
    icon: _icons.undoIcon,
    iconColor: colors.gray500,
    iconDivHidden: false,
    hoverStyle: true,
    border: menuLength > 0,
    qaDataAttribute: {
      type: 'data-qa-link',
      name: 'manage-beta-access-link'
    }
  };
}
function buildLogoutMenuItem(handleLogout, menuLength) {
  return {
    id: 'logout',
    iconDivHidden: false,
    icon: _icons.logoutIcon,
    action: handleLogout,
    text: 'Sign out',
    hoverStyle: true,
    border: menuLength > 0,
    qaDataAttribute: {
      type: 'data-qa-link',
      name: 'logout'
    }
  };
}
function buildCandidateEmailSettingsMenuItem(menuLength) {
  return {
    id: 'candidate-email-settings',
    iconDivHidden: true,
    href: '/settings/email/candidate',
    text: 'Candidate Email Settings',
    hoverStyle: true,
    border: false,
    qaDataAttribute: {
      type: 'data-qa-link',
      name: 'candidate-email-settings'
    }
  };
}
function buildUserManagementMenuItem(menuLength) {
  return {
    id: 'manage-users',
    iconDivHidden: true,
    text: 'Manage Users',
    href: `${process.env.WEBUI_WONSCORE_URL}/account/users`,
    hoverStyle: true,
    border: menuLength > 0,
    qaDataAttribute: {
      type: 'data-qa-link',
      name: 'manage-users'
    }
  };
}
function buildNotificationManagementMenuItem() {
  // todo this is just a placeholder, no design yet
  return {
    id: 'manage-notifications',
    iconDivHidden: true,
    text: 'Manage notifications',
    href: `${process.env.WEBUI_WONSCORE_URL}/manage-notifications`,
    // should this be the v2 url?
    hoverStyle: true,
    border: false,
    qaDataAttribute: {
      type: 'data-qa-link',
      name: 'manage-notifications'
    }
  };
}
function getMostRecentlyAccessedAccount(productUser) {
  const unifiedAccounts = productUser.unifiedAccounts;
  const productAccounts = unifiedAccounts[productUser.selectedUnifiedAccountId].productAccounts;
  const productAccountKeys = Object.keys(productAccounts);
  if (productAccountKeys.length > 0) {
    const mostRecentlyAccessedAccount = productAccountKeys.reduce((current, next) => new Date(productAccounts[current].dateLastAccessed) > new Date(productAccounts[next].dateLastAccessed) ? current : next);
    return mostRecentlyAccessedAccount;
  }
  return undefined;
}