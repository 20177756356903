"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "accountCircleIcon", {
  enumerable: true,
  get: function () {
    return _account_circle.default;
  }
});
Object.defineProperty(exports, "addBoxIcon", {
  enumerable: true,
  get: function () {
    return _add_box.default;
  }
});
Object.defineProperty(exports, "addIcon", {
  enumerable: true,
  get: function () {
    return _add.default;
  }
});
Object.defineProperty(exports, "addTackIcon", {
  enumerable: true,
  get: function () {
    return _add_task.default;
  }
});
Object.defineProperty(exports, "amexIcon", {
  enumerable: true,
  get: function () {
    return _amex.default;
  }
});
Object.defineProperty(exports, "analyticsIcon", {
  enumerable: true,
  get: function () {
    return _analytics.default;
  }
});
Object.defineProperty(exports, "archive", {
  enumerable: true,
  get: function () {
    return _archive.default;
  }
});
Object.defineProperty(exports, "arrowBackIcon", {
  enumerable: true,
  get: function () {
    return _arrow_back.default;
  }
});
Object.defineProperty(exports, "arrowDropdown", {
  enumerable: true,
  get: function () {
    return _arrow_dropdown.default;
  }
});
Object.defineProperty(exports, "bookIcon", {
  enumerable: true,
  get: function () {
    return _book.default;
  }
});
Object.defineProperty(exports, "box", {
  enumerable: true,
  get: function () {
    return _box.default;
  }
});
Object.defineProperty(exports, "bricksIcon", {
  enumerable: true,
  get: function () {
    return _bricks_icon.default;
  }
});
Object.defineProperty(exports, "briefcaseIcon", {
  enumerable: true,
  get: function () {
    return _briefcase_icon.default;
  }
});
Object.defineProperty(exports, "buildingIcon", {
  enumerable: true,
  get: function () {
    return _building_icon.default;
  }
});
Object.defineProperty(exports, "bulletedList", {
  enumerable: true,
  get: function () {
    return _bulleted_list.default;
  }
});
Object.defineProperty(exports, "campaignIcon", {
  enumerable: true,
  get: function () {
    return _campaign.default;
  }
});
Object.defineProperty(exports, "candidIcon", {
  enumerable: true,
  get: function () {
    return _candid_icon.default;
  }
});
Object.defineProperty(exports, "caretIcon", {
  enumerable: true,
  get: function () {
    return _caret.default;
  }
});
Object.defineProperty(exports, "chairIcon", {
  enumerable: true,
  get: function () {
    return _chair_icon.default;
  }
});
Object.defineProperty(exports, "chartDonutIcon", {
  enumerable: true,
  get: function () {
    return _chartDonut.default;
  }
});
Object.defineProperty(exports, "checkCircle", {
  enumerable: true,
  get: function () {
    return _check_circle.default;
  }
});
Object.defineProperty(exports, "checkComplete", {
  enumerable: true,
  get: function () {
    return _check_complete.default;
  }
});
Object.defineProperty(exports, "checkmarkFilledIcon", {
  enumerable: true,
  get: function () {
    return _check_filled.default;
  }
});
Object.defineProperty(exports, "checkmarkIcon", {
  enumerable: true,
  get: function () {
    return _checkmark.default;
  }
});
Object.defineProperty(exports, "chevronRight", {
  enumerable: true,
  get: function () {
    return _chevron_right.default;
  }
});
Object.defineProperty(exports, "chevronRightRoundedIcon", {
  enumerable: true,
  get: function () {
    return _chevron_right_rounded.default;
  }
});
Object.defineProperty(exports, "circleComplete", {
  enumerable: true,
  get: function () {
    return _circle_complete.default;
  }
});
Object.defineProperty(exports, "circleNext", {
  enumerable: true,
  get: function () {
    return _circle_next.default;
  }
});
Object.defineProperty(exports, "circleUpcoming", {
  enumerable: true,
  get: function () {
    return _circle_upcoming.default;
  }
});
Object.defineProperty(exports, "closeIcon", {
  enumerable: true,
  get: function () {
    return _close.default;
  }
});
Object.defineProperty(exports, "collectionsBookmark", {
  enumerable: true,
  get: function () {
    return _collections_bookmark.default;
  }
});
Object.defineProperty(exports, "committed", {
  enumerable: true,
  get: function () {
    return _filter_center_focus.default;
  }
});
Object.defineProperty(exports, "compass", {
  enumerable: true,
  get: function () {
    return _compass.default;
  }
});
Object.defineProperty(exports, "completed", {
  enumerable: true,
  get: function () {
    return _completed.default;
  }
});
Object.defineProperty(exports, "dashboardIcon", {
  enumerable: true,
  get: function () {
    return _dashboard.default;
  }
});
Object.defineProperty(exports, "deleteIcon", {
  enumerable: true,
  get: function () {
    return _delete.default;
  }
});
Object.defineProperty(exports, "dinersClubIcon", {
  enumerable: true,
  get: function () {
    return _diners_club.default;
  }
});
Object.defineProperty(exports, "downloadFileIcon", {
  enumerable: true,
  get: function () {
    return _file_download.default;
  }
});
Object.defineProperty(exports, "downloadIcon", {
  enumerable: true,
  get: function () {
    return _cloud_download.default;
  }
});
Object.defineProperty(exports, "dropdownArrowIcon", {
  enumerable: true,
  get: function () {
    return _dropdownArrow.default;
  }
});
Object.defineProperty(exports, "dvpDashboardIcon", {
  enumerable: true,
  get: function () {
    return _dvpDashboard.default;
  }
});
Object.defineProperty(exports, "dvpUserManagementIcon", {
  enumerable: true,
  get: function () {
    return _dvpUsermanagement.default;
  }
});
Object.defineProperty(exports, "editIcon", {
  enumerable: true,
  get: function () {
    return _edit.default;
  }
});
Object.defineProperty(exports, "emailIcon", {
  enumerable: true,
  get: function () {
    return _email_outline.default;
  }
});
Object.defineProperty(exports, "envelopeIcon", {
  enumerable: true,
  get: function () {
    return _envelope.default;
  }
});
Object.defineProperty(exports, "errorFilledIcon", {
  enumerable: true,
  get: function () {
    return _error_rounded.default;
  }
});
Object.defineProperty(exports, "errorIcon", {
  enumerable: true,
  get: function () {
    return _error.default;
  }
});
Object.defineProperty(exports, "errorOutlineIcon", {
  enumerable: true,
  get: function () {
    return _error_outline.default;
  }
});
Object.defineProperty(exports, "exclamationIcon", {
  enumerable: true,
  get: function () {
    return _exclamation.default;
  }
});
Object.defineProperty(exports, "exitIcon", {
  enumerable: true,
  get: function () {
    return _exit.default;
  }
});
Object.defineProperty(exports, "faceBookIconBordered", {
  enumerable: true,
  get: function () {
    return _facebook_icon.default;
  }
});
Object.defineProperty(exports, "faceBookIconBorderedMobile", {
  enumerable: true,
  get: function () {
    return _facebook_icon_mobile.default;
  }
});
Object.defineProperty(exports, "facebookIcon", {
  enumerable: true,
  get: function () {
    return _facebook.default;
  }
});
Object.defineProperty(exports, "factCheckIcon", {
  enumerable: true,
  get: function () {
    return _fact_check.default;
  }
});
Object.defineProperty(exports, "filterListIcon", {
  enumerable: true,
  get: function () {
    return _filter_list.default;
  }
});
Object.defineProperty(exports, "folderSharedIcon", {
  enumerable: true,
  get: function () {
    return _folder_shared.default;
  }
});
Object.defineProperty(exports, "gearIcon", {
  enumerable: true,
  get: function () {
    return _gear.default;
  }
});
Object.defineProperty(exports, "genericCardIcon", {
  enumerable: true,
  get: function () {
    return _material_credit_card.default;
  }
});
Object.defineProperty(exports, "graphIcon", {
  enumerable: true,
  get: function () {
    return _graph_icon.default;
  }
});
Object.defineProperty(exports, "gridViewIcon", {
  enumerable: true,
  get: function () {
    return _grid_view.default;
  }
});
Object.defineProperty(exports, "groupIcon", {
  enumerable: true,
  get: function () {
    return _groups.default;
  }
});
Object.defineProperty(exports, "hamburgerIcon", {
  enumerable: true,
  get: function () {
    return _hamburger_menu.default;
  }
});
Object.defineProperty(exports, "handshakeIcon", {
  enumerable: true,
  get: function () {
    return _handshake_icon.default;
  }
});
Object.defineProperty(exports, "helpIcon", {
  enumerable: true,
  get: function () {
    return _help.default;
  }
});
Object.defineProperty(exports, "helpfulIcon", {
  enumerable: true,
  get: function () {
    return _helpful_icon.default;
  }
});
Object.defineProperty(exports, "homeIcon", {
  enumerable: true,
  get: function () {
    return _home.default;
  }
});
Object.defineProperty(exports, "horizontalLineIcon", {
  enumerable: true,
  get: function () {
    return _horizontal_line_rounded.default;
  }
});
Object.defineProperty(exports, "howToGuide", {
  enumerable: true,
  get: function () {
    return _how_to_guide.default;
  }
});
Object.defineProperty(exports, "inProgress", {
  enumerable: true,
  get: function () {
    return _inProgress.default;
  }
});
Object.defineProperty(exports, "infoIcon", {
  enumerable: true,
  get: function () {
    return _info.default;
  }
});
Object.defineProperty(exports, "infoRoundedIcon", {
  enumerable: true,
  get: function () {
    return _info_rounded.default;
  }
});
Object.defineProperty(exports, "inviteIcon", {
  enumerable: true,
  get: function () {
    return _invite.default;
  }
});
Object.defineProperty(exports, "jcbIcon", {
  enumerable: true,
  get: function () {
    return _jcb.default;
  }
});
Object.defineProperty(exports, "kebabMenu", {
  enumerable: true,
  get: function () {
    return _kebabMenu.default;
  }
});
Object.defineProperty(exports, "knowledgeBase", {
  enumerable: true,
  get: function () {
    return _knowledge_base.default;
  }
});
Object.defineProperty(exports, "landscapeIcon", {
  enumerable: true,
  get: function () {
    return _landscape.default;
  }
});
Object.defineProperty(exports, "layers", {
  enumerable: true,
  get: function () {
    return _layers.default;
  }
});
Object.defineProperty(exports, "leadershipIcon", {
  enumerable: true,
  get: function () {
    return _leadership_icon.default;
  }
});
Object.defineProperty(exports, "libraryAddCheckIcon", {
  enumerable: true,
  get: function () {
    return _libraryAddCheck.default;
  }
});
Object.defineProperty(exports, "lightningIcon", {
  enumerable: true,
  get: function () {
    return _lightning_icon.default;
  }
});
Object.defineProperty(exports, "linkIcon", {
  enumerable: true,
  get: function () {
    return _link.default;
  }
});
Object.defineProperty(exports, "linkedInIcon", {
  enumerable: true,
  get: function () {
    return _linkedin.default;
  }
});
Object.defineProperty(exports, "linkedInIconBordered", {
  enumerable: true,
  get: function () {
    return _linked_in_icon.default;
  }
});
Object.defineProperty(exports, "linkedInIconBorderedMobile", {
  enumerable: true,
  get: function () {
    return _linked_in_icon_mobile.default;
  }
});
Object.defineProperty(exports, "logoutIcon", {
  enumerable: true,
  get: function () {
    return _logout.default;
  }
});
Object.defineProperty(exports, "magnifyingGlassIcon", {
  enumerable: true,
  get: function () {
    return _magnifying_glass_icon.default;
  }
});
Object.defineProperty(exports, "manageAccountIcon", {
  enumerable: true,
  get: function () {
    return _manage_accounts.default;
  }
});
Object.defineProperty(exports, "masterCardIcon", {
  enumerable: true,
  get: function () {
    return _mastercard.default;
  }
});
Object.defineProperty(exports, "openInNewIcon", {
  enumerable: true,
  get: function () {
    return _open_in_new.default;
  }
});
Object.defineProperty(exports, "openMindedIcon", {
  enumerable: true,
  get: function () {
    return _open_mind_icon.default;
  }
});
Object.defineProperty(exports, "outgoingIcon", {
  enumerable: true,
  get: function () {
    return _outgoing_icon.default;
  }
});
Object.defineProperty(exports, "paintingIcon", {
  enumerable: true,
  get: function () {
    return _painting_icon.default;
  }
});
Object.defineProperty(exports, "penAndPaperIcon", {
  enumerable: true,
  get: function () {
    return _pen_and_paper_icon.default;
  }
});
Object.defineProperty(exports, "peopleFilledIcon", {
  enumerable: true,
  get: function () {
    return _people2.default;
  }
});
Object.defineProperty(exports, "peopleIcon", {
  enumerable: true,
  get: function () {
    return _people.default;
  }
});
Object.defineProperty(exports, "personAddIcon", {
  enumerable: true,
  get: function () {
    return _personAdd.default;
  }
});
Object.defineProperty(exports, "personFilledIcon", {
  enumerable: true,
  get: function () {
    return _personFilled.default;
  }
});
Object.defineProperty(exports, "personGearIcon", {
  enumerable: true,
  get: function () {
    return _person_gear.default;
  }
});
Object.defineProperty(exports, "phoneIcon", {
  enumerable: true,
  get: function () {
    return _phone.default;
  }
});
Object.defineProperty(exports, "profileIcon", {
  enumerable: true,
  get: function () {
    return _person24px.default;
  }
});
Object.defineProperty(exports, "profileOutlineIcon", {
  enumerable: true,
  get: function () {
    return _person_outline.default;
  }
});
Object.defineProperty(exports, "progressCheckmark", {
  enumerable: true,
  get: function () {
    return _progressCheckmark.default;
  }
});
Object.defineProperty(exports, "puzzleIcon", {
  enumerable: true,
  get: function () {
    return _puzzle_icon.default;
  }
});
Object.defineProperty(exports, "questionMark", {
  enumerable: true,
  get: function () {
    return _question_mark.default;
  }
});
Object.defineProperty(exports, "redo", {
  enumerable: true,
  get: function () {
    return _redo.default;
  }
});
Object.defineProperty(exports, "reservedIcon", {
  enumerable: true,
  get: function () {
    return _reserved_icon.default;
  }
});
Object.defineProperty(exports, "roleSuccessIcon", {
  enumerable: true,
  get: function () {
    return _workspace_premium_rounded.default;
  }
});
Object.defineProperty(exports, "roundedArrowIcon", {
  enumerable: true,
  get: function () {
    return _arrow_rounded.default;
  }
});
Object.defineProperty(exports, "roundedCloseIcon", {
  enumerable: true,
  get: function () {
    return _close_rounded.default;
  }
});
Object.defineProperty(exports, "scalesIcon", {
  enumerable: true,
  get: function () {
    return _scales_icon.default;
  }
});
Object.defineProperty(exports, "searchGlassIcon", {
  enumerable: true,
  get: function () {
    return _search.default;
  }
});
Object.defineProperty(exports, "sendFeedback", {
  enumerable: true,
  get: function () {
    return _send_feedback.default;
  }
});
Object.defineProperty(exports, "sendIcon", {
  enumerable: true,
  get: function () {
    return _send.default;
  }
});
Object.defineProperty(exports, "sendRoundedIcon", {
  enumerable: true,
  get: function () {
    return _send2.default;
  }
});
Object.defineProperty(exports, "settingsIcon", {
  enumerable: true,
  get: function () {
    return _settings.default;
  }
});
Object.defineProperty(exports, "shareIcon", {
  enumerable: true,
  get: function () {
    return _share_rounded.default;
  }
});
Object.defineProperty(exports, "silhouetteIcon", {
  enumerable: true,
  get: function () {
    return _silhouette_icon.default;
  }
});
Object.defineProperty(exports, "sortFilledIcon", {
  enumerable: true,
  get: function () {
    return _sort.default;
  }
});
Object.defineProperty(exports, "sortIcon", {
  enumerable: true,
  get: function () {
    return _arrow_sort.default;
  }
});
Object.defineProperty(exports, "spaceshipIcon", {
  enumerable: true,
  get: function () {
    return _spaceship_icon.default;
  }
});
Object.defineProperty(exports, "starIcon", {
  enumerable: true,
  get: function () {
    return _star.default;
  }
});
Object.defineProperty(exports, "taskAlt", {
  enumerable: true,
  get: function () {
    return _task_alt.default;
  }
});
Object.defineProperty(exports, "team", {
  enumerable: true,
  get: function () {
    return _team.default;
  }
});
Object.defineProperty(exports, "teamAdmin", {
  enumerable: true,
  get: function () {
    return _admin_team.default;
  }
});
Object.defineProperty(exports, "teamworkIcon", {
  enumerable: true,
  get: function () {
    return _teamwork_icon.default;
  }
});
Object.defineProperty(exports, "testNext", {
  enumerable: true,
  get: function () {
    return _test_next.default;
  }
});
Object.defineProperty(exports, "testUpcoming", {
  enumerable: true,
  get: function () {
    return _test_upcoming.default;
  }
});
Object.defineProperty(exports, "thumbIcon", {
  enumerable: true,
  get: function () {
    return _thumb_icon.default;
  }
});
Object.defineProperty(exports, "trashIcon", {
  enumerable: true,
  get: function () {
    return _trash.default;
  }
});
Object.defineProperty(exports, "twitterIcon", {
  enumerable: true,
  get: function () {
    return _twitter.default;
  }
});
Object.defineProperty(exports, "twitterIconBordered", {
  enumerable: true,
  get: function () {
    return _twitter_icon.default;
  }
});
Object.defineProperty(exports, "twitterIconBorderedMobile", {
  enumerable: true,
  get: function () {
    return _twitter_icon_mobile.default;
  }
});
Object.defineProperty(exports, "unarchive", {
  enumerable: true,
  get: function () {
    return _unarchive.default;
  }
});
Object.defineProperty(exports, "undoIcon", {
  enumerable: true,
  get: function () {
    return _undo.default;
  }
});
Object.defineProperty(exports, "unionPayIcon", {
  enumerable: true,
  get: function () {
    return _union_pay.default;
  }
});
Object.defineProperty(exports, "uploadFileIcon", {
  enumerable: true,
  get: function () {
    return _uploadFile.default;
  }
});
Object.defineProperty(exports, "uploadIcon", {
  enumerable: true,
  get: function () {
    return _file_upload.default;
  }
});
Object.defineProperty(exports, "visaIcon", {
  enumerable: true,
  get: function () {
    return _visa.default;
  }
});
Object.defineProperty(exports, "warningIcon", {
  enumerable: true,
  get: function () {
    return _warning.default;
  }
});
Object.defineProperty(exports, "whatsNew", {
  enumerable: true,
  get: function () {
    return _whats_new.default;
  }
});
Object.defineProperty(exports, "wonderlicDevelopLogo", {
  enumerable: true,
  get: function () {
    return _wonderlic_develop_logo.default;
  }
});
Object.defineProperty(exports, "wonderlicLettermark", {
  enumerable: true,
  get: function () {
    return _wonderlic_lettermark.default;
  }
});
Object.defineProperty(exports, "wonderlicLogo", {
  enumerable: true,
  get: function () {
    return _wonderlic_logo.default;
  }
});
Object.defineProperty(exports, "wonderlicLogoLight", {
  enumerable: true,
  get: function () {
    return _wonderlic_logo_light.default;
  }
});
Object.defineProperty(exports, "wonderlicLogoLightV1", {
  enumerable: true,
  get: function () {
    return _wonderlic_logo_light_v.default;
  }
});
Object.defineProperty(exports, "wonderlicSelectLogo", {
  enumerable: true,
  get: function () {
    return _wonderlic_select_logo.default;
  }
});
Object.defineProperty(exports, "wonderlicSelectLogoLight", {
  enumerable: true,
  get: function () {
    return _wonderlic_select_logo_light.default;
  }
});
Object.defineProperty(exports, "wonscoreClientLogo", {
  enumerable: true,
  get: function () {
    return _wonscore_client_logo.default;
  }
});
Object.defineProperty(exports, "wonscoreHeaderLogo", {
  enumerable: true,
  get: function () {
    return _wonscore_header_logo.default;
  }
});
Object.defineProperty(exports, "wonscoreHeaderLogoMobile", {
  enumerable: true,
  get: function () {
    return _header_logo_mobile.default;
  }
});
Object.defineProperty(exports, "wonscoreLogo", {
  enumerable: true,
  get: function () {
    return _wonscore_logo.default;
  }
});
Object.defineProperty(exports, "workIcon", {
  enumerable: true,
  get: function () {
    return _work.default;
  }
});
Object.defineProperty(exports, "wrenchIcon", {
  enumerable: true,
  get: function () {
    return _wrench_icon.default;
  }
});
var _account_circle = _interopRequireDefault(require("../../public/icons/fonticon/account_circle.svg"));
var _analytics = _interopRequireDefault(require("../../public/icons/fonticon/analytics.svg"));
var _arrow_back = _interopRequireDefault(require("../../public/icons/fonticon/arrow_back.svg"));
var _arrow_dropdown = _interopRequireDefault(require("../../public/icons/fonticon/arrow_dropdown.svg"));
var _box = _interopRequireDefault(require("../../public/icons/box.svg"));
var _caret = _interopRequireDefault(require("../../public/icons/fonticon/caret.svg"));
var _campaign = _interopRequireDefault(require("../../public/icons/fonticon/campaign.svg"));
var _check_circle = _interopRequireDefault(require("../../public/icons/fonticon/check_circle.svg"));
var _chevron_right = _interopRequireDefault(require("../../public/icons/fonticon/chevron_right.svg"));
var _close = _interopRequireDefault(require("../../public/icons/fonticon/close.svg"));
var _collections_bookmark = _interopRequireDefault(require("../../public/icons/fonticon/collections_bookmark.svg"));
var _compass = _interopRequireDefault(require("../../public/icons/fonticon/compass.svg"));
var _dashboard = _interopRequireDefault(require("../../public/icons/fonticon/dashboard.svg"));
var _cloud_download = _interopRequireDefault(require("../../public/icons/fonticon/cloud_download.svg"));
var _edit = _interopRequireDefault(require("../../public/icons/fonticon/edit.svg"));
var _envelope = _interopRequireDefault(require("../../public/icons/fonticon/envelope.svg"));
var _error = _interopRequireDefault(require("../../public/icons/fonticon/error.svg"));
var _exit = _interopRequireDefault(require("../../public/icons/exit.svg"));
var _folder_shared = _interopRequireDefault(require("../../public/icons/fonticon/folder_shared.svg"));
var _gear = _interopRequireDefault(require("../../public/icons/fonticon/gear.svg"));
var _hamburger_menu = _interopRequireDefault(require("../../public/icons/fonticon/hamburger_menu.svg"));
var _info = _interopRequireDefault(require("../../public/icons/fonticon/info.svg"));
var _invite = _interopRequireDefault(require("../../public/icons/fonticon/invite.svg"));
var _layers = _interopRequireDefault(require("../../public/icons/layers.svg"));
var _link = _interopRequireDefault(require("../../public/icons/fonticon/link.svg"));
var _logout = _interopRequireDefault(require("../../public/icons/fonticon/logout.svg"));
var _open_in_new = _interopRequireDefault(require("../../public/icons/fonticon/open_in_new.svg"));
var _kebabMenu = _interopRequireDefault(require("../../public/icons/fonticon/kebabMenu.svg"));
var _people = _interopRequireDefault(require("../../public/icons/fonticon/people.svg"));
var _person_gear = _interopRequireDefault(require("../../public/icons/fonticon/person_gear.svg"));
var _phone = _interopRequireDefault(require("../../public/icons/fonticon/phone.svg"));
var _person24px = _interopRequireDefault(require("../../public/icons/fonticon/person-24px.svg"));
var _person_outline = _interopRequireDefault(require("../../public/icons/fonticon/person_outline.svg"));
var _progressCheckmark = _interopRequireDefault(require("../../public/icons/progressCheckmark.svg"));
var _question_mark = _interopRequireDefault(require("../../public/icons/fonticon/question_mark.svg"));
var _redo = _interopRequireDefault(require("../../public/icons/fonticon/redo.svg"));
var _search = _interopRequireDefault(require("../../public/icons/fonticon/search.svg"));
var _send = _interopRequireDefault(require("../../public/icons/fonticon/send.svg"));
var _send_feedback = _interopRequireDefault(require("../../public/icons/fonticon/send_feedback.svg"));
var _trash = _interopRequireDefault(require("../../public/icons/fonticon/trash.svg"));
var _warning = _interopRequireDefault(require("../../public/icons/fonticon/warning.svg"));
var _whats_new = _interopRequireDefault(require("../../public/icons/fonticon/whats_new.svg"));
var _how_to_guide = _interopRequireDefault(require("../../public/icons/how_to_guide.svg"));
var _knowledge_base = _interopRequireDefault(require("../../public/icons/knowledge_base.svg"));
var _wonderlic_logo_light = _interopRequireDefault(require("../../public/icons/wonderlic_logo_light.svg"));
var _wonderlic_logo_light_v = _interopRequireDefault(require("../../public/icons/wonderlic_logo_light_v1.svg"));
var _wonderlic_logo = _interopRequireDefault(require("../../public/icons/wonderlic_logo.svg"));
var _wonscore_logo = _interopRequireDefault(require("../../public/icons/wonscore_logo.svg"));
var _wonscore_header_logo = _interopRequireDefault(require("../../public/icons/wonscore_header_logo.svg"));
var _header_logo_mobile = _interopRequireDefault(require("../../public/icons/header_logo_mobile.svg"));
var _wonscore_client_logo = _interopRequireDefault(require("../../public/icons/wonscore_client_logo.svg"));
var _wonderlic_lettermark = _interopRequireDefault(require("../../public/icons/wonderlic_lettermark.svg"));
var _wonderlic_develop_logo = _interopRequireDefault(require("../../public/icons/wonderlic_develop_logo.svg"));
var _wonderlic_select_logo = _interopRequireDefault(require("../../public/icons/wonderlic_select_logo.svg"));
var _wonderlic_select_logo_light = _interopRequireDefault(require("../../public/icons/wonderlic_select_logo_light.svg"));
var _facebook = _interopRequireDefault(require("../../public/icons/facebook.svg"));
var _facebook_icon = _interopRequireDefault(require("../../public/icons/fonticon/facebook_icon.svg"));
var _facebook_icon_mobile = _interopRequireDefault(require("../../public/icons/fonticon/facebook_icon_mobile.svg"));
var _linkedin = _interopRequireDefault(require("../../public/icons/linkedin.svg"));
var _linked_in_icon = _interopRequireDefault(require("../../public/icons/fonticon/linked_in_icon.svg"));
var _linked_in_icon_mobile = _interopRequireDefault(require("../../public/icons/fonticon/linked_in_icon_mobile.svg"));
var _twitter_icon = _interopRequireDefault(require("../../public/icons/fonticon/twitter_icon.svg"));
var _twitter_icon_mobile = _interopRequireDefault(require("../../public/icons/fonticon/twitter_icon_mobile.svg"));
var _twitter = _interopRequireDefault(require("../../public/icons/twitter.svg"));
var _arrow_sort = _interopRequireDefault(require("../../public/icons/arrow_sort.svg"));
var _bricks_icon = _interopRequireDefault(require("../../public/icons/bricks_icon.svg"));
var _briefcase_icon = _interopRequireDefault(require("../../public/icons/briefcase_icon.svg"));
var _building_icon = _interopRequireDefault(require("../../public/icons/building_icon.svg"));
var _candid_icon = _interopRequireDefault(require("../../public/icons/candid_icon.svg"));
var _chair_icon = _interopRequireDefault(require("../../public/icons/chair_icon.svg"));
var _graph_icon = _interopRequireDefault(require("../../public/icons/graph_icon.svg"));
var _handshake_icon = _interopRequireDefault(require("../../public/icons/handshake_icon.svg"));
var _helpful_icon = _interopRequireDefault(require("../../public/icons/helpful_icon.svg"));
var _leadership_icon = _interopRequireDefault(require("../../public/icons/leadership_icon.svg"));
var _lightning_icon = _interopRequireDefault(require("../../public/icons/lightning_icon.svg"));
var _magnifying_glass_icon = _interopRequireDefault(require("../../public/icons/magnifying_glass_icon.svg"));
var _open_mind_icon = _interopRequireDefault(require("../../public/icons/open_mind_icon.svg"));
var _outgoing_icon = _interopRequireDefault(require("../../public/icons/outgoing_icon.svg"));
var _painting_icon = _interopRequireDefault(require("../../public/icons/painting_icon.svg"));
var _pen_and_paper_icon = _interopRequireDefault(require("../../public/icons/pen_and_paper_icon.svg"));
var _puzzle_icon = _interopRequireDefault(require("../../public/icons/puzzle_icon.svg"));
var _reserved_icon = _interopRequireDefault(require("../../public/icons/reserved_icon.svg"));
var _scales_icon = _interopRequireDefault(require("../../public/icons/scales_icon.svg"));
var _silhouette_icon = _interopRequireDefault(require("../../public/icons/silhouette_icon.svg"));
var _spaceship_icon = _interopRequireDefault(require("../../public/icons/spaceship_icon.svg"));
var _teamwork_icon = _interopRequireDefault(require("../../public/icons/teamwork_icon.svg"));
var _thumb_icon = _interopRequireDefault(require("../../public/icons/thumb_icon.svg"));
var _wrench_icon = _interopRequireDefault(require("../../public/icons/wrench_icon.svg"));
var _check_complete = _interopRequireDefault(require("../../public/icons/check_complete.svg"));
var _circle_complete = _interopRequireDefault(require("../../public/icons/circle_complete.svg"));
var _circle_next = _interopRequireDefault(require("../../public/icons/circle_next.svg"));
var _test_next = _interopRequireDefault(require("../../public/icons/test_next.svg"));
var _circle_upcoming = _interopRequireDefault(require("../../public/icons/circle_upcoming.svg"));
var _test_upcoming = _interopRequireDefault(require("../../public/icons/test_upcoming.svg"));
var _dvpDashboard = _interopRequireDefault(require("../../public/icons/dvp-dashboard.svg"));
var _dvpUsermanagement = _interopRequireDefault(require("../../public/icons/dvp-usermanagement.svg"));
var _chartDonut = _interopRequireDefault(require("../../public/icons/chart-donut.svg"));
var _book = _interopRequireDefault(require("../../public/icons/book.svg"));
var _libraryAddCheck = _interopRequireDefault(require("../../public/icons/library-add-check.svg"));
var _home = _interopRequireDefault(require("../../public/icons/home.svg"));
var _fact_check = _interopRequireDefault(require("../../public/icons/fonticon/fact_check.svg"));
var _team = _interopRequireDefault(require("../../public/icons/team.svg"));
var _admin_team = _interopRequireDefault(require("../../public/icons/admin_team.svg"));
var _amex = _interopRequireDefault(require("../../public/icons/amex.svg"));
var _diners_club = _interopRequireDefault(require("../../public/icons/diners_club.svg"));
var _material_credit_card = _interopRequireDefault(require("../../public/icons/material_credit_card.svg"));
var _jcb = _interopRequireDefault(require("../../public/icons/jcb.svg"));
var _mastercard = _interopRequireDefault(require("../../public/icons/mastercard.svg"));
var _union_pay = _interopRequireDefault(require("../../public/icons/union_pay.svg"));
var _visa = _interopRequireDefault(require("../../public/icons/visa.svg"));
var _add = _interopRequireDefault(require("../../public/icons/add.svg"));
var _add_task = _interopRequireDefault(require("../../public/icons/add_task.svg"));
var _bulleted_list = _interopRequireDefault(require("../../public/icons/bulleted_list.svg"));
var _add_box = _interopRequireDefault(require("../../public/icons/add_box.svg"));
var _checkmark = _interopRequireDefault(require("../../public/icons/checkmark.svg"));
var _check_filled = _interopRequireDefault(require("../../public/icons/check_filled.svg"));
var _chevron_right_rounded = _interopRequireDefault(require("../../public/icons/chevron_right_rounded.svg"));
var _delete = _interopRequireDefault(require("../../public/icons/delete.svg"));
var _file_download = _interopRequireDefault(require("../../public/icons/file_download.svg"));
var _dropdownArrow = _interopRequireDefault(require("../../public/icons/dropdownArrow.svg"));
var _email_outline = _interopRequireDefault(require("../../public/icons/email_outline.svg"));
var _error_outline = _interopRequireDefault(require("../../public/icons/error_outline.svg"));
var _error_rounded = _interopRequireDefault(require("../../public/icons/error_rounded.svg"));
var _exclamation = _interopRequireDefault(require("../../public/icons/exclamation.svg"));
var _filter_list = _interopRequireDefault(require("../../public/icons/filter_list.svg"));
var _grid_view = _interopRequireDefault(require("../../public/icons/grid_view.svg"));
var _groups = _interopRequireDefault(require("../../public/icons/groups.svg"));
var _help = _interopRequireDefault(require("../../public/icons/help.svg"));
var _horizontal_line_rounded = _interopRequireDefault(require("../../public/icons/horizontal_line_rounded.svg"));
var _info_rounded = _interopRequireDefault(require("../../public/icons/info_rounded.svg"));
var _landscape = _interopRequireDefault(require("../../public/icons/landscape.svg"));
var _manage_accounts = _interopRequireDefault(require("../../public/icons/manage_accounts.svg"));
var _people2 = _interopRequireDefault(require("../../public/icons/people.svg"));
var _personAdd = _interopRequireDefault(require("../../public/icons/personAdd.svg"));
var _personFilled = _interopRequireDefault(require("../../public/icons/personFilled.svg"));
var _workspace_premium_rounded = _interopRequireDefault(require("../../public/icons/workspace_premium_rounded.svg"));
var _arrow_rounded = _interopRequireDefault(require("../../public/icons/arrow_rounded.svg"));
var _close_rounded = _interopRequireDefault(require("../../public/icons/close_rounded.svg"));
var _send2 = _interopRequireDefault(require("../../public/icons/send.svg"));
var _settings = _interopRequireDefault(require("../../public/icons/settings.svg"));
var _share_rounded = _interopRequireDefault(require("../../public/icons/share_rounded.svg"));
var _sort = _interopRequireDefault(require("../../public/icons/sort.svg"));
var _star = _interopRequireDefault(require("../../public/icons/star.svg"));
var _task_alt = _interopRequireDefault(require("../../public/icons/task_alt.svg"));
var _undo = _interopRequireDefault(require("../../public/icons/undo.svg"));
var _file_upload = _interopRequireDefault(require("../../public/icons/file_upload.svg"));
var _work = _interopRequireDefault(require("../../public/icons/work.svg"));
var _inProgress = _interopRequireDefault(require("../../public/icons/inProgress.svg"));
var _completed = _interopRequireDefault(require("../../public/icons/completed.svg"));
var _archive = _interopRequireDefault(require("../../public/icons/archive.svg"));
var _unarchive = _interopRequireDefault(require("../../public/icons/unarchive.svg"));
var _filter_center_focus = _interopRequireDefault(require("../../public/icons/filter_center_focus.svg"));
var _uploadFile = _interopRequireDefault(require("../../public/icons/uploadFile.svg"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }