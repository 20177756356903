import * as React from 'react';
import {vr4, vr6, vr8} from '>styles/mixins/verticalRhythm';
import {HeaderSection, PageHeading, TabsContainer} from './styles';
import {useSelector} from 'react-redux';
import {dvpApi} from '>root/apis';
import {assertAccountExists} from '>lib/assert';
import {redirectToErrorPage} from '>lib/redirect';
import {Redirect, RouteChildrenProps} from 'react-router-dom';
import {ContextTabs, TabType} from '>shared/components/containers/contextTabs';
import {MinimalDropdown} from '>shared/components/minimalDropdown/minimalDropdown';
import {OverviewTab} from './overviewTab';
import {TeamReportTab} from './teamReportTab';
import {Select} from '>shared/components/form/select';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {ValueType} from 'react-select';
import styled from '@emotion/styled';
import {mediaQueries, respondTo} from '>shared/styles/breakpoints';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {MobileSectionHeader} from '>shared/components/landmarks/header/mobileSectionHeader';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {PageLayoutComponents} from '>shared/components/layouts/responsive/responsiveLayout';
import {TeamReport, TeamReportState} from 'wnd-dvp-reports';
import {rollbarLogger} from '>lib/logger';
import {HttpError} from 'wnd-util';
import {IconButton, IconButtonType} from '>shared/components/iconButton/iconButton';
import {exitIcon, kebabMenu} from '>shared/components/icon/icons';
import {Drawer} from '>shared/components/drawer/drawer';
import {white} from 'wnd-themes/lib/colorPalette';
import {getLoggedInUserId, isCoachWithTeamAccess, useAccountUser} from '>lib/user';
import {Team as TeamType} from '>generated/dvp.types';
import {Team} from '>components/teams/team';
import {LoadingScreen} from '>shared/components/loadingScreen';
import {RoutePath} from '>root/routes/routes';

const StyledHeaderSection = styled(HeaderSection)`
  display: flex;
  align-items: center;

  ${respondTo.smallOrLower} {
    flex-direction: column-reverse;
    align-items: start;
  }
`;

const StyledSelect = styled(Select)`
  width: 15rem;
  margin-right: 1.6rem;

  ${respondTo.smallOrLower} {
    width: 100%;
    ${vr8}
  }
`;

const StyledPageHeading = styled(PageHeading)`
  ${respondTo.smallOrLower} {
    ${vr4}
  }
`;

const TeamContainer = styled.div`
  padding: 0 1.6rem;
`;

const ExitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.2rem 1.4rem 0 0;
  ${vr4}
`;

const StyledIconButton = styled(IconButton)`
  width: 2.4rem;
  height: 2.4rem;
`;

export const TeamInsights: React.FC<RouteChildrenProps> = ({match, history, location}) => {
  const {teams: employeeTeams} = useSelector((state) => state.employee);
  const account = useSelector((state) => state.account.account);
  const [selectedTeamIndex, setSelectedTeamIndex] = React.useState(0);
  const {setHiddenComponent} = useResponsiveLayout();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [teams, setTeams] = React.useState<TeamType[]>(employeeTeams || []);
  const [teamReport, setTeamReport] = React.useState<TeamReport | undefined>();
  const [teamReportState, setTeamReportState] = React.useState<TeamReportState>(
    TeamReportState.FETCHING
  );
  const currentUser = useAccountUser();
  assertAccountExists(account);
  const loggedInUserId = getLoggedInUserId();

  React.useEffect(() => {
    if (teams.length > 0 || !currentUser) return;

    const queryParams = new URLSearchParams(location.search);
    const teamId = queryParams.get('teamId');

    const fetchTeams = isCoachWithTeamAccess(currentUser)
      ? dvpApi.getCoachTeams({accountId: account.id, authUserId: loggedInUserId})
      : dvpApi.getAccountTeams({accountId: account.id});

    fetchTeams.then((teamsResponse) => {
      const teamToView = teamsResponse.data.filter((apiTeam: TeamType) => apiTeam.id === teamId);

      if (teamToView.length) {
        setTeams(teamToView);
      } else {
        history.push(RoutePath.AdminTeamManagement);
      }
    });
  }, []);

  if (!account.enableTeams) {
    return <Redirect to="/" />;
  }

  const isMobile = window.matchMedia(mediaQueries.smallOrLower.media).matches;

  React.useEffect(() => {
    if (isMobile) {
      setHiddenComponent(PageLayoutComponents.Nav);
    }
  }, [isMobile]);

  const onTeamChange = React.useCallback((value: ValueType<Option, false>) => {
    if (value) {
      setSelectedTeamIndex(parseInt(value.value));
    }
  }, []);

  const dropdownOptions: (MenuLinkItem & {label: string; value: string})[] = teams.map(
    (team, index) => ({
      id: index.toString(),
      label: team.name,
      value: index.toString(),
      text: team.name,
      action: () => {
        return setSelectedTeamIndex(index);
      },
      currentlyUsing: index === selectedTeamIndex,
      iconDivHidden: true,
    })
  );

  const selectedTeam = React.useMemo(() => teams[selectedTeamIndex], [teams, selectedTeamIndex]);

  React.useEffect(() => {
    if (selectedTeam) {
      dvpApi
        .getTeamReport({teamId: selectedTeam.id})
        .then((response) => {
          setTeamReport(response.data as TeamReport);
          setTeamReportState(TeamReportState.READY);
        })
        .catch((err) => {
          const error = err as HttpError;
          if (error.status === 400) {
            setTeamReportState(TeamReportState.MEMBER_NOT_FINISHED);
          } else {
            rollbarLogger.error(err);
            redirectToErrorPage(err);
          }
        });
    }
  }, [selectedTeam]);

  if (teams.length === 0 || !selectedTeam) {
    return <LoadingScreen />;
  }

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <StyledHeaderSection css={[vr6]}>
        {isMobile ? (
          <MobileSectionHeader
            primaryHeader={false}
            showBackButton={false}
            zIndex="3"
            sectionHeading={
              teams.length > 1 ? (
                <MinimalDropdown
                  closeOnSelect
                  buttonText={selectedTeam.name}
                  dropdownMenuItems={dropdownOptions}
                  qaAttribute="team-selection-dropdown"
                />
              ) : (
                selectedTeam.name
              )
            }
            menuButton={
              <IconButton
                buttonLabel="open-drawer-with-team-members"
                icon={kebabMenu}
                onClick={toggleDrawer}
              />
            }
          />
        ) : (
          teams.length > 1 && (
            <StyledSelect
              value={dropdownOptions[selectedTeamIndex]}
              options={dropdownOptions}
              onSelectChange={onTeamChange}
            />
          )
        )}

        <StyledPageHeading as="h1">Team Insights</StyledPageHeading>
      </StyledHeaderSection>
      <TabsContainer>
        <ContextTabs
          tabType={TabType.Underlined}
          tabs={{
            overview: {
              title: 'Overview',
              page: (setActiveTab) => (
                <OverviewTab
                  team={selectedTeam}
                  teamReport={teamReport}
                  teamReportState={teamReportState}
                  goToReport={() => {
                    setActiveTab && setActiveTab('report');
                  }}
                />
              ),
            },
            report: {
              title: 'Team Report',
              page: () => (
                <TeamReportTab
                  team={selectedTeam}
                  teamReport={teamReport}
                  teamReportState={teamReportState}
                />
              ),
            },
          }}
        />
      </TabsContainer>
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        width={'75%'}
        backgroundColor={white}
        useFullHeight
      >
        <ExitButtonContainer>
          <StyledIconButton
            icon={exitIcon}
            onClick={toggleDrawer}
            buttonLabel="drawer-close-button"
          />
        </ExitButtonContainer>
        <TeamContainer>
          <Team
            showTeamName={true}
            name={selectedTeam.name}
            members={selectedTeam.members}
            showManagers={true}
            useAltAvatarStyle
          />
        </TeamContainer>
      </Drawer>
    </div>
  );
};
