"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScrollToTop = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
const ScrollToTop = () => {
  const {
    pathname
  } = (0, _reactRouterDom.useLocation)();
  (0, _react.useEffect)(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
exports.ScrollToTop = ScrollToTop;