import React from 'react';
import {ActionPlannerWelcomeItem} from '>generated/dvp.types';
import {BookContentsContainer} from '>shared/components/book/sidebar/contents';
import {vr4} from '>shared/styles/mixins/verticalRhythm';
import {SectionMarkdown, renderText} from 'wnd-dvp-reports';
import {Heading, Section} from '>shared/components/heading/heading';
import {textXsExtraboldUppercase} from '>shared/components/typography/designSystemTypography';
import {ContentSection, TitleContainer} from '../../employeeHome/employeeHome.styles';
import {sharedComponents} from '>lib/markdownSectionComponents';

interface Props {
  actionPlannerWelcomeItem: ActionPlannerWelcomeItem;
  templateContext: Record<string, any>;
}

export const ActionPlannerWelcomeItemView: React.FC<Props> = ({
  actionPlannerWelcomeItem,
  templateContext,
}) => {
  return (
    <BookContentsContainer>
      <ContentSection>
        <Section rootLevel={1}>
          <TitleContainer>
            <Heading css={[vr4, textXsExtraboldUppercase]}>
              {actionPlannerWelcomeItem.content.title}
            </Heading>
          </TitleContainer>
        </Section>
        <p>
          {renderText(
            actionPlannerWelcomeItem.content.description,
            templateContext,
            actionPlannerWelcomeItem.variables
          )}
        </p>

        <SectionMarkdown components={sharedComponents}>
          {renderText(
            actionPlannerWelcomeItem.content.body,
            templateContext,
            actionPlannerWelcomeItem.variables
          )}
        </SectionMarkdown>
      </ContentSection>
    </BookContentsContainer>
  );
};
