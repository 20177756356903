export function setUpAPIs(apis) {
    var ACCESS_TOKEN_STORAGE_PREFIX = window.location.port || '80';
    var ACCESS_TOKEN_STORAGE_PATH = "".concat(ACCESS_TOKEN_STORAGE_PREFIX, ":accessToken");
    var setAPIAccessToken = function (accessToken) {
        window.sessionStorage.setItem(ACCESS_TOKEN_STORAGE_PATH, accessToken);
        for (var _i = 0, apis_1 = apis; _i < apis_1.length; _i++) {
            var api = apis_1[_i];
            api.setAccessToken(accessToken);
        }
    };
    var getAPIAccessToken = function () {
        return window.sessionStorage.getItem(ACCESS_TOKEN_STORAGE_PATH);
    };
    var clearAPIAccessTokens = function () {
        window.sessionStorage.removeItem(ACCESS_TOKEN_STORAGE_PATH);
        for (var _i = 0, apis_2 = apis; _i < apis_2.length; _i++) {
            var api = apis_2[_i];
            api.clearAccessToken();
        }
    };
    return { setAPIAccessToken: setAPIAccessToken, getAPIAccessToken: getAPIAccessToken, clearAPIAccessTokens: clearAPIAccessTokens };
}
