import * as React from 'react';

export const getImageUrl = function(imageName: string): string {
  return `${process.env.PUBLIC_URL}/images/${imageName}`;
};

// TODO: Move to react utils
export function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  debounceTime: number,
  dependencies: React.DependencyList
): T {
  const timerId = React.useRef<number>();

  return React.useCallback((...args: any[]) => {
    window.clearTimeout(timerId.current);

    timerId.current = window.setTimeout(() => {
      callback(...args);
    }, debounceTime);
  }, dependencies) as T;
}


export function useSkipFirstRender(callback: () => void, dependencies: React.DependencyList) {
  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (!isFirstRender.current) {
      callback();
    } else {
      isFirstRender.current = false;
    }
  }, dependencies);
}

export function useDeferredDebounce(
  debounceFn: () => void,
  debounceTime: number,
  dependencies: React.DependencyList
) {
  const debounceTimer = React.useRef<number>();

  useSkipFirstRender(() => {
    window.clearTimeout(debounceTimer.current);
    debounceTimer.current = window.setTimeout(debounceFn, debounceTime);

    return () => {
      window.clearTimeout(debounceTimer.current);
    };
  }, dependencies);
}

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export function shallowEqual<T extends Record<string, any>, K extends keyof T>(
  objA?: T | null,
  objB?: T | null
): boolean {
  if (objA === objB) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA) as K[];
  const keysB = Object.keys(objB) as K[];

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (!objB.hasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

export function usePureState<T extends Record<string, any>>(
  initValue: T | (() => T)
): readonly [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = React.useState<T>(initValue);

  return [
    value,
    React.useCallback((setter) => {
      setValue((prevValue) => {
        let newValue;

        if (typeof setter === 'function') {
          newValue = (setter as (prevValue: T) => T)(prevValue);
        } else {
          newValue = setter as T;
        }

        if (shallowEqual(newValue, prevValue)) {
          return prevValue;
        }

        return newValue;
      });
    }, []),
  ] as const;
}

export function useSubState<
  T extends Record<string, Exclude<any, Function>>,
  K extends keyof T,
  V extends T[K]
>(
  rootSetFunction: (setter: T | ((prevValue: T) => T)) => void,
  child: K
): (setter: V | ((prevValue: V) => V)) => void {
  return React.useCallback((setter: V | ((prevValue: V) => V)) => {
    if (typeof setter === 'function') {
      rootSetFunction((prevRootValue) => {
        const prevTargetValue = prevRootValue[child];

        return {
          ...prevRootValue,
          child: (setter as (prevValue: V) => V)(prevTargetValue),
        };
      });
    } else {
      rootSetFunction(setter);
    }
  }, []);
}

export function getCustomerMessage(message: string): string {
  let customerMessage;
  const splitMessage = message.split('Payload Message:');

  if (splitMessage.length > 1) {
    customerMessage = splitMessage[1].trim();
  } else {
    customerMessage = message;
  }
  return customerMessage;
}