export var BadgeColors;
(function (BadgeColors) {
    BadgeColors["GRAY"] = "gray";
    BadgeColors["PRIMARY"] = "primary";
    BadgeColors["ERROR"] = "error";
    BadgeColors["WARNING"] = "warning";
    BadgeColors["SUCCESS"] = "success";
    BadgeColors["INDIGO"] = "indigo";
    BadgeColors["PURPLE"] = "purple";
    BadgeColors["ROSE"] = "rose";
    BadgeColors["ORANGE"] = "orange";
    BadgeColors["WHITE"] = "white";
})(BadgeColors || (BadgeColors = {}));
