import {Employee} from '>generated/dvp.types';

export function hasEverHadResults(employee: Employee): boolean {
  const hasPendingResults = Boolean(employee.results?.pendingResultWorkflowIds?.length);
  const hasReleasedResults = Boolean(employee.results?.activeResultWorkflowIds?.length);

  return hasPendingResults || hasReleasedResults;
}

export function getEmployeeFullName(employee: Employee): string {
  return `${employee.preferredName ?? employee.firstName} ${employee.lastName}`;
}
