import * as React from 'react';
import {Redirect, RouteChildrenProps} from 'react-router-dom';
import {BookIndex} from '>components/book/bookIndex';
import {BookContents} from './contents';
import {tableOfContentsSidebar} from './styles';
import {dvpApi} from '>root/apis';
import {
  BookLayout,
  Chapter,
  getActiveCategoryInChapter,
  getActiveContentSection,
  getActiveMeasureSection,
} from 'wnd-dvp-reports';
import {useSelector} from 'react-redux';

import {EmployeeHomeAnchorPrefix, RoutePath} from '>root/routes/routes';
import {Spinner} from '>shared/components/spinner';
import {
  PageLayoutComponents,
  ResponsiveLayout,
} from '>shared/components/layouts/responsive/responsiveLayout';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {mediaQueries} from '>shared/styles/breakpoints';
import {Location} from 'history';
import {Section} from '>shared/components/heading/heading';
import {assertEmployeeExists} from '>lib/assert';

function getActiveSectionTitle(chapter: Chapter, activeSectionId: string): string | undefined {
  const activeCategory = getActiveCategoryInChapter(chapter, activeSectionId);

  if (activeCategory) {
    const measureSection = getActiveMeasureSection(activeCategory, activeSectionId);
    return measureSection?.title;
  } else {
    const contentSection = getActiveContentSection(chapter, activeSectionId);
    return contentSection?.title;
  }
}

export const Results: React.FC<RouteChildrenProps<{chapterId: string}>> = ({
  match,
  history,
  location,
}) => {
  const employee = useSelector((state) => state.employee.employee);
  assertEmployeeExists(employee);

  const selectedChapterId = `${EmployeeHomeAnchorPrefix}-${match?.params.chapterId}`;
  const pageLocation = React.useRef<Location>(location);
  const {
    setHiddenComponent,
    setActiveSectionTitle,
    setIsHeaderHidden,
    hiddenComponent,
  } = useResponsiveLayout();

  const setSelectedChapter = React.useCallback((chapterId: string) => {
    const sectionRegExp = new RegExp(`${EmployeeHomeAnchorPrefix}-(\\d+)`);
    const m = chapterId.match(sectionRegExp);
    if (m) {
      history.push(`${RoutePath.EmployeeResults}${m[1]}#${chapterId}`);
    }
  }, []);

  const [isLoading, setIsLoading] = React.useState(true);
  const [bookLayout, setBookLayout] = React.useState<BookLayout>();

  const fetchBookLayout = React.useCallback(async () => {
    const result = await dvpApi.getBookResults({
      accountId: employee.accountId,
      employeeId: employee.id,
    });

    setBookLayout(result.data as BookLayout);
    setIsLoading(false);
  }, [selectedChapterId, employee]);

  React.useEffect(() => {
    fetchBookLayout();
  }, [selectedChapterId]);

  const activeChapter = React.useMemo(() => {
    if (bookLayout) {
      const chapter = bookLayout.chapters.find((c) => c.id === selectedChapterId);

      if (!chapter) {
        history.push(`${RoutePath.EmployeeResults}1`);
        return bookLayout?.chapters[0];
      }

      return chapter;
    }
  }, [bookLayout, selectedChapterId]);

  const [activeSectionId, setActiveSectionId] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!activeSectionId) {
      const introductionSection = activeChapter?.topLevelSections[0];

      if (introductionSection) {
        setActiveSectionId(introductionSection.id);
        setActiveSectionTitle(introductionSection.title);
      }
    }
  }, [activeChapter]);

  React.useEffect(() => {
    const isViewingChildPageOnMobile = hiddenComponent === PageLayoutComponents.Nav;
    setIsHeaderHidden(isViewingChildPageOnMobile);
  }, [hiddenComponent]);

  React.useEffect(() => {
    const mobile = window.matchMedia(mediaQueries.smallOrLower.media);
    const chapterWasChanged = location.pathname !== pageLocation.current.pathname;

    if (mobile.matches && !chapterWasChanged) {
      setHiddenComponent(PageLayoutComponents.Nav);
    }

    setActiveSectionId(location.hash.slice(1));
    pageLocation.current = location;

    if (activeChapter) {
      const sectionTitle = getActiveSectionTitle(activeChapter, location.hash.slice(1));
      if (sectionTitle) {
        setActiveSectionTitle(sectionTitle);
      }
    }
  }, [location]);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {bookLayout && activeSectionId && activeChapter && (
        <ResponsiveLayout
          nav={
            <BookIndex
              css={tableOfContentsSidebar}
              bookLayout={bookLayout}
              activeChapterId={selectedChapterId}
              activeSectionId={activeSectionId}
              onChapterChange={(chapterId) => {
                setSelectedChapter(chapterId);

                // We always want to take them to the introduction section when selecting a new chapter from the dropdown
                setActiveSectionId(
                  bookLayout.chapters.find((ch) => ch.id === chapterId)?.topLevelSections[0].id
                );
              }}
            />
          }
          main={
            <Section>
              <BookContents
                activeSectionId={activeSectionId}
                activeChapter={activeChapter}
                setActiveSectionId={setActiveSectionId}
                bookLayout={bookLayout}
              />
            </Section>
          }
          observerAttribute="data-bodycontent"
        />
      )}
    </>
  );
};
