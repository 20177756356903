import * as colors from 'wnd-themes/lib/colorPalette';
import styled from '@emotion/styled';
import {FontWeight} from 'wnd-themes/lib/fonts';
import {
  textLgMedium,
  textXsExtraboldUppercase,
} from '>shared/components/typography/designSystemTypography';
import {vr5} from '>shared/styles/mixins/verticalRhythm';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import {ActionItemWidgetVariant} from 'wnd-dvp-reports';
import {css} from '@emotion/react';

interface ActionItemWidgetVariantStyle {
  variant: ActionItemWidgetVariant;
}

export const ActionItemWidgetContainer = styled.div<ActionItemWidgetVariantStyle>`
  ${(props) =>
    props.variant === ActionItemWidgetVariant.Results &&
    `
     background-color: ${colors.white};
     border-radius: 0 0 1.2rem 1.2rem;
     box-shadow: 0 0.2rem 0.4rem -0.2rem rgba(16, 24, 40, 0.06),
     0 0.4rem 0.8rem -0.2rem rgba(16, 24, 40, 0.1);
    `}
`;

export const ActionItemWidgetHeader = styled.div<ActionItemWidgetVariantStyle>`
  ${(props) =>
    props.variant === ActionItemWidgetVariant.Results
      ? `
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding: 2.4rem 2.4rem 0 2.4rem;
  border-top: 0.4rem solid ${colors.blue500};
  `
      : `
  padding-bottom: 2rem;
  border-bottom: 1px solid ${colors.gray200}
  `}
`;

export const ActionItemWidgetBody = styled.div`
  padding: 2.4rem;
`;

export const RecommendedActionsHeader = styled.div<ActionItemWidgetVariantStyle>`
  font-weight: ${FontWeight.Medium};
  font-size: 1.4rem;

  ${(props) =>
    props.variant === ActionItemWidgetVariant.ActionPlanner && `color: ${colors.gray700}`}
`;

export const ActionItemWidgetHeading = styled.div<ActionItemWidgetVariantStyle>`
  ${(props) =>
    props.variant === ActionItemWidgetVariant.Results
      ? css`
          ${textXsExtraboldUppercase}
        `
      : css`
          ${textLgMedium}
          color ${colors.gray800}
        `}
`;

export const RecommendedActions = styled.div`
  ${vr5}
`;

export const LabelContainer = styled.div`
  display: block;
`;

export const StyledParagraph = styled(Paragraph)<{untouchedExistingItem: boolean}>`
  ${(props) => props.untouchedExistingItem && 'font-style: italic;'}
  margin-bottom: 0;
`;

export const StyledCheckboxContainer = styled.div`
  &:hover {
    border-radius: 1.2rem;
    outline: 1px solid ${colors.blue500};
  }

  &:focus-visible,
  &:focus-within {
    box-shadow: 0px 0px 0px 4px #e0f2fe;
    border-radius: 1.2rem;
    outline: 1px solid ${colors.blue500};
  }
`;
