import * as React from 'react';
import styled from '@emotion/styled';
import {DotPlot, DotPlotProps} from '>shared/components/charts/dotPlot';
import {vr8} from '>shared/styles/mixins/verticalRhythm';

export const TeamAttributeDotPlot: React.FC<DotPlotProps> = ({averageScore, data, scoreZones}) => {
  return (
    <Container>
      <DotPlot averageScore={averageScore} data={data} scoreZones={scoreZones} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 2.4rem;
  justify-content: center;
  width: 100%;
  max-width: 64rem;
  ${vr8}
`;
