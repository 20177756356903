import * as React from 'react';
import {vr6} from '>styles/mixins/verticalRhythm';
import {ActionPlannerWelcomeItemFormFields, ActionPlannerWelcomeItemFormBuilder} from './formFields';
import {FormProvider, UseFormMethods} from 'react-hook-form';
import {Heading} from '>shared/components/heading/heading';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';

export const defaultActionPlannerWelcomeItemFormValues: ActionPlannerWelcomeItemFormFields = {
  title: '',
  description: '',
  body: '',
};

interface Props {
  title: string;
  formMethods: UseFormMethods<ActionPlannerWelcomeItemFormFields>;
}

export const ActionPlannerWelcomeItemForm: React.FC<Props> = ({title, formMethods}) => {
  return (
    <>
      <Heading css={[vr6, displayXsMedium]}>{title}</Heading>
      <FormProvider {...formMethods}>
        <ActionPlannerWelcomeItemFormBuilder />
      </FormProvider>
    </>
  );
};
