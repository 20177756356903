import * as React from 'react';
import {useForm, useFormContext} from 'react-hook-form';
import {FormBuilder, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {Input} from '>shared/components/form/input';
import {rollbarLogger} from '>lib/logger';
import {dvpApi} from '>root/apis';
import {DrawerForm, Stage, StageFormValues} from '>shared/components/drawer/drawerForm';
import {FieldValues} from 'react-hook-form/dist/types/form';
import {useSelector} from 'react-redux';
import {assertAccountExists} from '>lib/assert';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import {getLoggedInUserId} from '>lib/user';
import {AccountUserWithPII, Team} from '>generated/dvp.types';
import {isValidEmailAddress} from '>shared/lib/emailValidation';
import {assertExists} from 'wnd-util/lib/assert';

type AccountUserWithPIIAndAuthId = AccountUserWithPII & {authUserId: string};

interface GeneralStageFields extends FieldValues {
  email: string;
}

interface GeneralFormProps {
  allAccountUsers: AccountUserWithPIIAndAuthId[];
}

const GeneralForm: React.FC<GeneralFormProps> = ({allAccountUsers}) => {
  const {register} = useFormContext<GeneralStageFields>();
  const loggedInUser = getLoggedInUserId();

  const validateUser = async (email: string) => {
    const accountUser = allAccountUsers.find(
      (user) => user.email.toLowerCase().trim() === email.toLowerCase().trim()
    );

    if (!accountUser) {
      return 'Results can only be shared with other users in your account';
    }

    if (loggedInUser === accountUser.authUserId) {
      return 'You cannot share results with yourself.';
    }

    if (accountUser.isPending) {
      return 'This user has not accepted their account invitation.';
    }
    return undefined;
  };

  function validEmailString(emailToValidate: string): string | undefined {
    const isValid = isValidEmailAddress(emailToValidate);

    return isValid ? undefined : 'Email is not valid.';
  }

  return (
    <FormBuilder
      showErrorSummary={false}
      fields={[
        {
          type: FormBuilderFieldType.Input,
          label: 'Email',
          name: 'email',
          component: (
            <Input
              ref={register({
                required: 'Email is required',
                validate: {
                  validEmailString,
                  validateUser,
                },
              })}
              placeholder="Email"
              required
            />
          ),
        },
      ]}
    />
  );
};

interface ShareTeamReportDrawerFormProps {
  isOpen: boolean;
  onClose: () => void;
  teamToShare: Team;
}

export const ShareTeamReportDrawerForm: React.FC<ShareTeamReportDrawerFormProps> = ({
  isOpen,
  onClose,
  teamToShare,
}) => {
  const {showSuccessAlert} = useSnackbar();
  const {account} = useSelector((state) => state.account);
  assertAccountExists(account);
  const [allAccountUsers, setAllAccountUsers] = React.useState<AccountUserWithPIIAndAuthId[]>([]);
  const [validUserName, setValidUserName] = React.useState<string>('');

  const generalFormMethods = useForm<GeneralStageFields>({
    mode: 'onSubmit',
    defaultValues: {email: ''},
  });

  const confirmationFormMethods = useForm<FieldValues>();

  React.useEffect(() => {
    dvpApi.getUsers({accountId: account.id}).then(({data: allAccountUsers}) => {
      setAllAccountUsers(allAccountUsers);
    });
  }, []);

  const submit = React.useCallback(
    async (stagesFieldValues: StageFormValues<GeneralStageFields>[]) => {
      const generalFields = stagesFieldValues[0] as GeneralStageFields;

      await dvpApi.shareTeamReportWithUser(
        {recipientEmail: generalFields.email},
        {accountId: account.id, teamId: teamToShare.id}
      );

      showSuccessAlert(`Success! The team was shared with ${validUserName}.`);
      onClose();
    },
    [onClose, validUserName]
  );

  const getUserName = React.useCallback(
    (email: string) => {
      const accountUser = allAccountUsers.find(
        (user) => user.email.toLowerCase().trim() === email.toLowerCase().trim()
      );

      assertExists(accountUser, 'User must exist.');
      setValidUserName(`${accountUser.firstName} ${accountUser.lastName}`);
    },
    [allAccountUsers]
  );

  const onSubmitError = React.useCallback(
    (error, setFormError) => {
      if (error.status === 404) {
        setFormError('User not found.');
      } else {
        rollbarLogger.error(error);
      }
    },
    [rollbarLogger]
  );

  const generalStage: Stage<GeneralStageFields> = {
    methods: generalFormMethods,
    form: <GeneralForm allAccountUsers={allAccountUsers} />,
    actions: {
      next: {
        onClick: async (stagesFieldValues: StageFormValues<GeneralStageFields>[]) => {
          const email = stagesFieldValues[0].email;
          assertExists(email, 'Email must exist');
          getUserName(email);
        },
      },
      cancel: {
        onClick: onClose,
      },
    },
  };

  const ConfirmationStage: Stage<FieldValues> = {
    methods: confirmationFormMethods,
    form: <Paragraph>Are you sure you want to share team results with {validUserName}?</Paragraph>,
    actions: {
      next: {
        onClick: submit,
      },
      back: {},
    },
  };

  return (
    <DrawerForm
      isOpen={isOpen}
      onDismiss={onClose}
      formTitle="Share Team Report"
      // Even if all stages satisfy the constraints of FieldValues
      // TS seems to be angry about any subsequent stage types
      stages={[generalStage, ConfirmationStage as any]}
      onSubmitError={onSubmitError}
      formDescription="Enter the email of the individual you would like to share this team report with."
    />
  );
};
