import {IntroductionForm} from '>components/contentManagement/form/introduction/form';
import {IntroductionFormFields} from '>components/contentManagement/form/introduction/formFields';
import {MeasureForm} from '>components/contentManagement/form/measure/form';
import {
  convertToUserFriendlyFieldName,
  MeasureFormFields,
} from '>components/contentManagement/form/measure/formFields';
import {TemplateControlForm} from '>components/contentManagement/form/templateControls/form';
import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import {
  ChapterItem,
  ChapterItemType,
  Introduction,
  Measure,
} from '>generated/dvp.types';
import styled from '@emotion/styled';
import React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {vr12} from '>styles/mixins/verticalRhythm';
import {ViewState} from './content';

interface Props {
  chapterItemType: ChapterItemType;
  chapterItemFormMethods:
    | UseFormMethods<IntroductionFormFields>
    | UseFormMethods<MeasureFormFields>;
  templateControlFormMethods: UseFormMethods<TemplateControlFormFields>;
  handleTemplateContextChange: (newValue: string) => void;
  handleGlobalVariablesChange: (newValue: string) => void;
  viewState: ViewState;
}

const Form = styled.div`
  flex-grow: 1;
`;

export const FormView: React.FC<Props> = ({
  chapterItemType,
  chapterItemFormMethods,
  templateControlFormMethods,
  handleTemplateContextChange,
  handleGlobalVariablesChange,
  viewState,
}) => {
  const ChapterItemForm = React.useMemo(() => {
    return {
      [ChapterItemType.Introduction]: (
        <IntroductionForm
          title="Edit Introduction"
          formMethods={chapterItemFormMethods as UseFormMethods<IntroductionFormFields>}
        />
      ),
      [ChapterItemType.Measure]: (
        <MeasureForm
          title={`${viewState === ViewState.Create ? 'Create' : 'Edit'} Measure`}
          formMethods={chapterItemFormMethods as UseFormMethods<MeasureFormFields>}
        />
      ),
    };
  }, [chapterItemFormMethods]);

  return (
    <>
      <Form css={vr12}>{ChapterItemForm[chapterItemType]}</Form>

      <TemplateControlForm
        formMethods={templateControlFormMethods}
        onTemplateContextChange={handleTemplateContextChange}
        onGlobalVariablesChange={handleGlobalVariablesChange}
      />
    </>
  );
};

export function convertChapterItemToFormFields(
  chapterItem: ChapterItem
): IntroductionFormFields | MeasureFormFields {
  if (chapterItem.type === ChapterItemType.Measure) {
    const measure = chapterItem.content as Required<Measure>;
    return {
      ...measure,
      synonym1: measure.synonyms[0],
      synonym2: measure.synonyms[1],
      synonym3: measure.synonyms[2],
    };
  }

  return chapterItem.content;
}

export function convertFormFieldsToChapterItemContent(
  formFields: IntroductionFormFields | MeasureFormFields,
  chapterItemType: ChapterItemType
): Introduction | Measure {
  switch (chapterItemType) {
    case ChapterItemType.Introduction:
      return formFields as Introduction;
    case ChapterItemType.Measure:
      const measureFormFields = formFields as MeasureFormFields;
      return {
        ...measureFormFields,
        scoringFloor: Number(measureFormFields.scoringFloor),
        synonyms: convertToSynonymsArray(
          measureFormFields.synonym1,
          measureFormFields.synonym2,
          measureFormFields.synonym3
        ),
      };
  }
}

function convertToSynonymsArray(synonym1?: string, synonym2?: string, synonym3?: string): string[] {
  const synonyms = [];
  if (synonym1) {
    synonyms.push(synonym1);
  }
  if (synonym2) {
    synonyms.push(synonym2);
  }
  if (synonym3) {
    synonyms.push(synonym3);
  }
  return synonyms;
}

export function validateFormForPublish(
  formMethods: UseFormMethods<IntroductionFormFields> | UseFormMethods<MeasureFormFields>,
  viewState: ViewState
): boolean {
  const formFieldsRecord = formMethods.getValues() as Record<string, any>;
  const formFieldKeys = Object.keys(formFieldsRecord);
  let isFormValid = true;

  formFieldKeys.forEach((formFieldKey) => {
    const value = formFieldsRecord[formFieldKey];

    if (value === '' || value === undefined) {
      isFormValid = false;

      if (viewState === ViewState.Create || viewState === ViewState.Edit) {
        const userFriendlyFieldName = convertToUserFriendlyFieldName(formFieldKey);
        formMethods.setError(formFieldKey as never, {
          type: `${formFieldKey}-error`,
          message: `${userFriendlyFieldName} is required`,
        });
      }
    }
  });

  return isFormValid;
}
