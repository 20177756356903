"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalDisplayType = void 0;
var ModalDisplayType;
(function (ModalDisplayType) {
  ModalDisplayType[ModalDisplayType["Popup"] = 0] = "Popup";
  ModalDisplayType[ModalDisplayType["Small"] = 1] = "Small";
  ModalDisplayType[ModalDisplayType["Standard"] = 2] = "Standard";
  ModalDisplayType[ModalDisplayType["Wide"] = 3] = "Wide";
  ModalDisplayType[ModalDisplayType["Alert"] = 4] = "Alert";
})(ModalDisplayType || (exports.ModalDisplayType = ModalDisplayType = {}));