import {ActionPlannerWelcomeItemFormFields} from '>components/contentManagement/form/actionPlannerWelcome/formFields';
import {
  SecondaryHomePageItemFormFields,
  SpotlightHomePageItemFormFields,
} from '>components/contentManagement/form/homePage/formFields';
import {
  OverviewTeamReportItemFormFields,
  ApproachTeamReportItemFormFields,
  AttributeTeamReportItemFormFields,
} from '>components/contentManagement/form/teamReport/formFields';
import {IntroductionFormFields} from '>components/contentManagement/form/introduction/formFields';
import {MeasureFormFields} from '>components/contentManagement/form/measure/formFields';
import {
  ActionPlannerWelcomeItemContent,
  HomePageItemContent,
  Introduction,
  Measure,
} from '>generated/dvp.types';
import {UseFormMethods} from 'react-hook-form';

export function setFormFields(
  formMethods:
    | UseFormMethods<IntroductionFormFields>
    | UseFormMethods<MeasureFormFields>
    | UseFormMethods<SpotlightHomePageItemFormFields>
    | UseFormMethods<SecondaryHomePageItemFormFields>
    | UseFormMethods<ActionPlannerWelcomeItemFormFields>
    | UseFormMethods<OverviewTeamReportItemFormFields>
    | UseFormMethods<ApproachTeamReportItemFormFields>
    | UseFormMethods<AttributeTeamReportItemFormFields>,
  fields:
    | IntroductionFormFields
    | MeasureFormFields
    | SpotlightHomePageItemFormFields
    | SecondaryHomePageItemFormFields
    | ActionPlannerWelcomeItemFormFields
    | OverviewTeamReportItemFormFields
    | ApproachTeamReportItemFormFields
    | AttributeTeamReportItemFormFields
) {
  formMethods.reset(fields);
}

export function validateContentForPublish(
  content: Introduction | Measure | HomePageItemContent | ActionPlannerWelcomeItemContent
): boolean {
  let isValidForPublish = true;
  const values = Object.values(content);
  values.forEach((value) => {
    if (value === '' || value === undefined) {
      isValidForPublish = false;
    }
  });

  return isValidForPublish;
}
