import * as nunjucks from 'nunjucks';

export function validateJson(textToValidate: string): string | undefined {
  try {
    JSON.parse(textToValidate);
  } catch (error) {
    return 'Content must be JSON format';
  }
}

export function validateTemplateSyntax(text: string): string | undefined {
  try {
    nunjucks.parser.parse(text);
  } catch (err) {
    if (err) {
      return `Template Syntax Error: ${(err as Error).message}`;
    }
  }
}
