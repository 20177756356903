import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {FormBuilder} from '>shared/components/form/formBuilder';
import {buildActionItemFormFields} from './formBuilder';
import {NewActionItem, ActionItem, ActionItemStatus} from '>generated/dvp.types';
import moment from 'moment';

export interface ActionItemFormFields {
  status: ActionItemStatus;
  title: string;
  attribute: string;
  dueDate?: string;
  color?: 'Orange' | 'Purple' | 'Blue' | 'Green' | 'Red' | 'Grey' | 'Indigo';
  taskType?: string;
  start?: number;
  finish?: number;
  current?: number;
  description: string;
  improve: string;
  firstStep: string;
  howToMeasure: string;
}

export interface ActionItemFormFieldProps {
  selectableAttributes: string[];
  isFromTemplate: boolean;
}

export const ActionItemFormFields: React.FC<ActionItemFormFieldProps> = ({
  selectableAttributes,
  isFromTemplate,
}) => {
  const {register} = useFormContext<ActionItemFormFields>();

  const [selectedAttribute, setSelectedAttribute] = React.useState<string>();
  const onSelectedAttribute = React.useCallback((attribute: string) => {
    setSelectedAttribute(attribute);
  }, []);

  const actionFormFields = buildActionItemFormFields(
    register,
    onSelectedAttribute,
    isFromTemplate,
    selectableAttributes,
    selectedAttribute
  );

  return <FormBuilder fields={actionFormFields} />;
};

export function convertFormFieldsToActionItem(
  formFields: ActionItemFormFields,
  employeeId: string,
  accountId: string
): NewActionItem {
  return {
    status: formFields.status,
    title: formFields.title,
    attribute: formFields.attribute,
    dueDate: formFields.dueDate
      ? moment(formFields.dueDate).format('yyyy-MM-DDTHH:mm:ss.000Z')
      : undefined,
    color: formFields.color,
    taskType: formFields.taskType || 'taskType',
    start: Number(formFields.start) || 0,
    finish: Number(formFields.finish) || 0,
    current: Number(formFields.current) || 0,
    description: formFields.description,
    improve: formFields.improve,
    firstStep: formFields.firstStep,
    howToMeasure: formFields.howToMeasure,
    employeeId,
    accountId,
  };
}

export function convertActionItemToFormFields(actionItem: ActionItem): ActionItemFormFields {
  return {
    status: actionItem.status,
    title: actionItem.title,
    attribute: actionItem.attribute,
    dueDate: actionItem.dueDate ? moment(actionItem.dueDate).format('YYYY-MM-DD') : undefined,
    color: actionItem.color,
    taskType: actionItem.taskType,
    start: actionItem.start,
    finish: actionItem.finish,
    current: actionItem.current,
    description: actionItem.description ?? '',
    improve: actionItem.improve ?? '',
    firstStep: actionItem.firstStep ?? '',
    howToMeasure: actionItem.howToMeasure ?? '',
  };
}
