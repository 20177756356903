import {assertExists} from 'wnd-util/lib/assert';

import {dvpApi} from '>root/apis';
import {createAppThunk} from '../thunk';
import {ActionItemStatus, Employee} from '>generated/dvp.types';

export const getActionPlannerWelcomeItems = createAppThunk(
  '@wnd/actionPlanner/getActionPlannerWelcomeItems',
  async (_, thunkApi) => {
    const {
      employee: {employee},
      account: {account},
    } = thunkApi.getState();
    assertExists(employee, 'Employee needs to be selected to render content.');
    assertExists(
      account,
      'Account needs to be initialized to render action planner welcome items.'
    );

    const response = await dvpApi.listRenderedActionPlannerWelcomeItems({
      accountId: account.id,
      employeeId: employee.id,
    });

    return response.data;
  }
);

export const getActionItems = createAppThunk(
  '@wnd/actionPlanner/getActionItems',
  async (_, thunkApi) => {
    const {
      employee: {employee},
      account: {account},
    } = thunkApi.getState();
    assertExists(employee, 'Employee needs to be selected to get action items.');
    assertExists(account, 'Account needs to be initialized to get action items.');

    const response = await dvpApi.listActionItems({
      accountId: account.id,
      employeeId: employee.id,
    });

    return response.data;
  }
);

interface CreateActionItemParams {
  title: string;
  description: string;
  templateId?: string;
  attribute?: string;
}

interface UpdateActionItemParams {
  actionItemId: string;
  title: string;
  description: string;
}

export const createActionItem = createAppThunk(
  '@wnd/actionPlanner/createActionItem',
  async ({title, description, templateId, attribute}: CreateActionItemParams, thunkApi) => {
    const {
      employee: {employee},
      account: {account},
    } = thunkApi.getState();
    assertExists(employee, 'Employee needs to be selected to create action items.');
    assertExists(account, 'Account needs to be initialized to create action items.');

    if (templateId) {
      await dvpApi.createActionItemFromTemplate({
        title,
        description,
        employeeId: employee.id,
        accountId: account.id,
        templateId,
      });
    } else {
      assertExists(attribute, 'attribute must be passed for non-template action items');

      await dvpApi.createActionItem({
        title,
        description,
        employeeId: employee.id,
        accountId: account.id,
        status: ActionItemStatus.ToDo,
        attribute,
      });
    }

    const response = await dvpApi.listActionItems({accountId: account.id, employeeId: employee.id});

    return response.data;
  }
);

export const deleteActionItem = createAppThunk(
  '@wnd/actionPlanner/getActionItem',
  async (actionItemId: string, thunkApi) => {
    const {
      employee: {employee},
      account: {account},
    } = thunkApi.getState();
    assertExists(employee, 'Employee needs to be selected to delete action items.');
    assertExists(account, 'Account needs to be initialized to delete action items.');

    await dvpApi.deleteActionItem({
      accountId: account.id,
      employeeId: employee.id,
      actionItemId,
    });

    const response = await dvpApi.listActionItems({accountId: account.id, employeeId: employee.id});

    return response.data;
  }
);

export const updateActionItem = createAppThunk(
  '@wnd/actionPlanner/updateActionItem',
  async ({actionItemId, title, description}: UpdateActionItemParams, thunkApi) => {
    const {
      employee: {employee},
      account: {account},
    } = thunkApi.getState();
    assertExists(employee, 'Employee needs to be selected to delete action items.');
    assertExists(account, 'Account needs to be initialized to delete action items.');

    await dvpApi.updateActionItem({
      id: actionItemId,
      accountId: account.id,
      employeeId: employee.id,
      title,
      description,
    });

    const response = await dvpApi.listActionItems({accountId: account.id, employeeId: employee.id});

    return response.data;
  }
);
