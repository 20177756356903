/*
    DO NOT MODIFY THIS FILE. THIS FILE WAS AUTOMATICALLY GENERATED BY WND-SWAGGER-TS-CLIENT-GENERATOR
*/

/* eslint-disable */
import * as generatedTypes from './unifiedAccount.types';

import {httpClient, RequestOptions, Response, toBearer, buildJsonPatch} from 'wnd-util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function pick(params: Record<string, unknown>, fields: string[]): Record<string, string> {
  return fields.reduce((acc, key) => {
    if (params[key] !== undefined) {
      acc[key] = params[key];
    }
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as Record<string, any>);
}

interface Options {
  // If provided, gets access token during each request
  getAuthToken?(): Promise<string>;
}

export class Api {
  private client = httpClient.create();

  constructor(private rootUrl: string, private options: Options = {}) {
    this.options = options;
  }

  setAccessToken(accessToken: string): void {
    if (this.options.getAuthToken) {
      throw new Error('Should not call setAccessToken method when getAuthToken method is provided');
    }

    this.client.setAuthToken(accessToken);
  }

  clearAccessToken(): void {
    if (this.options.getAuthToken) {
      throw new Error(
        'Should not call clearAccessToken method when getAuthToken method is provided'
      );
    }

    this.client.clearAuthToken();
  }

  private async request<RequestPayload, ResponsePayload, QueryParams>(
    requestOptions: RequestOptions<RequestPayload, QueryParams>
  ): Promise<Response<ResponsePayload>> {
    const updatedRequestOptions = {
      ...requestOptions,
    };

    updatedRequestOptions.headers = requestOptions.headers ?? {};

    if (this.options.getAuthToken) {
      const authToken = await this.options.getAuthToken();
      updatedRequestOptions.headers.Authorization = toBearer(authToken);
    }

    return this.client.request(updatedRequestOptions);
  }

  static listUnifiedAccounts_queryParams: string[] = [
    'billingAccountId',
    'companyName',
    'unifiedAccountIds',
    'limit',
  ];
  async listUnifiedAccounts(
    params: Record<string, unknown> & {
      billingAccountId?: string;
      companyName?: string;
      unifiedAccountIds?: string;
      limit?: number;
    }
  ): Promise<httpClient.Response<generatedTypes.UnifiedAccounts>> {
    const queryObj = pick(params, Api.listUnifiedAccounts_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/unifiedAccounts`,
      queryParams: queryObj,
    });
  }

  static createUnifiedAccount_queryParams: string[] = [];
  async createUnifiedAccount(
    body: generatedTypes.NewUnifiedAccount
  ): Promise<httpClient.Response<generatedTypes.UnifiedAccount>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/unifiedAccounts`,
      queryParams: queryObj,
      body,
    });
  }

  static getUnifiedAccountById_queryParams: string[] = [];
  async getUnifiedAccountById(
    params: {unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.UnifiedAccount & void>> {
    const queryObj = pick(params, Api.getUnifiedAccountById_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/unifiedAccounts/${params.unifiedAccountId}`,
      queryParams: queryObj,
    });
  }

  static updateCompanyName_queryParams: string[] = [];
  async updateCompanyName(
    body: {newName: string},
    params: {unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.UnifiedAccount & void>> {
    const queryObj = pick(params, Api.updateCompanyName_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/unifiedAccounts/${params.unifiedAccountId}/companyName`,
      queryParams: queryObj,
      body,
    });
  }

  static updateIsApproved_queryParams: string[] = [];
  async updateIsApproved(
    body: {isApproved: boolean},
    params: {unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.UnifiedAccount & void>> {
    const queryObj = pick(params, Api.updateIsApproved_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/unifiedAccounts/${params.unifiedAccountId}/isApproved`,
      queryParams: queryObj,
      body,
    });
  }

  static getUnifiedAccountExternalPayload_queryParams: string[] = [];
  async getUnifiedAccountExternalPayload(
    params: {unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.UnifiedAccountExternalPayload & void>> {
    const queryObj = pick(params, Api.getUnifiedAccountExternalPayload_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/unifiedAccounts/${params.unifiedAccountId}/externalPayload`,
      queryParams: queryObj,
    });
  }

  static acceptTermsAndConditions_queryParams: string[] = [];
  async acceptTermsAndConditions(
    params: {unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.acceptTermsAndConditions_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/unifiedAccounts/${params.unifiedAccountId}/termsAndConditions`,
      queryParams: queryObj,
    });
  }

  static addProductAccount_queryParams: string[] = [];
  async addProductAccount(
    body: generatedTypes.NewProductAccount,
    params: {unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.UnifiedAccount & void>> {
    const queryObj = pick(params, Api.addProductAccount_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/unifiedAccounts/${params.unifiedAccountId}/productAccounts`,
      queryParams: queryObj,
      body,
    });
  }

  static listProductUsers_queryParams: string[] = ['authUserIds'];
  async listProductUsers(
    params: Record<string, unknown> & {authUserIds?: string}
  ): Promise<httpClient.Response<generatedTypes.ProductUsers>> {
    const queryObj = pick(params, Api.listProductUsers_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/productUsers`,
      queryParams: queryObj,
    });
  }

  static addPartialProductUser_queryParams: string[] = [];
  async addPartialProductUser(
    body: generatedTypes.AddProductUserParams
  ): Promise<httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/productUsers`,
      queryParams: queryObj,
      body,
    });
  }

  static getMyProductUser_queryParams: string[] = [];
  async getMyProductUser(): Promise<
    httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>
  > {
    const queryObj = {};

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/productUsers/me`,
      queryParams: queryObj,
    });
  }

  static getProductUserByAuthUserId_queryParams: string[] = [];
  async getProductUserByAuthUserId(
    params: {authUserId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>> {
    const queryObj = pick(params, Api.getProductUserByAuthUserId_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/productUsers/${params.authUserId}`,
      queryParams: queryObj,
    });
  }

  static selectUserUnifiedAccount_queryParams: string[] = [];
  async selectUserUnifiedAccount(
    params: {authUserId: string; unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>> {
    const queryObj = pick(params, Api.selectUserUnifiedAccount_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/productUsers/${params.authUserId}/unifiedAccounts/${params.unifiedAccountId}/select`,
      queryParams: queryObj,
    });
  }

  static addUserToProductAccount_queryParams: string[] = [];
  async addUserToProductAccount(
    body: generatedTypes.AddUserToProductAccountParams,
    params: {authUserId: string; unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>> {
    const queryObj = pick(params, Api.addUserToProductAccount_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/productUsers/${params.authUserId}/unifiedAccounts/${params.unifiedAccountId}/productAccounts`,
      queryParams: queryObj,
      body,
    });
  }

  static updateProductUserPermissions_queryParams: string[] = [];
  async updateProductUserPermissions(
    body: generatedTypes.UserPermissions,
    params: {authUserId: string; unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>> {
    const queryObj = pick(params, Api.updateProductUserPermissions_queryParams);

    return this.request({
      method: 'patch',
      url: `${this.rootUrl}/productUsers/${params.authUserId}/unifiedAccounts/${params.unifiedAccountId}/permissions`,
      queryParams: queryObj,
      body,
    });
  }

  static updateProductUserPending_queryParams: string[] = [];
  async updateProductUserPending(
    body: generatedTypes.UpdateProductUserPendingParams,
    params: {authUserId: string; unifiedAccountId: string; productAccountId: string} & Record<
      string,
      unknown
    >
  ): Promise<httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>> {
    const queryObj = pick(params, Api.updateProductUserPending_queryParams);

    return this.request({
      method: 'put',
      url: `${this.rootUrl}/productUsers/${params.authUserId}/unifiedAccounts/${params.unifiedAccountId}/productAccounts/${params.productAccountId}/isPendingAcceptance`,
      queryParams: queryObj,
      body,
    });
  }

  static selectUserProductAccount_queryParams: string[] = [];
  async selectUserProductAccount(
    params: {authUserId: string; unifiedAccountId: string; productAccountId: string} & Record<
      string,
      unknown
    >
  ): Promise<httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>> {
    const queryObj = pick(params, Api.selectUserProductAccount_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/productUsers/${params.authUserId}/unifiedAccounts/${params.unifiedAccountId}/productAccounts/${params.productAccountId}/select`,
      queryParams: queryObj,
    });
  }

  static accessUserProductAccount_queryParams: string[] = [];
  async accessUserProductAccount(
    params: {authUserId: string; unifiedAccountId: string; productAccountId: string} & Record<
      string,
      unknown
    >
  ): Promise<httpClient.Response<generatedTypes.ProductUserWithCompanyData & void>> {
    const queryObj = pick(params, Api.accessUserProductAccount_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/productUsers/${params.authUserId}/unifiedAccounts/${params.unifiedAccountId}/productAccounts/${params.productAccountId}/access`,
      queryParams: queryObj,
    });
  }

  static removeUserFromProductAccount_queryParams: string[] = [];
  async removeUserFromProductAccount(
    params: {authUserId: string; unifiedAccountId: string; productAccountId: string} & Record<
      string,
      unknown
    >
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.removeUserFromProductAccount_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/productUsers/${params.authUserId}/unifiedAccounts/${params.unifiedAccountId}/productAccounts/${params.productAccountId}`,
      queryParams: queryObj,
    });
  }

  static publishNewUserAnalyticDatapoint_queryParams: string[] = [];
  async publishNewUserAnalyticDatapoint(
    body: generatedTypes.UserAnalytics,
    params: {authUserId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void>> {
    const queryObj = pick(params, Api.publishNewUserAnalyticDatapoint_queryParams);

    return this.request({
      method: 'patch',
      url: `${this.rootUrl}/analytics/user/${params.authUserId}/publish`,
      queryParams: queryObj,
      body,
    });
  }
}
