export var FontWeight;
(function (FontWeight) {
    FontWeight[FontWeight["Thin"] = 100] = "Thin";
    FontWeight[FontWeight["ExtraLight"] = 200] = "ExtraLight";
    FontWeight[FontWeight["Light"] = 300] = "Light";
    FontWeight[FontWeight["Normal"] = 400] = "Normal";
    FontWeight[FontWeight["Medium"] = 500] = "Medium";
    FontWeight[FontWeight["SemiBold"] = 600] = "SemiBold";
    FontWeight[FontWeight["Bold"] = 700] = "Bold";
    FontWeight[FontWeight["ExtraBold"] = 800] = "ExtraBold";
    FontWeight[FontWeight["Black"] = 900] = "Black";
    FontWeight[FontWeight["ExtraBlack"] = 950] = "ExtraBlack";
    FontWeight["Inherit"] = "inherit";
})(FontWeight || (FontWeight = {}));
export var FontStack;
(function (FontStack) {
    FontStack["Rubik"] = "Rubik, Arial, Helvetica, sans-serif";
    FontStack["Nunito"] = "Nunito, Arial, Helvetica, sans-serif";
    FontStack["Kievit"] = "KievitWeb, Helvetica, Arial, sans-serif";
    FontStack["Inter"] = "Inter, Arial, Helvetica, sans-serif";
    FontStack["Poppins"] = "Poppins, Arial, Helvetica, sans-serif";
})(FontStack || (FontStack = {}));
