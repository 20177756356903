import {createReducer} from '@reduxjs/toolkit';
import {updateIntercomState, updatePendoState} from '../actions/vendors';

export enum IntercomState {
  NOT_READY,
  READY_TO_BOOT,
  BOOTED,
}

export enum PendoState {
  NOT_READY,
  READY_TO_INITIALIZE,
  INITIALIZED,
}

const DEFAULT_STATE: Readonly<VendorsState> = {
  intercomState: IntercomState.NOT_READY,
  pendoState: PendoState.NOT_READY,
};

export interface VendorsState {
  pendoState: PendoState;
  intercomState: IntercomState;
}

export const vendorsReducer = createReducer<VendorsState>(DEFAULT_STATE, (builder) => {
  builder.addCase(updatePendoState, (state, action) => {
    return {
      ...state,
      pendoState: action.payload,
    };
  });

  builder.addCase(updateIntercomState, (state, action) => {
    return {
      ...state,
      intercomState: action.payload,
    };
  });
});
