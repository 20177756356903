import * as React from 'react';

import {IndexContentLayout} from '>components/indexContentLayout';
import {
  CmsItem,
  CmsNavigation,
  SimpleCmsNavHeader,
} from '>components/contentManagement/navigation/cmsNavigation';
import {ViewState} from '../homePage/content';
import {CmsItemSettings} from '>components/contentManagement/settings/cmsItemSettings';
import {ActionPlannerWelcomeItemViewer} from './content';
import {dvpApi} from '>root/apis';
import {
  defaultEmployeeReportTemplateContext,
  defaultEmployeeTemplateControlFormValues,
} from '>components/contentManagement/form/templateControls/form';
import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import {useForm} from 'react-hook-form';
import {ActionPlannerWelcomeItemFormFields} from '>components/contentManagement/form/actionPlannerWelcome/formFields';
import {defaultActionPlannerWelcomeItemFormValues} from '>components/contentManagement/form/actionPlannerWelcome/form';
import {ActionPlannerWelcomeItem} from '>generated/dvp.types';
import {assertExists} from 'wnd-util/lib/assert';
import {setFormFields, validateContentForPublish} from '../formUtils';
import {validateFormForPublish} from './formView';

export const ContentManagerForActionPlannerWelcome: React.FC = () => {
  const [previousViewState, setPreviousViewState] = React.useState<ViewState>(ViewState.View);
  const [viewState, setViewState] = React.useState<ViewState>(ViewState.View);
  const [isPublishChecked, setIsPublishChecked] = React.useState<boolean>(false);
  const [formFieldsInEdit, setFormFieldsInEdit] = React.useState<
    ActionPlannerWelcomeItemFormFields
  >();
  const [templateContext, setTemplateContext] = React.useState(
    JSON.parse(defaultEmployeeReportTemplateContext)
  );
  const [allActionPlannerWelcomeItems, setAllActionPlannerWelcomeItems] = React.useState<
    ActionPlannerWelcomeItem[]
  >([]);
  const [globalVariables, setGlobalVariables] = React.useState('');
  const [selectedActionPlannerWelcomeItem, setSelectedActionPlannerWelcomeItem] = React.useState<
    ActionPlannerWelcomeItem
  >();

  const getAllActionPlannerWelcomeItems = React.useCallback(async () => {
    const {data} = await dvpApi.listActionPlannerWelcomeItems();

    setAllActionPlannerWelcomeItems(data);

    const hasItems = data.length > 0;

    if (hasItems) {
      setSelectedActionPlannerWelcomeItem(data[0]);
      setIsPublishChecked(data[0].isPublished);
    }
  }, []);

  React.useEffect(() => {
    getAllActionPlannerWelcomeItems();
  }, []);

  const actionPlannerWelcomeItemFormMethods = useForm<ActionPlannerWelcomeItemFormFields>({
    mode: 'all',
    defaultValues: defaultActionPlannerWelcomeItemFormValues,
  });

  const templateControlFormMethods = useForm<TemplateControlFormFields>({
    mode: 'all',
    defaultValues: defaultEmployeeTemplateControlFormValues,
  });

  const handleTemplateContextChange = React.useCallback((newValue: string) => {
    setTemplateContext(JSON.parse(newValue));
    templateControlFormMethods.setValue('templateContext', newValue);
  }, []);

  const handleGlobalVariablesChange = React.useCallback((newValue: string) => {
    setGlobalVariables(newValue);
    templateControlFormMethods.setValue('globalVariables', newValue);
  }, []);

  const navItemList: CmsItem[] = React.useMemo(() => {
    return allActionPlannerWelcomeItems.map(actionPlannerWelcomeItemToCmsItem);
  }, [allActionPlannerWelcomeItems]);

  const selectedItemChanged = React.useCallback(
    (selectedItemId) => {
      const newItem = allActionPlannerWelcomeItems.find((item) => item.id === selectedItemId);

      if (newItem) {
        setIsPublishChecked(newItem.isPublished);
      }

      setSelectedActionPlannerWelcomeItem(newItem);
    },
    [allActionPlannerWelcomeItems]
  );

  const validateActionPlannerWelcomeItem = React.useCallback(async () => {
    const areTemplateFieldsValid = await templateControlFormMethods.trigger();

    if (!areTemplateFieldsValid) {
      return false;
    }

    const areNonTemplateFieldsValid = await actionPlannerWelcomeItemFormMethods.trigger();

    if (isPublishChecked) {
      const isPublishValid = validateFormForPublish(actionPlannerWelcomeItemFormMethods, viewState);
      return isPublishValid && areNonTemplateFieldsValid;
    }

    return areNonTemplateFieldsValid;
  }, [viewState, actionPlannerWelcomeItemFormMethods, isPublishChecked]);

  const handleCancel = React.useCallback(() => {
    assertExists(selectedActionPlannerWelcomeItem, 'Selected item should exist');

    setIsPublishChecked(selectedActionPlannerWelcomeItem.isPublished);

    setViewState(ViewState.View);
  }, [viewState, selectedActionPlannerWelcomeItem]);

  const togglePreview = React.useCallback(async () => {
    if (!(await validateActionPlannerWelcomeItem())) {
      return;
    }

    setPreviousViewState(viewState);

    switch (viewState) {
      case ViewState.Preview:
        assertExists(
          formFieldsInEdit,
          'When going back from preview state the form fields being edited should exist'
        );
        setFormFields(actionPlannerWelcomeItemFormMethods, formFieldsInEdit);
        templateControlFormMethods.reset({
          globalVariables,
          templateContext: JSON.stringify(templateContext, null, 2),
        });

        assertExists(
          previousViewState,
          'When going back from preview state the previous state must exist'
        );

        setViewState(previousViewState);
        break;
      case ViewState.Edit:
        setFormFieldsInEdit(actionPlannerWelcomeItemFormMethods.getValues());
        setGlobalVariables(templateControlFormMethods.getValues('globalVariables'));
        setTemplateContext(JSON.parse(templateControlFormMethods.getValues('templateContext')));
        setViewState(ViewState.Preview);
        break;
    }
  }, [viewState, validateActionPlannerWelcomeItem]);

  const handleEditItem = React.useCallback(() => {
    assertExists(selectedActionPlannerWelcomeItem, 'Selected action item should exist');
    setFormFields(actionPlannerWelcomeItemFormMethods, selectedActionPlannerWelcomeItem.content);

    setViewState(ViewState.Edit);

    setGlobalVariables(selectedActionPlannerWelcomeItem.variables || '');
    templateControlFormMethods.reset({
      globalVariables: selectedActionPlannerWelcomeItem.variables,
      templateContext: defaultEmployeeReportTemplateContext,
    });
  }, [selectedActionPlannerWelcomeItem]);

  const handleDeleteItem = async () => {
    assertExists(
      selectedActionPlannerWelcomeItem,
      'A selected home page item must exist for deletion'
    );
    await dvpApi.deleteActionPlannerWelcomeItem({
      actionPlannerWelcomeItemId: selectedActionPlannerWelcomeItem.id,
    });

    setAllActionPlannerWelcomeItems((items) => {
      const newItems = items.filter((item) => item.id !== selectedActionPlannerWelcomeItem.id);

      setSelectedActionPlannerWelcomeItem(newItems[0]);

      if (newItems[0]) {
        setIsPublishChecked(newItems[0].isPublished);
      }

      return newItems;
    });
  };

  const handlePublishItem = React.useCallback(
    async (isPublished: boolean): Promise<boolean> => {
      setIsPublishChecked(isPublished);
      assertExists(selectedActionPlannerWelcomeItem, 'Selected action item should exist');

      let isValidForPublish = true;

      if (isPublished) {
        if (viewState === ViewState.View) {
          isValidForPublish = validateContentForPublish(selectedActionPlannerWelcomeItem.content);

          if (isValidForPublish) {
            const {data} = await dvpApi.updateActionPlannerWelcomeItem(
              {...selectedActionPlannerWelcomeItem, isPublished: true},
              {actionPlannerWelcomeItemId: selectedActionPlannerWelcomeItem.id}
            );

            setAllActionPlannerWelcomeItems((items) => {
              const index = items.findIndex((item) => item.id === data.id);

              const newItems = [...items];

              newItems.splice(index, 1, data);

              return newItems;
            });
            setSelectedActionPlannerWelcomeItem(data);
          }
        } else if (viewState === ViewState.Preview) {
          if (formFieldsInEdit) {
            isValidForPublish = validateContentForPublish(formFieldsInEdit);
          }
        } else {
          isValidForPublish = validateFormForPublish(
            actionPlannerWelcomeItemFormMethods,
            viewState
          );
        }
      } else if (viewState === ViewState.View) {
        if (isValidForPublish) {
          const {data} = await dvpApi.updateActionPlannerWelcomeItem(
            {...selectedActionPlannerWelcomeItem, isPublished: false},
            {actionPlannerWelcomeItemId: selectedActionPlannerWelcomeItem.id}
          );

          setAllActionPlannerWelcomeItems((items) => {
            const index = items.findIndex((item) => item.id === data.id);

            const newItems = [...items];

            newItems.splice(index, 1, data);

            return newItems;
          });

          setSelectedActionPlannerWelcomeItem(data);
        }
      }

      return isValidForPublish;
    },
    [
      viewState,
      selectedActionPlannerWelcomeItem,
      formFieldsInEdit,
      actionPlannerWelcomeItemFormMethods,
    ]
  );

  const createNewItem = React.useCallback(async () => {
    const {data: newActionPlannerWelcomeItem} = await dvpApi.createActionPlannerWelcomeItem({
      content: {
        title: 'New Action Planner Welcome Item',
        description: 'Description',
        body: 'Body',
      },
      isPublished: false,
    });

    setAllActionPlannerWelcomeItems((items) => [...items, newActionPlannerWelcomeItem]);
    setSelectedActionPlannerWelcomeItem(newActionPlannerWelcomeItem);
  }, []);

  const submitItem = React.useCallback(async () => {
    const isItemValid = await validateActionPlannerWelcomeItem();

    assertExists(
      selectedActionPlannerWelcomeItem,
      'Selected action item must exist in order to submit changes'
    );

    if (isItemValid) {
      let content;

      if (viewState === ViewState.Preview) {
        assertExists(formFieldsInEdit, 'Form fields should exist when submitting form');
        content = formFieldsInEdit;
      } else {
        content = actionPlannerWelcomeItemFormMethods.getValues();
      }

      const {data} = await dvpApi.updateActionPlannerWelcomeItem(
        {
          isPublished: isPublishChecked,
          content,
          variables: globalVariables,
        },
        {
          actionPlannerWelcomeItemId: selectedActionPlannerWelcomeItem.id,
        }
      );

      const updatedItems = allActionPlannerWelcomeItems.map((item) => {
        return item.id === data.id ? data : item;
      });

      setAllActionPlannerWelcomeItems(updatedItems);
      setSelectedActionPlannerWelcomeItem(data);
      setViewState(ViewState.View);
    }
  }, [
    validateActionPlannerWelcomeItem,
    selectedActionPlannerWelcomeItem,
    viewState,
    isPublishChecked,
    formFieldsInEdit,
    actionPlannerWelcomeItemFormMethods,
  ]);

  return (
    <IndexContentLayout
      indexSection={
        <>
          <SimpleCmsNavHeader title="Action Planner CMS" onAddItem={createNewItem} />
          <CmsNavigation
            selectedNavItemId={selectedActionPlannerWelcomeItem?.id}
            navItemList={navItemList}
            onNavItemSelected={selectedItemChanged}
          />
        </>
      }
      contentSection={
        selectedActionPlannerWelcomeItem && (
          <ActionPlannerWelcomeItemViewer
            actionPlannerWelcomeItem={selectedActionPlannerWelcomeItem}
            actionPlannerWelcomeItemFormMethods={actionPlannerWelcomeItemFormMethods}
            templateControlFormMethods={templateControlFormMethods}
            formFieldsInEdit={formFieldsInEdit}
            handleTemplateContextChange={handleTemplateContextChange}
            handleGlobalVariablesChange={handleGlobalVariablesChange}
            viewState={viewState}
            templateContext={templateContext}
            globalVariables={globalVariables}
          />
        )
      }
      optionsSection={
        selectedActionPlannerWelcomeItem && (
          <CmsItemSettings
            cmsItem={actionPlannerWelcomeItemToCmsItem(selectedActionPlannerWelcomeItem)}
            viewState={viewState}
            isPublishedChecked={isPublishChecked}
            setIsPublishChecked={setIsPublishChecked}
            onSubmit={submitItem}
            onEdit={handleEditItem}
            onPublish={handlePublishItem}
            onCancel={handleCancel}
            onTogglePreview={togglePreview}
            onDelete={handleDeleteItem}
          />
        )
      }
    />
  );
};

function actionPlannerWelcomeItemToCmsItem(
  actionPlannerWelcomeItem: ActionPlannerWelcomeItem
): CmsItem {
  return {
    id: actionPlannerWelcomeItem.id,
    isPublished: actionPlannerWelcomeItem.isPublished,
    title: actionPlannerWelcomeItem.content.title,
    label: '',
  };
}
