import * as React from 'react';
import {useUIDSeed} from 'react-uid';

import {
  ActionsCell,
  EmailCell,
  HeaderCheckboxCell,
  NameCell,
  OverflowCell,
} from '>components/dataTable/cellStyles';
import {
  DateCell,
  DepartmentCell,
  LocationCell,
  StatusCell,
  UserSummaryCell,
} from '>components/employeeTable/employeeRowCells';
import {AppliedEmployeeFilters, Filters, SelectFilter} from './manageAppliedFilters';
import {CheckBox, CheckboxSizes} from '>shared/components/form/checkBox';
import {Container} from '../adminEmployeesPage.styles';
import {DataTableRowContainer} from '>components/dataTable/dataTableRow';
import {EmployeeFilterLabel, filterContainer, stickyTableHeader} from './employeesFilter.styles';
import {EmployeeSortField, SortDirection} from '>generated/dvp.types';
import {SortButtonDirection} from '>shared/components/data/sortButton';
import {Tags} from './employeesFilterModal';
import {buildSortedParams, useSortSelect} from './manageSortedFilter';
import {useSkipFirstRender} from '>lib/util';

interface FilterSectionProps extends SelectFilter<keyof Tags> {
  onFilterUpdate(callback: (filters: Filters) => Filters): void;
  filters: Filters;
  onSelectAll?(selectAll: boolean): void;
  isSelectAll?: boolean;
}

export const FilterSection: React.FC<FilterSectionProps> = ({
  onFilterUpdate,
  filters,
  onSelectAll,
  appliedTags,
  removeFromField,
  isSelectAll,
}) => {
  const {sortedField, updateSortedField, getSortDirection} = useSortSelect<EmployeeSortField>(
    filters.sort,
    filters.sortDirection && filters.sortDirection === SortDirection.Ascending
      ? SortButtonDirection.Ascending
      : SortButtonDirection.Descending
  );

  useSkipFirstRender(() => {
    onFilterUpdate((filters) => ({
      ...filters,
      ...buildSortedParams(sortedField),
    }));
  }, [sortedField, onFilterUpdate]);

  const seed = useUIDSeed();

  return (
    <Container as="thead" css={stickyTableHeader}>
      {appliedTags.length > 0 ? (
        <tr>
          <th role="rowgroup" colSpan={11}>
            <AppliedEmployeeFilters appliedTags={appliedTags} onRemoveTag={removeFromField} />
          </th>
        </tr>
      ) : null}

      <DataTableRowContainer css={filterContainer}>
        <HeaderCheckboxCell>
          <CheckBox
            label="Select All"
            hideLabel
            onChange={onSelectAll ?? (() => {})}
            checked={isSelectAll}
            size={CheckboxSizes.Small}
          />
        </HeaderCheckboxCell>
        <NameCell>
          <EmployeeFilterLabel
            label="Last Name"
            labelFor={seed('lastName')}
            sortable
            sortDirection={getSortDirection(EmployeeSortField.LastName)}
            onClick={updateSortedField(EmployeeSortField.LastName)}
          />
        </NameCell>
        <NameCell>
          <EmployeeFilterLabel
            label="First Name"
            labelFor={seed('firstName')}
            sortable
            sortDirection={getSortDirection(EmployeeSortField.FirstName)}
            onClick={updateSortedField(EmployeeSortField.FirstName)}
          />
        </NameCell>
        <EmailCell>
          <EmployeeFilterLabel
            label="Email"
            labelFor={seed('email')}
            sortable
            sortDirection={getSortDirection(EmployeeSortField.Email)}
            onClick={updateSortedField(EmployeeSortField.Email)}
          />
        </EmailCell>
        <UserSummaryCell>
          <EmployeeFilterLabel
            label="Job Title"
            labelFor={seed('jobTitle')}
            sortable
            sortDirection={getSortDirection(EmployeeSortField.JobTitle)}
            onClick={updateSortedField(EmployeeSortField.JobTitle)}
          />
        </UserSummaryCell>
        <DepartmentCell>
          <EmployeeFilterLabel
            label="Department"
            labelFor={seed('department')}
            sortable
            sortDirection={getSortDirection(EmployeeSortField.Department)}
            onClick={updateSortedField(EmployeeSortField.Department)}
          />
        </DepartmentCell>
        <LocationCell>
          <EmployeeFilterLabel
            label="Location"
            labelFor={seed('location')}
            sortable
            sortDirection={getSortDirection(EmployeeSortField.Location)}
            onClick={updateSortedField(EmployeeSortField.Location)}
          />
        </LocationCell>
        <DateCell>
          <EmployeeFilterLabel
            label="Sent"
            labelFor={seed('date')}
            sortable
            sortDirection={getSortDirection(EmployeeSortField.DateOfLastAssessmentSentDate)}
            onClick={updateSortedField(EmployeeSortField.DateOfLastAssessmentSentDate)}
          />
        </DateCell>
        <StatusCell>
          <EmployeeFilterLabel
            label="Status"
            labelFor={seed('assessmentStatus')}
            sortable
            sortDirection={getSortDirection(EmployeeSortField.LastAssessmentStatus)}
            onClick={updateSortedField(EmployeeSortField.LastAssessmentStatus)}
          />
        </StatusCell>
        <ActionsCell>
          <EmployeeFilterLabel label="Actions" labelFor="" />
        </ActionsCell>
        <OverflowCell />
      </DataTableRowContainer>
    </Container>
  );
};
