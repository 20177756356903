import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {Button} from '>shared/components/button/button';
import {SelectContainer} from '>shared/components/form/rawControls/rawSelect.styles';
import {Select} from '>shared/components/form/select';
import * as colors from 'wnd-themes/lib/colorPalette';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import {baseDropShadow} from '>styles/mixins/shadows';
import {
  textMdMedium,
  textXlBold,
  textXsRegular,
} from '>shared/components/typography/designSystemTypography';
import {mL4} from '>styles/mixins/margins';
import {vr6} from '>styles/mixins/verticalRhythm';
import {Heading} from '>shared/components/heading/heading';

export const IndexHeader = styled.div`

  padding: 4rem 1.6rem 0 1.6rem;
`;

export const CmsHeading = styled(Heading)`
  ${vr6}
  ${mL4}
  ${textXlBold}
`;

export const CreateMeasureButton = styled(Button)`
  width: 4.8rem;
  height: 4.8rem;
  margin-left: 0.4rem;
`;

export const CreateMeasureContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ChapterSelectDropdown = styled(Select)`
  flex-grow: 1;

  ${SelectContainer} {
    height: 4.8rem;
  }

  .react-select__single-value {
    ${textMdMedium};
  }
`;

export const List = styled.div`
  overflow-y: auto;
  padding: 0 1.6rem;
`;

const activeStyle = css`
  background: ${colors.char00};
  border: 0.1rem solid ${colors.char100};
  ${baseDropShadow};
`;

export const ItemContainer = styled.div<{isActive?: boolean}>`
  text-decoration: none;
  color: ${colors.char600};
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: clip;

  height: 4.8rem;
  padding: 0 1.2rem;
  margin: 0.4rem 0;

  border-radius: 0.8rem;
  border: 0.1rem solid transparent;

  &:hover {
    ${baseDropShadow};
  }

  ${(props) => (props.isActive ? activeStyle : '')}
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const ItemTitle = styled(Paragraph)`
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const ItemLabel = styled.label`
  ${textXsRegular};
  color: ${colors.char400};
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const HomePageItemNav = styled.div`
  display: flex;
  justify-content: space-between;
`;
