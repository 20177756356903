import {UserRole} from '>generated/dvp.types';
import {checkUserContentManagementAccess} from '>lib/user';
import {RouteLabel, RoutePath} from '>root/routes/routes';
import * as Icons from '>shared/components/icon/icons';
import {SideBarItemProps, SideBarItemType} from '>shared/components/sideBar/sideBar';

export interface SideBarMenuItemOptions {
  canManageUsers?: boolean;
  role?: UserRole;
  isNonArchivedEmployee?: boolean;
  isImpersonatingEmployee?: boolean;
  isTeamMember?: boolean;
  isTeamReportEnabled?: boolean;
  hasAccessibleTeams?: boolean;
}

function appendItemsWithContainer(
  name: string,
  existingItems: SideBarItemProps[],
  newItems: SideBarItemProps[],
  isFirstSection?: boolean
): void {
  if (!isFirstSection && existingItems.length > 0) {
    existingItems.push({
      id: `${name}-pages-divider`,
      type: SideBarItemType.divider,
    });
  }

  existingItems.push(...newItems);
}

export function getUserSideBarMenuItems({
  canManageUsers,
  role,
  isNonArchivedEmployee,
  isImpersonatingEmployee,
  isTeamMember,
  isTeamReportEnabled,
  hasAccessibleTeams,
}: SideBarMenuItemOptions) {
  const allSideBarItems: SideBarItemProps[] = [];

  const canSeeAdminTabs =
    role &&
    [UserRole.Owner, UserRole.AccountAdmin, UserRole.TeamAdmin].includes(role) &&
    !isImpersonatingEmployee;

  const canSeeEmployeeTabs = isNonArchivedEmployee;
  const canSeeTeamTabs = isTeamMember && isTeamReportEnabled;

  // CMS Section
  //----------------------------------------------------------------

  if (checkUserContentManagementAccess()) {
    const cmsItems: SideBarItemProps[] = [
      {
        id: 'content-management-home',
        type: SideBarItemType.navItem,
        item: {
          path: RoutePath.InternalContentManagementForHome,
          icon: Icons.homeIcon,
          label: RouteLabel.InternalContentManagementForHome,
        },
        qaDataAttribute: 'content-management-home',
      },
      {
        id: 'content-management-results',
        type: SideBarItemType.navItem,
        item: {
          path: `${RoutePath.InternalContentManagementForResults}`,
          icon: Icons.collectionsBookmark,
          label: RouteLabel.InternalContentManagementForResults,
        },
        qaDataAttribute: 'content-management-results',
      },
      {
        id: 'content-management-action-items',
        type: SideBarItemType.navItem,
        item: {
          path: `${RoutePath.InternalContentManagementForActionItems}`,
          icon: Icons.bulletedList,
          label: RouteLabel.InternalContentManagementForActionItems,
        },
        qaDataAttribute: 'content-management-action-items',
      },
      {
        id: 'content-management-action-planner-welcome',
        type: SideBarItemType.navItem,
        item: {
          path: `${RoutePath.InternalContentManagementForActionPlannerWelcome}`,
          icon: Icons.factCheckIcon,
          label: RouteLabel.InternalContentManagementForActionPlannerWelcome,
        },
        qaDataAttribute: 'content-management-action-planner-welcome',
      },
      {
        id: 'content-management-team-report',
        type: SideBarItemType.navItem,
        item: {
          path: `${RoutePath.InternalContentManagementForTeamReport}`,
          icon: Icons.team,
          label: RouteLabel.InternalContentManagementForTeamReport,
        },
        qaDataAttribute: 'content-management-team-report',
      },
    ];

    appendItemsWithContainer('cms', allSideBarItems, cmsItems, true);
  }

  // Coaching Section
  //----------------------------------------------------------------

  if (role === UserRole.Coach && !isImpersonatingEmployee) {
    const coachingItems: SideBarItemProps[] = [
      {
        id: 'coaching',
        type: SideBarItemType.navItem,
        item: {
          path: RoutePath.Coaching,
          icon: Icons.folderSharedIcon,
          label: RouteLabel.Coaching,
        },
        qaDataAttribute: 'coaching',
      },
    ];

    appendItemsWithContainer('coaching', allSideBarItems, coachingItems);
  }

  // Admin Section
  //----------------------------------------------------------------

  const adminItems: SideBarItemProps[] = [];

  if (canSeeAdminTabs) {
    adminItems.push({
      id: 'employees',
      type: SideBarItemType.navItem,
      item: {
        path: RoutePath.AdminEmployees,
        icon: Icons.peopleFilledIcon,
        label: RouteLabel.AdminEmployees,
      },
      qaDataAttribute: 'employees',
    });

    if (canManageUsers && !isImpersonatingEmployee) {
      adminItems.push({
        id: 'user-management',
        type: SideBarItemType.navItem,
        item: {
          path: RoutePath.AdminUserManagement,
          icon: Icons.manageAccountIcon,
          label: RouteLabel.AdminUserManagement,
        },
        qaDataAttribute: 'user-management',
      });
    }
  }

  if (isTeamReportEnabled && (canSeeAdminTabs || hasAccessibleTeams)) {
    adminItems.push({
      id: 'team-management',
      type: SideBarItemType.navItem,
      item: {
        path: RoutePath.AdminTeamManagement,
        icon: Icons.teamAdmin,
        label: RouteLabel.AdminTeamManagement,
      },
      qaDataAttribute: 'team-management',
    });
  }

  appendItemsWithContainer('admin', allSideBarItems, adminItems);

  // Employee Section
  //----------------------------------------------------------------

  if (canSeeEmployeeTabs) {
    const employeeItems: SideBarItemProps[] = [
      {
        id: 'my-home',
        type: SideBarItemType.navItem,
        item: {
          path: RoutePath.EmployeeHome,
          icon: Icons.homeIcon,
          label: RouteLabel.EmployeeHome,
        },
        qaDataAttribute: 'my-home',
      },
      {
        id: 'develop-index-page',
        type: SideBarItemType.navItem,
        item: {
          path: RoutePath.EmployeeDevelopIndex,
          icon: Icons.compass,
          label: RouteLabel.EmployeeDevelopIndex,
          smallIcon: true,
        },
        qaDataAttribute: 'develop-index-page',
      },
      {
        id: 'results-page',
        type: SideBarItemType.navItem,
        item: {
          path: `${RoutePath.EmployeeResults}:1`,
          pathMatcher: new RegExp(`${RoutePath.EmployeeResults}\\d+`),
          icon: Icons.collectionsBookmark,
          label: RouteLabel.EmployeeResults,
          smallIcon: true,
        },
        qaDataAttribute: 'results-page',
      },
      {
        id: 'develop-action-planner',
        type: SideBarItemType.navItem,
        item: {
          path: RoutePath.EmployeeActionPlanner,
          icon: Icons.factCheckIcon,
          label: RouteLabel.EmployeeActionPlanner,
          smallIcon: true,
        },
        qaDataAttribute: 'develop-index-page',
      },
    ];

    appendItemsWithContainer('employee', allSideBarItems, employeeItems);
  }

  // Team Section
  //----------------------------------------------------------------

  if (canSeeTeamTabs) {
    const teamItems: SideBarItemProps[] = [];

    if (isTeamMember) {
      teamItems.push({
        id: 'team-insights',
        type: SideBarItemType.navItem,
        item: {
          path: RoutePath.TeamInsights,
          icon: Icons.team,
          label: RouteLabel.TeamInsights,
        },
        qaDataAttribute: 'team-insights',
      });
    }

    appendItemsWithContainer('team', allSideBarItems, teamItems);
  }

  //----------------------------------------------------------------

  // Mobile end-cap
  allSideBarItems.push({
    id: 'apps-divider',
    type: SideBarItemType.divider,
    displayForMobileOnly: true,
  });

  return allSideBarItems;
}
