import {useLocation} from 'react-router-dom';
import {assertValidAuthState} from '>lib/assert';
import {clearAndLogout, clearAndUnimpersonate} from '>root/store/actions/auth';
import {useAppDispatch} from '>root/store/main';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {LoadingScreen} from '>shared/components/loadingScreen';

export const LogoutPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const authState = useSelector((state) => state.auth);

  React.useEffect(() => {
    assertValidAuthState(authState);
    if (authState.isImpersonated) {
      dispatch(clearAndUnimpersonate());
    } else {
      const redirectUri = new URLSearchParams(location.search).get('redirect_uri');
      console.log(redirectUri);
      dispatch(clearAndLogout(redirectUri ?? undefined));
    }
  }, []);

  return <LoadingScreen />;
};
