import {createReducer} from '@reduxjs/toolkit';

import {ActionItem, ActionPlannerWelcomeItem, User} from '>generated/dvp.types';
import {
  createActionItem,
  updateActionItem,
  deleteActionItem,
  getActionItems,
  getActionPlannerWelcomeItems,
} from '../actions/actionPlanner';
import {impersonateEmployee} from '../actions/employee';

export interface ActionPlannerState {
  actionPlannerWelcomeItems?: ActionPlannerWelcomeItem[];
  actionItems?: ActionItem[];
}

const DEFAULT_STATE: Readonly<ActionPlannerState> = {};

function updateActionPlannerWelcomeItems(
  state: ActionPlannerState,
  {payload}: {payload: ActionPlannerWelcomeItem[]}
): ActionPlannerState {
  return {
    ...state,
    actionPlannerWelcomeItems: payload,
  };
}

function clearActionItems(
  state: ActionPlannerState,
  {payload}: {payload: User}
): ActionPlannerState {
  return {
    ...state,
    actionItems: undefined,
  };
}

function updateActionItems(
  state: ActionPlannerState,
  {payload}: {payload: ActionItem[]}
): ActionPlannerState {
  return {
    ...state,
    actionItems: payload,
  };
}

export const actionPlannerReducer = createReducer<ActionPlannerState>(DEFAULT_STATE, (builder) => {
  builder
    .addCase(getActionPlannerWelcomeItems.fulfilled, updateActionPlannerWelcomeItems)
    .addCase(getActionItems.fulfilled, updateActionItems)
    .addCase(createActionItem.fulfilled, updateActionItems)
    .addCase(deleteActionItem.fulfilled, updateActionItems)
    .addCase(updateActionItem.fulfilled, updateActionItems)
    .addCase(impersonateEmployee.fulfilled, clearActionItems);
});
