"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconButtonType = exports.IconButtonSize = exports.IconButton = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var colors = _interopRequireWildcard(require("wnd-themes/lib/colorPalette"));
var _icon = require("../icon/icon");
var _iconButton = require("./iconButton.styles");
const _excluded = ["buttonLabel", "icon", "onClick", "variant", "size", "disabled", "children"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var IconButtonType;
(function (IconButtonType) {
  IconButtonType["DEFAULT"] = "default";
  IconButtonType["NAV"] = "nav";
})(IconButtonType || (exports.IconButtonType = IconButtonType = {}));
var IconButtonSize;
(function (IconButtonSize) {
  IconButtonSize["SM"] = "small";
  IconButtonSize["MD"] = "medium";
  IconButtonSize["LG"] = "large";
})(IconButtonSize || (exports.IconButtonSize = IconButtonSize = {}));
const IconButton = _ref => {
  let {
      buttonLabel,
      icon,
      onClick,
      variant = IconButtonType.DEFAULT,
      size = IconButtonSize.MD,
      disabled = false,
      children
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  const iconColor = !disabled ? colors.gray500 : colors.gray300;
  return (0, _jsxRuntime.jsxs)(_iconButton.DefaultIconButton, _objectSpread(_objectSpread({
    className: "button-icon",
    "aria-label": buttonLabel,
    onClick: onClick,
    type: "button",
    size: size,
    disabled: disabled,
    variant: variant
  }, rest), {}, {
    children: [icon && (0, _jsxRuntime.jsx)(_icon.Icon, {
      src: icon,
      primaryColor: iconColor,
      hoverColor: iconColor
    }), children]
  }));
};
exports.IconButton = IconButton;