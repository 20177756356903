import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {FormBuilder} from '>shared/components/form/formBuilder';
import {buildIntroductionFormFields} from './formBuilder';

export interface IntroductionFormFields {
  introduction: string;
  instructions: string;
}

export const IntroductionFormFields: React.FC = () => {
  const {register} = useFormContext<IntroductionFormFields>();
  const introductionFormFields = buildIntroductionFormFields(register);

  return <FormBuilder fields={introductionFormFields} />;
};
