import * as React from 'react';

import {Action, ActionType, Modal} from '>shared/components/modal/modal';
import {useModal} from '>shared/components/modal/useModal';
import styled from '@emotion/styled';
import * as colorPalette from 'wnd-themes/lib/colorPalette';
import {Button, ButtonType} from '>shared/components/button/button';

interface Props {
  itemTitle: string;
  deleteAction: () => Promise<void>;
}

function buildDeleteModalAction(
  handleSubmitAndClose: React.ReactEventHandler,
  handleSubmitAndClear: React.ReactEventHandler,
  isDisabled: boolean
): Action[] {
  return [
    {
      type: ActionType.Primary,
      label: 'Delete',
      inputType: 'submit',
      disabled: isDisabled,
      onClick: handleSubmitAndClose,
      qaDataAttribute: 'delete-button',
      component: (
        <Button
          buttonType={ButtonType.SuperDestructive}
          disabled={isDisabled}
          onClick={handleSubmitAndClose}
          data-qa-button="delete-button"
        >
          Delete
        </Button>
      ),
    },
    {
      type: ActionType.Secondary,
      label: 'Cancel',
      inputType: 'button',
      disabled: isDisabled,
      onClick: handleSubmitAndClear,
      qaDataAttribute: 'cancel-button',
      component: (
        <Button
          buttonType={ButtonType.Ghost}
          disabled={isDisabled}
          onClick={handleSubmitAndClear}
          data-qa-button="cancel-button"
        >
          Cancel
        </Button>
      ),
    },
  ];
}

const DeleteText = styled.div`
  font-size: 1.6rem;
  color: ${colorPalette.char600};
`;

export const DeleteItemModal: React.FC<Props> = ({deleteAction, itemTitle}) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const {hideModal} = useModal();

  const deleteAndClose = async () => {
    setIsDeleting(true);
    await deleteAction();
    setIsDeleting(false);
    hideModal();
  };

  const modalActions = buildDeleteModalAction(deleteAndClose, hideModal, isDeleting);
  return (
    <Modal
      headerOptions={{
        title: 'You’re about to delete this content',
        disabled: false,
      }}
      actions={modalActions}
      isLocked={false}
      actionsCentered={true}
      actionBackgroundColor={colorPalette.char00}
    >
      <DeleteText>
        Are you sure you want to delete "<b>{itemTitle}</b>”? This action can’t be undone.
      </DeleteText>
    </Modal>
  );
};
