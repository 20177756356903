import styled from '@emotion/styled';

import {TextCell} from '>components/dataTable/cellStyles';
import {TextContent} from '>components/dataTable/textContent';

export const PendingCell = styled(TextCell)``;

export const JobTitleData = styled(TextContent)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DepartmentCell = styled(TextCell)`
  min-width: 13rem;
  max-width: 13rem;
`;

export const StatusCell = styled(TextCell)`
  min-width: 14rem;
  max-width: 14rem;
`;

export const DateCell = styled(TextCell)`
  min-width: 12rem;
  max-width: 12rem;
`;

export const LocationCell = styled(TextCell)`
  min-width: 12rem;
  max-width: 12rem;
`;

export const UserSummaryCell = styled(TextCell)`
  min-width: 16rem;
  max-width: 16rem;
`;
