import * as React from 'react';
import {renderTypologyDiamond, TypologyDiamondProps, SVGSize} from 'wnd-dvp-reports';
import {SVGContainer, getGraphicMeasurements} from '>lib/visualizations';

export const TypologyDiamond: React.FC<{size: SVGSize} & TypologyDiamondProps> = ({
  active,
  domain,
  size,
  typology,
}) => {
  const {width, height} = getGraphicMeasurements(size);
  const svgText = renderTypologyDiamond({active, domain, typology});

  return (
    <SVGContainer
      containerWidth={width}
      containerHeight={height}
      dangerouslySetInnerHTML={{__html: svgText}}
    ></SVGContainer>
  );
};
