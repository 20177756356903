import React from 'react';
import styled from '@emotion/styled';

import {Card} from '>shared/components/cards/card';
import {ActionItem} from '>generated/dvp.types';

import moment from 'moment';
import {actionItemColors, ColoredDot} from './form/formOptions';

import * as colors from 'wnd-themes/lib/colorPalette';

export type ActionItemCardClickEvent = (actionItem: ActionItem) => void;
export type TemplateAttributeCardClickEvent = (attribute: string) => void;

interface CardPropsBase {
  selected: boolean;
}

interface ActionItemCardProps extends CardPropsBase {
  actionItem: ActionItem;
  onClick: ActionItemCardClickEvent;
}

interface TemplateAttributeCardProps extends CardPropsBase {
  attribute: string;
  onClick: TemplateAttributeCardClickEvent;
}

enum NavCardType {
  ActionItem,
  Attribute,
}

interface ActionPlannerNavCardContainerProps {
  selected: boolean;
  hover: boolean;
  type: NavCardType;
}

const ActionPlannerNavCardContainer = styled(Card)<ActionPlannerNavCardContainerProps>`
  width: 100%;
  margin: 0 0 0.8rem 0;
  padding: ${(props) => (props.type === NavCardType.ActionItem ? '0.8rem 0' : '1.2rem 1.6rem')};
  border-radius: 0.8rem;
  height: ${(props) => (props.type === NavCardType.ActionItem ? '5.6rem' : 'auto')};
  display: flex;
  flex-direction: column;
  cursor: pointer;

  background-color: ${(props) => (props.selected || props.hover ? colors.gray50 : colors.white)};
  border: 0.1rem solid
    ${(props) => (props.selected || props.hover ? colors.gray300 : colors.gray200)};
`;

const TopLineContainer = styled.div`
  display: flex;
`;

const ItemTitle = styled.p`
  color: ${colors.gray700};
  font-size: 1.4rem;
`;

const BottomLineContainer = styled.div`
  color: ${colors.gray500};
  font-size: 1.2rem;
  width: calc(100% - 3.6rem);
  margin: 0.4rem 0 0 3.2rem;
`;

const StyledColoredDot = styled(ColoredDot)`
  margin: 0.2rem 1rem;
  height: 1.2rem;
  width: 1.2rem;
  vertical-align: baseline;
`;

const MAX_TITLE_LENGTH = 30;

function truncateTitle(text: string): string {
  if (text.length > MAX_TITLE_LENGTH) {
    return `${text.substring(0, MAX_TITLE_LENGTH)}...`;
  }

  return text;
}

export const ActionItemCard: React.FC<ActionItemCardProps> = ({actionItem, onClick, selected}) => {
  const [hover, setHover] = React.useState(false);
  const actionItemColor = actionItemColors.find((color) => color.name === actionItem.color);
  const displayColor = actionItemColor
    ? selected || hover
      ? actionItemColor.color
      : actionItemColor.lightColor
    : 'transparent';

  return (
    <ActionPlannerNavCardContainer
      hover={hover}
      selected={selected}
      type={NavCardType.ActionItem}
      onClick={() => onClick(actionItem)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <TopLineContainer>
        <StyledColoredDot color={displayColor} />

        <ItemTitle>{truncateTitle(actionItem.title)}</ItemTitle>
      </TopLineContainer>
      <BottomLineContainer>
        <span>{actionItem.attribute}</span>
        {actionItem.dueDate && (
          <span css={{float: 'right', marginRight: '0.8rem'}}>
            {moment(actionItem.dueDate).format('MMM. DD, YYYY')}
          </span>
        )}
      </BottomLineContainer>
    </ActionPlannerNavCardContainer>
  );
};

export const TemplateAttributeCard: React.FC<TemplateAttributeCardProps> = ({
  attribute,
  onClick,
  selected,
}) => {
  const [hover, setHover] = React.useState(false);

  return (
    <ActionPlannerNavCardContainer
      hover={hover}
      selected={selected}
      type={NavCardType.Attribute}
      onClick={() => onClick(attribute)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <TopLineContainer>
        <ItemTitle>{truncateTitle(attribute)}</ItemTitle>
      </TopLineContainer>
    </ActionPlannerNavCardContainer>
  );
};
