import * as React from 'react';
import {vr6} from '>styles/mixins/verticalRhythm';
import {MeasureFormFields} from './formFields';
import type {SourceConstruct} from 'wnd-dvp-reports';
import {ChapterCategory} from 'wnd-dvp-reports';
import {FormProvider, UseFormMethods} from 'react-hook-form';
import { Heading } from '>shared/components/heading/heading';
import { displayXsMedium } from '>shared/components/typography/designSystemTypography';

export const defaultMeasureFormValues: MeasureFormFields = {
  sourceConstruct: '' as SourceConstruct,
  category: '' as ChapterCategory,
  baseName: '',
  displayTitles: '',
  synonym1: '',
  synonym2: '',
  synonym3: '',
  definition: '',
  bodyContent: '',
  actionItems: '',
  scoringFloor: 0
};

interface Props {
  title: string;
  formMethods: UseFormMethods<MeasureFormFields>;
}

export const MeasureForm: React.FC<Props> = ({title, formMethods}) => {
  return (
    <>
      <Heading css={[vr6, displayXsMedium]}>{title}</Heading>
      <FormProvider {...formMethods}>
        <MeasureFormFields />
      </FormProvider>
    </>
  );
};
