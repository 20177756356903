import {rootReducer} from './reducers/main';
import {AsyncThunkAction, configureStore, PayloadAction, SerializedError} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {ThunkApiConfig} from './thunk';
import {registerSideEffects} from './sideEffects/main';

function buildStore() {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
  });

  registerSideEffects(store);

  return store;
}

export const store: any = buildStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof buildStore>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

function isValidResponse<Returned, Meta, Error>(
  response: PayloadAction<Returned, string, Meta, never> | PayloadAction<void, string, Meta, Error>
): response is PayloadAction<Returned, string, Meta, never> {
  return !response.type.endsWith('/rejected');
}

export function useAsyncDispatch() {
  const dispatch = useAppDispatch();

  return async function<Returned, ThunkArg, Meta>(
    asyncThunkAction: AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>
  ): Promise<PayloadAction<Returned, string, Meta, never>> {
    const response:
      | PayloadAction<Returned, string, Meta, never>
      | PayloadAction<void, string, Meta, SerializedError> = (await dispatch(
      asyncThunkAction
    )) as any;

    if (isValidResponse(response)) {
      return response;
    }

    throw response.payload;
  };
}

declare module 'react-redux' {
  export interface DefaultRootState extends ReturnType<typeof rootReducer> {}
}
