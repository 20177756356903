import * as React from 'react';
import styled from '@emotion/styled';
import {Heading, Section} from '>shared/components/heading/heading';
import {
  PageLayoutComponents,
  ResponsiveLayout,
} from '>shared/components/layouts/responsive/responsiveLayout';
import {MobileSectionHeader} from '>shared/components/landmarks/header/mobileSectionHeader';
import {mediaQueries, respondTo} from '>shared/styles/breakpoints';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import * as colors from 'wnd-themes/lib/colorPalette';
import {displayXsSemibold} from '>shared/components/typography/designSystemTypography';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {Button, ButtonType} from '>shared/components/button/button';
import {ActionItemEditor} from '>components/actionPlanner/actionItemEditor';
import {ActionItemReader} from '>components/actionPlanner/actionItemReader';

import {ActionItem, ActionItemWidgetParams, NewActionItem} from '>generated/dvp.types';

import {dvpApi} from '>root/apis';
import {
  Navigation,
  ActionItemView,
  navPadding,
  isSelectionAnActionItem,
  isSelectionAttribute,
  verifyIsAttribute,
  getViewByStatus,
  ActionItemNavSelection,
  verifyIsNotAttribute,
} from '>components/actionPlanner/navigation';

import {LeftNavSize} from '>shared/components/layouts/responsive/responsiveLayout.styles';
import {Select} from '>shared/components/form/select';

import {HEADER_HEIGHT} from '>shared/components/landmarks/header/header.styles';
import {ActionPlannerWelcomeItems} from './welcomeItems';

import * as Icons from '>shared/components/icon/icons';
import {downloadPdf} from '>lib/pdf';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {API_URL} from '>root/env';
import {MobileOverflowMenu} from '>components/mobileOverflowMenu';
import {useAsyncDispatch} from '>root/store/main';
import {deleteActionItem, getActionItems} from '>root/store/actions/actionPlanner';
import {Spinner} from '>shared/components/spinner';
import {redirectToErrorPage} from '>lib/redirect';
import {rollbarLogger} from '>lib/logger';
import {useModal} from '>shared/components/modal/useModal';
import {
  ActionItemDestructiveModal,
  ActionItemModalType,
} from '>root/components/actionPlanner/actionItemModals';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {assert, assertExists} from 'wnd-util/lib/assert';
import {ActionItemWidget} from '>components/widgets/actionItemWidget/actionItemWidget';
import {assertEmployeeExists} from '>lib/assert';
import {LoadingScreen} from '>shared/components/loadingScreen';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import {ActionItemWidgetVariant} from 'wnd-dvp-reports';

enum ActionPlannerContentActionType {
  Home,
  Read,
  Edit,
  Recommendations,
}

export type FilterStatus = {label: string; value: string};

const IndexSection = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - ${HEADER_HEIGHT});
  margin: 0 auto;

  ${respondTo.mediumOrHigher} {
    border-right: 0.1rem solid ${colors.gray200};
  }
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${navPadding};
  ${respondTo.smallOrLower} {
    padding: ${navPadding} 0 ${navPadding} 0;
  }
`;

const StatusFilterSelect = styled(Select)`
  padding: ${navPadding};
  ${respondTo.smallOrLower} {
    padding: ${navPadding} 0 ${navPadding} 0;
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;

  ${respondTo.smallOrLower} {
    flex-flow: row wrap;
  }
`;

const ContentSection = styled.div`
  padding: 4rem;
  overflow-y: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
  flex-grow: 1;
  padding-top: 3.2rem;

  ::-webkit-scrollbar {
    display: none;
  }

  ${respondTo.smallOrLower} {
    padding: 2.4rem;
  }
`;

const viewOptions = [
  ActionItemView.Open,
  ActionItemView.Completed,
  ActionItemView.Archived,
  ActionItemView.RecommendedTemplates,
].map((v) => ({label: v, value: v}));

function findActionItemById(
  actionItemId?: string,
  actionItems?: ActionItem[]
): ActionItem | undefined {
  if (!actionItemId) {
    return;
  }

  return actionItems?.find((i) => i.id === actionItemId);
}

function handleError(err: Error) {
  rollbarLogger.error(err);
  redirectToErrorPage(err);
}

export const ActionPlanner: React.FC = () => {
  const {setHiddenComponent, setIsHeaderHidden, hiddenComponent} = useResponsiveLayout();

  const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(true);
  const [isLoadingInnerContent, setIsLoadingInnerContent] = React.useState<boolean>(false);
  const [templateAttributes, setTemplateAttributes] = React.useState<string[]>();
  const [actionItemWidgetParams, setActionItemWidgetParams] = React.useState<
    ActionItemWidgetParams
  >();
  const [contentDisplayType, setContentDisplayType] = React.useState<
    ActionPlannerContentActionType
  >(ActionPlannerContentActionType.Home);

  const asyncDispatch = useAsyncDispatch();
  const {showModal} = useModal();
  const {showSuccessAlert} = useSnackbar();

  const actionItems = useSelector((state) => state.actionPlanner.actionItems);
  const employee = useSelector((state) => state.employee.employee);
  assertEmployeeExists(employee);

  const {search} = useLocation();
  const queryParams = new URLSearchParams(search);
  const queryActionItemId = queryParams.get('id') ?? undefined;

  // The user can select either an action item, or an attribute for action item templates
  const [selectedItem, setSelectedItem] = React.useState<ActionItemNavSelection>(
    findActionItemById(queryActionItemId, actionItems)
  );

  React.useEffect(() => {
    setIsLoadingPage(true);

    const loadPromises: Promise<void>[] = [];

    // Lazy-load the action items. These may have been loaded earlier from another page that needs them.
    if (!actionItems) {
      loadPromises.push(
        asyncDispatch(getActionItems()).then((result) => {
          // Users can link directly to a specific action item externally.
          if (queryActionItemId) {
            const actionItem = findActionItemById(queryActionItemId, result.payload);
            if (actionItem) {
              actionItemTileClicked(actionItem);
            }
          }
        })
      );
    }

    // Pre-load all options in case they select "Recommended" from the dropdown
    loadPromises.push(
      dvpApi.getActionItemAttributes().then((response) => {
        setTemplateAttributes(response.data);
      })
    );

    Promise.all(loadPromises)
      .then(() => {
        setIsLoadingPage(false);
      })
      .catch(handleError);
  }, []);

  // Open the action item in the query string only on initial load, if the action items are already pre-loaded.
  React.useEffect(() => {
    if (actionItems) {
      const actionItem = findActionItemById(queryActionItemId, actionItems);
      if (actionItem) {
        actionItemTileClicked(actionItem);
      }
    }
  }, []);

  const [view, setView] = React.useState<ActionItemView>(ActionItemView.Open);

  const updateSelection = (view: ActionItemView) => {
    if (view === ActionItemView.RecommendedTemplates) {
      // Selecting recommended should auto-select the first entry
      assertExists(templateAttributes, 'template attributes were not loaded');
      assert(templateAttributes.length > 0, 'no fitting template attributes found in the CMS');
      templateAttributeTileClicked(templateAttributes[0]);
    } else {
      // Every other view has a "home" screen and doesn't need to auto-select
      setSelectedItem(undefined);
      setContentDisplayType(ActionPlannerContentActionType.Home);
    }

    setView(view);
  };

  React.useEffect(() => {
    const isViewingChildPageOnMobile = hiddenComponent === PageLayoutComponents.Nav;
    setIsHeaderHidden(isViewingChildPageOnMobile);
  }, [hiddenComponent]);

  const hideBody = React.useCallback(() => {
    if (!window.matchMedia(mediaQueries.mediumOrHigher.media).matches) {
      setHiddenComponent(PageLayoutComponents.Body);
    }
  }, []);

  const hideNav = React.useCallback(() => {
    if (!window.matchMedia(mediaQueries.mediumOrHigher.media).matches) {
      setHiddenComponent(PageLayoutComponents.Nav);
    }
  }, []);

  const menuItems: MenuLinkItem[] = React.useMemo(() => {
    const items: MenuLinkItem[] = [];

    if (
      contentDisplayType === ActionPlannerContentActionType.Read &&
      !window.matchMedia(mediaQueries.mediumOrHigher.media).matches
    ) {
      items.push({
        id: 'edit',
        icon: Icons.editIcon,
        text: 'Edit',
        hoverStyle: true,
        action: () => {
          editActionItemHandler();
        },
      });
    }

    if (isSelectionAnActionItem(selectedItem) && selectedItem) {
      const actionItem = selectedItem as ActionItem;

      items.push({
        id: 'download',
        icon: Icons.downloadIcon,
        text: 'Download PDF',
        hoverStyle: true,
        action: async () => {
          const fileName = `Develop Action Item - ${actionItem.title}`;
          const pdfEndpoint = `${API_URL}/accounts/${actionItem.accountId}/employees/${actionItem.employeeId}/actionItems/${actionItem.id}/pdf`;
          downloadPdf(fileName, pdfEndpoint);
        },
      });
      items.push({
        id: 'delete',
        icon: Icons.deleteIcon,
        text: 'Delete',
        hoverStyle: true,
        action: async () => {
          showModal(
            <ActionItemDestructiveModal
              type={ActionItemModalType.DeleteActionItem}
              onYes={async () => {
                await asyncDispatch(deleteActionItem(actionItem.id));
                showSuccessAlert('Action item successfully deleted');
              }}
            />
          );
        },
      });
    }

    return items;
  }, [selectedItem, contentDisplayType]);

  const handleAddAction = React.useCallback(() => {
    setSelectedItem(undefined);
    setContentDisplayType(ActionPlannerContentActionType.Edit);
    hideNav();
  }, []);

  const onEditComplete = React.useCallback(
    async (actionItem?: NewActionItem) => {
      if (actionItem) {
        assert(!isSelectionAttribute(selectedItem), 'An attribute should not be selected');

        if (isSelectionAnActionItem(selectedItem)) {
          const selectedActionItem = selectedItem as ActionItem;
          const updatedCurrentActionItem = {id: selectedActionItem.id, ...actionItem};
          const response = await dvpApi.updateActionItem(updatedCurrentActionItem);
          setSelectedItem(response.data);
        } else {
          const response = await dvpApi.createActionItem(actionItem);
          setSelectedItem(response.data);
        }
      }
      await refreshActionItems();
      return setContentDisplayType(ActionPlannerContentActionType.Read);
    },
    [selectedItem]
  );

  const handleGoToActionItemFromRecommended = React.useCallback(
    (id: string) => {
      const actionItem = findActionItemById(id, actionItems);
      assertExists(actionItem, 'expected linked action item to exist');
      setView(getViewByStatus(actionItem.status));
      actionItemTileClicked(actionItem);
    },
    [actionItems]
  );

  React.useEffect(() => {
    if (contentDisplayType === ActionPlannerContentActionType.Home) {
      setSelectedItem(undefined);
    }
  }, [contentDisplayType]);

  const onReaderChange = React.useCallback(async () => {
    await refreshActionItems();
  }, []);

  const refreshActionItems = React.useCallback(async () => {
    await asyncDispatch(getActionItems());
  }, []);

  React.useEffect(() => {
    if (actionItems && isSelectionAnActionItem(selectedItem)) {
      const selectedActionItem = selectedItem as ActionItem;
      setSelectedItem(actionItems.find((actionItem) => actionItem.id === selectedActionItem.id));
    }
  }, [actionItems]);

  const editActionItemHandler = () => {
    setContentDisplayType(ActionPlannerContentActionType.Edit);
    hideNav();
  };
  const contents = {
    [ActionPlannerContentActionType.Home]: React.useCallback(
      () => <ActionPlannerWelcomeItems />,
      []
    ),
    [ActionPlannerContentActionType.Read]: React.useCallback(() => {
      if (isSelectionAnActionItem(selectedItem)) {
        const actionItem = selectedItem as ActionItem;
        return (
          <ActionItemReader
            actionItem={actionItem}
            menuItems={menuItems}
            onEdit={editActionItemHandler}
            onQuickChange={onReaderChange}
          />
        );
      }
      setContentDisplayType(ActionPlannerContentActionType.Home);
    }, [selectedItem]),
    [ActionPlannerContentActionType.Edit]: React.useCallback(() => {
      verifyIsNotAttribute(selectedItem);
      return <ActionItemEditor actionItem={selectedItem} onComplete={onEditComplete} />;
    }, [selectedItem]),
    [ActionPlannerContentActionType.Recommendations]: React.useCallback(() => {
      console.log('verifyIsAttribute on', selectedItem);
      verifyIsAttribute(selectedItem);

      if (isLoadingInnerContent) {
        return <LoadingScreen />;
      }

      assertExists(actionItemWidgetParams, 'actionItemWidget params were not loaded yet');

      if (actionItemWidgetParams.templates.length === 0) {
        return (
          <Paragraph>
            There are currently no recommended action items for the selected attribute.
          </Paragraph>
        );
      }

      return (
        <ActionItemWidget
          measureDisplayTitle={selectedItem}
          content={actionItemWidgetParams.content}
          templates={actionItemWidgetParams.templates}
          variant={ActionItemWidgetVariant.ActionPlanner}
          onGoToActionItem={handleGoToActionItemFromRecommended}
        />
      );
    }, [isLoadingInnerContent, selectedItem, actionItemWidgetParams]),
  };

  const actionItemTileClicked = React.useCallback((actionItem: ActionItem) => {
    setSelectedItem(actionItem);
    setContentDisplayType(ActionPlannerContentActionType.Read);
    hideNav();
  }, []);

  const templateAttributeTileClicked = React.useCallback((attribute: string) => {
    console.log('clicked', attribute);
    setIsLoadingInnerContent(true);

    console.log('setSelectedItem to', attribute);
    setSelectedItem(attribute);
    console.log('setContentDisplayType(ActionPlannerContentActionType.Recommendations)');
    setContentDisplayType(ActionPlannerContentActionType.Recommendations);
    hideNav();

    dvpApi
      .getEmployeeActionItemWidgetParams({
        accountId: employee.accountId,
        employeeId: employee.id,
        attribute,
      })
      .then((response) => {
        setActionItemWidgetParams(response.data);
        setIsLoadingInnerContent(false);
      })
      .catch(handleError);
  }, []);

  if (isLoadingPage) {
    return <LoadingScreen />;
  }

  assertExists(templateAttributes, 'templateAttributes were not loaded yet');
  assertExists(actionItems, 'actionItems were not loaded yet');

  const content = contents[contentDisplayType]();

  return (
    <Section>
      <ResponsiveLayout
        leftNavSize={LeftNavSize.wider}
        nav={
          <IndexSection>
            <HeaderSection>
              <Heading css={displayXsSemibold}>Action Planner</Heading>
              <Button
                buttonType={ButtonType.Primary}
                data-qa-button="add-action-planner-item-button"
                onClick={handleAddAction}
              >
                Add
              </Button>
            </HeaderSection>
            <StatusFilterSelect
              name="StatusFilter"
              isSearchable={false}
              options={viewOptions}
              css={{marginBottom: '1.6rem'}}
              value={viewOptions.find((o) => o.label === view)}
              onSelectChange={(option) => {
                option && updateSelection(option.value as ActionItemView);
              }}
            />
            <Navigation
              onActionItemCardClicked={actionItemTileClicked}
              onTemplateAttributeClicked={templateAttributeTileClicked}
              currentActionItemId={selectedItem}
              view={view}
              templateAttributes={templateAttributes}
            />
          </IndexSection>
        }
        main={
          <Main>
            <ContentSection>
              <MobileSectionHeader
                primaryHeader={true}
                onBackButtonClick={hideBody}
                sectionHeading={selectedItem ? 'Action Item' : 'Action Planner'}
                menuButton={
                  <MobileOverflowMenu
                    fromHeader={true}
                    menuItems={
                      contentDisplayType === ActionPlannerContentActionType.Read ? menuItems : []
                    }
                  />
                }
              />
              {content}
            </ContentSection>
          </Main>
        }
        observerAttribute="data-actionplanner"
      ></ResponsiveLayout>
    </Section>
  );
};
