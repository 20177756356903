import {useSelector} from 'react-redux';
import {isValidAuthState} from './auth';
import {AccountUser, UserRole} from '>generated/dvp.types';
import {assertValidAuthState} from './assert';
import {store} from '>root/store/main';

export function isOwner(user: AccountUser): boolean {
  return user.role === UserRole.Owner;
}

export function isAdmin(user: AccountUser): boolean {
  return [UserRole.Owner, UserRole.AccountAdmin].includes(user.role);
}

export function isCoachWithTeamAccess(user: AccountUser): boolean {
  return Boolean(user.accessibleTeams && user.accessibleTeams.length > 0);
}

export function canCoachAccessCurrentTeam(user: AccountUser, teamId: string): boolean {
  return Boolean(user.accessibleTeams && user.accessibleTeams.includes(teamId));
}

export function getLoggedInUserId() {
  const auth = useSelector((state) => state.auth);
  assertValidAuthState(auth);
  return auth.token.user.id;
}

export function canManageUsers(user: AccountUser): boolean {
  return Boolean(user.permissions.canManageUsers);
}

export const useAccountUser = () => {
  const auth = useSelector((state) => state.auth);
  const account = useSelector((state) => state.account.account);
  const accountUser = useSelector((state) => state.user.accountUser);

  if (!isValidAuthState(auth) || !account) {
    return;
  }

  return accountUser;
};

export function checkUserContentManagementAccess() {
  const authState = store.getState().auth;
  let toolkitRoles;

  if (isValidAuthState(authState)) {
    assertValidAuthState(authState);
    toolkitRoles = authState.toolkitRoles;
  }

  return (
    toolkitRoles?.wonscore === 'superAdmin' &&
    (toolkitRoles?.consulting === 'toolkitUser' || toolkitRoles?.consulting === 'toolkitAdmin')
  );
}

export function getInitials(firstName?: string, lastName?: string, preferredName?: string): string {
  const firstInitial = `${preferredName?.charAt(0) ?? firstName?.charAt(0) ?? ''}`;
  const lastInitial = `${lastName?.charAt(0) ?? ''}`;

  return `${firstInitial}${lastInitial}`.trim();
}
