import * as React from 'react';
import styled from '@emotion/styled';
import {assertExists} from 'wnd-util/lib/assert';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useUIDSeed} from 'react-uid';

import {
  FormBuilder,
  FormBuilderFields,
  FormBuilderFieldType,
} from '>shared/components/form/formBuilder';
import {CheckBox} from '>shared/components/form/checkBox';
import {DrawerForm} from '>shared/components/drawer/drawerForm';
import {Input} from '>shared/components/form/input';
import {mobileSidebarDrawOrder} from '>shared/styles/mixins/drawOrder';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';

import {assertAccountUserExists, assertValidAuthState} from '>lib/assert';
import {Employee, UserRole} from '>generated/dvp.types';
import {EmployeeData} from '>components/employeeTable/employeeRow';
import {getCustomerMessage} from '>lib/util';
import {quickAddEmployee, updateEmployee} from '>store/actions/employee';
import {rollbarLogger} from '>lib/logger';
import {useAccountUser} from '>root/lib/user';
import {useAsyncDispatch} from '>root/store/main';

export enum EmployeeModalState {
  Add = 'add',
  Edit = 'edit',
}

interface AddEditEmployeeProps {
  employee?: Employee;
  isOpen: boolean;
  modalState: EmployeeModalState;
  onClose: () => void;
  updateRow?: (data: EmployeeData) => void;
}

export const AddEditEmployeeModal: React.FC<AddEditEmployeeProps> = ({
  employee,
  isOpen,
  modalState,
  onClose,
  updateRow,
}) => {
  const accountUser = useAccountUser();
  assertAccountUserExists(accountUser);

  const authState = useSelector((state) => state.auth);
  assertValidAuthState(authState);

  const asyncDispatch = useAsyncDispatch();
  const isImpersonated = authState.isImpersonated || authState.token.user.impersonationBlockBypass;

  const {showSuccessAlert} = useSnackbar();

  const seed = useUIDSeed();

  const isAccessibleDepartment = React.useCallback((departmentText: string) => {
    if (accountUser.role === UserRole.TeamAdmin && accountUser.accessibleDepartments) {
      const accessibleDepartments = accountUser.accessibleDepartments.map((department: string) =>
        department.toLowerCase()
      );
      return !accessibleDepartments.includes(departmentText.toLowerCase());
    } else {
      return false;
    }
  }, []);

  const formMethods = useForm<Employee>({
    mode: 'all',
    defaultValues: employee
      ? {
          firstName: employee.firstName,
          lastName: employee.lastName,
          preferredName: employee.preferredName,
          email: employee.email,
          jobTitle: employee.jobTitle,
          department: employee.department,
          location: employee.location,
          externalId: employee.externalId,
          managerName: employee.managerName,
        }
      : {
          firstName: '',
          lastName: '',
          preferredName: '',
          email: '',
          jobTitle: '',
          department: '',
          location: '',
          externalId: '',
          managerName: '',
        },
  });
  const {register, formState} = formMethods;
  const [isAccommodationsMode, setIsAccommodationsMode] = React.useState(
    employee?.isAccommodationsMode || false
  );

  const submitNewEmployee = React.useCallback(async () => {
    const newEmployee = formMethods.getValues();
    await asyncDispatch(quickAddEmployee(newEmployee));
    const successMessage = 'Success! 1 new employee added.';
    showSuccessAlert(successMessage);

    onClose();
    formMethods.reset();
  }, []);

  const editExistingEmployee = React.useCallback(async () => {
    assertExists(employee, 'Employee must exist in order to edit');

    const updatedEmployee = formMethods.getValues();

    //Logic to update the isAccommodationsMode flag for employee if impersonated
    if (isImpersonated && employee.isAccommodationsMode !== isAccommodationsMode) {
      updatedEmployee.isAccommodationsMode = isAccommodationsMode;
    }
    await asyncDispatch(
      updateEmployee({
        employee,
        updatedEmployee: {
          ...updatedEmployee,
        },
      })
    ),
      [employee];

    const employeeUpdate = {...employee, ...updatedEmployee};
    updateRow && updateRow({employee: employeeUpdate});

    const successMessage = `Success! Employee information updated.`;
    showSuccessAlert(successMessage);

    onClose();
    formMethods.reset();
  }, [isAccommodationsMode, employee]);

  const onSubmitError = React.useCallback(
    (err, setFormError) => {
      const error = err as Error;

      if (error.message) {
        setFormError(getCustomerMessage(error.message));
      } else {
        throw error;
      }

      rollbarLogger.error(error);
    },
    [rollbarLogger]
  );

  React.useEffect(() => {
    formMethods.reset(employee);
  }, [employee]);

  const isAddingEmployee = modalState === EmployeeModalState.Add;
  return (
    <FormProvider {...formMethods}>
      <FixedPositionDrawerContainer>
        <DrawerForm
          formTitle={modalState === EmployeeModalState.Add ? 'Add Employee' : 'Edit Employee'}
          formDescription={
            modalState === EmployeeModalState.Add
              ? 'Add employee details'
              : 'Manage employee details'
          }
          isOpen={isOpen}
          onDismiss={onClose}
          onSubmitError={onSubmitError}
          stages={[
            {
              methods: formMethods,
              form: (
                <FormBuilder
                  fields={
                    [
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'First Name',
                        name: 'firstName',
                        component: (
                          <Input
                            autoComplete="given-name"
                            ref={register({
                              required: 'First Name is required',
                              validate: {
                                required: (value: string) => {
                                  if (value.trim().length === 0) {
                                    return 'First Name is required';
                                  }
                                },
                              },
                            })}
                            placeholder="First Name"
                            required
                          />
                        ),
                      },
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'Last Name',
                        name: 'lastName',
                        component: (
                          <Input
                            autoComplete="family-name"
                            ref={register({
                              required: 'Last Name is required',
                              validate: {
                                required: (value: string) => {
                                  if (value.trim().length === 0) {
                                    return 'Last Name is required';
                                  }
                                },
                              },
                            })}
                            placeholder="Last Name"
                            required
                          />
                        ),
                      },
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'Preferred Name',
                        name: 'preferredName',
                        component: (
                          <Input
                            ref={register({
                              validate: {
                                minLength: (value: string) => {
                                  if (!!value && value.trim().length === 0) {
                                    return 'Preferred Name cannot be an empty string';
                                  }
                                },
                              },
                            })}
                            placeholder="Preferred Name"
                            required={false}
                          />
                        ),
                      },
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'Email',
                        name: 'email',
                        component: (
                          //Link to email Regex and explanation https://stackoverflow.com/questions/5601647/html5-email-input-pattern-attribute#answer-65442112
                          <Input
                            autoComplete="email"
                            ref={register({
                              required: 'Email is required',
                              pattern: {
                                value: /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d\+_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/,
                                message: 'Email is not valid',
                              },
                              validate: {
                                required: (value: string) => {
                                  if (value.trim().length === 0) {
                                    return 'Email is required';
                                  }
                                },
                              },
                            })}
                            placeholder="Email Address"
                            required
                          />
                        ),
                      },
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'Job Title',
                        name: 'jobTitle',
                        component: (
                          <Input
                            autoComplete="organization-title"
                            ref={register({
                              required: 'Job Title is required',
                              validate: {
                                minJobTitleLength: (value: string) => {
                                  if (!!value && value.trim().length < 2) {
                                    return 'Job Title value must contain more than two characters';
                                  }
                                },
                                maxJobTitleLength: (value: string) => {
                                  if (!!value && value.trim().length > 60) {
                                    return 'Job Title value must not contain more than 60 characters';
                                  }
                                },
                                required: (value: string) => {
                                  if (value.trim().length === 0) {
                                    return 'Job Title is required';
                                  }
                                },
                              },
                            })}
                            placeholder="Job Title"
                            required
                          />
                        ),
                      },
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'Department',
                        name: 'department',
                        component: (
                          <Input
                            ref={register({
                              required: 'Department is required',
                              validate: {
                                required: (value: string) => {
                                  if (value.trim().length === 0) {
                                    return 'Department is required';
                                  }
                                },
                                accessibleDepartments: (value: string) => {
                                  if (isAccessibleDepartment(value)) {
                                    return 'You do not have access to this department';
                                  }
                                },
                              },
                            })}
                            placeholder="Department"
                            required
                          />
                        ),
                      },
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'Location',
                        name: 'location',
                        component: (
                          <Input ref={register()} placeholder="Location" required={false} />
                        ),
                      },
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'Employee ID',
                        name: 'externalId',
                        component: (
                          <Input ref={register()} placeholder="Employee ID" required={false} />
                        ),
                      },
                      {
                        type: FormBuilderFieldType.Input,
                        label: 'Manager Name',
                        name: 'managerName',
                        component: (
                          <Input ref={register()} placeholder="Manager" required={false} />
                        ),
                      },
                      ...(isImpersonated && modalState === EmployeeModalState.Edit
                        ? [
                            {
                              type: FormBuilderFieldType.Input,
                              label: 'Accommodations Mode',
                              name: 'isAccommodationsMode',
                              component: (
                                <CheckBox
                                  label="Accommodations Mode"
                                  checked={isAccommodationsMode}
                                  id={seed('isAccommodationsMode')}
                                  onChange={() => {
                                    setIsAccommodationsMode(!isAccommodationsMode);
                                  }}
                                />
                              ),
                            },
                          ]
                        : []),
                    ] as FormBuilderFields
                  }
                />
              ),
              actions: {
                next: {
                  label: 'Save',
                  onClick: isAddingEmployee ? submitNewEmployee : editExistingEmployee,
                  disabled: formState.isSubmitting,
                },
                back: {
                  label: 'Cancel',
                  disabled: formState.isSubmitting,
                  onClick: onClose,
                },
              },
            },
          ]}
        />
      </FixedPositionDrawerContainer>
    </FormProvider>
  );
};

const FixedPositionDrawerContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  ${mobileSidebarDrawOrder};
`;
