import {AppRoute, findRoutePathFromLabel} from '>shared/lib/navigation';
import {assert} from 'wnd-util';
import {AdminEmployeesPage} from '>root/pages/adminEmployees/adminEmployeesPage';
import {UserManagementPage} from '>root/pages/adminUserManagement/userManagementPage';
import {TeamManagementPage} from '>root/pages/adminTeamManagement/teamManagementPage';
import {Coaching} from '>root/pages/coaching';
import {EmployeeHome} from '>root/pages/employeeHome/employeeHome';
import {Results} from '>root/pages/employeeResults/page';
import {DevelopIndex} from '>root/pages/developIndex';
import {AcceptAdminInvite, AcceptInvite} from '>root/pages/acceptInvite';
import {DVPErrorPage} from '>root/pages/errorPage/page';
import {LogoutPage} from '>root/pages/logout';
import {ContentManagerForResults} from '>root/pages/contentManager/book/page';
import {ImpersonateEmployee} from '>root/pages/impersonateEmployee';
import {ActionPlanner} from '>root/pages/actionPlanner/page';
import {ContentManagerForHome} from '>root/pages/contentManager/homePage/page';
import {ContentManagerForActionPlannerWelcome} from '>root/pages/contentManager/actionPlannerWelcome/page';
import {ContentManagerForActionItems} from '>root/pages/contentManager/actionItems/page';
import {withNonArchivedEmployeeWrapper} from '>components/wrappers/nonArchivedEmployee';
import {TeamInsights} from '>root/pages/teamInsights/page';
import {ContentManagerForTeamReport} from '>root/pages/contentManager/teamReport/page';
import {TeamDetailsPage} from '>root/pages/adminTeamManagement/teamDetailsPage';

export enum RouteLabel {
  // AdminDashboard = 'Dashboard',
  AdminEmployees = 'Employee Management',
  AdminUserManagement = 'Admin Management',
  AdminTeamManagement = 'Team Management',
  AdminTeamDetails = 'Team Details',
  EmployeeHome = 'Home',
  EmployeeResults = 'Results (Identify)',
  EmployeeActionPlanner = 'Action Planner (Commit)',
  EmployeeDevelopIndex = 'Index (Explore)',
  EmployeeAcceptInvitation = 'Accept Invitation',
  AdminAcceptInvitation = 'Admin Accept Invitation',
  Coaching = 'Coaching',
  InternalContentManagementForResults = 'Content Manager Results',
  InternalContentManagementForActionPlannerWelcome = 'Action Planner Welcome',
  InternalContentManagementForHome = 'Content Manager Home',
  InternalContentManagementForActionItems = 'Action Item Database',
  InternalContentManagementForTeamReport = 'Team Report',
  Logout = 'Logout',
  TeamInsights = 'Team Insights',
  Error = 'Error',
}

export enum RoutePath {
  // AdminDashboard = '/admin',
  AdminEmployees = '/admin/employees',
  AdminUserManagement = '/admin/user-management',
  AdminTeamManagement = '/admin/team-management',
  AdminTeamDetails = '/admin/team/:teamId',
  Coaching = '/coaching',
  EmployeeHome = '/',
  EmployeeResults = '/section',
  EmployeeActionPlanner = '/actionplanner',
  EmployeeDevelopIndex = '/developindex',
  InternalContentManagementForResults = '/managecontent',
  InternalContentManagementForActionPlannerWelcome = '/managecontent/actionplanner/welcome',
  InternalContentManagementForHome = '/managecontent/home',
  InternalContentManagementForActionItems = '/managecontent/actionitems',
  InternalContentManagementForTeamReport = '/managecontent/teamreport',
  Logout = '/logout',
  TeamInsights = '/team-insights',
  Error = '/error',
}

export const EmployeeHomeAnchorPrefix = 'section';

export const routes: Array<AppRoute<RouteLabel>> = [
  // {
  //   path: RoutePath.AdminDashboard,
  //   label: RouteLabel.AdminDashboard,
  //   Component: AdminDashboardPage,
  // },
  {
    path: RoutePath.AdminEmployees,
    label: RouteLabel.AdminEmployees,
    Component: AdminEmployeesPage,
  },
  {
    path: RoutePath.AdminUserManagement,
    label: RouteLabel.AdminUserManagement,
    Component: UserManagementPage,
  },
  {
    path: RoutePath.AdminTeamManagement,
    label: RouteLabel.AdminTeamManagement,
    Component: TeamManagementPage,
  },
  {
    path: RoutePath.AdminTeamDetails,
    label: RouteLabel.AdminTeamDetails,
    Component: TeamDetailsPage,
  },
  {
    path: RoutePath.EmployeeHome,
    label: RouteLabel.EmployeeHome,
    Component: withNonArchivedEmployeeWrapper(EmployeeHome),
  },
  {
    path: `${RoutePath.EmployeeResults}:chapterId`,
    label: RouteLabel.EmployeeResults,
    Component: withNonArchivedEmployeeWrapper(Results),
  },
  {
    path: RoutePath.EmployeeActionPlanner,
    label: RouteLabel.EmployeeActionPlanner,
    Component: withNonArchivedEmployeeWrapper(ActionPlanner),
  },
  {
    path: RoutePath.EmployeeDevelopIndex,
    label: RouteLabel.EmployeeDevelopIndex,
    Component: withNonArchivedEmployeeWrapper(DevelopIndex),
  },
  {
    path: 'accounts/:accountId/employees/:employeeId/accept-invite',
    label: RouteLabel.EmployeeAcceptInvitation,
    Component: AcceptInvite,
  },
  {
    path: '/employees/:employeeId/impersonate',
    label: RouteLabel.EmployeeHome,
    Component: ImpersonateEmployee,
  },
  {
    path: 'accounts/:accountId/accept-invite',
    label: RouteLabel.AdminAcceptInvitation,
    Component: AcceptAdminInvite,
  },
  {
    path: RoutePath.Coaching,
    label: RouteLabel.Coaching,
    Component: Coaching,
  },
  {
    path: RoutePath.TeamInsights,
    label: RouteLabel.TeamInsights,
    Component: TeamInsights,
  },
  {
    path: `${RoutePath.InternalContentManagementForResults}`,
    label: RouteLabel.InternalContentManagementForResults,
    Component: ContentManagerForResults,
  },
  {
    path: `${RoutePath.InternalContentManagementForHome}`,
    label: RouteLabel.InternalContentManagementForHome,
    Component: ContentManagerForHome,
  },
  {
    path: `${RoutePath.InternalContentManagementForActionItems}`,
    label: RouteLabel.InternalContentManagementForActionItems,
    Component: ContentManagerForActionItems,
  },
  {
    path: `${RoutePath.InternalContentManagementForActionItems}`,
    label: RouteLabel.InternalContentManagementForActionItems,
    Component: ContentManagerForActionItems,
  },
  {
    path: `${RoutePath.InternalContentManagementForTeamReport}`,
    label: RouteLabel.InternalContentManagementForTeamReport,
    Component: ContentManagerForTeamReport,
  },
  {
    path: RoutePath.Logout,
    label: RouteLabel.Logout,
    Component: LogoutPage,
  },
  {
    path: RoutePath.Error,
    label: RouteLabel.Error,
    Component: DVPErrorPage,
  },
  {
    path: RoutePath.InternalContentManagementForActionPlannerWelcome,
    label: RouteLabel.InternalContentManagementForActionPlannerWelcome,
    Component: ContentManagerForActionPlannerWelcome,
  },
];
export function findPathFromLabel(label: RouteLabel) {
  const path = findRoutePathFromLabel(label, routes);
  assert.assertExists(path, 'All path labels should be referenced by the routes');
  return path;
}
