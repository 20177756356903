import styled from '@emotion/styled';
import {baseDropShadow} from '>styles/mixins/shadows';
import * as colors from 'wnd-themes/lib/colorPalette';
import {FontIcon} from '>shared/components/icon/icon';
import {ContentsContainer} from '>shared/components/book/sidebar/contents';
import {miniScrollbar} from '>shared/styles/scrollbar';
import {respondTo} from '>shared/styles/breakpoints';

const collapseAnimationTime = '0.2s linear';

export const BookIndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;

export const ChapterSelectContainer = styled.div`
  padding-bottom: 0.8rem;
  width: 100%;

  ${respondTo.mediumOrHigher}{
    padding: 1.6rem 0.8rem 0.8rem 1.6rem;
  }
`;

export const CollapsibleButtonContainer = styled.div`
  display: none;

  ${respondTo.mediumOrHigher}{
    display: block;
    position: relative;
  }
`;

export const CollapsibleButton = styled.button<{isCollapsed: boolean}>`
  position: absolute;
  bottom: 0.4rem;

  ${baseDropShadow}
  background: ${colors.char100};
  border-radius: 0.4rem;
  border: 0;
  flex-shrink: 0;
  flex-grow: 0;
  transition: bottom ${collapseAnimationTime};

  ${(props) => props.isCollapsed && 'bottom: -2.4rem;'}
`;

export const CollapsibleButtonIcon = styled(FontIcon)<{isCollapsed: boolean}>`
  transform: rotate(0deg);
  transition: transform ${collapseAnimationTime};

  ${(props) => props.isCollapsed && 'transform: rotate(180deg);'}
`;

export const TableOfContentsContainer = styled(ContentsContainer)<{isCollapsed: boolean}>`
  transition: max-height ${collapseAnimationTime};
  max-height: 100%;

  ${miniScrollbar}

  ${(props) => props.isCollapsed && 'max-height: 0%;'}
`;

