import React from 'react';
import {ConstructDomain, HomePageItem, HomePageItemType} from '>generated/dvp.types';
import {BookContentsContainer} from '>shared/components/book/sidebar/contents';
import {vr4} from '>shared/styles/mixins/verticalRhythm';
import {SectionMarkdown, SpotlightFlag, renderText} from 'wnd-dvp-reports';
import {Heading, Section} from '>shared/components/heading/heading';
import {
  textXsExtraboldUppercase,
  textXsSemiboldUppercase,
} from '>shared/components/typography/designSystemTypography';
import {
  ContentSection,
  Spotlights,
  TitleContainer,
  WidgetContainer,
} from '../../employeeHome/employeeHome.styles';
import {centerItems} from '>shared/styles/mixins/alignment';
import {
  arrowBackIcon,
  landscapeIcon,
  roleSuccessIcon,
  starIcon,
} from '>shared/components/icon/icons';
import {mR1} from '>shared/styles/mixins/margins';
import * as colors from 'wnd-themes/lib/colorPalette';
import {SpotlightWidget} from '>components/widgets/spotlightWidget/spotlightWidget';
import {Icon} from '>shared/components/icon/icon';
import {Button, ButtonSizes, ButtonType} from '>shared/components/button/button';
import {AnchorId} from '../../employeeHome/employeeHome';
import {sharedComponents} from '>lib/markdownSectionComponents';
import styled from '@emotion/styled';
import {HomePageSpotlightItemContent} from '>generated/dvp.types';
import {HomePageSecondaryItemContent} from '>generated/dvp.types';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ArrowIcon = styled(Icon)`
  width: 1.7rem;
  height: 1.7rem;
  margin-left: 0.8rem;
  transform: rotate(180deg);
  fill: ${colors.gray500};
`;

interface Props {
  homePageItem: HomePageItem;
  templateContext: Record<string, any>;
}

export const HomePageItemView: React.FC<Props> = ({homePageItem, templateContext}) => {
  const HomePageItemView = React.useMemo(() => {
    switch (homePageItem.type) {
      case HomePageItemType.Spotlight:
        return spotlightView(homePageItem, templateContext);
      case HomePageItemType.Secondary:
        return secondaryHomePageItemView(homePageItem, templateContext);
      default:
        throw new Error(
          `Rendering view for home tab of type ${homePageItem.type} is not supported`
        );
    }
  }, [homePageItem]);

  return (
    <BookContentsContainer>
      <Section rootLevel={1}>
        <TitleContainer>
          <Heading css={[vr4, textXsExtraboldUppercase]}>{homePageItem.content.title}</Heading>
        </TitleContainer>
      </Section>
      {HomePageItemView}
      <ButtonContainer>
        <Button
          buttonType={ButtonType.Neutral}
          size={ButtonSizes.XL}
          data-qa-button="home-page-continue-button"
        >
          {homePageItem.content.continueButtonText}
          <ArrowIcon src={arrowBackIcon} />
        </Button>
      </ButtonContainer>
    </BookContentsContainer>
  );
};

const secondaryHomePageItemView = (
  homePageItem: HomePageItem,
  templateContext: Record<string, any>
) => {
  const content = homePageItem.content as HomePageSecondaryItemContent;
  return (
    <ContentSection>
      <SectionMarkdown components={sharedComponents}>
        {renderText(content.body, templateContext, homePageItem.variables)}
      </SectionMarkdown>
    </ContentSection>
  );
};

const spotlightView = (homePageItem: HomePageItem, templateContext: Record<string, any>) => {
  const content = homePageItem.content as HomePageSpotlightItemContent;
  return (
    <ContentSection>
      <SectionMarkdown components={sharedComponents}>
        {renderText(content.summary, templateContext, homePageItem.variables)}
      </SectionMarkdown>
      <Heading css={[vr4, centerItems, textXsSemiboldUppercase]} id={AnchorId.successAnchorId}>
        <Icon src={roleSuccessIcon} css={mR1} primaryColor={colors.gray500} />
        Critical Attributes
      </Heading>
      <Spotlights css={vr4} columns={5} data-homecontent>
        {[1, 2, 3, 4, 5].map((spotlight) => (
          <WidgetContainer key={spotlight}>
            <SpotlightWidget
              score={50}
              constructDomain={ConstructDomain.Cognitive}
              title={'title'}
              sectionInfo={[]}
              flag={SpotlightFlag.Critical}
            />
          </WidgetContainer>
        ))}
      </Spotlights>
      <SectionMarkdown components={sharedComponents}>
        {renderText(content.roleCriticalAttributes, templateContext, homePageItem.variables)}
      </SectionMarkdown>

      <Heading css={[vr4, centerItems, textXsSemiboldUppercase]} id={AnchorId.strengthsAnchorId}>
        <Icon src={starIcon} css={mR1} primaryColor={colors.gray500} />
        Least Effort Attributes
      </Heading>

      <Spotlights css={vr4} data-homecontent>
        {[1, 2, 3, 4, 5].map((spotlight) => (
          <WidgetContainer key={spotlight}>
            <SpotlightWidget
              score={100}
              constructDomain={ConstructDomain.Personality}
              title={'title'}
              sectionInfo={[]}
              flag={SpotlightFlag.FocusOpportunity}
            />
          </WidgetContainer>
        ))}
      </Spotlights>
      <SectionMarkdown components={sharedComponents}>
        {renderText(content.leastEffortAttributes, templateContext, homePageItem.variables)}
      </SectionMarkdown>
      <Heading css={[vr4, centerItems, textXsSemiboldUppercase]} id={AnchorId.focusAnchorId}>
        <Icon src={landscapeIcon} css={mR1} primaryColor={colors.gray500} />
        Most Effort Attributes
      </Heading>
      <Spotlights css={vr4} data-homecontent>
        {[1, 2, 3, 4, 5].map((spotlight) => (
          <WidgetContainer key={spotlight}>
            <SpotlightWidget
              score={10}
              constructDomain={ConstructDomain.Interest}
              title={'title'}
              sectionInfo={[]}
              flag={SpotlightFlag.Strength}
            />
          </WidgetContainer>
        ))}
      </Spotlights>
      <SectionMarkdown components={sharedComponents}>
        {renderText(content.mostEffortAttributes, templateContext, homePageItem.variables)}
      </SectionMarkdown>
      {content.closing && (
        <SectionMarkdown components={sharedComponents}>
          {renderText(content.closing, templateContext, homePageItem.variables)}
        </SectionMarkdown>
      )}
    </ContentSection>
  );
};
