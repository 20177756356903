"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResponsiveLayout = exports.PageLayoutComponents = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _breakpoints = require("../../../styles/breakpoints");
var _useResponsiveLayout = require("./useResponsiveLayout");
var _assert = require("wnd-util/lib/assert");
var _responsiveLayout = require("./responsiveLayout.styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const MobileHeaderHeight = '130px';
var PageLayoutComponents;
(function (PageLayoutComponents) {
  PageLayoutComponents["Nav"] = "Nav";
  PageLayoutComponents["Body"] = "Body";
  PageLayoutComponents["Table"] = "Table";
  PageLayoutComponents["Aside"] = "Aside";
})(PageLayoutComponents || (exports.PageLayoutComponents = PageLayoutComponents = {}));
const isElementAHeading = (pageElem, headings) => {
  let headingLevelString = '';
  headings.forEach(level => headingLevelString += `h${level},`);
  return pageElem.matches(headingLevelString.slice(0, -1));
};
const isMobile = () => window.matchMedia(_breakpoints.mediaQueries.smallOrLower.media).matches;
const ResponsiveLayout = ({
  className,
  main,
  nav,
  observerAttribute,
  headingLevels = [1, 2],
  leftNavSize
}) => {
  const firstRender = React.useRef(true);
  const {
    setActiveSectionTitle,
    hiddenComponent,
    setHiddenComponent
  } = (0, _useResponsiveLayout.useResponsiveLayout)();
  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      const mobileView = isMobile();
      if (mobileView) {
        setHiddenComponent(PageLayoutComponents.Body);
      }
      return;
    }
  }, []);
  React.useEffect(() => {
    const mobileView = isMobile();
    if (!firstRender.current && hiddenComponent === PageLayoutComponents.Nav && mobileView) {
      const scrollLocation = location.hash.replace('#', '');
      const element = document.getElementById(scrollLocation);
      if (element) {
        element.scrollIntoView(true);
        getTitleFromSiblingElement(element);
      }
    }
  }, [hiddenComponent]);
  const showCorrectViewAfterResize = React.useCallback(() => {
    const desktop = window.matchMedia(_breakpoints.mediaQueries.mediumOrHigher.media);
    if (desktop.matches) {
      setHiddenComponent(null);
    } else {
      setHiddenComponent(PageLayoutComponents.Nav);
      setActiveSectionTitle('');
    }
  }, []);
  React.useEffect(() => {
    window.addEventListener('resize', showCorrectViewAfterResize);
    return () => window.removeEventListener('resize', showCorrectViewAfterResize);
  }, []);
  const handleTitleChange = React.useCallback(title => {
    setActiveSectionTitle(title);
  }, []);
  const getTitleFromSiblingElement = React.useCallback(element => {
    const prevSibling = element.previousElementSibling;
    if (prevSibling) {
      if (isElementAHeading(prevSibling, headingLevels)) {
        return handleTitleChange(prevSibling.innerText);
      } else {
        getTitleFromSiblingElement(prevSibling);
      }
    }
  }, []);
  const handleObserverEvent = React.useCallback(entries => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      getTitleFromSiblingElement(entry.target);
    }
  }, []);
  React.useEffect(() => {
    if (observerAttribute) {
      const sections = [...document.querySelectorAll(`[${observerAttribute}]`)];
      const observer = new IntersectionObserver(handleObserverEvent, {
        root: null,
        rootMargin: `-${MobileHeaderHeight}`,
        threshold: 0.5
      });
      (0, _assert.assertExists)(sections, 'Sections must be defined to setup observer');
      sections.forEach(section => {
        observer.observe(section);
      });
      return () => {
        if (sections) {
          sections.forEach(section => {
            observer.unobserve(section);
          });
        }
      };
    }
  }, [main]);
  return (0, _jsxRuntime.jsx)(_responsiveLayout.FullWidthContainer, {
    children: (0, _jsxRuntime.jsxs)(_responsiveLayout.PageContainer, {
      className: className,
      leftNavSize: leftNavSize,
      children: [(0, _jsxRuntime.jsx)(_responsiveLayout.Nav, {
        hidden: hiddenComponent === PageLayoutComponents.Nav && !firstRender.current,
        children: nav
      }), (0, _jsxRuntime.jsx)(_responsiveLayout.Main, {
        hidden: hiddenComponent === PageLayoutComponents.Body,
        children: main
      })]
    })
  });
};
exports.ResponsiveLayout = ResponsiveLayout;