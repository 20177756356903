import {IntroductionFormFields} from '>components/contentManagement/form/introduction/formFields';
import {MeasureFormFields} from '>components/contentManagement/form/measure/formFields';
import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import {ChapterItem, ChapterItemType} from '>generated/dvp.types';
import React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {ChapterItemView} from './chapterItemView';
import {convertFormFieldsToChapterItemContent, FormView} from './formView';

export enum ViewState {
  Preview = 'preview-state',
  Edit = 'edit-state',
  View = 'view-state',
  Create = 'create-state',
}

interface Props {
  chapterItem: ChapterItem;
  chapterItemFormMethods:
    | UseFormMethods<IntroductionFormFields>
    | UseFormMethods<MeasureFormFields>;
  formFieldsInEdit?: IntroductionFormFields | MeasureFormFields;
  templateControlFormMethods: UseFormMethods<TemplateControlFormFields>;
  handleTemplateContextChange: (newValue: string) => void;
  handleGlobalVariablesChange: (newValue: string) => void;
  viewState: ViewState;
  templateContext: Record<string, any>;
  globalVariables: string;
}

export const ChapterItemContent: React.FC<Props> = ({
  chapterItem,
  chapterItemFormMethods,
  formFieldsInEdit,
  templateControlFormMethods,
  handleTemplateContextChange,
  handleGlobalVariablesChange,
  viewState,
  templateContext,
  globalVariables
}) => {
  const Content = React.useMemo(() => {
    return {
      [ViewState.View]: (
        <ChapterItemView
          chapterItemContent={chapterItem.content}
          chapterItemType={chapterItem.type}
          templateContext={templateContext}
        />
      ),
      [ViewState.Preview]: formFieldsInEdit ? (
        <ChapterItemView
          chapterItemContent={{
            ...convertFormFieldsToChapterItemContent(
              formFieldsInEdit,
              chapterItem.type
            ),
            variables: globalVariables
          }}
          chapterItemType={chapterItem.type}
          templateContext={templateContext}
        />
      ) : (
        <></>
      ),
      [ViewState.Create]: (
        <FormView
          chapterItemType={ChapterItemType.Measure}
          chapterItemFormMethods={chapterItemFormMethods}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
          handleGlobalVariablesChange={handleGlobalVariablesChange}
          viewState={viewState}
        />
      ),
      [ViewState.Edit]: chapterItem && (
        <FormView
          chapterItemType={chapterItem.type}
          chapterItemFormMethods={chapterItemFormMethods}
          templateControlFormMethods={templateControlFormMethods}
          handleTemplateContextChange={handleTemplateContextChange}
          handleGlobalVariablesChange={handleGlobalVariablesChange}
          viewState={viewState}
        />
      ),
    };
  }, [
    viewState,
    chapterItem,
    chapterItemFormMethods,
    templateControlFormMethods,
    handleTemplateContextChange,
    templateContext,
  ]);

  return Content[viewState];
};
