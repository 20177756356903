import * as React from 'react';
import {useSelector} from 'react-redux';
import {sortBy} from 'lodash';
import {CellProps, CellValue, Row} from 'react-table';
import {Redirect, RouteChildrenProps} from 'react-router-dom';
import {EmployeeHomeAnchorPrefix, RoutePath} from '>root/routes/routes';
import {Table, TableSize} from '>shared/components/tables/table';
import {CardSize, MeasureCard} from '>components/measures/measureCard/measureCard';
import {SpotlightFlag, ConstructDomain} from 'wnd-dvp-reports';
import {sharedComponents} from '>lib/markdownSectionComponents';
import {DevelopIndexContainer} from './developIndex/developIndexContainer';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {mediaQueries} from '>shared/styles/breakpoints';
import {PageLayoutComponents} from '>shared/components/layouts/responsive/responsiveLayout';
import {IndexMeasure, IndexMeasures} from '>generated/dvp.types';
import {SpotlightIcon} from '>components/measures/measureCard/spotlightIcon';
import {Spinner} from '>shared/components/spinner';
import {redirectToErrorPage} from '>lib/redirect';
import {rollbarLogger} from '>lib/logger';
import {useAsyncDispatch} from '>root/store/main';
import {getIndexMeasures, updateOnboardingProgress} from '>root/store/actions/employee';

const columns = [
  {
    Header: 'Measure',
    accessor: 'title' as const,
    width: 250,
  },
  {
    Header: 'Score',
    accessor: 'score' as const,
  },
  {
    Header: 'Spotlight',
    accessor: 'flags' as const,
    sortType: (rowA: Row, rowB: Row) =>
      (rowA.values.flags ?? '') > (rowB.values.flags ?? '') ? -1 : 1,
    Cell: ({row, value}: CellProps<CellValue>) => (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {value.map((flag: SpotlightFlag) => (
          <SpotlightIcon key={flag} flag={flag} buttonStyle={false} id={`${row.id}-${flag}`} />
        ))}
      </div>
    ),
  },
  {
    Header: 'Section',
    accessor: 'chapters' as const,
    sortType: (rowA: Row, rowB: Row) =>
      rowA.values.chapters.map(Number) < rowB.values.chapters.map(Number) ? -1 : 1,
    Cell: ({value}: CellProps<CellValue>) => value.join(', '),
  },
];

function sortIndexMeasures(measures: IndexMeasures): IndexMeasures {
  return sortBy(measures, 'title');
}

export const DevelopIndex: React.FC<RouteChildrenProps> = () => {
  const [employeeTraitIndex, setEmployeeTraitIndex] = React.useState(0);
  const [employeeTraitDetails, setEmployeeTraitDetails] = React.useState<Array<IndexMeasure>>([]);
  const bucketMapping = useSelector((state) => state.interpretationBuckets.bucketMapping);
  const {hiddenComponent, setHiddenComponent, setIsHeaderHidden} = useResponsiveLayout();
  const asyncDispatch = useAsyncDispatch();

  const employee = useSelector((state) => state.employee.employee);
  const homePageItems = useSelector((state) => state.employee.homePageItems);
  if (!employee) {
    return <Redirect to={RoutePath.AdminEmployees} />;
  }

  React.useEffect(() => {
    if (!employee.userData?.onboardingProgress?.isCompleted && homePageItems) {
      const isLastItemCompleted =
        homePageItems.length < 1 ||
        Boolean(
          employee.userData?.onboardingProgress?.finishedSections?.find(
            (id) => id === homePageItems[homePageItems.length - 1].id
          )
        );

      if (
        isLastItemCompleted &&
        !Boolean(
          employee.userData?.onboardingProgress?.finishedSections?.find(
            (id) => id === 'developIndex'
          )
        )
      ) {
        asyncDispatch(
          updateOnboardingProgress({
            sectionCompleted: 'developIndex',
          })
        );
      }
    }
  }, [homePageItems]);

  const indexMeasures = useSelector((state) => state.employee.indexMeasures);

  // Lazy-load IndexMeasures
  React.useEffect(() => {
    if (indexMeasures) {
      setEmployeeTraitDetails(sortIndexMeasures(indexMeasures));
    } else {
      asyncDispatch(getIndexMeasures(employee.id)).catch((err) => {
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      });
    }
  }, [employee.id, indexMeasures]);

  React.useEffect(() => {
    const isViewingChildPageOnMobile = hiddenComponent === PageLayoutComponents.Table;
    setIsHeaderHidden(isViewingChildPageOnMobile);
  }, [hiddenComponent]);

  const onRowClicked = React.useCallback((itemIndex: number) => {
    setEmployeeTraitIndex(itemIndex);

    const mobile = window.matchMedia(mediaQueries.smallOrLower.media);
    if (mobile.matches) {
      setHiddenComponent(PageLayoutComponents.Table);
    }
  }, []);

  const getChapterLinks = React.useCallback((measure: IndexMeasure) => {
    const chapterLinkInformation = measure.chapters.map((chapter, idx) => {
      const buildUrl = `${RoutePath.EmployeeResults}${chapter}#${EmployeeHomeAnchorPrefix}${chapter}-${measure.anchorIds[idx]}`;

      return {
        number: chapter,
        href: buildUrl,
      };
    });

    return chapterLinkInformation;
  }, []);

  return !employeeTraitDetails ? (
    <Spinner />
  ) : (
    <DevelopIndexContainer
      card={
        <>
          {employeeTraitDetails.length && employeeTraitIndex !== undefined ? (
            <MeasureCard
              chapters={getChapterLinks(employeeTraitDetails[employeeTraitIndex])}
              sharedComponents={sharedComponents}
              constructDomain={
                employeeTraitDetails[employeeTraitIndex].constructDomain as ConstructDomain
              }
              title={employeeTraitDetails[employeeTraitIndex].title}
              score={employeeTraitDetails[employeeTraitIndex].score}
              bucketMapping={bucketMapping}
              synonyms={employeeTraitDetails[employeeTraitIndex].synonyms}
              definition={employeeTraitDetails[employeeTraitIndex].definition}
              cardSize={CardSize.small}
              spotlightFlags={employeeTraitDetails[employeeTraitIndex].flags as SpotlightFlag[]}
            />
          ) : (
            ''
          )}
        </>
      }
      table={
        <Table
          columns={columns}
          data={employeeTraitDetails}
          makeRowsClickable={true}
          highlightFirstRowOnLoad
          scrollable
          heightToReduceForScrollableTable={20}
          onRowClicked={onRowClicked}
          boldWhenClicked={false}
          useSimpleMobileStyle={false}
          size={TableSize.Large}
        />
      }
    />
  );
};
