import * as React from 'react';
import {Redirect, RouteChildrenProps} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {redirectToErrorPage} from '>lib/redirect';
import {EmployeeArchivedError} from '>root/errors';
import {RoutePath} from '>root/routes/routes';

export function withNonArchivedEmployeeWrapper<T extends {[K in keyof T]?: string | undefined}>(
  WrappedComponent: React.ComponentType<RouteChildrenProps<T>>
) {
  const ComponentRequiringNonArchivedEmployee = (props: RouteChildrenProps<T>) => {
    const employee = useSelector((state) => state.employee.employee);

    if (!employee) {
      return <Redirect to={RoutePath.AdminEmployees} />;
    }

    if (employee.isArchived) {
      redirectToErrorPage(new EmployeeArchivedError());
    }

    return <WrappedComponent {...props} />;
  };

  return ComponentRequiringNonArchivedEmployee;
}
