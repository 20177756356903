import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {FormBuilder} from '>shared/components/form/formBuilder';
import {buildTemplateControlFormFields} from './formBuilder';

export interface TemplateControlFormFields {
  globalVariables: string;
  templateContext: string;
}

interface Props {
  onTemplateContextChange: (newValue: string) => void;
  onGlobalVariablesChange?: (newValue: string) => void;
}

export const TemplateControlFormFields: React.FC<Props> = ({onTemplateContextChange, onGlobalVariablesChange}) => {
  const {register} = useFormContext<TemplateControlFormFields>();
  const formFields = buildTemplateControlFormFields(register, onTemplateContextChange, onGlobalVariablesChange);

  return <FormBuilder fields={formFields} />;
};
