import * as React from 'react';
import {Employee, TeamMemberWithEmployee, TeamRoles} from '>generated/dvp.types';
import {
  textSmRegular,
  textXlBold,
  textXsSemiboldUppercase,
} from '>shared/components/typography/designSystemTypography';
import * as colors from 'wnd-themes/lib/colorPalette';
import {Avatar} from '>shared/components/avatar/avatar';
import {vr1, vr3, vr6, vr8} from '>shared/styles/mixins/verticalRhythm';
import styled from '@emotion/styled';
import {getInitials} from '>lib/user';

interface TeamProps {
  name: string;
  members: TeamMemberWithEmployee[];
  showTeamName?: boolean;
  showManagers?: boolean;
  useAltAvatarStyle?: boolean;
}

interface MemberProps {
  employee: Employee;
  showAvatar?: boolean;
  useAltAvatarStyle?: boolean;
}

const TeamName = styled.div`
  ${textXlBold}
  ${vr1}
`;

const ManagerSection = styled.div`
  ${vr6}
`;

const TeamSectionHeading = styled.div`
  ${textXsSemiboldUppercase}
  ${vr3}
`;

const MemberContainer = styled.div`
  ${textSmRegular}
  ${vr1}
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const TeamSection = styled.div`
  ${vr6}
`;

function buildMemberName(employee: Employee): string {
  return `${employee.preferredName ?? employee.firstName} ${employee.lastName}`;
}

export const Member: React.FC<MemberProps> = ({employee, showAvatar, useAltAvatarStyle}) => {
  return (
    <MemberContainer>
      {showAvatar && (
        <Avatar
          text={getInitials(employee.firstName, employee.lastName, employee.preferredName)}
          backgroundColor={colors.gray100}
          textColor={colors.gray500}
          altStyle={useAltAvatarStyle}
        />
      )}
      {buildMemberName(employee)}
    </MemberContainer>
  );
};

export const Team: React.FC<TeamProps> = ({
  name,
  members,
  showTeamName,
  showManagers,
  useAltAvatarStyle,
}) => {
  const managers = members.filter((m) => m.role === TeamRoles.Manager);
  const nonManagers = members.filter((m) => m.role !== TeamRoles.Manager);

  return (
    <div>
      {showTeamName && <TeamName>{name}</TeamName>}
      <TeamSection>
        <TeamSectionHeading>Team Members</TeamSectionHeading>
        {nonManagers.map((n) => (
          <Member
            key={n.employeeId}
            employee={n.employee}
            useAltAvatarStyle={useAltAvatarStyle}
            showAvatar
          />
        ))}
      </TeamSection>
      {showManagers && managers.length > 0 && (
        <ManagerSection>
          <>
            <TeamSectionHeading>Managed By:</TeamSectionHeading>
            {managers.map((m) => (
              <Member
                key={m.employeeId}
                employee={m.employee}
                useAltAvatarStyle={useAltAvatarStyle}
              />
            ))}
          </>
        </ManagerSection>
      )}
    </div>
  );
};
