import * as React from 'react';

import {Employee} from '>generated/dvp.types';

export enum DrawerModalType {
  AddEmployee = 'ADD_EMPLOYEE',
  EditEmployee = 'EDIT_EMPLOYEE',
  ConfirmJobTitle = 'CONFIRM_JOB_TITLE',
  BulkImport = 'BULK_IMPORT',
}

type EmployeeManagementDrawerModalContextType = {
  isDrawerModalOpen: boolean;
  setIsDrawerModalOpen(isOpen: boolean): void;
  selectedEmployee: Employee | undefined;
  setSelectedEmployee(employee: Employee | undefined): void;
  modalType: DrawerModalType;
  setModalType(type: DrawerModalType): void;
  isRootContext: boolean;
};

const EmployeeManagementDrawerModalContext = React.createContext<
  EmployeeManagementDrawerModalContextType
>({
  isDrawerModalOpen: false,
  setIsDrawerModalOpen: () => {},
  selectedEmployee: undefined,
  setSelectedEmployee: () => {},
  modalType: DrawerModalType.EditEmployee,
  setModalType: () => {},
  isRootContext: true,
});

function useEmployeeManagementDrawerModal(): EmployeeManagementDrawerModalContextType {
  const context = React.useContext<EmployeeManagementDrawerModalContextType>(
    EmployeeManagementDrawerModalContext
  );

  if (context.isRootContext) {
    throw new Error(
      'useEmployeeManagementDrawerModal must be used within a EmployeeManagementDrawerModalProvider'
    );
  }

  return context;
}

const EmployeeManagementDrawerModalProvider: React.FC = ({children}) => {
  const [isDrawerModalOpen, setIsDrawerModalOpen] = React.useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = React.useState<Employee | undefined>(undefined);
  const [modalType, setModalType] = React.useState<DrawerModalType>(DrawerModalType.EditEmployee);

  return (
    <EmployeeManagementDrawerModalContext.Provider
      value={{
        isDrawerModalOpen,
        setIsDrawerModalOpen,
        modalType,
        setModalType,
        selectedEmployee,
        setSelectedEmployee,
        isRootContext: false,
      }}
    >
      {children}
    </EmployeeManagementDrawerModalContext.Provider>
  );
};

export {useEmployeeManagementDrawerModal, EmployeeManagementDrawerModalProvider};
