"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildPaths = buildPaths;
exports.combinePaths = combinePaths;
exports.findRoutePathFromLabel = findRoutePathFromLabel;
exports.flattenRoutes = flattenRoutes;
exports.generateAppRoutes = generateAppRoutes;
exports.setupParents = setupParents;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function combinePaths(parent, child) {
  return `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`;
}
function buildPaths(routes, parentPath = '') {
  return routes.map(route => {
    const path = combinePaths(parentPath, route.path);
    return _objectSpread(_objectSpread({}, route), {}, {
      path
    }, route.routes && {
      routes: buildPaths(route.routes, path)
    });
  });
}
function setupParents(routes, parentRoute) {
  return routes.map(route => {
    const withParent = _objectSpread(_objectSpread({}, route), parentRoute && {
      parent: parentRoute
    });
    return _objectSpread(_objectSpread({}, withParent), withParent.routes && {
      routes: setupParents(withParent.routes, withParent)
    });
  });
}
function flatten(array) {
  return array.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}
function flattenRoutes(routes) {
  return flatten(routes.map(route => [route.routes ? flattenRoutes(route.routes) : [], route]));
}
function generateAppRoutes(routes) {
  const paths = buildPaths(routes);
  const parents = setupParents(paths);
  return flattenRoutes(parents);
}
function findRoutePathFromLabel(label, currentRoutes, currentPath = '') {
  // TODO: Make sure iterator works in ie/edge
  // eslint-disable-next-line no-restricted-syntax
  for (const route of currentRoutes) {
    if (route.label === label) {
      return currentPath + route.path;
    }
    if (route.routes) {
      const path = findRoutePathFromLabel(label, route.routes, currentPath + route.path);
      if (path) {
        return path;
      }
    }
  }
  return undefined;
}