import * as React from 'react';
import {mL2} from '>styles/mixins/margins';
import {
  List,
  ItemContainer,
  ItemInfo,
  ItemLabel,
  ItemTitle,
  IndexHeader,
  CmsHeading,
} from './styles';
import {Icons} from '>styles/icon-types';
import {FontIcon, smallIcon} from '>shared/components/icon/icon';
import {CategoryLabel} from '>shared/components/book/sidebar/categoryLabel';
import {Button} from '>shared/components/button/button';
import { css } from '@emotion/react';

export interface CmsNavCategory {
  title: string;
  items: CmsItem[];
}

export interface CmsNavItems {
  topLevelItems: CmsItem[];
  categories: CmsNavCategory[];
}

export interface CmsItem {
  id: string;
  isPublished?: boolean;
  title: string;
  label: string;
}

interface Props {
  selectedNavItemId?: string;
  navItemList: CmsItem[] | CmsNavItems;
  onNavItemSelected: (navItemId: string) => void;
}

export const CmsNavigation: React.FC<Props> = ({
  selectedNavItemId,
  navItemList,
  onNavItemSelected,
}) => {
  if (Array.isArray(navItemList)) {
    navItemList = {
      topLevelItems: navItemList,
      categories: [],
    };
  }

  return (
    <List>
      {buildNavItems(navItemList.topLevelItems, onNavItemSelected, selectedNavItemId)}
      {navItemList.categories.map((category) => {
        return (
          <>
            <CategoryLabel>{category.title}</CategoryLabel>
            {buildNavItems(category.items, onNavItemSelected, selectedNavItemId)}
          </>
        );
      })}
    </List>
  );
};

function buildNavItems(
  navItems: CmsItem[],
  onNavItemSelected: (itemId: string) => void,
  selectedNavItemId?: string
) {
  return navItems.map((navItem) => buildNavItem(navItem, onNavItemSelected, selectedNavItemId));
}

export function buildNavItem(
  navItem: CmsItem,
  onHomePageItemSelected: (homePageItemId: string) => void,
  selectedHomePageItemId?: string
) {
  return (
    <ItemContainer
      key={navItem.id}
      isActive={selectedHomePageItemId === navItem.id}
      onClick={() => {
        onHomePageItemSelected(navItem.id);
      }}
    >
      {navItem.isPublished !== undefined && (
        <FontIcon
          icon={navItem.isPublished ? Icons.Visibility : Icons.NoVisibility}
          disabled={!navItem.isPublished}
          css={smallIcon}
        />
      )}
      <ItemInfo css={mL2}>
        <ItemTitle>{navItem.title}</ItemTitle>
        <ItemLabel>{navItem.label}</ItemLabel>
      </ItemInfo>
    </ItemContainer>
  );
}

interface NavHeaderProps {
  title: string;
  onAddItem: () => void;
}

export const SimpleCmsNavHeader: React.FC<NavHeaderProps> = ({title, onAddItem}) => {
  return (
    <IndexHeader
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      `}
    >
      <CmsHeading>{title}</CmsHeading>
      <Button data-qa-button="create-cms-nav-item" onClick={onAddItem}>
        Add
      </Button>
    </IndexHeader>
  );
};
