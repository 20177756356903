import * as React from 'react';
import {Heading} from '>shared/components/heading/heading';
import {textLgMedium} from '>shared/components/typography/designSystemTypography';
import {useDispatch, useSelector} from 'react-redux';
import {ContextTabs, TabDetails, TabType} from '>shared/components/containers/contextTabs';
import {SectionMarkdown} from 'wnd-dvp-reports';
import {sharedComponents} from '>lib/markdownSectionComponents';
import {ActionPlannerWelcomeItem} from '>generated/dvp.types';
import {getActionPlannerWelcomeItems} from '>root/store/actions/actionPlanner';
import {PageContent, ReaderPageContainer} from '>components/actionPlanner/actionItemReader.styles';
import {css} from '@emotion/react';
import {vr10} from '>shared/styles/mixins/verticalRhythm';

type RenderedContent = Record<string, TabDetails>;

const MAX_VISIBLE_TABS = 3;

export const ActionPlannerWelcomeItems: React.FC = () => {
  const dispatch = useDispatch();
  const [renderedContent, setRenderedContent] = React.useState<RenderedContent>();

  const actionPlannerWelcomeItems = useSelector(
    (state) => state.actionPlanner.actionPlannerWelcomeItems
  );

  React.useEffect(() => {
    if (!actionPlannerWelcomeItems) {
      setRenderedContent(undefined);
      return;
    }

    const tabDetails: RenderedContent = {};

    actionPlannerWelcomeItems
      .slice(0, MAX_VISIBLE_TABS)
      .forEach((actionPlannerWelcomeItem, index) => {
        tabDetails[index.toString()] = {
          title: actionPlannerWelcomeItem.content.title,
          page: () => {
            return (
              <RenderedActionPlannerWelcomeItem
                actionPlannerWelcomeItem={actionPlannerWelcomeItem}
              />
            );
          },
        };
      });

    setRenderedContent(tabDetails);
  }, [actionPlannerWelcomeItems]);

  React.useEffect(() => {
    dispatch(getActionPlannerWelcomeItems());
  }, []);

  return (
    <ReaderPageContainer>
      <PageContent>
        <Heading css={[textLgMedium, vr10]}>Welcome to the Action Planner</Heading>

        {renderedContent && <ContextTabs tabs={renderedContent} tabType={TabType.Underlined}/>}
      </PageContent>
    </ReaderPageContainer>
  );
};

const RenderedActionPlannerWelcomeItem: React.FC<{
  actionPlannerWelcomeItem: ActionPlannerWelcomeItem;
}> = ({actionPlannerWelcomeItem}) => {
  return (
    <>
      <div
        css={css`
          margin-bottom: 1.6rem;
        `}
      />
      <SectionMarkdown components={sharedComponents}>
        {actionPlannerWelcomeItem.content.body}
      </SectionMarkdown>
    </>
  );
};
