import {css} from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import {
  DONUT_METRIC_INNER_CIRCLE_CLASS,
  DONUT_METRIC_OUTER_ARC_CLASS,
  drawDonutMetric,
  MetricOptions,
} from 'wnd-dvp-reports';
import * as colors from 'wnd-themes/lib/colorPalette';

const inactiveDonutStyle = css`
  & .${DONUT_METRIC_OUTER_ARC_CLASS} {
    stroke: ${colors.char400};
  }

  & .${DONUT_METRIC_INNER_CIRCLE_CLASS} {
    fill: ${colors.char100};
  }
`;

const SvgContainer = styled.div<{isActive?: boolean}>`
  display: inline-flex;

  ${(props) => (props.isActive ? '' : inactiveDonutStyle)};

  & .${DONUT_METRIC_OUTER_ARC_CLASS}, & .${DONUT_METRIC_INNER_CIRCLE_CLASS} {
    transition: all ease 0.25s;
  }
`;

export const DonutMetric: React.FC<{className?: string; isActive?: boolean} & MetricOptions> = ({
  className,
  isActive = true,
  ...metricOptions
}) => {
  const svgText = React.useMemo(() => {
    return drawDonutMetric(metricOptions);
  }, [metricOptions.score, metricOptions.color, metricOptions.variant]);

  return (
    <SvgContainer
      isActive={isActive}
      className={className}
      dangerouslySetInnerHTML={{__html: svgText}}
    ></SvgContainer>
  );
};
