import {WndError} from '>root/errors';
import {history} from '>root/history';
import {WEBUI_BILLING_URL} from '../env';

export function redirectToErrorPage(error?: WndError): void {
  const errorPath = '/error';
  if (error?.code) {
    history.replace({pathname: errorPath, search: `?code=${error.code}`});
  } else {
    history.replace(errorPath);
  }
}

export function redirectToProductPicker(): void {
  window.location.replace(`${WEBUI_BILLING_URL}/productPicker`);
}
