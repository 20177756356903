import * as React from 'react';
import {Redirect, RouteChildrenProps, useParams} from 'react-router-dom';
import {LoadingScreen} from '>shared/components/loadingScreen';
import {dvpApi, unifiedAccountApi} from '>root/apis';
import {assertExists} from 'wnd-util/lib/assert';
import {redirectToErrorPage} from '>lib/redirect';
import {rollbarLogger} from '>lib/logger';
import {useAsyncDispatch} from '>store/main';
import {refreshUserContext} from '>root/store/actions/user';
import {selectUnifiedAccount} from '>root/store/actions/account';

interface AcceptInviteRouteParams {
  accountId?: string;
  employeeId?: string;
}

interface AcceptAdminInviteRouteParams {
  accountId?: string;
}

export const AcceptInvite: React.FC<RouteChildrenProps> = () => {
  const asyncDispatch = useAsyncDispatch();
  const [isAccepting, setIsAccepting] = React.useState<boolean>(true);

  const {accountId, employeeId} = useParams<AcceptInviteRouteParams>();
  assertExists(accountId, 'accountId must be part of the route params');
  assertExists(employeeId, 'employeeId must be part of the route params');

  React.useEffect(() => {
    dvpApi
      .acceptEmployeeUserInvite({accountId, employeeId})
      .then(() => {
        return asyncDispatch(refreshUserContext());
      })
      .then(() => {
        setIsAccepting(false);
      })
      .catch((err) => {
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      });
  }, [accountId, employeeId]);

  if (isAccepting) {
    return <LoadingScreen />;
  }

  return <Redirect to="/" />;
};

export const AcceptAdminInvite: React.FC<RouteChildrenProps> = () => {
  const asyncDispatch = useAsyncDispatch();
  const [isAccepting, setIsAccepting] = React.useState<boolean>(true);

  const {accountId} = useParams<AcceptAdminInviteRouteParams>();
  assertExists(accountId, 'accountId must be part of the route params');

  React.useEffect(() => {
    dvpApi
      .acceptUserInvite({accountId})
      .then(() => {
        return unifiedAccountApi.getMyProductUser();
      })

      .then(({data: productUser}) => {
        const selectedUnifiedAccountId = Object.keys(productUser.unifiedAccounts).find(
          (unifiedAccountId) => {
            return (
              productUser.unifiedAccounts[unifiedAccountId].productAccounts.development
                ?.productAccountId === accountId
            );
          }
        );

        assertExists(
          selectedUnifiedAccountId,
          'By accepting the invite you must have access to the account'
        );

        return asyncDispatch(selectUnifiedAccount({
          authUserId: productUser.authUserId,
          unifiedAccountId: selectedUnifiedAccountId,
        }));
      })
      .then(() => {
        return asyncDispatch(refreshUserContext());
      })
      .then(() => {
        setIsAccepting(false);
      })
      .catch((err) => {
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      });
  }, [accountId]);

  if (isAccepting) {
    return <LoadingScreen />;
  }

  return <Redirect to="/" />;
};
