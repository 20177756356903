"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeaderSearch = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _searchInput = require("../searchInput/searchInput");
var _breakpoints = require("../../styles/breakpoints");
var _headerSearch = require("./headerSearch.styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var PlaceholderText;
(function (PlaceholderText) {
  PlaceholderText["Mobile"] = "Search...";
  PlaceholderText["Desktop"] = "Search here for candidates or openings...";
})(PlaceholderText || (PlaceholderText = {}));
const HeaderSearch = ({
  searchAction
}) => {
  const [placeholderText, setPlaceholderText] = React.useState(PlaceholderText.Desktop);
  const handleSearchAction = React.useCallback(inputValue => {
    if (inputValue) {
      searchAction(inputValue);
    }
  }, []);
  const updatePlaceholderText = React.useCallback(() => {
    const isMobile = window.matchMedia(_breakpoints.mediaQueries.smallOrLower.media).matches;
    const textToUse = isMobile ? PlaceholderText.Mobile : PlaceholderText.Desktop;
    setPlaceholderText(textToUse);
  }, []);
  React.useEffect(updatePlaceholderText, []);
  window.addEventListener('resize', updatePlaceholderText);
  return (0, _jsxRuntime.jsx)(_headerSearch.SearchBox, {
    children: (0, _jsxRuntime.jsx)(_searchInput.SearchInput, {
      inputId: "header-search",
      onSubmitAction: handleSearchAction,
      placeholder: placeholderText,
      searchLabel: "search"
    })
  });
};
exports.HeaderSearch = HeaderSearch;