import * as React from 'react';
import {UseFormMethods, useFormContext} from 'react-hook-form';
import {
  buildApproachTeamReportItemFormFields,
  buildAttributeTeamReportItemFormFields,
  buildOverviewTeamReportItemFormFields,
} from '>components/contentManagement/form/teamReport/formBuilder';
import {FormBuilder, FormBuilderField} from '>shared/components/form/formBuilder';
import {TeamReportContentType, TeamReportItem, TypologyContent} from '>generated/dvp.types';
import {assertUnreachable} from 'wnd-util/lib/assert';

export type AllTeamReportItemFormFields =
  | OverviewTeamReportItemFormFields
  | ApproachTeamReportItemFormFields
  | AttributeTeamReportItemFormFields;

export type AllTeamReportItemFormMethods = UseFormMethods<AllTeamReportItemFormFields>;

export interface OverviewTeamReportItemFormFields {
  cardTitle: string;
  introDynamicInterpretation: string;
  cardParagraph: string;
  strengths: string;
  risks: string;
}

export interface ApproachTeamReportItemFormFields {
  introductionText: string;
  typology1?: TypologyContent;
  typology2?: TypologyContent;
  typology3?: TypologyContent;
  typology4?: TypologyContent;
  attributeSectionIntroText: string;
}

export interface AttributeTeamReportItemFormFields {
  content: string;
}

interface Props {
  teamReportItem: TeamReportItem;
}

export const TeamReportItemFormFields: React.FC<Props> = ({teamReportItem}) => {
  const {register} = useFormContext<
    OverviewTeamReportItemFormFields | ApproachTeamReportItemFormFields
  >();

  let teamReportItemFormFields: FormBuilderField[] = [];

  switch (teamReportItem.type) {
    case TeamReportContentType.Overview:
      teamReportItemFormFields = buildOverviewTeamReportItemFormFields(register);
      break;
    case TeamReportContentType.Approach:
      teamReportItemFormFields = buildApproachTeamReportItemFormFields(register);
      break;
    case TeamReportContentType.Attribute:
      teamReportItemFormFields = buildAttributeTeamReportItemFormFields(register);
      break;
    default:
      assertUnreachable(teamReportItem.type, 'Unexpected team report item type');
  }

  return <FormBuilder fields={teamReportItemFormFields} />;
};
