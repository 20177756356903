import * as React from 'react';
import {useSelector} from 'react-redux';

import {CookieNoticeBanner} from '>shared/components/cookies/cookieNoticeBanner';

import {authApi} from '>root/apis';
import {assertValidAuthState} from '>lib/assert';
import {ENABLE_COOKIES_CONSENT} from '>root/env';
import {rollbarLogger} from '>lib/logger';

export const CookieBanner: React.FC = () => {
  const authInfoStore = useSelector((state) => state.auth);
  assertValidAuthState(authInfoStore);

  const userId = authInfoStore.token.user.id;

  const [showCookiesNotice, setShowCookiesNotice] = React.useState(false);

  React.useEffect(() => {
    setShowCookiesNotice(!!ENABLE_COOKIES_CONSENT && !authInfoStore.hasAcceptedCookies);
  }, [authInfoStore]);

  const handleAcceptCookies = React.useCallback(async () => {
    try {
      await authApi.acceptCookiesNotice({userId});

      setShowCookiesNotice(false);
    } catch (err) {
      rollbarLogger.error(err as Error);
    }
  }, [authInfoStore.token.user, userId]);

  return <>{showCookiesNotice && <CookieNoticeBanner onAcceptCookies={handleAcceptCookies} />}</>;
};
