import * as React from 'react';

import {personAddIcon, uploadFileIcon} from '>shared/components/icon/icons';
import {DropdownButton} from '>shared/components/dropdownButton/dropdownButton';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {mediumOrHigher, smallOrLower} from '>shared/styles/mixins/breakpoints';
import {
  DrawerModalType,
  useEmployeeManagementDrawerModal,
} from '>root/hooks/useEmployeeManagementDrawerModal';

import {assertAccountUserExists} from '>lib/assert';
import {AddEditEmployeeModal, EmployeeModalState} from '>components/modal/addEditEmployee';
import {BulkImportModal} from '>components/modal/bulkImport';
import {useAccountUser} from '>root/lib/user';
import {Button, ButtonType} from '>shared/components/button/button';

export const AddEmployeeButton = () => {
  const accountUser = useAccountUser();
  assertAccountUserExists(accountUser);

  const {
    isDrawerModalOpen,
    modalType,
    setModalType,
    setIsDrawerModalOpen,
  } = useEmployeeManagementDrawerModal();
  const showImportButton = accountUser.permissions.canBulkUploadEmployees;

  const openAddEmployeeModal = React.useCallback(() => {
    setModalType(DrawerModalType.AddEmployee);
    setIsDrawerModalOpen(true);
  }, []);

  const openBulkImportModal = React.useCallback(() => {
    setModalType(DrawerModalType.BulkImport);
    setIsDrawerModalOpen(true);
  }, []);

  const menuItems: MenuLinkItem[] = [
    {
      id: 'add-individual-employee',
      icon: personAddIcon,
      text: 'Add employee',
      hoverStyle: true,
      action: openAddEmployeeModal,
    },
  ];

  showImportButton &&
    menuItems.push({
      id: 'bulk-upload-employees',
      icon: uploadFileIcon,
      text: 'Import list',
      hoverStyle: true,
      action: openBulkImportModal,
    });

  return (
    <>
      <DropdownButton
        css={mediumOrHigher}
        buttonText="Add employee"
        buttonIcon={personAddIcon}
        dropdownMenuItems={menuItems}
        qaAttribute="Add Employee"
        closeOnSelect
      />
      <AddEditEmployeeModal
        modalState={EmployeeModalState.Add}
        isOpen={isDrawerModalOpen && modalType === DrawerModalType.AddEmployee}
        onClose={() => setIsDrawerModalOpen(false)}
      />
      <BulkImportModal
        isOpen={isDrawerModalOpen && modalType === DrawerModalType.BulkImport}
        onClose={() => setIsDrawerModalOpen(false)}
      />
      <Button
        onClick={openAddEmployeeModal}
        data-qa-button="Add Employee"
        buttonType={ButtonType.Tertiary}
        icon={personAddIcon}
        css={smallOrLower}
      ></Button>
    </>
  );
};
