"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XSMALL_UPPER_BOUND = exports.ScreenSize = exports.SMALL_UPPER_BOUND = exports.MEDIUM_UPPER_BOUND = exports.LARGE_UPPER_BOUND = void 0;
exports.getCurrentScreenSize = getCurrentScreenSize;
exports.respondTo = exports.mediaQueries = void 0;
const pixelToEm = pixels => {
  return `${pixels / 16}em`;
};
const XSMALL_UPPER_BOUND = exports.XSMALL_UPPER_BOUND = 415;
const SMALL_UPPER_BOUND = exports.SMALL_UPPER_BOUND = 768;
const MEDIUM_UPPER_BOUND = exports.MEDIUM_UPPER_BOUND = 1280;
const LARGE_UPPER_BOUND = exports.LARGE_UPPER_BOUND = 1440;
const xSmallToSmall = pixelToEm(XSMALL_UPPER_BOUND);
const smallToMedium = pixelToEm(SMALL_UPPER_BOUND);
const mediumToLarge = pixelToEm(MEDIUM_UPPER_BOUND);
const largeToXLarge = pixelToEm(LARGE_UPPER_BOUND);
function mediaQuery(config) {
  if (config.min && config.max) {
    throw new Error('min and max bounds not supported');
  } else if (config.min) {
    return `(min-width: ${config.min})`;
  } else if (config.max) {
    return `not all and (min-width: ${config.max})`;
  } else {
    return 'all';
  }
}
function bounds(config) {
  const rulesString = mediaQuery(config);
  return `@media ${rulesString}`;
}
const respondTo = exports.respondTo = {
  xSmallOrLower: bounds({
    max: xSmallToSmall
  }),
  xSmallOrHigher: bounds({}),
  // Mobile
  smallOrLower: bounds({
    max: smallToMedium
  }),
  smallOrHigher: bounds({
    min: xSmallToSmall
  }),
  // Tablet
  mediumOrLower: bounds({
    max: mediumToLarge
  }),
  mediumOrHigher: bounds({
    min: smallToMedium
  }),
  // Desktop
  largeOrLower: bounds({
    max: largeToXLarge
  }),
  largeOrHigher: bounds({
    min: mediumToLarge
  }),
  // Large Desktop
  xLargeOrLower: bounds({}),
  xLargeOrHigher: bounds({
    min: largeToXLarge
  })
};
const mediaQueries = exports.mediaQueries = {
  xSmallOrLower: window.matchMedia(mediaQuery({
    max: xSmallToSmall
  })),
  xSmallOrHigher: window.matchMedia(mediaQuery({})),
  // Mobile
  smallOrLower: window.matchMedia(mediaQuery({
    max: smallToMedium
  })),
  smallOrHigher: window.matchMedia(mediaQuery({
    min: xSmallToSmall
  })),
  // Tablet
  mediumOrLower: window.matchMedia(mediaQuery({
    max: mediumToLarge
  })),
  mediumOrHigher: window.matchMedia(mediaQuery({
    min: smallToMedium
  })),
  // Desktop
  largeOrLower: window.matchMedia(mediaQuery({
    max: largeToXLarge
  })),
  largeOrHigher: window.matchMedia(mediaQuery({
    min: mediumToLarge
  })),
  // Large Desktop
  xLargeOrLower: window.matchMedia(mediaQuery({})),
  xLargeOrHigher: window.matchMedia(mediaQuery({
    min: largeToXLarge
  }))
};
var ScreenSize;
(function (ScreenSize) {
  ScreenSize[ScreenSize["XSmall"] = 0] = "XSmall";
  ScreenSize[ScreenSize["Small"] = 1] = "Small";
  ScreenSize[ScreenSize["Medium"] = 2] = "Medium";
  ScreenSize[ScreenSize["Large"] = 3] = "Large";
  ScreenSize[ScreenSize["XLarge"] = 4] = "XLarge";
})(ScreenSize || (exports.ScreenSize = ScreenSize = {}));
function getCurrentScreenSize() {
  if (mediaQueries.xSmallOrLower.matches) {
    return ScreenSize.XSmall;
  } else if (mediaQueries.smallOrLower.matches) {
    return ScreenSize.Small;
  } else if (mediaQueries.mediumOrLower.matches) {
    return ScreenSize.Medium;
  } else if (mediaQueries.largeOrLower.matches) {
    return ScreenSize.Large;
  }
  return ScreenSize.XLarge;
}