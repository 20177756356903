import {RollbarLogger} from 'wnd-logger/lib/browser';
import {GIT_HASH} from '>shared/lib/config';

import {LOGGER_ACCESS_TOKEN, LOGGER_ENABLED, LOGGER_ENV} from '../env';

const config = {
  useErrorMonitor: LOGGER_ENABLED,
  accessToken: LOGGER_ACCESS_TOKEN,
  environment: LOGGER_ENV,
  gitHash: GIT_HASH,
};

export const rollbarLogger = new RollbarLogger(config);
