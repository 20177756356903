/*
    DO NOT MODIFY THIS FILE. THIS FILE WAS AUTOMATICALLY GENERATED BY WND-SWAGGER-TS-CLIENT-GENERATOR
*/

/* eslint-disable */
import * as generatedTypes from './notification.types';

import {httpClient, RequestOptions, Response, toBearer, buildJsonPatch} from 'wnd-util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function pick(params: Record<string, unknown>, fields: string[]): Record<string, string> {
  return fields.reduce((acc, key) => {
    if (params[key] !== undefined) {
      acc[key] = params[key];
    }
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as Record<string, any>);
}

interface Options {
  // If provided, gets access token during each request
  getAuthToken?(): Promise<string>;
}

export class Api {
  private client = httpClient.create();

  constructor(private rootUrl: string, private options: Options = {}) {
    this.options = options;
  }

  setAccessToken(accessToken: string): void {
    if (this.options.getAuthToken) {
      throw new Error('Should not call setAccessToken method when getAuthToken method is provided');
    }

    this.client.setAuthToken(accessToken);
  }

  clearAccessToken(): void {
    if (this.options.getAuthToken) {
      throw new Error(
        'Should not call clearAccessToken method when getAuthToken method is provided'
      );
    }

    this.client.clearAuthToken();
  }

  private async request<RequestPayload, ResponsePayload, QueryParams>(
    requestOptions: RequestOptions<RequestPayload, QueryParams>
  ): Promise<Response<ResponsePayload>> {
    const updatedRequestOptions = {
      ...requestOptions,
    };

    updatedRequestOptions.headers = requestOptions.headers ?? {};

    if (this.options.getAuthToken) {
      const authToken = await this.options.getAuthToken();
      updatedRequestOptions.headers.Authorization = toBearer(authToken);
    }

    return this.client.request(updatedRequestOptions);
  }

  static createNotification_queryParams: string[] = [];
  async createNotification(
    body: generatedTypes.newNotification
  ): Promise<httpClient.Response<generatedTypes.notificationId & void & void>> {
    const queryObj = {};

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/notifications`,
      queryParams: queryObj,
      body,
    });
  }

  static listNotifications_queryParams: string[] = [
    'entityType',
    'entityId',
    'platform',
    'timestamp',
  ];
  async listNotifications(
    params: Record<string, unknown> & {
      entityType?: string;
      entityId?: string;
      platform?: string;
      timestamp?: string;
    }
  ): Promise<httpClient.Response<generatedTypes.notificationList & void & void>> {
    const queryObj = pick(params, Api.listNotifications_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/notifications`,
      queryParams: queryObj,
    });
  }

  static getNotificationCounts_queryParams: string[] = [];
  async getNotificationCounts(): Promise<httpClient.Response<generatedTypes.counts & void>> {
    const queryObj = {};

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/notifications/counts`,
      queryParams: queryObj,
    });
  }

  static listNotificationGroups_queryParams: string[] = ['platform'];
  async listNotificationGroups(
    params: Record<string, unknown> & {platform?: string}
  ): Promise<httpClient.Response<generatedTypes.notificationGroupList & void>> {
    const queryObj = pick(params, Api.listNotificationGroups_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/notifications/groups`,
      queryParams: queryObj,
    });
  }

  static dismissNotification_queryParams: string[] = [];
  async dismissNotification(
    params: {id: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.dismissNotification_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/notifications/${params.id}/dismiss`,
      queryParams: queryObj,
    });
  }

  static undismissNotification_queryParams: string[] = [];
  async undismissNotification(
    params: {id: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.undismissNotification_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/notifications/${params.id}/undismiss`,
      queryParams: queryObj,
    });
  }

  static dismissNotifications_queryParams: string[] = ['entityType', 'entityId', 'timestamp'];
  async dismissNotifications(
    params: Record<string, unknown> & {entityType?: string; entityId?: string; timestamp?: string}
  ): Promise<httpClient.Response<generatedTypes.dismissalId & void & void>> {
    const queryObj = pick(params, Api.dismissNotifications_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/notifications/dismiss`,
      queryParams: queryObj,
    });
  }

  static undismissNotifications_queryParams: string[] = [];
  async undismissNotifications(
    params: {dismissalId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.undismissNotifications_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/notifications/dismiss/${params.dismissalId}`,
      queryParams: queryObj,
    });
  }

  static deleteNotification_queryParams: string[] = ['entityUrl'];
  async deleteNotification(
    params: {entityType: string; entityId: string; eventName: string} & {entityUrl?: string}
  ): Promise<httpClient.Response<void & void>> {
    const queryObj = pick(params, Api.deleteNotification_queryParams);

    return this.request({
      method: 'delete',
      url: `${this.rootUrl}/notifications/${params.entityType}/${params.entityId}/${params.eventName}`,
      queryParams: queryObj,
    });
  }

  static publishActivityFeedNotification_queryParams: string[] = [];
  async publishActivityFeedNotification(
    body: generatedTypes.newActivityFeedItem,
    params: {unifiedAccountId: string} & Record<string, unknown>
  ): Promise<httpClient.Response<generatedTypes.activityFeedItem & void>> {
    const queryObj = pick(params, Api.publishActivityFeedNotification_queryParams);

    return this.request({
      method: 'post',
      url: `${this.rootUrl}/activityFeed/${params.unifiedAccountId}`,
      queryParams: queryObj,
      body,
    });
  }

  static getActivityFeedItems_queryParams: string[] = ['fetchAfterDate'];
  async getActivityFeedItems(
    body: generatedTypes.activityFeedFilters,
    params: {unifiedAccountId: string} & {fetchAfterDate?: string}
  ): Promise<httpClient.Response<generatedTypes.activityFeed & void & void>> {
    const queryObj = pick(params, Api.getActivityFeedItems_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/activityFeed/${params.unifiedAccountId}`,
      queryParams: queryObj,
      body,
    });
  }

  static getPusherChannelAccess_queryParams: string[] = ['socketId'];
  async getPusherChannelAccess(
    params: {unifiedAccountId: string} & {socketId?: string}
  ): Promise<httpClient.Response<Record<string, unknown> & void>> {
    const queryObj = pick(params, Api.getPusherChannelAccess_queryParams);

    return this.request({
      method: 'get',
      url: `${this.rootUrl}/activityFeed/${params.unifiedAccountId}/pusherChannel`,
      queryParams: queryObj,
    });
  }
}
