import * as React from 'react';

import {AbstractReport} from 'wnd-dvp-reports';
import {BookLayout} from 'wnd-dvp-reports';
import {findSectionById} from 'wnd-dvp-reports';
import type {ValueType} from 'react-select';

import {CategoryLabel} from '>shared/components/book/sidebar/categoryLabel';
import {Group, Item} from '>shared/components/book/sidebar/contents';
import {FontIcon} from '>shared/components/icon/icon';
import {Icons} from '>shared/styles/icon-types';
import {Measure, MeasureType} from '>components/book/sidebar/measure';
import {Option, SelectVariant} from '>shared/components/form/rawControls/rawSelect';
import {SectionContainer} from '>shared/components/book/sidebar/sectionContainer';
import {pubSub} from '>root/events';
import {ResultsProps} from 'wnd-dvp-reports';
import {assertExists} from 'wnd-util/lib/assert';
import {Chapter} from 'wnd-dvp-reports';
import {
  BookIndexContainer,
  ChapterSelectContainer,
  CollapsibleButton,
  CollapsibleButtonContainer,
  CollapsibleButtonIcon,
  TableOfContentsContainer,
} from './bookIndex.styles';
import { useSelector } from 'react-redux';
import {ChapterSelectDropdown} from '>components/contentManagement/navigation/styles';

interface BookProps {
  className?: string;
  bookLayout: BookLayout;
  activeChapterId: string;
  activeSectionId: string;
  onChapterChange(chapter: string): void;
}

const TableOfContentsContext = React.createContext<
  | {
      isCollapsed: boolean;
      activeChapterId: string;
      activeSectionId: string;
      currentChapter: Chapter;
    }
  | undefined
>(undefined);

export interface ChapterSelectOptions {
  id: string;
  abbreviatedTitle: string;
} 

const ChapterLine: React.FC<ResultsProps['Chapter']> = React.memo(({id, children}) => {
  const context = React.useContext(TableOfContentsContext);
  assertExists(context, 'This component must exist within a TableOfContentsContext provider');

  const {activeChapterId, isCollapsed} = context;

  if (id === activeChapterId) {
    return (
      <TableOfContentsContainer isCollapsed={isCollapsed}>
        <Group>{children}</Group>
      </TableOfContentsContainer>
    );
  }
  return <React.Fragment></React.Fragment>;
});

const Category: React.FC<ResultsProps['Category']> = React.memo(({title, children}) => (
  <>
    <Item>
      <CategoryLabel>{title}</CategoryLabel>
    </Item>
    <Group>{children}</Group>
  </>
));

const MeasureLine: React.FC<ResultsProps['Measure']> = React.memo(({title, score, constructDomain, id, flags}) => {
  const context = React.useContext(TableOfContentsContext);
  assertExists(context, 'This component must exist with a TableOfContentsContext provider');

  const {currentChapter, activeSectionId} = context;

  const section = findSectionById(currentChapter, id);

  const measureRef = React.useRef<HTMLAnchorElement>(null);

  const isActive = section.id === activeSectionId;
  const bucketMapping = useSelector(state => state.interpretationBuckets.bucketMapping);

  return (
    <Item>
      <Measure
        ref={measureRef}
        active={isActive}
        constructDomain={constructDomain}
        bucketMapping={bucketMapping}
        name={title}
        score={score}
        measureType={MeasureType.Normal}
        sectionId={section.id}
        spotlightFlags={flags}
      />
    </Item>
  );
});

const Section: React.FC<ResultsProps['Section']> = React.memo(({title, id}) => {
  const context = React.useContext(TableOfContentsContext);
  assertExists(context, 'This component must exist within a TableOfContentsContext provider');

  const {currentChapter, activeSectionId} = context;
  const s = findSectionById(currentChapter, id);

  return (
    <Item key={id}>
      <SectionContainer
        active={s.id === activeSectionId}
        sectionId={s.id}
        prefix={<FontIcon icon={Icons.Book} />}
      >
        {title}
      </SectionContainer>
    </Item>
  );
});

export const BookIndex: React.FC<BookProps> = ({
  className,
  bookLayout,
  activeChapterId,
  activeSectionId,
  onChapterChange,
}) => {
  const currentChapter = React.useMemo(() => {
    return (
      bookLayout.chapters.find((chapter) => chapter.id === activeChapterId) ??
      bookLayout.chapters[0]
    );
  }, [activeChapterId, bookLayout]);

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const bucketMapping = useSelector(state => state.interpretationBuckets.bucketMapping);

  return (
    <BookIndexContainer className={className}>
      <ChapterSelectContainer>
        <ChapterSelect
          options={bookLayout.chapters}
          currentChapter={activeChapterId}
          onChapterChange={onChapterChange}
        />
      </ChapterSelectContainer>
      <TableOfContentsContext.Provider
        value={{activeChapterId, activeSectionId, currentChapter, isCollapsed}}
      >
        <AbstractReport
          layout={bookLayout}
          Section={Section}
          Chapter={ChapterLine}
          Category={Category}
          Measure={MeasureLine}
          bucketMapping={bucketMapping}
        />
      </TableOfContentsContext.Provider>
      <CollapsibleButtonContainer>
        <CollapsibleButton
          isCollapsed={isCollapsed}
          onClick={() => {
            setIsCollapsed((c) => !c);
          }}
        >
          <CollapsibleButtonIcon isCollapsed={isCollapsed} icon={Icons.ArrowDropUp} />
        </CollapsibleButton>
      </CollapsibleButtonContainer>
    </BookIndexContainer>
  );
};

export const ChapterSelect: React.FC<{
  options: ChapterSelectOptions[];
  currentChapter: string;
  onChapterChange: (chapter: string) => void;
}> = ({options, currentChapter, onChapterChange}) => {
  let chapters = React.useMemo(
    () =>
      options.map((chapter) => {
        return {
          label: chapter.abbreviatedTitle,
          value: chapter.id,
        };
      }),
    [options]
  );

  const value = React.useMemo(() => {
    return chapters.find((chapter) => chapter.value === currentChapter);
  }, [chapters, currentChapter]);

  const onSelectChange = React.useCallback(
    (value: ValueType<Option, false>) => {
      if (value) {
        onChapterChange(value.value);

        pubSub.emit('chapterUpdated');
      }
    },
    [onChapterChange]
  );

  return (
    <ChapterSelectDropdown
      options={chapters}
      value={value}
      isSearchable={false}
      onSelectChange={onSelectChange}
      variant={SelectVariant.alternate}
    />
  );
};
