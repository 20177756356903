import {AsyncThunk, createAction} from '@reduxjs/toolkit';
import {IntercomState, PendoState} from '../reducers/vendors';
import {createAppThunk} from 'wnd-react-utils/lib/thunk';
import {ThunkApiConfig} from '../thunk';

export const updatePendoState = createAction<PendoState>('@wnd/vendors/updatePendoState');
export const updateIntercomState = createAction<IntercomState>('@wnd/vendors/updateIntercomState');

export const refreshIntercomPage: AsyncThunk<void, void, ThunkApiConfig> = createAppThunk(
  '@wnd/vendors/refreshIntercomPage',
  async (_, thunkApi) => {
    const intercomState = thunkApi.getState().vendors.intercomState;

    if (intercomState === IntercomState.BOOTED) {
      // Signal an update with no changes to the visitor/account payload so intercom will update its Recent Page Views
      window.Intercom('update');
    }
  }
);
