import {UserUnifiedAccount} from '>generated/unifiedAccount.types';
import {isValidAuthState} from '>lib/auth';
import {assertValidAuthState} from '>lib/assert';

import {store} from '>root/store/main';
import {toolkitRoles} from '>root/generated/auth.types';
import {Products} from '>shared/components/menu/headerAppMenu';
import {buildAppMenuItems} from '>shared/lib/appMenuBuilder';

export const getAppMenuItems = (renderForMobile?: boolean) => {
  let toolkitRoles: toolkitRoles | undefined;
  const authState = store.getState().auth;

  if (isValidAuthState(authState)) {
    assertValidAuthState(authState);
    toolkitRoles = authState.toolkitRoles;
  }

  const productUser = store.getState().user.productUser;
  let productAccounts,
    canManageBilling = false;

  if (productUser) {
    const unifiedAccounts = productUser.unifiedAccounts as Record<string, UserUnifiedAccount>;
    const selectedUnifiedAccount = unifiedAccounts[productUser.selectedUnifiedAccountId];
    productAccounts = selectedUnifiedAccount.productAccounts;

    canManageBilling = Boolean(
      unifiedAccounts[productUser.selectedUnifiedAccountId].permissions.canManageBilling
    );
  }

  return buildAppMenuItems(
    productAccounts,
    toolkitRoles ? Object.keys(toolkitRoles) : [],
    renderForMobile,
    Products.WonderlicDevelop,
    canManageBilling
  );
};
