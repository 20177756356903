import React from 'react';
import {
  TeamReportApproachContent,
  TeamReportAttributeContent,
  TeamReportContentType,
  TeamReportDomain,
  TeamReportItem,
  TeamReportOverviewContent,
} from '>generated/dvp.types';
import {assertExists, assertUnreachable} from 'wnd-util/lib/assert';
import {TypologyCard} from '>components/teams/typologyCard';
import {
  AbstractTeamReportApproachSubsection,
  AbstractTeamReportAttributeIntroSubsection,
  AbstractTeamReportSingleAttributeSubsection,
  AttributeContent,
  CompetenciesSourceConstruct,
  InterestsSourceConstruct,
  PersonalitySourceConstruct,
  Typology,
  TypologyQuadrant,
  ViewType,
  renderText,
} from 'wnd-dvp-reports';
import {sharedTeamReportComponents} from '>lib/teamReportComponents';
import {sharedComponents} from '>lib/markdownSectionComponents';
import {Paragraph} from '>shared/components/typography/baseTypography2';

interface Props {
  allTeamReportItems: TeamReportItem[];
  selectedTeamReportItem: TeamReportItem;
  templateContext: Record<string, any>;
}

interface AttributeContentWithSourceConstruct extends AttributeContent {
  sourceConstruct:
    | CompetenciesSourceConstruct
    | InterestsSourceConstruct
    | PersonalitySourceConstruct;
}

export const TeamReportItemView: React.FC<Props> = ({
  allTeamReportItems,
  selectedTeamReportItem,
  templateContext,
}) => {
  const TeamReportItemView = React.useMemo(() => {
    switch (selectedTeamReportItem.type) {
      case TeamReportContentType.Overview:
        return overviewItemView(selectedTeamReportItem, templateContext);
      case TeamReportContentType.Approach:
        return approachItemView(allTeamReportItems, selectedTeamReportItem, templateContext);
      case TeamReportContentType.Attribute:
        return attributeItemView(allTeamReportItems, selectedTeamReportItem, templateContext);
      default:
        assertUnreachable();
    }
  }, [allTeamReportItems, selectedTeamReportItem, templateContext]);

  return TeamReportItemView;
};

const STRENGTHS_HEADING = 'STRENGTHS';
const RISKS_HEADING = 'RISKS';

const overviewItemView = (teamReportItem: TeamReportItem, templateContext: Record<string, any>) => {
  const content = teamReportItem.content as TeamReportOverviewContent;

  let typologyOrMotivator;
  let communicationIntroDynamicInterpretation = '<communication intro>';
  let workIntroDynamicInterpretation = '<work intro>';
  let challengeIntroDynamicInterpretation = '<challenge intro>';

  switch (content.domain) {
    case TeamReportDomain.Communication:
      typologyOrMotivator = {
        name: content.domain,
        quadrant: templateContext.typologies.communication,
      };
      communicationIntroDynamicInterpretation = renderText(
        content.introDynamicInterpretation,
        templateContext
      );
      break;
    case TeamReportDomain.Work:
      typologyOrMotivator = {name: content.domain, quadrant: templateContext.typologies.challenge};
      workIntroDynamicInterpretation = renderText(
        content.introDynamicInterpretation,
        templateContext
      );
      break;
    case TeamReportDomain.Challenge:
      typologyOrMotivator = {name: content.domain, quadrant: templateContext.typologies.work};
      challengeIntroDynamicInterpretation = renderText(
        content.introDynamicInterpretation,
        templateContext
      );
      break;
    case TeamReportDomain.Drivers:
      typologyOrMotivator = templateContext.highestMotivator;
      break;
    default:
      assertUnreachable();
  }

  const cardHeading =
    content.domain === TeamReportDomain.Drivers
      ? 'What Drives This Team'
      : `How This Team ${content.cardTitle}`;

  return (
    <>
      <Paragraph>
        {`Your team tends to ${communicationIntroDynamicInterpretation}, ${workIntroDynamicInterpretation}, and ${challengeIntroDynamicInterpretation}.`}
      </Paragraph>
      <TypologyCard
        heading={cardHeading}
        domain={content.domain}
        typologyOrMotivator={typologyOrMotivator}
        bodyContent={renderText(content.cardParagraph, templateContext)}
        strengthHeading={STRENGTHS_HEADING}
        riskHeading={RISKS_HEADING}
        strengths={renderText(content.strengths, templateContext)}
        risks={renderText(content.risks, templateContext)}
        goToReport={() => {}}
      />
    </>
  );
};

function approachItemView(
  allTeamReportItems: TeamReportItem[],
  selectedTeamReportItem: TeamReportItem,
  templateContext: Record<string, any>
) {
  const section = buildApproachSection(
    selectedTeamReportItem,
    allTeamReportItems,
    templateContext
  ) as any;

  return (
    <>
      <AbstractTeamReportApproachSubsection
        sharedComponents={sharedComponents}
        teamReportComponents={sharedTeamReportComponents}
        teamReportSection={section}
      />
      <br />
      <AbstractTeamReportAttributeIntroSubsection
        sharedComponents={sharedComponents}
        teamReportComponents={sharedTeamReportComponents}
        teamReportSection={section}
      />
    </>
  );
}

function attributeItemView(
  allTeamReportItems: TeamReportItem[],
  selectedTeamReportItem: TeamReportItem,
  templateContext: Record<string, any>
) {
  const content = selectedTeamReportItem.content as TeamReportAttributeContent;

  const domainApproachItem = allTeamReportItems.find(
    (item) =>
      item.type === TeamReportContentType.Approach &&
      item.content.domain === selectedTeamReportItem.content.domain
  );

  if (!domainApproachItem) {
    return null;
  }

  const section = buildApproachSection(domainApproachItem, allTeamReportItems, templateContext);

  const attributeContent = section.subSections.attributes.content.find(
    (content) =>
      (content as AttributeContentWithSourceConstruct).sourceConstruct ===
      (selectedTeamReportItem.content as TeamReportAttributeContent).sourceConstruct
  ) as AttributeContentWithSourceConstruct;

  const mergedAttributeContent = {
    ...attributeContent,
    description: renderText(content.description, templateContext),
    bodyContent: renderText(content.content, templateContext),
  };

  return (
    <AbstractTeamReportSingleAttributeSubsection
      teamReportComponents={sharedTeamReportComponents}
      sharedComponents={sharedComponents}
      domain={section.domain as TeamReportDomain}
      attribute={mergedAttributeContent}
      viewType={ViewType.Web}
    />
  );
}

function buildApproachSection(
  selectedTeamReportItem: TeamReportItem,
  allTeamReportItems: TeamReportItem[],
  templateContext: Record<string, any>
) {
  let typologyContent;
  const content = selectedTeamReportItem.content as TeamReportApproachContent;
  const domain = selectedTeamReportItem.content.domain;
  const selectedTypologyQuadrant = templateContext.typologies[domain.toLowerCase()];
  const attributesContent = getAttributesContent(allTeamReportItems, domain);

  switch (domain) {
    case TeamReportDomain.Communication:
      typologyContent = getTypologyMappings(content, selectedTypologyQuadrant);
      return buildCommunicationApproachSection(
        content,
        attributesContent,
        typologyContent,
        templateContext
      );
    case TeamReportDomain.Work:
      typologyContent = getTypologyMappings(content, selectedTypologyQuadrant);
      return buildWorkApproachSection(content, attributesContent, typologyContent, templateContext);
    case TeamReportDomain.Challenge:
      typologyContent = getTypologyMappings(content, selectedTypologyQuadrant);
      return buildChallengeApproachSection(
        content,
        attributesContent,
        typologyContent,
        templateContext
      );
    case TeamReportDomain.Drivers:
      return buildDriversApproachSection(content, attributesContent, templateContext);
    default:
      assertUnreachable();
  }
}

interface TypologyMappings {
  selectedTypology: Typology;
  allTypologies: Typology[];
}

function getTypologyMappings(
  content: TeamReportApproachContent,
  selectedQuadrant: TypologyQuadrant
): TypologyMappings {
  assertExists(content.typology1, 'Typology 1 not found');
  assertExists(content.typology2, 'Typology 2 not found');
  assertExists(content.typology3, 'Typology 3 not found');
  assertExists(content.typology4, 'Typology 4 not found');

  const allTypologies = [
    {
      name: content.typology1.displayName,
      quadrant: TypologyQuadrant.TypeI,
      typeInterpretation: content.typology1.typeInterpretation,
    },
    {
      name: content.typology2.displayName,
      quadrant: TypologyQuadrant.TypeII,
      typeInterpretation: content.typology2.typeInterpretation,
    },
    {
      name: content.typology3.displayName,
      quadrant: TypologyQuadrant.TypeIII,
      typeInterpretation: content.typology3.typeInterpretation,
    },
    {
      name: content.typology4.displayName,
      quadrant: TypologyQuadrant.TypeIV,
      typeInterpretation: content.typology4.typeInterpretation,
    },
  ];

  const selectedTypology = allTypologies.find((typology) => typology.quadrant === selectedQuadrant);

  assertExists(selectedTypology, 'Selected typology not found');

  return {
    selectedTypology,
    allTypologies,
  };
}

function getAttributesContent(allTeamReportItems: TeamReportItem[], domain: TeamReportDomain) {
  const attributesContent = allTeamReportItems
    .filter(
      (item) => item.type === TeamReportContentType.Attribute && item.content.domain === domain
    )
    .map((item) => item.content) as TeamReportAttributeContent[];

  return attributesContent;
}

function buildCommunicationApproachSection(
  content: TeamReportApproachContent,
  communicationAttributesContent: TeamReportAttributeContent[],
  typologyContent: TypologyMappings,
  templateContext: Record<string, any>
) {
  const {allTypologies, selectedTypology} = typologyContent;

  return {
    domain: 'Communication',
    title: 'How we communicate and interact',
    abbreviatedTitle: 'Communication & Interaction',
    introTitle: 'Communicates and Interacts',
    introText: renderText(content.introductionText, templateContext),
    subSections: {
      attributes: {
        title: 'Communication and Interaction',
        intro: renderText(content.attributeSectionIntroText, templateContext),
        spotlights: [
          {
            score: 86,
            constructDomain: 'competency',
            title: 'Communication',
          },
          {
            score: 27,
            constructDomain: 'personality',
            title: 'Compassion',
          },
          {
            score: 24,
            constructDomain: 'personality',
            title: 'Enthusiasm',
          },
          {
            score: 89,
            constructDomain: 'competency',
            title: 'Teamwork',
          },
        ],
        content: [
          {
            sourceConstruct: CompetenciesSourceConstruct.Communication,
            title: 'Communication',
            teamAverage: 86,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 86},
              {firstName: 'Bb', lastName: 'Bb', score: 86},
              {firstName: 'Cc', lastName: 'Cc', score: 86},
            ],
            bodyContent: renderText(
              communicationAttributesContent.find(
                (item) => item?.sourceConstruct === CompetenciesSourceConstruct.Communication
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: PersonalitySourceConstruct.Compassion,
            title: 'Compassion',
            teamAverage: 27,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 12},
              {firstName: 'Bb', lastName: 'Bb', score: 38},
              {firstName: 'Cc', lastName: 'Cc', score: 31},
            ],
            bodyContent: renderText(
              communicationAttributesContent.find(
                (item) => item?.sourceConstruct === PersonalitySourceConstruct.Compassion
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: PersonalitySourceConstruct.Enthusiasm,
            title: 'Enthusiasm',
            teamAverage: 24,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 7},
              {firstName: 'Bb', lastName: 'Bb', score: 44},
              {firstName: 'Cc', lastName: 'Cc', score: 19},
            ],
            bodyContent: renderText(
              communicationAttributesContent.find(
                (item) => item?.sourceConstruct === PersonalitySourceConstruct.Enthusiasm
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: CompetenciesSourceConstruct.TeamworkAndCollaboration,
            title: 'Teamwork',
            teamAverage: 89,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 89},
              {firstName: 'Bb', lastName: 'Bb', score: 89},
              {firstName: 'Cc', lastName: 'Cc', score: 89},
            ],
            bodyContent: renderText(
              communicationAttributesContent.find(
                (item) =>
                  item?.sourceConstruct === CompetenciesSourceConstruct.TeamworkAndCollaboration
              )?.content ?? '',
              templateContext
            ),
          },
        ],
      },
    },
    selectedTypology,
    allTypologies,
    balancerContent: [
      {
        typology: allTypologies[0],
        content: renderText(content.typology1?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[1],
        content: renderText(content.typology2?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[2],
        content: renderText(content.typology3?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[3],
        content: renderText(content.typology4?.balancerParagraph ?? '', templateContext),
      },
    ],
    quadrantScores: {
      individualScores: [
        {firstName: 'Aa', lastName: 'Aa', x: 1, y: 86},
        {firstName: 'Bb', lastName: 'Bb', x: 75, y: 75},
        {firstName: 'Cc', lastName: 'Cc', x: 11, y: 28},
      ],
      teamAverageScore: {x: 29, y: 63},
    },
  };
}

function buildWorkApproachSection(
  content: TeamReportApproachContent,
  workAttributesContent: TeamReportAttributeContent[],
  typologyContent: TypologyMappings,
  templateContext: Record<string, any>
) {
  const {allTypologies, selectedTypology} = typologyContent;

  return {
    domain: 'Work',
    title: 'How we get work done together',
    abbreviatedTitle: 'Getting Work Done Together',
    introTitle: 'Gets Work Done Together',
    introText: renderText(content.introductionText, templateContext),
    subSections: {
      attributes: {
        title: 'Getting Work Done Together',
        intro: renderText(content.attributeSectionIntroText, templateContext),
        spotlights: [
          {
            score: 95,
            constructDomain: 'competency',
            title: 'Decision Making',
          },
          {
            score: 34,
            constructDomain: 'personality',
            title: 'Orderliness',
          },
          {
            score: 80,
            constructDomain: 'competency',
            title: 'Results Focus',
          },
          {
            score: 84,
            constructDomain: 'competency',
            title: 'Time Management',
          },
        ],
        content: [
          {
            sourceConstruct: CompetenciesSourceConstruct.DecisionMaking,
            title: 'Decision Making',
            teamAverage: 95,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 95},
              {firstName: 'Bb', lastName: 'Bb', score: 95},
              {firstName: 'Cc', lastName: 'Cc', score: 95},
            ],
            bodyContent: renderText(
              workAttributesContent.find(
                (item) => item?.sourceConstruct === CompetenciesSourceConstruct.DecisionMaking
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: PersonalitySourceConstruct.Orderliness,
            title: 'Orderliness',
            teamAverage: 34,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 5},
              {firstName: 'Bb', lastName: 'Bb', score: 68},
              {firstName: 'Cc', lastName: 'Cc', score: 29},
            ],
            bodyContent: renderText(
              workAttributesContent.find(
                (item) => item?.sourceConstruct === PersonalitySourceConstruct.Orderliness
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: CompetenciesSourceConstruct.ResultsMotivation,
            title: 'Results Focus',
            teamAverage: 80,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 80},
              {firstName: 'Bb', lastName: 'Bb', score: 80},
              {firstName: 'Cc', lastName: 'Cc', score: 80},
            ],
            bodyContent: renderText(
              workAttributesContent.find(
                (item) => item?.sourceConstruct === CompetenciesSourceConstruct.ResultsMotivation
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: CompetenciesSourceConstruct.TimeManagement,
            title: 'Time Management',
            teamAverage: 84,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 84},
              {firstName: 'Bb', lastName: 'Bb', score: 84},
              {firstName: 'Cc', lastName: 'Cc', score: 84},
            ],
            bodyContent: renderText(
              workAttributesContent.find(
                (item) => item?.sourceConstruct === CompetenciesSourceConstruct.TimeManagement
              )?.content ?? '',
              templateContext
            ),
          },
        ],
      },
    },
    selectedTypology,
    allTypologies,
    balancerContent: [
      {
        typology: allTypologies[0],
        content: renderText(content.typology1?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[1],
        content: renderText(content.typology2?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[2],
        content: renderText(content.typology3?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[3],
        content: renderText(content.typology4?.balancerParagraph ?? '', templateContext),
      },
    ],
    quadrantScores: {
      individualScores: [
        {firstName: 'Aa', lastName: 'Aa', x: 39, y: 59},
        {firstName: 'Bb', lastName: 'Bb', x: 23, y: 46},
        {firstName: 'Cc', lastName: 'Cc', x: 44, y: 2},
      ],
      teamAverageScore: {x: 35, y: 35},
    },
  };
}

function buildChallengeApproachSection(
  content: TeamReportApproachContent,
  challengeAttributesContent: TeamReportAttributeContent[],
  typologyMappings: TypologyMappings,
  templateContext: Record<string, any>
) {
  const {allTypologies, selectedTypology} = typologyMappings;

  return {
    domain: 'Challenge',
    title: 'How we tackle challenges',
    abbreviatedTitle: 'Tackling Challenges',
    introTitle: 'Tackles Challenges',
    introText: renderText(content.introductionText, templateContext),
    subSections: {
      attributes: {
        title: 'Tackling Challenges',
        intro: renderText(content.attributeSectionIntroText, templateContext),
        spotlights: [
          {
            score: 83,
            constructDomain: 'competency',
            title: 'Adaptability',
          },
          {
            score: 85,
            constructDomain: 'competency',
            title: 'Change Adoption',
          },
          {
            score: 66,
            constructDomain: 'personality',
            title: 'Stress Tolerance',
          },
        ],
        content: [
          {
            sourceConstruct: CompetenciesSourceConstruct.Adaptability,
            title: 'Adaptability',
            teamAverage: 83,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 83},
              {firstName: 'Bb', lastName: 'Bb', score: 83},
              {firstName: 'Cc', lastName: 'Cc', score: 83},
            ],
            bodyContent: renderText(
              challengeAttributesContent.find(
                (item) => item?.sourceConstruct === CompetenciesSourceConstruct.Adaptability
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: CompetenciesSourceConstruct.ChangeTolerance,
            title: 'Change Adoption',
            teamAverage: 85,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 85},
              {firstName: 'Bb', lastName: 'Bb', score: 85},
              {firstName: 'Cc', lastName: 'Cc', score: 85},
            ],
            bodyContent: renderText(
              challengeAttributesContent.find(
                (item) => item?.sourceConstruct === CompetenciesSourceConstruct.ChangeTolerance
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: PersonalitySourceConstruct.StressTolerance,
            title: 'Stress Tolerance',
            teamAverage: 66,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 67},
              {firstName: 'Bb', lastName: 'Bb', score: 48},
              {firstName: 'Cc', lastName: 'Cc', score: 83},
            ],
            bodyContent: renderText(
              challengeAttributesContent.find(
                (item) => item?.sourceConstruct === PersonalitySourceConstruct.StressTolerance
              )?.content ?? '',
              templateContext
            ),
          },
        ],
      },
    },
    selectedTypology,
    allTypologies,
    balancerContent: [
      {
        typology: allTypologies[0],
        content: renderText(content.typology1?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[1],
        content: renderText(content.typology2?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[2],
        content: renderText(content.typology3?.balancerParagraph ?? '', templateContext),
      },
      {
        typology: allTypologies[3],
        content: renderText(content.typology4?.balancerParagraph ?? '', templateContext),
      },
    ],
    quadrantScores: {
      individualScores: [
        {firstName: 'Aa', lastName: 'Aa', x: 69, y: 42},
        {firstName: 'Bb', lastName: 'Bb', x: 69, y: 8},
        {firstName: 'Cc', lastName: 'Cc', x: 69, y: 7},
      ],
      teamAverageScore: {x: 69, y: 19},
    },
  };
}

function buildDriversApproachSection(
  content: TeamReportApproachContent,
  driversAttributesContent: TeamReportAttributeContent[],
  templateContext: Record<string, any>
) {
  const highestMotivator = templateContext.highestMotivator;

  return {
    domain: 'Drivers',
    abbreviatedTitle: 'What Drives Us',
    title: 'Team Motivation',
    introTitle: 'What Drives This Team',
    introText: renderText(content.introductionText, templateContext),
    subSections: {
      attributes: {
        title: 'What Drives This Team',
        intro: renderText(content.attributeSectionIntroText, templateContext),
        spotlights: [
          {
            score: 50,
            constructDomain: 'interest',
            title: 'Administrative Work',
          },
          {
            score: 42,
            constructDomain: 'interest',
            title: 'Analytical Work',
          },
          {
            score: 61,
            constructDomain: 'interest',
            title: 'Creative Work',
          },
          {
            score: 92,
            constructDomain: 'interest',
            title: 'Enterprising Work',
          },
          {
            score: 52,
            constructDomain: 'interest',
            title: 'Hands-On Work',
          },
          {
            score: 46,
            constructDomain: 'interest',
            title: 'Supportive Work',
          },
        ],
        content: [
          {
            sourceConstruct: InterestsSourceConstruct.Conventional,
            title: 'Administrative Work',
            teamAverage: 50,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 84},
              {firstName: 'Bb', lastName: 'Bb', score: 55},
              {firstName: 'Cc', lastName: 'Cc', score: 11},
            ],
            bodyContent: renderText(
              driversAttributesContent.find(
                (item) => item?.sourceConstruct === InterestsSourceConstruct.Conventional
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: InterestsSourceConstruct.Investigative,
            title: 'Analytical Work',
            teamAverage: 42,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 63},
              {firstName: 'Bb', lastName: 'Bb', score: 9},
              {firstName: 'Cc', lastName: 'Cc', score: 54},
            ],
            bodyContent: renderText(
              driversAttributesContent.find(
                (item) => item?.sourceConstruct === InterestsSourceConstruct.Investigative
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: InterestsSourceConstruct.Artistic,
            title: 'Creative Work',
            teamAverage: 61,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 46},
              {firstName: 'Bb', lastName: 'Bb', score: 86},
              {firstName: 'Cc', lastName: 'Cc', score: 51},
            ],
            bodyContent: renderText(
              driversAttributesContent.find(
                (item) => item?.sourceConstruct === InterestsSourceConstruct.Artistic
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: InterestsSourceConstruct.Enterprising,
            title: 'Enterprising Work',
            teamAverage: 92,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 92},
              {firstName: 'Bb', lastName: 'Bb', score: 96},
              {firstName: 'Cc', lastName: 'Cc', score: 87},
            ],
            bodyContent: renderText(
              driversAttributesContent.find(
                (item) => item?.sourceConstruct === InterestsSourceConstruct.Enterprising
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: InterestsSourceConstruct.Realistic,
            title: 'Hands-On Work',
            teamAverage: 52,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 33},
              {firstName: 'Bb', lastName: 'Bb', score: 36},
              {firstName: 'Cc', lastName: 'Cc', score: 86},
            ],
            bodyContent: renderText(
              driversAttributesContent.find(
                (item) => item?.sourceConstruct === InterestsSourceConstruct.Realistic
              )?.content ?? '',
              templateContext
            ),
          },
          {
            sourceConstruct: InterestsSourceConstruct.Social,
            title: 'Supportive Work',
            teamAverage: 46,
            dotPlotData: [
              {firstName: 'Aa', lastName: 'Aa', score: 63},
              {firstName: 'Bb', lastName: 'Bb', score: 37},
              {firstName: 'Cc', lastName: 'Cc', score: 37},
            ],
            bodyContent: renderText(
              driversAttributesContent.find(
                (item) => item?.sourceConstruct === InterestsSourceConstruct.Social
              )?.content ?? '',
              templateContext
            ),
          },
        ],
      },
    },
    highestMotivator,
  };
}
