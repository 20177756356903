import styled from '@emotion/styled';
import {css} from '@emotion/react';

import * as colors from 'wnd-themes/lib/colorPalette';
import {textSmRegular} from '>shared/components/typography/designSystemTypography';
import {tableStyles} from './styles';

const cellGap = '0.8rem';

export const EmployeeContainerStyle = css`
  ${textSmRegular};
  color: ${tableStyles.defaultFontColor};
  border-bottom: 0.1rem solid ${tableStyles.borderColor};
  gap: ${cellGap};
`;

export const DataTableRowContainer = styled.tr`
  ${EmployeeContainerStyle};

  height: 4.8rem;

  & td {
    border-bottom: 0.1rem solid ${tableStyles.borderColor};
  }

  :hover {
    background-color: ${tableStyles.hoverBackgroundColor};
    color: ${tableStyles.hoverFontColor};

    label {
      background-color: ${colors.transparent};
      color: ${tableStyles.hoverFontColor};
    }
  }
`;

export const SeparatorElement = styled.span`
  color: ${colors.cherokee};
  margin: 0 0.8rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;
