import * as React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {TextArea, TextAreaResizeEnum} from '>shared/components/form/textArea';
import {Input} from '>shared/components/form/input';
import {validateTemplateSyntax} from '>root/lib/validation';

export function buildSpotlightHomePageItemFormFields(
  register: UseFormMethods['register']
): FormBuilderFields {
  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'title',
      label: 'Title',
      component: (
        <Input
          placeholder="Title"
          ref={register({
            required: 'Title is required',
            pattern: {
              message: 'Title should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          maxLength={100}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'description',
      label: 'Description',
      component: (
        <TextArea
          placeholder="Description"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Description should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'summary',
      label: 'Summary',
      component: (
        <TextArea
          placeholder="Summary"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Summary contain should at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'roleCriticalAttributes',
      label: 'Critical Attributes',
      component: (
        <TextArea
          placeholder="Critical Attributes"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Critical attributes should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'leastEffortAttributes',
      label: 'Least Effort Attributes',
      component: (
        <TextArea
          placeholder="Least Effort Attributes"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Least effort attributes should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'mostEffortAttributes',
      label: 'Most Effort Attributes',
      component: (
        <TextArea
          placeholder="Most Effort Attributes"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Most effort attributes should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'closing',
      label: 'Closing',
      component: (
        <TextArea
          placeholder="Closing"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Closing should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'continueButtonText',
      label: 'Continue Button',
      component: (
        <Input
          placeholder="Continue Button"
          ref={register({
            pattern: {
              message: 'Continue button should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
          maxLength={100}
        />
      ),
    },
  ];
}

export function buildSecondaryHomePageItemFormFields(
  register: UseFormMethods['register']
): FormBuilderFields {
  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'title',
      label: 'Title',
      component: (
        <Input
          placeholder="Title"
          ref={register({
            required: 'Title is required',
            pattern: {
              message: 'Title should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          maxLength={100}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'description',
      label: 'Description',
      component: (
        <TextArea
          placeholder="Description"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Description should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'body',
      label: 'Body',
      component: (
        <TextArea
          placeholder="Body"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            pattern: {
              message: 'Body contain should at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'continueButtonText',
      label: 'Continue Button',
      component: (
        <Input
          placeholder="Continue Button"
          ref={register({
            pattern: {
              message: 'Continue button should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
          maxLength={100}
        />
      ),
    },
  ];
}
