"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToggleSize = exports.Toggle = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var colors = _interopRequireWildcard(require("wnd-themes/lib/colorPalette"));
var _baseTypography = require("../typography/baseTypography2");
var _icon = require("../icon/icon");
var _margins = require("../../styles/mixins/margins");
var _toggle = require("./toggle.styles");
var _verticalRhythm = require("../../styles/mixins/verticalRhythm");
var _accessibility = require("../../styles/accessibility");
const _excluded = ["label", "ariaLabel", "checkedLabel", "icon", "checkedIcon", "checked", "defaultChecked", "disabled", "error", "hideLabel", "onChange", "rightSideLabel", "className", "qaDataAttribute", "size"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var ToggleSize;
(function (ToggleSize) {
  ToggleSize[ToggleSize["Small"] = 0] = "Small";
  ToggleSize[ToggleSize["Medium"] = 1] = "Medium";
})(ToggleSize || (exports.ToggleSize = ToggleSize = {}));
const Toggle = _ref => {
  let {
      label,
      ariaLabel,
      checkedLabel,
      icon,
      checkedIcon,
      checked,
      defaultChecked,
      disabled,
      error,
      hideLabel,
      onChange,
      rightSideLabel,
      className,
      qaDataAttribute,
      size = ToggleSize.Small
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  const accessibleLabel = checked && checkedLabel ? checkedLabel : label;
  const shouldUseSmallSize = size === ToggleSize.Small;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_toggle.ToggleContainer, _objectSpread(_objectSpread({
      className: className,
      error: error?.message,
      css: _verticalRhythm.vr2
    }, rest), {}, {
      children: [(0, _jsxRuntime.jsxs)(_toggle.ToggleLabel, {
        css: hideLabel && _accessibility.visuallyHidden,
        children: [icon && (0, _jsxRuntime.jsx)(_icon.FontIcon, {
          "aria-hidden": true,
          icon: checked && checkedIcon ? checkedIcon : icon,
          css: [_margins.mR2, _icon.smallIcon],
          disabled: !checked
        }), (0, _jsxRuntime.jsx)(_baseTypography.Caption, {
          children: accessibleLabel
        })]
      }), (0, _jsxRuntime.jsxs)(_toggle.InputContainer, {
        small: shouldUseSmallSize,
        children: [(0, _jsxRuntime.jsx)(_toggle.ToggleInput, {
          "aria-label": ariaLabel ?? accessibleLabel,
          type: "checkbox",
          onChange: onChange,
          checked: checked,
          disabled: disabled,
          defaultChecked: defaultChecked,
          "data-qa-toggle": qaDataAttribute,
          small: shouldUseSmallSize
        }), (0, _jsxRuntime.jsx)(_toggle.ToggleSlider, {
          small: shouldUseSmallSize
        })]
      }), rightSideLabel && (0, _jsxRuntime.jsx)(_baseTypography.Caption, {
        children: rightSideLabel
      })]
    })), error?.message && (0, _jsxRuntime.jsx)(_baseTypography.Caption, {
      css: [_verticalRhythm.vr2, {
        color: colors.validationErrorRed
      }],
      children: error.message
    })]
  });
};
exports.Toggle = Toggle;