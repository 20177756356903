import * as React from 'react';
import {TypologyQuadrantProps} from 'wnd-dvp-reports';

import {Quadrant} from '>shared/components/charts/quadrant';
import {vr8} from '>shared/styles/mixins/verticalRhythm';

import {
  getAdjectivesForChartAxes,
  getQuadrantLabels,
  getQuadrantTheme,
} from '>lib/quadrant-helpers';

export const TypologyQuadrant: React.FC<TypologyQuadrantProps> = ({
  domain,
  selectedTypology,
  allTypologies,
  scores,
  teamAverage,
}) => {
  const theme = getQuadrantTheme(selectedTypology, domain);

  return (
    <div css={vr8}>
      <Quadrant
        data={scores}
        axisLabels={getAdjectivesForChartAxes(domain)}
        quadrantLabels={getQuadrantLabels(allTypologies)}
        teamAverage={teamAverage}
        theme={theme}
      />
    </div>
  );
};
