import {assert} from 'wnd-util';
import {AuthState, ValidAuthStateWithUser} from '>root/store/reducers/authReducer';
import {Account, AccountUser, Employee, Team} from '../generated/dvp.types';

export function assertValidAuthState(
  authState: AuthState
): asserts authState is ValidAuthStateWithUser {
  assert.assert(authState.isValidToken === true, 'No Valid Token Found');
  assert.assert(authState.isAuthenticated, 'Authentication Required');
  assert.assertExists(authState.token?.user, 'No Authenticated User');
}

export function assertAccountExists(account?: Account): asserts account {
  assert.assertExists(account, 'Account is required');
}

export function assertAccountUserExists(accountUser?: AccountUser): asserts accountUser {
  assert.assertExists(accountUser, 'Account user is required');
}

export function assertEmployeeExists(employee?: Employee): asserts employee {
  assert.assertExists(employee, 'Employee is required');
}

export function assertTeamsExists(teams?: Team[]): asserts teams {
  assert.assertExists(teams, 'Teams is required, empty or not');
}
