import React from 'react';
import styled from '@emotion/styled';
import {useSelector} from 'react-redux';

import * as colors from 'wnd-themes/lib/colorPalette';
import {BadgeColors} from 'wnd-graphics/lib/badge/badge';
import {Modal} from '>shared/components/modal/modal';
import {BadgeButton} from '>shared/components/button/badgeButton';
import {useModal} from '>shared/components/modal/useModal';
import {Input} from '>shared/components/form/input';
import {Button, ButtonType} from '>shared/components/button/button';
import {Caption} from '>shared/components/typography/baseTypography2';
import {vr6} from '>shared/styles/mixins/verticalRhythm';
import {Icon} from '>shared/components/icon/icon';
import {checkCircle, infoRoundedIcon} from '>shared/components/icon/icons';
import {ToolTip, TooltipPosition} from '>shared/components/tooltip';
import {textSmRegular} from '>shared/components/typography/designSystemTypography';
import {BadgeSize} from '>shared/components/emblems/badge';

import {AccountUserWithPII, Employee} from '>generated/dvp.types';
import {assertAccountExists} from '>lib/assert';
import {dvpApi} from '>root/apis';
import {ConfirmShareResultsModal} from './confirmShareResultsModal';
import {HttpError} from 'wnd-util';
import {redirectToErrorPage} from '>lib/redirect';

interface ShareResultsModalProps {
  employees: Employee[] | undefined;
}

export const ShareResultsModal: React.FC<ShareResultsModalProps> = ({employees}) => {
  const {hideModal, showModal} = useModal();

  const [emailToVerify, setEmailToVerify] = React.useState<string>('');
  const [verifiedUser, setVerifiedUser] = React.useState<AccountUserWithPII | Employee | undefined>(
    undefined
  );
  const [verifiedUserError, setVerifiedUserError] = React.useState<string | undefined>(undefined);
  const [employeesToShare, setEmployeesToShare] = React.useState<Employee[] | []>(employees ?? []);
  const {account} = useSelector((state) => state.account);
  assertAccountExists(account);

  const verifyEmail = React.useCallback(async () => {
    if (!emailToVerify.length) {
      setVerifiedUser(undefined);
      setVerifiedUserError(undefined);
    } else {
      let recipient;
      let isValidRecipient;

      const {data: allAccountUsers} = await dvpApi.getUsers({accountId: account.id});

      const accountUser = allAccountUsers.find(
        (user) => user.email.toLowerCase() === emailToVerify.toLowerCase().trim()
      );

      if (accountUser) {
        recipient = accountUser;
        isValidRecipient = !accountUser.isPending;
      } else {
        try {
          const {data: reponse} = await dvpApi.getEmployeeByEmail({
            accountId: account.id,
            employeeEmail: emailToVerify,
          });
          recipient = reponse;
          isValidRecipient = Boolean(reponse.userData);
        } catch (err) {
          const httpError = err as HttpError;
          if (httpError.status !== 404) {
            hideModal();
            redirectToErrorPage(
              new Error('Error encountered while trying to get employee by email')
            );
          }
        }
      }

      if (recipient) {
        if (isValidRecipient) {
          setVerifiedUser(recipient);
          setVerifiedUserError(undefined);
        } else {
          setVerifiedUser(undefined);
          setVerifiedUserError(
            "This user has not accepted their account invitation or hasn't completed testing."
          );
        }
      } else {
        setVerifiedUser(undefined);
        setVerifiedUserError(
          'Results can only be shared with other users and employees in your account'
        );
      }
    }
  }, [emailToVerify]);

  const onRemove = React.useCallback((event, id) => {
    event.preventDefault();

    setEmployeesToShare(employeesToShare.filter((emp) => emp.id !== id));
  }, []);

  return (
    <Modal
      headerOptions={{
        title: 'Share results',
      }}
    >
      <Caption css={vr6}>
        Enter the email of the individual you would like to send results to
      </Caption>
      <Input
        css={vr6}
        label="Email"
        hasError={Boolean(verifiedUserError)}
        helperText={
          verifiedUser ? (
            <VerifiedUserInformation
              verifiedUser={`${verifiedUser.firstName} ${verifiedUser.lastName}`}
            />
          ) : (
            verifiedUserError
          )
        }
        onChange={(event) => setEmailToVerify(event.target.value)}
        onBlur={verifyEmail}
        required
        value={emailToVerify}
      />
      {employeesToShare && (
        <>
          <Caption css={vr6}>
            {`You are giving permission to view all results for the following ${
              employeesToShare.length > 1 ? 'employees' : 'employee'
            }:`}
          </Caption>
          <SharingContainer>
            {employeesToShare.map((employee) => (
              <EmployeeContainer key={employee.email}>
                <EmployeeEmail>{employee.email}</EmployeeEmail>
                {employeesToShare.length > 1 && (
                  <BadgeButton
                    color={BadgeColors.ERROR}
                    onClick={(e) => onRemove(e, employee.id)}
                    size={BadgeSize.Small}
                  >
                    Remove
                  </BadgeButton>
                )}
              </EmployeeContainer>
            ))}
          </SharingContainer>
        </>
      )}
      <ButtonContainer>
        <Button buttonType={ButtonType.Neutral} onClick={hideModal} data-qa-button="cancel-share">
          Cancel
        </Button>
        <Button
          disabled={Boolean(verifiedUserError) || !emailToVerify.length}
          data-qa-button="view-confirm-share"
          onClick={() =>
            verifiedUser
              ? showModal(
                  <ConfirmShareResultsModal
                    accountId={account.id}
                    employeeIdsToShare={employeesToShare.map((emp) => emp.id)}
                    verifiedUser={verifiedUser}
                  />
                )
              : verifyEmail()
          }
        >
          Share
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

const VerifiedUserInformation: React.FC<{verifiedUser: string}> = React.memo(({verifiedUser}) => {
  return (
    <VerifiedEmployee>
      <Username>
        <Icon src={checkCircle} primaryColor={colors.primary600} />
        {verifiedUser}
      </Username>
      <span
        data-for="verified-user-tooltip"
        data-tip={`If this isn't the person you were looking for, double-check that you entered their email correctly.`}
      >
        <Icon src={infoRoundedIcon} />
      </span>
      <ToolTip id="verified-user-tooltip" place={TooltipPosition.Left} />
    </VerifiedEmployee>
  );
});

const SharingContainer = styled.div`
  padding: 1.6rem;
  border: 0.1rem solid ${colors.gray300};
  border-radius: 0.8rem;
  min-height: 4.2rem;
`;

const VerifiedEmployee = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Username = styled.span`
  ${textSmRegular};
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

const EmployeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
`;

const EmployeeEmail = styled(Caption)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.2rem;
  margin: 2.4rem 0;
`;
