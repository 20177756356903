import * as React from 'react';
import styled from '@emotion/styled';
import {vr4, vr12} from '>shared/styles/mixins/verticalRhythm';
import {mR1} from '>shared/styles/mixins/margins';
import {centerItems} from '>shared/styles/mixins/alignment';
import {SpotlightWidget} from '>components/widgets/spotlightWidget/spotlightWidget';
import {
  HomePageItem,
  HomePageItemType,
  HomePageSecondaryItemContent,
  HomePageSpotlightItemContent,
  SpotlightMeasure,
  SpotlightMeasures,
} from '>generated/dvp.types';
import {Heading, Section} from '>shared/components/heading/heading';
import {textXsSemiboldUppercase} from '>shared/components/typography/designSystemTypography';
import {ContentSection, Spotlights, WidgetContainer} from './employeeHome.styles';
import {Icon} from '>shared/components/icon/icon';
import * as colors from 'wnd-themes/lib/colorPalette';
import {
  roleSuccessIcon,
  landscapeIcon,
  starIcon,
  arrowBackIcon,
} from '>shared/components/icon/icons';
import {AnchorId} from './employeeHome';
import {SectionMarkdown, SpotlightFlag} from 'wnd-dvp-reports';
import {Button, ButtonSizes, ButtonType} from '>shared/components/button/button';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import {sharedComponents} from '>lib/markdownSectionComponents';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ArrowIcon = styled(Icon)<{isLastTab: boolean}>`
  width: 1.7rem;
  height: 1.7rem;
  margin-left: 0.8rem;
  transform: rotate(180deg);
  fill: ${(props) => (props.isLastTab ? `${colors.white}` : `${colors.gray500}`)};
`;

const SpotlightIntroParagraph = styled(Paragraph)`
  margin-top: 3.4rem;
  ${vr12}
`;

interface HomePageItemRenderedContent {
  homePageItem: HomePageItem;
  spotlightMeasures: SpotlightMeasures;
  isLastTab: boolean;
  navButtonClick: () => void;
  jobTitle: string;
}

export const HomePageItemRenderedContent: React.FC<HomePageItemRenderedContent> = ({
  homePageItem,
  spotlightMeasures,
  isLastTab,
  navButtonClick,
  jobTitle,
}) => {
  return (
    <>
      <ContentSection>
        {homePageItem.type === HomePageItemType.Spotlight ? (
          <SpotlightHomePageItemRenderedContent
            homePageItem={homePageItem}
            spotlightMeasures={spotlightMeasures}
            jobTitle={jobTitle}
          />
        ) : (
          <SecondaryHomePageItemRenderedContent homePageItem={homePageItem} />
        )}
      </ContentSection>
      <ButtonContainer>
        <Button
          buttonType={isLastTab ? ButtonType.Primary : ButtonType.Neutral}
          data-qa-button="home-page-continue-button"
          size={ButtonSizes.XL}
          onClick={navButtonClick}
        >
          {homePageItem.content.continueButtonText}
          <ArrowIcon src={arrowBackIcon} isLastTab={isLastTab} />
        </Button>
      </ButtonContainer>
    </>
  );
};

interface SpotlightHomePageItemRenderedContent {
  homePageItem: HomePageItem;
  spotlightMeasures: SpotlightMeasures;
  jobTitle: string;
}

export const SpotlightHomePageItemRenderedContent: React.FC<SpotlightHomePageItemRenderedContent> = ({
  homePageItem,
  spotlightMeasures,
  jobTitle,
}) => {
  const content = homePageItem.content as HomePageSpotlightItemContent;
  return (
    <Section>
      <SpotlightIntroParagraph>
        <SectionMarkdown components={sharedComponents}>{content.summary}</SectionMarkdown>
      </SpotlightIntroParagraph>
      <Heading css={[vr4, centerItems, textXsSemiboldUppercase]} id={AnchorId.successAnchorId}>
        <Icon src={roleSuccessIcon} css={mR1} primaryColor={colors.gray500} aria-hidden />
        Critical Attributes for {jobTitle}
      </Heading>
      {/* TODO: WD-1908 - This element (Spotlights and its children) should be replaced by AbstractSpotlightWidget */}
      <Spotlights data-homecontent>
        {spotlightMeasures &&
          spotlightMeasures.critical.map((spotlightMeasure: SpotlightMeasure) => (
            <WidgetContainer key={spotlightMeasure.title}>
              <SpotlightWidget
                score={spotlightMeasure.score}
                constructDomain={spotlightMeasure.constructDomain}
                title={spotlightMeasure.title}
                sectionInfo={spotlightMeasure.spotlightSectionInfo}
                flag={SpotlightFlag.Critical}
              />
            </WidgetContainer>
          ))}
      </Spotlights>
      <Paragraph css={vr12}>
        <SectionMarkdown components={sharedComponents}>
          {content.roleCriticalAttributes}
        </SectionMarkdown>
      </Paragraph>

      <Heading css={[vr4, centerItems, textXsSemiboldUppercase]} id={AnchorId.strengthsAnchorId}>
        <Icon src={starIcon} css={mR1} primaryColor={colors.gray500} aria-hidden />
        Least Effort Attributes
      </Heading>
      <Spotlights data-homecontent>
        {spotlightMeasures &&
          spotlightMeasures.strengths.map((spotlightMeasure: SpotlightMeasure) => (
            <WidgetContainer key={spotlightMeasure.title}>
              <SpotlightWidget
                score={spotlightMeasure.score}
                constructDomain={spotlightMeasure.constructDomain}
                title={spotlightMeasure.title}
                sectionInfo={spotlightMeasure.spotlightSectionInfo}
                flag={SpotlightFlag.Strength}
              />
            </WidgetContainer>
          ))}
      </Spotlights>
      <Paragraph css={vr12}>
        <SectionMarkdown components={sharedComponents}>
          {content.leastEffortAttributes}
        </SectionMarkdown>
      </Paragraph>

      <Heading css={[vr4, centerItems, textXsSemiboldUppercase]} id={AnchorId.focusAnchorId}>
        <Icon src={landscapeIcon} css={mR1} primaryColor={colors.gray500} aria-hidden />
        Most Effort Attributes
      </Heading>
      <Spotlights data-homecontent>
        {spotlightMeasures &&
          spotlightMeasures.opportunities.map((spotlightMeasure: SpotlightMeasure) => (
            <WidgetContainer key={spotlightMeasure.title}>
              <SpotlightWidget
                score={spotlightMeasure.score}
                constructDomain={spotlightMeasure.constructDomain}
                title={spotlightMeasure.title}
                sectionInfo={spotlightMeasure.spotlightSectionInfo}
                flag={SpotlightFlag.FocusOpportunity}
              />
            </WidgetContainer>
          ))}
      </Spotlights>
      <Paragraph css={vr12}>
        <SectionMarkdown components={sharedComponents}>
          {content.mostEffortAttributes}
        </SectionMarkdown>
      </Paragraph>
      {content.closing && (
        <Paragraph css={vr12}>
          <SectionMarkdown components={sharedComponents}>{content.closing}</SectionMarkdown>
        </Paragraph>
      )}
    </Section>
  );
};

interface SecondaryHomePageItemRenderedContent {
  homePageItem: HomePageItem;
}

export const SecondaryHomePageItemRenderedContent: React.FC<SecondaryHomePageItemRenderedContent> = ({
  homePageItem,
}) => {
  const content = homePageItem.content as HomePageSecondaryItemContent;
  return (
    <Paragraph css={[vr12, {marginTop: '3.4rem'}]}>
      <SectionMarkdown components={sharedComponents}>{content.body}</SectionMarkdown>
    </Paragraph>
  );
};
