import {css} from '@emotion/react';
import {respondTo} from '>shared/styles/breakpoints';

export const tableOfContentsSidebar = css`
  ${respondTo.mediumOrHigher} {
    width: 30rem;
    position: fixed;
    max-height: 88%;
  }
`;
