import * as React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {TextArea, TextAreaResizeEnum} from '>shared/components/form/textArea';

export function buildIntroductionFormFields(
  register: UseFormMethods['register']
): FormBuilderFields {
  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'introduction',
      label: 'Introduction',
      component: (
        <TextArea
          placeholder="Introduction"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            required: 'Introduction is required',
            pattern: {
              message: 'Introduction should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
          })}
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'instructions',
      label: 'Instructions',
      component: (
        <TextArea
          placeholder="Instructions"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            required: 'Instructions are required',
            pattern: {
              message: 'Instructions should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
          })}
        />
      ),
    },
  ];
}
