import styled from '@emotion/styled';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import {respondTo} from '>styles/breakpoints';
import {Heading} from '>shared/components/heading/heading';
import {
  textXlBold,
  textXsSemiboldUppercase,
} from '>shared/components/typography/designSystemTypography';
import {vr4, vr8} from '>shared/styles/mixins/verticalRhythm';
import {DrawOrder} from '>styles/mixins/drawOrder';

export const ResultsSpotlights = styled.div`
  margin-top: 2.4rem 0 1.2rem 0;
`;

export const ContentSection = styled(Paragraph)`
  max-width: 64rem;

  ${respondTo.mediumOrHigher} {
    margin-top: 12.8rem;
  }
`;

export const ChapterButtonContainer = styled.div``;

const DEFAULT_SPOTLIGHT_COLUMNS = 5;
export const Spotlights = styled.div<{columns?: number}>`
  display: grid;
  grid-template-columns: repeat(${({columns}) => columns ?? DEFAULT_SPOTLIGHT_COLUMNS}, 1fr);
  grid-template-rows: auto auto;
  // 3 column layouts (and below) have a different design
  gap: ${({columns}) => (columns && columns <= 3 ? '2.4rem' : '0.8rem')};
  padding-bottom: 1.6rem;
`;

export const WidgetContainer = styled.div``;

export const DashboardIndexContainer = styled.aside`
  position: fixed;
  padding: 0.8rem;

  ${respondTo.mediumOrHigher} {
    padding: 2.4rem 1.6rem;
    width: 30rem;
  }
`;

export const EmployeeName = styled(Heading)`
  ${textXlBold};
`;

export const EmployeeJobTitle = styled.p`
  ${textXsSemiboldUppercase};
  ${vr8};
`;

export const TitleContainer = styled.div<{
  width?: number;
  fixedHeader?: boolean;
  impersonatingEmployee?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  max-width: 64rem;

  ${(props) =>
    props.fixedHeader &&
    `
      position: fixed;
      top: ${props.impersonatingEmployee ? '9.0' : '4.8'}rem;
      padding-top: 2.4rem;
      background-color: white;
      z-index: ${DrawOrder.Content};
  `}

  ${(props) => props.width && `width: ${props.width}rem;`}
`;

export const OnboardingHeading = styled.div`
  ${vr4};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FixedHeaderContextTabsContainer = styled.div<{impersonating?: boolean}>`
  .tab-header {
    position: fixed;
    top: 10.4rem;
    max-width: 64rem;
    width: 100%;
    z-index: ${DrawOrder.Base};

    ${(props) => props.impersonating && 'top: 14.6rem'};
  }
`;

export const OptionsSectionContainer = styled.div`
  position: fixed;
  margin-top: 12rem;
`;
