"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.up = exports.space = exports.right = exports.left = exports.home = exports.escapeKey = exports.enter = exports.end = exports.down = void 0;
// KeyboardEvent.code values
const escapeKey = exports.escapeKey = 'Escape';
const end = exports.end = 'End';
const home = exports.home = 'Home';
const left = exports.left = 'ArrowLeft';
const up = exports.up = 'ArrowUp';
const right = exports.right = 'ArrowRight';
const down = exports.down = 'ArrowDown';
const enter = exports.enter = 'Enter';
const space = exports.space = 'Space';