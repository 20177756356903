import React, {InputHTMLAttributes} from 'react';
import * as colors from 'wnd-themes/lib/colorPalette';
import styled from '@emotion/styled';
import {textSmRegular, textLgMedium} from '>shared/components/typography/designSystemTypography';
import {respondTo} from '>shared/styles/breakpoints';

export const AttributeContainer: React.FC = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DateStatusChanger: React.FC = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.6rem;
`;
export const StatusChanger: React.FC = styled.span`
  margin-left: 2.4rem;
  width: 100%;
`;

export const StatusOption: React.FC = styled.div`
  ${textSmRegular};
  width: fit-content;
`;

export const ReaderPageContainer: React.FC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 64rem;
`;

export const PageHeader: React.FC = styled.div`
  display: flex;
  width: 100%;
`;

export const PageContent: React.FC = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 0.8rem;
`;
export const DatePicker: React.FC<InputHTMLAttributes<HTMLInputElement>> = styled.input`
    outline: none;
    height: 100%;
    width: 12rem;
    margin: 0;
    padding: 0.4rem;
    border: 0;
    color: ${colors.gray700};
    font-family: Inter,Arial,Helvetica,sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
  }
`;

export const TabContentContainer: React.FC = styled.div`
  width: 100%;
`;

export const ReaderPageTitle: React.FC = styled.div`
  ${textLgMedium};
  color: ${colors.gray800};
`;

export const AttributeBadge: React.FC = styled.div`
  color: ${colors.gray700};
  background-color: ${colors.gray100};
  line-height: 2.4rem;
  font-size: 1.4rem;
  padding: 0 1rem;
  margin: 0 0.4rem 0 0;
`;

export const StatusChangeOption: React.FC = styled.span`
  ${textSmRegular};
  justifycontent: left;
  color: ${colors.gray700}};
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${respondTo.smallOrLower} {
    gap: 0.4rem;
  }
`;

export const StatusChangeOptionContainer = styled.div`
  display: flex;
  align-items: center;
`;
