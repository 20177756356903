import * as React from 'react';
import {useSelector} from 'react-redux';
import {assertExists} from 'wnd-util/lib/assert';

import _ from 'lodash';

import {store} from '>root/store/main';
import {dvpApi} from '>root/apis';
import {EMPLOYEE_TABLE_PAGE_SIZE} from '>root/configuration';
import {updateEmployeeTableVersionId} from '>root/store/actions/account';
import {EmployeeData} from '>components/employeeTable/employeeRow';
import {Filters} from './filters/manageAppliedFilters';
import {debounce, isEqual} from 'lodash';
import {AssessmentStatus} from '>generated/dvp.types';
import {flattenTags} from '>lib/tags';

enum PageState {
  Pending = 'Pending',
  Complete = 'Complete',
}

interface FilteredEmployeeOptions {
  dvpAccountId: string;
  appliedFilters: Filters;
}

export enum EmployeeTableState {
  InitialFetch = 'Initial Fetch',
  PageLoaded = 'Page Loaded',
  UpdatedRow = 'Updated Row',
}

export interface AvailableTags {
  assessmentStatuses: string[];
  departments: string[];
  locations: string[];
  jobTitles: string[];
}

export interface EmployeeFetcher {
  employeeCount: number;
  fetchEmployeesByPage(pages: number[]): void;
  cancelFetches(): void;
  rows: EmployeeData[];
  setRows: React.Dispatch<React.SetStateAction<EmployeeData[]>>;
  setStateToUpdatedRow(): void;
  availableTags: AvailableTags;
  tableState: EmployeeTableState;
}

class EmployeeFetchContext {
  employeeCount: number = 0;
  lastVisiblePages: number[] = [];
  rows: EmployeeData[] = [];
  availableTags: AvailableTags = {
    locations: [],
    departments: [],
    assessmentStatuses: [],
    jobTitles: [],
  };
  tableState: EmployeeTableState = EmployeeTableState.InitialFetch;
  cancellationSignalled = false;

  pageStates: Array<PageState | undefined> = [PageState.Pending];
  pageFetchQueue: number[] = [];
  private fetchesInProgress = 0;
  employeeTableVersionId?: string;
  private filterVersion = 0;
  private requestWipe = true;

  constructor(
    private dvpAccountId: string,
    private signalUpdate: () => void,
    private appliedFilters: Filters
  ) {
    this.signalUpdate();
  }

  updateAppliedFilters(filters: Filters) {
    this.performAppliedFilterUpdate(filters);

    this.cancelInFlightRequests();
  }

  performAppliedFilterUpdate = debounce((filters: Filters) => {
    if (this.cancellationSignalled || isEqual(this.appliedFilters, filters)) {
      return;
    }

    // We should reset several things here
    this.appliedFilters = filters;
    this.pageStates = this.pageStates.map((_) => undefined);
    this.rows = [];

    this.pageFetchQueue = [0];
    this.filterVersion += 1;
    this.fetchesInProgress = 0;
    this.tableState = EmployeeTableState.InitialFetch;
    this.signalUpdate();

    this.fetchNextPage();
  }, 250);

  async fetchPage(pageNumber: number, includeTags: boolean): Promise<boolean> {
    this.fetchesInProgress += 1;
    this.pageStates[pageNumber] = PageState.Pending;
    const versionStart = this.filterVersion;

    const inProgressStatuses = [
      AssessmentStatus.Invited,
      AssessmentStatus.NotStarted,
      AssessmentStatus.InProgress,
      AssessmentStatus.Expiring,
    ];

    const appliedFiltersForApi = _.cloneDeep(this.appliedFilters);
    if (appliedFiltersForApi.assessmentStatuses?.includes(AssessmentStatus.InProgress)) {
      for (const inProgressStatus of inProgressStatuses) {
        if (!appliedFiltersForApi.assessmentStatuses.includes(inProgressStatus)) {
          appliedFiltersForApi.assessmentStatuses.push(inProgressStatus);
        }
      }
    }

    const {data} = await dvpApi.getEmployeesByAccountId({
      accountId: this.dvpAccountId,
      pageSize: EMPLOYEE_TABLE_PAGE_SIZE,
      offset: pageNumber * EMPLOYEE_TABLE_PAGE_SIZE,
      includeTags,
      ...appliedFiltersForApi,
    });

    if (versionStart !== this.filterVersion) {
      return false;
    }

    this.fetchesInProgress -= 1;
    this.tableState = EmployeeTableState.PageLoaded;
    this.employeeTableVersionId = data.employeeTableVersionId;

    if (includeTags) {
      const {tags} = data;
      assertExists(tags, 'Should be included when includeTags is true');

      this.availableTags = {
        assessmentStatuses: flattenTags(tags.assessmentStatuses),
        departments: flattenTags(tags.departments),
        jobTitles: flattenTags(tags.jobTitles),
        locations: flattenTags(tags.locations),
      };
    }

    this.pageStates[pageNumber] = PageState.Complete;

    if (data.employeeCount) {
      this.employeeCount = data.employeeCount;
    }

    if (this.requestWipe) {
      this.requestWipe = false;
      this.rows = [];
    }

    if (data.employees) {
      const newRows = this.rows.concat();

      for (let i = 0; i < (data.employees.length ?? 0); i += 1) {
        const employee = data.employees[i];
        newRows[i + EMPLOYEE_TABLE_PAGE_SIZE * pageNumber] = {
          employee,
        };
      }

      this.rows = newRows;
    }

    this.fetchNextPage();

    return true;
  }

  versionUpdated() {
    if (this.cancellationSignalled) {
      return;
    }

    this.pageStates = this.pageStates.map((_) => undefined);
    this.requestWipe = true;

    this.pageFetchQueue = this.lastVisiblePages.concat();
    this.fetchesInProgress = 0;

    this.fetchNextPage();
  }

  async initFetch() {
    await this.fetchPage(0, true);

    this.signalUpdate();
  }

  async fetchNextPage(): Promise<void> {
    let pageToFetch: number | undefined;

    while (this.pageFetchQueue.length) {
      pageToFetch = this.pageFetchQueue.shift() as number;

      if (this.pageStates[pageToFetch] === undefined) {
        break;
      }

      pageToFetch = undefined;
    }

    if (pageToFetch === undefined) {
      return;
    }

    await this.fetchPage(pageToFetch, true);

    if (this.cancellationSignalled) {
      return;
    }

    this.signalUpdate();

    if (!this.fetchesInProgress && this.pageFetchQueue.length) {
      return this.fetchNextPage();
    }
  }

  fetchEmployeesByPage(pages: number[]) {
    this.lastVisiblePages = pages;
    this.pageFetchQueue = pages.concat();

    if (this.fetchesInProgress === 0 && !this.cancellationSignalled) {
      return this.fetchNextPage();
    }
  }

  updateRows(rows: EmployeeData[]) {
    this.rows = rows;

    this.signalUpdate();
  }

  cancelInFlightRequests() {
    this.pageFetchQueue = [];
  }

  cancel() {
    this.cancelInFlightRequests();
    this.cancellationSignalled = true;
  }
}

export function useEmployeeFetch({
  dvpAccountId,
  appliedFilters,
}: FilteredEmployeeOptions): EmployeeFetcher {
  const fetcher = React.useRef<EmployeeFetchContext>();
  const [_, setUpdateVersion] = React.useState(0);

  const employeeTableVersionId = useSelector((state) => state.account.employeeTableVersionId);
  const metrics = useSelector((state) => state.account.employeeMetrics);
  const initialEmployeeAdd = fetcher.current?.employeeCount === 0 && metrics.employeeCount > 0;

  const signalUpdate = React.useCallback(() => {
    setUpdateVersion((updateVersion) => updateVersion + 1);
  }, []);

  React.useEffect(() => {
    fetcher.current?.cancel();
    fetcher.current = new EmployeeFetchContext(dvpAccountId, signalUpdate, appliedFilters);

    fetcher.current.initFetch();
  }, [dvpAccountId, signalUpdate, appliedFilters]);

  React.useEffect(() => {
    if (
      !fetcher.current?.employeeTableVersionId ||
      fetcher.current?.employeeTableVersionId === employeeTableVersionId
    ) {
      return;
    }

    fetcher.current.versionUpdated();
  }, [employeeTableVersionId]);

  React.useEffect(() => {
    const version = fetcher.current?.employeeTableVersionId;
    if (version) {
      store.dispatch(updateEmployeeTableVersionId({employeeTableVersionId: version}));
    }
  }, [fetcher.current?.employeeTableVersionId]);

  React.useEffect(() => {
    assertExists(fetcher.current, 'fetcher.current must exist by this point');

    fetcher.current.updateAppliedFilters(appliedFilters);
  }, [appliedFilters]);

  React.useEffect(() => {
    if (initialEmployeeAdd) {
      fetcher.current?.initFetch();
    }
  }, [metrics.employeeCount]);

  const fetchEmployeesByPage = React.useCallback((pages: number[]) => {
    assertExists(fetcher.current, 'fetcher.current must exist by this point');
    fetcher.current.fetchEmployeesByPage(pages);
  }, []);

  const cancelFetches = React.useCallback(() => {
    fetcher.current?.cancelInFlightRequests();
  }, []);

  const setRows: React.Dispatch<React.SetStateAction<EmployeeData[]>> = React.useCallback(
    (rows) => {
      assertExists(fetcher.current, 'Fetcher must have been instantiated by this point');

      if (rows instanceof Array) {
        fetcher.current.updateRows(rows);
      } else {
        const newRows = rows(fetcher.current.rows);

        fetcher.current.updateRows(newRows);
      }
    },
    []
  );

  const setStateToUpdatedRow = React.useCallback(() => {
    // Not sure if this is needed any more
    assertExists(fetcher.current, 'Fetcher must have been instantiated by this point');
    fetcher.current.tableState = EmployeeTableState.UpdatedRow;
  }, []);

  React.useDebugValue(fetcher.current?.employeeCount ?? 0);

  return (
    (fetcher.current && {
      employeeCount: fetcher.current.employeeCount,
      fetchEmployeesByPage,
      cancelFetches,
      rows: fetcher.current.rows,
      availableTags: fetcher.current.availableTags,
      setRows,
      setStateToUpdatedRow,
      tableState: fetcher.current.tableState,
    }) ?? {
      employeeCount: 0,
      fetchEmployeesByPage,
      cancelFetches,
      rows: [],
      availableTags: {
        assessmentStatuses: [],
        departments: [],
        jobTitles: [],
        locations: [],
      },
      setRows,
      setStateToUpdatedRow,
      tableState: EmployeeTableState.PageLoaded,
    }
  );
}
