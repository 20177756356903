import * as React from 'react';
import {useForm, useFormContext} from 'react-hook-form';
import {FormBuilder, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {Input} from '>shared/components/form/input';
import {rollbarLogger} from '>lib/logger';
import {dvpApi} from '>root/apis';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {ControlledAsyncSelect} from '>shared/components/form/asyncSelect';
import {DrawerForm, Stage, StageFormValues} from '>shared/components/drawer/drawerForm';
import {FieldValues} from 'react-hook-form/dist/types/form';
import {useSelector} from 'react-redux';
import {assertAccountExists} from '>lib/assert';
import {Team, TeamRoles} from '>generated/dvp.types';
import {MIN_NUM_NON_MANAGERS} from '>lib/team';
import {isEqual} from 'lodash';
import {assertExists} from 'wnd-util/lib/assert';

const MAX_TEAM_NAME_LENGTH = 100;

interface GeneralStageFields extends FieldValues {
  teamName: string;
  manager: Option;
}

interface MembersStageFields extends FieldValues {
  employees: Option[];
}

const MIN_TYPE_AHEAD = 2;

function buildTeamMemberOption(
  id: string,
  firstName: string,
  lastName: string,
  preferredName?: string
): Option {
  return {
    label: `${preferredName ? preferredName : firstName} ${lastName}`,
    value: id,
  };
}

async function loadOptions(
  accountId: string,
  searchQuery: string,
  excludedEmployeeIds: string[] = []
): Promise<Option[]> {
  if (searchQuery.length < MIN_TYPE_AHEAD) {
    return [];
  }

  const {data: employees} = await dvpApi.searchEmployeesByName({accountId, searchQuery});

  return employees
    .filter((e) => !excludedEmployeeIds.includes(e.id))
    .map((e) => buildTeamMemberOption(e.id, e.firstName, e.lastName, e.preferredName));
}

function getNoResultsMessage(input: string): string {
  let message = '';

  if (input.length === 0) {
    message = 'Enter an employee name to show results';
  } else if (input.length < MIN_TYPE_AHEAD) {
    message = 'Please type more characters';
  } else if (input.length >= MIN_TYPE_AHEAD) {
    message = `No results found for "${input}"`;
  }

  return message;
}

interface GeneralFormProps {
  accountId: string;
  mode: AddEditTeamMode;
  initialTeamName?: string; // For edit mode only
}

interface MembersFormProps {
  accountId: string;
  managerId?: string;
}

const GeneralForm: React.FC<GeneralFormProps> = ({accountId, mode, initialTeamName}) => {
  const MANAGER_FIELD_NAME = 'manager';
  const MANAGER_LABEL = 'Team Manager';

  const {control, register} = useFormContext<GeneralStageFields>();

  return (
    <FormBuilder
      showErrorSummary={false}
      fields={[
        {
          type: FormBuilderFieldType.Input,
          label: 'Team Name',
          name: 'teamName',
          component: (
            <Input
              ref={register({
                required: 'Team Name is required',
                maxLength: {
                  value: MAX_TEAM_NAME_LENGTH,
                  message: `Team Name must be ${MAX_TEAM_NAME_LENGTH} characters or fewer`,
                },
                validate: async (value) => {
                  if (
                    mode === AddEditTeamMode.Add ||
                    (mode === AddEditTeamMode.Edit &&
                      initialTeamName?.toLocaleLowerCase() !== value.toLocaleLowerCase())
                  ) {
                    const {data: doesExist} = await dvpApi.doesTeamExist({
                      accountId,
                      teamName: value,
                    });
                    if (doesExist) {
                      return 'Team name already in use.';
                    }
                  }
                },
              })}
              placeholder="Team Name"
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          label: MANAGER_LABEL,
          name: MANAGER_FIELD_NAME,
          component: (
            <ControlledAsyncSelect
              name={MANAGER_FIELD_NAME}
              label={MANAGER_LABEL}
              control={control}
              placeholder="Start typing manager name or email..."
              loadOptions={(text) => loadOptions(accountId, text)}
              getNoResultsMessage={getNoResultsMessage}
              rules={{required: `${MANAGER_LABEL} is required`}}
            />
          ),
        },
      ]}
    />
  );
};

const MembersForm: React.FC<MembersFormProps> = ({accountId, managerId}) => {
  const EMPLOYEES_FIELD_NAME = 'employees';
  const EMPLOYEES_LABEL = 'Employees';

  const {control} = useFormContext<MembersStageFields>();

  return (
    <FormBuilder
      showErrorSummary={false}
      fields={[
        {
          type: FormBuilderFieldType.Input,
          name: EMPLOYEES_FIELD_NAME,
          label: EMPLOYEES_LABEL,
          component: (
            <ControlledAsyncSelect
              name={EMPLOYEES_FIELD_NAME}
              label={EMPLOYEES_LABEL}
              control={control}
              placeholder="Enter employee name"
              isMulti
              loadOptions={(text) =>
                loadOptions(accountId, text, managerId ? [managerId] : undefined)
              }
              getNoResultsMessage={getNoResultsMessage}
              rules={{
                required: `${EMPLOYEES_LABEL} are required`,
              }}
            />
          ),
        },
      ]}
    />
  );
};

export enum AddEditTeamMode {
  Add,
  Edit,
}

interface AddTeamDrawerFormProps {
  mode: AddEditTeamMode;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (newTeam: Team) => void;
  teamInEdit?: Team;
}

function getDefaultTeamName(mode: AddEditTeamMode, team?: Team): string | undefined {
  if (mode === AddEditTeamMode.Add) {
    return undefined;
  }

  if (!team) {
    throw new Error('Team is required for edit mode');
  }

  return team.name;
}

function getDefaultManagerOption(mode: AddEditTeamMode, team?: Team): Option | undefined {
  if (mode === AddEditTeamMode.Add) {
    return undefined;
  }

  if (!team) {
    throw new Error('Team is required for edit mode');
  }

  const manager = team.members.find((m) => m.role === TeamRoles.Manager);

  if (!manager) {
    return undefined; // User will be required to enter something before saving
  }

  const {employee} = manager;

  return buildTeamMemberOption(
    employee.id,
    employee.firstName,
    employee.lastName,
    employee.preferredName
  );
}

function getDefaultEmployeesOption(mode: AddEditTeamMode, team?: Team): Option[] | undefined {
  if (mode === AddEditTeamMode.Add) {
    return undefined;
  }

  if (!team) {
    throw new Error('Team is required for edit mode');
  }

  const nonManagers = team.members.filter((m) => !m.role);

  if (nonManagers.length === 0) {
    throw new Error(`Team ${team.id} is being edited without existing employees`);
  }

  return nonManagers.map((nonManager) => {
    const {employee} = nonManager;
    return buildTeamMemberOption(
      employee.id,
      employee.firstName,
      employee.lastName,
      employee.preferredName
    );
  });
}

export const AddEditTeamDrawerForm: React.FC<AddTeamDrawerFormProps> = ({
  mode,
  isOpen,
  onClose,
  onSuccess,
  teamInEdit,
}) => {
  const {showSuccessAlert} = useSnackbar();
  const {account} = useSelector((state) => state.account);
  assertAccountExists(account);

  const generalFormMethods = useForm<GeneralStageFields>({
    mode: 'onBlur',
    defaultValues: {
      teamName: getDefaultTeamName(mode, teamInEdit),
      manager: getDefaultManagerOption(mode, teamInEdit),
    },
  });

  const membersFormMethods = useForm<MembersStageFields>({
    mode: 'all',
    defaultValues: {employees: getDefaultEmployeesOption(mode, teamInEdit)},
  });

  const [managerId, setManagerId] = React.useState<string | undefined>();

  const submitTeam = React.useCallback(
    async (stagesFieldValues: StageFormValues<GeneralStageFields | MembersStageFields>[]) => {
      const generalFields = stagesFieldValues[0] as GeneralStageFields;
      const membersFields = stagesFieldValues[1] as MembersStageFields;

      const newMembers = [
        {employeeId: generalFields.manager.value, role: TeamRoles.Manager},
        ...membersFields.employees.map((option) => ({employeeId: option.value})),
      ];

      if (mode === AddEditTeamMode.Add) {
        const {data: newTeam} = await dvpApi.createTeam({
          accountId: account.id,
          name: generalFields.teamName,
          membersAdded: newMembers,
        });

        onSuccess(newTeam);
      } else {
        assertExists(teamInEdit, 'teamInEdit must exist in edit mode');

        const didNameChange = teamInEdit.name !== generalFields.teamName;
        const didMembersChange = !isEqual(teamInEdit.members, newMembers);
        const {data: updatedTeam} = await dvpApi.updateTeam(
          {
            name: didNameChange ? generalFields.teamName : undefined,
            members: didMembersChange ? newMembers : undefined,
          },
          {teamId: teamInEdit.id}
        );

        onSuccess(updatedTeam);
      }

      showSuccessAlert(
        `Success! The ${generalFields.teamName} team was ${
          mode === AddEditTeamMode.Add ? 'added' : 'updated'
        }`
      );

      onClose();
    },
    [onClose]
  );

  const onSubmitError = React.useCallback(
    (error, setFormError) => {
      if (error.status === 400 && error.payload?.message.includes('contain at least')) {
        setFormError(`Must add at least ${MIN_NUM_NON_MANAGERS} employees.`);
      } else if (
        error.status === 400 &&
        error.payload?.message.includes('manager as an employee')
      ) {
        setFormError('The employees list cannot include the manager');
      } else if (error.status === 409) {
        setFormError(`This team name is already in use.`);
      } else {
        rollbarLogger.error(error);
      }
    },
    [rollbarLogger]
  );

  const generalStage: Stage<GeneralStageFields> = {
    methods: generalFormMethods,
    form: <GeneralForm accountId={account.id} mode={mode} initialTeamName={teamInEdit?.name} />,
    actions: {
      next: {
        onClick: (
          stagesFieldValues: StageFormValues<GeneralStageFields | MembersStageFields>[]
        ) => {
          // This is used to access the manager in another stage but before the submit.
          // If this is a common pattern, we may be able to handle this
          // in a more generic way using render functions for stage.form.
          setManagerId(stagesFieldValues[0].manager.value);
          return Promise.resolve();
        },
      },
      cancel: {
        onClick: onClose,
      },
    },
  };

  const memberStage: Stage<MembersStageFields> = {
    methods: membersFormMethods,
    form: <MembersForm accountId={account.id} managerId={managerId} />,
    actions: {
      next: {
        onClick: submitTeam,
      },
      back: {},
    },
  };

  return (
    <DrawerForm
      isOpen={isOpen}
      onDismiss={onClose}
      formTitle={mode === AddEditTeamMode.Add ? 'Add Team' : 'Edit Team'}
      // Even if all stages satisfy the constraints of FieldValues
      // TS seems to be angry about any subsequent stage types
      stages={[generalStage, memberStage as any]}
      onSubmitError={onSubmitError}
    />
  );
};
