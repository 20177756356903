import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { personalDriversTraitColors } from '../styles/traitColors';
const offset = 187;
const duration = 1.4;
function generateColorKeyFrames(colors) {
    const totalColors = colors.length;
    return colors
        .map((color, index) => {
        const percent = index / (totalColors - 1);
        return `${Math.ceil(percent * 100)}% {stroke: ${color.primaryColor};}`;
    })
        .join(' ');
}
const colors = keyframes `
	${generateColorKeyFrames(personalDriversTraitColors)}
`;
const dash = keyframes `
 0% { stroke-dashoffset: ${offset}; }
 50% {
   stroke-dashoffset: ${offset / 4};
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: ${offset};
   transform:rotate(450deg);
 }
`;
const rotator = keyframes `
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
`;
const SpinnerSVG = styled.svg `
  margin: auto;
  animation: ${rotator} ${duration}s linear infinite;
`;
const SpinnerCircle = styled.circle `
  stroke-width: 0.5rem;
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} ${duration}s ease-in-out infinite,
    ${colors} ${duration * 4}s ease-in-out infinite;
`;
export const Spinner = ({ size = 65 }) => {
    return (_jsx(SpinnerSVG, { "data-testid": "loading-indicator", width: `${size}px`, height: `${size}px`, viewBox: "0 0 66 66", xmlns: "http://www.w3.org/2000/svg", "aria-label": "Loading", "aria-busy": "true", children: _jsx(SpinnerCircle, { fill: "none", "stroke-linecap": "round", cx: "33", cy: "33", r: "30" }) }));
};