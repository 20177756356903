import React from 'react';

import {
  ActionItem,
  ConstructDomain,
  EmployeeRenderedMeasure,
  EmployeeRenderedMeasures,
} from '>generated/dvp.types';
import {useHistory} from 'react-router-dom';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import styled from '@emotion/styled';

import {MeasureCard} from '>components/measures/measureCard/measureCard';
import {chapterDropdownOptions} from '>components/contentManagement/navigation/navigationIndex';
import {SectionMarkdown, SpotlightFlag, makeSpinalCase} from 'wnd-dvp-reports';
import {assertExists} from 'wnd-util/lib/assert';

import {useSelector} from 'react-redux';

import {sharedComponents} from '>lib/markdownSectionComponents';
import {Button, ButtonType} from '>shared/components/button/button';
import {Heading} from '>shared/components/heading/heading';
import {textMdMedium} from '>shared/components/typography/designSystemTypography';
import {IndexMeasure} from '>generated/dvp.types';

import {Spinner} from '>shared/components/spinner';
import {useAsyncDispatch} from '>root/store/main';
import {getIndexMeasures} from '>root/store/actions/employee';
import {rollbarLogger} from '>lib/logger';
import {redirectToErrorPage} from '>lib/redirect';
import {MissingAttributeError} from '>root/errors';
import {dvpApi} from '>root/apis';
import {vr6} from '>shared/styles/mixins/verticalRhythm';
import {ContextTabs, TabDetails, TabType} from '>shared/components/containers/contextTabs';
import {mediaQueries} from '>shared/styles/breakpoints';
import ScrollMagic from 'scrollmagic';

const TAB_HEADER_OFFSET = 68;

type RenderedContent = Record<string, TabDetails>;

interface DescriptionProps {
  actionItem: ActionItem;
}

interface AttributeProps {
  actionItem: ActionItem;
}

const DescriptionSection = styled.div`
  padding: 1.6rem 0 1.6rem 0;
  width: 100%;
`;
const DescriptionHeading = styled(Heading)`
  ${textMdMedium}
`;

const DescriptionParagraph = styled(Paragraph)`
  padding-top: 1.2rem;
  width: 100%;
`;

const AttributeContainer = styled.div`
  margin: 1.6rem 0 1.6rem 0;
`;

const ButtonLinksContainer = styled.div`
  display: flex;
  ${vr6}
`;

export const FixedHeaderContextTabsContainer = styled.div<{
  isOffScreen?: boolean;
  impersonating?: boolean;
}>`
  .tab-header {
    ${(props) =>
      props.isOffScreen &&
      `
      top: ${props.impersonating ? '8' : '4.5'}rem;
      position: fixed;
      max-width: 65rem;
      width: 100%;
      padding: 2.4rem 0;
      margin-top: 0;
      background-color: white;
    `};
  }
`;

interface ButtonLink {
  chapter: string;
  anchorId: string;
  text?: string;
}

function getChapterDropdownOption(chapterNumber: string) {
  return chapterDropdownOptions.find((option) => chapterNumber === option.value);
}

function getButtonLinks(measure: IndexMeasure): ButtonLink[] {
  const links: ButtonLink[] = [];

  for (let i = 0; i < measure.chapters.length; i++) {
    const chapterNumber = measure.chapters[i];

    const option = getChapterDropdownOption(chapterNumber);
    assertExists(option, `chapter number ${chapterNumber} not found`);

    links.push({
      text: getTabTileFromChapter(chapterNumber),
      chapter: option.value,
      anchorId: measure.anchorIds[i],
    });
  }

  return links;
}

function getTabTileFromChapter(chapterNumber: string): string {
  const option = getChapterDropdownOption(chapterNumber);
  assertExists(option, `chapter number ${chapterNumber} not found`);
  const split = option.label.toString().split(':');
  const buttonText = split[split.length - 1];
  return buttonText;
}

export const ActionDescription: React.FC<DescriptionProps> = ({actionItem}) => {
  return (
    <>
      <DescriptionSection>
        <DescriptionHeading>Description</DescriptionHeading>
        <DescriptionParagraph>{actionItem.description || 'N/A'}</DescriptionParagraph>
      </DescriptionSection>
      <DescriptionSection>
        <DescriptionHeading>
          How will this action help you improve in {actionItem.attribute}?{' '}
        </DescriptionHeading>
        <DescriptionParagraph>{actionItem.improve || 'N/A'}</DescriptionParagraph>
      </DescriptionSection>
      <DescriptionSection>
        <DescriptionHeading>What is the first step you can take?</DescriptionHeading>
        <DescriptionParagraph>{actionItem.firstStep || 'N/A'}</DescriptionParagraph>
      </DescriptionSection>
      <DescriptionSection>
        <DescriptionHeading>How will you know when you're done?</DescriptionHeading>
        <DescriptionParagraph>{actionItem.howToMeasure || 'N/A'}</DescriptionParagraph>
      </DescriptionSection>
    </>
  );
};

function handleError(err: Error): void {
  rollbarLogger.error(err);
  redirectToErrorPage(err);
}

function renderTabContent(employeeSectionMeasures: EmployeeRenderedMeasures) {
  const tabContent: RenderedContent = {};
  employeeSectionMeasures.sort((a, b) => parseInt(a.chapterNumber) - parseInt(b.chapterNumber));

  employeeSectionMeasures.forEach((measure: EmployeeRenderedMeasure, index: number) => {
    const chapterTitle = getTabTileFromChapter(measure.chapterNumber);
    tabContent[index.toString()] = {
      title: chapterTitle,
      hashLink: makeSpinalCase(chapterTitle),
      page: () => {
        return (
          <div css={{marginTop: '2.4rem'}}>
            <SectionMarkdown components={sharedComponents}>{measure.content}</SectionMarkdown>
          </div>
        );
      },
    };
  });

  return tabContent;
}
export const AttributeDetails: React.FC<AttributeProps> = ({actionItem}) => {
  const employee = useSelector((state) => state.employee.employee);

  const [measure, setMeasure] = React.useState<IndexMeasure | undefined>();
  const [buttonLinks, setButtonLinks] = React.useState<ButtonLink[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [tabContent, setTabContent] = React.useState<RenderedContent>();
  const [isOffScreen, setIsOffScreen] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const history = useHistory();
  assertExists(employee, 'Employee should exist when rendering the Action Item form fields');
  const account = useSelector((state) => state.account.account);
  assertExists(account, 'Account should exist when rendering the Action Item form fields');
  const bucketMapping = useSelector((state) => state.interpretationBuckets.bucketMapping);

  const asyncDispatch = useAsyncDispatch();
  const indexMeasures = useSelector((state) => state.employee.indexMeasures);

  const isAdminImpersonatingEmployee = useSelector(
    (state) => state.employee.employee?.isImpersonated
  );

  const isMobile = window.matchMedia(mediaQueries.smallOrLower.media).matches;

  const getMeasureForAllSections = React.useCallback(async () => {
    setIsLoading(true);
    const {data} = await dvpApi.getEmployeeMeasureForAllSections({
      accountId: account.id,
      employeeId: employee.id,
      measureName: actionItem.attribute,
    });

    setTabContent(renderTabContent(data));
    setIsLoading(false);
  }, [actionItem.id, employee.id, indexMeasures]);

  const goToSection = (chapterNumber: string, anchorId: string) => {
    history.push(`/section${chapterNumber}#section${chapterNumber}-${anchorId}`);
  };

  // Lazy-load index measures
  React.useEffect(() => {
    if (indexMeasures) {
      const indexMeasure = indexMeasures.find((m) => m.title === actionItem.attribute);
      if (indexMeasure) {
        if (isMobile) {
          setButtonLinks(getButtonLinks(indexMeasure));
        } else {
          getMeasureForAllSections();
        }
        setMeasure(indexMeasure);
      } else {
        handleError(
          new MissingAttributeError(
            `Action item ${actionItem.id} is using an attribute called ${actionItem.attribute}, but there's no Measure with that display title.`
          )
        );
        return;
      }
    } else {
      asyncDispatch(getIndexMeasures(employee.id)).catch(handleError);
    }
  }, [actionItem.id, employee.id, indexMeasures, isMobile]);

  React.useEffect(() => {
    if (!containerRef.current || isMobile) {
      return;
    }

    //Using the scrollmagic library to track the entering/exit of the
    //tab header in order to make it sticky
    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: containerRef.current, // The container to watch
      triggerHook: 0, //Trigger when the container goes past top of viewport
      offset: -`${TAB_HEADER_OFFSET}`, // The offset so the it triggers at start of element leaving vs end
    })
      .on('enter', () => {
        setIsOffScreen(true);
      })
      .on('leave', () => {
        setIsOffScreen(false);
      })
      .addTo(controller);

    return () => {
      scene.destroy();
      controller.destroy(true);
    };
  });

  return (
    <AttributeContainer>
      {measure ? (
        <>
          <div css={vr6}>
            <MeasureCard
              title={measure.title}
              score={measure.score}
              synonyms={measure.synonyms}
              definition={measure.definition}
              constructDomain={measure.constructDomain as ConstructDomain}
              bucketMapping={bucketMapping}
              sharedComponents={sharedComponents}
              spotlightFlags={measure.flags as SpotlightFlag[]}
            />
          </div>
          {isMobile ? (
            <ButtonLinksContainer>
              {buttonLinks &&
                buttonLinks.map((buttonLink) => (
                  <Button
                    key={buttonLink.text}
                    css={{marginLeft: '0.4rem'}}
                    buttonType={ButtonType.Ghost}
                    data-qa-button="action-item-view-results"
                    onClick={() =>
                      buttonLink.chapter && goToSection(buttonLink.chapter, buttonLink.anchorId)
                    }
                  >
                    {buttonLink.text}
                  </Button>
                ))}
            </ButtonLinksContainer>
          ) : tabContent && !isLoading ? (
            <FixedHeaderContextTabsContainer
              isOffScreen={isOffScreen}
              impersonating={isAdminImpersonatingEmployee}
              ref={containerRef}
            >
              <ContextTabs tabs={tabContent} tabType={TabType.Button} />
            </FixedHeaderContextTabsContainer>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </AttributeContainer>
  );
};
export const Reflections: React.FC<DescriptionProps> = ({actionItem}) => {
  return (
    <div>
      Reflections{actionItem.title} {actionItem.description}
    </div>
  );
};
