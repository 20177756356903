import * as React from 'react';
import {useFormContext} from 'react-hook-form';
import {
  buildSecondaryHomePageItemFormFields,
  buildSpotlightHomePageItemFormFields,
} from '>components/contentManagement/form/homePage/formBuilder';
import {FormBuilder} from '>shared/components/form/formBuilder';
import {HomePageItemType} from '>generated/dvp.types';

export interface SpotlightHomePageItemFormFields {
  title: string;
  description: string;
  summary: string;
  roleCriticalAttributes: string;
  leastEffortAttributes: string;
  mostEffortAttributes: string;
  closing?: string;
  continueButtonText: string;
}

export interface SecondaryHomePageItemFormFields {
  title: string;
  description: string;
  body: string;
  continueButtonText: string;
}

interface Props {
  homePageItemType: HomePageItemType;
}

export const HomePageItemFormFields: React.FC<Props> = ({homePageItemType}) => {
  const {register} = useFormContext<
    SpotlightHomePageItemFormFields | SecondaryHomePageItemFormFields
  >();

  const homePageItemFormFields =
    homePageItemType === HomePageItemType.Spotlight
      ? buildSpotlightHomePageItemFormFields(register)
      : buildSecondaryHomePageItemFormFields(register);

  return <FormBuilder fields={homePageItemFormFields} />;
};
