import * as React from 'react';
import styled from '@emotion/styled';
import {RouteChildrenProps} from 'react-router-dom';
import {css} from '@emotion/react';
import {orderBy, startCase} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useUIDSeed} from 'react-uid';

import * as icons from '>shared/components/icon/icons';
import {ActionsCell, EmailCell, NameCell, TextCell} from '>components/dataTable/cellStyles';
import {ActionsContainer, DataTableRowContainer} from '>components/dataTable/dataTableRow';
import {
  Container,
  EmployeesTableContainer,
  stickyContent,
} from '../adminEmployees/adminEmployeesPage.styles';
import {
  EmployeeFilterLabel,
  filterContainer,
  stickyTableHeader,
} from '../adminEmployees/filters/employeesFilter.styles';
import {Button, ButtonType} from '>shared/components/button/button';
import {EmployeeActionIcon} from '>components/employeeTable/employeeRow';
import {HeadingActions} from '>components/headingActions';
import {Icon} from '>shared/components/icon/icon';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {PageHeadingWithActions, InfiniteScrollPageContents} from '../page.styles';
import {RemoveUserModal} from '>components/modal/removeUser';
import {TextContent} from '>components/dataTable/textContent';
import {ToolTip} from '>shared/components/tooltip';
import {UserRole} from '>generated/dvp.types';
import {assertAccountExists, assertAccountUserExists, assertValidAuthState} from '>lib/assert';
import {canManageUsers, isOwner, useAccountUser} from '>lib/user';
import {dvpApi} from '>root/apis';
import {getMyDvpUser} from '>root/store/actions/user';
import {history} from '>root/history';
import {nextSortDirection, SortButtonDirection} from '>shared/components/data/sortButton';
import {pageChangeEffect} from '>lib/sideEffects';
import {resendUserInvitation} from '>root/store/actions/account';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {useAppDispatch} from '>root/store/main';
import {useModal} from '>shared/components/modal/useModal';
import {vr1md} from '>shared/styles/mixins/verticalRhythm';
import {assertExists} from 'wnd-util/lib/assert';
import {LoadingScreen} from '>shared/components/loadingScreen';
import {AddUserDrawerModal} from '>components/modal/addUserDrawer';
import {EditUserDrawerModal} from '>components/modal/editUserDrawer';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';

const ACTION_TOOLTIP = 'tooltip-action-info';

const PAGE_TITLE = 'User Management';

interface AllowedActions {
  canEdit?: boolean;
  canDelete?: boolean;
}

export const RoleCell = styled(TextCell)`
  min-width: 16rem;
  max-width: 16rem;
`;

enum UserSortField {
  LastName,
  FirstName,
  Email,
  Role,
}

export interface TableUser {
  accountId: string;
  authUserId: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  allowedActions: AllowedActions;
  isPending: boolean;
  isVerified: boolean;
}

export const UserManagementPage: React.FC<RouteChildrenProps> = React.memo(() => {
  React.useEffect(pageChangeEffect(PAGE_TITLE), []);
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  assertValidAuthState(auth);
  const loggedInUser = auth.token.user;
  const accountState = useSelector((state) => state.account);
  const account = accountState.account;
  assertAccountExists(account);
  const currentUser = useAccountUser();
  assertAccountUserExists(currentUser);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = React.useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = React.useState(false);
  const [userToEdit, setUserToEdit] = React.useState<TableUser | undefined>();

  React.useEffect(() => {
    dispatch(getMyDvpUser(account.id));
  }, []);

  const addUserRoleOptions: Option[] = React.useMemo(() => {
    const selectableRoles = Object.keys(account.userRolePermissions).filter(
      (key) => key === UserRole.AccountAdmin || key === UserRole.Coach
    );
    const RoleDisplayLabel: Record<string, string> = {
      accountAdmin: 'Account Admin',
      coach: 'Coach',
    };

    return selectableRoles.map((role) => {
      return {
        label: RoleDisplayLabel[role] || role,
        value: role,
      };
    });
  }, [account.userRolePermissions]);

  if (!canManageUsers(currentUser)) {
    history.replace('/');
    return null;
  }

  const [tableUsers, setTableUsers] = React.useState<TableUser[]>([]);

  React.useEffect(() => {
    dvpApi.getUsers({accountId: account.id}).then((getDVPUsersResponse) => {
      const dvpUsersWithPII = getDVPUsersResponse.data;

      const sortedDvpUsersWithPII = orderBy(dvpUsersWithPII, (user) => [
        user.lastName ? user.lastName.toLocaleLowerCase() : '',
        user.firstName ? user.firstName.toLocaleLowerCase() : '',
      ]);

      const users = sortedDvpUsersWithPII.map((dvpUser) => {
        const isCurrentUser = dvpUser.authUserId === loggedInUser.id;
        return {
          accountId: account.id,
          authUserId: dvpUser.authUserId,
          firstName: dvpUser.firstName ?? '',
          lastName: dvpUser.lastName ?? '',
          name: dvpUser.firstName ? `${dvpUser.firstName} ${dvpUser.lastName}` : 'Pending',
          isVerified: dvpUser.isVerified,
          isPending: dvpUser.isPending,
          email: dvpUser.email,
          role: dvpUser.role,
          allowedActions: {
            canEdit: !isOwner(dvpUser) && !isCurrentUser,
            canDelete: !isOwner(dvpUser) && !isCurrentUser,
          },
        };
      });

      setTableUsers(users);
    });
  }, [accountState.userManagementTableVersion]);

  const [currentSortField, setCurrentSortField] = React.useState(UserSortField.LastName);
  const [currentSortDirection, setCurrentSortDirection] = React.useState(
    SortButtonDirection.Ascending
  );

  const getSortDirection = React.useCallback(
    (fieldToSort: UserSortField) => {
      if (fieldToSort === currentSortField) {
        return currentSortDirection;
      }

      return SortButtonDirection.None;
    },
    [currentSortField, currentSortDirection]
  );

  const updateSortedField = React.useCallback(
    (fieldToSort: UserSortField): React.MouseEventHandler => {
      return () => {
        let sortDirection: SortButtonDirection;
        if (fieldToSort === currentSortField) {
          sortDirection = nextSortDirection(currentSortDirection);
        } else {
          sortDirection = SortButtonDirection.Ascending;
          setCurrentSortField(fieldToSort);
        }

        setCurrentSortDirection(sortDirection);

        const order = sortDirection === SortButtonDirection.Ascending ? 'asc' : ('desc' as const);

        setTableUsers((users) => {
          if (fieldToSort === UserSortField.LastName) {
            return orderBy(
              users,
              (user) => [
                user.lastName ? user.lastName.toLocaleLowerCase() : '',
                user.firstName ? user.firstName.toLocaleLowerCase() : '',
                user.email,
              ],
              [order, order, order]
            );
          } else if (fieldToSort === UserSortField.FirstName) {
            return orderBy(
              users,
              (user) => [
                user.firstName ? user.firstName.toLocaleLowerCase() : '',
                user.lastName ? user.lastName.toLocaleLowerCase() : '',
                user.email,
              ],
              [order, order, order]
            );
          } else if (fieldToSort === UserSortField.Email) {
            return orderBy(users, (user) => [user.email], [order]);
          } else if (fieldToSort === UserSortField.Role) {
            return orderBy(users, (user) => [user.role], [order]);
          } else {
            throw new Error('Unable to reach');
          }
        });
      };
    },
    [currentSortField, currentSortDirection]
  );

  const getSelectableOptions = React.useCallback(() => {
    const roles = Object.keys(account.userRolePermissions);

    switch (userToEdit?.role) {
      case UserRole.Owner:
        return roles.filter((r) => r === UserRole.Owner);
      case UserRole.AccountAdmin:
        return roles.filter((r) => r === UserRole.AccountAdmin || r === UserRole.Owner);
      case UserRole.Coach:
        return roles.filter((r) => r === UserRole.Coach);
      default:
        return [];
    }
  }, [userToEdit]);

  const editUserRoleOptions: Option[] = getSelectableOptions().map((role) => {
    return {
      label: RoleDisplayLabel[role],
      value: role,
    };
  });

  const seed = useUIDSeed();

  if (tableUsers.length === 0) {
    return <LoadingScreen />;
  }

  const tableLoggedInUser = tableUsers.find((tableUser) => tableUser.email === loggedInUser.email);
  assertExists(tableLoggedInUser, 'Logged in user not found in the users table');

  return (
    <InfiniteScrollPageContents>
      <PageHeadingWithActions
        css={[
          stickyContent(),
          css`
            padding: 2.4rem 1.6rem 2.4rem 3.2rem;
          `,
        ]}
      >
        <Heading css={[displayXsMedium, vr1md]}>User Management</Heading>
        <HeadingActions>
          <Button
            buttonType={ButtonType.Neutral}
            data-qa-button="addUser"
            onClick={() => setIsAddUserModalOpen(true)}
          >
            <Icon src={icons.addIcon} small />
            Add User
          </Button>
        </HeadingActions>
      </PageHeadingWithActions>
      <EmployeesTableContainer>
        <Container as="thead" css={stickyTableHeader}>
          <DataTableRowContainer css={filterContainer}>
            <NameCell as="th">
              <EmployeeFilterLabel
                label="Last Name"
                labelFor={seed('lastName')}
                sortable
                sortDirection={getSortDirection(UserSortField.LastName)}
                onClick={updateSortedField(UserSortField.LastName)}
              />
            </NameCell>
            <NameCell as="th">
              <EmployeeFilterLabel
                label="First Name"
                labelFor={seed('firstName')}
                sortable
                sortDirection={getSortDirection(UserSortField.FirstName)}
                onClick={updateSortedField(UserSortField.FirstName)}
              />
            </NameCell>
            <EmailCell as="th">
              <EmployeeFilterLabel
                label="Email"
                labelFor={seed('email')}
                sortable
                sortDirection={getSortDirection(UserSortField.Email)}
                onClick={updateSortedField(UserSortField.Email)}
              />
            </EmailCell>
            <RoleCell as="th">
              <EmployeeFilterLabel
                label="Role"
                labelFor={seed('role')}
                sortable
                sortDirection={getSortDirection(UserSortField.Role)}
                onClick={updateSortedField(UserSortField.Role)}
              />
            </RoleCell>
            <ActionsCell as="th">
              <EmployeeFilterLabel label="Actions" labelFor="" />
            </ActionsCell>
          </DataTableRowContainer>
        </Container>
        <tbody>
          {tableUsers.map((user) => (
            <UserRow
              key={user.email}
              user={user}
              setIsEditUserModalOpen={setIsEditUserModalOpen}
              setUserToEdit={setUserToEdit}
              isEditUserModalOpen={isEditUserModalOpen}
            />
          ))}
        </tbody>
      </EmployeesTableContainer>
      <ToolTip id={ACTION_TOOLTIP} />
      <AddUserDrawerModal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        accountId={account.id}
        roleOptions={addUserRoleOptions}
      />
      <EditUserDrawerModal
        isOpen={isEditUserModalOpen}
        onClose={() => {
          setIsEditUserModalOpen(false);
          setUserToEdit(undefined);
        }}
        initialRoleEditedUser={userToEdit?.role}
        roleLoggedInUser={tableLoggedInUser.role}
        roleOptions={editUserRoleOptions}
        user={userToEdit ?? undefined}
      />
    </InfiniteScrollPageContents>
  );
});

const RoleDisplayLabel: Record<string, string> = {
  coach: 'Coach',
  accountAdmin: 'Account Admin',
  owner: 'Owner',
};

interface UserRowProps {
  user: TableUser;
  isEditUserModalOpen: boolean;
  setIsEditUserModalOpen: (isOpen: boolean) => void;
  setUserToEdit: React.Dispatch<React.SetStateAction<TableUser | undefined>>;
}

const UserRow: React.FC<UserRowProps> = ({user, setIsEditUserModalOpen, setUserToEdit}) => {
  const dispatch = useAppDispatch();
  const {showModal} = useModal();
  const {showSuccessAlert, showErrorAlert} = useSnackbar();

  const state = useSelector((state) => state);
  const accountState = state.account;
  const account = accountState.account;
  assertAccountExists(account);

  const authState = state.auth;
  assertValidAuthState(authState);

  const sendUserInvitation = React.useCallback(async () => {
    const response = await dispatch(
      resendUserInvitation({
        userId: user.authUserId,
      })
    );

    if (resendUserInvitation.rejected.match(response)) {
      showErrorAlert('Unable to resend user invitation!');
    } else {
      showSuccessAlert(`Successfully resent user invitation!`);
    }
  }, [user.authUserId]);

  const editUser = React.useCallback(() => {
    setIsEditUserModalOpen(true);
    setUserToEdit(user);
  }, [user]);

  const deleteUser = React.useCallback(() => {
    showModal(
      <RemoveUserModal
        accountId={user.accountId}
        authUserId={user.authUserId}
        userName={user.name}
        email={user.email}
        isPending={user.isPending}
      />
    );
  }, []);

  const actions = React.useMemo(() => {
    const actions = [];

    if (!user.isVerified || user.isPending) {
      actions.push({
        id: `resendInviteAction-${user.authUserId}`,
        tooltipText: 'Resend Invite',
        icon: icons.inviteIcon,
        onClick: sendUserInvitation,
      });
    } else if (user.allowedActions.canEdit) {
      actions.push({
        id: `editUserAction-${user.authUserId}`,
        tooltipText: 'Edit User',
        icon: icons.editIcon,
        onClick: editUser,
      });
    }

    if (user.allowedActions.canDelete) {
      actions.push({
        id: `deleteUserAction-${user.authUserId}`,
        tooltipText: 'Delete User',
        icon: icons.deleteIcon,
        onClick: deleteUser,
      });
    }

    return actions;
  }, [user, sendUserInvitation, editUser, deleteUser]);

  return (
    <DataTableRowContainer key={user.email}>
      <NameCell>
        <TextContent>{user.lastName}</TextContent>
      </NameCell>
      <NameCell>
        <TextContent>{user.firstName}</TextContent>
      </NameCell>
      <EmailCell>
        <TextContent
          hasWarning={user.isPending || !user.isVerified}
          warningText={user.isVerified ? 'Pending Acceptance' : 'Pending Email Verification'}
        >
          {user.email}
        </TextContent>
      </EmailCell>
      <RoleCell>
        <TextContent>{startCase(user.role)}</TextContent>
      </RoleCell>
      <ActionsCell>
        <ActionsContainer>
          {actions.map((action) => (
            <EmployeeActionIcon
              key={action.id}
              id={action.id}
              tooltipText={action.tooltipText}
              icon={action.icon}
              onClick={action.onClick}
            />
          ))}
        </ActionsContainer>
      </ActionsCell>
    </DataTableRowContainer>
  );
};
