import * as React from 'react';
import {Redirect, RouteChildrenProps} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RoutePath} from '>root/routes/routes';
import {pageChangeEffect} from '>lib/sideEffects';
import {UserRole} from '>generated/dvp.types';
import {PageContents, PageHeadingWithActions} from './page.styles';
import {vr1md} from '>shared/styles/mixins/verticalRhythm';
import {Paragraph} from '>shared/components/typography/paragraph';
import styled from '@emotion/styled';
import {respondTo} from '>shared/styles/breakpoints';

const PAGE_TITLE = 'Coaching';

const CoachingPageHeading = styled(PageHeadingWithActions)`
  padding-left: 0px;
`;

const CoachParagraph = styled(Paragraph)`
  ${respondTo.largeOrHigher} {
    max-width: 55%;
    text-align: left;
  }
`;

export const Coaching: React.FC<RouteChildrenProps> = ({history}) => {
  React.useEffect(pageChangeEffect(PAGE_TITLE), []);
  const accountUser = useSelector((state) => state.user.accountUser);

  if (accountUser?.role !== UserRole.Coach) {
    return <Redirect to={RoutePath.AdminEmployees} />;
  }

  return (
    <PageContents>
      <CoachingPageHeading>
        <h2 css={vr1md}>Welcome to {process.env.DEVELOPMENT_PRODUCT_NAME}</h2>
      </CoachingPageHeading>
      <CoachParagraph>
        You’re now logged into {process.env.DEVELOPMENT_PRODUCT_NAME}. As employee results are
        shared with you, you will receive links to log in and help them along their development
        journey.
      </CoachParagraph>
    </PageContents>
  );
};
