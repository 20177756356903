import * as React from 'react';
import {Action, ActionType, Modal} from '>shared/components/modal/modal';
import {useModal} from '>shared/components/modal/useModal';
import {ModalDisplayType} from '>shared/components/modal/displayType';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import {dvpApi} from '>root/apis';
import {rollbarLogger} from '>lib/logger';
import {Team} from '>generated/dvp.types';
import {warningIcon} from '>shared/components/icon/icons';
import {error500} from 'wnd-themes/lib/colorPalette';

interface Props {
  teamId: string;
  teamName: string;
  employeeId: string;
  employeeFullName: string;
  onRemove: (updatedTeam: Team) => void;
  willHaveLessThanMinMembers?: boolean;
}

export const RemoveTeamMemberModal: React.FC<Props> = ({
  teamId,
  teamName,
  employeeId,
  employeeFullName,
  onRemove,
  willHaveLessThanMinMembers,
}) => {
  const [isBusy, setIsBusy] = React.useState(false);
  const {hideModal} = useModal();
  const {showSuccessAlert, showErrorAlert} = useSnackbar();

  const handleSubmit = React.useCallback(async () => {
    setIsBusy(true);

    try {
      const {data: updatedTeam} = await dvpApi.removeTeamMembers([employeeId], {teamId});
      const snackbarMessage = `Success! ${employeeFullName} was removed from ${teamName}.`;
      showSuccessAlert(snackbarMessage);
      onRemove(updatedTeam);
    } catch (err) {
      showErrorAlert('Unable to remove employee. Please try again.');
      rollbarLogger.error(err as Error);
    }

    setIsBusy(false);
    hideModal();
  }, [isBusy, onRemove]);

  const modalActions: Action[] = [
    {
      type: ActionType.Primary,
      label: 'Remove',
      inputType: 'button',
      disabled: isBusy,
      onClick: handleSubmit,
      qaDataAttribute: 'remove-button',
    },
    {
      type: ActionType.Secondary,
      label: 'Cancel',
      inputType: 'button',
      disabled: isBusy,
      onClick: hideModal,
      qaDataAttribute: 'cancel-release-results',
    },
  ];

  return (
    <Modal
      headerOptions={{
        title: 'Remove Employee',
        icon: willHaveLessThanMinMembers ? warningIcon : undefined,
        iconColor: willHaveLessThanMinMembers ? error500 : undefined,
      }}
      actions={modalActions}
      displayType={willHaveLessThanMinMembers ? ModalDisplayType.Small : ModalDisplayType.Popup}
      isLocked={isBusy}
    >
      {willHaveLessThanMinMembers && (
        <>
          <Paragraph>
            {`Removing ${employeeFullName} will cause this team to have less than the minimum number of members to view the report.`}
          </Paragraph>
          <Paragraph>
            The report will need to be re-released once enough members are added.
          </Paragraph>
        </>
      )}

      {!willHaveLessThanMinMembers && (
        <Paragraph>{`${employeeFullName} will be removed from ${teamName}.`}</Paragraph>
      )}
    </Modal>
  );
};
