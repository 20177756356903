import * as React from 'react';
import {CellProps, CellValue, Column} from 'react-table';

import styled from '@emotion/styled';
import * as icons from '>shared/components/icon/icons';
import {Heading} from '>shared/components/heading/heading';
import {textXlBold} from '>shared/components/typography/designSystemTypography';
import {vr1, vr8} from '>shared/styles/mixins/verticalRhythm';
import {Paragraph} from '>shared/components/typography/baseTypography2';
import {addIcon} from '>shared/components/icon/icons';
import {Button} from '>shared/components/button/button';
import {ButtonType} from '>shared/components/button/button';
import {ModalProvider} from '>shared/components/modal/useModal';
import {OpenModalAction} from '>shared/components/modal/modalActions';
import {ActionItemsImportModal} from '../../../components/modal/actionItemsImport';
import {Table, TableColumnDataType} from '>shared/components/tables/table';
import {
  TableActionButtonsContainer,
  SearchAndBulkSelectContainer,
  TableActionsContainer,
} from '>root/pages/adminEmployees/adminEmployeesPage.styles';
import {RawInput} from '>shared/components/form/rawControls/rawInput';
import {Icons} from '>shared/styles/icon-types';
import {Option, RawSelect} from '>shared/components/form/rawControls/rawSelect';
import {dvpApi} from '>root/apis';

import {ActionItemTemplate} from '>root/generated/dvp.types';
import {IconButton, IconButtonType} from '>shared/components/iconButton/iconButton';
import {Dropdown, DropdownPosition} from '>shared/components/menu/dropdown';
import {OverflowMenu} from '>shared/components/menu/overflowMenu';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {ActionItemTemplateTableButtonGroup} from './tableButtonGroup';
import {TableSelectionCount} from '>shared/components/tables/table.styles';
import {
  ActionItemDestructiveModal,
  ActionItemModalType,
} from '>components/actionPlanner/actionItemModals';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';

const ACTION_PAGE_TITLE = 'Action item database';

const PageContainer = styled.div`
  width: 100%;
`;

const ActionItemsSectionContainer = styled.div`
  margin: 2.4rem 1.6rem 3.2rem 3.2rem;
`;

export const CellContent = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const OverflowCellContent = styled.div`
  overflow: visible;
`;

interface SearchableActionItemTemplate extends ActionItemTemplate {
  searchString: string;
}

export const ContentManagerForActionItems: React.FC = () => {
  const [selectedAttribute, setSelectedAttribute] = React.useState<string>();
  const [availableAttributes, setAvailableAttributes] = React.useState<Array<Option>>([]);
  const [searchString, setSearchString] = React.useState('');
  const [templatesByAttribute, setTemplatesByAttribute] = React.useState<
    Array<SearchableActionItemTemplate>
  >([]);
  const [filteredTemplates, setFilteredTemplates] = React.useState<
    Array<SearchableActionItemTemplate>
  >([]);
  const {showSuccessAlert} = useSnackbar();
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = React.useState(false);
  const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<string[]>([]);

  const handleTableSelectionChange = React.useCallback((selectedIds: string[]) => {
    setSelectedTemplateIds(selectedIds);
  }, []);

  const buildMenuCell = React.useCallback(
    (cellProps: CellProps<CellValue>) => {
      const actionItemTemplateId = cellProps.row.original.id;

      const menuItems: MenuLinkItem[] = [
        {
          id: 'delete-action-item',
          icon: icons.trashIcon,
          text: 'Delete',
          hoverStyle: true,
          modal: (
            <ActionItemDestructiveModal
              type={ActionItemModalType.DeleteSingleActionItemTemplate}
              onYes={async () => {
                await dvpApi.bulkDeleteActionItemTemplates({templateIds: [actionItemTemplateId]});
                showSuccessAlert(`Success! Action Item Template deleted.`);
                getActionItemTemplatesByAttribute(selectedAttribute);
              }}
            />
          ),
        },
      ];

      return (
        <ModalProvider>
          <Dropdown
            position={DropdownPosition.AlignBottomRight}
            renderButton={(onClick) => (
              <IconButton
                icon={icons.kebabMenu}
                buttonLabel="View available actions for this action item"
                data-qa-button="action-item-actions-button"
                onClick={onClick}
                variant={IconButtonType.NAV}
              />
            )}
          >
            <OverflowMenu hideOnMobile={false} menuItems={menuItems} />
          </Dropdown>
        </ModalProvider>
      );
    },
    [selectedAttribute]
  );

  const columns: Array<Column<any>> = React.useMemo(
    () => [
      {
        id: 'attribute',
        Header: 'Attribute',
        accessor: 'attribute',
        width: 2,
        dataType: TableColumnDataType.Textual,
      },
      {
        id: 'effort',
        Header: 'Effort',
        accessor: 'effort',
        width: 1,
        dataType: TableColumnDataType.Textual,
      },
      {
        id: 'title',
        Header: 'Title',
        accessor: 'title',
        width: 2,
        dataType: TableColumnDataType.Textual,
        Cell: ({value}: CellProps<CellValue>) => <CellContent>{value}</CellContent>,
      },
      {
        id: 'description',
        Header: 'Description',
        accessor: 'description',
        width: 5,
        dataType: TableColumnDataType.Textual,
        Cell: ({value}: CellProps<CellValue>) => <CellContent>{value}</CellContent>,
      },
      {
        id: 'overflowMenu',
        sortable: false,
        disableSortBy: true,
        width: 1,
        dataType: TableColumnDataType.OverflowMenu,
        Cell: (cellProps: CellProps<CellValue>) => (
          <OverflowCellContent>{buildMenuCell(cellProps)}</OverflowCellContent>
        ),
      },
    ],
    [buildMenuCell]
  );

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((evt) => {
    const {value} = evt.target;
    setSearchString(value);
  }, []);

  const updateActionItemAttributesList = React.useCallback(async () => {
    const {data: attributes} = await dvpApi.getActionItemAttributes();

    setAvailableAttributes(
      attributes.map((attribute) => {
        return {
          label: attribute,
          value: attribute,
        };
      })
    );
  }, []);

  React.useEffect(() => {
    updateActionItemAttributesList();
  }, []);

  const handleNewImport = React.useCallback(() => {
    updateActionItemAttributesList();
    getActionItemTemplatesByAttribute(selectedAttribute);
  }, [selectedAttribute]);

  const onAttributeSelect = React.useCallback((value: Option) => {
    setSelectedAttribute(value.value);
  }, []);

  const getActionItemTemplatesByAttribute = React.useCallback(async (attribute?: string) => {
    setTemplatesByAttribute([]);

    if (!attribute) {
      return;
    }

    const {data: actionItems} = await dvpApi.listActionItemTemplates({attributes: [attribute]});

    setTemplatesByAttribute(
      actionItems.map((actionItem) => {
        return {
          ...actionItem,
          searchString: `${actionItem.attribute} ${actionItem.title} ${actionItem.description}`,
        };
      })
    );
  }, []);

  React.useEffect(() => {
    if (selectedAttribute) {
      getActionItemTemplatesByAttribute(selectedAttribute);
    }
  }, [selectedAttribute]);

  const debounceTimer = React.useRef<number>();

  React.useEffect(() => {
    if (debounceTimer.current !== undefined) {
      window.clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = window.setTimeout(() => {
      const searchRegex = new RegExp(`.*${searchString}.*`, 'ig');

      setFilteredTemplates(
        templatesByAttribute.filter((actionItem) => actionItem.searchString.match(searchRegex))
      );
    }, 300);
  }, [searchString, templatesByAttribute]);

  return (
    <PageContainer>
      <ActionItemsSectionContainer>
        <Heading css={[vr1, textXlBold]}>{ACTION_PAGE_TITLE}</Heading>
        <Paragraph css={vr8}>
          Develop’s curated list of recommended action items. Avoid duplicates and incomplete
          records.
        </Paragraph>
        <TableActionsContainer>
          <SearchAndBulkSelectContainer>
            <TableSelectionCount>Selected ({selectedTemplateIds.length})</TableSelectionCount>
            <RawSelect
              value={
                selectedAttribute ? {label: selectedAttribute, value: selectedAttribute} : undefined
              }
              name="attribute"
              placeholder="Attribute name"
              options={availableAttributes}
              onSelectChange={onAttributeSelect}
              isSearchable
            />
            <RawInput
              name="Search Employees"
              placeholder="Search"
              insetIcon={Icons.Search}
              value={searchString}
              onChange={onSearch}
            />
          </SearchAndBulkSelectContainer>
          <TableActionButtonsContainer>
            <ActionItemTemplateTableButtonGroup
              selectedTemplateIds={selectedTemplateIds}
              onDelete={() => {
                getActionItemTemplatesByAttribute(selectedAttribute);
              }}
            />{' '}
            <Button
              data-qa-button="add-action-items"
              icon={addIcon}
              buttonType={ButtonType.Tertiary}
              onClick={() => setIsBulkImportModalOpen(true)}
            >
              Upload action item
            </Button>
            <ActionItemsImportModal
              isOpen={isBulkImportModalOpen}
              onClose={() => setIsBulkImportModalOpen(false)}
              onImport={handleNewImport}
            />
          </TableActionButtonsContainer>
        </TableActionsContainer>
        <Table
          columns={columns}
          data={filteredTemplates}
          makeRowsSelectable
          onSelectionChange={handleTableSelectionChange}
          useSimpleMobileStyle={false}
        />
      </ActionItemsSectionContainer>
    </PageContainer>
  );
};
