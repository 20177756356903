"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmbeddedVideoSource = void 0;
var EmbeddedVideoSource;
(function (EmbeddedVideoSource) {
  EmbeddedVideoSource["Youtube"] = "youtube";
  EmbeddedVideoSource["Vimeo"] = "vimeo";
})(EmbeddedVideoSource || (exports.EmbeddedVideoSource = EmbeddedVideoSource = {}));