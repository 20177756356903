import * as React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/react';

import * as colors from 'wnd-themes/lib/colorPalette';
import {MetricsWidget} from '>shared/components/widgets/metricsWidget/metricsWidget';
import {AssessmentStatus} from '>generated/dvp.types';

export enum EmployeeMetricCount {
  TOTAL = 'Total',
}

export type EmployeeMetricVariant = EmployeeMetricCount | AssessmentStatus;

export interface EmployeeMetric {
  count: number;
  isApplied?: boolean;
  label: string;
  variant: EmployeeMetricVariant;
  shaded?: boolean;
}

function getBorderColor(activeVariant: EmployeeMetricVariant): string {
  switch (activeVariant) {
    case AssessmentStatus.InProgress:
      return colors.orange200;

    case AssessmentStatus.AwaitingResults:
      return colors.violet200;

    case AssessmentStatus.Expired:
      return colors.error200;

    case AssessmentStatus.New:
      return colors.success200;

    case AssessmentStatus.ResultsReleased:
      return colors.blue200;

    case EmployeeMetricCount.TOTAL:
      return colors.gray200;

    default:
      return colors.gray200;
  }
}

function getBoxShadow(activeVariant: EmployeeMetricVariant): string {
  switch (activeVariant) {
    case AssessmentStatus.InProgress:
      return colors.orange100;

    case AssessmentStatus.AwaitingResults:
      return colors.violet100;

    case AssessmentStatus.Expired:
      return colors.error100;

    case AssessmentStatus.New:
      return colors.success100;

    case AssessmentStatus.ResultsReleased:
      return colors.primary100;

    default:
      return 'none';
  }
}

interface StyledWidgetProps {
  variant: EmployeeMetricVariant;
  isApplied?: boolean;
}

const StyledMetric = styled(MetricsWidget)<StyledWidgetProps>`
  ${(props) =>
    props.isApplied &&
    css`
      border: 0.1rem solid ${getBorderColor(props.variant)};
      box-shadow: 0rem 0rem 0rem 0.2rem ${getBoxShadow(props.variant)};
    `}
`;

export const EmployeeMetricsWidget: React.FC<EmployeeMetric> = ({
  count,
  isApplied,
  label,
  variant,
  shaded = false,
}) => {
  return (
    <StyledMetric
      isApplied={isApplied}
      variant={variant}
      shaded={shaded}
      count={count}
      label={label}
    />
  );
};
