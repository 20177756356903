import * as React from 'react';
import {UseFormMethods} from 'react-hook-form';
import {FormBuilderFields, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {TextArea, TextAreaResizeEnum} from '>shared/components/form/textArea';
import {Input} from '>shared/components/form/input';
import {validateTemplateSyntax} from '>root/lib/validation';

export function buildOverviewTeamReportItemFormFields(
  register: UseFormMethods['register']
): FormBuilderFields {
  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'cardTitle',
      label: 'Card Title',
      component: (
        <Input
          placeholder=""
          ref={register({
            required: 'Card title is required',
            pattern: {
              message: 'Card title should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          maxLength={100}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'introDynamicInterpretation',
      label: 'Overview Interpretation',
      component: (
        <TextArea
          placeholder="Overview Interpetation"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            required: 'Overview interpretation is required',
            pattern: {
              message: 'Overview interpretation should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'cardParagraph',
      label: 'Type Summary',
      component: (
        <TextArea
          placeholder="Type summary"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            required: 'Type summary is required',
            pattern: {
              message: 'Type summary contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'strengths',
      label: 'Overview Type Strengths',
      component: (
        <TextArea
          placeholder="Overview type strngths"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            required: 'Overview type strengths is required',
            pattern: {
              message: 'Overview type strengths contain should at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'risks',
      label: 'Overview Type Risks',
      component: (
        <TextArea
          placeholder="Overview type risks"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            required: 'Overview type risks is required',
            pattern: {
              message: 'Overview type risks should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
  ];
}

export function buildApproachTeamReportItemFormFields(
  register: UseFormMethods['register']
): FormBuilderFields {
  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'introductionText',
      label: 'Approach Introduction',
      component: (
        <Input
          placeholder=""
          ref={register({
            required: 'Approach introduction is required',
            pattern: {
              message: 'Approach introduction should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Group,
      name: 'typology1',
      label: 'Typology 1 (top right)',
      fields: [
        {
          type: FormBuilderFieldType.Input,
          name: 'displayName',
          label: 'Display Name',
          component: (
            <Input
              placeholder=""
              ref={register({
                required: 'Display name is required',
                pattern: {
                  message: 'Display name should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              maxLength={100}
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'typeInterpretation',
          label: 'Approach Interpretation',
          component: (
            <TextArea
              placeholder=""
              ref={register({
                required: 'Approach interpretation is required',
                pattern: {
                  message: 'Approach nterpretation should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'balancerParagraph',
          label: 'Approach Balancers',
          component: (
            <TextArea
              placeholder=""
              ref={register({
                required: 'Approach balancers are required',
                pattern: {
                  message: 'Approach balancers should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              required
            />
          ),
        },
      ],
    },
    {
      type: FormBuilderFieldType.Group,
      name: 'typology2',
      label: 'Typology 2 (top left)',
      fields: [
        {
          type: FormBuilderFieldType.Input,
          name: 'displayName',
          label: 'Display Name',
          component: (
            <Input
              placeholder=""
              ref={register({
                required: 'Display name is required',
                pattern: {
                  message: 'Display name should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              maxLength={100}
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'typeInterpretation',
          label: 'Approach Interpretation',
          component: (
            <TextArea
              placeholder=""
              ref={register({
                required: 'Approach interpretation is required',
                pattern: {
                  message: 'Approach nterpretation should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'balancerParagraph',
          label: 'Approach Balancers',
          component: (
            <TextArea
              placeholder=""
              ref={register({
                required: 'Approach balancers are required',
                pattern: {
                  message: 'Approach balancers should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              required
            />
          ),
        },
      ],
    },
    {
      type: FormBuilderFieldType.Group,
      name: 'typology3',
      label: 'Typology 3 (bottom left)',
      fields: [
        {
          type: FormBuilderFieldType.Input,
          name: 'displayName',
          label: 'Display Name',
          component: (
            <Input
              placeholder=""
              ref={register({
                required: 'Display name is required',
                pattern: {
                  message: 'Display name should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              maxLength={100}
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'typeInterpretation',
          label: 'Approach Interpretation',
          component: (
            <TextArea
              placeholder=""
              ref={register({
                required: 'Approach interpretation is required',
                pattern: {
                  message: 'Approach interpretation should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'balancerParagraph',
          label: 'Approach Balancers',
          component: (
            <TextArea
              placeholder=""
              ref={register({
                required: 'Approach balancers are required',
                pattern: {
                  message: 'Approach balancers should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              required
            />
          ),
        },
      ],
    },
    {
      type: FormBuilderFieldType.Group,
      name: 'typology4',
      label: 'Typology 4 (bottom right)',
      fields: [
        {
          type: FormBuilderFieldType.Input,
          name: 'displayName',
          label: 'Display Name',
          component: (
            <Input
              placeholder=""
              ref={register({
                required: 'Display name is required',
                pattern: {
                  message: 'Display name should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              maxLength={100}
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'typeInterpretation',
          label: 'Approach Interpretation',
          component: (
            <TextArea
              placeholder=""
              ref={register({
                required: 'Approach interpretation is required',
                pattern: {
                  message: 'Approach nterpretation should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              required
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'balancerParagraph',
          label: 'Approach Balancers',
          component: (
            <TextArea
              placeholder=""
              ref={register({
                required: 'Approach balancers are required',
                pattern: {
                  message: 'Approach balancers should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
                validate: validateTemplateSyntax,
              })}
              required
            />
          ),
        },
      ],
    },
    /// Approach intro text
    {
      type: FormBuilderFieldType.Input,
      name: 'attributeSectionIntroText',
      label: 'Attribute Introduction',
      component: (
        <TextArea
          placeholder="Attribute introduction"
          ref={register({
            required: 'Attribute introduction is required',
            pattern: {
              message: 'Attribute introduction should contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
  ];
}

export function buildAttributeTeamReportItemFormFields(
  register: UseFormMethods['register']
): FormBuilderFields {
  return [
    {
      type: FormBuilderFieldType.Input,
      name: 'description',
      label: 'Attribute Definition',
      component: (
        <TextArea
          placeholder="Attribute Definition"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            required: 'Attribute Definition is required',
            pattern: {
              message: 'Attribute Definition must contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
    {
      type: FormBuilderFieldType.Input,
      name: 'content',
      label: 'Attribute Interpretation',
      component: (
        <TextArea
          placeholder="Attribute interpretation"
          skipScroll={true}
          resize={TextAreaResizeEnum.Vertical}
          ref={register({
            required: 'Attribute interpretation is required',
            pattern: {
              message: 'Attribute interpretation must contain at least one character',
              value: new RegExp(/[^\s]+/),
            },
            validate: validateTemplateSyntax,
          })}
          required
        />
      ),
    },
  ];
}
