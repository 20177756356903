import * as React from 'react';
import styled from '@emotion/styled';

import * as colors from 'wnd-themes/lib/colorPalette';
import {textMdRegular, textXlMedium} from '>shared/components/typography/designSystemTypography';
import {vr2} from '>shared/styles/mixins/verticalRhythm';
import {IconHalo} from '>shared/components/icon/iconHalo';
import {Icon} from '>shared/components/icon/icon';
import {EmptyStateContainer} from '>root/pages/page.styles';

const LightText = styled.p`
  ${textMdRegular};
  color: ${colors.gray500};
`;

const HaloContainer = styled.div`
  display: flex;
  justify-content: center;
  ${vr2};
`;

export interface NoItemsViewProps {
  icon: string;
}

export const NoItemsView: React.FC<NoItemsViewProps> = ({icon, children}) => {
  return (
    <EmptyStateContainer>
      <HaloContainer>
        <IconHalo borderColor={colors.primary50} backgroundColor={colors.primary100}>
          <Icon src={icon} primaryColor={colors.primary600} hoverColor={colors.primary600} />
        </IconHalo>
      </HaloContainer>
      <h2 css={[textXlMedium, vr2]}>No records found</h2>
      <LightText>{children}</LightText>
    </EmptyStateContainer>
  );
};
