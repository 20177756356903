import {createReducer} from '@reduxjs/toolkit';
import {
  Account,
  BulkImportCommitResult,
  Employee,
  EmployeesMetrics,
} from '../../generated/dvp.types';
import {
  addUser,
  deleteUser,
  getAccountById,
  getEmployeeMetrics,
  updateEmployeeTableVersionId,
  updateUser,
} from '../actions/account';
import {initializeAuthToken} from '../actions/auth';
import {
  archiveEmployee,
  bulkArchiveEmployees,
  bulkUnArchiveEmployees,
  commitBulkEmployeeImport,
  quickAddEmployee,
  releaseResults,
  sendAssessmentInvitations,
  unArchiveEmployee,
  updateEmployee,
} from '../actions/employee';

export interface AccountState {
  employeeMetrics: EmployeesMetrics;
  account?: Account;
  employeeTableVersionId?: string;
  userManagementTableVersion: number;
}

const DEFAULT_STATE: Readonly<AccountState> = {
  employeeMetrics: {
    employeeCount: 0,
    assessmentMetrics: {
      needsInviteCount: 0,
      activeCount: 0,
      expiredCount: 0,
      resultsReleasedCount: 0,
      awaitingResultsCount: 0,
    },
  },
  userManagementTableVersion: 1,
};

function emptyAccountState(): AccountState {
  return DEFAULT_STATE;
}

function updateEmployeeTableVersion(
  state: AccountState,
  {payload}: {payload: (BulkImportCommitResult | Employee) & {employeeTableVersionId?: string}}
): AccountState {
  if (
    payload.employeeTableVersionId &&
    payload.employeeTableVersionId !== state.employeeTableVersionId
  ) {
    return {
      ...state,
      employeeTableVersionId: payload.employeeTableVersionId,
    };
  } else {
    return state;
  }
}

function updateUserTableVersion(state: AccountState): AccountState {
  return {
    ...state,
    userManagementTableVersion: state.userManagementTableVersion + 1,
  };
}

function updateEmployeeMetrics(
  state: AccountState,
  {payload}: {payload: EmployeesMetrics}
): AccountState {
  return {
    ...state,
    employeeMetrics: payload,
  };
}

function updateAccount(state: AccountState, {payload}: {payload: Account}): AccountState {
  return {
    ...state,
    account: payload,
    employeeTableVersionId: undefined,
  };
}

export const accountReducer = createReducer<AccountState>(DEFAULT_STATE, (builder) => {
  builder
    .addCase(initializeAuthToken.fulfilled, emptyAccountState)
    .addCase(getAccountById.fulfilled, updateAccount)
    .addCase(getAccountById.rejected, emptyAccountState)
    .addCase(quickAddEmployee.fulfilled, updateEmployeeTableVersion)
    .addCase(updateEmployee.fulfilled, updateEmployeeTableVersion)
    .addCase(archiveEmployee.fulfilled, updateEmployeeTableVersion)
    .addCase(unArchiveEmployee.fulfilled, updateEmployeeTableVersion)
    .addCase(bulkArchiveEmployees.fulfilled, updateEmployeeTableVersion)
    .addCase(bulkUnArchiveEmployees.fulfilled, updateEmployeeTableVersion)
    .addCase(commitBulkEmployeeImport.fulfilled, updateEmployeeTableVersion)
    .addCase(sendAssessmentInvitations.fulfilled, updateEmployeeTableVersion)
    .addCase(releaseResults.fulfilled, updateEmployeeTableVersion)
    .addCase(updateEmployeeTableVersionId, (state, {payload}) => {
      return {
        ...state,
        employeeTableVersionId: payload.employeeTableVersionId,
      };
    })
    .addCase(getEmployeeMetrics.fulfilled, updateEmployeeMetrics)
    .addCase(addUser.fulfilled, updateUserTableVersion)
    .addCase(updateUser.fulfilled, updateUserTableVersion)
    .addCase(deleteUser.fulfilled, updateUserTableVersion);
});
