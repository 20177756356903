import React from 'react';
import styled from '@emotion/styled';
import {assertExists} from 'wnd-util/lib/assert';
import {ActionItemStatus, Administration, Employee} from '>generated/dvp.types';
import * as colors from 'wnd-themes/lib/colorPalette';
import * as Icons from '>shared/components/icon/icons';

export const ColoredDot: React.FC<{color: string}> = styled.span<{color: string}>`
  height: 1.2rem;
  width: 1.2rem;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
`;

export const actionItemStatusOptions = [
  {label: ActionItemStatus.ToDo, value: ActionItemStatus.ToDo, icon: Icons.inProgress},
  {
    label: ActionItemStatus.Committed,
    value: ActionItemStatus.Committed,
    icon: Icons.committed,
  },
  {label: ActionItemStatus.Completed, value: ActionItemStatus.Completed, icon: Icons.completed},
  {label: ActionItemStatus.Archived, value: ActionItemStatus.Archived, icon: Icons.archive},
];

export const getAttributeList = (employee: Employee) => {
  assertExists(
    employee.administrations,
    'Employee administrations should exists when rendering the attribute list of Action Planner'
  );

  return getAttributeListFromEmployeeAdministrations(employee.administrations);
};

const getAttributeListFromEmployeeAdministrations = (administrations: Administration[]) => {
  return administrations
    .map((administration) => administration.scales)
    .flat()
    .map((scale) => {
      return {
        label: scale.name,
        value: scale.name,
      };
    });
};

export const taskTypeList = [
  {label: 'Number 1', value: '1'},
  {label: 'Number 2', value: '2'},
  {label: 'Number 3', value: '3'},
  {label: 'Number 4', value: '4'},
  {label: 'Number 5', value: '5'},
];

export const actionItemColors = [
  {name: 'Orange', color: colors.orange500, lightColor: colors.orange200},
  {name: 'Purple', color: colors.violet500, lightColor: colors.violet200},
  {name: 'Blue', color: colors.blue500, lightColor: colors.blue200},
  {name: 'Green', color: colors.success500, lightColor: colors.success200},
  {name: 'Red', color: colors.error500, lightColor: colors.error200},
  {name: 'Grey', color: colors.gray500, lightColor: colors.gray200},
  {name: 'Indigo', color: colors.indigo500, lightColor: colors.indigo200},
];
export const colorList = actionItemColors.map((color) => {
  return {
    label: (
      <>
        <ColoredDot color={color.color} /> {color.name}
      </>
    ),
    value: color.name,
  };
});
