import * as React from 'react';
import {SectionContainer} from '>shared/components/book/sidebar/sectionContainer';
import {Icon} from '>shared/components/icon/icon';
import {DonutVariant} from 'wnd-dvp-reports';
import {DonutMetric} from '>components/measures/donutMetric/donutMetric';
import {SpotlightFlag} from 'wnd-dvp-reports';

import {useTheme} from '@emotion/react';
import {
  ConstructDomain,
  mapInterpretationBucketToColor,
  mapScoreToInterpretationBucket,
  BucketMapping,
} from 'wnd-dvp-reports';
import styled from '@emotion/styled';
import {landscapeIcon, roleSuccessIcon, starIcon} from '>shared/components/icon/icons';

export enum MeasureType {
  Normal,
  NeededForSuccess,
  GreatestStrength,
  Opportunity,
}

interface MeasureProps {
  active?: boolean;
  score: number;
  name: string;
  measureType: MeasureType;
  sectionId: string;
  constructDomain: ConstructDomain;
  spotlightFlags?: SpotlightFlag[];
  bucketMapping: BucketMapping;
}

const spotlightFlagContent = {
  [SpotlightFlag.Critical]: roleSuccessIcon,
  [SpotlightFlag.FocusOpportunity]: landscapeIcon,
  [SpotlightFlag.Strength]: starIcon,
};

const SpotlightFlagIcon = styled(Icon)`
  margin-left: 0.4rem;
  width: 1.6rem;
  height: 1.6rem;
`;

export const Measure = React.memo(
  React.forwardRef<HTMLAnchorElement, MeasureProps>(
    ({active, score, name, sectionId, constructDomain, spotlightFlags, bucketMapping}, ref) => {
      const theme = useTheme();
      const [hover, setHover] = React.useState(false);
      const hoverChangeHandler = (hoverState: boolean) => {
        setHover(hoverState);
      };
      const interpretationBucket = mapScoreToInterpretationBucket(
        score,
        constructDomain,
        bucketMapping
      );

      const color = mapInterpretationBucketToColor(interpretationBucket, constructDomain);
      const suffix = (
        <>
          {spotlightFlags &&
            spotlightFlags.length >= 1 &&
            spotlightFlags.map((spotlightFlag) => {
              const spotlightIcon = spotlightFlagContent[spotlightFlag];
              return (
                <SpotlightFlagIcon
                  key={spotlightFlag}
                  src={spotlightIcon}
                  primaryColor={
                    active || hover
                      ? theme.button.primary.active.backgroundColor
                      : theme.button.primary.disabled.backgroundColor
                  }
                />
              );
            })}
        </>
      );

      return (
        <SectionContainer
          ref={ref}
          active={active}
          sectionId={sectionId}
          prefix={
            <DonutMetric
              isActive={active || hover}
              variant={DonutVariant.Small}
              score={score}
              color={color}
            />
          }
          suffix={suffix}
          onHoverChange={hoverChangeHandler}
        >
          {name}
        </SectionContainer>
      );
    }
  )
);

Measure.displayName = 'Measure';
