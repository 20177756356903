import * as React from 'react';

import {Drawer} from '>shared/components/drawer/drawer';
import {Option} from '>shared/components/form/rawControls/rawSelect';

import {UserRole} from '>generated/dvp.types';
import {TableUser} from '>root/pages/adminUserManagement/userManagementPage';

import {EditUserForm} from './editUser';

interface EditUserModalProps {
  initialRoleEditedUser?: UserRole;
  isOpen: boolean;
  onClose: () => void;
  roleLoggedInUser: UserRole;
  roleOptions: Option[];
  user?: TableUser;
}

export const EditUserDrawerModal: React.FC<EditUserModalProps> = ({
  isOpen,
  onClose,
  roleLoggedInUser,
  roleOptions,
  user,
}) => {
  return (
    <Drawer isOpen={isOpen}>
      {user && (
        <EditUserForm
          onClose={onClose}
          initialRoleEditedUser={user.role}
          roleLoggedInUser={roleLoggedInUser}
          roleOptions={roleOptions}
          user={user}
        />
      )}
    </Drawer>
  );
};
