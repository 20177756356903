import * as React from 'react';
export function useUserPref(key, defaultValue, version) {
    var _a = React.useState(function () {
        try {
            var localValue = localStorage.getItem(key);
            if (localValue) {
                var storedPref = JSON.parse(localValue);
                if ((storedPref === null || storedPref === void 0 ? void 0 : storedPref.v) === version) {
                    return storedPref.value;
                }
            }
        }
        catch (err) {
            return defaultValue;
        }
        return defaultValue;
    }), value = _a[0], setValue = _a[1];
    React.useEffect(function () {
        localStorage.setItem(key, JSON.stringify({ value: value, v: version }));
    }, [value]);
    return [value, setValue];
}
