import * as React from 'react';
import styled from '@emotion/styled';
import {useSelector} from 'react-redux';
import {assertExists} from 'wnd-util/lib/assert';

import {Action, ActionType, Modal} from '>shared/components/modal/modal';
import {AlertModal, AlertModalVariants} from '>shared/components/modal/alertModal';
import {CheckBox, CheckboxSizes} from '>shared/components/form/checkBox';
import {IconAvatar} from '>shared/components/avatar/avatar';
import {LightCaption} from '>shared/components/typography/baseTypography2';
import {ModalDisplayType} from '>shared/components/modal/displayType';
import {peopleFilledIcon, personFilledIcon} from '>shared/components/icon/icons';
import {useModal} from '>shared/components/modal/useModal';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';
import {vr4} from '>shared/styles/mixins/verticalRhythm';

import {BulkEmployeeSelection, FilterQuery} from '>generated/dvp.types';
import {dvpApi} from '>root/apis';
import {EmployeeSelection} from '>root/pages/adminEmployees/employeesTable';
import {sendAssessmentInvitations} from '>root/store/actions/employee';
import {useAppDispatch} from '>root/store/main';

import {ModalSubHeading, ModalText} from '../styles';
interface EmployeeDetails {
  firstName: string;
  lastName: string;
  lastAssessmentStatus: string;
  autoRelease?: boolean;
  preferredName?: string;
}

interface Props {
  employeeSelection: EmployeeSelection;
  employeeDetails?: EmployeeDetails;
  onInvite?(): void;
  filterQuery?: FilterQuery;
}
enum SendInviteState {
  WARNING,
  READY_TO_SEND,
}

const warnings: {[key: string]: string} = {
  AwaitingResults: 'has already completed an assessment. Would you like to submit a retest?',
  ResultsReleased: 'has already completed an assessment. Would you like to submit a retest?',
  InProgress: 'has an in progress assessment. Would you like to send a new invite? ',
  Invited: 'has already received an invitation. Would you like to send a new invite?',
  NotStarted: 'has already received an invitation. Would you like to send a new invite?',
};

export const AssessmentInvitationModal: React.FC<Props> = ({
  employeeSelection,
  employeeDetails,
  onInvite,
  filterQuery,
}) => {
  const account = useSelector((state) => state.account.account);
  assertExists(account, 'account must exist');
  const accountId = account.id;

  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = React.useState<
    EmployeeDetails | undefined
  >(employeeDetails);

  const [isBusy, setIsBusy] = React.useState(false);
  const [autoRelease, setAutoRelease] = React.useState(
    employeeDetails ? employeeDetails.autoRelease !== false : true
  );
  const {hideModal} = useModal();
  const {showSuccessAlert, showErrorAlert} = useSnackbar();
  const dispatch = useAppDispatch();

  const numOfInvitations = employeeSelection.selectionCount;
  const isSingleInvitation = numOfInvitations === 1;

  React.useEffect(() => {
    if (!selectedEmployeeDetails) {
      const {activeEmployees} = employeeSelection;
      const firstSelectedEmployeeId = activeEmployees.keys().next().value;

      fetchEmployeeDetails(firstSelectedEmployeeId);
    }

    async function fetchEmployeeDetails(employeeId: string) {
      const {data} = await dvpApi.getEmployeeById({accountId, employeeId});

      setSelectedEmployeeDetails({
        firstName: data.firstName,
        lastName: data.lastName,
        lastAssessmentStatus: data.lastAssessmentStatus,
        autoRelease: data.autoRelease,
        preferredName: data.preferredName,
      });
    }
  }, [employeeDetails]);

  let invitationRecipients: string = '';

  if (selectedEmployeeDetails) {
    const name = `${selectedEmployeeDetails.preferredName ?? selectedEmployeeDetails.firstName} ${
      selectedEmployeeDetails.lastName
    }`;
    if (isSingleInvitation) {
      invitationRecipients = name;
    } else {
      const numRemaining = numOfInvitations - 1;

      invitationRecipients = `${name} and ${
        numRemaining > 1 ? `${numRemaining} others` : '1 other person'
      }`;
    }
  }

  let warning;
  if (isSingleInvitation && employeeDetails) {
    warning = warnings[employeeDetails.lastAssessmentStatus];
  }

  const [state, setState] = React.useState(
    warning ? SendInviteState.WARNING : SendInviteState.READY_TO_SEND
  );

  const handleWarningConfirm = React.useCallback(async () => {
    setState(SendInviteState.READY_TO_SEND);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    setIsBusy(true);

    const bulkSelection: BulkEmployeeSelection = {
      employeeSelectionMode: employeeSelection.selectionMode,
      employeeIds: Array.from(employeeSelection.activeEmployees.keys()),
      filterQuery,
      autoRelease,
    };

    const response = await dispatch(sendAssessmentInvitations(bulkSelection));
    if (sendAssessmentInvitations.rejected.match(response)) {
      showErrorAlert('Something went wrong. Please try again.');
    } else {
      const snackbarMessage = `Success! ${numOfInvitations} ${
        isSingleInvitation ? 'Invitation' : 'Invitations'
      } sent.`;
      showSuccessAlert(snackbarMessage);
    }
    onInvite?.();

    setIsBusy(false);
    hideModal();
  }, [isBusy, onInvite, autoRelease]);

  const modalActions: Action[] = [
    {
      type: ActionType.Primary,
      label: state === SendInviteState.READY_TO_SEND ? 'Send' : 'Confirm',
      inputType: 'button',
      disabled: isBusy,
      onClick: state === SendInviteState.READY_TO_SEND ? handleSubmit : handleWarningConfirm,
      qaDataAttribute: 'send-invite-button',
    },
    {
      type: ActionType.Secondary,
      label: 'Cancel',
      inputType: 'button',
      disabled: isBusy,
      onClick: hideModal,
      qaDataAttribute: 'cancel-send-invites-button',
    },
  ];

  return state === SendInviteState.WARNING ? (
    <AlertModal
      actions={modalActions}
      title={'Send Invitation'}
      subtitle={`${invitationRecipients} ${warning}`}
      variant={AlertModalVariants.Warning}
    />
  ) : (
    <Modal
      headerOptions={{title: isSingleInvitation ? 'Send Invitation to' : 'Send Invitations to'}}
      actions={modalActions}
      displayType={ModalDisplayType.Alert}
      isLocked={isBusy}
    >
      <ModalSubHeading>
        <IconAvatar
          iconSrc={isSingleInvitation ? personFilledIcon : peopleFilledIcon}
          text={'Person Icon'}
        />
        <ModalText>{invitationRecipients}</ModalText>
      </ModalSubHeading>

      <LightCaption css={vr4}>
        Note: Invites expire after 90 days and assessment completion reminders will be sent after 14
        days.
      </LightCaption>
      <CheckBox
        label="Automatically share employee reports when available"
        checked={autoRelease}
        onChange={(checked) => {
          setAutoRelease(checked);
        }}
        trimLeftMargin
        size={CheckboxSizes.SmallMedium}
      />
    </Modal>
  );
};
