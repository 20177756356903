import React from 'react';
import {ActionItem, ActionItemStatus} from '>generated/dvp.types';
import styled from '@emotion/styled';
import {
  ActionItemCard,
  ActionItemCardClickEvent,
  TemplateAttributeCard,
  TemplateAttributeCardClickEvent,
} from './navigationCard';
import {NavEmptyPage} from './navigationEmptyPage';
import {miniScrollbar} from '>shared/styles/scrollbar';
import {respondTo} from '>shared/styles/breakpoints';
import {useSelector} from 'react-redux';
import {Dictionary, groupBy, sortBy} from 'lodash';
import {rollbarLogger} from '>lib/logger';
import {redirectToErrorPage} from '>lib/redirect';
import {getActionItems} from '>root/store/actions/actionPlanner';
import {Spinner} from '>shared/components/spinner';
import {useAsyncDispatch} from '>root/store/main';
import {vr1, vr4} from '>shared/styles/mixins/verticalRhythm';
import {textSmMedium} from '>shared/components/typography/designSystemTypography';
import {EmotionJSX} from '@emotion/react/types/jsx-namespace';
import {assert} from 'wnd-util/lib/assert';

// The string type is for selecting attributes
export type ActionItemNavSelection = ActionItem | string | undefined;

interface Props {
  onActionItemCardClicked: ActionItemCardClickEvent;
  onTemplateAttributeClicked: TemplateAttributeCardClickEvent;
  currentActionItemId?: ActionItemNavSelection;
  view: ActionItemView;
  templateAttributes: string[];
}

export const navPadding = '1.6rem';
export const navPaddingMobile = '0rem';

export enum ActionItemView {
  Open = 'Open',
  Completed = 'Completed',
  Archived = 'Archived',
  RecommendedTemplates = 'Recommended Action Items',
}

const NavigationContainer = styled.div`
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
  margin: 0 auto;

  ${respondTo.mediumOrHigher} {
    padding: 0 ${navPadding} 0.8rem ${navPadding};
  }
  ${miniScrollbar}
`;

const GroupHeader = styled.div`
  ${vr1}
  ${textSmMedium}
`;

interface ActionItemCardGroupProps {
  actionItems: ActionItem[];
  onCardClicked: ActionItemCardClickEvent;
  currentSelectionId?: string;
  groupHeader?: string;
}

// The user can select either an action item, or an attribute for action item templates
export function isSelectionAnActionItem(selectedItem?: ActionItemNavSelection): boolean {
  return selectedItem !== undefined && typeof selectedItem !== 'string';
}

export function isSelectionAttribute(selectedItem?: ActionItemNavSelection): boolean {
  return typeof selectedItem === 'string';
}

export function verifyIsAttribute(item: ActionItemNavSelection): asserts item is string {
  assert(isSelectionAttribute(item), 'selection must be an attribute');
}

export function verifyIsNotActionItem(
  item: ActionItemNavSelection
): asserts item is string | undefined {
  assert(isSelectionAttribute(item) || item === undefined, 'selection cannot be an action item');
}

export function verifyIsNotAttribute(
  item: ActionItemNavSelection
): asserts item is ActionItem | undefined {
  assert(isSelectionAnActionItem(item) || item === undefined, 'selection cannot be an attribute');
}

function buildStatusNavItemList(
  allUserActionItems: ActionItem[],
  view: ActionItemView,
  onActionItemClicked: ActionItemCardClickEvent,
  currentSelection?: ActionItem
): EmotionJSX.Element[] {
  const statuses = getStatusesByView(view);
  const maxStatusesForView = statuses.length;
  const actionItemsByStatus = allUserActionItems.filter((a) => statuses.includes(a.status));

  if (actionItemsByStatus.length === 0) {
    return [
      <NavEmptyPage
        key="navEmptyPage"
        needToCreate={allUserActionItems.length === 0}
        statusView={view}
      />,
    ];
  }

  const dueDateSortedItems: ActionItem[] = sortBy(actionItemsByStatus, 'dueDate');
  const groupedItems: Dictionary<ActionItem[]> = groupBy(dueDateSortedItems, (a) => a.status);
  const shouldShowStatusHeader = maxStatusesForView > 1;

  const groupedCommittedFirstItems: Dictionary<ActionItem[]> = {};

  // Committed items should appear first if they're included in the View.
  if (groupedItems[ActionItemStatus.Committed]) {
    groupedCommittedFirstItems[ActionItemStatus.Committed] =
      groupedItems[ActionItemStatus.Committed];

    delete groupedItems[ActionItemStatus.Committed];
  }

  // Add the non-committed statuses
  Object.keys(groupedItems).forEach((status) => {
    groupedCommittedFirstItems[status] = groupedItems[status];
  });

  return Object.entries(groupedCommittedFirstItems).map(([status, actionItems]) => {
    return (
      <ActionItemCardGroup
        key={status}
        groupHeader={shouldShowStatusHeader ? status : undefined}
        actionItems={actionItems}
        onCardClicked={onActionItemClicked}
        currentSelectionId={currentSelection?.id}
      />
    );
  });
}

function buildRecommendedItemList(
  templateAttributes: string[],
  onTemplateAttributeClicked: TemplateAttributeCardClickEvent,
  currentSelectedAttribute?: string
): EmotionJSX.Element[] {
  const sortedTemplateAttributes = templateAttributes.sort();

  return sortedTemplateAttributes.map((attribute) => {
    return (
      <TemplateAttributeCard
        key={attribute}
        attribute={attribute}
        onClick={onTemplateAttributeClicked}
        selected={currentSelectedAttribute === attribute}
      />
    );
  });
}

function buildNavItemList(
  allUserActionItems: ActionItem[],
  templateAttributes: string[],
  view: ActionItemView,
  onActionItemCardClicked: ActionItemCardClickEvent,
  onTemplateAttributeClicked: TemplateAttributeCardClickEvent,
  currentItemSelection: ActionItemNavSelection
): EmotionJSX.Element[] {
  let items = [];

  // This is a custom view that is unrelated to statuses entirely.
  // It shows you a list of all available attributes which have
  // templates to create new action items from.
  if (view === ActionItemView.RecommendedTemplates) {
    verifyIsNotActionItem(currentItemSelection);

    items = buildRecommendedItemList(
      templateAttributes,
      onTemplateAttributeClicked,
      currentItemSelection
    );
  } else {
    verifyIsNotAttribute(currentItemSelection);
    items = buildStatusNavItemList(
      allUserActionItems,
      view,
      onActionItemCardClicked,
      currentItemSelection
    );
  }

  return items;
}

const ActionItemCardGroup: React.FC<ActionItemCardGroupProps> = ({
  groupHeader,
  actionItems,
  onCardClicked,
  currentSelectionId,
}) => {
  return (
    <div css={[vr4]}>
      {groupHeader && <GroupHeader>{groupHeader}</GroupHeader>}
      {actionItems.map((actionItem) => (
        <ActionItemCard
          key={actionItem.id}
          actionItem={actionItem}
          onClick={onCardClicked}
          selected={actionItem.id === currentSelectionId}
        />
      ))}
    </div>
  );
};

export function getStatusesByView(view: ActionItemView): ActionItemStatus[] {
  switch (view) {
    case ActionItemView.Open:
      return [ActionItemStatus.ToDo, ActionItemStatus.Committed];
    case ActionItemView.Completed:
      return [ActionItemStatus.Completed];
    case ActionItemView.Archived:
      return [ActionItemStatus.Archived];
    default:
      throw new Error(`The ${view} view is not intended to show statuses.`);
  }
}

export function getViewByStatus(status: ActionItemStatus): ActionItemView {
  switch (status) {
    case ActionItemStatus.ToDo:
    case ActionItemStatus.Committed:
      return ActionItemView.Open;
    case ActionItemStatus.Archived:
      return ActionItemView.Archived;
    case ActionItemStatus.Completed:
      return ActionItemView.Completed;
  }
}

export const Navigation: React.FC<Props> = ({
  onActionItemCardClicked,
  onTemplateAttributeClicked,
  currentActionItemId,
  view,
  templateAttributes,
}) => {
  const asyncDispatch = useAsyncDispatch();
  const actionItems = useSelector((state) => state.actionPlanner.actionItems);

  React.useEffect(() => {
    if (!actionItems) {
      asyncDispatch(getActionItems()).catch((err) => {
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      });
    }
  }, [actionItems]);

  if (!actionItems) {
    return <Spinner />;
  }

  return (
    <NavigationContainer>
      {buildNavItemList(
        actionItems,
        templateAttributes,
        view,
        onActionItemCardClicked,
        onTemplateAttributeClicked,
        currentActionItemId
      )}
    </NavigationContainer>
  );
};
