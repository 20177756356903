import {assert} from 'wnd-util';

const USE_MOCK_URLS =
  window.location.search.includes('USE_MOCK=true') ||
  window.localStorage.getItem('USE_MOCK') === 'true' ||
  false;

function getEnv(name: string, value?: string): string {
  assert.assertExists(value, `${name} must be configured for this to work`);
  return value;
}

// TODO: Autogenerate this file based on data from webpack.overrides.js
//  OR just declare that they exist in a declaration file...
assert.assertExists(process.env.API_URL, 'API_URL must be configured for this to work');
export const API_URL = USE_MOCK_URLS
  ? getEnv('MOCK_API_URL', process.env.MOCK_API_URL)
  : process.env.API_URL;

assert.assertExists(process.env.LOGGER_ENABLED, 'LOGGER_ENABLED must be configured');
export const LOGGER_ENABLED: boolean = process.env.LOGGER_ENABLED as any;
assert.assertExists(process.env.LOGGER_ENV, 'LOGGER_ENV must be configured');
export const LOGGER_ENV = process.env.LOGGER_ENV;
assert.assertExists(process.env.LOGGER_ACCESS_TOKEN, 'LOGGER_ACCESS_TOKEN must be configured');
export const LOGGER_ACCESS_TOKEN = process.env.LOGGER_ACCESS_TOKEN;
assert.assertExists(process.env.LOGGER_ENVIRONMENT, 'LOGGER_ENVIRONMENT must be configured');
export const LOGGER_ENVIRONMENT = process.env.LOGGER_ENVIRONMENT;
assert.assertExists(process.env.LOGGER_IGNORE_LIST, 'LOGGER_IGNORE_LIST must be configured');
export const LOGGER_IGNORE_LIST = process.env.LOGGER_IGNORE_LIST;

assert.assertExists(process.env.AUTH_URL, 'AUTH_URL must be configured for this to work');
export const AUTH_URL = USE_MOCK_URLS
  ? getEnv('MOCK_AUTH_URL', process.env.MOCK_AUTH_URL)
  : process.env.AUTH_URL;

assert.assertExists(process.env.API_AUTH_URL, 'API_AUTH_URL must be configured for this to work');
export const API_AUTH_URL = USE_MOCK_URLS
  ? getEnv('MOCK_API_AUTH_URL', process.env.MOCK_API_AUTH_URL)
  : process.env.API_AUTH_URL;

assert.assertExists(
  process.env.API_UNIFIED_ACCOUNT_URL,
  'API_UNIFIED_ACCOUNT_URL must be configured for this to work'
);
export const API_UNIFIED_ACCOUNT_URL = USE_MOCK_URLS
  ? getEnv('MOCK_API_UNIFIED_ACCOUNT_URL', process.env.MOCK_API_UNIFIED_ACCOUNT_URL)
  : process.env.API_UNIFIED_ACCOUNT_URL;

assert.assertExists(
  process.env.API_NOTIFICATION_URL,
  'API_NOTIFICATION_URL must be configured for this to work'
);
export const API_NOTIFICATION_URL = USE_MOCK_URLS
  ? getEnv('MOCK_API_NOTIFICATION_URL', process.env.MOCK_API_NOTIFICATION_URL)
  : process.env.API_NOTIFICATION_URL;

assert.assertExists(
  process.env.WEBUI_WONSCORE_URL,
  'WEBUI_WONSCORE_URL must be configured for this to work'
);
export const WEBUI_WONSCORE_URL = process.env.WEBUI_WONSCORE_URL;

assert.assertExists(
  process.env.WEBUI_ECOMMERCE_URL,
  'WEBUI_ECOMMERCE_URL must be configured for this to work'
);
export const WEBUI_ECOMMERCE_URL = process.env.WEBUI_ECOMMERCE_URL;

assert.assertExists(process.env.WEBUI_DVP_URL, 'WEBUI_DVP_URL must be configured for this to work');
export const WEBUI_DVP_URL = process.env.WEBUI_DVP_URL;

assert.assertExists(
  process.env.WEBUI_BILLING_URL,
  'WEBUI_BILLING_URL must be configured for this to work'
);
export const WEBUI_BILLING_URL = process.env.WEBUI_BILLING_URL;

assert.assertExists(process.env.WEBUI_PRIVACY_URL, 'WEBUI_PRIVACY_URL must be configured');
export const WEBUI_PRIVACY_URL = process.env.WEBUI_PRIVACY_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_WONSCORE_URL,
  'WEBUI_TOOLKIT_WONSCORE_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_WONSCORE_URL = process.env.WEBUI_TOOLKIT_WONSCORE_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_RESEARCH_URL,
  'WEBUI_TOOLKIT_RESEARCH_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_RESEARCH_URL = process.env.WEBUI_TOOLKIT_RESEARCH_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_ACQUIRETM_URL,
  'WEBUI_TOOLKIT_ACQUIRETM_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_ACQUIRETM_URL = process.env.WEBUI_TOOLKIT_ACQUIRETM_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_APPLICANTPRO_URL,
  'WEBUI_TOOLKIT_APPLICANTPRO_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_APPLICANTPRO_URL = process.env.WEBUI_TOOLKIT_APPLICANTPRO_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_BIZMERLIN_URL,
  'WEBUI_TOOLKIT_BIZMERLIN_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_BIZMERLIN_URL = process.env.WEBUI_TOOLKIT_BIZMERLIN_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_HIRINGTHING_URL,
  'WEBUI_TOOLKIT_HIRINGTHING_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_HIRINGTHING_URL = process.env.WEBUI_TOOLKIT_HIRINGTHING_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_ICIMS_URL,
  'WEBUI_TOOLKIT_ICIMS_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_ICIMS_URL = process.env.WEBUI_TOOLKIT_ICIMS_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_JOBMA_URL,
  'WEBUI_TOOLKIT_JOBMA_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_JOBMA_URL = process.env.WEBUI_TOOLKIT_JOBMA_URL;

assert.assertExists(
  process.env.WEBUI_TOOLKIT_NEWTON_URL,
  'WEBUI_TOOLKIT_NEWTON_URL must be configured for this to work'
);
export const WEBUI_TOOLKIT_NEWTON_URL = process.env.WEBUI_TOOLKIT_NEWTON_URL;

assert.assertExists(process.env.CLIENT_ID, 'CLIENT_ID must be configured for this to work');
export const CLIENT_ID = process.env.CLIENT_ID;
assert.assertExists(process.env.CLIENT_SCOPES, 'CLIENT_SCOPES must be configured for this to work');
export const CLIENT_SCOPES = process.env.CLIENT_SCOPES;

assert.assertExists(process.env.PENDO_ENABLED, 'PENDO_ENABLED must be configured');
export const PENDO_ENABLED = process.env.PENDO_ENABLED;

assert.assertExists(process.env.PENDO_API_KEY, 'PENDO_API_KEY must be configured');
export const PENDO_API_KEY = process.env.PENDO_API_KEY;

assert.assertExists(
  process.env.INTERCOM_BASE_HELP_URL,
  'INTERCOM_BASE_HELP_URL must be configured'
);
export const INTERCOM_BASE_HELP_URL = process.env.INTERCOM_BASE_HELP_URL;

assert.assertExists(process.env.INTERCOM_APP_ID, 'INTERCOM_APP_ID must be configured');
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

assert.assertExists(process.env.INTERCOM_ENABLED, 'INTERCOM_ENABLED must be configured');
export const INTERCOM_ENABLED = process.env.INTERCOM_ENABLED;

assert.assertExists(
  process.env.SELECTION_PRODUCT_NAME,
  'SELECTION_PRODUCT_NAME must be configured'
);
export const SELECTION_PRODUCT_NAME = process.env.SELECTION_PRODUCT_NAME;

assert.assertExists(
  process.env.ENABLE_DEVELOP_PRODUCT,
  'ENABLE_DEVELOP_PRODUCT must be configured'
);
export const ENABLE_DEVELOP_PRODUCT = process.env.ENABLE_DEVELOP_PRODUCT;

assert.assertExists(
  process.env.DEVELOPMENT_PRODUCT_NAME,
  'DEVELOPMENT_PRODUCT_NAME must be configured'
);
export const DEVELOPMENT_PRODUCT_NAME = process.env.DEVELOPMENT_PRODUCT_NAME;

export const TERMS_AND_CONDITIONS_VERSION = process.env.TERMS_AND_CONDITIONS_VERSION;

assert.assertExists(
  process.env.ENABLE_COOKIES_CONSENT,
  'ENABLE_COOKIES_CONSENT must be configured'
);
export const ENABLE_COOKIES_CONSENT = process.env.ENABLE_COOKIES_CONSENT;
