import * as React from 'react';
import {useUIDSeed} from 'react-uid';
import {addIcon, filterListIcon, roundedCloseIcon} from '>shared/components/icon/icons';
import {MenuItem, MenuLinkItem} from '>shared/components/menu/menuItem';
import {ButtonType} from '>shared/components/button/button';
import {Icon} from '>shared/components/icon/icon';
import {Icons} from '>styles/icon-types';
import {Dropdown, DropdownPosition} from '>shared/components/menu/dropdown';
import {Menu, MenuItemContainer} from '>shared/components/menu/menu.styles';
import {CheckboxSizes} from '>shared/components/form/checkBox';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {
  Filters,
  SelectFilter,
  useAssessmentStatusTags,
  useVerbatimTags,
} from './manageAppliedFilters';
import {AppliedFilterTags, Tags} from './employeesFilterModal';
import {assertExists, assert} from 'wnd-util/lib/assert';
import {
  FilterButtonContainer,
  FilterMenuContainer,
  FilterOptionCheckbox,
  FilterOptionDropdown,
  FilterOptionMenuItem,
  FilterSearchInput,
  NoMatchesMenuItem,
} from './filterButton.styles';
import {mediaQueries} from '>shared/styles/breakpoints';

type FilterMenuLinkItem = MenuLinkItem & {id: keyof Tags};

export interface FilterMenuProps {
  menuItems: FilterMenuLinkItem[];
  selectFilter: SelectFilter<keyof Tags>;
  applyFiltersAndTags: (filters: Filters, appliedTags: AppliedFilterTags) => void;
  appliedFilters: Filters;
  subItemPosition?: DropdownPosition;
  hideOnMobile?: boolean;
}

interface FilterSubMenuProps {
  fieldName: keyof Tags;
  subMenuItems: MenuLinkItem[];
  selectFilter: SelectFilter<keyof Tags>;
  applyFiltersAndTags: (filters: Filters, appliedTags: AppliedFilterTags) => void;
  appliedFilters: Filters;
}

function createSubMenuButton(menuItem: FilterMenuLinkItem, onOpen: React.MouseEventHandler) {
  return (
    <MenuItemContainer id={menuItem.id} key={menuItem.id} onClick={onOpen}>
      <MenuItem
        icon={filterListIcon}
        action={menuItem.action}
        hoverStyle={true}
        currentlyUsing={false}
      >
        {menuItem.text}
      </MenuItem>
    </MenuItemContainer>
  );
}

function isFilterOptionChecked(
  fieldName: keyof Tags,
  appliedTags: AppliedFilterTags,
  value: string,
  label?: string | JSX.Element
): boolean {
  assertExists(label, 'label must have a value');

  const tagIndex = appliedTags.findIndex((tag) => {
    return tag.fieldName === fieldName && tag.value === value;
  });

  const isChecked = tagIndex !== -1;
  return isChecked;
}

function onCheckBoxChange(
  checked: boolean,
  selectFilter: SelectFilter<keyof Tags>,
  fieldName: keyof Tags,
  value: string,
  label?: string | JSX.Element
): void {
  assertExists(label, 'label must have a value');

  if (checked) {
    const addFn = selectFilter.addToField(fieldName);
    addFn({label, value});
  } else {
    selectFilter.removeFromField({fieldName, label, value});
  }
}

function buildSubMenuItems(
  fieldName: keyof Tags,
  items: MenuLinkItem[],
  selectFilter: SelectFilter<keyof Tags>,
  applyFiltersAndTags: (filters: Filters, appliedTags: AppliedFilterTags) => void,
  appliedFilters: Filters
) {
  React.useEffect(() => {
    applyFiltersAndTags(appliedFilters, selectFilter.appliedTags);
  }, [appliedFilters, selectFilter.appliedTags]);

  if (items.length === 0) {
    return (
      <MenuItemContainer>
        <NoMatchesMenuItem iconDivHidden={true} truncateText={true}>
          No matches found
        </NoMatchesMenuItem>
      </MenuItemContainer>
    );
  }

  return items.map((item) => {
    return (
      <MenuItemContainer key={item.id}>
        <FilterOptionMenuItem iconDivHidden={true} truncateText={true}>
          <FilterOptionCheckbox
            label={item.text}
            hideLabel={false}
            checked={isFilterOptionChecked(fieldName, selectFilter.appliedTags, item.id, item.text)}
            size={CheckboxSizes.Small}
            onChange={(checked) => {
              onCheckBoxChange(checked, selectFilter, fieldName, item.id, item.text);
            }}
          />
        </FilterOptionMenuItem>
      </MenuItemContainer>
    );
  });
}

const MAX_SUBMENU_ITEMS = 7;

const FilterSubMenu: React.FC<FilterSubMenuProps> = ({
  fieldName,
  subMenuItems,
  selectFilter,
  applyFiltersAndTags,
  appliedFilters,
}) => {
  const [searchedItems, setSearchedItems] = React.useState<MenuLinkItem[]>(
    subMenuItems.slice(0, MAX_SUBMENU_ITEMS)
  );
  const seed = useUIDSeed();

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (evt) => {
      const {value: searchTerm} = evt.target;

      setSearchedItems(
        subMenuItems
          .filter((item) => {
            assertExists(item.text, 'filter menu items must have a text property');
            assert(typeof item.text === 'string', 'filter menu items must be string to compare');
            return item.text.toLowerCase().includes(searchTerm.toLowerCase());
          })
          .slice(0, MAX_SUBMENU_ITEMS)
      );
    },
    [subMenuItems]
  );

  return (
    <Menu>
      <FilterSearchInput
        compact
        id={seed('filter-search')}
        name={'Search Filters'}
        placeholder="Search"
        insetIcon={Icons.Search}
        onChange={onSearch}
      />
      {buildSubMenuItems(
        fieldName,
        searchedItems,
        selectFilter,
        applyFiltersAndTags,
        appliedFilters
      )}
    </Menu>
  );
};

export const FilterMenu: React.FC<FilterMenuProps> = ({
  menuItems,
  selectFilter,
  applyFiltersAndTags,
  appliedFilters,
}) => {
  return (
    <FilterMenuContainer>
      {menuItems.map((menuItem, _index) => {
        return (
          <FilterOptionDropdown
            key={menuItem.id}
            position={DropdownPosition.TopRight}
            offsetX={4}
            renderButton={(onOpen) => {
              return createSubMenuButton(menuItem, onOpen);
            }}
          >
            <FilterSubMenu
              fieldName={menuItem.id}
              subMenuItems={menuItem.menuItems ?? []}
              selectFilter={selectFilter}
              applyFiltersAndTags={applyFiltersAndTags}
              appliedFilters={appliedFilters}
            />
          </FilterOptionDropdown>
        );
      })}
    </FilterMenuContainer>
  );
};

export interface FilterButtonProps {
  appliedFilters: Filters;
  selectFilter: SelectFilter<keyof Tags>;
  applyFiltersAndTags: (filters: Filters, appliedTags: AppliedFilterTags) => void;
  onMobileClick: () => void;
}

export interface ClearFiltersButtonProps {
  onClick: () => void;
}

interface FilterOption {
  id: string;
  text: string;
}

function buildFilterOption(option: Option): FilterOption {
  return {
    id: option.value,
    text: option.label.toString(),
  };
}

export const ClearFiltersButton: React.FC<ClearFiltersButtonProps> = ({onClick}) => {
  return (
    <FilterButtonContainer
      buttonType={ButtonType.Neutral}
      onClick={onClick}
      data-qa-button="clear-filters"
    >
      <Icon src={roundedCloseIcon} small />
      Clear all
    </FilterButtonContainer>
  );
};

export const FilterButton: React.FC<FilterButtonProps> = ({
  appliedFilters,
  selectFilter,
  applyFiltersAndTags,
  onMobileClick,
}) => {
  const jobs = useVerbatimTags(selectFilter.getAllTags, 'jobTitles');
  const departments = useVerbatimTags(selectFilter.getAllTags, 'departments');
  const locations = useVerbatimTags(selectFilter.getAllTags, 'locations');
  const statuses = useAssessmentStatusTags(selectFilter.getAllTags, 'assessmentStatuses');

  const menuItems: FilterMenuLinkItem[] = [
    {
      id: 'jobTitles',
      text: 'Job Title',
      menuItems: jobs.map(buildFilterOption),
    },
    {
      id: 'departments',
      text: 'Department',
      menuItems: departments.map(buildFilterOption),
    },
    {
      id: 'locations',
      text: 'Location',
      menuItems: locations.map(buildFilterOption),
    },
    {
      id: 'assessmentStatuses',
      text: 'Status',
      menuItems: statuses.map(buildFilterOption),
    },
  ];

  const isMobile = window.matchMedia(mediaQueries.smallOrLower.media).matches;

  return (
    <Dropdown
      position={DropdownPosition.AlignBottomRight}
      renderButton={(onClick) => (
        <FilterButtonContainer
          buttonType={ButtonType.Tertiary}
          onClick={isMobile ? onMobileClick : onClick}
          data-qa-button="filter"
        >
          <Icon src={addIcon} small />
          Filter
        </FilterButtonContainer>
      )}
    >
      <FilterMenu
        hideOnMobile={false}
        menuItems={menuItems}
        selectFilter={selectFilter}
        applyFiltersAndTags={applyFiltersAndTags}
        appliedFilters={appliedFilters}
      />
    </Dropdown>
  );
};
