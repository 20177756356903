import styled from '@emotion/styled';
import {SVGSize} from 'wnd-dvp-reports';

export function getGraphicMeasurements(size: SVGSize) {
  switch (size) {
    case SVGSize.Large:
      return {
        width: 60,
        height: 60,
      };
    case SVGSize.Small:
      return {
        width: 36,
        height: 36,
      };
  }
}


export const SVGContainer = styled.div<{containerWidth: number; containerHeight: number}>`
  display: inline-flex;
  width: ${(props) => props.containerWidth}px;
  height: ${(props) => props.containerHeight}px;
`;
