import * as React from 'react';
import styled from '@emotion/styled';
import {ValueType} from 'react-select';

import {ActionType, Modal} from '>shared/components/modal/modal';
import {ControlContainer} from '>shared/components/form/controlContainer';
import {ModalDisplayType} from '>shared/components/modal/displayType';
import {
  AppliedEmployeeFilters,
  AppliedFilter,
  Filters,
  useAssessmentStatusTags,
  useSelectFilter,
  useVerbatimTags,
} from './manageAppliedFilters';
import {RawSelect, Option} from '>shared/components/form/rawControls/rawSelect';
import {useUIDSeed, useUID} from 'react-uid';
import {Icons} from '>shared/styles/icon-types';
import {vr2} from '>styles/mixins/verticalRhythm';
import {useModal} from '>shared/components/modal/useModal';
import {ClearFiltersButton} from './filterButtons';

export interface Tags {
  jobTitles: string[];
  departments: string[];
  locations: string[];
  assessmentStatuses: string[];
}

export interface FilterSelectProps {
  label: string;
  options: Option[];
  onSelectChange(value: ValueType<Option, false>): void;
}

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  padding: 0 2.4rem 2.4rem;
`;

const ModalSelect: React.FC<FilterSelectProps> = ({label, options, onSelectChange}) => {
  const id = useUID();

  return (
    <ControlContainer inputId={id} label={label} css={vr2}>
      <RawSelect
        id={id}
        placeholder={`Filter by ${label}`}
        insetIcon={Icons.Search}
        // This prevents react-select from setting the value
        value={null}
        options={options}
        onSelectChange={onSelectChange}
      />
    </ControlContainer>
  );
};

export type AppliedFilterTags = Array<AppliedFilter<keyof Tags>>;

export const FiltersModal: React.FC<{
  tags: Tags;
  filters: Filters;
  appliedTags: Array<AppliedFilter<keyof Tags>>;
  applyFiltersAndTags: (filters: Filters, appliedTags: AppliedFilterTags) => void;
}> = ({tags, applyFiltersAndTags, filters}) => {
  const {hideModal} = useModal();
  const seed = useUIDSeed();

  const [appliedFilters, setAppliedFilters] = React.useState<Filters>(filters);

  const selectFilter = useSelectFilter(tags, appliedFilters, setAppliedFilters);

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback((evt) => {
    const {value} = evt.target;
    setAppliedFilters((filters) => ({
      ...filters,
      searchText: value,
    }));
  }, []);

  const submitForm = React.useCallback(() => {
    applyFiltersAndTags(appliedFilters, selectFilter.appliedTags);

    hideModal();
  }, [applyFiltersAndTags, appliedFilters, hideModal, selectFilter.appliedTags]);

  return (
    <Modal
      displayType={ModalDisplayType.Wide}
      headerOptions={{title: 'Filter Employees'}}
      actions={[
        {
          type: ActionType.Primary,
          onClick: submitForm,
          label: 'Apply',
          inputType: 'button',
          qaDataAttribute: 'apply-filter-button',
        },
        {
          type: ActionType.Secondary,
          onClick: hideModal,
          label: 'Cancel',
          inputType: 'button',
          qaDataAttribute: 'cancel-filter-button',
        },
      ]}
    >
      <Wrapper>
        <AppliedEmployeeFilters
          appliedTags={selectFilter.appliedTags}
          onRemoveTag={selectFilter.removeFromField}
        />
        <Actions>
          {selectFilter.appliedTags.length > 1 && (
            <ClearFiltersButton onClick={selectFilter.removeAllTags} />
          )}
        </Actions>
        <div>
          <ModalSelect
            label="Job Title"
            options={useVerbatimTags(selectFilter.getAvailableTags, 'jobTitles')}
            onSelectChange={selectFilter.addToField('jobTitles')}
          />
          <ModalSelect
            label="Department"
            options={useVerbatimTags(selectFilter.getAvailableTags, 'departments')}
            onSelectChange={selectFilter.addToField('departments')}
          />
          <ModalSelect
            label="Location"
            options={useVerbatimTags(selectFilter.getAvailableTags, 'locations')}
            onSelectChange={selectFilter.addToField('locations')}
          />
          <ModalSelect
            label="Status"
            options={useAssessmentStatusTags(selectFilter.getAvailableTags, 'assessmentStatuses')}
            onSelectChange={selectFilter.addToField('assessmentStatuses')}
          />
        </div>
      </Wrapper>
    </Modal>
  );
};
