"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchInput = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _button = require("../button/button");
var _icon = require("../icon/icon");
var _icons = require("../icon/icons");
var _accessibility = require("../../styles/mixins/accessibility");
var _searchInput = require("./searchInput.styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const SearchInput = ({
  placeholder,
  inputId,
  onSubmitAction,
  searchLabel
}) => {
  const [inputText, setInputText] = React.useState('');
  const inputRef = React.useRef(null);
  const handleInputFocus = React.useCallback(event => {
    if (event.type === 'blur') {
      setInputText('');
    }
  }, []);
  const handleInputChange = React.useCallback(event => {
    const inputElement = event.target;
    setInputText(inputElement.value);
  }, []);
  const handleButtonFocus = React.useCallback(event => {
    event.preventDefault();
    if (event.relatedTarget === inputRef.current) {
      event.target.blur();
    } else {
      inputRef.current?.focus();
    }
  }, [inputText, inputRef]);
  const handleInputSubmit = React.useCallback(event => {
    event.preventDefault();
    onSubmitAction(inputText);
  }, [inputText]);
  return (0, _jsxRuntime.jsxs)(_searchInput.StyledForm, {
    role: "search",
    onSubmit: handleInputSubmit,
    children: [(0, _jsxRuntime.jsx)("label", {
      htmlFor: inputId,
      css: _accessibility.visuallyHiddenMixin,
      children: searchLabel
    }), (0, _jsxRuntime.jsx)(_searchInput.StyledInput, {
      ref: inputRef,
      autoComplete: "off",
      id: inputId,
      type: "text",
      placeholder: placeholder,
      onFocus: handleInputFocus,
      onBlur: handleInputFocus,
      onChange: handleInputChange,
      value: inputText
    }), (0, _jsxRuntime.jsx)(_searchInput.StyledButton, {
      "aria-label": "Press Enter Key to Search",
      buttonType: _button.ButtonType.Ghost,
      "data-qa-button": `execute-search-for-${inputId}`,
      onFocus: handleButtonFocus,
      size: _button.ButtonSizes.XS,
      children: (0, _jsxRuntime.jsx)(_icon.Icon, {
        src: inputText ? _icons.roundedCloseIcon : _icons.searchGlassIcon
      })
    })]
  });
};
exports.SearchInput = SearchInput;