import React from 'react';
import {ChapterItemType, Introduction, Measure} from '>generated/dvp.types';
import {BookContentsContainer} from '>shared/components/book/sidebar/contents';
import {MeasureCard} from '>components/measures/measureCard/measureCard';
import {vr12, vr4} from '>shared/styles/mixins/verticalRhythm';
import {SectionMarkdown, determineConstructDomainFromSourceConstruct} from 'wnd-dvp-reports';
import {renderText} from 'wnd-dvp-reports';
import {InstructionsWidget} from '>components/widgets/instructionsWidget';
import {visuallyHidden} from '>shared/styles/accessibility';
import {useSelector} from 'react-redux';
import {sharedComponents} from '>lib/markdownSectionComponents';
import {Heading} from '>shared/components/heading/heading';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';

interface Props {
  chapterItemContent: Introduction | Measure;
  chapterItemType: ChapterItemType;
  templateContext: Record<string, any>;
}

export const ChapterItemView: React.FC<Props> = ({
  chapterItemContent,
  chapterItemType,
  templateContext,
}) => {
  const bucketMapping = useSelector((state) => state.interpretationBuckets.bucketMapping);

  const chapterItemView = React.useMemo(() => {
    switch (chapterItemType) {
      case ChapterItemType.Introduction:
        const introductionContent = chapterItemContent as Introduction;
        return (
          <>
            <Heading css={[vr4, displayXsMedium]}>Introduction</Heading>
            <SectionMarkdown components={sharedComponents}>
              {renderText(
                introductionContent.introduction,
                templateContext,
                introductionContent.variables
              )}
            </SectionMarkdown>
            <InstructionsWidget>
              <SectionMarkdown components={sharedComponents}>
                {renderText(
                  introductionContent.instructions,
                  templateContext,
                  introductionContent.variables
                )}
              </SectionMarkdown>
            </InstructionsWidget>
          </>
        );
      case ChapterItemType.Measure:
        const measureContent = chapterItemContent as Required<Measure>;
        return (
          <>
            <Heading css={visuallyHidden}>{measureContent.displayTitles}</Heading>
            <div css={vr12}>
              <MeasureCard
                sharedComponents={sharedComponents}
                title={renderText(
                  measureContent.displayTitles,
                  templateContext,
                  measureContent.variables
                )}
                constructDomain={determineConstructDomainFromSourceConstruct(
                  measureContent.sourceConstruct
                )}
                bucketMapping={bucketMapping}
                score={50}
                synonyms={measureContent.synonyms.map((s) =>
                  renderText(s, templateContext, measureContent.variables)
                )}
                definition={renderText(
                  measureContent.definition,
                  templateContext,
                  measureContent.variables
                )}
              />
            </div>
            <SectionMarkdown components={sharedComponents}>
              {renderText(measureContent.bodyContent, templateContext, measureContent.variables)}
            </SectionMarkdown>
          </>
        );
      default:
        throw new Error(
          `Rendering view for chapter item of type ${chapterItemType} is not supported`
        );
    }
  }, [chapterItemContent, chapterItemType]);

  return <BookContentsContainer>{chapterItemView}</BookContentsContainer>;
};

export function validateContentForPublish(content: Introduction | Measure): boolean {
  let isValidForPublish = true;
  const values = Object.values(content);
  values.forEach((value) => {
    if (value === '' || value === undefined) {
      isValidForPublish = false;
    }
  });

  return isValidForPublish;
}
