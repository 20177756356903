"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AsyncSelect = AsyncSelect;
exports.ControlledAsyncSelect = ControlledAsyncSelect;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _wndReactSelect = require("wnd-react-select");
var _reactHookForm = require("react-hook-form");
var _react2 = require("@emotion/react");
var _reactUid = require("react-uid");
var _controlContainer = require("./controlContainer");
var _iconTypes = require("../../styles/icon-types");
var _rawSelect = require("./rawControls/rawSelect.styles");
var _icon = require("../icon/icon");
var _rawInput = require("./rawControls/rawInput");
var _icons = require("../icon/icons");
var _checkBox = require("./checkBox");
var _verticalRhythm = require("../../styles/mixins/verticalRhythm");
var _filterTag = require("./filterTag");
var _asyncSelect = require("./asyncSelect.styles");
var _baseTypography = require("../typography/baseTypography2");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function buildCustomComponents(isMulti) {
  return {
    ClearIndicator: () => (0, _jsxRuntime.jsx)(React.Fragment, {}),
    DropdownIndicator: () => (0, _jsxRuntime.jsx)(React.Fragment, {}),
    IndicatorSeparator: () => (0, _jsxRuntime.jsx)(React.Fragment, {}),
    MultiValueRemove: props => (0, _jsxRuntime.jsx)(_wndReactSelect.components.MultiValueRemove, _objectSpread(_objectSpread({}, props), {}, {
      children: (0, _jsxRuntime.jsx)(_icon.Icon, {
        src: _icons.roundedCloseIcon,
        small: true
      })
    })),
    Option: props => (0, _jsxRuntime.jsx)(_wndReactSelect.components.Option, _objectSpread(_objectSpread({}, props), {}, {
      children: isMulti ? (0, _jsxRuntime.jsx)(_checkBox.CheckBox, {
        label: props.label,
        checked: props.isSelected,
        size: _checkBox.CheckboxSizes.Small
      }) : props.label
    }))
  };
}
function buildTag(fieldName, option, value,
// react-select doesn't use a more specific type
onChange // react-select doesn't use a more specific type
) {
  const allSelections = [...value];
  return (0, _jsxRuntime.jsx)(_filterTag.FilterTag, {
    text: option.label,
    onRemove: () => {
      const selectionsAfterRemoval = allSelections.filter(s => s.value !== option.value);
      // The 'required' validation rule will not trigger for empty arrays, it must be null.
      const newValue = selectionsAfterRemoval.length === 0 ? null : selectionsAfterRemoval;
      onChange(newValue, {
        action: 'remove-value',
        removedValue: option,
        name: fieldName
      });
    }
  }, option.value);
}
function AsyncSelect({
  label,
  disabled,
  name,
  hasError,
  isMulti,
  placeholder,
  hint,
  value,
  className,
  isRequired,
  onChange,
  getNoResultsMessage,
  loadOptions
}) {
  const inputId = (0, _reactUid.useUID)();
  const theme = (0, _react2.useTheme)();
  const insetIcon = hasError ? _iconTypes.Icons.Error : undefined;
  return (0, _jsxRuntime.jsxs)(_controlContainer.ControlContainer, {
    label: label,
    className: className,
    disabled: disabled,
    required: isRequired,
    inputId: inputId,
    children: [(0, _jsxRuntime.jsxs)(_rawSelect.SelectContainer, {
      css: [_verticalRhythm.vr2],
      hasError: hasError,
      disabled: disabled,
      hasIcon: Boolean(insetIcon),
      className: className,
      isMulti: isMulti,
      children: [(0, _jsxRuntime.jsx)(_asyncSelect.StyledAsyncSelect, {
        inputId: inputId,
        name: name,
        label: label,
        isMulti: isMulti,
        autoFocus: false,
        closeMenuOnSelect: true,
        closeMenuOnScroll: false,
        defaultMenuIsOpen: false,
        onChange: onChange,
        controlShouldRenderValue: !isMulti,
        value: value,
        cacheOptions: true,
        loadOptions: loadOptions,
        menuPlacement: "bottom",
        menuPosition: "absolute",
        placeholder: placeholder,
        loadingMessage: _ => 'Loading results...',
        backspaceRemovesValue: true,
        isClearable: true,
        isSearchable: true,
        hideSelectedOptions: false,
        isDisabled: disabled,
        "aria-required": isRequired,
        "aria-invalid": hasError,
        noOptionsMessage: ({
          inputValue
        }) => getNoResultsMessage(inputValue),
        components: buildCustomComponents(isMulti),
        styles: (0, _asyncSelect.buildStylesConfig)(theme)
      }), insetIcon && (0, _jsxRuntime.jsx)(_icon.FontIcon, {
        "aria-hidden": true,
        icon: insetIcon,
        className: "input-icon",
        css: _rawInput.iconBuffer,
        primaryColor: theme.textInput.color.iconError,
        fontSize: theme.textInput.iconSize
      })]
    }), hint && (0, _jsxRuntime.jsx)(_baseTypography.LightCaption, {
      css: [_verticalRhythm.vr2],
      children: hint
    }), isMulti && (0, _jsxRuntime.jsx)(_asyncSelect.MultiSelectOutput, {
      children: value?.map(option => buildTag(name, option, value, onChange))
    })]
  });
}
function ControlledAsyncSelect({
  label,
  disabled,
  rules,
  name,
  placeholder,
  control,
  isMulti,
  className,
  hasError,
  hint,
  loadOptions,
  getNoResultsMessage
}) {
  return (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
    name: name,
    control: control,
    defaultValue: null,
    rules: rules,
    render: ({
      onChange,
      value
    }) => (0, _jsxRuntime.jsx)(AsyncSelect, {
      label: label,
      disabled: disabled,
      name: name,
      hasError: hasError,
      isMulti: isMulti,
      placeholder: placeholder,
      hint: hint,
      value: value,
      className: className,
      isRequired: Boolean(rules?.required),
      onChange: onChange,
      getNoResultsMessage: getNoResultsMessage,
      loadOptions: loadOptions
    })
  });
}