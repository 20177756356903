import styled from '@emotion/styled';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1.6rem 3.2rem 2.4rem;
  height: 100%;
`;

export const OptionsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  margin-bottom: 3.6rem;
`;

export const ActionButtonContainer = styled.div`
  margin-bottom: 0.8rem;

  button {
    justify-content: flex-start;
    gap: 0.4rem;
  }

  i {
    margin-left: 0.4rem;
  }
`;

export const BottomActionsContainer = styled.div`
  margin-top: auto;
`;
