import * as React from 'react';
import styled from '@emotion/styled';
import {respondTo} from '>styles/breakpoints';
import {AbstractBookPage} from 'wnd-dvp-reports';
import {Chapter, BookLayout, SectionMarkdown} from 'wnd-dvp-reports';
import {assertExists} from 'wnd-util/lib/assert';
import {pubSub} from '>root/events';
import {BookContentsContainer, MeasureSection} from '>shared/components/book/sidebar/contents';
import {
  PaginationButtonContainer,
  PaginationButtonsList,
  PaginationButton,
} from '>shared/components/pagination/paginationButton';
import {MeasureCard} from '>components/measures/measureCard/measureCard';
import {char100} from 'wnd-themes/lib/colorPalette';
import {visuallyHidden} from '>shared/styles/accessibility';
import {vr12, vr4} from '>shared/styles/mixins/verticalRhythm';
import {useSelector} from 'react-redux';
import * as icons from '>shared/components/icon/icons';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {API_URL} from '>root/env';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {MobileSectionHeader} from '>shared/components/landmarks/header/mobileSectionHeader';
import {PageLayoutComponents} from '>shared/components/layouts/responsive/responsiveLayout';
import {useLocation} from 'react-router-dom';
import {EmployeeHomeAnchorPrefix, RoutePath} from '>root/routes/routes';
import {Heading} from '>shared/components/heading/heading';
import {
  displayXsBold,
  displayXsMedium,
  textXlRegular,
} from '>shared/components/typography/designSystemTypography';
import * as colors from 'wnd-themes/lib/colorPalette';
import {downloadPdf} from '>lib/pdf';
import {MobileOverflowMenu} from '>components/mobileOverflowMenu';
import {ActionItemWidget} from '>components/widgets/actionItemWidget/actionItemWidget';
import {sharedComponents} from '>lib/markdownSectionComponents';
import {ActionItemWidgetVariant, ResultsComponents} from 'wnd-dvp-reports';
import {history} from '>root/history';

const headerHeight = '64px';

interface BookContentsProps {
  activeChapter: Chapter;
  activeSectionId: string;
  bookLayout: BookLayout;
  setActiveSectionId(id: string): void;
}

const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BookContents: React.FC<BookContentsProps> = React.memo(
  ({activeChapter, activeSectionId, setActiveSectionId, bookLayout}) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const childContainerRef = React.useRef<HTMLDivElement>(null);

    const refById = React.useRef<Array<[string, HTMLHeadingElement]>>([]);
    const bucketMapping = useSelector((state) => state.interpretationBuckets.bucketMapping);
    const accountState = useSelector((state) => state.account);
    const employee = useSelector((state) => state.employee.employee);
    const {account} = accountState;
    const {hash} = useLocation();

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [hash]);

    const determineActiveSection = React.useCallback((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        const children: Array<HTMLElement> = Array.from(entry.target.children);
        const firstChildWithId: HTMLElement | undefined = children.find((elem) => elem.id);

        if (firstChildWithId) {
          setActiveSectionId(firstChildWithId.id);
        }
      }
    }, []);

    React.useEffect(() => {
      const elementsToObserve = document.querySelectorAll('section[data-scrolllistener]');
      const observer = new IntersectionObserver(determineActiveSection, {
        root: null,
        rootMargin: `-${headerHeight}`,
        threshold: 0.1,
      });

      assertExists(elementsToObserve, 'Section elements must be defined to setup observer');

      elementsToObserve.forEach((element) => {
        observer.observe(element);
      });

      return () => {
        if (elementsToObserve) {
          elementsToObserve.forEach((element) => {
            observer.unobserve(element);
          });
        }
      };
    });

    const findHeadingComponent = React.useCallback(
      (id: string, element: HTMLHeadingElement | null) => {
        if (element) {
          refById.current.push([id, element]);
        }
      },
      []
    );

    React.useEffect(() => {
      const chapterUpdated = () => {
        if (containerRef.current) {
          containerRef.current.scrollTop = 0;
        }
      };

      pubSub.on('chapterUpdated', chapterUpdated);

      () => pubSub.off('chapterUpdated', chapterUpdated);
    }, []);

    const {setHiddenComponent, activeSectionTitle} = useResponsiveLayout();

    const download = React.useCallback(async () => {
      const chapterNumber = activeChapter.id.replace(`${EmployeeHomeAnchorPrefix}-`, '');
      assertExists(account, 'Active account must exist to view this page.');
      assertExists(employee, 'Employee must exist to view this page.');

      const fileName = `${employee?.preferredName || employee?.firstName} ${
        employee?.lastName
      } - Develop Journey Section ${chapterNumber}`;

      const pdfEndpoint = `${API_URL}/accounts/${account.id}/employees/${employee?.id}/book/chapter/${chapterNumber}/pdf`;
      downloadPdf(fileName, pdfEndpoint);
    }, [activeChapter]);

    const menuItems: MenuLinkItem[] = React.useMemo(() => {
      return [
        {
          id: 'download',
          icon: icons.downloadIcon,
          text: 'Download',
          hoverStyle: true,
          action: download,
        },
      ];
    }, [activeChapter]);

    const contentsComponents: Partial<ResultsComponents> = {
      Section: ({title, id, children, instructions}) => {
        return (
          <section data-scrolllistener>
            <Heading id={id} ref={(el) => findHeadingComponent(id, el)} css={[vr4, textXlRegular]}>
              {title}
            </Heading>
            <SectionMarkdown components={sharedComponents}>{children}</SectionMarkdown>
          </section>
        );
      },
      Chapter: ({children, id}) => {
        if (id === activeChapter.id) {
          return (
            <>
              <PageHeadingContainer>
                <Heading id={activeChapter.id} css={displayXsBold}>
                  {activeChapter.title}
                </Heading>
                <MobileOverflowMenu fromHeader={false} menuItems={menuItems} />
              </PageHeadingContainer>
              {children}
            </>
          );
        }
        return <></>;
      },
      Measure: ({
        title,
        score,
        synonyms,
        definition,
        children,
        constructDomain,
        id,
        flags,
        actionItemWidgetParams,
      }) => {
        return (
          <MeasureSection data-scrolllistener>
            <Heading css={[visuallyHidden, displayXsMedium]}>{title}</Heading>
            <div css={vr12} id={id} ref={(el) => findHeadingComponent(id, el)}>
              <MeasureCard
                sharedComponents={sharedComponents}
                title={title}
                score={score}
                synonyms={synonyms}
                bucketMapping={bucketMapping}
                definition={definition}
                constructDomain={constructDomain}
                spotlightFlags={flags}
              />
            </div>
            <SectionMarkdown components={sharedComponents}>{children}</SectionMarkdown>
            <ActionItemWidget
              measureDisplayTitle={title}
              content={actionItemWidgetParams.content}
              templates={actionItemWidgetParams.templates}
              variant={ActionItemWidgetVariant.Results}
              onGoToActionItem={(id: string) => {
                history.push(`/actionplanner?id=${id}`);
              }}
            />
          </MeasureSection>
        );
      },
      Category: ({title, children}) => {
        return (
          <>
            <CategoryHeading>{title}</CategoryHeading>
            <Divider />
            {children}
          </>
        );
      },
      Pagination: ({buttons}) => {
        return (
          <PaginationButtonsList>
            {buttons.map((button) => (
              <PaginationButtonContainer key={button.href}>
                <PaginationButton
                  href={button.href}
                  isDirectionForward={button.isDirectionForward}
                  topText={button.topText}
                  bottomText={button.bottomText}
                />
              </PaginationButtonContainer>
            ))}
          </PaginationButtonsList>
        );
      },
    };

    const hideBody = () => {
      setHiddenComponent(PageLayoutComponents.Body);
    };

    return (
      <BookContentContainer ref={containerRef}>
        <BookContentsContainer ref={childContainerRef}>
          <MobileSectionHeader
            primaryHeader={true}
            onBackButtonClick={hideBody}
            menuButton={<MobileOverflowMenu fromHeader={true} menuItems={menuItems} />}
            sectionHeading={activeSectionTitle ?? activeChapter.abbreviatedTitle}
          />
          <AbstractBookPage
            activeChapterId={activeChapter.id}
            activeSectionId={activeSectionId}
            layout={bookLayout}
            reportBasePath={RoutePath.EmployeeResults}
            bucketMapping={bucketMapping}
            {...contentsComponents}
          />
        </BookContentsContainer>
      </BookContentContainer>
    );
  }
);

export const BookContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 6.4rem 5.6rem;
  flex-grow: 1;
  overflow-y: auto;

  ${respondTo.mediumOrLower} {
    justify-content: center;
    padding: 3.2rem;
  }

  ${respondTo.smallOrLower} {
    padding: 2.4rem;
  }
`;

const CategoryHeading = styled(Heading)`
  ${textXlRegular};
  color: ${colors.gray700};
  margin-bottom: 0.8rem;
`;

const Divider = styled.hr`
  border: 0;
  margin-block-start: 0;
  border-top: 1px solid ${char100};
  width: 100%;
`;
