import * as React from 'react';
import {css} from '@emotion/react';

import {InfiniteScrollContainer, RowState} from '>shared/components/data/infiniteScrollContainer';
import {SkeletonLine} from '>shared/components/util/skeleton';
import {EmployeeData, EmployeeRow} from '>components/employeeTable/employeeRow';
import {EmployeeFetcher, EmployeeTableState} from './employeeFetch';
import {EMPLOYEE_TABLE_PAGE_SIZE} from '../../configuration';
import {FilterSection} from './filters/employeesFilter';
import {Spinner} from '>shared/components/spinner';
import {Filters, SelectFilter} from './filters/manageAppliedFilters';
import {NoItemsView} from '>components/noItemsView';
import {EmployeesTableContainer} from './adminEmployeesPage.styles';
import {Tags} from './filters/employeesFilterModal';
import {AssessmentStatus, EmployeeSelectionMode, JobProfileStatus} from '>generated/dvp.types';
import {useSkipFirstRender} from '>lib/util';
import {DataTableRowContainer} from '>components/dataTable/dataTableRow';
import {
  ActionsCell,
  CheckboxCell,
  EmailCell,
  NameCell,
  OverflowCell,
} from '>components/dataTable/cellStyles';
import {TextContent} from '>components/dataTable/textContent';
import {
  DateCell,
  DepartmentCell,
  LocationCell,
  StatusCell,
  UserSummaryCell,
} from '>components/employeeTable/employeeRowCells';
import {useSelector} from 'react-redux';
import {personFilledIcon} from '>shared/components/icon/icons';
import {AddEditEmployeeModal, EmployeeModalState} from '>components/modal/addEditEmployee';
import {ConfirmJobTitleModal} from '>components/modal/confirmJobTitle';
import {
  DrawerModalType,
  useEmployeeManagementDrawerModal,
} from '>root/hooks/useEmployeeManagementDrawerModal';

interface EmployeesTableProps {
  appliedFilters: Filters;
  setAppliedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  employeeFetcher: EmployeeFetcher;
  selectFilter: SelectFilter<keyof Tags>;
  employeeSelection: EmployeeSelection;
  setEmployeeSelection: React.Dispatch<React.SetStateAction<EmployeeSelection>>;
  numEmployeeMatches: number;
  clearSelection(): void;
}

// Subset of properties to store along with a selected employee
export interface SelectedEmployee {
  // TODO: after SW-20730 is fixed, all fields should be required
  status?: AssessmentStatus;
  jobStatus?: JobProfileStatus;
}

export interface EmployeeSelection {
  selectionMode: EmployeeSelectionMode;
  activeEmployees: Map<string, SelectedEmployee>;
  selectionCount: number;
  visibleRows?: EmployeeData[];
  // Version is just used to enforce a re-render when the set changes
  version: number;
}

export const EmployeesTable: React.FC<EmployeesTableProps> = ({
  appliedFilters,
  setAppliedFilters,
  employeeFetcher,
  selectFilter,
  employeeSelection,
  setEmployeeSelection,
  numEmployeeMatches,
  clearSelection,
}) => {
  // TODO: We can't look at the rows length because there
  //  may be employees, but the filters have resulted in 0
  const hasEmployees = employeeFetcher.rows.length > 0;
  // This will need to be dynamic once we can hide/show columns
  const visibleColumnCount = 11;

  const {
    isDrawerModalOpen,
    modalType,
    selectedEmployee,
    setSelectedEmployee,
    setIsDrawerModalOpen,
  } = useEmployeeManagementDrawerModal();

  const onDrawerModalClose = React.useCallback(() => {
    setIsDrawerModalOpen(false);
    setSelectedEmployee(undefined);
  }, []);

  const updateRow = React.useCallback((data: EmployeeData) => {
    employeeFetcher.setStateToUpdatedRow();
    employeeFetcher.setRows((previousRows) => {
      const index = previousRows.findIndex((row) => {
        return row && row.employee.id === data.employee.id;
      });

      if (index === -1) {
        return previousRows;
      } else {
        const rows = previousRows.concat();
        rows[index] = data;

        return rows;
      }
    });
  }, []);

  useSkipFirstRender(() => {
    clearSelection();
  }, [appliedFilters]);

  const updateSelectedEmployees = React.useCallback(
    (employeeId: string, checked: boolean) => {
      setEmployeeSelection((prevSelection: EmployeeSelection) => {
        const employeeData = employeeFetcher.rows.find((data) => {
          if (data) {
            return data.employee.id === employeeId;
          }
        });

        const addToActiveSet =
          (prevSelection.selectionMode === EmployeeSelectionMode.Inclusive && checked) ||
          (prevSelection.selectionMode === EmployeeSelectionMode.Exclusive && !checked);

        const activeSet = prevSelection.activeEmployees;
        if (addToActiveSet) {
          activeSet.set(employeeId, {
            status: employeeData?.employee.lastAssessmentStatus,
            jobStatus: employeeData?.employee.jobProfileStatus,
          });
        } else {
          activeSet.delete(employeeId);
        }

        let count: number;

        if (prevSelection.selectionMode === EmployeeSelectionMode.Inclusive) {
          count = activeSet.size;
        } else {
          // Exclusive mode
          count = numEmployeeMatches - activeSet.size;
        }

        return {
          ...prevSelection,
          activeEmployees: activeSet,
          version: prevSelection.version + 1,
          selectionCount: count,
        };
      });
    },

    [numEmployeeMatches, employeeFetcher.rows]
  );

  const updateSelectAll = React.useCallback(
    (isSelectAll) => {
      setEmployeeSelection((prevSelection: EmployeeSelection) => {
        prevSelection.activeEmployees.clear();
        return {
          ...prevSelection,
          version: prevSelection.version + 1,
          selectionMode: isSelectAll
            ? EmployeeSelectionMode.Exclusive
            : EmployeeSelectionMode.Inclusive,
          selectionCount: isSelectAll ? numEmployeeMatches : 0,
          visibleRows: employeeFetcher.rows,
        };
      });
    },
    [numEmployeeMatches, employeeFetcher.rows]
  );

  const isEmployeeSelected = (employeeId: string) => {
    const employeeIsActive = employeeSelection.activeEmployees.has(employeeId);

    return (
      (employeeIsActive && employeeSelection.selectionMode === EmployeeSelectionMode.Inclusive) ||
      (!employeeIsActive && employeeSelection.selectionMode === EmployeeSelectionMode.Exclusive)
    );
  };

  const onCtxMenuAction = React.useCallback(
    (employeeId: string) => {
      if (isEmployeeSelected(employeeId)) {
        updateSelectedEmployees(employeeId, false);
      }
    },
    [updateSelectedEmployees]
  );

  const metrics = useSelector((state) => state.account.employeeMetrics);

  return (
    <EmployeesTableContainer>
      <FilterSection
        {...selectFilter}
        onFilterUpdate={setAppliedFilters}
        filters={appliedFilters}
        isSelectAll={employeeSelection.selectionMode === EmployeeSelectionMode.Exclusive}
        onSelectAll={updateSelectAll}
      />
      {employeeFetcher.tableState === EmployeeTableState.InitialFetch ? (
        <tbody>
          <tr>
            <td colSpan={visibleColumnCount}>
              <Spinner />
            </td>
          </tr>
        </tbody>
      ) : hasEmployees ? (
        <InfiniteScrollContainer<EmployeeData>
          debounceTimer={100}
          fetchPages={employeeFetcher.fetchEmployeesByPage}
          onCancellationRequested={employeeFetcher.cancelFetches}
          rowData={employeeFetcher.rows}
          renderThreshold={500}
          prefetchRowCount={20}
          pageSize={EMPLOYEE_TABLE_PAGE_SIZE}
          maxRowCount={employeeFetcher.employeeCount}
          renderRow={(key, state, ref) => {
            if (state.type === RowState.Complete) {
              return (
                <PopulatedEmployeeRow
                  innerRef={ref}
                  employeeId={state.data.employee.id}
                  index={state.rowNumber}
                  key={key}
                  data={state.data}
                  updateSelectedEmployees={updateSelectedEmployees}
                  isEmployeeSelected={isEmployeeSelected}
                  updateRow={updateRow}
                  onContextMenuAction={onCtxMenuAction}
                  selectFilter={selectFilter}
                />
              );
            } else if (state.type === RowState.Pending) {
              return <LoadingEmployeeRow key={key} index={state.rowNumber} />;
            } else {
              return <></>;
            }
          }}
        />
      ) : (
        <tbody>
          <tr>
            <td colSpan={visibleColumnCount}>
              <NoItemsView icon={personFilledIcon}>
                {metrics.employeeCount > 0
                  ? 'Update the filter criteria and try your search again.'
                  : 'Import employee records or add employees individually by clicking the “Add employee” button in the top right corner.'}
              </NoItemsView>
            </td>
          </tr>
        </tbody>
      )}
      <AddEditEmployeeModal
        employee={selectedEmployee}
        isOpen={isDrawerModalOpen && modalType === DrawerModalType.EditEmployee}
        modalState={EmployeeModalState.Edit}
        onClose={onDrawerModalClose}
        updateRow={updateRow}
      />
      <ConfirmJobTitleModal
        employee={selectedEmployee}
        isOpen={isDrawerModalOpen && modalType === DrawerModalType.ConfirmJobTitle}
        onClose={onDrawerModalClose}
        updateRow={updateRow}
      />
    </EmployeesTableContainer>
  );
};

interface PopulatedProps {
  data: EmployeeData;
  employeeId: string;
  index: number;
  updateSelectedEmployees(employeeId: string, selected: boolean): void;
  isEmployeeSelected(employeeId: string): boolean;
  updateRow(employee: EmployeeData): void;
  onContextMenuAction?(employeeId: string): void;
  innerRef: React.Ref<HTMLElement>;
  selectFilter: SelectFilter<keyof Tags>;
}

const PopulatedEmployeeRow = React.memo(
  ({
    employeeId,
    data,
    index,
    updateSelectedEmployees,
    isEmployeeSelected,
    updateRow,
    onContextMenuAction,
    innerRef,
    selectFilter,
  }: PopulatedProps) => {
    const onSelect = (employeeId: string) =>
      React.useCallback(
        (selected: boolean) => {
          updateSelectedEmployees(employeeId, selected);
        },
        [employeeId, updateSelectedEmployees]
      );

    return (
      <EmployeeRow
        employeeId={employeeId}
        innerRef={innerRef}
        checked={isEmployeeSelected(employeeId)}
        onSelect={onSelect(employeeId)}
        index={index}
        updateRow={updateRow}
        row={data}
        onContextMenuAction={onContextMenuAction}
        selectFilter={selectFilter}
      />
    );
  }
);

const LoadingEmployeeRow = React.memo(({index}: {index: number}) => {
  return (
    <DataTableRowContainer aria-rowindex={index}>
      <CheckboxCell />
      <NameCell>
        <TextContent>
          <SkeletonLine lineWidth={15} />
        </TextContent>
      </NameCell>
      <NameCell>
        <TextContent>
          <SkeletonLine lineWidth={15} />
        </TextContent>
      </NameCell>
      <EmailCell>
        <TextContent>
          <SkeletonLine lineWidth={15} />
        </TextContent>
      </EmailCell>
      <UserSummaryCell>
        <SkeletonLine lineWidth={18} />
      </UserSummaryCell>
      <DepartmentCell>
        <TextContent>
          <SkeletonLine lineWidth={12} />
        </TextContent>
      </DepartmentCell>
      <LocationCell>
        <TextContent>
          <SkeletonLine lineWidth={15} />
        </TextContent>
      </LocationCell>
      <DateCell>
        <TextContent>
          <SkeletonLine lineWidth={8} />
        </TextContent>
      </DateCell>
      <StatusCell>
        <TextContent>
          <SkeletonLine lineWidth={9} />
        </TextContent>
      </StatusCell>
      <ActionsCell></ActionsCell>
      <OverflowCell></OverflowCell>
    </DataTableRowContainer>
  );
});
