import {combineReducers} from 'redux';
import {accountReducer, AccountState} from './accountReducer';
import {authReducer, AuthState} from './authReducer';
import {employeeReducer, EmployeeState} from './employeeReducer';
import {userReducer, UserState} from './userReducer';
import {chapterReducer, ChapterState} from './chapterReducer';
import {actionPlannerReducer, ActionPlannerState} from './actionPlanner';
import {
  interpretationBucketReducer,
  InterpretationBucketsState,
} from './interpretationBucketReducer';
import {vendorsReducer, VendorsState} from './vendors';
import {CombinedState} from 'redux';

export type DvpCombinedState = CombinedState<{
  account: AccountState;
  actionPlanner: ActionPlannerState;
  auth: AuthState;
  employee: EmployeeState;
  user: UserState;
  chapter: ChapterState;
  interpretationBuckets: InterpretationBucketsState;
  vendors: VendorsState;
}>;

export const rootReducer = combineReducers({
  account: accountReducer,
  actionPlanner: actionPlannerReducer,
  auth: authReducer,
  employee: employeeReducer,
  user: userReducer,
  chapter: chapterReducer,
  interpretationBuckets: interpretationBucketReducer,
  vendors: vendorsReducer,
});
