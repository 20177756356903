import React from 'react';
import {assert, assertExists} from 'wnd-util/lib/assert';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {map} from 'lodash';

import {
  TeamReport,
  TeamReportState,
  TeamReportSection,
  TeamReportDomain,
  AbstractTeamReportSection,
  makeSpinalCase,
  Typology,
  SVGSize,
  InterestsSourceConstruct,
  ViewType,
} from 'wnd-dvp-reports';

import {Item} from '>shared/components/book/sidebar/contents';
import {LoadingScreen} from '>shared/components/loadingScreen';
import {mediaQueries} from '>shared/styles/breakpoints';
import {MobileSectionHeader} from '>shared/components/landmarks/header/mobileSectionHeader';
import {
  PageLayoutComponents,
  ResponsiveLayout,
} from '>shared/components/layouts/responsive/responsiveLayout';
import {
  PaginationButton,
  PaginationButtonContainer,
  PaginationButtonProps,
  PaginationButtonsList,
} from '>shared/components/pagination/paginationButton';
import {SectionContainer as BookSideBarSectionContainer} from '>shared/components/book/sidebar/sectionContainer';
import {sharedComponents} from '>lib/markdownSectionComponents';

import {Team as TeamComponent} from '>components/teams/team';
import {
  MotivationSection,
  PersonalityCompetencySection,
  Team,
  TeamReportSections,
} from '>generated/dvp.types';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';

import {TabTableOfContents} from '>components/tabTableOfContents';
import {TypologyDiamond} from '>components/teams/typologyDiamond';

import {
  ReportMainContent,
  ReportRightPane,
  ReportRightPaneFixed,
  TabContentMainContainer,
  TabContentNavContainer,
} from './styles';
import {Section} from '>shared/components/heading/heading';
import {sharedTeamReportComponents} from '>lib/teamReportComponents';
import {DriverIcon} from '>components/teams/driverIcon';
import {SpotlightWidgetVariant} from 'wnd-dvp-reports';

export interface TeamReportTabProps {
  team: Team;
  teamReport?: TeamReport;
  teamReportState: TeamReportState;
}

interface SectionNavItemProps {
  id: string;
  abbreviatedTitle: string;
  isActive?: boolean;
  onClick: () => void;
  domain: TeamReportDomain;
  typologyOrMotivator: Typology | InterestsSourceConstruct;
}

const TABLE_OF_CONTENTS = ['Team Approach', 'Section Attributes'];

const SECTION_ORDER: Array<keyof TeamReportSections> = [
  'communication',
  'work',
  'challenge',
  'drivers',
];

const SubSectionNavItem: React.FC<SectionNavItemProps> = ({
  id,
  abbreviatedTitle,
  isActive,
  onClick,
  domain,
  typologyOrMotivator,
}) => {
  let prefix;

  if (domain === TeamReportDomain.Drivers) {
    assert(
      typeof typologyOrMotivator === 'string',
      `Expected typologyOrMotivator to be a string, got ${typeof typologyOrMotivator}`
    );
    prefix = (
      <DriverIcon size={SVGSize.Small} isActive={isActive} highestMotivator={typologyOrMotivator} />
    );
  } else {
    prefix = (
      <TypologyDiamond
        active={isActive}
        domain={domain}
        size={SVGSize.Small}
        typology={typologyOrMotivator as Typology}
      />
    );
  }

  return (
    <Item onClick={() => onClick()}>
      <BookSideBarSectionContainer active={isActive} sectionId={id} prefix={prefix}>
        {abbreviatedTitle}
      </BookSideBarSectionContainer>
    </Item>
  );
};

function getNavContent(
  teamReportState: TeamReportState,
  onSectionChange: (section: TeamReportSection) => void,
  teamReport?: TeamReport,
  selectedSectionId?: string
): React.ReactNode {
  if (teamReportState === TeamReportState.READY) {
    assertExists(teamReport, 'The team report should exist in the READY state');

    return (
      <>
        {map(teamReport.sections, (section) => (
          <SubSectionNavItem
            key={section.domain}
            id={`${section.domain}Section`}
            abbreviatedTitle={section.abbreviatedTitle}
            onClick={() => {
              onSectionChange(section);
            }}
            isActive={section.domain === selectedSectionId}
            domain={section.domain}
            typologyOrMotivator={
              section.domain !== TeamReportDomain.Drivers
                ? (section as PersonalityCompetencySection).selectedTypology
                : (section as MotivationSection).highestMotivator
            }
          />
        ))}
      </>
    );
  }

  return <></>;
}

function getPaginationNav(selectedSectionId: keyof TeamReportSections, teamReport?: TeamReport) {
  assertExists(teamReport, 'The team report should exist');
  const paginationButtons: PaginationButtonProps[] = [];

  const previousSectionIndex = SECTION_ORDER.indexOf(selectedSectionId) - 1;
  const currentSectionIndex = SECTION_ORDER.indexOf(selectedSectionId);
  const nextSectionIndex = SECTION_ORDER.indexOf(selectedSectionId) + 1;

  const nextSectionNav =
    currentSectionIndex < SECTION_ORDER.length - 1
      ? teamReport.sections[SECTION_ORDER[nextSectionIndex]]
      : undefined;

  const previousSectionNav =
    currentSectionIndex > 0 ? teamReport.sections[SECTION_ORDER[previousSectionIndex]] : undefined;

  if (previousSectionNav) {
    paginationButtons.push({
      href: `team-insights#${previousSectionNav.domain}Section`,
      isDirectionForward: false,
      topText: 'Previous',
      bottomText: previousSectionNav.abbreviatedTitle,
    });
  }

  if (nextSectionNav) {
    paginationButtons.push({
      href: `team-insights#${nextSectionNav.domain}Section`,
      isDirectionForward: true,
      topText: 'Next',
      bottomText: nextSectionNav.abbreviatedTitle,
    });
  }

  return (
    <PaginationButtonsList css={{marginTop: '4rem'}}>
      {paginationButtons.map((button) => (
        <PaginationButtonContainer key={button.href}>
          <PaginationButton
            href={button.href}
            isDirectionForward={button.isDirectionForward}
            topText={button.topText}
            bottomText={button.bottomText}
          />
        </PaginationButtonContainer>
      ))}
    </PaginationButtonsList>
  );
}

export const TeamReportTab: React.FC<TeamReportTabProps> = ({
  team,
  teamReport,
  teamReportState,
}) => {
  const {activeSectionTitle, setHiddenComponent, setActiveSectionTitle} = useResponsiveLayout();
  const hideBody = React.useCallback(() => {
    setHiddenComponent(PageLayoutComponents.Body);
  }, []);
  const {hash} = useLocation();
  const bucketMapping = useSelector((state) => state.interpretationBuckets.bucketMapping);
  const [selectedSection, setSelectedSection] = React.useState<TeamReportSection | undefined>();

  const isMobile = window.matchMedia(mediaQueries.smallOrLower.media).matches;

  React.useEffect(() => {
    if (teamReportState === TeamReportState.READY) {
      assertExists(teamReport, 'The team report should exist in the READY state');

      // The user can be linked into sections based on the hash
      if (hash.length !== 0) {
        const sectionId = hash.replace('#', '');
        const sectionName = sectionId.replace('Section', '');
        const linkedSection =
          teamReport.sections[sectionName.toLowerCase() as keyof TeamReportSections];

        // Anything could be entered into the URL, so ignore if its not a real section.
        if (linkedSection) {
          setSelectedSection(linkedSection);
          window.scrollTo(0, 0);
        }
      } else {
        // Default to the first section once the report loads and we know what the sections are.
        setSelectedSection(teamReport.sections.communication);
      }
    }
  }, [hash, teamReport]);

  const onSectionChange = React.useCallback(
    (section: TeamReportSection) => {
      setSelectedSection(section);
      setActiveSectionTitle(section.title);

      if (isMobile) {
        setHiddenComponent(PageLayoutComponents.Nav);
      }

      window.scrollTo(0, 0);
    },
    [isMobile]
  );

  if (teamReportState === TeamReportState.FETCHING) {
    return <LoadingScreen />;
  }

  return (
    <ResponsiveLayout
      nav={
        <TabContentNavContainer>
          {getNavContent(teamReportState, onSectionChange, teamReport, selectedSection?.domain)}
        </TabContentNavContainer>
      }
      main={
        <TabContentMainContainer>
          <MobileSectionHeader
            primaryHeader={true}
            onBackButtonClick={hideBody}
            sectionHeading={activeSectionTitle ?? ''}
          />

          <ReportMainContent>
            {selectedSection && (
              <Section>
                <AbstractTeamReportSection
                  teamReportSection={selectedSection}
                  teamReportState={teamReportState}
                  sharedComponents={sharedComponents}
                  teamReportComponents={sharedTeamReportComponents}
                  bucketMapping={bucketMapping}
                  spotlightVariant={SpotlightWidgetVariant.Default}
                  viewType={ViewType.Web}
                />
                {getPaginationNav(
                  selectedSection.domain.toLowerCase() as keyof TeamReportSections,
                  teamReport
                )}
              </Section>
            )}
          </ReportMainContent>
          <ReportRightPane>
            <ReportRightPaneFixed>
              <TabTableOfContents
                links={TABLE_OF_CONTENTS.map((item) => ({
                  text: item,
                  targetId: makeSpinalCase(item),
                }))}
              />
              <TeamComponent name={team.name} members={team.members} />
            </ReportRightPaneFixed>
          </ReportRightPane>
        </TabContentMainContainer>
      }
      observerAttribute="data-bodycontent"
    />
  );
};
