import {UseFormMethods, useFormContext} from 'react-hook-form';
import {TemplatedActionItemFields} from './interfaces';
import {FormBuilder, FormBuilderFieldType} from '>shared/components/form/formBuilder';
import {TextArea, TextAreaResizeEnum} from '>shared/components/form/textArea';

export const TemplatedActionItemForm: React.FC = () => {
  const {register} = useFormContext<TemplatedActionItemFields>();

  return (
    <FormBuilder
      showErrorSummary={false}
      fields={[
        {
          type: FormBuilderFieldType.Input,
          name: 'title',
          label: 'Title of action item',
          component: (
            <TextArea
              skipScroll={true}
              resize={TextAreaResizeEnum.Vertical}
              small
              ref={register({
                required: 'Title is required',
                pattern: {
                  message: 'Title should contain at least one character',
                  value: new RegExp(/[^\s]+/),
                },
              })}
              maxLength={500}
            />
          ),
        },
        {
          type: FormBuilderFieldType.Input,
          name: 'description',
          label: 'Describe your action item.',
          component: (
            <TextArea
              skipScroll={true}
              resize={TextAreaResizeEnum.Vertical}
              ref={register({
                required: false,
              })}
              maxLength={5000}
            />
          ),
        },
      ]}
    />
  );
};
