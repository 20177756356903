"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LinkVariant = exports.LinkSizes = exports.LinkButton = void 0;
exports.getLinkTheme = getLinkTheme;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var _linkThemes = require("./linkThemes");
var _link = require("./link.styles");
var LinkVariant;
(function (LinkVariant) {
  LinkVariant["Primary"] = "primary";
  LinkVariant["Neutral"] = "neutral";
  LinkVariant["Destructive"] = "destructive";
})(LinkVariant || (exports.LinkVariant = LinkVariant = {}));
var LinkSizes;
(function (LinkSizes) {
  LinkSizes["MD"] = "medium";
  LinkSizes["LG"] = "large";
})(LinkSizes || (exports.LinkSizes = LinkSizes = {}));
function getLinkTheme(variant) {
  switch (variant) {
    case LinkVariant.Primary:
      return _linkThemes.primaryTheme;
    case LinkVariant.Neutral:
      return _linkThemes.neutralTheme;
    case LinkVariant.Destructive:
      return _linkThemes.destructiveTheme;
  }
}
const LinkButton = ({
  onClick,
  children,
  size = LinkSizes.MD,
  variant = LinkVariant.Primary,
  'data-qa-attribute': qaDataAttribute
}) => {
  const theme = getLinkTheme(variant);
  return (0, _jsxRuntime.jsx)(_link.StyledLinkButton, {
    onClick: onClick,
    type: "button",
    linkTheme: theme,
    "data-qa-link": qaDataAttribute,
    size: size,
    children: children
  });
};
exports.LinkButton = LinkButton;