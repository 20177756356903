import styled from '@emotion/styled';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import * as colors from 'wnd-themes/lib/colorPalette';
import {drawMeasureSlider, MeasureSliderOptions} from 'wnd-dvp-reports';

const SvgContainer = styled.div`
  padding-top: 2.4rem;
  width: 100%;
  display: inline-flex;
`;

const ToolTip = styled(ReactTooltip)`
  font-size: 1.2rem !important; // overriding font size in ReactToolTip
  border-radius: 0.8rem !important; // overriding border-radius in ReactToolTip
  padding: 0.8rem !important; // overriding padding in ReactToolTip
  line-height: 2rem;
  max-width: 21.5rem;
  ${(props) => props.theme.defaultFontFamily && `font-family: ${props.theme.defaultFontFamily}`};

  &.show {
    opacity: 1 !important; // overriding opacity in ReactToolTip
  }
`;

const getOrdinalSuffix = (score: number) => {
  const scoreModTen = score % 10;
  const scoreModHundred = score % 100;

  if (scoreModTen == 1 && scoreModHundred != 11) {
    return `${score}st`;
  }
  if (scoreModTen == 2 && scoreModHundred != 12) {
    return `${score}nd`;
  }
  if (scoreModTen == 3 && scoreModHundred != 13) {
    return `${score}rd`;
  }
  return `${score}th`;
};

const getTooltipText = (measureName: string, score: number) => {
  return `You scored in the ${getOrdinalSuffix(score)} percentile of ${measureName}`;
};

export const MeasureSlider: React.FC<{
  className?: string;
  measureName: string;
} & MeasureSliderOptions> = ({className, measureName, ...sliderOptions}) => {
  const tooltipText = getTooltipText(measureName, sliderOptions.score);
  const tooltipDataId = `${measureName
    .replaceAll('\n', '')
    .replaceAll('\r', '')}_measureSliderTooltip`;
  sliderOptions.tooltipDataId = tooltipDataId;

  const svgContainerRef = React.useRef<HTMLDivElement>(null);
  const [size, setSize] = React.useState<number>(sliderOptions.width);

  const updateWindowSize = React.useCallback(() => {
    if (svgContainerRef.current) {
      setSize(svgContainerRef.current.clientWidth);
    }
  }, [svgContainerRef]);

  React.useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
  }, [updateWindowSize]);

  React.useEffect(() => updateWindowSize(), [svgContainerRef]);

  const svgText = React.useMemo(() => {
    return drawMeasureSlider({...sliderOptions, width: size});
  }, [
    sliderOptions.score,
    sliderOptions.color,
    size,
    sliderOptions.leftAnchorText,
    sliderOptions.rightAnchorText,
  ]);

  return (
    <>
      <SvgContainer
        className={className}
        dangerouslySetInnerHTML={{__html: svgText}}
        ref={svgContainerRef}
      />
      <ToolTip
        effect="solid"
        id={tooltipDataId}
        type="info"
        offset={{top: 1}}
        place={sliderOptions.score >= 50 ? 'bottom' : 'right'}
        backgroundColor={colors.white}
        textColor={colors.char600}
        borderColor={colors.char100}
        border={true}
        delayShow={500}
      >
        {tooltipText}
      </ToolTip>
    </>
  );
};
