import React from 'react';
import * as colors from 'wnd-themes/lib/colorPalette';
import {
  PageLayoutComponents,
  ResponsiveLayout,
} from '>shared/components/layouts/responsive/responsiveLayout';
import {ReportMainContent, TabContentMainContainer, TabContentNavContainer} from './styles';
import {Team as TeamComponent} from '>components/teams/team';
import {Team} from '>generated/dvp.types';
import {useResponsiveLayout} from '>shared/components/layouts/responsive/useResponsiveLayout';
import {mediaQueries} from '>shared/styles/breakpoints';
import {
  AbstractTeamOverview,
  TeamReport,
  TeamReportState,
  TeamOverviewProps,
  getColorForTeamReportSection,
  TeamReportDomain,
} from 'wnd-dvp-reports';
import {sharedComponents} from '>lib/markdownSectionComponents';
import {TypologyCard} from '>components/teams/typologyCard';
import styled from '@emotion/styled';
import {displayXsRegular} from '>shared/components/typography/designSystemTypography';
import {vr10, vr6} from '>shared/styles/mixins/verticalRhythm';
import {ButtonGroup, ChildButton} from '>shared/components/buttonGroup/buttonGroup';
import {ButtonType} from '>shared/components/button/button';
import {downloadPdf} from '>lib/pdf';
import {API_URL} from '>root/env';
import * as Icons from '>shared/components/icon/icons';
import {Paragraph} from '>shared/components/typography/baseTypography2';

interface OverviewTabProps {
  team: Team;
  teamReportState: TeamReportState;
  teamReport?: TeamReport;
  goToReport?: () => void;
}

const OverviewHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  ${vr10}
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const Bar = styled.div<{color: string}>`
  background-color: ${(props) => props.color};
  width: 4.8rem;
  height: 0.8rem;
`;

const Line = styled.div`
  background-color: ${colors.gray200};
  height: 0.1rem;
  flex-grow: 1;
`;

const OverviewHeader: React.FC<TeamOverviewProps> = ({teamId, teamName}) => {
  const buttonGroupOptions: ChildButton[] = [
    {
      icon: Icons.downloadIcon,
      text: 'Download PDF',
      hideText: true,
      onClick: async () => {
        const fileName = `${teamName} Team Report`;
        const pdfEndpoint = `${API_URL}/teams/${teamId}/report/pdf`;
        downloadPdf(fileName, pdfEndpoint);
      },
    },
  ];

  return (
    <OverviewHeaderContainer>
      <BarContainer>
        <Bar color={getColorForTeamReportSection(TeamReportDomain.Communication)} />
        <Bar color={getColorForTeamReportSection(TeamReportDomain.Work)} />
        <Bar color={getColorForTeamReportSection(TeamReportDomain.Challenge)} />
        <Bar color={getColorForTeamReportSection(TeamReportDomain.Drivers)} />
      </BarContainer>
      <Line />
      <ButtonGroup options={buttonGroupOptions} buttonType={ButtonType.Neutral} />
    </OverviewHeaderContainer>
  );
};

const OverviewHeaderDescription = styled.div`
  ${displayXsRegular}
  ${vr6}
  color: ${colors.gray600};
`;

const OverviewHeaderInterpretation = styled.div`
  ${vr10}
`;

export const OverviewTab: React.FC<OverviewTabProps> = ({
  team,
  teamReport,
  teamReportState,
  goToReport,
}) => {
  const {setHiddenComponent} = useResponsiveLayout();
  const isMobile = window.matchMedia(mediaQueries.smallOrLower.media).matches;

  sharedComponents.Paragraph = styled(Paragraph)`
    color: ${colors.gray600};
  `;

  React.useEffect(() => {
    if (isMobile) {
      setHiddenComponent(PageLayoutComponents.Nav);
    }
  }, [isMobile]);

  return (
    <ResponsiveLayout
      nav={
        <>
          {!isMobile && (
            <TabContentNavContainer>
              <TeamComponent
                name={team.name}
                members={team.members}
                showTeamName
                showManagers
                useAltAvatarStyle
              />
            </TabContentNavContainer>
          )}
        </>
      }
      main={
        <TabContentMainContainer>
          <ReportMainContent>
            <AbstractTeamOverview
              teamReport={teamReport}
              teamReportState={teamReportState}
              sharedComponents={sharedComponents}
              teamSharedComponents={{TypologyCard}}
              teamOverviewComponents={{OverviewHeader, OverviewHeaderDescription, OverviewHeaderInterpretation}}
              goToReport={goToReport}
            />
          </ReportMainContent>
        </TabContentMainContainer>
      }
      observerAttribute="data-bodycontent"
    />
  );
};
