"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tertiaryTheme = exports.superDestructiveTheme = exports.secondaryTheme = exports.primaryTheme = exports.neutralTheme = exports.ghostTheme = exports.destructiveTheme = void 0;
var colors = _interopRequireWildcard(require("wnd-themes/lib/colorPalette"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const primaryTheme = exports.primaryTheme = {
  text: {
    default: colors.white
  },
  background: {
    active: colors.primary800,
    default: colors.primary600,
    disabled: colors.primary200,
    focus: colors.primary600,
    hover: colors.primary700
  },
  border: {
    active: colors.primary800,
    default: colors.primary600,
    disabled: colors.primary200,
    focus: colors.primary400,
    hover: colors.primary700
  },
  shadow: {
    focus: colors.primary100
  }
};
const secondaryTheme = exports.secondaryTheme = {
  text: {
    default: colors.primary700,
    disabled: colors.white,
    pressed: colors.primary800
  },
  background: {
    active: colors.primary200,
    default: colors.primary50,
    disabled: colors.primary200,
    focus: colors.primary50,
    hover: colors.primary200
  },
  border: {
    active: colors.primary200,
    default: colors.primary200,
    disabled: colors.primary200,
    focus: colors.primary400,
    hover: colors.primary200
  },
  shadow: {
    focus: colors.primary100
  }
};
const tertiaryTheme = exports.tertiaryTheme = {
  text: {
    default: colors.gray700,
    disabled: colors.gray400
  },
  background: {
    active: colors.white,
    default: colors.gray100,
    disabled: colors.gray200,
    focus: colors.gray100,
    hover: colors.gray50
  },
  border: {
    active: colors.gray200,
    default: colors.gray200,
    disabled: colors.gray200,
    focus: colors.primary400,
    hover: colors.gray300
  },
  shadow: {
    focus: colors.gray100
  }
};
const ghostTheme = exports.ghostTheme = {
  text: {
    default: colors.gray700,
    disabled: colors.gray400
  },
  background: {
    active: colors.gray100,
    default: colors.transparent,
    disabled: colors.transparent,
    focus: colors.transparent,
    hover: colors.gray50
  },
  border: {
    active: colors.gray200,
    default: colors.transparent,
    disabled: colors.transparent,
    focus: colors.primary400,
    hover: colors.gray200
  },
  shadow: {
    hideDefault: true,
    focus: colors.gray100
  }
};
const neutralTheme = exports.neutralTheme = {
  text: {
    default: colors.gray700,
    disabled: colors.gray400
  },
  background: {
    active: colors.gray100,
    default: colors.white,
    disabled: colors.gray200,
    focus: colors.white,
    hover: colors.gray50
  },
  border: {
    active: colors.gray300,
    default: colors.gray300,
    disabled: colors.gray300,
    focus: colors.primary400,
    hover: colors.gray300
  },
  shadow: {
    focus: colors.primary100
  }
};
const destructiveTheme = exports.destructiveTheme = {
  text: {
    default: colors.error700,
    disabled: colors.white,
    pressed: colors.white
  },
  background: {
    active: colors.error700,
    default: colors.white,
    disabled: colors.error200,
    focus: colors.white,
    hover: colors.error600
  },
  border: {
    active: colors.error700,
    default: colors.error200,
    disabled: colors.error200,
    focus: colors.error400,
    hover: colors.error600
  },
  shadow: {
    focus: colors.error100
  }
};
const superDestructiveTheme = exports.superDestructiveTheme = {
  text: {
    default: colors.white,
    disabled: colors.white
  },
  background: {
    active: colors.error700,
    default: colors.error600,
    disabled: colors.error200,
    focus: colors.error700,
    hover: colors.error700
  },
  border: {
    active: colors.error700,
    default: colors.error600,
    disabled: colors.error200,
    focus: colors.error400,
    hover: colors.error700
  },
  shadow: {
    focus: colors.error100
  }
};