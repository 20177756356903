"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DrawerForm = DrawerForm;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var _assert = require("wnd-util/lib/assert");
var _drawer = require("./drawer");
var _drawerForm = require("./drawerForm.styles");
var _verticalRhythm = require("../../styles/mixins/verticalRhythm");
var _designSystemTypography = require("../typography/designSystemTypography");
var _heading = require("../heading/heading");
var _icon = require("../icon/icon");
var Icons = _interopRequireWildcard(require("../icon/icons"));
var _reactHookForm = require("react-hook-form");
var _button = require("../button/button");
var _delayRender = require("../util/delayRender");
var _spinner = require("../spinner");
var _react = _interopRequireDefault(require("react"));
var _formBuilder = require("../form/formBuilder");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const SPINNER_SIZE = 24;
function DrawerForm({
  isOpen,
  formTitle,
  formDescription,
  stages,
  onOpen,
  onDismiss,
  onSubmitError,
  manualError
}) {
  (0, _assert.assert)(stages.length > 0, 'Must have at least 1 form stage');
  const [isSaving, setIsSaving] = _react.default.useState(false);
  // Which stage we're on
  const [stageIndex, setStageIndex] = _react.default.useState(0);
  // Save data for each of the stages.
  // Default to empty FieldValues for each stage.
  const [stagesFormValues, setStagesFormValues] = _react.default.useState(new Array(stages.length).fill({}));
  (0, _assert.assert)(stageIndex < stages.length, `${stageIndex} is not a valid stage`);
  const stage = stages[stageIndex];
  const isMultiStage = stages.length > 1;
  const isFirstStage = stageIndex === 0;
  const isFinalStage = stageIndex === stages.length - 1;
  const toPreviousStage = _react.default.useCallback(() => {
    (0, _formBuilder.clearGlobalFormError)(stage.methods);
    if (!isMultiStage || isFirstStage) {
      return;
    }
    // Go back one stage
    const previousStageIndex = stageIndex - 1;
    setStageIndex(previousStageIndex);
    // Load the data from that stage
    const previousStageData = stagesFormValues[previousStageIndex];
    (0, _assert.assertExists)(previousStageData, `stage data for stage ${previousStageIndex} was never initialized`);
    // TODO WD-2514: In react-hook-form@6, calling reset() changes the default values which breaks cancelling.
    // It will work after upgrading to react-hook-form@7+ and uncommenting the reset call below.
    // https://legacy.react-hook-form.com/migrate-v6-to-v7/
    // stages[previousStageIndex].methods.reset(previousStageData, {keepDefaultValues: true});
  }, [isMultiStage, isFirstStage, stage, stagesFormValues]);
  const toNextStage = _react.default.useCallback(() => {
    (0, _formBuilder.clearGlobalFormError)(stage.methods);
    const allStagesFormValues = [...stagesFormValues];
    const currentStageData = stage.methods.getValues();
    // Save the current stage's data in memory.
    // This will also be provided to the submit handler.
    allStagesFormValues[stageIndex] = currentStageData;
    setStagesFormValues(allStagesFormValues);
    if (isMultiStage && !isFinalStage) {
      // Advance
      const nextStageIndex = stageIndex + 1;
      setStageIndex(nextStageIndex);
    }
    return allStagesFormValues;
  }, [stage, stagesFormValues]);
  const {
    cancel: stageCancelAction,
    back: stageBackAction,
    next: stageNextAction
  } = stage.actions;
  const resetAllStages = _react.default.useCallback(() => {
    stages.forEach(stage => {
      stage.methods.reset();
    });
    setStageIndex(0);
    setStagesFormValues(new Array(stages.length).fill({}));
  }, [stages]);
  const handleDismiss = _react.default.useCallback(event => {
    event?.preventDefault();
    onDismiss && onDismiss();
    resetAllStages();
  }, [stageCancelAction]);
  const handleCancel = _react.default.useCallback(event => {
    event?.preventDefault();
    stageCancelAction?.onClick && stageCancelAction.onClick(event);
    resetAllStages();
  }, [stageCancelAction]);
  const handleBack = _react.default.useCallback(event => {
    event?.preventDefault();
    toPreviousStage();
    stageBackAction?.onClick && stageBackAction.onClick(event);
  }, [stageBackAction]);
  const setFormError = _react.default.useCallback(message => {
    (0, _formBuilder.setGlobalFormError)(stage.methods, message);
  }, []);
  _react.default.useEffect(() => {
    if (manualError) {
      setFormError(manualError);
    }
  }, [manualError]);
  // This submit fires after all stages. Each stage is its own form.
  const handleSubmit = _react.default.useCallback(async () => {
    setIsSaving(true);
    const allStagesFormValues = toNextStage();
    if (stageNextAction.onClick) {
      try {
        await stageNextAction.onClick(allStagesFormValues);
        if (isFinalStage) {
          resetAllStages();
        }
      } catch (err) {
        const error = err;
        if (onSubmitError) {
          onSubmitError(error, setFormError);
        } else {
          setFormError('A server error occurred while processing the request');
        }
      }
    }
    setIsSaving(false);
  }, [toNextStage, stageNextAction, stage, onSubmitError]);
  function toggleIntercomIcon() {
    const intercomContainerOnLoad = document.getElementsByClassName('intercom-lightweight-app')[0];
    if (intercomContainerOnLoad) {
      intercomContainerOnLoad.style.display = isOpen ? 'none' : 'block';
    }
    const intercomConatinerAfterUsage = document.getElementById('intercom-container');
    if (intercomConatinerAfterUsage) {
      intercomConatinerAfterUsage.style.display = isOpen ? 'none' : 'block';
    }
  }
  // onOpen:
  // Since this depends on isOpen, this should only fire
  // if it changes, and it's now open (false -> true).
  _react.default.useEffect(() => {
    if (isOpen) {
      resetAllStages();
      onOpen && onOpen();
    }
    toggleIntercomIcon();
  }, [isOpen]);
  return (0, _jsxRuntime.jsx)(_drawer.Drawer, {
    isOpen: isOpen,
    children: (0, _jsxRuntime.jsxs)(_drawerForm.DrawerFormContainer, {
      children: [(0, _jsxRuntime.jsxs)(_drawerForm.DrawerFormHeader, {
        css: [_verticalRhythm.vr2],
        children: [(0, _jsxRuntime.jsx)(_heading.Heading, {
          css: [_designSystemTypography.displayXsMedium],
          children: formTitle
        }), (0, _jsxRuntime.jsx)(_drawerForm.XButton, {
          buttonLabel: "Dismiss Drawer",
          "data-qa-button": "dismissDrawer",
          onClick: handleDismiss,
          children: (0, _jsxRuntime.jsx)(_icon.Icon, {
            src: Icons.roundedCloseIcon
          })
        })]
      }), (0, _jsxRuntime.jsx)(_drawerForm.Subtitle, {
        addBottomMargin: true,
        children: formDescription
      }), (0, _jsxRuntime.jsx)(_reactHookForm.FormProvider, _objectSpread(_objectSpread({}, stage.methods), {}, {
        children: (0, _jsxRuntime.jsx)("div", {
          css: [_verticalRhythm.vr2],
          children: stage.form
        })
      })), stage.hint && (0, _jsxRuntime.jsx)(_drawerForm.FormHint, {
        children: stage.hint
      }), (0, _jsxRuntime.jsxs)(_drawerForm.DrawerFormActions, {
        children: [stageCancelAction && (0, _jsxRuntime.jsx)(_drawerForm.DrawerFormSecondaryAction, {
          children: (0, _jsxRuntime.jsx)(_button.Button, {
            "data-qa-button": "cancel",
            buttonType: _button.ButtonType.Neutral,
            disabled: stageCancelAction.disabled || isSaving,
            type: "button",
            onClick: handleCancel,
            children: stageCancelAction.label ?? 'Cancel'
          })
        }), stageBackAction && (0, _jsxRuntime.jsx)(_drawerForm.DrawerFormSecondaryAction, {
          children: (0, _jsxRuntime.jsx)(_button.Button, {
            "data-qa-button": "back",
            buttonType: _button.ButtonType.Neutral,
            disabled: stageBackAction.disabled || isSaving,
            type: "button",
            onClick: handleBack,
            children: stageBackAction.label ?? 'Back'
          })
        }), (0, _jsxRuntime.jsxs)(_button.Button, {
          "data-qa-button": "next",
          disabled: stageNextAction.disabled || isSaving,
          type: "submit",
          onClick: event => {
            event?.preventDefault();
            (0, _formBuilder.clearGlobalFormError)(stage.methods);
            stage.methods.handleSubmit(handleSubmit)(event);
          },
          children: [isSaving && (0, _jsxRuntime.jsx)(_delayRender.DelayRender, {
            delayInSeconds: 2,
            children: (0, _jsxRuntime.jsx)(_spinner.Spinner, {
              size: SPINNER_SIZE
            })
          }), stageNextAction.label ?? isFinalStage ? 'Save' : 'Next']
        })]
      })]
    })
  });
}