import * as React from 'react';
import {vr6} from '>styles/mixins/verticalRhythm';
import {
  TeamReportItemFormFields,
  OverviewTeamReportItemFormFields,
  ApproachTeamReportItemFormFields,
  AttributeTeamReportItemFormFields,
  AllTeamReportItemFormMethods,
} from './formFields';
import {FormProvider} from 'react-hook-form';
import {Heading} from '>shared/components/heading/heading';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {TeamReportItem} from '>generated/dvp.types';

export const deafultOverviewTeamReportItemFormFields: OverviewTeamReportItemFormFields = {
  cardTitle: '',
  introDynamicInterpretation: '',
  cardParagraph: '',
  strengths: '',
  risks: '',
};

export const deafultApproachTeamReportItemFormFields: ApproachTeamReportItemFormFields = {
  introductionText: '',
  typology1: {
    displayName: '',
    typeInterpretation: '',
    balancerParagraph: '',
  },
  typology2: {
    displayName: '',
    typeInterpretation: '',
    balancerParagraph: '',
  },
  typology3: {
    displayName: '',
    typeInterpretation: '',
    balancerParagraph: '',
  },
  typology4: {
    displayName: '',
    typeInterpretation: '',
    balancerParagraph: '',
  },
  attributeSectionIntroText: '',
};

export const deafultAttributeTeamReportItemFormFields: AttributeTeamReportItemFormFields = {
  content: '',
};

interface Props {
  title: string;
  teamReportItem: TeamReportItem;
  formMethods: AllTeamReportItemFormMethods;
}

export const TeamReportItemForm: React.FC<Props> = ({title, teamReportItem, formMethods}) => {
  return (
    <>
      <Heading css={[vr6, displayXsMedium]}>{title}</Heading>
      <FormProvider {...formMethods}>
        <TeamReportItemFormFields teamReportItem={teamReportItem} />
      </FormProvider>
    </>
  );
};
