import {
  CompetenciesSourceConstruct,
  InterestsSourceConstruct,
  PersonalitySourceConstruct,
  TeamReportTemplateContext,
  TemplateContextAttributes,
  TypologyQuadrant,
} from 'wnd-dvp-reports';

export const mockEmployeeReportTemplateContext = {
  firstName: 'Sam',
  lastName: 'Pel',
  job: {
    name: 'Manager of Customer Success',
    nameWithIndefiniteArticle: 'a Sales representative',
    processedName: 'manager_customer_success',
    jobLevel: 'MGR',
    isManager: true,
    competency: {
      'Adaptability': {
        name: 'Flexibility',
        score: 57,
        interpGroup: 1,
        flag: [],
        rankOrder: 6,
      },
      'Change Tolerance': {
        name: 'Change Adoption',
        score: 66,
        interpGroup: 2,
        flag: [],
        rankOrder: 10,
      },
      'Communication': {
        name: 'Communication',
        score: 78,
        interpGroup: 2,
        flag: ['critical'],
        rankOrder: 2,
      },
      'Teamwork': {
        name: 'Teamwork',
        score: 65,
        interpGroup: 1,
        flag: ['critical'],
        rankOrder: 1,
      },
      'Interpersonal Sensitivity': {
        name: 'Emotional Awareness',
        score: 81,
        interpGroup: 2,
        flag: [],
        rankOrder: 5,
      },
      'Decision Making': {
        name: 'Decision Making',
        score: 59,
        interpGroup: 1,
        flag: [],
        rankOrder: 4,
      },
      'Organization and Planning': {
        name: 'Organization and Planning',
        score: 77,
        interpGroup: 2,
        flag: ['critical'],
        rankOrder: 0,
      },
      'Composure': {
        name: 'Composure',
        score: 59,
        interpGroup: 1,
        flag: [],
        rankOrder: 8,
      },
      'Results Motivation': {
        name: 'Results Focus',
        score: 77,
        interpGroup: 2,
        flag: [],
        rankOrder: 9,
      },
      'Time Management': {
        name: 'Time Management',
        score: 69,
        interpGroup: 2,
        flag: [],
        rankOrder: 7,
      },
      'Growth Mindset': {
        name: 'Growth Mindset',
        score: 74,
        interpGroup: 2,
        flag: [],
        rankOrder: 3,
      },
      'Writing Ability': {
        name: 'Writing Ability',
        score: 50,
        interpGroup: 1,
        flag: [],
        rankOrder: 11,
      },
    },
    cognitive: {
      'Fluid Reasoning': {
        name: 'Fluid Reasoning',
        score: 54,
        interpGroup: 1,
        flag: [],
      },
      'Induction': {
        name: 'Pattern Recognition',
        score: 54,
        interpGroup: 1,
        flag: [],
      },
      'Quantitative Reasoning': {
        name: 'Numerical Reasoning',
        score: 54,
        interpGroup: 1,
        flag: [],
      },
      'Visual-Spatial Processing': {
        name: 'Spatial Reasoning',
        score: 54,
        interpGroup: 1,
        flag: [],
      },
    },
    personality: {
      'Industriousness': {
        name: 'Diligence',
        score: 88,
        interpGroup: 3,
        flag: [],
      },
      'Orderliness': {
        name: 'Orderliness',
        score: 88,
        interpGroup: 3,
        flag: [],
      },
      'Compassion': {
        name: 'Compassion',
        score: 100,
        interpGroup: 3,
        flag: [],
      },
      'Politeness': {
        name: 'Courtesy',
        score: 100,
        interpGroup: 3,
        flag: [],
      },
      'Intellect': {
        name: 'Curiosity',
        score: 75,
        interpGroup: 3,
        flag: [],
      },
      'Aesthetic Openness': {
        name: 'Receptiveness',
        score: 75,
        interpGroup: 3,
        flag: [],
      },
      'Enthusiasm': {
        name: 'Enthusiasm',
        score: 72,
        interpGroup: 2,
        flag: [],
      },
      'Assertiveness': {
        name: 'Assertiveness',
        score: 72,
        interpGroup: 2,
        flag: [],
      },
      'Ambition': {
        name: 'Ambition',
        score: 89,
        interpGroup: 3,
        flag: [],
      },
    },
    interest: {
      Realistic: {
        name: 'Hands-On Work',
        score: 55,
        interpGroup: 1,
        flag: [],
        rankOrder: 3,
      },
      Investigative: {
        name: 'Analytical Work',
        score: 82,
        interpGroup: 2,
        flag: [],
        rankOrder: 5,
      },
      Artistic: {
        name: 'Creative Work',
        score: 35,
        interpGroup: 0,
        flag: [],
        rankOrder: 4,
      },
      Social: {
        name: 'Service Work',
        score: 81,
        interpGroup: 2,
        flag: ['critical'],
        rankOrder: 2,
      },
      Enterprising: {
        name: 'Enterprising Work',
        score: 80,
        interpGroup: 2,
        flag: ['critical'],
        rankOrder: 0,
      },
      Conventional: {
        name: 'Clerical Work',
        score: 26,
        interpGroup: 0,
        flag: ['critical'],
        rankOrder: 1,
      },
    },
    workContexts: {
      'Coordinate or Lead Others': 4.04673,
      'Deal With External Customers': 3.98284,
      'Deal With Unpleasant or Angry People': 2.79454,
      'Frequency of Conflict Situations': 3.28755,
      'Frequency of Decision Making': 3.82533,
      'Responsibility for Outcomes and Results': 3.78622,
      'Structured versus Unstructured Work': 4.36369,
      'Time Pressure': 3.95256,
      'Work With Work Group or Team': 4.61395,
    },
    iwas: {
      'Adjust equipment to ensure adequate performance.': 0,
      'Adjust medical equipment to ensure adequate performance.': 0,
      'Administer basic health care or medical treatments.': 0,
      'Administer diagnostic tests to assess patient health.': 0,
      'Administer emergency medical treatment.': 0,
      'Administer therapeutic treatments.': 0,
      'Advise others on business or operational matters.': 1,
      'Advise others on educational or vocational matters.': 0,
      'Advise others on environmental sustainability or green practices.': 0,
      'Advise others on financial matters.': 0,
      'Advise others on healthcare or wellness issues.': 0,
      'Advise others on legal or regulatory matters.': 0,
      'Advise others on products or services.': 0,
      'Advise others on the design or use of technologies.': 0,
      'Advise others on workplace health or safety issues.': 0,
      'Advise patients or clients on medical issues.': 0,
      'Advocate for individual or community needs.': 0,
      'Alter audio or video recordings.': 0,
      'Analyze biological or chemical substances or related data.': 0,
      'Analyze business or financial data.': 0,
      'Analyze business or financial risks.': 0,
      'Analyze data to improve operations.': 1,
      'Analyze environmental or geospatial data.': 0,
      'Analyze health or medical data.': 0,
      'Analyze market or industry conditions.': 0,
      'Analyze performance of systems or equipment.': 0,
      'Analyze scientific or applied data using mathematical principles.': 0,
      'Apply decorative finishes.': 0,
      'Apply hygienic or cosmetic agents to skin or hair.': 0,
      'Apply materials to fill gaps or imperfections.': 0,
      'Apply protective solutions or coatings.': 0,
      'Arrange displays or decorations.': 0,
      'Assemble equipment or components.': 0,
      'Assemble products or work aids.': 0,
      'Assess characteristics of land or property.': 0,
      'Assess characteristics or impacts of regulations or policies.': 0,
      'Assess compliance with environmental standards or regulations.': 0,
      'Assess living, work, or social needs or status of individuals or communities.': 0,
      'Assess student capabilities, needs, or performance.': 0,
      'Assign work to others.': 0,
      'Assist healthcare practitioners during medical procedures.': 0,
      'Assist individuals with paperwork.': 0,
      'Assist individuals with special needs.': 0,
      'Assist others to access additional services or resources.': 0,
      'Assist scientists, scholars, or technical specialists with projects or research.': 0,
      'Authorize business activities or transactions.': 0,
      'Build structures.': 0,
      'Calculate financial data.': 0,
      'Care for plants or animals.': 0,
      'Clean medical equipment or facilities.': 0,
      'Clean tools, equipment, facilities, or work areas.': 0,
      'Clean workpieces, finished products, or other objects.': 0,
      'Climb equipment or structures.': 0,
      'Coach others.': 0,
      'Collaborate in the development of educational programs.': 0,
      'Collect data about consumer needs or opinions.': 0,
      'Collect environmental or biological samples.': 0,
      'Collect fares or payments.': 0,
      'Collect information about patients or clients.': 0,
      'Collect samples of products or materials.': 0,
      'Communicate environmental or sustainability information.': 0,
      'Communicate with others about business strategies.': 0,
      'Communicate with others about operational plans or activities.': 1,
      'Communicate with others about specifications or project details.': 0,
      'Compile records, documentation, or other data.': 0,
      'Conduct amusement or gaming activities.': 0,
      'Confer with clients to determine needs or order specifications.': 0,
      'Confer with healthcare or other professionals about patient care.': 0,
      'Connect components or supply lines to equipment or tools.': 0,
      'Consult legal materials or public records.': 0,
      'Coordinate activities with clients, agencies, or organizations.': 1,
      'Coordinate artistic or entertainment activities.': 0,
      'Coordinate group, community, or public activities.': 0,
      'Coordinate regulatory compliance activities.': 0,
      'Coordinate with others to resolve problems.': 0,
      'Counsel others about personal matters.': 0,
      'Create artistic designs or performances.': 0,
      'Create decorative objects or parts of objects.': 0,
      'Create visual designs or displays.': 0,
      'Cut materials.': 0,
      'Cut trees or other vegetation.': 0,
      'Design computer or information systems or applications.': 0,
      'Design databases.': 0,
      'Design electrical or electronic systems or equipment.': 0,
      'Design industrial systems or equipment.': 0,
      'Design materials or devices.': 0,
      'Design structures or facilities.': 0,
      'Determine operational methods or procedures.': 0,
      'Determine resource needs of projects or operations.': 0,
      'Determine values or prices of goods or services.': 0,
      'Develop business or marketing plans.': 0,
      'Develop contingency or emergency response plans.': 0,
      'Develop educational programs, plans, or procedures.': 0,
      'Develop financial or business plans.': 0,
      'Develop health assessment methods or programs.': 0,
      'Develop marketing or promotional materials.': 0,
      'Develop models of systems, processes, or products.': 0,
      'Develop news, entertainment, or artistic content.': 0,
      'Develop operational or technical procedures or standards.': 0,
      'Develop organizational or program goals or objectives.': 0,
      'Develop organizational policies, systems, or processes.': 1,
      'Develop patient or client care or treatment plans.': 0,
      'Develop plans for managing or preserving natural resources.': 0,
      'Develop professional relationships or networks.': 0,
      'Develop public or community health programs.': 0,
      'Develop recipes or menus.': 0,
      'Develop research plans or methodologies.': 0,
      'Develop safety standards, policies, or procedures.': 0,
      'Develop scientific or mathematical theories or models.': 0,
      'Develop sustainable organizational or business policies or practices.': 0,
      'Develop systems or practices to mitigate or resolve environmental problems.': 0,
      'Develop technical specifications for products or operations.': 0,
      'Diagnose health conditions or disorders.': 0,
      'Diagnose system or equipment problems.': 0,
      'Direct construction or extraction activities.': 0,
      'Direct legal activities.': 0,
      'Direct organizational operations, activities, or procedures.': 1,
      'Direct scientific or technical activities.': 0,
      'Direct security or safety activities or operations.': 0,
      'Direct vehicle traffic.': 0,
      'Disassemble equipment.': 0,
      'Discuss legal matters with clients, disputants, or legal professionals or staff.': 0,
      'Dispose of waste or debris.': 0,
      'Distribute materials, supplies, or resources.': 0,
      'Document technical designs, procedures, or activities.': 0,
      'Draft legislation or regulations.': 0,
      'Drill holes in earth or materials.': 0,
      'Edit written materials or documents.': 0,
      'Embalm corpses.': 0,
      'Engrave objects.': 0,
      'Escort others.': 0,
      'Estimate project development or operational costs.': 0,
      'Evaluate condition of financial assets, property, or other resources.': 0,
      'Evaluate designs, specifications, or other technical data.': 0,
      'Evaluate green technologies or processes.': 0,
      'Evaluate patient or client condition or treatment options.': 0,
      'Evaluate personnel capabilities or performance.': 0,
      'Evaluate production inputs or outputs.': 0,
      'Evaluate programs, practices, or processes.': 0,
      'Evaluate project feasibility.': 0,
      'Evaluate scholarly work.': 0,
      'Evaluate the characteristics, usefulness, or performance of products or technologies.': 0,
      'Evaluate the quality or accuracy of data.': 0,
      'Examine financial activities, operations, or systems.': 0,
      'Examine materials or documentation for accuracy or compliance.': 0,
      'Examine people or animals to assess health conditions or physical characteristics.': 0,
      'Execute financial transactions.': 0,
      'Explain financial information.': 0,
      'Explain medical information to patients or family members.': 0,
      'Explain regulations, policies, or procedures.': 0,
      'Explain technical details of products or services.': 0,
      'Fabricate devices or components.': 0,
      'Fabricate medical devices.': 0,
      'Fit assistive devices to patients or clients.': 0,
      'Follow standard healthcare safety procedures to protect patient and staff members.': 0,
      'Gather data about operational or development activities.': 0,
      'Gather information for news stories.': 0,
      'Gather information from physical or electronic sources.': 0,
      'Groom or style hair.': 0,
      'Hunt animals.': 0,
      'Identify business or organizational opportunities.': 0,
      'Implement procedures or processes.': 0,
      'Implement security measures for computer or information systems.': 0,
      'Inspect characteristics or conditions of materials or products.': 0,
      'Inspect commercial, industrial, or production systems or equipment.': 0,
      'Inspect completed work or finished products.': 0,
      'Inspect facilities or equipment.': 0,
      'Inspect vehicles.': 0,
      'Install commercial or production equipment.': 0,
      'Install energy or heating equipment.': 0,
      'Install plumbing or piping equipment or systems.': 0,
      'Interpret language, cultural, or religious information for others.': 0,
      'Intervene in crisis situations or emergencies.': 0,
      'Interview people to obtain information.': 0,
      'Investigate criminal or legal matters.': 0,
      'Investigate incidents or accidents.': 0,
      "Investigate individuals' background, behavior, or activities.": 0,
      'Investigate organizational or operational problems.': 0,
      'Investigate the environmental impact of industrial or development activities.': 0,
      'Issue documentation.': 0,
      'Join parts using soldering, welding, or brazing techniques.': 0,
      'Load products, materials, or equipment for transportation or further processing.': 0,
      'Maintain current knowledge in area of expertise.': 0,
      'Maintain electronic, computer, or other technical equipment.': 0,
      'Maintain facilities or equipment.': 0,
      'Maintain health or medical records.': 0,
      'Maintain medical equipment or instruments.': 0,
      'Maintain operational records.': 0,
      'Maintain safety or security.': 0,
      'Maintain sales or financial records.': 0,
      'Maintain tools or equipment.': 0,
      'Maintain vehicles in working condition.': 0,
      'Make legal decisions.': 0,
      'Manage agricultural or forestry operations.': 0,
      'Manage budgets or finances.': 1,
      'Manage control systems or activities.': 0,
      'Manage human resources activities.': 0,
      'Mark materials or objects for identification.': 0,
      'Measure physical characteristics of materials, products, or equipment.': 0,
      'Mediate disputes.': 0,
      'Monitor environmental conditions.': 0,
      'Monitor equipment operation.': 0,
      'Monitor external affairs, trends, or events.': 0,
      'Monitor financial data or activities.': 0,
      'Monitor health conditions of humans or animals.': 0,
      'Monitor individual behavior or performance.': 0,
      'Monitor operation of computer or information technologies.': 0,
      'Monitor operations to ensure adequate performance.': 0,
      'Monitor operations to ensure compliance with regulations or standards.': 0,
      'Monitor resources or inventories.': 0,
      'Monitor safety or security of work areas, facilities, or properties.': 0,
      'Monitor traffic conditions.': 0,
      'Move materials, equipment, or supplies.': 0,
      'Negotiate contracts or agreements.': 0,
      'Notify others of emergencies or problems.': 0,
      'Obtain formal documentation or authorization.': 0,
      'Obtain information about goods or services.': 0,
      'Operate agricultural or forestry equipment.': 0,
      'Operate audiovisual or related equipment.': 0,
      'Operate communications equipment or systems.': 0,
      'Operate computer systems or computerized equipment.': 0,
      'Operate construction or excavation equipment.': 0,
      'Operate cutting or grinding equipment.': 0,
      'Operate energy production or distribution equipment.': 0,
      'Operate industrial processing or production equipment.': 0,
      'Operate laboratory or field equipment.': 0,
      'Operate lifting or moving equipment.': 0,
      'Operate medical equipment.': 0,
      'Operate office equipment.': 0,
      'Operate pumping systems or equipment.': 0,
      'Operate transportation equipment or vehicles.': 0,
      'Order medical tests or procedures.': 0,
      'Package objects.': 0,
      'Perform administrative or clerical activities.': 0,
      'Perform agricultural activities.': 0,
      'Perform athletic activities for fitness, competition, or artistic purposes.': 0,
      'Perform court-related or other legal administrative activities.': 0,
      'Perform general construction or extraction activities.': 0,
      'Perform human resources activities.': 0,
      'Perform recruiting or hiring activities.': 1,
      'Plan events or programs.': 0,
      'Plan work activities.': 0,
      'Position materials or components for assembly.': 0,
      'Position tools or equipment.': 0,
      'Position workpieces or materials on equipment.': 0,
      'Prepare documentation for contracts, applications, or permits.': 0,
      'Prepare financial documents, reports, or budgets.': 0,
      'Prepare foods or beverages.': 0,
      'Prepare health or medical documents.': 0,
      'Prepare industrial materials for processing or use.': 0,
      'Prepare informational or instructional materials.': 0,
      'Prepare legal or regulatory documents.': 0,
      'Prepare medical equipment or work areas for use.': 0,
      'Prepare mixtures or solutions.': 0,
      'Prepare proposals or grant applications.': 0,
      'Prepare reports of operational or procedural activities.': 0,
      'Prepare schedules for services or facilities.': 0,
      'Prepare specimens or materials for testing.': 0,
      'Prescribe medical treatments or devices.': 0,
      'Present arts or entertainment performances.': 0,
      'Present information in legal proceedings.': 0,
      'Present research or technical information.': 0,
      'Process animal carcasses.': 0,
      'Process digital or online data.': 0,
      'Process forensic or legal evidence.': 0,
      'Process shipments or mail.': 0,
      'Program computer systems or production equipment.': 0,
      'Promote products, services, or programs.': 0,
      'Protect people or property from threats such as fires or flooding.': 0,
      'Provide food or beverage services.': 0,
      'Provide general assistance to others, such as customers, patrons, or motorists.': 0,
      'Provide information or assistance to the public.': 0,
      'Provide information to guests, clients, or customers.': 0,
      'Provide support or encouragement to others.': 0,
      'Purchase goods or services.': 0,
      'Read documents or materials to inform work processes.': 0,
      'Reconcile financial data.': 0,
      'Record images with photographic or audiovisual equipment.': 0,
      'Record information about environmental conditions.': 0,
      'Record information about legal matters.': 0,
      'Remove workpieces from production equipment.': 0,
      'Repair electrical or electronic equipment.': 0,
      'Repair tools or equipment.': 0,
      'Repair vehicle components.': 0,
      'Repair workpieces or products.': 0,
      'Replenish inventories of materials, equipment, or products.': 0,
      'Research agricultural processes or practices.': 0,
      'Research biological or ecological phenomena.': 0,
      'Research healthcare issues.': 0,
      'Research historical or social issues.': 0,
      'Research issues related to earth sciences.': 0,
      'Research laws, precedents, or other legal data.': 0,
      'Research organizational behavior, processes, or performance.': 0,
      'Research technology designs or applications.': 1,
      'Resolve computer problems.': 0,
      'Resolve personnel or operational problems.': 0,
      'Respond to customer problems or inquiries.': 0,
      'Schedule appointments.': 0,
      'Schedule operational activities.': 0,
      'Select materials or equipment for operations or projects.': 0,
      'Sell products or services.': 0,
      'Serve on organizational committees.': 0,
      'Set up classrooms, facilities, educational materials, or equipment.': 0,
      'Set up computer systems, networks, or other information systems.': 0,
      'Set up equipment.': 0,
      'Set up protective structures or coverings near work areas.': 0,
      'Sew garments or materials.': 0,
      'Shape materials to create products.': 0,
      'Signal others to coordinate work activities.': 0,
      'Smooth surfaces of objects or equipment.': 0,
      'Sort materials or products.': 0,
      'Stock supplies or products.': 0,
      'Study details of artistic productions.': 0,
      'Supervise activities in correctional facilities.': 0,
      'Supervise personnel activities.': 1,
      'Take physical measurements of patients or clients.': 0,
      'Teach academic or vocational subjects.': 0,
      'Teach life skills.': 0,
      'Teach safety procedures or standards to others.': 0,
      'Tend watercraft.': 0,
      'Test characteristics of materials or products.': 0,
      'Test performance of computer or information systems.': 0,
      'Test performance of equipment or systems.': 0,
      'Test sites or materials for environmental hazards.': 0,
      'Train animals.': 0,
      'Train others on health or medical topics.': 0,
      'Train others on operational or work procedures.': 0,
      'Train others to use equipment or products.': 0,
      'Transport patients or clients.': 0,
      'Treat injuries, illnesses, or diseases.': 0,
      'Verify personal information.': 0,
      'Write material for artistic or commercial purposes.': 0,
    },
  },
};

const teamReportAttributes = Object.values(CompetenciesSourceConstruct)
  .concat(Object.values(InterestsSourceConstruct) as any)
  .concat(Object.values(PersonalitySourceConstruct) as any);

const attributes: TemplateContextAttributes = {};
teamReportAttributes.forEach((attr) => {
  // Generate random data for scores
  const score = Math.random() * 100; // 0.0 - 100.0
  const interpGroup = Math.floor(Math.random() * 2.99); // 0-2
  attributes[attr] = {
    score,
    interpGroup,
  };
});

export const mockTeamReportTemplateContext: TeamReportTemplateContext = {
  typologies: {
    communication: TypologyQuadrant.TypeIV,
    work: TypologyQuadrant.TypeI,
    challenge: TypologyQuadrant.TypeIII,
  },
  highestMotivator: InterestsSourceConstruct.Artistic,
  attributes,
};
