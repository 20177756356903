import * as React from 'react';
import {IndexContentLayout} from '>components/indexContentLayout';
import {TeamReportDomain} from 'wnd-dvp-reports';
import {Select} from '>shared/components/form/select';
import {CmsHeading, IndexHeader} from '>components/contentManagement/navigation/styles';
import {Option} from '>shared/components/form/rawControls/rawSelect';
import {ValueType} from 'react-select/src/types';
import {
  CmsItem,
  CmsNavItems,
  CmsNavigation,
} from '>components/contentManagement/navigation/cmsNavigation';
import {
  TeamReportApproachContent,
  TeamReportAttributeContent,
  TeamReportContentType,
  TeamReportItem,
  TeamReportOverviewContent,
} from '>generated/dvp.types';
import {assertExists, assertUnreachable} from 'wnd-util/lib/assert';
import styled from '@emotion/styled';
import {vr2} from '>shared/styles/mixins/verticalRhythm';
import {dvpApi} from '>root/apis';
import {rollbarLogger} from '>lib/logger';
import {redirectToErrorPage} from '>lib/redirect';
import {HttpError} from 'wnd-util';
import {TeamReportItemContentView, ViewState} from './content';
import {
  defaultTeamReportTemplateContext,
  defaultTeamTemplateControlFormValues,
} from '>components/contentManagement/form/templateControls/form';
import {
  AllTeamReportItemFormFields,
  AllTeamReportItemFormMethods,
  ApproachTeamReportItemFormFields,
  AttributeTeamReportItemFormFields,
  OverviewTeamReportItemFormFields,
} from '>components/contentManagement/form/teamReport/formFields';
import {
  deafultApproachTeamReportItemFormFields,
  deafultAttributeTeamReportItemFormFields,
  deafultOverviewTeamReportItemFormFields,
} from '>components/contentManagement/form/teamReport/form';
import {useForm} from 'react-hook-form';
import {TemplateControlFormFields} from '>components/contentManagement/form/templateControls/formFields';
import {setFormFields} from '../formUtils';
import {CmsItemSettings} from '>components/contentManagement/settings/cmsItemSettings';
import {useSnackbar} from '>shared/components/snackbars/useSnackbar';

const teamReportDomains = Object.values(TeamReportDomain);

const options = teamReportDomains.map((domain) => {
  return {
    label: domain,
    value: domain,
  };
});

const StyledSelect = styled(Select)`
  ${vr2}
`;

export const ContentManagerForTeamReport: React.FC = () => {
  const [selectedDomainName, setSelectedDomainName] = React.useState<string>(options[0].value);
  const [selectedDomainTeamReportItems, setSelectedDomainTeamReportItems] = React.useState<
    TeamReportItem[] | undefined
  >();
  const [selectedTeamReportItem, setSelectedTeamReportItem] = React.useState<TeamReportItem>();
  const [viewState, setViewState] = React.useState<ViewState>(ViewState.View);
  const [templateContext, setTemplateContext] = React.useState(
    JSON.parse(defaultTeamReportTemplateContext)
  );
  const [formFieldsInEdit, setFormFieldsInEdit] = React.useState<AllTeamReportItemFormFields>();
  const [previousViewState, setPreviousViewState] = React.useState<ViewState>();

  const {showSuccessAlert} = useSnackbar();

  const overviewTeamReportItemFormMethods = useForm<OverviewTeamReportItemFormFields>({
    mode: 'all',
    defaultValues: deafultOverviewTeamReportItemFormFields,
  });

  const approachTeamReportItemFormMethods = useForm<ApproachTeamReportItemFormFields>({
    mode: 'all',
    defaultValues: deafultApproachTeamReportItemFormFields,
  });

  const attributeTeamReportItemFormMethods = useForm<AttributeTeamReportItemFormFields>({
    mode: 'all',
    defaultValues: deafultAttributeTeamReportItemFormFields,
  });

  const templateControlFormMethods = useForm<TemplateControlFormFields>({
    mode: 'all',
    defaultValues: defaultTeamTemplateControlFormValues,
  });

  const formMethods = React.useMemo(() => {
    return {
      [TeamReportContentType.Overview]: overviewTeamReportItemFormMethods,
      [TeamReportContentType.Approach]: approachTeamReportItemFormMethods,
      [TeamReportContentType.Attribute]: attributeTeamReportItemFormMethods,
    };
  }, [
    overviewTeamReportItemFormMethods,
    approachTeamReportItemFormMethods,
    attributeTeamReportItemFormMethods,
  ]);

  const handleTemplateContextChange = React.useCallback((newValue: string) => {
    setTemplateContext(JSON.parse(newValue));
    templateControlFormMethods.setValue('templateContext', newValue);
  }, []);

  const handleDomainSelected = React.useCallback(
    (selectedOption: ValueType<Option, false>) => {
      if (selectedOption) {
        setSelectedDomainName(selectedOption.value);
      }
    },
    [selectedDomainName]
  );

  const handleTeamReportItemSelected = React.useCallback(
    (itemId: string) => {
      if (selectedDomainTeamReportItems) {
        setSelectedTeamReportItem(
          selectedDomainTeamReportItems.find((item) => item.id === itemId) as TeamReportItem
        );
        setViewState(ViewState.View);
      }
    },
    [selectedDomainTeamReportItems]
  );

  const navItemList: CmsNavItems = React.useMemo(() => {
    if (!selectedDomainTeamReportItems) {
      return {
        topLevelItems: [],
        categories: [],
      };
    }

    const overviewContent = selectedDomainTeamReportItems.find(
      (item) => item.type === TeamReportContentType.Overview
    );
    const overviewNavItem = {
      id: overviewContent?.id,
      title: TeamReportContentType.Overview,
      label: '',
    };

    const approachContent = selectedDomainTeamReportItems.find(
      (item) => item.type === TeamReportContentType.Approach
    );
    const approachNavItem = {
      id: approachContent?.id,
      title: TeamReportContentType.Approach,
      label: '',
    };

    const attributes = selectedDomainTeamReportItems.map((item) => {
      if (item.type === TeamReportContentType.Attribute) {
        return item;
      }
    });

    assertExists(attributes, 'Attributes must exist');

    return {
      topLevelItems: [overviewNavItem, approachNavItem] as CmsItem[],
      categories: [
        {
          title: 'Attributes',
          items: teamReportAttributeToNav(
            attributes.filter((item) => item !== undefined) as TeamReportItem[]
          ),
        },
      ],
    };
  }, [selectedDomainTeamReportItems]);

  const validateTeamReportItem = React.useCallback(async () => {
    assertExists(selectedTeamReportItem, 'Selected team report item must exist for validation');
    const areTemplateFieldsValid = await templateControlFormMethods.trigger();

    if (!areTemplateFieldsValid) {
      return false;
    }

    const areNonTemplateFieldsValid = await formMethods[selectedTeamReportItem.type].trigger();

    return areNonTemplateFieldsValid;
  }, [selectedTeamReportItem, viewState, formMethods]);

  const submitTeamReportItem = React.useCallback(async () => {
    const isTeamReportItemValid = await validateTeamReportItem();

    assertExists(
      selectedTeamReportItem,
      'A team report item must exist in order to submit changes'
    );

    if (isTeamReportItemValid) {
      let updatedContent;

      if (viewState === ViewState.Preview) {
        assertExists(formFieldsInEdit, 'Form fields should exist when submitting form');
        updatedContent = formFieldsInEdit;
      } else {
        updatedContent = formMethods[selectedTeamReportItem.type].getValues();
      }

      try {
        const {data: updatedTeamReportItem} = await dvpApi.updateTeamReportItem({
          id: selectedTeamReportItem.id,
          type: selectedTeamReportItem.type,
          content: {...selectedTeamReportItem.content, ...updatedContent},
        });

        assertExists(selectedDomainTeamReportItems, 'Selected domain content must exist');

        const updatedDomainTeamReportItems = selectedDomainTeamReportItems.map((item) => {
          if (item.id === updatedTeamReportItem.id) {
            return updatedTeamReportItem;
          }
          return item;
        });
        setViewState(ViewState.View);
        showSuccessAlert('Team report item successfully updated');

        setSelectedDomainTeamReportItems(updatedDomainTeamReportItems);
        setSelectedTeamReportItem(updatedTeamReportItem);
      } catch (error) {
        const err = error as HttpError;
        rollbarLogger.error(err);
        redirectToErrorPage(err);
      }
    }
  }, [
    validateTeamReportItem,
    selectedTeamReportItem,
    selectedDomainTeamReportItems,
    viewState,
    formMethods,
    overviewTeamReportItemFormMethods,
    approachTeamReportItemFormMethods,
  ]);

  const handleEditTeamReportItem = React.useCallback(() => {
    if (selectedTeamReportItem) {
      setViewState(ViewState.Edit);
      let content;
      switch (selectedTeamReportItem.type) {
        case TeamReportContentType.Overview:
          content = selectedTeamReportItem.content as TeamReportOverviewContent;
          break;
        case TeamReportContentType.Approach:
          content = selectedTeamReportItem.content as TeamReportApproachContent;
          break;
        case TeamReportContentType.Attribute:
          content = selectedTeamReportItem.content as TeamReportAttributeContent;
          break;
        default:
          assertUnreachable(selectedTeamReportItem.type, 'Unexpected team report item type');
      }

      setFormFields(formMethods[selectedTeamReportItem.type], content);

      templateControlFormMethods.reset({
        globalVariables: '',
        templateContext: defaultTeamReportTemplateContext,
      });
    }
  }, [selectedTeamReportItem]);

  const handleCancel = React.useCallback(() => {
    setViewState(ViewState.View);
  }, [viewState, selectedTeamReportItem]);

  const togglePreview = React.useCallback(async () => {
    assertExists(selectedTeamReportItem, 'Home tab should exist');
    if (!(await validateTeamReportItem())) {
      return;
    }

    setPreviousViewState(viewState);

    switch (viewState) {
      case ViewState.Preview:
        assertExists(
          formFieldsInEdit,
          'When going back from preview state the form fields being edited should exist'
        );
        setFormFields(formMethods[selectedTeamReportItem.type], formFieldsInEdit);
        templateControlFormMethods.reset({
          templateContext: JSON.stringify(templateContext, null, 2),
        });

        assertExists(
          previousViewState,
          'When going back from preview state the previous state must exist'
        );

        setViewState(previousViewState);
        break;
      case ViewState.Edit:
        setFormFieldsInEdit(formMethods[selectedTeamReportItem.type].getValues());
        setTemplateContext(JSON.parse(templateControlFormMethods.getValues('templateContext')));
        setViewState(ViewState.Preview);
        break;
    }
  }, [viewState]);

  React.useEffect(() => {
    dvpApi
      .getDomainTeamReportItems({teamReportDomain: selectedDomainName})
      .then(({data}) => {
        setSelectedDomainTeamReportItems(data);
        setSelectedTeamReportItem(data[0]);
        setViewState(ViewState.View);
      })
      .catch((err: any) => {
        const error = err as HttpError;
        rollbarLogger.error(error);
        redirectToErrorPage(error);
      });
  }, [selectedDomainName]);

  const canViewCms = selectedDomainTeamReportItems && selectedTeamReportItem;

  return (
    <>
      {canViewCms && (
        <IndexContentLayout
          indexSection={
            <>
              <IndexHeader>
                <CmsHeading>Team Contents</CmsHeading>
                <StyledSelect
                  options={options}
                  defaultValue={options[0]}
                  onSelectChange={handleDomainSelected}
                />
              </IndexHeader>
              <CmsNavigation
                navItemList={navItemList}
                onNavItemSelected={handleTeamReportItemSelected}
                selectedNavItemId={selectedTeamReportItem?.id}
              />
            </>
          }
          contentSection={
            <TeamReportItemContentView
              allTeamReportItems={selectedDomainTeamReportItems}
              selectedTeamReportItem={selectedTeamReportItem}
              teamReportItemFormMethods={
                formMethods[selectedTeamReportItem.type] as AllTeamReportItemFormMethods
              }
              templateControlFormMethods={templateControlFormMethods}
              handleTemplateContextChange={handleTemplateContextChange}
              templateContext={templateContext}
              viewState={viewState}
              formFieldsInEdit={formFieldsInEdit}
            />
          }
          optionsSection={
            <CmsItemSettings
              cmsItem={teamReportItemToCmsItem(selectedTeamReportItem)}
              viewState={viewState}
              onEdit={handleEditTeamReportItem}
              onSubmit={submitTeamReportItem}
              onCancel={handleCancel}
              onTogglePreview={togglePreview}
            />
          }
        />
      )}
    </>
  );
};

function teamReportAttributeToNav(teamReportItems: TeamReportItem[]): CmsItem[] {
  return teamReportItems.map((item) => {
    const content = item.content as TeamReportAttributeContent;

    return {
      id: item.id,
      title: content.sourceConstruct,
      label: '',
    };
  });
}

function teamReportItemToCmsItem(item: TeamReportItem): CmsItem {
  return {
    id: item.id,
    title: item.type,
    label: '',
  };
}
