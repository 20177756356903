"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDimensions = void 0;
var React = _interopRequireWildcard(require("react"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
/**
 * This function should be used when you need to calculate the width or height based on the parent element's CSS dimensions
 * If you need to use the browser window width, use the useWindowSize hook instead
 * adapted from https://www.react-graph-gallery.com/radar-chart
 */
const useDimensions = targetRef => {
  const getDimensions = () => {
    return {
      width: targetRef.current ? targetRef.current.offsetWidth : 0,
      height: targetRef.current ? targetRef.current.offsetHeight : 0
    };
  };
  const [dimensions, setDimensions] = React.useState(getDimensions);
  const handleResize = () => {
    setDimensions(getDimensions());
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [targetRef?.current?.offsetWidth, targetRef?.current?.offsetHeight]);
  React.useLayoutEffect(() => {
    handleResize();
  }, [targetRef?.current?.offsetWidth, targetRef?.current?.offsetHeight]);
  React.useEffect(() => {
    handleResize();
  }, [targetRef?.current?.offsetWidth, targetRef?.current?.offsetHeight]);
  return dimensions;
};
exports.useDimensions = useDimensions;