"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pixelsToRem = pixelsToRem;
exports.pixelsWithUnitToPixels = pixelsWithUnitToPixels;
exports.remToPixels = remToPixels;
exports.remToPixelsWithUnit = remToPixelsWithUnit;
function getBaseFontSize() {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
}
function remToPixels(rem) {
  return rem * getBaseFontSize();
}
function pixelsToRem(pixels) {
  return pixels / getBaseFontSize();
}
// Example: 2.0 -> "20px"
function remToPixelsWithUnit(rem) {
  return `${remToPixels(rem)}px`;
}
// Example: "20px" -> 20.0
function pixelsWithUnitToPixels(pixels) {
  return parseFloat(pixels.replace('px', ''));
}