var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FontStack, FontWeight } from './fonts';
import * as colors from './colorPalette';
var primaryColor = colors.blue;
var primaryAccent = colors.scienceBlue;
var PrimaryButton = {
    borderRadius: '2.7rem',
    padding: '0.5rem 2.0rem 0.4rem',
    outlineColor: primaryColor,
    enabled: {
        backgroundColor: primaryColor,
        color: colors.white,
        borderColor: colors.iceberg,
    },
    active: {
        backgroundColor: primaryColor,
        color: colors.white,
        borderColor: primaryColor,
    },
    disabled: {
        backgroundColor: colors.hawkesBlue,
        color: colors.white,
        borderColor: colors.hawkesBlue,
    },
    hover: {
        backgroundColor: primaryAccent,
        color: colors.white,
        borderColor: colors.iceberg,
    },
};
var SecondaryButton = __assign(__assign({}, PrimaryButton), { enabled: {
        backgroundColor: colors.transparent,
        color: primaryColor,
        borderColor: primaryColor,
    }, hover: {
        backgroundColor: colors.transparent,
        color: primaryAccent,
        borderColor: primaryAccent,
    }, active: {
        backgroundColor: colors.grey50,
        color: colors.white,
        borderColor: colors.white,
    } });
var TransparentButton = __assign(__assign({}, PrimaryButton), { enabled: {
        backgroundColor: colors.transparent,
        color: primaryColor,
        borderColor: primaryColor,
    }, hover: {
        backgroundColor: primaryAccent,
        color: colors.white,
        borderColor: primaryAccent,
    }, active: {
        backgroundColor: primaryColor,
        color: colors.white,
        borderColor: primaryColor,
    } });
var InvertedButton = __assign(__assign({}, PrimaryButton), { enabled: {
        backgroundColor: colors.transparent,
        color: primaryAccent,
        borderColor: colors.white,
    }, hover: {
        backgroundColor: colors.white,
        color: primaryAccent,
        borderColor: colors.white,
    }, active: {
        backgroundColor: colors.white,
        color: primaryColor,
        borderColor: colors.white,
    }, disabled: {
        backgroundColor: colors.transparent,
        color: colors.grey90,
        borderColor: colors.grey90,
    } });
var NavButton = __assign(__assign({}, SecondaryButton), { enabled: {
        color: colors.black,
        backgroundColor: colors.white,
        borderColor: primaryColor,
    }, hover: {
        color: colors.black,
        backgroundColor: colors.pattensBlue,
        borderColor: primaryColor,
    }, active: {
        color: colors.black,
        backgroundColor: colors.white,
        borderColor: primaryColor,
    } });
var Tile = {
    backgroundColor: colors.white,
    borderColor: colors.grey90,
    borderRadius: '0.5rem',
    borderSize: '0.1rem',
    paddingMediumOrHigher: '4rem',
    paddingSmallOrLower: '4rem',
};
var ErrorPage = {
    backgroundColor: colors.grey96,
};
var BreadCrumbs = {
    color: colors.white,
    separator: '>',
};
var Page = {
    pageWidth: '128rem',
    mediumGutter: '2rem',
    smallGutter: '1.6rem',
};
var SideBar = {
    background: primaryColor,
    mobileBackground: primaryColor,
    hoverOutlineColor: primaryAccent,
    activeOutlineColor: primaryAccent,
    focusOutlineColor: primaryAccent,
    hoverBackgroundColor: colors.white,
    activeBackgroundColor: primaryAccent,
    dividerColor: primaryAccent,
    color: colors.white,
    itemFocusColor: colors.darkPink,
};
var AppHeader = {
    subHeaderColor: primaryColor,
};
var Icons = {
    primaryColor: primaryColor,
};
var Widgets = {
    headerColor: primaryColor,
};
var Checkbox = {
    borderColor: primaryColor,
    backgroundColor: primaryColor,
};
var InputBubble = {
    focusBackgroundColor: colors.lightBlue,
    activeBackgroundColor: colors.blueAccent,
};
var UserMenu = {
    backgroundColor: colors.grey30,
    hoverBackgroundColor: colors.grey50,
    dividerColor: colors.grey90,
    activeMenuIconColor: colors.white,
};
var OverflowMenu = {
    hoverBackgroundColor: colors.grey93,
};
var WorkflowProgressBar = {
    primaryColor: colors.mariner,
    secondaryColor: colors.frenchSkyBlue,
    backgroundColor: colors.aliceBlue,
};
var FormLayoutTheme = {
    gap: '1.2rem',
};
var AppLayoutTheme = {
    navWidth: '7.2rem',
    maxContentWidth: '136.8rem',
    maxPageWidth: '144rem',
    defaultScrollPadding: '12rem',
    mobileScrollPadding: '14rem',
};
export var defaultTheme = {
    name: 'Default Theme',
    background: colors.white,
    primaryFontFamily: FontStack.Kievit,
    banner: {
        padding: '1.2rem',
    },
    button: {
        primary: PrimaryButton,
        secondary: SecondaryButton,
        transparent: TransparentButton,
        inverted: InvertedButton,
        accent: PrimaryButton,
    },
    bulletListItem: {
        fontFamily: FontStack.Kievit,
        color: colors.grey44,
    },
    paragraph: {
        fontFamily: FontStack.Kievit,
        color: colors.darkGreyAzure,
    },
    pageTitle: {
        color: colors.grey44,
        fontFamily: FontStack.Kievit,
        fontWeight: FontWeight.Normal,
    },
    sectionHeading: {
        color: colors.grey30,
        fontFamily: FontStack.Kievit,
        fontWeight: FontWeight.Normal,
    },
    card: {
        backgroundColor: colors.white,
        border: 'none',
        boxShadow: 'none',
        sectionStatement: {
            fontFamily: FontStack.Kievit,
        },
        title: {
            color: colors.grey44,
            fontFamily: FontStack.Kievit,
        },
    },
    flagpole: {
        primary: {
            backgroundColor: colors.primary25,
            poleColor: colors.primary600,
        },
        secondary: {
            backgroundColor: colors.yellow25,
            poleColor: colors.yellow500,
        },
    },
    radioButton: {
        bubble: {
            size: '4rem',
            margin: '0',
        },
        default: {
            color: colors.grey30,
            backgroundColor: colors.grey96,
            borderColor: colors.grey96,
            buttonColor: colors.grey96,
            buttonCircleColor: colors.grey44,
        },
        checked: {
            color: colors.white,
            backgroundColor: primaryAccent,
            borderColor: primaryAccent,
            buttonColor: primaryAccent,
            buttonCircleColor: primaryAccent,
        },
        hover: {
            color: colors.white,
            backgroundColor: colors.grey30,
            borderColor: colors.grey30,
            buttonColor: colors.grey30,
            buttonCircleColor: colors.grey44,
        },
        tabSelected: {
            color: colors.grey30,
            backgroundColor: colors.grey96,
            borderColor: colors.grey30,
            buttonColor: primaryColor,
            buttonCircleColor: colors.grey44,
        },
        disabled: {
            color: colors.grey50,
            backgroundColor: colors.grey96,
            borderColor: colors.grey50,
            buttonColor: colors.grey50,
            buttonCircleColor: colors.grey62,
        },
    },
    toolTip: {
        textColor: primaryColor,
        backgroundColor: colors.white,
        borderColor: primaryColor,
    },
    textInput: {
        background: {
            default: colors.white,
            disabled: colors.grey93,
        },
        border: {
            border: '0.1rem solid',
            borderRadius: '0.4rem',
            borderColor: colors.grey50,
            errorBorderColor: colors.validationErrorRed,
            focusBorderColor: colors.scienceBlue,
        },
        color: {
            default: colors.grey30,
            disabled: colors.grey50,
            error: colors.validationErrorRed,
            placeholder: colors.grey50,
        },
        defaultHeight: '4.8rem',
        font: {
            fontSize: '2rem',
            fontWeight: FontWeight.Normal,
            placeholderFontWeight: FontWeight.Light,
            lineHeight: '3.2rem',
        },
        helperText: {
            fontWeight: FontWeight.Medium,
        },
        inputPadding: '1.4rem 0.8rem',
        label: {
            fontSize: '1.6rem',
            fontWeight: FontWeight.Medium,
            lineHeight: '2.4rem',
            color: colors.grey30,
            helperCase: 'capitalize',
            helperStyle: 'italic',
            helperWeight: FontWeight.Light,
            asterisk: false,
            showOptional: true,
        },
        focusBoxShadow: false,
    },
    textArea: {
        showIcon: true,
        defaultHeight: '12rem',
        padding: '1rem 3rem 1rem 0.6rem',
    },
    select: {
        placeholder: {
            color: colors.grey50,
        },
        input: {
            focus: "border-color: ".concat(colors.scienceBlue, "; outline-color: ").concat(colors.scienceBlue, ";"),
        },
        menu: {
            border: "0.1rem solid ".concat(colors.char100),
            borderRadius: '0.8rem',
            boxShadow: '0 0.4rem 0.8rem rgba(0, 0, 0, 0.04), 0 0 0.2rem rgba(0, 0, 0, 0.06), 0 0 0.1rem rgba(0, 0, 0, 0.04)',
        },
        menuList: {
            color: colors.grey30,
            backgroundColor: colors.white,
        },
        option: {
            styleOptions: false,
        },
        value: {
            color: colors.grey30,
        },
        valueContainer: {
            margin: '0 8px',
        },
        enableVariants: false,
    },
    menuToggle: {
        hoverBackgroundColor: colors.greenAccent,
    },
    navLink: NavButton,
    tile: Tile,
    errorPage: ErrorPage,
    breadCrumbs: BreadCrumbs,
    page: Page,
    sideBar: SideBar,
    appHeader: AppHeader,
    icons: Icons,
    widgets: Widgets,
    checkbox: Checkbox,
    inputBubble: InputBubble,
    userMenu: UserMenu,
    overflowMenu: OverflowMenu,
    workflowProgressBar: WorkflowProgressBar,
    formLayoutTheme: FormLayoutTheme,
    layout: AppLayoutTheme,
};
