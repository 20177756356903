import React from 'react';
import styled from '@emotion/styled';
import {MenuLinkItem} from '>shared/components/menu/menuItem';
import {IconDropdownMenu} from '>shared/components/menu/overflowMenu';
import {respondTo} from '>shared/styles/breakpoints';
import {char600} from 'wnd-themes/lib/colorPalette';
import {Icons as IconTypes} from '>shared/styles/icon-types';
import {calculateDropdownPosition} from '>lib/overflowMenu';

const OverflowContainer = styled.div<{fromHeader: boolean}>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.fromHeader ? 0 : '1.6rem')};

  a {
    color: ${char600};
  }

  ${respondTo.smallOrLower} {
    ${(props) => !props.fromHeader && 'display: none'};
  }
`;

interface MobileOverflowMenuProps {
  menuItems: MenuLinkItem[];
  fromHeader: boolean;
}

export const MobileOverflowMenu: React.FC<MobileOverflowMenuProps> = ({fromHeader, menuItems}) => {
  return (
    <OverflowContainer fromHeader={fromHeader}>
      <IconDropdownMenu
        altText="Actions"
        icon={IconTypes.KebabMenu}
        menuItems={menuItems}
        position={calculateDropdownPosition()}
      />
    </OverflowContainer>
  );
};
