import {ChapterContent, ChapterItem, ChapterItemType} from '>generated/dvp.types';
import {createReducer} from '@reduxjs/toolkit';
import {assert} from 'wnd-util/lib/assert';
import {
  createChapterIntroduction,
  createChapterMeasure,
  deleteChapterMeasure,
  fetchChapterContent,
  updateChapterItem,
} from '../actions/chapter';

export interface ChapterState {
  chapter: ChapterContent;
}

export const DEFAULT_INTRODUCTION: ChapterItem = {
  id: 'default',
  type: ChapterItemType.Introduction,
  chapterNumber: '1',
  anchorId: '0',
  content: {
    introduction: '',
    instructions: '',
  },
  isPublished: false,
};

const DEFAULT_STATE: Readonly<ChapterState> = {
  chapter: {
    introduction: DEFAULT_INTRODUCTION,
    measures: [],
  },
};

function addChapterMeasure(state: ChapterState, {payload}: {payload: ChapterItem}): ChapterState {
  if (payload.type !== ChapterItemType.Measure) {
    throw new Error(
      `Chapter item is of type ${payload.type}, but must be of type ${ChapterItemType.Measure} to be added`
    );
  }

  return {
    chapter: {
      ...state.chapter,
      measures: [...state.chapter.measures, payload],
    },
  };
}

function removeChapterMeasure(
  state: ChapterState,
  {payload}: {payload: ChapterItem}
): ChapterState {
  if (payload.type !== ChapterItemType.Measure) {
    throw new Error(
      `Chapter item is of type ${payload.type}, but must be of type ${ChapterItemType.Measure} to be removed`
    );
  }

  const measures = state.chapter.measures;
  const filteredMeasures = measures.filter((measure) => measure.id !== payload.id);

  return {
    chapter: {
      ...state.chapter,
      measures: filteredMeasures,
    },
  };
}

function replaceChapterItem(state: ChapterState, {payload}: {payload: ChapterItem}): ChapterState {
  let newState = {...state};

  if (payload.type === ChapterItemType.Introduction) {
    newState = {
      chapter: {
        ...state.chapter,
        introduction: payload,
      },
    };
  }

  if (payload.type === ChapterItemType.Measure) {
    const measures = state.chapter.measures;
    const measureToUpdate = measures.findIndex((measure) => {
      return measure.id === payload.id;
    });

    assert(measureToUpdate >= 0, `Cannot find measure ${payload.id} to update`);

    const newMeasures = [...measures];
    newMeasures[measureToUpdate] = payload;

    newState = {
      chapter: {
        ...state.chapter,
        measures: newMeasures,
      },
    };
  }

  return newState;
}

function replaceChapterContent(
  _state: ChapterState,
  {payload}: {payload: ChapterContent}
): ChapterState {
  if (payload.introduction) {
    return {
      chapter: payload,
    };
  } else {
    return {
      chapter: {
        introduction: DEFAULT_INTRODUCTION,
        measures: payload.measures,
      },
    };
  }
}

export const chapterReducer = createReducer<ChapterState>(DEFAULT_STATE, (builder) => {
  builder.addCase(createChapterMeasure.fulfilled, addChapterMeasure);
  builder.addCase(deleteChapterMeasure.fulfilled, removeChapterMeasure);
  builder.addCase(createChapterIntroduction.fulfilled, replaceChapterItem);
  builder.addCase(updateChapterItem.fulfilled, replaceChapterItem);
  builder.addCase(fetchChapterContent.fulfilled, replaceChapterContent);
});
