import * as React from 'react';
import {InterestsSourceConstruct, renderDriverIcon, SVGSize} from 'wnd-dvp-reports';
import {getGraphicMeasurements, SVGContainer} from '>lib/visualizations';

interface DriverIconProps {
  size: SVGSize;
  highestMotivator: InterestsSourceConstruct;
  isActive?: boolean;
}

export const DriverIcon: React.FC<DriverIconProps> = ({size, isActive, highestMotivator}) => {
  const {width, height} = getGraphicMeasurements(size);
  const svgText = renderDriverIcon(highestMotivator, isActive);

  return (
    <SVGContainer
      containerWidth={width}
      containerHeight={height}
      dangerouslySetInnerHTML={{
        __html: svgText,
      }}
    ></SVGContainer>
  );
};
