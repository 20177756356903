import React from 'react';

import * as Icons from '>shared/components/icon/icons';
import {Icon} from '>shared/components/icon/icon';
import * as colors from 'wnd-themes/lib/colorPalette';

import styled from '@emotion/styled';

import {textLgMedium, textMdRegular} from '>shared/components/typography/designSystemTypography';
import {ActionItemView} from './navigation';
const EmptyPageContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
`;
const emptyNavCheckCircle = (
  <div css={{justifyContent: 'center', width: '100%', display: 'flex'}}>
    <div
      css={{
        width: '5.6rem',
        height: '5.6rem',
        backgroundColor: colors.blue50,
        borderRadius: '5.6rem',
        display: 'flex',
      }}
    >
      <div
        css={{
          width: '3.6rem',
          height: '3.6rem',
          backgroundColor: colors.blue100,
          borderRadius: '3.6rem',
          display: 'flex',
          margin: 'auto',
        }}
      >
        <Icon src={Icons.checkCircle} primaryColor={colors.blue700} css={{margin: 'auto'}} />
      </div>
    </div>
  </div>
);

const EmptyPageHeader = styled.div`
  ${textLgMedium};
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const EmptyPageText = styled.div`
  ${textMdRegular};
  width: 100%;
  margin-top: 2rem;
  display: flex;
  text-align: center;
`;

export const NavEmptyPage: React.FC<{needToCreate: boolean; statusView: ActionItemView}> = ({
  needToCreate,
  statusView,
}) => {
  return (
    <EmptyPageContainer>
      {emptyNavCheckCircle}
      <EmptyPageHeader>
        {needToCreate ? 'Create an action item!' : `No "${statusView}" action items found`}
      </EmptyPageHeader>
      <EmptyPageText>
        {needToCreate
          ? `You don't have any action items yet. Click the "add" button in the top right of this panel
        to create your first one!`
          : `You don't have any "${statusView}" action items. Use the status drop down to find other action items`}
      </EmptyPageText>
    </EmptyPageContainer>
  );
};
