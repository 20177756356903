import styled from '@emotion/styled';
import {respondTo} from '>shared/styles/breakpoints';

export const PageHeading = styled.div`
  text-align: center;
  font-size: 3.2rem;
  padding: 4.8rem;
`;

export const PageHeadingWithActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 3.2rem 0rem 1.6rem 0.8rem;
`;

export const PageContents = styled.div`
  text-align: center;
  font-size: 1.6rem;
  padding-left: 1.6rem;
  width: 100%;
`;

export const InfiniteScrollPageContents = styled(PageContents)`
  padding-left: 0;
  width: min-content;
`;

export const EmptyStateContainer = styled.div`
  text-align: center;
  margin: 10rem auto auto;
  max-width: 36rem;

  ${respondTo.smallOrLower} {
    margin-top: 8rem;
  }
`;

export const CenteredImg = styled.img`
  margin: auto;
  display: block;
  width: 31.3rem;
`;
