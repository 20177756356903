import {DonutMetric} from '>components/measures/donutMetric/donutMetric';
import {AverageScoreDisplay} from '>components/teams/averageScoreDisplay';
import {BalancerCard} from '>components/teams/balancerCard';
import {TeamAttributeDotPlot} from '>components/teams/teamAttributeDotPlot';
import {TypologyQuadrant} from '>components/teams/typologyQuadrant';
import {SpotlightCardContainer} from '>components/widgets/employeesMetricsWidget/employeesMetricsWidget.styles';
import {
  SpotlightCard,
  DonutMetricContainer,
  Title,
} from '>components/widgets/spotlightWidget/styles';
import {Spotlights} from '>root/pages/employeeHome/employeeHome.styles';
import {
  TeamReportIntroBorder,
  TeamReportIntroPreHeader,
  TeamReportIntroMainHeader,
  TeamReportIntroTeamDescription,
  TeamReportSubsectionHeader,
  Divider,
  ColoredDivider,
  GrayDivider,
} from '>root/pages/teamInsights/styles';
import React from 'react';
import {TeamReportComponents, TeamReportDomain} from 'wnd-dvp-reports';

const TeamReportSubsectionDivider: React.FC<{sectionDomain: TeamReportDomain}> = ({
  sectionDomain,
}) => {
  return (
    <Divider role="presentation">
      <ColoredDivider sectionDomain={sectionDomain} />
      <GrayDivider />
    </Divider>
  );
};

const SubsectionContainer: React.FC<{anchorId: string}> = ({anchorId, children}) => {
  return <div id={anchorId}>{children}</div>;
};

export const sharedTeamReportComponents: TeamReportComponents = {
  BalancerCard,
  DotPlot: TeamAttributeDotPlot,
  spotlightWidgetComponents: {
    Spotlights,
    SpotlightCardContainer,
    SpotlightCard,
    DonutMetricContainer,
    DonutMetric,
    Title,
  },
  TeamReportAttributeAverageScore: AverageScoreDisplay,
  TeamReportSubsectionTitleContainer: React.Fragment,
  TeamReportSubsectionAttrDescription: React.Fragment,
  TeamReportIntroBorder,
  TeamReportIntroPreHeader,
  TeamReportSubsectionHeaderContainer: React.Fragment,
  TeamReportIntroMainHeader,
  TeamReportIntroTeamDescription,
  TeamReportSubsectionContainer: SubsectionContainer,
  TeamReportSubsectionDivider,
  TeamReportSubsectionHeader,
  TypologyQuadrant,
  // TODO: This will become a real component in the story that adds the content between the quadrant and the balancer card
  TeamReportTypeInterpretation: React.Fragment,
};
