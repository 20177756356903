import {textMdMedium} from '>shared/components/typography/designSystemTypography';
import styled from '@emotion/styled';

export const ModalSubHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.6rem;
`;

export const ModalText = styled.p`
  margin-left: 1.2rem;
  ${textMdMedium}
`;
