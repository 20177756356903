import {createReducer} from '@reduxjs/toolkit';
import {
  Employee,
  HomePageItems,
  IndexMeasures,
  SpotlightMeasures,
  Team,
  User,
  UserData,
} from '>generated/dvp.types';
import {getMyDvpUser} from '../actions/user';
import {
  getHomePageContent,
  getIndexMeasures,
  impersonateEmployee,
  updatePendingOnboardingProgress,
} from '../actions/employee';

export interface ImpersonatedEmployee {
  isImpersonated?: boolean;
}

export interface EmployeeState {
  employee?: Employee & ImpersonatedEmployee;
  indexMeasures?: IndexMeasures;
  homePageItems?: HomePageItems;
  spotlightMeasures?: SpotlightMeasures;
  teams?: Team[]; // The teams that this employee belongs to.
}

const DEFAULT_STATE: Readonly<EmployeeState> = {};

function emptyEmployeeState() {
  return DEFAULT_STATE;
}

function updateEmployee(state: EmployeeState, {payload}: {payload: User}): EmployeeState {
  return {
    ...state,
    employee: payload.employee,
    teams: payload.teams,
  };
}

function impersonate(state: EmployeeState, {payload}: {payload: User}): EmployeeState {
  return {
    ...state,
    employee: payload.employee,
    teams: payload.teams,
    indexMeasures: undefined, // allow indexMeasures to reload for the correct employee when required
  };
}

function updateIndexMeasures(
  state: EmployeeState,
  {payload}: {payload: IndexMeasures}
): EmployeeState {
  return {
    ...state,
    indexMeasures: payload,
  };
}

function updateHomePageContent(
  state: EmployeeState,
  {payload}: {payload: {spotlightMeasures: SpotlightMeasures; homePageItems: HomePageItems}}
) {
  return {
    ...state,
    homePageItems: payload.homePageItems,
    spotlightMeasures: payload.spotlightMeasures,
  };
}

function updateUserData(
  state: EmployeeState,
  {payload}: {payload: UserData | undefined}
): EmployeeState {
  if (payload === undefined || !state.employee) {
    return state;
  }

  return {
    ...state,
    employee: {
      ...state.employee,
      userData: payload,
    },
  };
}

function resetEmployee() {
  return DEFAULT_STATE;
}

// For anyone looking at this reducer as a pattern to copy, please note that the
// builder arrow function must have curly braces around the function.
// If you don't add curly braces around the function, then typescript thinks
// that the actions are part of the reducer type and it creates a circular
// type-reference
// TL;DR: Use (builder) => {builder.addCase(...)}, NOT (builder) => builder.addCase(...)
export const employeeReducer = createReducer<EmployeeState>(DEFAULT_STATE, (builder) => {
  builder
    .addCase(getMyDvpUser.fulfilled, updateEmployee)
    .addCase(impersonateEmployee.fulfilled, impersonate)
    .addCase(getMyDvpUser.rejected, emptyEmployeeState)
    .addCase(getIndexMeasures.fulfilled, updateIndexMeasures)
    .addCase(getMyDvpUser.pending, resetEmployee)
    .addCase(getHomePageContent.fulfilled, updateHomePageContent)
    .addCase(updatePendingOnboardingProgress, updateUserData);
});
