import {Team} from '>generated/dvp.types';

export const MIN_NUM_NON_MANAGERS = 2;
export const MIN_NUM_MANAGERS = 1;
export const MIN_NUM_MEMBERS = MIN_NUM_NON_MANAGERS + MIN_NUM_MANAGERS;

export function isEmployeeInTeam(employeeId: string, team?: Team): boolean {
  if (!team) {
    return false;
  }

  return Boolean(team.members.find((m) => m.employeeId === employeeId));
}

export function isEmployeeInAnyTeam(employeeId: string, teams?: Team[]): boolean {
  if (!teams) {
    return false;
  }

  return teams.some((t) => isEmployeeInTeam(employeeId, t));
}
