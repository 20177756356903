import {createAction} from '@reduxjs/toolkit';

import {assertExists} from 'wnd-util/lib/assert';

import {Permissions, UserRole} from '>generated/dvp.types';
import {assertAccountExists, assertValidAuthState} from '>lib/assert';

import {refreshUserContext} from './user';
import {createAppThunk} from '../thunk';
import {dvpApi, unifiedAccountApi} from '../../apis';

export interface UserUpdateActionParams {
  accountId: string;
  authUserId: string;
  role?: UserRole;
  permissions?: Permissions;
}

export const getAccountById = createAppThunk(
  '@wnd/account/getAccountById',
  async (accountId: string) => {
    const {data} = await dvpApi.getAccountById({accountId});
    return data;
  }
);

export const updateEmployeeTableVersionId = createAction<{employeeTableVersionId: string}>(
  '@wnd/account/updateEmployeeTableVersionId'
);

export const getEmployeeMetrics = createAppThunk(
  '@wnd/account/getEmployeeMetrics',
  async (_, thunkApi) => {
    const {account: accountState, auth: authState} = thunkApi.getState();
    assertValidAuthState(authState);
    assertAccountExists(accountState.account);

    const response = await dvpApi.getEmployeesMetrics({
      accountId: accountState.account.id,
      userId: authState.token.user.id,
    });

    return response.data;
  }
);

export const addUser = createAppThunk(
  '@wnd/account/users/add',
  async (
    {
      accountId,
      email,
      role,
      permissions,
    }: {accountId: string; email: string; role: UserRole; permissions: Permissions},
    _
  ) => {
    const response = await dvpApi.addUser({email, role, permissions}, {accountId});

    return response.data;
  }
);

export const updateUser = createAppThunk(
  '@wnd/account/users/updateUser',
  async ({accountId, authUserId, role, permissions}: UserUpdateActionParams, _) => {
    const response = await dvpApi.updateUser(
      {role, permissions},
      {
        accountId,
        authUserId,
      }
    );

    return response.data;
  }
);

export const deleteUser = createAppThunk(
  '@wnd/account/users/delete',
  async ({accountId, authUserId}: {accountId: string; authUserId: string}, _) => {
    const response = await dvpApi.deleteUser({
      accountId,
      authUserId,
    });

    return response.data;
  }
);

export const resendUserInvitation = createAppThunk(
  '@wnd/account/users/resendInvitation',
  async ({userId}: {userId: string}, thunkApi) => {
    const accountId = thunkApi.getState().account.account?.id;

    assertExists(accountId, 'Must have an account id to send a user invitation');

    await dvpApi.resendUserInvitation({authUserId: userId, accountId});
  }
);

export const selectUnifiedAccount = createAppThunk(
  '@wnd/account/selectUnifiedAccount',
  async (
    {authUserId, unifiedAccountId}: {authUserId: string; unifiedAccountId: string},
    thunkApi
  ) => {
    await unifiedAccountApi.selectUserUnifiedAccount({authUserId, unifiedAccountId});
    const response = await thunkApi.dispatch(refreshUserContext());
    if (refreshUserContext.rejected.match(response)) {
      throw response.payload;
    }
  }
);
