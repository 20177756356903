"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Icons = exports.ICONS_CODEPOINTS = void 0;
var Icons;
(function (Icons) {
  Icons["AccountCircle"] = "account_circle";
  Icons["Add"] = "add";
  Icons["Analytics"] = "analytics";
  Icons["AppSwitcher"] = "app-switcher";
  Icons["ArrowBack"] = "arrow_back";
  Icons["ArrowDropUp"] = "arrow_drop_up";
  Icons["ArrowDropdown"] = "arrow_dropdown";
  Icons["BentoMenu"] = "bento_menu";
  Icons["Book"] = "book";
  Icons["Campaign"] = "campaign";
  Icons["Caret"] = "caret";
  Icons["CheckCircle"] = "check_circle";
  Icons["ChevronRight"] = "chevron_right";
  Icons["Close"] = "close";
  Icons["CloudDownload"] = "cloud_download";
  Icons["CollectionsBookmark"] = "collections_bookmark";
  Icons["Compass"] = "compass";
  Icons["Dashboard"] = "dashboard";
  Icons["Edit"] = "edit";
  Icons["Envelope"] = "envelope";
  Icons["Error"] = "error";
  Icons["FacebookIconMobile"] = "facebook_icon_mobile";
  Icons["FacebookIcon"] = "facebook_icon";
  Icons["FactCheck"] = "fact_check";
  Icons["Filter"] = "filter";
  Icons["FlagIcon"] = "flag_icon";
  Icons["Focus"] = "focus";
  Icons["FolderShared"] = "folder_shared";
  Icons["Gear"] = "gear";
  Icons["HamburgerMenu"] = "hamburger_menu";
  Icons["Info"] = "info";
  Icons["Invite"] = "invite";
  Icons["KebabMenu"] = "kebabMenu";
  Icons["Link"] = "link";
  Icons["LinkedInIconMobile"] = "linked_in_icon_mobile";
  Icons["LinkedInIcon"] = "linked_in_icon";
  Icons["Logout"] = "logout";
  Icons["NoVisibility"] = "no_visibility";
  Icons["OpenInNew"] = "open_in_new";
  Icons["People"] = "people";
  Icons["PersonAdd"] = "person_add";
  Icons["PersonGear"] = "person_gear";
  Icons["PersonOutline"] = "person_outline";
  Icons["Person_24px"] = "person-24px";
  Icons["Phone"] = "phone";
  Icons["QuestionMark"] = "question_mark";
  Icons["Redo"] = "redo";
  Icons["RoleSuccess"] = "role_success";
  Icons["Save"] = "save";
  Icons["Search"] = "search";
  Icons["SendFeedback"] = "send_feedback";
  Icons["Send"] = "send";
  Icons["Star"] = "star";
  Icons["Strengths"] = "strengths";
  Icons["Trash"] = "trash";
  Icons["TwitterIconMobile"] = "twitter_icon_mobile";
  Icons["TwitterIcon"] = "twitter_icon";
  Icons["Upload"] = "upload";
  Icons["Visibility"] = "visibility";
  Icons["Warning"] = "warning";
  Icons["WhatsNew"] = "whats_new";
})(Icons || (exports.Icons = Icons = {}));
const ICONS_CODEPOINTS = exports.ICONS_CODEPOINTS = {
  [Icons.AccountCircle]: '61697',
  [Icons.Add]: '61698',
  [Icons.Analytics]: '61699',
  [Icons.AppSwitcher]: '61700',
  [Icons.ArrowBack]: '61701',
  [Icons.ArrowDropUp]: '61702',
  [Icons.ArrowDropdown]: '61703',
  [Icons.BentoMenu]: '61704',
  [Icons.Book]: '61705',
  [Icons.Campaign]: '61706',
  [Icons.Caret]: '61707',
  [Icons.CheckCircle]: '61708',
  [Icons.ChevronRight]: '61709',
  [Icons.Close]: '61710',
  [Icons.CloudDownload]: '61711',
  [Icons.CollectionsBookmark]: '61712',
  [Icons.Compass]: '61713',
  [Icons.Dashboard]: '61714',
  [Icons.Edit]: '61715',
  [Icons.Envelope]: '61716',
  [Icons.Error]: '61717',
  [Icons.FacebookIconMobile]: '61718',
  [Icons.FacebookIcon]: '61719',
  [Icons.FactCheck]: '61720',
  [Icons.Filter]: '61721',
  [Icons.FlagIcon]: '61722',
  [Icons.Focus]: '61723',
  [Icons.FolderShared]: '61724',
  [Icons.Gear]: '61725',
  [Icons.HamburgerMenu]: '61726',
  [Icons.Info]: '61727',
  [Icons.Invite]: '61728',
  [Icons.KebabMenu]: '61729',
  [Icons.Link]: '61730',
  [Icons.LinkedInIconMobile]: '61731',
  [Icons.LinkedInIcon]: '61732',
  [Icons.Logout]: '61733',
  [Icons.NoVisibility]: '61734',
  [Icons.OpenInNew]: '61735',
  [Icons.People]: '61736',
  [Icons.PersonAdd]: '61737',
  [Icons.PersonGear]: '61738',
  [Icons.PersonOutline]: '61739',
  [Icons.Person_24px]: '61740',
  [Icons.Phone]: '61741',
  [Icons.QuestionMark]: '61742',
  [Icons.Redo]: '61743',
  [Icons.RoleSuccess]: '61744',
  [Icons.Save]: '61745',
  [Icons.Search]: '61746',
  [Icons.SendFeedback]: '61747',
  [Icons.Send]: '61748',
  [Icons.Star]: '61749',
  [Icons.Strengths]: '61750',
  [Icons.Trash]: '61751',
  [Icons.TwitterIconMobile]: '61752',
  [Icons.TwitterIcon]: '61753',
  [Icons.Upload]: '61754',
  [Icons.Visibility]: '61755',
  [Icons.Warning]: '61756',
  [Icons.WhatsNew]: '61757'
};