import React from 'react';

import {assert} from 'wnd-util/lib/assert';
import {ButtonGroup} from '>shared/components/buttonGroup/buttonGroup';
import {inviteIcon, sendRoundedIcon, archive, unarchive} from '>shared/components/icon/icons';
import {ReleaseEmployeeResultsModal} from '>components/modal/releaseEmployeeResults';
import {
  AssessmentStatus,
  EmployeeSelectionMode,
  FilterQuery,
  JobProfileStatus,
} from '>generated/dvp.types';
import {useModal} from '>shared/components/modal/useModal';
import {EmployeeSelection} from '>root/pages/adminEmployees/employeesTable';
import {AssessmentInvitationModal} from '>components/modal/assessmentInvitation/assessmentInvitation';
import {ArchiveEmployeeModal, ArchiveEmployeeModalMode} from '>components/modal/archiveEmployee';
import {SelectFilter} from '>root/pages/adminEmployees/filters/manageAppliedFilters';
import {Tags} from '>root/pages/adminEmployees/filters/employeesFilterModal';

interface Props {
  selection: EmployeeSelection;
  filters?: FilterQuery;
  clearSelection(): void;
  selectFilter: SelectFilter<keyof Tags>;
}

const ALLOWED_BULK_INVITE_STATUSES = [AssessmentStatus.New, AssessmentStatus.Expired];
const ALLOWED_BULK_RELEASE_STATUS = AssessmentStatus.AwaitingResults;

function isShowingAnyArchivedEmployees(statusFilters?: string[]): boolean {
  return Boolean(statusFilters && statusFilters.includes('isArchived'));
}

function isShowingOnlyArchivedEmployees(statusFilters?: string[]): boolean {
  return Boolean(statusFilters?.length === 1 && statusFilters[0] === 'isArchived');
}

function isBulkInviteEnabledForInclusiveMode(selection: EmployeeSelection): boolean {
  const {activeEmployees} = selection;

  // No checkboxes are checked
  if (activeEmployees.size === 0) {
    return false;
  }

  return Array.from(activeEmployees.values()).every(
    (selectedEmployee) =>
      selectedEmployee.status && ALLOWED_BULK_INVITE_STATUSES.includes(selectedEmployee.status)
  );
}

function isBulkInviteEnabledForExclusiveMode(
  selectionCount: number,
  selection: EmployeeSelection,
  statusFilters?: string[]
): boolean {
  if (!statusFilters || selectionCount === 0) {
    return false;
  }

  const activeIds = Array.from(selection.activeEmployees.keys());

  // Every filter applied has to be one of the ALLOWED_BULK_INVITE_STATUSES
  const canInviteAll = selection.visibleRows
    ? selection.visibleRows
        ?.filter((row) => !activeIds.includes(row.employee.id))
        .every((employee) => {
          return ALLOWED_BULK_INVITE_STATUSES.includes(employee.employee.lastAssessmentStatus);
        })
    : statusFilters.every((filter) =>
        ALLOWED_BULK_INVITE_STATUSES.some((allowedStatus) => filter === allowedStatus)
      );

  return canInviteAll;
}

function isBulkReleaseEnabledForInclusiveMode(selection: EmployeeSelection): boolean {
  const {activeEmployees} = selection;

  // No checkboxes are checked
  if (activeEmployees.size === 0) {
    return false;
  }

  return Array.from(activeEmployees.values()).every(
    (selectedEmployee) =>
      selectedEmployee.status === ALLOWED_BULK_RELEASE_STATUS &&
      selectedEmployee.jobStatus === JobProfileStatus.Success
  );
}

function isBulkReleaseEnabledForExclusiveMode(
  selectionCount: number,
  selection: EmployeeSelection,
  statusFilters?: string[]
): boolean {
  if (!statusFilters || selectionCount === 0) {
    return false;
  }

  const activeIds = Array.from(selection.activeEmployees.keys());
  const canReleaseAll = selection.visibleRows
    ? selection.visibleRows
        ?.filter((row) => !activeIds.includes(row.employee.id))
        .every(
          (employee) =>
            employee.employee.lastAssessmentStatus === ALLOWED_BULK_RELEASE_STATUS &&
            employee.employee.jobProfileStatus === JobProfileStatus.Success
        )
    : statusFilters.length === 1 && statusFilters[0] === ALLOWED_BULK_RELEASE_STATUS;

  return canReleaseAll;
}

function isBulkReleaseEnabled(selection: EmployeeSelection, statusFilters?: string[]): boolean {
  if (isShowingAnyArchivedEmployees(statusFilters)) {
    return false;
  }

  return selection.selectionMode === EmployeeSelectionMode.Inclusive
    ? isBulkReleaseEnabledForInclusiveMode(selection)
    : isBulkReleaseEnabledForExclusiveMode(selection.selectionCount, selection, statusFilters);
}

function isBulkInviteEnabled(selection: EmployeeSelection, statusFilters?: string[]): boolean {
  if (isShowingAnyArchivedEmployees(statusFilters)) {
    return false;
  }

  return selection.selectionMode === EmployeeSelectionMode.Inclusive
    ? isBulkInviteEnabledForInclusiveMode(selection)
    : isBulkInviteEnabledForExclusiveMode(selection.selectionCount, selection, statusFilters);
}

function isBulkArchiveEnabled(selection: EmployeeSelection, statusFilters?: string[]): boolean {
  if (isShowingAnyArchivedEmployees(statusFilters)) {
    return false;
  }

  return selection.selectionCount > 0;
}

function isBulkUnArchiveEnabled(selection: EmployeeSelection, statusFilters?: string[]): boolean {
  return isShowingOnlyArchivedEmployees(statusFilters) && selection.selectionCount > 0;
}

function assertSelectionExists(selection: EmployeeSelection): void {
  if (selection.selectionMode === EmployeeSelectionMode.Inclusive) {
    assert(selection.activeEmployees.size !== 0, `Selection does not contain any employees`);
  }
}

interface TagOption {
  fieldName: keyof Tags;
  label: string;
  value: string;
}

const archiveTag: TagOption = {
  fieldName: 'assessmentStatuses',
  label: 'Archived',
  value: 'isArchived',
};

export const EmployeeTableActionButtonGroup: React.FC<Props> = ({
  selection,
  filters,
  clearSelection,
  selectFilter,
}) => {
  const {showModal} = useModal();

  const filterByArchived = React.useCallback(() => {
    const addFn = selectFilter.addToField(archiveTag.fieldName);
    addFn({label: archiveTag.label, value: archiveTag.value});
  }, [selectFilter]);

  const removeArchivedFilter = React.useCallback(() => {
    selectFilter.removeFromField(archiveTag);
  }, [selectFilter]);

  return (
    <ButtonGroup
      options={[
        {
          icon: inviteIcon,
          hideText: true,
          text: 'Send Invitation',
          onClick: () => {
            assertSelectionExists(selection);
            showModal(
              <AssessmentInvitationModal
                employeeSelection={selection}
                filterQuery={filters}
                onInvite={clearSelection}
              />
            );
          },
          hide: !isBulkInviteEnabled(selection, filters?.assessmentStatuses),
        },
        {
          icon: sendRoundedIcon,
          hideText: true,
          text: 'Release Results',
          onClick: () => {
            assertSelectionExists(selection);
            showModal(
              <ReleaseEmployeeResultsModal
                employeeSelection={selection}
                filterQuery={filters}
                onRelease={clearSelection}
              />
            );
          },
          hide: !isBulkReleaseEnabled(selection, filters?.assessmentStatuses),
        },
        {
          icon: archive,
          hideText: true,
          text: 'Archive',
          onClick: () => {
            assertSelectionExists(selection);
            showModal(
              <ArchiveEmployeeModal
                employeeSelection={selection}
                filterQuery={filters}
                mode={ArchiveEmployeeModalMode.Archive}
                onArchiveChange={() => {
                  filterByArchived();
                }}
              />
            );
          },
          hide: !isBulkArchiveEnabled(selection, filters?.assessmentStatuses),
        },
        {
          icon: unarchive,
          hideText: true,
          text: 'Unarchive',
          onClick: () => {
            assertSelectionExists(selection);
            showModal(
              <ArchiveEmployeeModal
                employeeSelection={selection}
                filterQuery={filters}
                mode={ArchiveEmployeeModalMode.UnArchive}
                onArchiveChange={() => {
                  removeArchivedFilter();
                }}
              />
            );
          },
          hide: !isBulkUnArchiveEnabled(selection, filters?.assessmentStatuses),
        },
      ]}
    />
  );
};
