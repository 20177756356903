import * as React from 'react';
import {ValueType} from 'react-select';
import {Option, SelectVariant} from '>shared/components/form/rawControls/rawSelect';
import {vr4} from '>styles/mixins/verticalRhythm';
import {
  CreateMeasureContainer,
  ChapterSelectDropdown,
  CreateMeasureButton,
  IndexHeader,
  CmsHeading,
} from './styles';
import {ChapterItem, ChapterItemType, Measure} from '>generated/dvp.types';
import {ButtonSizes, ButtonType} from '>shared/components/button/button';
import {Icons} from '>styles/icon-types';
import {FontIcon} from '>shared/components/icon/icon';
import {useAppDispatch} from '>root/store/main';
import {fetchChapterContent} from '>root/store/actions/chapter';
import {useSelector} from 'react-redux';
import {CmsItem, CmsNavCategory, CmsNavigation, CmsNavItems} from './cmsNavigation';
import {ChapterCategory} from 'wnd-dvp-reports';

const NAVIGATION_INDEX_TITLE = 'Develop Contents';
const CHAPTER_TITLE_PREFIX = 'Section';

export const chapterDropdownOptions: Option[] = [
  {label: `${CHAPTER_TITLE_PREFIX} 1: Getting Things Done`, value: '1'},
  {label: `${CHAPTER_TITLE_PREFIX} 2: People`, value: '2'},
  {label: `${CHAPTER_TITLE_PREFIX} 3: Problems`, value: '3'},
  {label: `${CHAPTER_TITLE_PREFIX} 4: Change`, value: '4'},
  {label: `${CHAPTER_TITLE_PREFIX} 5: Learning`, value: '5'},
];

interface Props {
  chapterItem?: ChapterItem;
  chapterNumber: string;
  onChapterChanged(value: ValueType<Option, false>): void;
  onCreateMeasure: () => void;
  onChapterItemSelected: (chapterItemId: string) => void;
}

export const NavigationIndex: React.FC<Props> = ({
  chapterItem,
  chapterNumber,
  onChapterChanged,
  onCreateMeasure,
  onChapterItemSelected,
}) => {
  const chapterState = useSelector((state) => state.chapter);
  const dispatch = useAppDispatch();

  const getChapterContent = React.useCallback(async () => {
    await dispatch(fetchChapterContent(chapterNumber));
  }, [chapterNumber]);

  React.useEffect(() => {
    getChapterContent();
  }, [chapterNumber]);

  const navItemList: CmsNavItems = React.useMemo(() => {
    function navItemsByCategory(category: ChapterCategory): [CmsNavCategory] | [] {
      const items = chapterState.chapter.measures
        .filter((item) => {
          const measure = item.content as Measure;
          return measure.category === category;
        })
        .map(chapterItemToNavItem);

      if (items.length) {
        return [{title: category, items}];
      } else {
        return [];
      }
    }

    return {
      topLevelItems: [chapterItemToNavItem(chapterState.chapter.introduction)],
      categories: [
        ...navItemsByCategory(ChapterCategory.Behaviors),
        ...navItemsByCategory(ChapterCategory.Capabilities),
        ...navItemsByCategory(ChapterCategory.Interests),
      ],
    };
  }, [chapterState]);

  return (
    <>
      <IndexHeader>
        <CmsHeading>{NAVIGATION_INDEX_TITLE}</CmsHeading>
        <CreateMeasureContainer>
          <ChapterSelectDropdown
            css={vr4}
            name="chapterSelectDropdown"
            options={chapterDropdownOptions}
            /* @ts-ignore */
            defaultValue={chapterDropdownOptions[0]}
            onSelectChange={onChapterChanged}
            variant={SelectVariant.alternate}
          />
          <CreateMeasureButton
            data-qa-button="create-measure-button"
            onClick={onCreateMeasure}
            buttonType={ButtonType.Tertiary}
            size={ButtonSizes.XL}
          >
            <FontIcon icon={Icons.Add} />
          </CreateMeasureButton>
        </CreateMeasureContainer>
      </IndexHeader>
      <CmsNavigation
        onNavItemSelected={onChapterItemSelected}
        selectedNavItemId={chapterItem?.id}
        navItemList={navItemList}
      />
    </>
  );
};

function chapterItemToNavItem(chapterItem: ChapterItem): CmsItem {
  return {
    id: chapterItem.id,
    isPublished: chapterItem.isPublished,
    title:
      chapterItem.type === ChapterItemType.Introduction
        ? 'Introduction'
        : (chapterItem.content as Measure).baseName,
    label:
      chapterItem.type === ChapterItemType.Introduction
        ? 'Required*'
        : (chapterItem.content as Measure).sourceConstruct ?? '',
  };
}
