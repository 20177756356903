"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Modal = exports.ActionType = void 0;
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var colors = _interopRequireWildcard(require("wnd-themes/lib/colorPalette"));
var _reactModal = _interopRequireDefault(require("react-modal"));
var _assert = require("wnd-util/lib/assert");
var _modalLayout = require("./modalLayout");
var _modal = require("./modal.styles");
var _button = require("../button/button");
var _spinner = require("../spinner");
var _useModal = require("./useModal");
var _delayRender = require("../util/delayRender");
var _icons = require("../icon/icons");
var _icon = require("../icon/icon");
var _displayType = require("./displayType");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var ActionType;
(function (ActionType) {
  ActionType[ActionType["Primary"] = 0] = "Primary";
  ActionType[ActionType["Secondary"] = 1] = "Secondary";
})(ActionType || (exports.ActionType = ActionType = {}));
const ModalHeader = props => {
  const {
    title,
    titleColor,
    titleCenter,
    emphasized,
    hideDismissButton,
    icon,
    iconColor,
    disabled,
    padding,
    subtitle,
    isFullscreen
  } = props;
  const {
    hideModal
  } = (0, _useModal.useModal)();
  const titleMargin = icon ? '1.2rem' : '0';
  const marginCss = titleCenter ? {
    margin: 'auto'
  } : {
    marginLeft: titleMargin
  };
  const getHeaderIcon = React.useCallback(icon => {
    return typeof icon === 'string' ? (0, _jsxRuntime.jsx)(_icon.Icon, {
      src: icon,
      primaryColor: iconColor
    }) : icon;
  }, [icon]);
  return (0, _jsxRuntime.jsxs)(_modal.ModalHeaderContainer, {
    emphasized: emphasized,
    padding: padding,
    children: [(0, _jsxRuntime.jsxs)(_modal.TitleContainer, {
      children: [icon && getHeaderIcon(icon), (0, _jsxRuntime.jsxs)("div", {
        children: [(0, _jsxRuntime.jsx)(_modal.Title, {
          "data-qa-text": "modalTitle",
          titleColor: titleColor,
          css: marginCss,
          children: title
        }), subtitle && (0, _jsxRuntime.jsx)(_modal.Subtitle, {
          css: marginCss,
          children: subtitle
        })]
      })]
    }), !hideDismissButton && (0, _jsxRuntime.jsx)(_modal.CloseButtonSection, {
      shouldMoveOutsideModal: isFullscreen,
      children: (0, _jsxRuntime.jsx)(_modal.CloseButton, {
        buttonLabel: "Dismiss Modal",
        "data-qa-button": "dismissModal",
        onClick: hideModal,
        children: (0, _jsxRuntime.jsx)(_icon.Icon, {
          src: _icons.roundedCloseIcon,
          primaryColor: isFullscreen ? colors.white : colors.gray500
        })
      })
    })]
  });
};
const SPINNER_SIZE = 24;
const Modal = props => {
  const {
    children,
    actions,
    isLocked,
    displayType = _displayType.ModalDisplayType.Standard,
    headerOptions,
    actionsCentered,
    actionBackgroundColor
  } = props;
  const rootElement = document.getElementById('root');
  (0, _assert.assertExists)(rootElement, 'Modal component must be contained in an element with the id "root"');
  _reactModal.default.setAppElement(rootElement);
  if (actions) {
    const primaryActions = actions.filter(action => action.type === ActionType.Primary);
    (0, _assert.assertMaxLength)(primaryActions, 1, 'Modal component should only have one primary action');
  }
  const primaryAction = actions?.find(action => action.type === ActionType.Primary);
  const secondaryActions = actions?.filter(action => action.type === ActionType.Secondary);
  const {
    hideModal
  } = (0, _useModal.useModal)();
  const showHeader = React.useMemo(() => {
    return headerOptions?.title || !headerOptions?.hideDismissButton;
  }, [headerOptions?.title, headerOptions?.hideDismissButton]);
  const isFullscreenModal = displayType === _displayType.ModalDisplayType.Wide;
  return (0, _jsxRuntime.jsx)(_modal.StyledModal, {
    contentLabel: `${headerOptions?.title ?? ''} Modal`,
    isOpen: true,
    displayType: displayType,
    style: isFullscreenModal ? _modal.fullscreenModalStyle : _modal.modalStyle,
    shouldCloseOnOverlayClick: !isLocked,
    shouldCloseOnEsc: !isLocked,
    onRequestClose: () => {
      if (!isLocked) {
        hideModal();
      }
    },
    children: (0, _jsxRuntime.jsx)(_modalLayout.ModalLayout, {
      actionsCentered: actionsCentered,
      actionBackgroundColor: actionBackgroundColor,
      content: children,
      disablePadding: isFullscreenModal,
      header: showHeader && (0, _jsxRuntime.jsx)(ModalHeader, _objectSpread(_objectSpread({}, headerOptions), {}, {
        isFullscreen: isFullscreenModal
      })),
      primaryAction: primaryAction && (primaryAction.component ? primaryAction.component : (0, _jsxRuntime.jsxs)(_button.Button, {
        "data-qa-button": primaryAction.qaDataAttribute,
        disabled: primaryAction.disabled || isLocked,
        type: primaryAction.inputType,
        onClick: primaryAction.onClick,
        buttonType: primaryAction.buttonType ?? _button.ButtonType.Primary,
        children: [isLocked && (0, _jsxRuntime.jsx)(_delayRender.DelayRender, {
          delayInSeconds: 2,
          children: (0, _jsxRuntime.jsx)(_spinner.Spinner, {
            size: SPINNER_SIZE
          })
        }), primaryAction.label]
      })),
      secondaryActions: secondaryActions?.map(action => {
        return action.component ? action.component : (0, _jsxRuntime.jsx)(_button.Button, {
          "data-qa-button": action.qaDataAttribute,
          buttonType: _button.ButtonType.Neutral,
          disabled: action.disabled || isLocked,
          type: action.inputType,
          onClick: action.onClick,
          children: action.label
        }, action.label);
      })
    })
  });
};
exports.Modal = Modal;