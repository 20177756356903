import * as React from 'react';
import {vr6} from '>styles/mixins/verticalRhythm';
import {ActionItemFormFields} from './formFields';
import {FormProvider, UseFormMethods} from 'react-hook-form';
import {displayXsMedium} from '>shared/components/typography/designSystemTypography';
import {Heading} from '>shared/components/heading/heading';
import {get} from 'lodash';
import {ActionItemStatus} from '>generated/dvp.types';

export const defaultActionFormValues: ActionItemFormFields = {
  status: ActionItemStatus.ToDo,
  title: '',
  attribute: '',
  color: undefined,
  dueDate: '',
  description: '',
  improve: '',
  firstStep: '',
  howToMeasure: '',
};

interface ActionItemFormProps {
  title: string;
  formMethods: UseFormMethods<ActionItemFormFields>;
  selectableAttributes: string[];
  isFromTemplate: boolean;
}

export function convertToUserFriendlyFieldName(fieldName: string): string {
  const words = fieldName.split(/(?=[A-Z])/g);
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(' ');
}

export const ActionItemForm: React.FC<ActionItemFormProps> = ({
  title,
  formMethods,
  selectableAttributes,
  isFromTemplate,
}) => {
  return (
    <>
      <Heading css={[vr6, displayXsMedium]}>{title}</Heading>
      <FormProvider {...formMethods}>
        <ActionItemFormFields
          selectableAttributes={selectableAttributes}
          isFromTemplate={isFromTemplate}
        />
      </FormProvider>
    </>
  );
};

export function validateFormForPublish(formMethods: UseFormMethods<ActionItemFormFields>): boolean {
  const formFieldsRecord = formMethods.getValues() as Record<string, any>;
  const formFieldKeys = Object.keys(formFieldsRecord);
  let isFormValid = true;

  const optionalFields = [
    'dueDate',
    'color',
    'description',
    'improve',
    'firstStep',
    'howToMeasure',
  ];

  const fieldLimits = {
    title: {min: 3},
  };

  formFieldKeys.forEach((formFieldKey) => {
    let thisFieldValid = true;
    if (optionalFields.includes(formFieldKey)) {
      return;
    }
    const value = formFieldsRecord[formFieldKey];

    if (value === '' || value === undefined) {
      isFormValid = false;
      thisFieldValid = false;

      const userFriendlyFieldName = convertToUserFriendlyFieldName(formFieldKey);

      formMethods.setError(formFieldKey as never, {
        type: `${formFieldKey}-error`,
        message: `${userFriendlyFieldName} is required`,
      });
    }

    if (thisFieldValid) {
      const limits = get(fieldLimits, formFieldKey);
      if (limits !== undefined) {
        const charCount = value.length;
        if (charCount < limits.min) {
          isFormValid = false;
          const userFriendlyFieldName = convertToUserFriendlyFieldName(formFieldKey);

          formMethods.setError(formFieldKey as never, {
            type: `${formFieldKey}-error`,
            message: `${userFriendlyFieldName} should contain at least ${limits.min} characters`,
          });
        }
      }
    }
  });

  return isFormValid;
}
