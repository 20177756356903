import * as React from 'react';
import {AssessmentStatus, EmployeesMetrics} from '>generated/dvp.types';
import {SpotlightCardContainer, Widgets} from './employeesMetricsWidget.styles';
import {AppliedFilter, Filters} from '>root/pages/adminEmployees/filters/manageAppliedFilters';
import {AppliedFilterTags, Tags} from '>root/pages/adminEmployees/filters/employeesFilterModal';
import {assessmentStatusToUserFriendly} from '>components/statusBadges';
import {
  EmployeeMetric,
  EmployeeMetricCount,
  EmployeeMetricsWidget,
  EmployeeMetricVariant,
} from '>components/employeeTable/employeeMetricsWidget';

interface Props {
  filters: Filters;
  appliedTags: Array<AppliedFilter<keyof Tags>>;
  applyFiltersAndTags: (filters: Filters, appliedTags: AppliedFilterTags) => void;
  metrics: EmployeeMetric[];
}

export const EmployeesMetricsWidget: React.FC<Props> = ({
  applyFiltersAndTags,
  filters,
  appliedTags,
  metrics,
}) => {
  const isActive = (metric: EmployeeMetricVariant) =>
    Boolean(appliedTags.find((tag) => tag.value === metric));

  const applyStatus = React.useCallback(
    (newStatus: EmployeeMetricVariant) => {
      // reset all active assessmentStatus tags and filters

      if (newStatus === EmployeeMetricCount.TOTAL) {
        const newTags = appliedTags.filter((tag) => tag.fieldName !== 'assessmentStatuses');
        applyFiltersAndTags(
          {
            ...filters,
            assessmentStatuses: [],
          },
          newTags
        );
      } else if (isActive(newStatus)) {
        // remove this assessmentStatus from active tags and filters

        const newTags = appliedTags.filter((tag) => tag.value !== newStatus);
        const newFilters = filters.assessmentStatuses?.filter((fil) => fil !== newStatus);

        applyFiltersAndTags(
          {
            ...filters,
            assessmentStatuses: newFilters,
          },
          newTags
        );
      } else {
        // add this assessmentStatus from active tags and filters

        const newTag: AppliedFilter<keyof Tags> = {
          fieldName: 'assessmentStatuses',
          label: assessmentStatusToUserFriendly((newStatus as unknown) as AssessmentStatus),
          value: newStatus,
        };
        const newFilters = filters.assessmentStatuses?.concat([newStatus]);

        applyFiltersAndTags(
          {
            ...filters,
            assessmentStatuses: newFilters,
          },
          appliedTags.concat(newTag)
        );
      }
    },
    [applyFiltersAndTags, appliedTags, filters]
  );

  return (
    <Widgets>
      {metrics.map((metric: EmployeeMetric) => {
        return (
          <SpotlightCardContainer
            key={`${metric.label}`}
            onClick={() => applyStatus(metric.variant)}
          >
            <EmployeeMetricsWidget isApplied={isActive(metric.variant)} {...metric} />
          </SpotlightCardContainer>
        );
      })}
    </Widgets>
  );
};

export function convertToUserFriendlyMetrics(metrics: EmployeesMetrics): EmployeeMetric[] {
  const {assessmentMetrics} = metrics;

  return [
    {
      count: metrics.employeeCount,
      label: 'Employee Records',
      shaded: true,
      variant: EmployeeMetricCount.TOTAL,
    },
    {
      count: assessmentMetrics.needsInviteCount,
      label: 'Ready to Test',
      variant: AssessmentStatus.New,
    },
    {
      count: assessmentMetrics.activeCount,
      label: 'In Progress',
      variant: AssessmentStatus.InProgress,
    },
    {
      count: assessmentMetrics.expiredCount,
      label: 'Expired',
      variant: AssessmentStatus.Expired,
    },
    {
      count: assessmentMetrics.awaitingResultsCount,
      label: 'Pending Release',
      variant: AssessmentStatus.AwaitingResults,
    },
    {
      count: assessmentMetrics.resultsReleasedCount,
      label: 'Results Released',
      variant: AssessmentStatus.ResultsReleased,
    },
  ];
}
