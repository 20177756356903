/*
    DO NOT MANUALLY MODIFY THIS FILE. THIS FILE HAS BEEN AUTOMATICALLY GENERATED.
    
    If anything in here needs fixing, address those issues in wnd-swagger-ts.
*/
/* eslint-disable */

export interface Account {
  id: string;
  unifiedAccountId: string;
  billingAccountId: string;
  employeeCommitInProgress: boolean;
  employeeTableVersionId: string;
  dateCreated: string;
  userRolePermissions: {[key: string]: Permissions};
  status: AccountStatus;
  subStatus?: AccountSubStatus;
  isTrial?: boolean;
  hasUsedTrial?: boolean;
  enableRegressionTesting?: boolean;
  enableAdaptiveMotivation?: boolean;
  enableTeams?: boolean;
  dateOfBillingOverride?: string;
  billingOverrideHistory?: Array<BillingOverrideHistory>;
}

export interface OnboardingProgress {
  isCompleted: boolean;
  finishedSections?: Array<string>;
}

export interface UserData {
  onboardingProgress?: OnboardingProgress;
}

export interface Employee {
  id: string;
  userData?: UserData;
  ephemeralId: string;
  firstInterpretationBucketVersionSeen?: string;
  externalId?: string;
  authUserId?: string;
  accountId: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  email: string;
  searchString: string;
  department: string;
  jobTitle: string;
  lastAssessmentStatus: AssessmentStatus;
  location?: string;
  managerName?: string;
  dateOfLastAssessmentSentDate?: string;
  dateCreated: string;
  dateOfLastStatusUpdate?: string;
  dateOfCookiesConsent?: string;
  isDeleted?: boolean;
  isArchived?: boolean;
  isAccommodationsMode?: boolean;
  jobProfileStatus: JobProfileStatus;
  preferredLanguage?: AssessmentLanguage;
  jobProfileAuditHistory?: Array<JobProfileAudit>;
  administrations?: Array<Administration>;
  autoRelease?: boolean;
  autoReleaseAuthorizedBy?: string;
  results?: EmployeeResults;
  jobLevel?: JobLevel;
  resultsShareLogs?: Array<ResultsShareLog>;
  accountChangedLogs?: Array<AccountChangedLog>;
}

export interface EmployeeNames {
  id: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
}

export interface Administration {
  product: {id: string};
  workflow: {id: string; activityNumber: number};
  language: string;
  scales: Array<Scale>;
  dateCreated: string;
}

export interface Scale {
  name: string;
  rawScore: number;
  percentileScore?: number;
  weight?: number;
}

export interface JobProfileAudit {
  dateCreated: string;
  jobTitle: string;
  department: string;
  processedJobTitle?: string;
  job2vecVersion?: string;
  jobProfileStatus: JobProfileStatus;
}

export enum JobProfileStatus {
  Pending = 'Pending',
  Fetching = 'Fetching',
  Success = 'Success',
  Failure = 'Failure',
}

export interface StagedEmployee {
  id: string;
  commitId: string;
  externalId: string;
  accountId: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  email: string;
  department: string;
  jobTitle: string;
  location?: string;
  managerName?: string;
  dateCreated: string;
}

export interface NewAccount {
  authUserId: string;
  unifiedAccountId: string;
}

export interface NewEmployee {
  externalId?: string;
  accountId: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  email: string;
  jobTitle: string;
  department: string;
  location?: string;
  managerName?: string;
  isAccommodationsMode?: boolean;
}

export type EmployeePreview = NewEmployee & {externalId: string};

export interface UpdateEmployee {
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  email?: string;
  jobTitle?: string;
  department?: string;
  location?: string;
  managerName?: string;
  externalId?: string;
  isDeleted?: boolean;
  isAccommodationsMode?: boolean;
  lastAssessmentStatus?: AssessmentStatus;
}

export interface FileImportError {
  rowIndex?: number;
  columnName?: string;
  userFacingMessage: string;
  errorCode: FileImportErrorCode;
}

export interface BulkImportCommitResult {
  errors?: Array<FileImportError>;
  employeesAdded?: number;
  employeesUpdated?: number;
  employeeMergeInserted?: number;
  employeeMerged?: number;
  employeeTableVersionId?: string;
}

export interface BulkImportStageResult {
  errors?: Array<FileImportError>;
  commitId?: string;
  employeesToBeAdded?: number;
  employeesToBeUpdated?: number;
  previewEmployees?: Array<EmployeePreview>;
}

export interface BulkEmployeeDeleteResult {
  employeeTableVersionId?: string;
  employeesDeleted?: number;
}

export interface BulkEmployeeArchiveResult {
  employeeTableVersionId?: string;
  employeesArchived?: number;
}

export interface BulkEmployeeUnArchiveResult {
  employeeTableVersionId?: string;
  employeesUnArchived?: number;
}

export interface BulkActionItemTemplateDeleteResult {
  templatesDeleted?: number;
}

export interface Employees {
  employeeCount: number;
  employees: Array<Employee>;
  tags?: Tags;
}

export interface EmployeesToolkitSearch {
  employees: Array<{
    id: string;
    firstName: string;
    lastName: string;
    preferredName?: string;
    email: string;
    jobTitle: string;
    scoringProfile?: string;
    lastAssessmentStatus: AssessmentStatus;
    unifiedAccountId?: string;
    companyName?: string;
    dateOfLastStatusUpdate?: string;
  }>;
}

export interface EmployeesMetrics {
  employeeCount: number;
  assessmentMetrics: AssessmentMetrics;
}

export interface AssessmentMetrics {
  needsInviteCount: number;
  activeCount: number;
  expiredCount: number;
  resultsReleasedCount: number;
  awaitingResultsCount: number;
}

export interface Tags {
  departments: {[key: string]: number};
  jobTitles: {[key: string]: number};
  locations: {[key: string]: number};
  assessmentStatuses: {[key: string]: number};
}

export enum FileImportErrorCode {
  FileBadFormat = 'fileBadFormat',
  FileConflict = 'fileConflict',
  FileRequired = 'fileRequired',
  FileInvalidLength = 'FileInvalidLength',
  FileInvalidEmail = 'fileInvalidEmail',
  FileInvalidDepartment = 'fileInvalidDepartment',
  FileEmpty = 'fileEmpty',
  DatabaseConflict = 'databaseConflict',
  GeneralServerError = 'generalServerError',
}

export enum EmployeeSortField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  JobTitle = 'jobTitle',
  Department = 'department',
  Location = 'location',
  DateOfLastStatusUpdate = 'dateOfLastStatusUpdate',
  DateOfLastAssessmentSentDate = 'dateOfLastAssessmentSentDate',
  LastAssessmentStatus = 'lastAssessmentStatus',
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface AccountUser {
  dateCreated: string;
  role: UserRole;
  permissions: Permissions;
  accessibleDepartments?: Array<string>;
  accessibleTeams?: Array<string>;
}

export interface User {
  accountUser?: AccountUser;
  employee?: Employee;
  teams?: Array<Team>;
}

export type AccountUserWithPII = AccountUser & {
  firstName?: string;
  lastName?: string;
  email: string;
  isVerified: boolean;
  isPending: boolean;
};

export interface NewUser {
  email: string;
  role: UserRole;
  permissions?: Permissions;
  accessibleDepartments?: Array<string>;
}

export interface UserUpdate {
  permissions?: Permissions;
  role?: UserRole;
  teamIdToAccess?: string;
}

export enum UserRole {
  Owner = 'owner',
  AccountAdmin = 'accountAdmin',
  TeamAdmin = 'teamAdmin',
  Coach = 'coach',
}

export interface Permissions {
  canBulkUploadEmployees?: boolean;
  canManageUsers?: boolean;
  canManageBilling?: boolean;
}

export interface Workflow {
  id: string;
  accountId: string;
  employeeId: string;
  remoteWorkflowId: string;
  activities: Array<WorkflowActivity>;
  emailReceipts: Array<EmailReceipt>;
  dateCreated: string;
  dateExpired: string;
  status: WorkflowStatus;
  launchUrl: string;
  reminderSent?: boolean;
  selectedLanguage?: AssessmentLanguage;
  initiatingUser: InitiatingUser;
}

export interface WorkflowActivity {
  productId: string;
  status: WorkflowActivityStatus;
  sessionId?: string;
  hideIntermissionAfter?: boolean;
  hideIntermissionBefore?: boolean;
  hideInActivityList?: boolean;
}

export enum AssessmentStatus {
  New = 'New',
  Invited = 'Invited',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Expiring = 'Expiring',
  Expired = 'Expired',
  AwaitingResults = 'AwaitingResults',
  ResultsReleased = 'ResultsReleased',
}

export enum AssessmentLanguage {
  English = 'English',
  French = 'French',
  Spanish = 'Spanish',
}

export enum NewWorkflowStatus {
  NotStarted = 'NotStarted',
  Started = 'Started',
  Completed = 'Completed',
}

export enum WorkflowStatus {
  NotStarted = 'NotStarted',
  Started = 'Started',
  Completed = 'Completed',
  Expired = 'Expired',
}

export enum WorkflowActivityStatus {
  NotStarted = 'NotStarted',
  ReadyToStart = 'ReadyToStart',
  Started = 'Started',
  Completed = 'Completed',
}

export interface InitiatingUser {
  id: string;
  name: string;
  email: string;
}

export interface EmailReceipt {
  email: string;
  dateSent: string;
}

export interface EmailEventFailure {
  severity: string;
  reason: string;
}

export interface EmailEvent {
  name: string;
  recipient?: string;
  dateOccurred: string;
  failure?: EmailEventFailure;
}

export interface NotificationEvent {
  event: string;
  value?: Array<EmailEvent> | string;
}

export interface Notification {
  referenceId?: string;
  messageType?: string;
  entityType: string;
  entityId: string;
  timestamp: string;
  events: Array<NotificationEvent>;
}

export interface WorkflowStatusCheck {
  status: WorkflowStatus;
  launchUrl?: string;
}

export interface ActivityStatusCheck {
  status: WorkflowActivityStatus;
  launchUrl?: string;
}

export interface BulkEmployeeSelection {
  employeeSelectionMode: EmployeeSelectionMode;
  employeeIds: Array<string>;
  filterQuery?: FilterQuery;
  autoRelease?: boolean;
}

export interface BulkActionItemTemplateSelection {
  templateIds: Array<string>;
}

export interface LanguageSelection {
  language: AssessmentLanguage;
}

export interface FilterQuery {
  searchText?: string;
  departments?: Array<string>;
  jobTitles?: Array<string>;
  locations?: Array<string>;
  assessmentStatuses?: Array<string>;
}

export enum EmployeeSelectionMode {
  Inclusive = 'inclusive',
  Exclusive = 'exclusive',
}

export interface NewHomePageItem {
  type: HomePageItemType;
  content: HomePageItemContent;
  isPublished: boolean;
  variables?: string;
}

export interface ActionPlannerWelcomeItemUpdate {
  content: ActionPlannerWelcomeItemContent;
  isPublished: boolean;
  variables?: string;
}

export interface HomePageItemUpdate {
  type: HomePageItemType;
  content: HomePageItemContent;
  isPublished: boolean;
  variables?: string;
}

export interface NewChapterItem {
  type: ChapterItemType;
  chapterNumber: string;
  content: Measure | Introduction;
  isPublished: boolean;
}

export enum ActionItemStatus {
  ToDo = 'To Do',
  Completed = 'Completed',
  Archived = 'Archived',
  Committed = 'Committed',
}

export interface UpdateActionItem {
  id: string;
  title?: string;
  status?: ActionItemStatus;
  attribute?: string;
  dueDate?: string;
  color?: 'Orange' | 'Purple' | 'Blue' | 'Green' | 'Red' | 'Grey' | 'Indigo';
  taskType?: string;
  start?: number;
  finish?: number;
  current?: number;
  description?: string;
  improve?: string;
  firstStep?: string;
  howToMeasure?: string;
  employeeId: string;
  accountId: string;
}

export interface NewActionItem {
  title: string;
  status: ActionItemStatus;
  attribute: string;
  dueDate?: string;
  color?: 'Orange' | 'Purple' | 'Blue' | 'Green' | 'Red' | 'Grey' | 'Indigo';
  taskType?: string;
  start?: number;
  finish?: number;
  current?: number;
  description?: string;
  improve?: string;
  firstStep?: string;
  howToMeasure?: string;
  employeeId: string;
  accountId: string;
  templateId?: string;
}

export interface NewTemplatedActionItem {
  title: string;
  description: string;
  employeeId: string;
  accountId: string;
  templateId: string;
}

export type ActionItem = NewActionItem & {id: string; dateCreated: string; dateLastUpdated: string};

export type ActionItemTemplates = Array<ActionItemTemplate>;

export type ActionItems = Array<ActionItem>;

export interface NewActionPlannerWelcomeItem {
  content: ActionPlannerWelcomeItemContent;
  isPublished: boolean;
  variables?: string;
}

export type ActionPlannerWelcomeItem = NewActionPlannerWelcomeItem & {
  id: string;
  dateCreated: string;
};

export type HomePageItem = NewHomePageItem & {id: string; dateCreated: string; isDeleted?: boolean};

export type ActionPlannerWelcomeItems = Array<ActionPlannerWelcomeItem>;

export type HomePageItems = Array<HomePageItem>;

export enum HomePageItemType {
  Spotlight = 'spotlight',
  Secondary = 'secondary',
}

export type ChapterItem = NewChapterItem & {id: string; isDeleted?: boolean; anchorId: string};

export interface Introduction {
  introduction: string;
  instructions: string;
  variables?: string;
}

export interface Measure {
  sourceConstruct?: string;
  category: ChapterCategory;
  baseName: string;
  displayTitles?: string;
  synonyms?: Array<string>;
  definition?: string;
  bodyContent?: string;
  actionItems?: string;
  variables?: string;
  scoringFloor: number;
}

export interface ActionPlannerWelcomeItemContent {
  title: string;
  description: string;
  body: string;
}

export type HomePageItemContent = HomePageSpotlightItemContent | HomePageSecondaryItemContent;

export interface HomePageSecondaryItemContent {
  title: string;
  description: string;
  body: string;
  continueButtonText: string;
}

export interface HomePageSpotlightItemContent {
  title: string;
  description: string;
  summary: string;
  roleCriticalAttributes: string;
  leastEffortAttributes: string;
  mostEffortAttributes: string;
  closing?: string;
  continueButtonText: string;
}

export interface ChapterContent {
  introduction: ChapterItem;
  measures: Array<ChapterItem>;
}

export enum ChapterCategory {
  Capabilities = 'Capabilities',
  Behaviors = 'Behaviors',
  Interests = 'Interests',
}

export enum ChapterItemType {
  Introduction = 'introduction',
  Measure = 'measure',
}

export interface ChapterFilters {
  chapterNumber?: string;
  isPublished?: boolean;
  isDeleted?: boolean;
  type?: ChapterItemType;
  baseName?: string;
  sourceConstructs?: Array<string>;
}

export enum Chapters {
  Chapter1 = '1',
  Chapter2 = '2',
  Chapter3 = '3',
  Chapter4 = '4',
  Chapter5 = '5',
}

export enum ChapterTitles {
  Chapter1 = 'Getting Things Done',
  Chapter2 = 'People',
  Chapter3 = 'Problems',
  Chapter4 = 'Change',
  Chapter5 = 'Learning',
}

export interface RootSection {
  id: string;
  title: string;
  content: string;
}

export type MeasureSection = RootSection & {
  type: SectionType;
  definition: string;
  score: number;
  actionItems: string;
  constructDomain: string;
  synonyms: Array<string>;
  flags?: Array<string>;
};

export type ContentSection = RootSection & {type: SectionType};

export enum SectionType {
  Measure = 'measure',
  Content = 'content',
}

export type Section = MeasureSection | ContentSection;

export interface Category {
  title: string;
  sections: Array<Section>;
}

export interface Chapter {
  id: string;
  title: string;
  abbreviatedTitle: string;
  topLevelSections: Array<Section>;
  categories: Array<Category>;
}

export interface Book {
  chapters: Array<Chapter>;
}

export enum SortMeasureProperty {
  BaseName = 'baseName',
  DisplayTitles = 'displayTitles',
}

export interface InterpretationScale {
  ceiling: number;
  floor: number;
}

export enum ConstructDomain {
  Competency = 'competency',
  Cognitive = 'cognitive',
  Personality = 'personality',
  Interest = 'interest',
}

export interface SpotlightSectionInfo {
  title: string;
  redirectPath: string;
}

export interface SpotlightMeasure {
  title: string;
  score: number;
  constructDomain: ConstructDomain;
  spotlightSectionInfo: Array<SpotlightSectionInfo>;
}

export interface SpotlightMeasures {
  critical: Array<SpotlightMeasure>;
  strengths: Array<SpotlightMeasure>;
  opportunities: Array<SpotlightMeasure>;
}

export type SpotlightIwas = Array<string>;

export interface TemplateContext {
  firstName: string;
  lastName: string;
  job: {
    name: string;
    processedName: string;
    jobLevel: string;
    competency: {[key: string]: TemplateContextMetrics};
    cognitive: {[key: string]: TemplateContextMetrics};
    interest: {[key: string]: TemplateContextMetrics};
    personality: {[key: string]: TemplateContextMetrics};
    workContexts: {[key: string]: number};
    iwas: {[key: string]: number};
  };
}

export interface TemplateContextMetrics {
  name: string;
  score: number;
  interpCategory: string;
  flag: string;
  rankOrder: number;
}

export interface InterpretationBuckets {
  competency: InterpretationBucket;
  cognitive: InterpretationBucket;
  interest: InterpretationBucket;
  personality: InterpretationBucket;
}

export interface UpdateInterpretationBuckets {
  version: string;
  buckets: InterpretationBuckets;
}

export interface VersionedInterpretationBuckets {
  dateCreated: string;
  version: string;
  buckets: InterpretationBuckets;
}

// For, [0, 25, 50, 75]:
// - 0-24 = #1
// - 25-49 = #2
// - 50-74 = #3
// - 75-100 = #4
//
// 100 is implied and shouldn't be provided
//
export type InterpretationBucket = Array<number>;

export interface EmployeeResults {
  activeResultWorkflowIds?: Array<string>;
  pendingResultWorkflowIds?: Array<string>;
}

export enum EmployeeJobLevel {
  IndividualContributor = 'IC',
  SeniorIndividualContributor = 'SIC',
  ManagerOrSupervisor = 'MGR',
  SeniorManagerOrDirector = 'SMGR',
  VicePresidentOrHigher = 'VP',
}

export enum DirectReportsResponse {
  Yes = 'Yes',
  No = 'No',
}

export interface JobLevel {
  level: EmployeeJobLevel;
  directReports: DirectReportsResponse;
}

export enum AccountStatus {
  NoPlan = 'No Plan',
  Active = 'Active',
  Cancelled = 'Cancelled',
  OnHold = 'On Hold',
}

export enum AccountSubStatus {
  PendingCancellation = 'Pending Cancellation',
  PendingPayment = 'Pending Payment',
  FailedBilling = 'FailedBilling',
  PastDue = 'Past Due',
  TemporaryAccessOverridden = 'Temporary Access / Overridden',
}

export interface IndexMeasure {
  id: string;
  title: string;
  type?: SectionType;
  definition: string;
  score: number;
  actionItems?: string;
  constructDomain: string;
  synonyms: Array<string>;
  flags: Array<string>;
  chapters: Array<string>;
  anchorIds: Array<string>;
  category?: ChapterCategory;
}

export type IndexMeasures = Array<IndexMeasure>;

export type Workflows = Array<Workflow>;

export interface BillingOverrideHistory {
  date?: string;
  userName?: string;
  userId?: string;
  dateOfBillingOverride?: string;
}

export interface UpdateBillingOverride {
  dateOfBillingOverride: string;
  removeBillingOverride?: boolean;
}

export interface UpdateEnableTeams {
  enableTeams: boolean;
}

export interface ResultsShareDetails {
  recipientEmail: string;
  employeeIds: Array<string>;
}

export interface ResultsShareLog {
  dateOfResultsShare: string;
  senderAuthId: string;
  recipientAuthId: string;
}

export interface AccountChangedLog {
  originalAccountId: string;
  movedByUserId: string;
  dateMoved: string;
}

export enum EffortType {
  High = 'high',
  Moderate = 'moderate',
  Low = 'low',
}

export interface NewActionItemTemplate {
  title: string;
  attribute: string;
  effort: EffortType;
  description?: string;
  improvement?: string;
  nextSteps?: string;
  doneCriteria?: string;
}

export type ActionItemTemplate = NewActionItemTemplate & {id: string; isDeleted?: boolean};

export interface ActionItemWidgetParams {
  content: string;
  templates: Array<ActionItemWidgetTemplate>;
}

export type ActionItemWidgetTemplate = ActionItemTemplate & {isUsed: boolean};

export type ActionItemAttributes = Array<string>;

export interface ActionItemTemplateImportResult {
  errors?: Array<FileImportError>;
  actionItemTemplatesAdded?: number;
  actionItemTemplatesUpdated?: number;
}

export interface EmployeeRenderedMeasure {
  name: string;
  chapterNumber: string;
  content: string;
}

export type EmployeeRenderedMeasures = Array<EmployeeRenderedMeasure>;

export interface Team {
  id: string;
  accountId: string;
  name: string;
  members: Array<TeamMemberWithEmployee>;
  dateCreated: string;
  isReportReleased?: boolean;
}

export interface UpdateTeamParams {
  name?: string;
  members?: Array<TeamMember>;
}

export interface TeamUpdateAudit {
  dateUpdated: string;
  name?: string;
  membersAdded?: Array<TeamMember>;
  membersRemoved?: Array<TeamMember>;
  isReportReleased?: boolean;
}

export interface TeamShareAudit {
  dateShared: string;
  sharedBy: string;
  sharedWith: string;
}

export interface TeamReportShareDetails {
  recipientEmail: string;
}

export interface NewTeam {
  accountId: string;
  name: string;
  membersAdded: Array<TeamMember>;
}

export interface TeamMember {
  employeeId: string;
  role?: TeamRoles;
}

export type TeamMemberWithEmployee = TeamMember & {employee: Employee};

export enum TeamRoles {
  Manager = 'Manager',
}

export type Teams = Array<Team>;

export interface TeamReport {
  teamId: string;
  teamName: string;
  overviews: TeamReportOverviews;
  sections: TeamReportSections;
}

export interface Typology {
  name: string;
  quadrant: TypologyQuadrant;
  typeInterpretation: string;
}

export interface TeamReportOverviews {
  communication: PersonalityCompetencyOverview;
  work: PersonalityCompetencyOverview;
  challenge: PersonalityCompetencyOverview;
  drivers: MotivationOverview;
}

export interface TeamReportSections {
  communication: PersonalityCompetencySection;
  work: PersonalityCompetencySection;
  challenge: PersonalityCompetencySection;
  drivers: MotivationSection;
}

export interface TeamReportOverviewBase {
  introDynamicInterpretation: string;
  introTitle: string;
  cardParagraph: string;
  strengths: string;
  risks: string;
}

export type PersonalityCompetencyOverview = TeamReportOverviewBase & {selectedTypology: Typology};

export type MotivationOverview = TeamReportOverviewBase & {
  highestMotivator: InterestsSourceConstruct;
};

export interface TeamReportSectionBase {
  domain: TeamReportDomain;
  title: string;
  abbreviatedTitle: string;
  introTitle: string;
  introText: string;
  subSections: TeamReportSubSections;
}

export type PersonalityCompetencySection = TeamReportSectionBase & {
  balancerContent: Array<BalancerTypologyContent>;
  selectedTypology: Typology;
  allTypologies: Array<Typology>;
  quadrantScores: TypologyQuadrantScores;
};

export type MotivationSection = TeamReportSectionBase & {
  highestMotivator: InterestsSourceConstruct;
};

export interface TeamReportSubSections {
  attributes: AttributeSubSection;
}

export interface AttributeSubSection {
  title: string;
  intro: string;
  spotlights: Array<Spotlight>;
  teamApproachSpotlights: Array<Spotlight>;
  content: Array<AttributeContent>;
}

export interface AttributeContent {
  title: string;
  description: string;
  teamAverage: number;
  dotPlotData: Array<AttributePlotData>;
  bodyContent: string;
}

export interface AttributePlotData {
  firstName: string;
  lastName: string;
  score: number;
}

export interface Spotlight {
  title: string;
  score: number;
  constructDomain: ConstructDomain;
}

export interface BalancerTypologyContent {
  typology: Typology;
  content: string;
}

export interface TypologyQuadrantScores {
  individualScores: Array<TypologyQuadrantScore>;
  teamAverageScore: TeamSectionAverageScore;
}

export interface TypologyQuadrantScore {
  firstName: string;
  lastName: string;
  x: number;
  y: number;
}

export interface TeamSectionAverageScore {
  x: number;
  y: number;
}

export enum TeamReportDomain {
  Communication = 'Communication',
  Work = 'Work',
  Challenge = 'Challenge',
  Drivers = 'Drivers',
}

export enum TypologyQuadrant {
  TypeI = 'Type I',
  TypeII = 'Type II',
  TypeIII = 'Type III',
  TypeIV = 'Type IV',
}

export enum InterestsSourceConstruct {
  Realistic = 'Realistic',
  Investigative = 'Investigative',
  Artistic = 'Artistic',
  Social = 'Social',
  Enterprising = 'Enterprising',
  Conventional = 'Conventional',
}

export interface TeamReportItem {
  id: string;
  type: TeamReportContentType;
  content: TeamReportOverviewContent | TeamReportApproachContent | TeamReportAttributeContent;
}

export type TeamReportItems = Array<TeamReportItem>;

export interface TeamReportOverviewContent {
  domain: TeamReportDomain;
  cardTitle: string;
  introDynamicInterpretation: string;
  cardParagraph: string;
  strengths: string;
  risks: string;
}

export interface TeamReportApproachContent {
  introductionText: string;
  domain: TeamReportDomain;
  typology1?: TypologyContent;
  typology2?: TypologyContent;
  typology3?: TypologyContent;
  typology4?: TypologyContent;
  attributeSectionIntroText: string;
}

export interface TeamReportAttributeContent {
  domain: TeamReportDomain;
  sourceConstruct: string;
  description: string;
  content: string;
}

export interface TypologyContent {
  displayName: string;
  typeInterpretation: string;
  balancerParagraph: string;
}

export enum TeamReportContentType {
  Overview = 'Overview',
  Approach = 'Approach',
  Attribute = 'Attribute',
}

export interface BooleanWrapper {
  value: boolean;
}
