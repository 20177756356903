import * as React from 'react';
import {gray500} from 'wnd-themes/lib/colorPalette';
import styled from '@emotion/styled';

import {TeamReportScoringZones} from 'wnd-dvp-reports';
import {getScoreZone} from 'wnd-graphics/lib/dotPlot/scoreZone';
import {ScoreContainer} from '>shared/components/charts/dotPlotButtons';
import {textSmMedium, textSmRegular} from '>shared/components/typography/designSystemTypography';
import {vr6} from '>shared/styles/mixins/verticalRhythm';

export const AverageScoreDisplay: React.FC<{averageScore: number}> = ({averageScore, children}) => {
  const scoreZone = getScoreZone(averageScore, TeamReportScoringZones);

  return (
    <AverageScoreContainer color={scoreZone.textColor}>
      <Text>{children}</Text>
      <ScoreContainer
        borderColor={scoreZone.scoreBorderColor}
        backgroundColor={scoreZone.scoreBackgroundColor}
      >
        {averageScore}
      </ScoreContainer>
    </AverageScoreContainer>
  );
};

const AverageScoreContainer = styled.div<{color: string}>`
  ${textSmMedium};
  ${vr6};
  color: ${(props) => props.color};
`;

const Text = styled.span`
  ${textSmRegular};
  color: ${gray500};
`;
