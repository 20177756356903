"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextTabs = void 0;
Object.defineProperty(exports, "TabType", {
  enumerable: true,
  get: function () {
    return _contextTabs.TabType;
  }
});
var _jsxRuntime = require("@emotion/react/jsx-runtime");
var React = _interopRequireWildcard(require("react"));
var _tabBehavior = require("./tabBehavior");
var _rawSelect = require("../form/rawControls/rawSelect");
var _breakpoints = require("../../styles/breakpoints");
var _verticalRhythm = require("../../styles/mixins/verticalRhythm");
var _contextTabs = require("./contextTabs.styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const DropdownHeader = ({
  tabs,
  activeTab,
  setActiveTab
}) => {
  const tabDropdownOptions = Object.keys(tabs).map(key => {
    const details = tabs[key];
    return {
      label: details.title,
      value: key
    };
  });
  const handleTabSelectChanged = React.useCallback(tab => {
    setActiveTab(tab.value);
  }, []);
  const activeTabIndex = tabDropdownOptions.findIndex(option => option.value === activeTab);
  return (0, _jsxRuntime.jsx)(_contextTabs.TabSelectDropdown, {
    css: _verticalRhythm.vr2,
    options: tabDropdownOptions,
    onSelectChange: handleTabSelectChanged,
    variant: _rawSelect.SelectVariant.alternate,
    value: tabDropdownOptions[activeTabIndex],
    /* @ts-ignore */
    defaultValue: tabDropdownOptions[0],
    name: "context-tabs-mobile-dropdown"
  });
};
const TabHeader = ({
  tabs,
  registerTab,
  tabType,
  className,
  activeTab
}) => {
  return (0, _jsxRuntime.jsx)(_contextTabs.TabContainer, {
    tabType: tabType,
    className: className,
    children: Object.keys(tabs).map(key => {
      const details = tabs[key];
      return (0, _jsxRuntime.jsx)(_contextTabs.Tab, _objectSpread(_objectSpread({
        role: "tab",
        "aria-selected": key === activeTab,
        expand: details.expand
      }, registerTab(key)), {}, {
        tabType: tabType,
        children: details.title
      }), key);
    })
  });
};
const ContextTabs = ({
  tabs,
  tabType,
  enableScroll = true,
  useDropdownOnMobile = true,
  manualTabOverride
}) => {
  const {
    registerTab,
    activeTab,
    setActiveTab
  } = (0, _tabBehavior.useTabs)({
    activationMethod: _tabBehavior.TabActivationMethod.Manual
  });
  const isMobile = window.matchMedia(_breakpoints.mediaQueries.smallOrLower.media).matches;
  if (isMobile && !activeTab) {
    setActiveTab(Object.keys(tabs)[0]);
  }
  React.useEffect(() => {
    const linkedTab = Object.keys(tabs).find(tabKey => tabs[tabKey].hashLink === window.location.hash.slice(1));
    if (linkedTab) {
      setActiveTab(linkedTab);
    }
  }, [window.location.hash]);
  React.useEffect(() => {
    if (activeTab && tabs[activeTab]) {
      tabs[activeTab].onActivate?.(activeTab);
      enableScroll && scrollTo(0, 0);
    }
  }, [activeTab, enableScroll]);
  React.useEffect(() => {
    const firstTab = Object.keys(tabs)[0];
    if (manualTabOverride) {
      setActiveTab(firstTab);
    }
  }, [manualTabOverride]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [isMobile && useDropdownOnMobile ? (0, _jsxRuntime.jsx)(DropdownHeader, {
      tabs: tabs,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      tabType: tabType
    }) : (0, _jsxRuntime.jsx)(TabHeader, {
      className: "tab-header",
      tabs: tabs,
      registerTab: registerTab,
      tabType: tabType,
      activeTab: activeTab
    }), activeTab && tabs[activeTab].page(setActiveTab)]
  });
};
exports.ContextTabs = ContextTabs;