import styled from '@emotion/styled';

export const Widgets = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0.8rem 0 2.4rem;
  overflow-x: auto;
`;

export const SpotlightCardContainer = styled.button<{isColumn?: boolean; isLast?: boolean}>`
  background: none;
  border: none;
  outline: none;
  padding: 0.8rem;
  cursor: pointer;

  :first-of-type {
    padding-left: 0;
  }
`;
